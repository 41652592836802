import dayjs from 'dayjs';
import {
  BACKEND,
  BACKEND_EU,
  SUPPORTED_BACKENDS,
} from 'src/constants/backends';
import { LANGUAGE } from 'src/constants/languages';
import OrderBundleError from 'src/errors/OrderBundleError';
import backendApi, {
  IRequestParams,
  isRDCPUser,
} from 'src/lib/apiClient/backendApi';
import IAuthUser from 'src/types/IAuthUser';
import IBackendError from 'src/types/IBackendError';
import IBackendProbeEndpoint from 'src/types/IBackendProbeEndpoint';
import IBundle from 'src/types/IBundle';
import IBundleConfigurationsOptions from 'src/types/IBundleConfigurationsOptions';
import IBundleGroup from 'src/types/IBundleGroup';
import ICheckUserBundleEndpoint from 'src/types/ICheckUserBundleEndpoint';
import IConsentEndpoint, {
  INormalizedConsentData,
} from 'src/types/IConsentEndpoint';
import IErrorEndpoint from 'src/types/IErrorEndpoint';
import IGivenConsent from 'src/types/IGivenConsent';
import IInsuranceCard from 'src/types/IInsuranceCard';
import ILoginEndpoint from 'src/types/ILoginEndpoint';
import IRDCPAuthEndpoint from 'src/types/IRDCPAuthEndpoint';
import IRegisterConfirmEndpoint from 'src/types/IRegisterConfirmEndpoint';
import IRegisterEndpoint from 'src/types/IRegisterEndpoint';
import IValidateResetPasswordCodeEndpoint from 'src/types/IResetPasswordEndpoint';
import IShipment from 'src/types/IShipment';
import ITriggerEmailVerificationEndpoint from 'src/types/ITriggerEmailVerificationEndpoint';
import IUnvalidatedBundleProperty from 'src/types/IUnvalidatedBundleProperty';
import IUser from 'src/types/IUser';
import IUserMeEndpoint from 'src/types/IUserMeEndpoint';
import IValidatedBundleProperty from 'src/types/IValidatedBundleProperty';
import IVoucher from 'src/types/IVoucher';
import IVoucherResponse from 'src/types/IVoucherResponse';
import IVoucherResponseData from 'src/types/IVoucherResponseData';
import { parseBundle } from '../parseBundle';
import getOrderPropertyValue from './getOrderPropertyValue';
import prepareOIDCHeader from './prepareOIDCHeader';
import UnauthorizedException from './UnauthorizedException';

export interface IOrderParams {
  user: IAuthUser;
  shipment?: IShipment;
  billing?: IShipment;
  bundle: IBundle;
  group: IBundleGroup;
  consents: IGivenConsent[];
  language: LANGUAGE;
  voucher?: IVoucher;
  insuranceCard?: IInsuranceCard;
  employer?: string;
  insuranceNumber?: string;
}

export interface IBackendClientOptions {
  baseUrls: { eu: string; us: string };
  environment: string;
  region: BACKEND;
}

interface IRedeemVoucherProps {
  region?: BACKEND;
  user?: IAuthUser;
  voucher: IVoucher;
}

export interface IOrderBundleProps {
  billing: IShipment;
  bundle: IBundle;
  consents: IGivenConsent[];
  group: IBundleGroup;
  insuranceCard?: IInsuranceCard;
  insuranceNumber?: string;
  language: LANGUAGE;
  shipment: IShipment;
  user: IAuthUser;
  voucher?: IVoucher;
}

class BackendClient {
  private static instance: BackendClient;
  public baseUrls: { eu: string; us: string };
  public environment = 'development';
  public region: BACKEND = BACKEND_EU;
  public appType = 'WEB_SHOP';

  constructor(options: IBackendClientOptions) {
    this.baseUrls = options.baseUrls;
    this.region = options.region;
    this.environment = options.environment;
  }

  public static getInstance(options?: IBackendClientOptions) {
    if (!BackendClient.instance && options) {
      BackendClient.instance = new BackendClient(options);
    }
    return BackendClient.instance;
  }

  public async register(
    user: IUser,
    consents: IGivenConsent[],
    language: LANGUAGE,
    country: string,
    dayOfBirth?: string,
    region?: BACKEND,
  ): Promise<IRegisterEndpoint | IErrorEndpoint> {
    return backendApi
      .post('/users', {
        backend: region || this.region,
        data: {
          userInput: {
            consents,
            country: country.toUpperCase(),
            emailAddress: user.email,
            language,
            password: user.password,
            registrationType: 'HOME_WEB',
            dayOfBirth,
          },
        },
        language,
      })
      .catch(error => error)
      .then(response => {
        if (response.status === 403) {
          return response.data as IErrorEndpoint;
        }
        if (response.status !== 200) {
          return {
            error: { code: response.status, message: response.statusText },
          };
        }
        return response.data as IRegisterEndpoint;
      });
  }

  public async getRecommendedRegionDetails(backends: BACKEND[]): Promise<{
    region: BACKEND;
    probe: IBackendProbeEndpoint | undefined;
  }> {
    const backendsProbeRequest = backends.map(backend =>
      backendApi.get<IBackendProbeEndpoint>('/users/probe', {
        apiVersion: 3,
        backend,
        params: { ignoreAppConditions: true },
      }),
    );
    return Promise.all(backendsProbeRequest).then(backendsProbe => {
      let recommendedBackendIndex = backendsProbe.findIndex(
        ({ data }) => data?.recommendedBackend === true,
      );

      // Fallback just in case, this is impossible in a production environment
      if (recommendedBackendIndex < 0) {
        recommendedBackendIndex = 0;
      }

      const region = SUPPORTED_BACKENDS[recommendedBackendIndex];
      const probe = backendsProbe[recommendedBackendIndex].data;
      return {
        probe,
        region,
      };
    });
  }

  public async RDCPAuth(
    token: string,
    tokenType: 'CODE' | 'REFRESH',
    region: BACKEND,
  ): Promise<IRDCPAuthEndpoint> {
    return backendApi
      .post<IRDCPAuthEndpoint>('/rochediabetes/token/shop', {
        apiVersion: 3,
        backend: region,
        data: {
          token,
          tokenType,
        },
      })
      .then(response => response.data!);
  }

  public async getUserDetails(user: IAuthUser, region?: BACKEND) {
    return backendApi
      .get<IUserMeEndpoint>('/users/me', {
        backend: region || this.region,
        apiVersion: 3,
        user,
      })
      .then(response => response.data!);
  }

  public async checkUserBundle(
    user: IUser | IAuthUser,
  ): Promise<ICheckUserBundleEndpoint> {
    return backendApi
      .get('/commerce/bundle/active', {
        backend: this.region,
        user,
      })
      .then(() => ({
        bundleActive: true,
        validCredentials: true,
      }))
      .catch(err => {
        if (err.unauthorized) {
          throw err;
        }
        return {
          bundleActive: false,
          validCredentials: err.status === 404,
        };
      });
  }

  public async login(
    user: IUser,
    region?: string,
  ): Promise<ILoginEndpoint | IErrorEndpoint> {
    const reqRegion = region || this.region;
    let validTo = dayjs().add(3, 'hour');
    if (/valid-to-now/.exec(user.email)) {
      validTo = validTo.subtract(3, 'hour');
    }
    return backendApi
      .post('/users/me/auth/tokens/new', {
        user,
        backend: reqRegion as BACKEND,
        data: {
          userTokenInput: {
            authorities: ['ROLE_RESTCLIENT'],
            cookie: false,
            validTo: validTo.unix(),
          },
        },
      })
      .catch(() => ({
        config: {},
        data: {},
        headers: {},
        status: 401,
        statusText: 'Unauthorized',
      }))
      .then(response => {
        if (response.status !== 200) {
          return {
            error: { code: response.status, message: response.statusText! },
          };
        }
        return response.data as ILoginEndpoint;
      });
  }

  public async logout(user: IAuthUser, region?: BACKEND) {
    const authUser: IAuthUser = {
      password: user.authenticationToken,
      email: 'x',
    };

    return backendApi
      .delete('/users/me/auth/tokens', {
        user: authUser,
        backend: region || this.region,
      })
      .then(response => response.status === 204)
      .catch(() => false);
  }

  public async RDCPLogout(user: IAuthUser, region?: BACKEND) {
    return backendApi
      .post('/rochediabetes/logout', {
        user,
        backend: region || this.region,
        apiVersion: 3,
        data: {
          token: user.authenticationToken,
        },
      })
      .then(response => response.status === 204)
      .catch(() => false);
  }

  public async triggerEmailVerification(
    email: string,
    language: LANGUAGE,
    region?: BACKEND,
  ): Promise<ITriggerEmailVerificationEndpoint | IErrorEndpoint> {
    return backendApi
      .post('/users/me/emailverification/code', {
        backend: region || this.region,
        data: { emailVerification: { emailAddress: email, isDesktop: true } },
        params: { language },
      })
      .then(() => ({ email, triggered: true }))
      .catch(response =>
        Promise.reject({
          error: { code: response.status, message: response.statusText },
        }),
      );
  }

  public async confirmEmail(
    email: string,
    code: string,
    region?: BACKEND,
  ): Promise<IRegisterConfirmEndpoint | IErrorEndpoint> {
    return backendApi
      .post('/users/me/emailverification/registrationtoken', {
        data: {
          emailVerification: {
            code,
            emailAddress: email,
          },
        },
        backend: region || this.region,
      })
      .catch(response => {
        const status = response.status
          ? response.status
          : parseInt(response.message.match(/(\d+)/)[0], 10);
        return {
          status,
          statusText: '',
        };
      })
      .then(response => {
        if (response.status !== 200 || !('data' in response)) {
          return {
            error: {
              code: response.status,
              message: response.statusText!,
            },
          };
        }
        return {
          confirmed: true,
          registrationToken: response.data.registrationToken.token,
        };
      });
  }

  public async userExists(email: string, region?: BACKEND): Promise<boolean> {
    return backendApi
      .get(`/users/${email}/probe`, {
        backend: region || this.region,
      })
      .catch(() => ({ status: 404 }))
      .then(response => response.status === 204 || response.status === 302);
  }

  public async resetPassword(
    email: string,
    token: string,
    password: string,
    region?: BACKEND,
  ): Promise<any> {
    return backendApi.put('/resetpassword', {
      backend: region || this.region,
      data: {
        passwordReset: {
          email,
          password,
          token,
        },
      },
    });
  }

  public async requestPasswordReset(
    email: string,
    region: BACKEND,
  ): Promise<boolean> {
    return backendApi
      .post('/resetpassword', {
        backend: region,
        data: { passwordReset: { email, appType: this.appType } },
      })
      .then(response => response.status === 204);
  }

  public async validateResetPasswordCode(
    email: string,
    code: string,
    region?: BACKEND,
  ): Promise<IValidateResetPasswordCodeEndpoint | IErrorEndpoint> {
    return backendApi
      .post('/resetpassword/code', {
        backend: region || this.region,
        data: {
          passwordReset: {
            code,
            email,
          },
        },
      })
      .catch(response => {
        const status = response.status
          ? response.status
          : parseInt(response.message.match(/(\d+)/)[0], 10);
        return Promise.reject({
          status,
          statusText: '',
        });
      })
      .then(response => {
        if (response.status !== 200 || !('data' in response)) {
          return Promise.reject({
            error: {
              code: response.status,
              message: response.statusText,
            },
          });
        }
        return Promise.resolve({
          token: response.data.passwordReset.token,
        });
      });
  }

  public async getMissingConsents(options: {
    purpose: string;
    country: string;
    user?: IAuthUser;
    bundle?: IBundle;
    region?: BACKEND;
    language?: LANGUAGE;
  }): Promise<INormalizedConsentData> {
    const { user, bundle, country, language, purpose, region } = options;
    const parameters = [];
    if (bundle) {
      parameters.push({
        key: 'bundleConfigurationCode',
        value: bundle.code,
      });
    }

    let auth: IAuthUser | IUser | undefined =
      user && (user.authenticationToken || user.password)
        ? {
            password: user.authenticationToken || user.password,
            email: user.authenticationToken ? 'x' : user.email,
          }
        : undefined;

    if (isRDCPUser(user)) {
      auth = user;
    }

    return backendApi
      .post<IConsentEndpoint>('/users/me/consents/missing', {
        backend: region || this.region,
        user: auth,
        apiVersion: 3,
        data: {
          purpose: {
            country: country.toUpperCase(),
            parameters,
            type: purpose,
          },
        },
        language,
      })
      .then(response => {
        if (!response.data) {
          return {
            consentRequirements: {
              purpose,
              requiredDocuments: [],
            },
          };
        }
        return {
          consentRequirements: response.data,
        };
      })
      .catch(err => {
        if (err.status === 401) {
          return Promise.reject(new UnauthorizedException());
        }
        return Promise.reject(err);
      });
  }

  public async getBundle(code: string, region?: BACKEND): Promise<IBundle> {
    return backendApi
      .get(`/commerce/config/bundle/${code}`, {
        backend: region || this.region,
      })
      .then(response => response.data.bundle as IBundle)
      .then((response: IBundle) => parseBundle(response));
  }

  public async getBundleConfigurations(
    options: IBundleConfigurationsOptions,
  ): Promise<IBundle[]> {
    const params: IRequestParams = {};
    if (options.country) {
      params.country = options.country;
    }

    return backendApi
      .get('/commerce/config/bundles', { params, backend: this.region })
      .then((res): IBundle[] => res.data.bundle.configurations || [])
      .then((response: IBundle[]): IBundle[] =>
        response.map(bundle => parseBundle(bundle)).filter(e => e),
      );
  }

  public async giveConsents(
    user: IAuthUser,
    consents: IGivenConsent[],
  ): Promise<any> {
    let authUser: IAuthUser = {
      password: user.authenticationToken,
      email: 'x',
    };
    if (isRDCPUser(user)) {
      authUser = user;
    }
    return backendApi
      .post('/users/me/consents', {
        backend: this.region,
        apiVersion: 3,
        headers: prepareOIDCHeader(user.authenticationToken, user.authType),
        user: authUser,
        data: { consents },
      })
      .then(
        response =>
          new Promise((resolve, reject) => {
            if (
              response.data.consentRecord.length > 0 ||
              consents.length === 0
            ) {
              resolve(true);
            } else {
              reject(false);
            }
          }),
      )
      .catch(error => {
        if (error && error.status === 401) {
          return Promise.reject(new UnauthorizedException());
        }
        return Promise.reject(false);
      });
  }

  public async orderBundle(options: IOrderBundleProps): Promise<IBundle> {
    return this.orderBundleCommerce({
      billing: options.billing,
      bundle: options.bundle,
      consents: options.consents,
      group: options.group,
      insuranceCard: options.insuranceCard,
      insuranceNumber: options.insuranceNumber,
      language: options.language,
      shipment: options.shipment,
      user: options.user,
      voucher: options.voucher,
    });
  }

  public async validateVoucherCode(
    voucherCode: string,
    region: BACKEND,
  ): Promise<IVoucherResponse> {
    return backendApi
      .get(`/vouchers/${voucherCode}`, {
        backend: region,
      })
      .then(response =>
        response.status === 200
          ? {
              valid: true,
              voucher: response.data.voucher as IVoucherResponseData,
            }
          : { valid: false },
      )
      .catch(err => ({ valid: false, message: err.data?.message }));
  }

  public async redeemVoucher({
    voucher,
    user,
    region,
  }: IRedeemVoucherProps): Promise<any> {
    if (!user) {
      throw new Error('user missing');
    }

    let authUser: IAuthUser = {
      password: user.authenticationToken,
      email: 'x',
    };
    if (isRDCPUser(user)) {
      authUser = user;
    }

    return backendApi
      .put(`/vouchers/${voucher.voucherCode}`, {
        user: authUser,
        backend: region || this.region,
      })
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  }

  public async validateBundleProperties(
    bundle: IBundle,
    properties: IUnvalidatedBundleProperty[],
  ): Promise<IValidatedBundleProperty[]> {
    return backendApi
      .post(`/commerce/properties/bundle/${bundle.code}/validate`, {
        backend: this.region,
        data: {
          bundleProperties: { properties },
        },
      })
      .then(
        response =>
          response.data.bundleProperties
            .properties as IValidatedBundleProperty[],
      )
      .catch(() => []);
  }

  private async orderBundleCommerce(data: IOrderBundleProps) {
    const orderProperties = (data.bundle.properties || [])
      .map(bundleProperty => ({
        key: bundleProperty.key,
        value: getOrderPropertyValue(bundleProperty.key, data),
      }))
      .filter(
        property => property.value !== null && property.value !== undefined,
      );

    const shipment = data.shipment;
    const billing = data.billing;

    const shipmentAddress = {
      address1: shipment.address,
      // Backend requires address2 to be either at least 1 character long or NULL
      address2: shipment.address2 || null,
      city: shipment.city,
      country: shipment.country.toUpperCase(),
      firstName: shipment.firstName,
      lastName: shipment.lastName,
      state: shipment.state,
      type: 'SHIPMENT_ADDRESS',
      zipCode: shipment.postalCode,
    };

    const paymentAddress = {
      address1: billing.address,
      // Backend requires address2 to be either at least 1 character long or NULL
      address2: billing.address2 || null,
      city: billing.city,
      country: billing.country.toUpperCase(),
      firstName: billing.firstName,
      lastName: billing.lastName,
      state: billing.state,
      type: 'PAYMENT_ADDRESS',
      zipCode: billing.postalCode,
    };

    const sendData = {
      order: {
        emailAddress: data.user.email,
        items: [
          {
            amount: 1,
            code: data.group.code,
          },
        ],
        orderAddresses: [shipmentAddress, paymentAddress],
        orderProperties,
      },
    };

    const headers = {};

    if (global && (global as any).EXECUTION_ENVIRONMENT === 'functional') {
      headers['X-mySugr-validationOnly'] = true;
    }
    let authUser: IAuthUser = {
      password: data.user.authenticationToken,
      email: 'x',
    };
    if (isRDCPUser(data.user)) {
      authUser = data.user;
    }
    return backendApi
      .post(`/commerce/orders`, {
        backend: this.region,
        user: authUser,
        data: sendData,
        headers,
      })
      .then(() => data.bundle)
      .catch(err => {
        if (err.unauthorized) {
          throw err;
        }
        throw new OrderBundleError(err.data as IBackendError);
      });
  }
}

export default BackendClient;
