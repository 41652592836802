import classNames from 'classnames';
import * as React from 'react';
import FormFieldError from 'src/components/FormFieldError/FormFieldError';
import FormLabel, { IMoreInfo } from 'src/components/FormLabel/FormLabel';
import { IProps as IInputProps } from 'src/components/Input/Input';
import { IFieldMask } from 'src/lib/FieldMask';
import IFormNotice from 'src/types/IFormNotice';
import { INPUT_TYPE } from 'src/types/IInputType';
import InlineWarning from '../InlineWarning/InlineWarning';
import css from './FormField.module.scss';

export interface IProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  errors: IFormNotice[];
  formName: string;
  hint?: string;
  injectError?: boolean;
  label?: string;
  labelClassName?: string;
  labelFallback?: string;
  labelBundle?: string;
  last?: boolean;
  name: string;
  autocomplete?: string;
  placeholder?: string;
  pattern?: string;
  moreInfo?: IMoreInfo;
  type?: INPUT_TYPE;
  mask?: IFieldMask[];
  warnings: IFormNotice[];
  required?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}

export function FormField({
  children,
  className,
  disabled,
  errors,
  formName,
  hint,
  injectError,
  label,
  labelFallback,
  labelClassName,
  labelBundle,
  last,
  name,
  autocomplete,
  placeholder,
  required,
  moreInfo,
  warnings,
  pattern,
  type,
  mask,
  onBlur,
  onFocus,
}: Readonly<IProps>) {
  const shouldInjectError = injectError ?? true;
  const isLast = last ?? false;

  // Filter checkbox errors that should show directly next to the checkbox.
  const valueErrors = errors.filter(
    error =>
      error.field !== 'consents' &&
      error.field !== 'code' &&
      error.field !== 'birthdateCheckbox',
  );

  const renderWarnings = warnings.map((error, i) => (
    <InlineWarning key={i} warning={error} />
  ));

  return (
    <div
      className={classNames(css.formField, className, {
        [css.formFieldError]: valueErrors.length > 0,
        [css.disabled]: disabled,
        [css.last]: isLast,
      })}
    >
      <a id={`${name}-field`} />
      {label && (
        <FormLabel
          className={labelClassName}
          disabled={disabled}
          name={name}
          labelBundle={labelBundle}
          label={label}
          labelFallback={labelFallback}
          hint={hint}
          autocomplete={autocomplete}
          placeholder={placeholder}
          required={required}
          moreInfo={moreInfo}
        />
      )}
      {typeof children === 'function'
        ? children({ error: errors.length > 0, errors, formName })
        : React.Children.map(children, (child: React.ReactChild) => {
            const props: {
              error?: boolean;
              errors?: IFormNotice[];
              warning?: boolean;
              warnings?: IFormNotice[];
              formName?: string;
              pattern?: string;
              type?: INPUT_TYPE;
              mask?: IFieldMask[];
              onFocus?: () => void;
              onBlur?: () => void;
            } = {
              pattern,
              type,
              mask,
              onBlur,
              onFocus,
            };
            if (shouldInjectError) {
              props.error = errors.length > 0;
              props.errors = errors;
              props.warning = warnings.length > 0;
              props.warnings = warnings;
              props.formName = formName;
            }
            return React.cloneElement(
              child as React.ReactElement<IInputProps>,
              props,
            );
          })}
      {renderWarnings}
      <FormFieldError
        id={`${name}-field-error`}
        errors={valueErrors.length > 0 ? valueErrors : []}
        formName={formName}
        name={name}
      />
    </div>
  );
}

FormField.defaultProps = {};

export default FormField;
