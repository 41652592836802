/* istanbul ignore file */

import IBundleGroupItemPrice from './IBundleGroupItemPrice';

export enum BundleGroupItemType {
  product = 'Product',
  sub = 'Subscription',
}

export enum BundleGroupItemAttrTypeValue {
  coachSub = 'COACH_SUBSCRIPTION',
  testStripsSub = 'TEST_STRIPS_SUBSCRIPTION',
  cuff = 'CUFF',
  weightScale = 'WEIGHT_SCALE',
  proSub = 'PRO_SUBSCRIPTION',
  lancets = 'LANCETS',
  softclixLancets = 'LANCETS_SOFTCLIX',
  lancingKit = 'LANCING_KIT',
  softclixLancingKit = 'LANCING_KIT_SOFTCLIX',
  guide = 'GUIDE',
  guideMe = 'GUIDE_ME',
  meter = 'GUIDE_ME',
  testStrips = 'TEST_STRIPS',
  pdfReport = 'PDF_REPORT',
}

export enum BundleGroupItemAttrKey {
  unit = 'UNIT',
  region = 'REGION',
  coachSelect = 'COACH_SELECTION',
  language = 'LANGUAGE',
  type = 'TYPE',
  alternateText = 'ALTERNATE_TEXT',
  coachGroup = 'COACH_GROUP',
}

export enum BundleGroupItemAttrValue {
  countryDe = 'de',
  countryUs = 'us',
  langDe = 'DE',
  langEn = 'EN',
  regionUs = 'US',
  regionDe = 'DE',
  coachSelectRegion = 'REGION',
  coachSelectVoucher = 'VOUCHER_BASED',
  coachSelectGroup = 'COACH_GROUP',
  coachSelectCare = 'CARE_INNOVATIONS',
  unitMMOL = 'MMOL',
  unitMG = 'MG',
  true = 'TRUE',
  false = 'FALSE',
}

export const translationKeyMap: {
  [K in BundleGroupItemAttrTypeValue]: string;
} = {
  [BundleGroupItemAttrTypeValue.coachSub]: 'coach',
  [BundleGroupItemAttrTypeValue.proSub]: 'pro',
  [BundleGroupItemAttrTypeValue.testStripsSub]: 'strips_subscription_guide',
  [BundleGroupItemAttrTypeValue.guide]: 'guide',
  [BundleGroupItemAttrTypeValue.guideMe]: 'guide_me',
  [BundleGroupItemAttrTypeValue.meter]: 'guide_me',
  [BundleGroupItemAttrTypeValue.lancets]: 'fastclix_lancets',
  [BundleGroupItemAttrTypeValue.softclixLancets]: 'softclix_lancets',
  [BundleGroupItemAttrTypeValue.lancingKit]: 'fastclix_lancing_kit',
  [BundleGroupItemAttrTypeValue.softclixLancingKit]: 'softclix_lancing_kit',
  [BundleGroupItemAttrTypeValue.cuff]: 'cuff',
  [BundleGroupItemAttrTypeValue.weightScale]: 'scale',
  [BundleGroupItemAttrTypeValue.testStrips]: 'guide_strips',
  [BundleGroupItemAttrTypeValue.pdfReport]: 'pdf_report',
};

export interface IBundleGroupItemAttr {
  key: BundleGroupItemAttrKey;
  value: BundleGroupItemAttrValue | BundleGroupItemAttrTypeValue | string;
}

interface IBundleGroupItem {
  attributes?: Array<Partial<IBundleGroupItemAttr>>;
  code: string;
  max: number;
  min: number;
  name: string;
  prices: IBundleGroupItemPrice | IBundleGroupItemPrice[];
  quantity: number;
  type: BundleGroupItemType;
}

export default IBundleGroupItem;
