import {
  IRedeemVoucherFailureAction,
  IVoucherSetErrors,
  REDEEM_VOUCHER_FAILURE_ACTION,
  REDEEM_VOUCHER_SUCCESS_ACTION,
  VOUCHER_SET_ERRORS,
} from 'src/actions/bundleActions';
import {
  CLEAR_VOUCHER_DATA_ACTION,
  CONTINUE_WITH_VOUCHER_ACTION,
  IContinueWithVoucherAction,
  IValidateVoucherCodeFailureAction,
  IValidateVoucherCodeSuccessAction,
  IVoucherCodeInputAction,
  VALIDATE_VOUCHER_CODE_FAILURE_ACTION,
  VALIDATE_VOUCHER_CODE_SUCCESS_ACTION,
  VOUCHER_CODE_INPUT_ACTION,
  VOUCHER_DELETE_ACTION,
  VOUCHER_RESET_ACTION,
  VoucherActions,
} from 'src/actions/voucherActions';
import parseVoucher from 'src/lib/parseVoucher';
import IVoucherState from 'src/types/IVoucherState';

export const initialState: IVoucherState = {
  validBackendRegions: [],
  voucher: {
    extensionDays: undefined,
    grantProUntil: undefined,
    partnerName: undefined,
    types: { pro: false },
    validated: undefined,
    voucherCode: undefined,
  },
};

function handleContinueWithVoucher(
  state: IVoucherState,
  action: IContinueWithVoucherAction,
): IVoucherState {
  return { ...state, voucher: action.voucher };
}

function handleVoucherCodeInput(
  state: IVoucherState,
  action: IVoucherCodeInputAction,
): IVoucherState {
  return {
    ...state,
    voucher: {
      types: { bundle: false, feature: false, pro: false },
      voucherCode: action.voucherCode,
    },
  };
}

function handleVoucherDelete(state: IVoucherState): IVoucherState {
  return {
    ...state,
    validBackendRegions: [],
    voucher: {
      types: {
        pro: false,
      },
    },
  };
}

function handleVoucherReset(state: IVoucherState): IVoucherState {
  const voucherCode = state.voucher.voucherCode;
  return {
    ...state,
    voucher: {
      types: {
        pro: false,
      },
      voucherCode,
    },
  };
}

function handleValidateVoucherCodeSuccess(
  state: IVoucherState,
  action: IValidateVoucherCodeSuccessAction,
): IVoucherState {
  return {
    ...state,
    voucher: parseVoucher(action.voucher, action.bundle),
  };
}

function handleValidateVoucherCodeFailure(
  state: IVoucherState,
  action: IValidateVoucherCodeFailureAction,
): IVoucherState {
  return {
    ...state,
    voucher: {
      errors: action.errors,
      types: { bundle: false, feature: false, pro: false },
      validated: false,
      voucherCode: action.voucherCode,
    },
  };
}

function handleRedeemVoucherSuccess(state: IVoucherState): IVoucherState {
  return { ...state, orderSuccessful: true };
}

function handleVoucherSetErrors(
  state: IVoucherState,
  action: IVoucherSetErrors,
): IVoucherState {
  return { ...state, errors: action.errors };
}

function handleRedeemVoucherFailure(
  state: IVoucherState,
  action: IRedeemVoucherFailureAction,
): IVoucherState {
  return { ...state, orderSuccessful: false, errors: action.errors };
}

function handleClearVoucherData(state: IVoucherState): IVoucherState {
  const types = { ...state.voucher.types };
  const extensionDays = state.voucher.extensionDays;
  const grantProUntil = state.voucher.grantProUntil;
  return {
    ...initialState,
    voucher: {
      ...state.voucher,
      extensionDays,
      partnerName: undefined,
      grantProUntil,
      types,
      validated: undefined,
      voucherCode: undefined,
    },
  };
}

function voucherReducer(
  state: IVoucherState = initialState,
  action: VoucherActions | { type: null } = { type: null },
) {
  switch (action.type) {
    case CONTINUE_WITH_VOUCHER_ACTION:
      return handleContinueWithVoucher(state, action);

    case VOUCHER_CODE_INPUT_ACTION:
      return handleVoucherCodeInput(state, action);

    case VALIDATE_VOUCHER_CODE_SUCCESS_ACTION:
      return handleValidateVoucherCodeSuccess(state, action);

    case VALIDATE_VOUCHER_CODE_FAILURE_ACTION:
      return handleValidateVoucherCodeFailure(state, action);

    case VOUCHER_SET_ERRORS:
      return handleVoucherSetErrors(state, action);

    case REDEEM_VOUCHER_FAILURE_ACTION:
      return handleRedeemVoucherFailure(state, action);

    case REDEEM_VOUCHER_SUCCESS_ACTION:
      return handleRedeemVoucherSuccess(state);

    case VOUCHER_DELETE_ACTION:
      return handleVoucherDelete(state);

    case VOUCHER_RESET_ACTION:
      return handleVoucherReset(state);

    case CLEAR_VOUCHER_DATA_ACTION:
      return handleClearVoucherData(state);
  }
  return state;
}

export default voucherReducer;
