import { createSelector } from 'reselect';
import { PARAM_BUNDLE_SLUG } from 'src/constants/bundleParameters';
import { LANGUAGE, LANGUAGE_EN } from 'src/constants/languages';
import getBundleParameter from 'src/lib/getBundleParameter';
import IBundle from 'src/types/IBundle';
import IStoreState from 'src/types/IStoreState';
import { getBundleDynamic } from './bundleSelectors';

function getDefaultTranslations(
  state: IStoreState,
): Record<string, string> | undefined {
  if (state.translation.language === LANGUAGE_EN) {
    return state.translation.translations;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return require('../translations/en');
  }
}

function getTranslations(
  state: IStoreState,
): Record<string, string> | undefined {
  return state.translation.translations;
}

export function getLanguage(state: IStoreState): LANGUAGE | undefined {
  return state.translation.language;
}

function getTranslatedKey(
  translations: Record<string, any>,
  options: {
    key: string;
    payor?: string;
  },
): string | undefined {
  const version = 2;

  const keyPriorityOrder = [
    // key with versioned 2 and with payor
    options.payor && translations[`${options.key}.${options.payor}.${version}`],
    // key with payor
    options.payor && translations[`${options.key}.${options.payor}`],
    // key with version 2
    translations[`${options.key}.${version}`],
    // just key
    translations[options.key],
  ];

  for (const key of keyPriorityOrder) {
    if (key) {
      return key;
    }
  }

  return undefined;
}

export function getTranslation(
  key: string,
  variables?: Record<string, any>,
  ignoreIfEmpty = false,
  payor?: string,
) {
  return createSelector(
    [getTranslations, getDefaultTranslations, getLanguage, getBundleDynamic],
    (
      translations: Record<string, string> | undefined,
      defaultTranslations: Record<string, string> | undefined,
      language: LANGUAGE | undefined,
      bundle: IBundle | undefined,
    ): string | undefined => {
      const slug = bundle && getBundleParameter(PARAM_BUNDLE_SLUG, bundle);
      const usePayor = payor ?? slug;

      let translated: string | undefined;
      if (translations) {
        translated = getTranslatedKey(translations, { key, payor: usePayor });
      }
      if (
        !translated &&
        !ignoreIfEmpty &&
        language !== LANGUAGE_EN &&
        defaultTranslations
      ) {
        translated = getTranslatedKey(defaultTranslations, {
          key,
          payor: usePayor,
        });
      }
      if (!translated && !ignoreIfEmpty) {
        translated = key;
      }
      if (translated && variables) {
        Object.keys(variables).forEach(variable => {
          const regEx = new RegExp(`:${variable}`, 'g');
          translated = translated?.replace(regEx, `${variables[variable]}`);
        });
      }

      return translated;
    },
  );
}
