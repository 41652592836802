import {
  COUNTRY_AT,
  COUNTRY_DE,
  COUNTRY_PL,
  COUNTRY_US,
  COUNTRY_ZA,
  SUPPORTED_COUNTRY,
} from 'src/constants/countries';

function validateUsPostalCode(postalCode: string): boolean {
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(postalCode);
}

function validateDePostalCode(postalCode: string): boolean {
  return /^\d{5}$/.test(postalCode);
}

function validateAtPostalCode(postalCode: string): boolean {
  return /^\d{4}$/.test(postalCode);
}

function validatePlPostalCode(postalCode: string): boolean {
  return /^\d{2}-\d{3}$/.test(postalCode);
}

function validateZaPostalCode(postalCode: string): boolean {
  return /^\d{4}$/.test(postalCode);
}

function validatePostalCode(
  country: SUPPORTED_COUNTRY,
  postalCode: string,
): boolean {
  switch (country) {
    case COUNTRY_US:
      return validateUsPostalCode(postalCode);

    case COUNTRY_DE:
      return validateDePostalCode(postalCode);

    case COUNTRY_AT:
      return validateAtPostalCode(postalCode);

    case COUNTRY_PL:
      return validatePlPostalCode(postalCode);

    case COUNTRY_ZA:
      return validateZaPostalCode(postalCode);
  }
  return false;
}

export default validatePostalCode;
