import { getTranslation } from 'src/selectors/translationSelectors';
import IBundle from 'src/types/IBundle';
import IBundleGroup from 'src/types/IBundleGroup';
import IStoreState from 'src/types/IStoreState';
import modelBundleGroup from './models/modelBundleGroup';

function getProductName(
  state: IStoreState,
  bundleGroup?: IBundleGroup,
): string | undefined {
  let productName;
  const groupModel = bundleGroup && modelBundleGroup(bundleGroup);
  if (groupModel?.isCoachOnly()) {
    const key = 'productName.coach';
    productName = getTranslation(key, {}, true)(state);
  } else if (bundleGroup) {
    const key = 'productName.bundle';
    productName = getTranslation(key, {}, true)(state);
  }

  return productName;
}

function getFlowTitle(
  state: IStoreState,
  bundle?: IBundle,
  productName?: string,
): string | undefined {
  let flowTitle;
  if (bundle?.payor && productName) {
    const key = 'pageTitle.flowTitle.withProductAndPayor';
    const payorName = bundle.payor.name;
    flowTitle = getTranslation(key, { payorName, productName }, true)(state);
  } else if (bundle?.payor) {
    const key = 'pageTitle.flowTitle.withPayor';
    const payorName = bundle.payor.name;
    flowTitle = getTranslation(key, { payorName }, true)(state);
  } else {
    flowTitle = productName;
  }

  return flowTitle;
}

function getStepTitle(
  state: IStoreState,
  pageName: string,
): string | undefined {
  return (
    getTranslation(`${pageName}.pageName`, {}, true)(state) ??
    getTranslation(`${pageName}.stepName`, {}, true)(state)
  );
}

interface IGetPageTitleOptions {
  bundle?: IBundle;
  bundleGroup?: IBundleGroup;
  pageName: string;
}

function getPageTitle(
  state: IStoreState,
  options: IGetPageTitleOptions,
): string {
  const { bundle, bundleGroup, pageName } = options;
  const stepTitle = getStepTitle(state, pageName);
  const productName = getProductName(state, bundleGroup);
  const flowTitle = getFlowTitle(state, bundle, productName);

  if (stepTitle && flowTitle) {
    const key = 'pageTitle.withStepAndFlow';
    return getTranslation(key, { flowTitle, stepTitle })(state)!;
  } else if (stepTitle) {
    return getTranslation('pageTitle.withStep', { stepTitle })(state)!;
  } else if (flowTitle) {
    return getTranslation('pageTitle.withFlow', { flowTitle })(state)!;
  }
  return getTranslation('pageTitle')(state)!;
}

export default getPageTitle;
