import {
  IOrderBundleSuccessAction,
  ORDER_BUNDLE_SUCCESS_ACTION,
} from 'src/actions/bundleActions';
import {
  CONFIRM_EMAIL_FAILURE_ACTION,
  CONFIRM_EMAIL_SUCCESS_ACTION,
  ConfirmEmailAction,
  IConfirmEmailFailureAction,
  IConfirmEmailSuccessAction,
} from 'src/actions/confirmEmailActions';
import { CHANGE_EMAIL_ACTION } from 'src/actions/loginActions';
import { ILogoutAction, LOGOUT_ACTION } from 'src/actions/logoutActions';
import {
  IMissingConsentsSuccessAction,
  IOptionalConsentsSuccessAction,
  IRegisterFailureAction,
  IRegisterFormAction,
  IRemoveRegisterErrorAction,
  IToggleConsentAction,
  MISSING_CONSENTS_SUCCESS_ACTION,
  REGISTER_FAILURE_ACTION,
  REGISTER_FORM_ACTION,
  REGISTER_SUCCESS_ACTION,
  RegisterAction,
  REMOVE_REGISTER_ERROR_ACTION,
  SHOW_REGISTER_ACTION,
  TOGGLE_CONSENT_ACTION,
  OPTIONAL_CONSENTS_SUCCESS_ACTION,
} from 'src/actions/registerActions';
import {
  TRIGGER_EMAIL_VERIFICATION_SUCCESS_ACTION,
  TriggerEmailVerificationAction,
} from 'src/actions/triggerEmailVerificationActions';
import {
  IUserExistsSuccessAction,
  USER_EXISTS_SUCCESS_ACTION,
} from 'src/actions/userExistsActions';
import getChangedFormFields from 'src/lib/getChangedFormFields';
import removeError from 'src/lib/removeError';
import removeErrorsForFields from 'src/lib/removeErrorsForFields';
import IRegisterForm from 'src/types/IRegisterForm';
import IRegisterState from 'src/types/IRegisterState';

const initialForm: IRegisterForm = {
  email: '',
  password: '',
  password2: '',
};

const initialStatus = {
  emailVerificationTriggered: false,
  emailVerified: false,
  formValid: false,
  registered: false,
  showRegistration: false,
};

export const initialState: IRegisterState = {
  consents: {
    mandatory: {
      consentRequirements: {
        purpose: '',
        requiredDocuments: [],
        optionalDocuments: [],
      },
    },
    optional: {
      consentRequirements: {
        purpose: '',
        requiredDocuments: [],
        optionalDocuments: [],
      },
    },
  },
  errors: [],
  form: { ...initialForm },
  status: { ...initialStatus },
  successful: false,
};

function handleRegisterForm(
  state: IRegisterState,
  action: IRegisterFormAction,
): IRegisterState {
  const changedField = getChangedFormFields(state.form, action.form);
  if (
    changedField[0] === 'password' &&
    state.errors[0] &&
    state.errors[0].field === 'password2' &&
    state.errors[0].message !== 'MIN_LENGTH'
  ) {
    changedField.push('password2');
  }
  const errors = removeErrorsForFields(state.errors, changedField);
  return {
    ...state,
    errors: [...errors],
    form: { ...action.form },
  };
}

function handleRegisterFailure(
  state: IRegisterState,
  action: IRegisterFailureAction,
): IRegisterState {
  return { ...state, errors: action.errors };
}

function handleRegisterSuccess(state: IRegisterState): IRegisterState {
  return {
    ...state,
    errors: [],
    status: { ...state.status, registered: true },
  };
}

function handleTriggerEmailVerificationSuccess(
  state: IRegisterState,
): IRegisterState {
  return {
    ...state,
    errors: [],
    status: {
      ...state.status,
      emailVerificationTriggered: true,
      showRegistration: false,
    },
  };
}

function handleConfirmEmailSuccess(
  state: IRegisterState,
  action: IConfirmEmailSuccessAction,
): IRegisterState {
  return {
    ...state,
    errors: [],
    registrationToken: action.registrationToken,
    status: { ...state.status, emailVerified: true },
  };
}

function handleConfirmEmailFailure(
  state: IRegisterState,
  action: IConfirmEmailFailureAction,
): IRegisterState {
  return { ...state, errors: action.errors };
}

function handleMissingConsentsSuccess(
  state: IRegisterState,
  action: IMissingConsentsSuccessAction,
): IRegisterState {
  return {
    ...state,
    consents: { mandatory: action.consents, optional: state.consents.optional },
    errors: [],
  };
}

function handleOptionalConsentsSuccess(
  state: IRegisterState,
  action: IOptionalConsentsSuccessAction,
): IRegisterState {
  return {
    ...state,
    consents: { ...state.consents, optional: action.consents },
    errors: [],
  };
}

function handleChangeEmail(state: IRegisterState): IRegisterState {
  return {
    ...state,
    status: {
      ...state.status,
      emailVerificationTriggered: false,
      emailVerified: false,
    },
  };
}

function handleOrderBundleSuccess(state: IRegisterState): IRegisterState {
  return {
    ...state,
    errors: [],
  };
}

function handleUserExistsSuccess(
  state: IRegisterState,
  action: IUserExistsSuccessAction,
): IRegisterState {
  if (action.userExists) {
    return state;
  }
  return { ...state, form: { ...state.form, email: action.form.email } };
}

function handleToggleConsent(
  state: IRegisterState,
  action: IToggleConsentAction,
) {
  const errors = state.errors.filter(
    error =>
      error.field !== 'consents' ||
      !error.options ||
      !action.consents.includes(error.options.type),
  );
  return { ...state, errors: [...errors] };
}

function handleShowRegistration(state: IRegisterState): IRegisterState {
  return { ...state, status: { ...state.status, showRegistration: true } };
}

function handleRemoveRegisterError(
  state: IRegisterState,
  action: IRemoveRegisterErrorAction,
): IRegisterState {
  return {
    ...state,
    errors: removeError(state.errors, {
      field: action.field,
      message: action.message,
    }),
  };
}

function handleLogout(state: IRegisterState): IRegisterState {
  return {
    ...state,
    form: { ...initialForm },
    registrationToken: undefined,
    status: { ...initialStatus },
  };
}

const registerReducer = (
  state: IRegisterState = initialState,
  action:
    | RegisterAction
    | ConfirmEmailAction
    | TriggerEmailVerificationAction
    | IOrderBundleSuccessAction
    | IUserExistsSuccessAction
    | ILogoutAction
    | { type: null } = { type: null },
): IRegisterState => {
  switch (action.type) {
    case REGISTER_FORM_ACTION:
      return handleRegisterForm(state, action);

    case REGISTER_FAILURE_ACTION:
      return handleRegisterFailure(state, action);

    case REGISTER_SUCCESS_ACTION:
      return handleRegisterSuccess(state);

    case TRIGGER_EMAIL_VERIFICATION_SUCCESS_ACTION:
      return handleTriggerEmailVerificationSuccess(state);

    case CONFIRM_EMAIL_SUCCESS_ACTION:
      return handleConfirmEmailSuccess(state, action);

    case CONFIRM_EMAIL_FAILURE_ACTION:
      return handleConfirmEmailFailure(state, action);

    case MISSING_CONSENTS_SUCCESS_ACTION:
      return handleMissingConsentsSuccess(state, action);

    case OPTIONAL_CONSENTS_SUCCESS_ACTION:
      return handleOptionalConsentsSuccess(state, action);

    case CHANGE_EMAIL_ACTION:
      return handleChangeEmail(state);

    case ORDER_BUNDLE_SUCCESS_ACTION:
      return handleOrderBundleSuccess(state);

    case USER_EXISTS_SUCCESS_ACTION:
      return handleUserExistsSuccess(state, action);

    case TOGGLE_CONSENT_ACTION:
      return handleToggleConsent(state, action);

    case SHOW_REGISTER_ACTION:
      return handleShowRegistration(state);

    case REMOVE_REGISTER_ERROR_ACTION:
      return handleRemoveRegisterError(state, action);

    case LOGOUT_ACTION:
      return handleLogout(state);
  }

  return state;
};

export default registerReducer;
