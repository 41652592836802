import { LocationContext } from '@gatsbyjs/reach-router';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  continueAction,
  navigateToProduct,
  updateFlowNameAction,
} from 'src/actions/flowActions';
import { deleteVoucherAction } from 'src/actions/voucherActions';
import BundleGroupSelection from 'src/components/BundleGroupSelection/BundleGroupSelection';
import BundleProduct from 'src/components/BundleProduct/BundleProduct';
import HomePage from 'src/components/HomePage/HomePage';
import Loader from 'src/components/Loader/Loader';
import FLOW_NAME from 'src/constants/flowName';
import arrangeBundleGroupsByType from 'src/lib/arrangeBundleGroupsByType';
import browserOnly from 'src/lib/browserOnly';
import getLocaleFromPathname from 'src/lib/getLocaleFromPathname';
import useMemoizedCallback from 'src/lib/hooks/useMemoizedCallback';
import isBundleAvailable from 'src/lib/isBundleAvailable';
import {
  getBundleDynamic,
  getSelectedBundleGroup,
} from 'src/selectors/bundleSelectors';
import { isProductPageAccessible } from 'src/selectors/flowSelectors';
import { isLoading } from 'src/selectors/loadingSelectors';
import { getVoucher } from 'src/selectors/voucherSelectors';
import IBundle from 'src/types/IBundle';
import IBundleGroup from 'src/types/IBundleGroup';
import IStoreState from 'src/types/IStoreState';
import IVoucher from 'src/types/IVoucher';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import withLocation from '../withLocation/withLocation';

interface IOwnProps extends LocationContext {
  path?: string;
}

interface IStateProps {
  bundle?: IBundle;
  bundleGroup?: IBundleGroup;
  isAccessible?: boolean;
  loading: boolean;
  voucher?: IVoucher;
}

interface IDispatchProps {
  continueNextStep: () => void;
  deleteVoucher: () => Promise<any>;
  setFlowName: () => Promise<any>;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {
  payor?: string;
}

export function ProductPage(props: Readonly<IProps>) {
  const {
    isAccessible,
    location,
    bundle,
    bundleGroup,
    deleteVoucher,
    continueNextStep,
    voucher,
    loading,
    payor,
  } = props;
  const pathname = React.useMemo(() => location.pathname, [location]);
  const documentIsDefined = React.useMemo(
    () => browserOnly(() => true, false),
    [],
  );

  const onDone = useMemoizedCallback(() => {
    continueNextStep();
  }, []);

  const [language, country] = React.useMemo(
    () => getLocaleFromPathname(pathname),
    [pathname],
  );

  const showGroupSelection: string[] = React.useMemo(
    () =>
      (bundle?.groups &&
        !bundleGroup &&
        arrangeBundleGroupsByType(bundle.groups)) ||
      [],
    [bundle, bundleGroup],
  );

  React.useEffect(() => {
    if (
      (voucher?.validated && bundle && voucher.bundleCode !== bundle.code) ||
      voucher?.types.pro
    ) {
      deleteVoucher();
    }
  }, [voucher, bundle]);

  React.useEffect(() => {
    props.setFlowName();
  }, []);

  if (documentIsDefined && !isAccessible) {
    deleteVoucher();
    return null;
  }

  if (documentIsDefined && loading) {
    return <Loader />;
  }

  if (!payor && language && !country) {
    return <HomePage />;
  }

  if (!bundle) {
    return <NotFoundPage delay={true} />;
  }

  if (showGroupSelection.length) {
    return <BundleGroupSelection bundle={bundle} />;
  }

  const bundleIsAvailable = isBundleAvailable(bundle);
  if (!bundleGroup || !bundleIsAvailable) {
    return <NotFoundPage />;
  }

  return <BundleProduct bundle={bundle} group={bundleGroup} onDone={onDone} />;
}

export function mapStateToProps(state: IStoreState): IStateProps {
  return {
    bundle: getBundleDynamic(state),
    bundleGroup: getSelectedBundleGroup(state),
    isAccessible: isProductPageAccessible(state),
    loading: isLoading('getBundleConfigurations')(state),
    voucher: getVoucher(state),
  };
}

export function mapDispatchToProps(dispatch: any): IDispatchProps {
  return {
    continueNextStep: () => dispatch(continueAction()),
    deleteVoucher: async () => {
      dispatch(deleteVoucherAction());
      dispatch(navigateToProduct());
    },
    setFlowName: () => dispatch(updateFlowNameAction(FLOW_NAME.orderBundle)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocation(ProductPage));
