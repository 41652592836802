import { countryDefaultBundleCode } from 'src/config/nonStandardBundleOverride/bundleOverrides';
import { BACKEND_EU, BACKEND_US } from 'src/constants/backends';
import IBundle from 'src/types/IBundle';
import backendApi from './apiClient/backendApi';
import filterBackendRegionsByCountry from './filterBackendRegionsByCountry';
import { parseBundle } from './parseBundle';

async function loadBundle({ slug = '', country = '' }): Promise<IBundle> {
  const countryDefaultBundle =
    country && !slug && countryDefaultBundleCode[country];

  if (!countryDefaultBundle && (!slug || !country)) {
    return Promise.reject(
      new Error('config "slug" or "country" are undefined'),
    );
  }

  let path = `slug/${slug}?returnInactive=false`;
  if (countryDefaultBundle) {
    path = `${countryDefaultBundle}`;
  }

  const regions = filterBackendRegionsByCountry(
    [BACKEND_EU, BACKEND_US],
    country,
    true,
  );

  let error = null;

  for (const backend of regions) {
    try {
      const bundleConfig = await backendApi.get(
        `/commerce/config/bundle/${path}`,
        {
          backend,
        },
      );
      const bundle = parseBundle(bundleConfig.data.bundle);
      return Promise.resolve(bundle);
    } catch (e) {
      error = e;
    }
  }

  return Promise.reject(error);
}

export default loadBundle;
