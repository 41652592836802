import { DEFAULT_STRIPS_COUNT } from 'src/constants/bundles';
import IBundleGroupItem from 'src/types/IBundleGroupItem';

function getCountFromStripsItem(item: IBundleGroupItem): number {
  const matchCountInName = /\((\d{1,3}) (Stück|pieces)\)/.exec(item.name);
  const countInName = matchCountInName?.[1];
  const count = countInName ? parseInt(countInName, 10) : 0;

  return count > 0 ? count * item.quantity : DEFAULT_STRIPS_COUNT;
}

export default getCountFromStripsItem;
