import * as React from 'react';
import css from './VisuallyHidden.module.scss';

export interface IProps {
  children: React.ReactNode;
}

function VisuallyHidden(props: Readonly<IProps>) {
  return <span className={css.hidden}>{props.children}</span>;
}

export default VisuallyHidden;
