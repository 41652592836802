import { createSelector } from 'reselect';
import { countryDefaultBundleCode } from 'src/config/nonStandardBundleOverride/bundleOverrides';
import parseBundleSteps from 'src/config/parseBundleSteps';
import {
  NON_BUNDLE_ACCOUNT_PAGE,
  NON_BUNDLE_OVERVIEW_PAGE,
} from 'src/config/REMOTE/NON_BUNDLE_FLOWS/nonBundleSteps';
import { LANGUAGE } from 'src/constants/languages';
import bundleRequiresVoucherCode from 'src/lib/bundleRequiresVoucherCode';
import getLocaleFromPathname from 'src/lib/getLocaleFromPathname';
import IAuthUser from 'src/types/IAuthUser';
import IBundle from 'src/types/IBundle';
import IBundleGroup from 'src/types/IBundleGroup';

import INonBundleFlowConfig from 'src/types/INonBundleFlowConfig';
import IShipment from 'src/types/IShipment';
import IStep from 'src/types/IStep';
import IStoreState from 'src/types/IStoreState';
import IVoucher from 'src/types/IVoucher';
import getPathname from '../server/lib/getPathname';
import {
  getBundleDynamic,
  getBundleGroupDynamic,
  getCartBundle,
  getCartBundleGroup,
  getNonBundleFlowConfig,
  getSelectedBundleGroup,
} from './bundleSelectors';
import { getUser, isAppUser, isAuthenticated } from './loginSelectors';
import { getShipment } from './shipmentSelectors';
import { getProVoucher, getVoucher } from './voucherSelectors';

export type isAccessibleStepFunc = (state: IStoreState) => boolean;

function getCurrentStep(state: IStoreState): number {
  return state.flow.currentStep;
}

function getCompletedSteps(state: IStoreState): number[] {
  return state.flow.completedSteps;
}

export function getCountry(state: IStoreState): string | undefined {
  const [, country] = getLocaleFromPathname(getPathname(state));
  return country;
}

function getLanguage(state: IStoreState): LANGUAGE | undefined {
  return state.translation.language;
}

export const getSteps = createSelector(
  [
    isAuthenticated,
    isAppUser,
    getVoucher,
    getBundleDynamic,
    getBundleGroupDynamic,
    getNonBundleFlowConfig,
  ],
  (
    authenticated: boolean,
    appUser,
    voucher?: IVoucher,
    bundle?: IBundle,
    bundleGroup?: IBundleGroup,
    nonBundleFlowConfig?: INonBundleFlowConfig,
  ) => {
    if (nonBundleFlowConfig) {
      let index = 0;
      return nonBundleFlowConfig.STEPS.map(stepConfig => {
        if (appUser && stepConfig === NON_BUNDLE_ACCOUNT_PAGE) {
          return false;
        }
        index++;

        const success = stepConfig === NON_BUNDLE_OVERVIEW_PAGE;

        return {
          footerLegalNotice: undefined,
          isAccessible: () => true,
          name: stepConfig.name,
          route: stepConfig.route,
          step: index,
          uiStep: index,
          success,
          visible: stepConfig.visible,
        };
      }).filter(e => e) as IStep[];
    }

    return parseBundleSteps({
      appUser,
      authenticated,
      bundle,
      bundleGroup,
      voucher,
    });
  },
);

export const getRemainingSteps = createSelector(
  [getCurrentStep, getSteps],
  (currentStep: number, enabledSteps: IStep[]) => {
    const remainingSteps = enabledSteps
      .filter(step => step.uiStep > 0)
      .filter(step => step.success === false);
    return remainingSteps.length - currentStep + 1;
  },
);

export const getActiveStep = createSelector(
  [getCurrentStep, getSteps],
  (currentStep: number, enabledSteps: IStep[]) => {
    const filteredSteps = enabledSteps.filter(
      step => step.step === currentStep,
    );
    return filteredSteps[0];
  },
);

export const getActiveStepUrl = createSelector(
  [
    getActiveStep,
    getBundleDynamic,
    getCountry,
    getLanguage,
    getNonBundleFlowConfig,
    getVoucher,
    getBundleGroupDynamic,
  ],
  (
    activeStep,
    bundle,
    country,
    language,
    nonBundleFlowConfig,
    voucher,
    bundleGroup,
  ) =>
    activeStep
      ? activeStep.route.getPath({
          bundle,
          country,
          language,
          nonBundleFlowConfig,
          voucher,
          bundleGroup,
        })
      : undefined,
);

export const isCompletedStep = (step: number) =>
  createSelector(
    [getCurrentStep, getCompletedSteps],
    (currentStep: number, accessibleSteps: number[]) =>
      currentStep >= step || accessibleSteps.includes(step),
  );

export const hasShipmentStep = createSelector(
  [getSteps],
  (enabledSteps: IStep[]): boolean =>
    enabledSteps.find(
      step =>
        step.name === 'shipment.stepName' || step.name === 'contact.stepName',
    ) !== undefined,
);

export const isProductPageAccessible: isAccessibleStepFunc = createSelector(
  [getVoucher],
  (voucher: IVoucher): boolean => {
    /* istanbul ignore else */
    if (voucher.types.bundle && !voucher.bundle) {
      return false;
    }

    return true;
  },
);

export const isProVoucherPageAccessible: isAccessibleStepFunc = createSelector(
  [getVoucher],
  (voucher: IVoucher): boolean =>
    (voucher.validated && voucher.types.pro) || false,
);

export const isShipmentPageAccessible: isAccessibleStepFunc = createSelector(
  [hasShipmentStep, getBundleDynamic, getVoucher],
  (shipmentStep: boolean, bundle?: IBundle, voucher?: IVoucher): boolean => {
    if (!shipmentStep) {
      return false;
    }
    if (bundle && bundleRequiresVoucherCode(bundle) && !voucher?.validated) {
      return false;
    }
    return Boolean(bundle);
  },
);

export const isAccountPageAccessible: isAccessibleStepFunc = createSelector(
  [hasShipmentStep, getProVoucher, getBundleDynamic, getShipment, getVoucher],
  (
    shipmentStep: boolean,
    proVoucher: boolean,
    bundle?: IBundle,
    shipment?: IShipment,
    voucher?: IVoucher,
  ): boolean => {
    if (proVoucher) {
      if (
        !voucher?.validated ||
        (voucher.errors && voucher.errors.length > 0)
      ) {
        return false;
      }
      return true;
    }

    if (bundle && bundleRequiresVoucherCode(bundle) && !voucher?.validated) {
      return false;
    }

    return Boolean(bundle && (!shipmentStep || shipment !== undefined));
  },
);

export const isOverviewPageAccessible: isAccessibleStepFunc = createSelector(
  [hasShipmentStep, getUser, getCartBundle, getShipment, getVoucher],
  (
    shipmentStep: boolean,
    user: IAuthUser,
    cartBundle?: IBundle,
    shipment?: IShipment,
    voucher?: IVoucher,
  ): boolean => {
    if (!cartBundle) {
      return false;
    }

    if (bundleRequiresVoucherCode(cartBundle) && !voucher?.validated) {
      return false;
    }

    if (!user?.authenticationToken) {
      return false;
    }

    return Boolean(
      (!shipmentStep || shipment !== undefined) && user !== undefined,
    );
  },
);

function getSource(state: IStoreState): string {
  return state.flow.source;
}

// istanbul ignore next
export const isSuccessPageAccessible = () => true;

export const isApp = createSelector([getSource], (source: string) => {
  switch (source) {
    case 'app-ios':
    case 'app-android':
    case 'app':
      return true;
  }
  return false;
});

export const cartMatchesSelectedBundleGroup = createSelector(
  [
    getCartBundleGroup,
    getSelectedBundleGroup,
    getVoucher,
    getBundleDynamic,
    getPathname,
  ],
  (cartBundleGroup, selectedBundleGroup, voucher, bundle, pathname) => {
    if (voucher?.validated) {
      return true;
    }

    const [, country] = getLocaleFromPathname(pathname);

    // check for country default bundle
    if (
      bundle &&
      country &&
      countryDefaultBundleCode[country] === bundle.code
    ) {
      return true;
    }

    if (!selectedBundleGroup || !cartBundleGroup) {
      return false;
    }
    return selectedBundleGroup.code === cartBundleGroup.code;
  },
);
