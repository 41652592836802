import * as React from 'react';
import Input from 'src/components/Input/Input';
import { IFieldMask } from 'src/lib/FieldMask';
import { TEXT_INPUT_TYPE } from 'src/types/IInputType';

export interface IProps {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  formName: string;
  inputClassName?: string;
  name?: string;
  pattern?: string;
  placeholder?: string;
  onBlur?: (target: HTMLInputElement) => void;
  onChange?: (target: HTMLInputElement) => void;
  onFocus?: (target: HTMLInputElement) => void;
  onRef?: (ref: HTMLInputElement) => void;
  success?: boolean;
  type?: TEXT_INPUT_TYPE;
  autocomplete?: string;
  value?: string;
  verified?: boolean;
  warning?: boolean;
  maxLength?: number;
  focusOnError?: boolean;
  required?: boolean;
  mask?: IFieldMask[];
}

interface IState {
  inputRef?: HTMLInputElement;
}

class TextInput extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      inputRef: undefined,
    };
  }

  public componentDidUpdate(): void {
    if (this.props.focusOnError && this.props.error && this.state.inputRef) {
      this.state.inputRef.focus();
    }
  }

  public render() {
    const {
      className,
      disabled,
      error,
      formName,
      inputClassName,
      maxLength,
      name,
      onBlur,
      onChange,
      onFocus,
      pattern,
      placeholder,
      required,
      success,
      type,
      autocomplete,
      value,
      verified,
      warning,
      mask,
    } = this.props;

    return (
      <Input
        className={className}
        disabled={disabled ?? false}
        error={error}
        formName={formName}
        inputClassName={inputClassName}
        name={name ?? ''}
        pattern={pattern}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onRef={this.setRef}
        success={success}
        autocomplete={autocomplete}
        type={type ?? 'text'}
        maxLength={maxLength}
        value={value}
        verified={verified}
        warning={warning}
        required={required}
        mask={mask}
      />
    );
  }

  private readonly setRef = (ref: HTMLInputElement) => {
    this.setState({
      inputRef: ref,
    });

    if (this.props.onRef) {
      this.props.onRef(ref);
    }
  };
}

export default TextInput;
