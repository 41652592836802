import * as React from 'react';
import { PARAM_BUNDLE_LOGO_URL } from 'src/constants/bundleParameters';
import getBundleParameter from 'src/lib/getBundleParameter';
import IBundle from 'src/types/IBundle';

export interface IProps {
  className?: string;
  bundle?: IBundle;
}

export function PayorLogo({
  className,
  bundle,
}: Readonly<IProps>): React.ReactElement | null {
  const bundleUrl = getBundleParameter(PARAM_BUNDLE_LOGO_URL, bundle);
  if (!bundle || !bundleUrl || !bundle.payor) {
    return null;
  }

  return (
    <img
      alt={bundle.name}
      className={className}
      id={`payor-logo-${bundle.payor.code}`}
      src={bundleUrl}
    />
  );
}
