import IAuthUser from 'src/types/IAuthUser';
import IFormNotice from 'src/types/IFormNotice';
import ILoginForm from 'src/types/ILoginForm';
import IUser from 'src/types/IUser';

export const LOGIN_ACTION = 'LOGIN_ACTION';
export type LOGIN_ACTION = typeof LOGIN_ACTION;

export const LOGIN_BEGIN_ACTION = 'LOGIN_BEGIN_ACTION';
export type LOGIN_BEGIN_ACTION = typeof LOGIN_BEGIN_ACTION;

export const LOGIN_SUCCESS_ACTION = 'LOGIN_SUCCESS_ACTION';
export type LOGIN_SUCCESS_ACTION = typeof LOGIN_SUCCESS_ACTION;

export const LOGIN_FAILURE_ACTION = 'LOGIN_FAILURE_ACTION';
export type LOGIN_FAILURE_ACTION = typeof LOGIN_FAILURE_ACTION;

export const LOGIN_REMOVE_ERRORS = 'LOGIN_REMOVE_ERRORS';
export type LOGIN_REMOVE_ERRORS = typeof LOGIN_REMOVE_ERRORS;

export const CHANGE_EMAIL_ACTION = 'CHANGE_EMAIL_ACTION';
export type CHANGE_EMAIL_ACTION = typeof CHANGE_EMAIL_ACTION;

export const LOGIN_FORM_ACTION = 'LOGIN_FORM_ACTION';
export type LOGIN_FORM_ACTION = typeof LOGIN_FORM_ACTION;

export const REMOVE_LOGIN_ERROR_ACTION = 'REMOVE_LOGIN_ERROR_ACTION';
export type REMOVE_LOGIN_ERROR_ACTION = typeof REMOVE_LOGIN_ERROR_ACTION;

export const SKIP_RDCP_AUTH_ACTION = 'SKIP_RDCP_AUTH_ACTION';
export type SKIP_RDCP_AUTH_ACTION = typeof SKIP_RDCP_AUTH_ACTION;

export const SET_ACCESS_TOKEN_ACTION = 'SET_ACCESS_TOKEN_ACTION';
export type SET_ACCESS_TOKEN_ACTION = typeof SET_ACCESS_TOKEN_ACTION;

export const RDCP_AUTH_SUCCESS = 'RDCP_AUTH_SUCCESS';
export type RDCP_AUTH_SUCCESS = typeof RDCP_AUTH_SUCCESS;

export interface IRDCPAuth {
  access_token: string;
  refresh_token: string;
}

export interface IRemoveLoginErrorAction {
  type: REMOVE_LOGIN_ERROR_ACTION;
  field?: string;
  message?: string;
}

export interface ILoginAction {
  type: LOGIN_ACTION;
  user: IUser;
}

export interface ILoginBeginAction {
  type: LOGIN_BEGIN_ACTION;
}

export interface ILoginSuccessAction {
  type: LOGIN_SUCCESS_ACTION;
  user: IAuthUser;
}

export interface ILoginFailureAction {
  type: LOGIN_FAILURE_ACTION;
  errors: IFormNotice[];
}

export interface IChangeEmailAction {
  type: CHANGE_EMAIL_ACTION;
}

export interface ILoginFormAction {
  type: LOGIN_FORM_ACTION;
  form: ILoginForm;
}

export interface ISetAccessTokenAction {
  type: SET_ACCESS_TOKEN_ACTION;
  payload: {
    accessToken: string;
    refreshToken?: string;
  };
}

export interface IRocheAuthSuccess {
  type: RDCP_AUTH_SUCCESS;
  RDCPAuth: IRDCPAuth;
}

export type LoginAction =
  | IRemoveLoginErrorAction
  | ILoginAction
  | ILoginBeginAction
  | ILoginSuccessAction
  | ILoginFailureAction
  | IChangeEmailAction
  | ILoginFormAction
  | IRocheAuthSuccess
  | ISetAccessTokenAction;

export function loginBeginAction(): ILoginBeginAction {
  return { type: LOGIN_BEGIN_ACTION };
}

export function loginSuccessAction(user: IAuthUser): ILoginSuccessAction {
  return { type: LOGIN_SUCCESS_ACTION, user };
}

export function loginFailureAction(errors: IFormNotice[]): ILoginFailureAction {
  return { type: LOGIN_FAILURE_ACTION, errors };
}

export function changeEmailAction(): IChangeEmailAction {
  return { type: CHANGE_EMAIL_ACTION };
}

export function loginFormAction(form: ILoginForm): ILoginFormAction {
  return { type: LOGIN_FORM_ACTION, form };
}

export function setAccessToken(
  newAccessToken: string,
  newRefreshToken?: string,
): ISetAccessTokenAction {
  return {
    type: SET_ACCESS_TOKEN_ACTION,
    payload: {
      accessToken: newAccessToken,
      refreshToken: newRefreshToken,
    },
  };
}

export function removeLoginErrorAction({
  field,
}: {
  field?: string;
}): IRemoveLoginErrorAction {
  return { type: REMOVE_LOGIN_ERROR_ACTION, field };
}

export function RDCPAuthSuccess(RDCPAuth: IRDCPAuth) {
  return { type: RDCP_AUTH_SUCCESS, RDCPAuth };
}
