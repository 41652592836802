import {
  PROPERTY_CARD_GROUP_ID,
  PROPERTY_CARD_MEMBER_ID,
  PROPERTY_EMPLOYER,
  PROPERTY_INSURANCE_NUMBER,
  PROPERTY_IP,
  PROPERTY_KEY,
  PROPERTY_LANGUAGE,
  PROPERTY_MEMBER_CLASS,
  PROPERTY_PHONE,
  PROPERTY_VOUCHER,
} from 'src/constants/bundleProperties';
import { DEFAULT_IP_ADDRESS } from 'src/constants/defaults';
import cleanString from '../cleanString';
import { IOrderParams } from './BackendClient';

const getMemberIdValue = (data: IOrderParams): string | null => {
  if (!data.insuranceCard && data.insuranceNumber) {
    return data.insuranceNumber;
  }

  return cleanString(data.insuranceCard?.memberId) || null;
};

const getOrderPropertyValue = (
  key: PROPERTY_KEY,
  data: IOrderParams,
): string | number | null => {
  switch (key) {
    case PROPERTY_PHONE:
      return data.shipment ? data.shipment.phone : '01234567890123456789';
    case PROPERTY_IP:
      return DEFAULT_IP_ADDRESS;
    case PROPERTY_LANGUAGE:
      return data.language.toUpperCase();
    case PROPERTY_VOUCHER:
      return data.voucher?.voucherCode || null;
    case PROPERTY_MEMBER_CLASS:
      return (data.shipment?.memberClass as string) || null;
    case PROPERTY_EMPLOYER:
      return (data.shipment?.employer as string) || null;
    case PROPERTY_CARD_GROUP_ID:
      return cleanString(data.insuranceCard?.groupNumber) || null;
    case PROPERTY_CARD_MEMBER_ID:
      return getMemberIdValue(data);
    case PROPERTY_INSURANCE_NUMBER:
      return data.insuranceNumber || null;

    default:
      // eslint-disable-next-line no-console
      console.log(`Missing property transformer "${key}"`);
      return null;
  }
};

export default getOrderPropertyValue;
