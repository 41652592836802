/* istanbul ignore file */

import { initialState as bundleInitialState } from 'src/reducers/bundleReducer';
import { initialState as eligibilityCheckInitialState } from 'src/reducers/eligibilityCheckReducer';
import { initialState as flowInitialState } from 'src/reducers/flowReducer';
import { initialState as formInitialState } from 'src/reducers/formReducer';
import { initialState as insuranceNumberInitialState } from 'src/reducers/insuranceNumberReducer';
import { initialState as loadingInitialState } from 'src/reducers/loadingReducer';
import { initialState as loginInitialState } from 'src/reducers/loginReducer';
import { initialState as notificationInitialState } from 'src/reducers/notificationReducer';
import { initialState as registerInitialState } from 'src/reducers/registerReducer';
import { initialState as shipmentInitialState } from 'src/reducers/shipmentReducer';
import { initialState as translationInitialState } from 'src/reducers/translationReducer';
import { initialState as userInitialState } from 'src/reducers/userReducer';
import { initialState as voucherInitialState } from 'src/reducers/voucherReducer';
import IStoreState from 'src/types/IStoreState';

const initialState: IStoreState = {
  bundle: bundleInitialState,
  eligibilityCheck: eligibilityCheckInitialState,
  flow: flowInitialState,
  form: formInitialState,
  insuranceNumber: insuranceNumberInitialState,
  loading: loadingInitialState,
  login: loginInitialState,
  notification: notificationInitialState,
  register: registerInitialState,
  shipment: shipmentInitialState,
  translation: translationInitialState,
  user: userInitialState,
  voucher: voucherInitialState,
};

export default initialState;
