import { PARAMETER_KEY } from 'src/constants/bundleParameters';
import IBundle from 'src/types/IBundle';
import { parseBundleParameters } from './parseBundleParameters';

const getBundleParameter = (parameter: PARAMETER_KEY, bundle?: IBundle) => {
  const params = parseBundleParameters(bundle);

  if (params[parameter] === undefined) {
    return '';
  }

  return params[parameter];
};

export default getBundleParameter;
