import { LocationContext } from '@gatsbyjs/reach-router';
import classnames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import VisuallyHidden from 'src/components/VisuallyHidden/VisuallyHidden';
import {
  PARAM_BUNDLE_LOGO_URL,
  PARAM_BUNDLE_SLUG,
} from 'src/constants/bundleParameters';
import stepRoutes from 'src/constants/stepRoutes';
import getBundleParameter from 'src/lib/getBundleParameter';
import getPayorForPathname from 'src/lib/getPayorForPathname';
import { getStartPagePath } from 'src/lib/paths';
import { getBundleDynamic } from 'src/selectors/bundleSelectors';
import { getActiveStep, isApp } from 'src/selectors/flowSelectors';
import { getTranslation } from 'src/selectors/translationSelectors';
import IBundle from 'src/types/IBundle';
import IStep from 'src/types/IStep';
import IStoreState from 'src/types/IStoreState';
import { PayorLogo } from '../PayorLogo/PayorLogo';
import withLocation from '../withLocation/withLocation';
import css from './Header.module.scss';
import mySugrLogo from './mySugr-reg.svg';
import heart from './heart.svg';
import greyBar from './grey-bar.png';

interface IStateProps {
  activeStep?: IStep;
  app: boolean;
  bundle?: IBundle;
  translations: { [propName: string]: string | undefined };
}

export interface IProps extends IStateProps, LocationContext {}

const renderLogo = () => {
  return <img src={mySugrLogo} alt="mySugr" className={css.logoImg} />;
};

export function Header(props: IProps) {
  const pathname = props.location.pathname;
  const startPage = React.useMemo(() => getStartPagePath(pathname), [pathname]);

  if (props.app) {
    return null;
  }

  const isOverviewPage = props.activeStep
    ? props.activeStep.route.name === stepRoutes.overview.name
    : false;

  const bundleLogo: string = getBundleParameter(
    PARAM_BUNDLE_LOGO_URL,
    props.bundle,
  );
  const hasBundleLogo = Boolean(bundleLogo);

  const payor = props.bundle?.payor;
  const rochePayor = Boolean(payor?.code.includes('roche'));

  const slugInPathname = getPayorForPathname(pathname);
  const bundleMatchesSlug =
    !slugInPathname ||
    slugInPathname.toLowerCase() ===
      getBundleParameter(PARAM_BUNDLE_SLUG, props.bundle).toLowerCase();

  return (
    <header
      className={classnames(css.container, {
        [css.overviewPage]: isOverviewPage,
        [css.payorHeader]: hasBundleLogo,
      })}
    >
      <VisuallyHidden>
        <span role="text">
          {props.translations['logoText.mySugr'] + ` `}
          {hasBundleLogo && payor && props.translations['logoText.payor']}
        </span>
      </VisuallyHidden>

      <div className={css.logos} aria-hidden="true">
        <div className="mySugrLogo">
          <a href={startPage}>{renderLogo()}</a>
        </div>
        {hasBundleLogo && (
          <>
            {!rochePayor ? (
              <img
                alt={props.translations['header.heart.altText']}
                className={css.payorHeart}
                src={heart}
              />
            ) : (
              <img alt="" className={css.payorBar} src={greyBar} />
            )}
            {bundleMatchesSlug && (
              <PayorLogo bundle={props.bundle} className={css.payorLogo} />
            )}
          </>
        )}
      </div>
    </header>
  );
}

export function mapStateToProps(state: IStoreState): IStateProps {
  const bundle = getBundleDynamic(state);
  return {
    activeStep: getActiveStep(state),
    app: isApp(state),
    bundle,
    translations: {
      'header.heart.altText': getTranslation('header.heart.altText')(state),
      'logoText.mySugr': getTranslation('logoText.mySugr')(state),
      'logoText.payor': getTranslation('logoText.payor', {
        payor: bundle?.payor?.name,
      })(state),
    },
  };
}

export default connect(mapStateToProps)(withLocation(Header));
