import IFormNotice from 'src/types/IFormNotice';

export const REQUEST_PASSWORD_RESET_ACTION = 'REQUEST_PASSWORD_RESET_ACTION';
export type REQUEST_PASSWORD_RESET_ACTION =
  typeof REQUEST_PASSWORD_RESET_ACTION;

export const REQUEST_PASSWORD_RESET_BEGIN_ACTION =
  'REQUEST_PASSWORD_RESET_BEGIN_ACTION';
export type REQUEST_PASSWORD_RESET_BEGIN_ACTION =
  typeof REQUEST_PASSWORD_RESET_BEGIN_ACTION;

export const REQUEST_PASSWORD_RESET_SUCCESS_ACTION =
  'REQUEST_PASSWORD_RESET_SUCCESS_ACTION';
export type REQUEST_PASSWORD_RESET_SUCCESS_ACTION =
  typeof REQUEST_PASSWORD_RESET_SUCCESS_ACTION;

export const REQUEST_PASSWORD_RESET_FAILURE_ACTION =
  'REQUEST_PASSWORD_RESET_FAILURE_ACTION';
export type REQUEST_PASSWORD_RESET_FAILURE_ACTION =
  typeof REQUEST_PASSWORD_RESET_FAILURE_ACTION;

export interface IRequestPasswordResetBeginAction {
  type: REQUEST_PASSWORD_RESET_BEGIN_ACTION;
}

export interface IRequestPasswordResetSuccessAction {
  type: REQUEST_PASSWORD_RESET_SUCCESS_ACTION;
  email: string;
}

export interface IRequestPasswordResetFailureAction {
  type: REQUEST_PASSWORD_RESET_FAILURE_ACTION;
  email: string | undefined;
  errors: IFormNotice[];
}

export type PasswordResetAction =
  | IRequestPasswordResetBeginAction
  | IRequestPasswordResetFailureAction
  | IRequestPasswordResetSuccessAction;

export function requestPasswordResetBeginAction(): IRequestPasswordResetBeginAction {
  return { type: REQUEST_PASSWORD_RESET_BEGIN_ACTION };
}

export function requestPasswordResetSuccessAction(
  email: string,
): IRequestPasswordResetSuccessAction {
  return { type: REQUEST_PASSWORD_RESET_SUCCESS_ACTION, email };
}

export function requestPasswordResetFailureAction(
  email: string,
  errors: IFormNotice[],
): IRequestPasswordResetFailureAction {
  return { type: REQUEST_PASSWORD_RESET_FAILURE_ACTION, email, errors };
}
