import React, { useState } from 'react';
import browserOnly from 'src/lib/browserOnly';

type childrenType = JSX.Element | string;

export interface IProps {
  online: boolean;
  children: string | JSX.Element | childrenType[];
  forceStatus?: boolean;
}

export interface IState {
  isOnline?: boolean;
}

function Connection(props: Readonly<IProps>) {
  const { forceStatus, children, online } = props;

  const getOnlineStatus = () =>
    browserOnly(
      () => (typeof forceStatus === 'boolean' ? forceStatus : navigator.onLine),
      true,
    );

  const [isOnline, setIsOnline] = useState(getOnlineStatus());

  React.useEffect(() => {
    browserOnly(() => {
      window.addEventListener('online', () => setIsOnline(true));
      window.addEventListener('offline', () => setIsOnline(false));
    });
  }, []);

  if (isOnline === online) {
    return <React.Fragment>{children}</React.Fragment>;
  } else {
    return null;
  }
}

export default Connection;
