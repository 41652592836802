import IBundle from 'src/types/IBundle';
import IBundleProperty from 'src/types/IBundleProperty';

export interface IBundleParsedProperties {
  [key: string]: {
    required: boolean;
    validationRegex?: RegExp;
  };
}

export function getParsedBundleProperties(
  bundle: IBundle | undefined,
): IBundleParsedProperties {
  const properties: IBundleProperty[] =
    (bundle?.properties &&
      (Array.isArray(bundle.properties)
        ? bundle.properties
        : [bundle?.properties])) ||
    [];
  const parsed: IBundleParsedProperties = {};

  for (const { key, required, validationRegex } of properties) {
    parsed[key] = {
      required,
      validationRegex: validationRegex
        ? new RegExp(validationRegex)
        : undefined,
    };
  }

  return parsed;
}
