import * as React from 'react';
import css from './Footer.module.scss';

export function CookieSettings(): React.ReactElement {
  return (
    <li className={css.link}>
      <button
        id="ot-sdk-btn"
        className="ot-sdk-show-settings"
        style={{ all: 'unset', cursor: 'pointer' }}
      ></button>
    </li>
  );
}

export default CookieSettings;
