import * as React from 'react';
import LinkButton from '../LinkButton/LinkButton';
import questionMarkIcon from 'src/icons/more-info.svg';

interface IProps {
  altText?: string;
  onClick: (event: React.SyntheticEvent) => void;
}

function QuestionMarkButton(props: Readonly<IProps>): React.ReactElement {
  const { onClick, altText } = props;
  return (
    <LinkButton onClick={onClick}>
      <img src={questionMarkIcon} alt={altText} />
    </LinkButton>
  );
}

export default QuestionMarkButton;
