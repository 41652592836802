import { Dispatch } from 'redux';
import {
  loadingBeginAction,
  loadingSuccessAction,
} from 'src/actions/loadingActions';
import { LANGUAGE } from 'src/constants/languages';
import browserOnly from 'src/lib/browserOnly';
import getLocaleFromPathname from 'src/lib/getLocaleFromPathname';
import navigate from 'src/lib/navigate';
import getPathname from 'src/server/lib/getPathname';
import IStoreState from 'src/types/IStoreState';

export function switchLanguageFlow(
  language: LANGUAGE,
): (dispatch: Dispatch, getState: () => IStoreState) => Promise<any> {
  return (dispatch: Dispatch, getState: () => IStoreState) =>
    new Promise(resolve => {
      dispatch(loadingBeginAction('change-language'));

      const [, country, path] = getLocaleFromPathname(getPathname(getState()));

      const countryUrl = country ? `-${country}` : '';
      const pathUrl = path ? `/${path}` : '';
      const newUrl = `/${language}${countryUrl}${pathUrl}`;
      navigate(newUrl);

      /**
       * Scroll to top of page after url has changed
       * For some reason chrome scrolls to somewhere near the content after `navigate()` is called,
       * this prevents that and introduces more consistency by scrolling all the way to the top
       */
      browserOnly(() => {
        window.scroll(0, 0);
      });

      setTimeout(() => {
        dispatch(loadingSuccessAction('change-language'));
        resolve(null);
      }, 350);
    });
}
