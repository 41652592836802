/* istanbul ignore file */

import IState from 'src/types/IState';

const germanStates: IState[] = [
  { short: 'Baden-Württemberg', name: 'Baden-Württemberg' },
  { short: 'Bayern', name: 'Bayern' },
  { short: 'Berlin', name: 'Berlin' },
  { short: 'Brandenburg', name: 'Brandenburg' },
  { short: 'Bremen', name: 'Bremen' },
  { short: 'Hamburg', name: 'Hamburg' },
  { short: 'Hessen', name: 'Hessen' },
  { short: 'Niedersachsen', name: 'Niedersachsen' },
  { short: 'Mecklenburg-Vorpommern', name: 'Mecklenburg-Vorpommern' },
  { short: 'Nordrhein-Westfalen', name: 'Nordrhein-Westfalen' },
  { short: 'Rheinland-Pfalz', name: 'Rheinland-Pfalz' },
  { short: 'Saarland', name: 'Saarland' },
  { short: 'Sachsen', name: 'Sachsen' },
  { short: 'Sachsen-Anhalt', name: 'Sachsen-Anhalt' },
  { short: 'Schleswig-Holstein', name: 'Schleswig-Holstein' },
  { short: 'Thüringen', name: 'Thüringen' },
];

export default germanStates;
