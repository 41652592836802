/* istanbul ignore file */

module.exports = {
  'account.account.backButton': 'Wróć',
  'account.account.continueButton': 'Kontynuuj',
  'account.account.loggedInAs': 'Jesteś zalogowany jako :email',
  'account.account.logoutButton': 'Wyloguj',
  'account.account.logoutText':
    'Nie ty? Możesz się wylogować i zalogować przy użyciu innego konta lub utworzyć nowe konto.',
  'account.emailConfirmForm.backButton': 'Wróć',
  'account.emailConfirmForm.changeEmail': 'Zmień adres e-mail',
  'account.emailConfirmForm.checkYourEmail': 'Sprawdź swój e-mail',
  'account.emailConfirmForm.codeLabel': 'Wprowadź swój kod weryfikacyjny',
  'account.emailConfirmForm.codeName': 'Kod weryfikacyjny',
  'account.emailConfirmForm.codeWrong': 'Kod jest nieprawidłowy lub nieaktywny',
  'account.emailConfirmForm.continueButton': 'Kontynuuj',
  'account.emailConfirmForm.emailLabel': 'Adres e-mail',
  'account.emailConfirmForm.receivedNoCode': 'Nie otrzymałeś kodu?',
  'account.emailConfirmForm.sendCodeAgain': 'Wyślij ponownie e-maila',
  'account.emailConfirmForm.sendCodeSuccess':
    'Wysłaliśmy Twój kod weryfikacyjny na adres e-mail :email.',
  'account.emailConfirmForm.sentCode': 'Wysłaliśmy do Ciebie kod',
  'account.emailForm.backButton': 'Wróć',
  'account.emailForm.continueButton': 'Kontynuuj',
  'account.emailForm.emailLabel': 'Adres e-mail',
  'account.emailForm.emailName': 'E-Mail',
  'account.emailForm.emailPlaceholder': 'email@example.com',
  'account.emailForm.intro':
    'Jeżeli <strong>masz już konto mySugr</strong>, wpisz ten sam adres e-mail, aby kupon został przypisany do istniejącego konta. Swój adres e-mail możesz znaleźć w swoim profilu w aplikacji mySugr.',
  'account.emailForm.proVoucherIntro':
    'Jeżeli <strong>masz już konto mySugr</strong>, wpisz ten sam adres e-mail, aby kupon został przypisany do istniejącego konta. Swój adres e-mail możesz znaleźć w swoim profilu w aplikacji mySugr.',
  'account.loginForm.backButton': 'Wróć',
  'account.loginForm.continueButton': 'Kontynuuj',
  'account.loginForm.emailLabel': 'Twój adres e-mail',
  'account.loginForm.emailName': 'E-Mail',
  'account.loginForm.forgotPassword': 'Nie pamiętasz hasła?',
  'account.loginForm.passwordLabel': 'Wprowadź hasło',
  'account.loginForm.passwordName': 'Hasło',
  'account.loginForm.passwordResetRequestedText':
    'Zażądałeś zresetowania hasła. Sprawdź swoje wiadomości e-mail i postępuj zgodnie z instrukcjami, które wysłaliśmy do Ciebie.',
  'account.loginForm.redeemButton': 'Zrealizuj',
  'account.loginTitle': 'Login',
  'account.loginTitleRedeem': 'Zaloguj się, aby zrealizować kupon',
  'account.registerForm.backButton': 'Wróć',
  'account.registerForm.consentsName': 'Zgody',
  'account.registerForm.continueButton': 'Kontynuuj',
  'account.registerForm.countryLabel': 'Wybierz swój kraj',
  'account.registerForm.emailLabel': 'Twój adres e-mail',
  'account.registerForm.emailName': 'E-Mail',
  'account.registerForm.passwordConfirmLabel': 'Wprowadź ponownie hasło',
  'account.registerForm.passwordConfirmName': 'Potwierdzenie hasła',
  'account.registerForm.passwordHint': 'Co najmniej 6 znaków.',
  'account.registerForm.passwordHintLength': 'Co najmniej :minLength znaków.',
  'account.registerForm.passwordLabel': 'Hasło',
  'account.registerForm.passwordName': 'Hasło',
  'account.registerForm.redeemButton': 'Zrealizuj',
  'account.registerForm.verifiedEmail': 'Zweryfikowano',
  'account.registerTitle': 'Rejestracja',
  'account.registerTitleRedeem': 'Utwórz konto, aby zrealizować kupon',
  'account.resetPassword.checkYourEmail': 'Zażądałeś zresetowania hasła.',
  'account.resetPassword.passwordConfirmLabel': 'Potwierdź nowe hasło',
  'account.resetPassword.passwordConfirmName': 'Potwierdź nowe hasło',
  'account.resetPassword.passwordHint': 'Co najmniej 6 znaków.',
  'account.resetPassword.passwordHintLength': 'Co najmniej :minLength znaków.',
  'account.resetPassword.passwordLabel': 'Wprowadź nowe hasło',
  'account.resetPassword.passwordName': 'Wprowadź nowe hasło',
  'account.resetPassword.sentCode': 'Wysłaliśmy kod na Twój e-mail',
  'account.resetPasswordCodeVerification.backButton': 'Wróć',
  'account.resetPasswordCodeVerification.continueButton': 'Zweryfikuj kod',
  'account.resetPasswordForm.backButton': 'Wróć',
  'account.resetPasswordForm.saveButton': 'Zapisz hasło',
  'account.resetPasswordFormText':
    'Wybierz nowe hasło do swojego konta mySugr.',
  'account.resetPasswordTitle': 'Zresetuj hasło',
  'account.stepName': 'Konto',
  'account.title': 'Rejestracja / Logowanie',
  activateProTitle: 'Aktywuj mySugr PRO',
  appLegalNotice:
    'Aplikacja mySugr jest oferowana w ramach pakietu. Aby uzyskać dostęp do wszystkich usług z pakietu, wymagane jest korzystanie z aplikacji.',
  'appProvider.title': 'Dostawca aplikacji',
  'bundle.item.coach.description':
    'Pomoc certyfikowanych edukatorów diabetologicznych w aplikacji mySugr.',
  'bundle.item.coach.name': 'Certyfikowany edukator diabetologiczny',
  'bundle.item.cuff.description': 'Podłączony ciśnieniomierz.',
  'bundle.item.cuff.name': '1x mankiet do ciśnieniomierza',
  'bundle.item.dkv_coach_1.name': '',
  'bundle.item.fastclix_lancets_2.description':
    '34 bębenków, każdy z 6 lancetami.',
  'bundle.item.fastclix_lancets_2.name': '204x lancety',
  'bundle.item.fastclix_lancets.description':
    '17 bębenków, każdy z 6 lancetami.',
  'bundle.item.fastclix_lancets.name': '102x lancety',
  'bundle.item.fastclix_lancing_kit.description':
    'Z bębenkiem z 6 lancetami, których nie trzeba dotykać ani oglądać.',
  'bundle.item.fastclix_lancing_kit.name': '1x nakłuwacz Accu-Chek FastClix',
  'bundle.item.guide_3.description':
    'Wysyła wyniki pomiarów poziomu cukru we krwi bezpośrednio do aplikacji mySugr.',
  'bundle.item.guide_3.name':
    '1x glukometr Accu-Chek® Guide wraz z nakłuwaczem',
  'bundle.item.guide_4.description':
    'Wysyła wyniki pomiarów poziomu cukru we krwi bezpośrednio do aplikacji mySugr.',
  'bundle.item.guide_4.name':
    '1x glukometr Accu-Chek® Guide wraz z nakłuwaczem',
  'bundle.item.guide_me.description':
    'Wysyła wyniki pomiarów poziomu cukru we krwi bezpośrednio do aplikacji mySugr.',
  'bundle.item.guide_me.name': '1x glukometr Accu-Chek® Guide Me',
  'bundle.item.guide_strips_1.description.country_de':
    'Cztery opakowania testów paskowych Accu-Chek® Guide.',
  'bundle.item.guide_strips.description':
    'Pięć opakowań testów paskowych Accu-Chek® Guide.',
  'bundle.item.guide_strips.name': ':amountx testów paskowych',
  'bundle.item.guide.description':
    'Wysyła wyniki pomiarów poziomu cukru we krwi bezpośrednio do aplikacji mySugr.',
  'bundle.item.guide.name': '1x glukometr Accu-Chek® Guide',
  'bundle.item.mysugr_coach_voucher_based_1.description':
    'Pomoc certyfikowanych edukatorów diabetologicznych w aplikacji mySugr.',
  'bundle.item.mysugr_coach_voucher_based_1.name':
    'Certyfikowany edukator diabetologiczny',
  'bundle.item.mysugr_strips_subscription_guide_2.description':
    'na podstawie zaimportowanych wyników pomiarów.',
  'bundle.item.mysugr_strips_subscription_guide_2.name':
    'Automatyczne dostawy testów paskowych',
  'bundle.item.mysugr_strips_subscription_guide_2.name.2':
    'Nieograniczone dostawy testów paskowych',
  'bundle.item.pro.description':
    'Synchronizacja wartości cukru, szacowane HbA1c, raporty PDF, wyzwania itd.',
  'bundle.item.pro.name': 'Aplikacja mySugr z funkcjami PRO',
  'bundle.item.scale.description': 'Podłączona waga.',
  'bundle.item.scale.name': '1x waga',
  'bundle.item.schwenninger_coach_1.description': '',
  'bundle.item.schwenninger_coach_1.name': '',
  'bundle.item.servidigest_coach_1.description': '',
  'bundle.item.servidigest_coach_1.name': '',
  'bundle.item.strips_subscription_guide.description':
    'na podstawie zaimportowanych wyników pomiarów.',
  'bundle.item.strips_subscription_guide.name':
    'Automatyczne dostawy testów paskowych',
  'bundle.item.strips_subscription_guide.name.2':
    'Nieograniczone dostawy testów paskowych',
  'bundleDelivery.text':
    'Twój pakiet dotrze w ciągu :time dni roboczych. Wyślemy Ci wiadomość e-mail ze szczegółami dostawy. <b>Wszystkie przyszłe dostawy będą uruchamiane z aplikacji.</b>',
  'bundleGroupItemName.activisionblizzard_coach_1': '',
  'bundleGroupItemName.dhp_neomed_coach_1': '',
  'bundleGroupItemName.fastclix_lancets_1': '',
  'bundleGroupItemName.fastclix_lancets_2': '',
  'bundleGroupItemName.fastclix_lancing_kit_1': '',
  'bundleGroupItemName.guide_1': '',
  'bundleGroupItemName.guide_3': '',
  'bundleGroupItemName.guide_4': '',
  'bundleGroupItemName.guide_me_1': '',
  'bundleGroupItemName.guide_strips_1': '',
  'bundleGroupItemName.mysugr_coach_1': '',
  'bundleGroupItemName.mysugr_coach_2': '',
  'bundleGroupItemName.mysugr_coach_3': '',
  'bundleGroupItemName.mysugr_coach_5': '',
  'bundleGroupItemName.mysugr_coach_6': '',
  'bundleGroupItemName.mysugr_coach_6_copy': '',
  'bundleGroupItemName.mysugr_pro_1': '',
  'bundleGroupItemName.mysugr_pro_2': '',
  'bundleGroupItemName.mysugr_pro_3': '',
  'bundleGroupItemName.mysugr_pro_4': '',
  'bundleGroupItemName.mysugr_strips_subscription_guide_1': '',
  'bundleGroupItemName.mysugr_strips_subscription_guide_2': '',
  'bundleGroupItemName.mysugr_strips_subscription_guide_3': '',
  'bundleGroupItemName.schwenninger_coach_1': '',
  'bundleGroupItemName.servidigest_coach_1': '',
  'bundleGroupItemName.sigroup_coach_1': '',
  'bundleGroupItemName.us_spanish_coach_1': '',
  'bundleProduct.content.items.coach': 'Trener',
  'bundleProduct.content.items.cuff': 'Mankiet do ciśnieniomierza',
  'bundleProduct.content.items.fastclix_lancets': 'Lancety',
  'bundleProduct.content.items.fastclix_lancing_kit': 'Nakłuwacz<br />',
  'bundleProduct.content.items.guide': 'Glukometr',
  'bundleProduct.content.items.guide_me': 'Glukometr',
  'bundleProduct.content.items.mysugr_coach_voucher_based_1': 'Trener',
  'bundleProduct.content.items.pro': 'Aplikacja PRO',
  'bundleProduct.content.items.scale': 'Waga<wbr>',
  'bundleProduct.content.items.servidigest_coach_1': '',
  'bundleProduct.content.items.strips_subscription_guide': 'Testy paskowe',
  'bundleProduct.content.title': 'Twój pakiet zawiera:',
  'bundleProduct.content.title.roche-benefits-us': '',
  'bundleProduct.content.title.roche-benefits-us-coach': '',
  'bundleProduct.continueButton': 'Kontynuuj',
  'bundleProduct.countryRestriction.de': 'Tylko Niemcy',
  'bundleProduct.countryRestriction.sg': '',
  'bundleProduct.countryRestriction.us': 'Tylko USA',
  'bundleProduct.description.list.aok_nordwest_coach_1': '',
  'bundleProduct.description.list.coach':
    'Wyłączny dostęp do <strong>Coacha</strong>',
  'bundleProduct.description.list.dkv_coach_1': '',
  'bundleProduct.description.list.entry_search': '',
  'bundleProduct.description.list.guide':
    'Podłączone do <strong>glukometru</strong> Accu-Chek® Guide',
  'bundleProduct.description.list.guide_me':
    'Podłączone do <strong>glukometru</strong> Accu-Chek® Guide Me',
  'bundleProduct.description.list.meal_photos': '',
  'bundleProduct.description.list.pdf_report': '',
  'bundleProduct.description.list.pro':
    'Uaktualniono do aplikacji <strong>mySugr PRO</strong>',
  'bundleProduct.description.list.pro.dkv':
    'Uaktualniono do aplikacji <strong>mySugr PRO</strong>',
  'bundleProduct.description.list.pro.roche-benefits-us': '',
  'bundleProduct.description.list.pro.roche-benefits-us-coach': '',
  'bundleProduct.description.list.reminders': '',
  'bundleProduct.description.list.scale_1': '',
  'bundleProduct.description.list.schwenninger_coach_1': '',
  'bundleProduct.description.list.strips_subscription_guide':
    '<strong>Nieograniczona liczba testów paskowych</strong>, które wysyłane są na podstawie intensywności użytkowania (zaimportowanych do aplikacji pomiarów poziomu cukru we krwi)',
  'bundleProduct.description.list.strips_subscription_guide.2':
    '<strong>Nieograniczona liczba testów paskowych</strong>, które wysyłane są na podstawie intensywności użytkowania (zaimportowanych do aplikacji pomiarów poziomu cukru we krwi)',
  'bundleProduct.description.title':
    'Twoja cukrzyca stała się mniej uciążliwa...',
  'bundleProduct.description.title.dkv': '',
  'bundleProduct.description.title.dvg': '',
  'bundleProduct.features.items.immediate.coach':
    'Dostęp w aplikacji <strong>do osobistego Coacha</strong>',
  'bundleProduct.features.items.immediate.dkv_coach_1': '',
  'bundleProduct.features.items.immediate.mysugr_coach_voucher_based_1':
    'Dostęp w aplikacji <strong>do osobistego Coacha</strong>',
  'bundleProduct.features.items.immediate.mysugr_pro_1.dkv': '',
  'bundleProduct.features.items.immediate.pro':
    '<strong>Opcje PRO</strong> w aplikacji mySugr',
  'bundleProduct.features.items.immediate.schwenninger_coach_1': '',
  'bundleProduct.features.items.immediate.servidigest_coach_1': '',
  'bundleProduct.features.items.product.cuff':
    '<b>1x</b> mankiet do ciśnieniomierza',
  'bundleProduct.features.items.product.fastclix_lancets':
    '<strong>102x lancety</strong>',
  'bundleProduct.features.items.product.fastclix_lancets_2':
    '<strong>204x lancety</strong>',
  'bundleProduct.features.items.product.fastclix_lancing_kit':
    '<strong>1x</strong> <strong>nakłuwacz</strong> Accu-Chek FastClix',
  'bundleProduct.features.items.product.guide':
    '<strong>1x</strong> <strong>glukometr</strong> Accu-Chek® Guide',
  'bundleProduct.features.items.product.guide_me':
    '<strong>1x</strong> <strong>glukometr</strong> Accu-Chek® Guide Me',
  'bundleProduct.features.items.product.guide_strips':
    '<strong>:amountx testów paskowych</strong>',
  'bundleProduct.features.items.product.scale': '<b>1x</b> waga',
  'bundleProduct.features.items.subscription.aok_nordwest_coach_1': '',
  'bundleProduct.features.items.subscription.coach':
    '<strong>mySugr Coach</strong> w aplikacji',
  'bundleProduct.features.items.subscription.dkv_coach_1': '',
  'bundleProduct.features.items.subscription.mysugr_coach_voucher_based_1':
    '<strong>mySugr Coach</strong> w aplikacji',
  'bundleProduct.features.items.subscription.schwenninger_coach_1': '',
  'bundleProduct.features.items.subscription.servidigest_coach_1': '',
  'bundleProduct.features.items.subscription.strips_subscription_guide':
    '<strong>Automatyczne dostawy testów paskowych</strong> na podstawie zaimportowanych pomiarów',
  'bundleProduct.features.items.subscription.strips_subscription_guide.2':
    '<strong>Nieograniczone dostawy testów paskowych</strong> na podstawie zaimportowanych pomiarów',
  'bundleProduct.features.title': 'Twój pakiet zawiera:',
  'bundleProduct.features.title.dkv': '',
  'bundleProduct.features.title.roche-benefits-us': '',
  'bundleProduct.features.title.roche-benefits-us-coach': '',
  'bundleProduct.features.titles.immediate': 'Natychmiastowy dostęp do:',
  'bundleProduct.features.titles.product':
    'W ciągu kilku dni dotrze Twoja paczka z:',
  'bundleProduct.features.titles.subscription': 'Nieprzerwany dostęp do:',
  'bundleProduct.freeShipping': 'Bezpłatna dostawa',
  'bundleProduct.groupNotFound': 'Ten pakiet jest obecnie niedostępny.',
  'bundleProduct.minCommitment.info.DAY.1': 'Wymagane uczestnictwo 1 dzień',
  'bundleProduct.minCommitment.info.DAY.2':
    'Wymagane uczestnictwo :minRecurringCount dni',
  'bundleProduct.minCommitment.info.MONTH.1': 'Wymagane uczestnictwo 1 miesiąc',
  'bundleProduct.minCommitment.info.MONTH.2':
    'Wymagane uczestnictwo :minRecurringCount miesiące',
  'bundleProduct.minCommitment.info.WEEK.1': '',
  'bundleProduct.minCommitment.info.YEAR.1': 'Wymagane uczestnictwo 1 rok',
  'bundleProduct.minCommitment.info.YEAR.2':
    'Wymagane uczestnictwo :minRecurringCount lata',
  'bundleProduct.noBundle.button': 'Spróbuj ponownie',
  'bundleProduct.noBundle.text':
    'Przepraszamy, nie mogliśmy wprowadzić informacji o tym pakiecie.',
  'bundleProduct.paymentInterval.DAY.1.long': 'Każdego dnia',
  'bundleProduct.paymentInterval.DAY.1.short': 'Dziennie:',
  'bundleProduct.paymentInterval.DAY.2+': 'Co :intervalCount dni',
  'bundleProduct.paymentInterval.MONTH.1.long': 'Każdego miesiąca',
  'bundleProduct.paymentInterval.MONTH.1.short': 'Miesięcznie:',
  'bundleProduct.paymentInterval.MONTH.2+': 'Co :intervalCount miesiące',
  'bundleProduct.paymentInterval.WEEK.1.long': '',
  'bundleProduct.paymentInterval.WEEK.1.short': '',
  'bundleProduct.paymentInterval.YEAR.1.long': 'Każdego roku',
  'bundleProduct.paymentInterval.YEAR.1.short': 'rocznie:',
  'bundleProduct.paymentInterval.YEAR.2+': 'Co :intervalCount lat(a)',
  'bundleProduct.price': ':price',
  cancelAnytime: 'Możesz wyrejestrować się z mySugr w dowolnym momencie.',
  changeCardDetails: 'Zmień dane karty',
  'coachBox.text':
    'Aby przeczytać wiadomość, zaloguj się do aplikacji i w pełni wykorzystaj nową, zindywidualizowaną pomoc diabetologiczną.',
  'coachBox.title': 'Twój <b>osobisty Coach</b> wysłał do Ciebie wiadomość!',
  'coachSelection.message.en':
    'Hello, <br>\nI am your <br>\npersonal <br>\ncoach!',
  'coachSelection.message.es':
    'Hola,<br>\nsoy tu<br>\n¡entrenador<br>\npersonal!',
  'coachSelection.select.button.en': 'Select',
  'coachSelection.select.button.es': 'Seleccionar',
  'coachSelection.subtitle':
    'W jakim języku chcesz rozmawiać ze swoim Coachem?',
  'coachSelection.title': '<strong>Wybierz język swojego Coacha</strong>',
  confirmEmailCodeInputLabel:
    'Wprowadź tutaj kod otrzymany w wiadomości e-mail',
  'contact.stepName': 'Kontakt',
  'contact.title': '<strong>Dane</strong> kontaktowe',
  continueToNextStep: 'Przejdź do następnego etapu',
  'cookieBar.buttonText': 'OK',
  'cookieBar.text':
    'Używamy plików cookie w celu optymalizacji i ciągłego ulepszania naszej witryny. Zapoznaj się z naszą <a target="_blank" rel="noopener" href=":link" tabindex=":tabIndex">polityką prywatności</a>, aby uzyskać więcej informacji na temat plików cookie oraz ich akceptowania i odrzucenia.',
  'cookieBar.text.global':
    'Używamy plików cookie w celu optymalizacji i ciągłego ulepszania naszej witryny. Zapoznaj się z naszą polityką prywatności (<a target="_blank" rel="noopener" href=":linkUs" tabindex=":tabIndex">US</a>, <a target="_blank" rel="noopener" href=":linkEu" tabindex=":tabIndex">EU</a>), aby uzyskać więcej informacji na temat plików cookie oraz ich akceptowania i odrzucenia.',
  'countres.gu': 'Guam',
  'countries.ad': 'Andora',
  'countries.ae': 'Zjednoczone Emiraty Arabskie',
  'countries.af': 'Afganistan',
  'countries.ag': 'Antigua i Barbuda',
  'countries.ai': 'Anguilla',
  'countries.al': 'Albania',
  'countries.am': 'Armenia',
  'countries.ao': 'Angola',
  'countries.aq': 'Antarktyda',
  'countries.ar': 'Argentyna',
  'countries.as': 'Samoa Amerykańskie',
  'countries.at': 'Austria',
  'countries.au': 'Australia',
  'countries.aw': 'Aruba',
  'countries.ax': 'Wyspy Alandzkie',
  'countries.az': 'Azerbejdżan',
  'countries.ba': 'Bośnia i Hercegowina',
  'countries.bb': 'Barbados',
  'countries.bd': 'Bangladesz',
  'countries.be': 'Belgia',
  'countries.bf': 'Burkina Faso',
  'countries.bg': 'Bułgaria',
  'countries.bh': 'Bahrajn',
  'countries.bi': 'Burundi',
  'countries.bj': 'Benin',
  'countries.bl': 'Saint-Barthélemy',
  'countries.bm': 'Bermudy',
  'countries.bn': 'Brunei Darussalam',
  'countries.bo': 'Boliwia',
  'countries.bq': 'Bonaire',
  'countries.br': 'Brazylia',
  'countries.bs': 'Bahamy',
  'countries.bt': 'Bhutan',
  'countries.bv': 'Wyspa Bouveta',
  'countries.bw': 'Botswana',
  'countries.by': 'Białoruś',
  'countries.bz': 'Belize',
  'countries.cc': 'Wyspy Kokosowe (Keelinga)',
  'countries.cd': 'Kongo, Demokratyczna Republika',
  'countries.cf': 'Republika Środkowoafrykańska',
  'countries.cg': 'Kongo',
  'countries.ch': 'Szwajcaria',
  'countries.ci': 'Wybrzeże Kości Słoniowej',
  'countries.ck': 'Wyspy Cooka',
  'countries.cl': 'Chile',
  'countries.cm': 'Kamerun',
  'countries.cn': 'Kanada',
  'countries.co': 'Kolumbia',
  'countries.cr': 'Kostaryka',
  'countries.cu': 'Kuba',
  'countries.cv': 'Republika Zielonego Przylądka',
  'countries.cw': 'Curaçao',
  'countries.cx': 'Wyspa Bożego Narodzenia',
  'countries.cy': 'Cypr',
  'countries.cz': 'Czechy',
  'countries.de': 'Niemcy',
  'countries.dj': 'Dżibuti',
  'countries.dk': 'Dania',
  'countries.dm': 'Dominika',
  'countries.do': 'Dominikana, Republika',
  'countries.dz': 'Algieria',
  'countries.ec': 'Ekwador',
  'countries.ee': 'Estonia',
  'countries.eg': 'Egipt',
  'countries.eh': 'Sahara Zachodnia',
  'countries.er': 'Erytrea',
  'countries.es': 'Hiszpania',
  'countries.et': 'Etiopia',
  'countries.fi': 'Finlandia',
  'countries.fj': 'Fidżi',
  'countries.fk': 'Falklandy (Malwiny)',
  'countries.fm': 'Mikronezja, Sfederowane Stany',
  'countries.fo': 'Wyspy Owcze',
  'countries.fr': 'Francja',
  'countries.ga': 'Gabon',
  'countries.gb': 'Zjednoczone Królestwo',
  'countries.gd': 'Grenada',
  'countries.ge': 'Gruzja',
  'countries.gf': 'Gujana Francuska',
  'countries.gg': 'Guernsey',
  'countries.gh': 'Ghana',
  'countries.gi': 'Gibraltar',
  'countries.gl': 'Grenlandia',
  'countries.gm': 'Gambia',
  'countries.gn': 'Gwinea',
  'countries.gp': 'Gwadelupa',
  'countries.gq': 'Gwinea Równikowa',
  'countries.gr': 'Grecja',
  'countries.gs': 'Georgia Południowa i Sandwich Południowy',
  'countries.gt': 'Gwatemala',
  'countries.gw': 'Gwinea Bissau',
  'countries.gy': 'Gujana',
  'countries.hk': 'Hongkong',
  'countries.hm': 'Wyspy Heard i McDonalda',
  'countries.hn': 'Honduras',
  'countries.hr': 'Chorwacja',
  'countries.ht': 'Haiti',
  'countries.hu': 'Węgry',
  'countries.id': 'Indonezja',
  'countries.ie': 'Irlandia',
  'countries.il': 'Izrael',
  'countries.im': 'Wyspa Man',
  'countries.in': 'Indie',
  'countries.io': 'Brytyjskie Terytorium Oceanu Indyjskiego',
  'countries.iq': 'Irak',
  'countries.ir': 'Iran, Islamska Republika',
  'countries.is': 'Islandia',
  'countries.it': 'Włochy',
  'countries.je': 'Jersey',
  'countries.jm': 'Jamajka',
  'countries.jo': 'Jordania',
  'countries.jp': 'Japonia',
  'countries.ke': 'Kenia',
  'countries.kg': 'Kirgistan',
  'countries.kh': 'Kambodża',
  'countries.ki': 'Kiribati',
  'countries.km': 'Komory',
  'countries.kn': 'Saint Kitts i Nevis',
  'countries.kp': 'Koreańska Republika Ludowo-Demokratyczna',
  'countries.kr': 'Korea, Republika',
  'countries.kw': 'Kuwejt',
  'countries.ky': 'Kajmany',
  'countries.kz': 'Kazachstan',
  'countries.la': 'Laotańska Republika Ludowo-Demokratyczna',
  'countries.lb': 'Liban',
  'countries.lc': 'Święta Łucja',
  'countries.li': 'Liechtenstein',
  'countries.lk': 'Sri Lanka',
  'countries.lr': 'Liberia',
  'countries.ls': 'Lesotho',
  'countries.lt': 'Litwa',
  'countries.lu': 'Luksemburg',
  'countries.lv': 'Łotwa',
  'countries.ly': 'Libia',
  'countries.ma': 'Maroko',
  'countries.mc': 'Monako',
  'countries.md': 'Mołdawia, Republika',
  'countries.me': 'Czarnogóra',
  'countries.mf': 'Saint Martin (część francuska)',
  'countries.mg': 'Madagaskar',
  'countries.mh': 'Wyspy Marshalla',
  'countries.mk': 'Macedonia, Była Jugosłowiańska Republika',
  'countries.ml': 'Mali',
  'countries.mm': 'Myanmar',
  'countries.mn': 'Mongolia',
  'countries.mo': 'Makao',
  'countries.mp': 'Mariany Północne',
  'countries.mq': 'Martynika',
  'countries.mr': 'Mauretania',
  'countries.ms': 'Montserrat',
  'countries.mt': 'Malta',
  'countries.mu': 'Mauritius',
  'countries.mv': 'Malediwy',
  'countries.mw': 'Malawi',
  'countries.mx': 'Meksyk',
  'countries.my': 'Malezja',
  'countries.mz': 'Mozambik',
  'countries.na': 'Namibia',
  'countries.nc': 'Nowa Kaledonia',
  'countries.ne': 'Niger',
  'countries.nf': 'Wyspa Norfolk',
  'countries.ng': 'Nigeria',
  'countries.ni': 'Nikaragua',
  'countries.nl': 'Holandia',
  'countries.no': 'Norwegia',
  'countries.np': 'Nepal',
  'countries.nr': 'Nauru',
  'countries.nu': 'Niue',
  'countries.nz': 'Nowa Zelandia',
  'countries.om': 'Oman',
  'countries.pa': 'Panama',
  'countries.pe': 'Peru',
  'countries.pf': 'Polinezja Francuska',
  'countries.pg': 'Papua-Nowa Gwinea',
  'countries.ph': 'Filipiny',
  'countries.pk': 'Pakistan',
  'countries.pl': 'Polska',
  'countries.pm': 'Saint Pierre i Miquelon',
  'countries.pn': 'Pitcairn',
  'countries.pr': 'Portoryko',
  'countries.ps': 'Terytorium Palestyńskie, Okupowane',
  'countries.pt': 'Portugalia',
  'countries.pw': 'Palau',
  'countries.py': 'Paragwaj',
  'countries.qa': 'Katar',
  'countries.re': 'Réunion',
  'countries.ro': 'Rumunia',
  'countries.rs': 'Serbia',
  'countries.ru': 'Federacja Rosyjska',
  'countries.rw': 'Rwanda',
  'countries.sa': 'Arabia Saudyjska',
  'countries.sb': 'Wyspy Salomona',
  'countries.sc': 'Seszele',
  'countries.sd': 'Sudan',
  'countries.se': 'Szwecja',
  'countries.sg': 'Singapur',
  'countries.sh': 'Święta Helena, Wniebowstąpienia i Tristan da Cunha',
  'countries.si': 'Słowenia',
  'countries.sj': 'Svalbard i Jan Mayen',
  'countries.sk': 'Słowacja',
  'countries.sl': 'Sierra Leone',
  'countries.sm': 'San Marino',
  'countries.sn': 'Senegal',
  'countries.so': 'Somalia',
  'countries.sr': 'Surinam',
  'countries.ss': 'Południowy Sudan',
  'countries.st': 'Wyspy Świętego Tomasza i Książęca',
  'countries.sv': 'Salwador',
  'countries.sx': 'Sint Maarten (część holenderska)',
  'countries.sy': 'Syryjska Republika Arabska',
  'countries.sz': 'Suazi',
  'countries.tc': 'Wyspy Turks i Caicos',
  'countries.td': 'Czad',
  'countries.tf': 'Francuskie Terytoria Południowe',
  'countries.tg': 'Togo',
  'countries.th': 'Tajlandia',
  'countries.tj': 'Tadżykistan',
  'countries.tk': 'Tokelau',
  'countries.tl': 'Timor Wschodni',
  'countries.tm': 'Turkmenistan',
  'countries.tn': 'Tunezja',
  'countries.to': 'Tonga',
  'countries.tr': 'Turcja',
  'countries.tt': 'Trynidad i Tobago',
  'countries.tv': 'Tuvalu',
  'countries.tw': 'Tajwan',
  'countries.tz': 'Tanzania, Zjednoczona Republika',
  'countries.ua': 'Ukraina',
  'countries.ug': 'Uganda',
  'countries.um': 'Dalekie Wyspy Mniejsze Stanów Zjednoczonych',
  'countries.us': 'Stany Zjednoczone',
  'countries.uy': 'Urugwaj',
  'countries.uz': 'Uzbekistan',
  'countries.va': 'Stolica Apostolska (Państwo Watykańskie)',
  'countries.vc': 'Saint Vincent i Grenadyny',
  'countries.ve': 'Wenezuela, Boliwariańska Republika',
  'countries.vg': 'Wyspy Dziewicze, Wielka Brytania',
  'countries.vi': 'Wyspy Dziewicze, USA',
  'countries.vn': 'Wietnam',
  'countries.vu': 'Vanuatu',
  'countries.wf': 'Wallis i Futuna',
  'countries.ws': 'Samoa',
  'countries.ye': 'Jemen',
  'countries.yt': 'Majotta',
  'countries.za': 'Republika Południowej Afryki',
  'countries.zm': 'Zambia',
  'countries.zw': 'Zimbabwe',
  'countryNames.de.long': 'Niemcy',
  'countryNames.de.medium': 'Niemcy',
  'countryNames.de.short': 'DE',
  'countryNames.us.long': 'Stany Zjednoczone',
  'countryNames.us.medium': 'US',
  'countryNames.us.short': 'US',
  'cta.redeemVoucher': 'Zrealizuj kupon',
  'cta.unlockFree': 'Odblokuj bezpłatnie',
  'customCodeVerificationForm.label.dvg': '',
  'customCodeVerificationForm.label.optumrx_1': 'Identyfikator OptumRX',
  'customCodeVerificationForm.overlayButtonText': 'więcej informacji',
  'customCodeVerificationForm.overlayText.dvg': '',
  'customCodeVerificationForm.overlayText.optumrx_1': '',
  'customCodeVerificationForm.placeholder.dvg': '',
  'customCodeVerificationForm.placeholder.optumrx_1': ' ',
  'downloadApp.desktop.text':
    'Skieruj aparat smartfona na <br> poniższy kod QR, aby pobrać aplikację',
  'downloadApp.mobile.text': 'Aplikację możesz pobrać z witryny:',
  'downloadApp.text':
    'Aby dokończyć rejestrację, <b>pobierz aplikację mySugr</b> ze sklepu z aplikacjami na Twój telefon i zaloguj się przy użyciu adresu e-mail.',
  'downloadApp.title': '<b>Jeszcze jedna rzecz!</b>',
  'eligibilityCheck.failure.changeEmailButton': 'Zmień adres e-mail',
  'eligibilityCheck.failure.monsterAlt': '',
  'eligibilityCheck.failure.title': '',
  'eligibilityCheck.failure.unsuccessfulText': '',
  'eligibilityCheck.form.companyEmail.label': '',
  'eligibilityCheck.form.continue': 'Kontynuuj',
  'eligibilityCheck.form.overlayText':
    '<p>Wprowadź adres e-mail podany przez pracodawcę, aby sprawdzić, czy kwalifikujesz się na pakiet mySugr.</p> \n\n<p>Jeżeli kwalifikujesz się na pakiet mySugr, otrzymasz od nas wiadomość e-mail z hiperłączem do zarejestrowania się.</p> \n\n<p>Używamy adresu e-mail podanego przez pracodawcę tylko do sprawdzenia, czy się kwalifikujesz. Po zarejestrowaniu się w programie możesz zalogować się na istniejące konto mySugr lub utworzyć nowe konto mySugr z innym adresem e-mail.</p>',
  'eligibilityCheck.introText': '',
  'eligibilityCheck.pageTitle': '',
  'eligibilityCheck.resend.notification.failure':
    'Nie mogliśmy ponownie wysłać wiadomości e-mail z weryfikacją kwalifikowalności.',
  'eligibilityCheck.resend.notification.success':
    'Ponownie wysłaliśmy wiadomość e-mail z weryfikacją kwalifikowalności.',
  'eligibilityCheck.success.boxAlt': '',
  'eligibilityCheck.success.boxWithHeartAlt': 'Serce wyskakujące z pudełka',
  'eligibilityCheck.success.changeEmailButton': 'Zmień adres e-mail',
  'eligibilityCheck.success.resendButton': 'Wyślij ponownie e-maila',
  'eligibilityCheck.success.resendText': 'Nie otrzymałeś kodu kuponu?',
  'eligibilityCheck.success.successfullText':
    'Twoja organizacja wykupiła pakiet mySugr.<br>\nWysłaliśmy do Ciebie wiadomość e-mail z kodem kuponu, który umożliwia zarejestrowanie się.',
  'eligibilityCheck.success.title':
    'Adres e-mail kwalifikuje się na pakiet mySugr',
  'eligibilityCheck.title': '',
  'email.stepName': 'Rejestracja / Logowanie',
  endsWithNumber: 'Kończy się:',
  'errors.ADYEN_REFUSED_3D_NOT_AUTHENTICATED':
    'Bezpieczne uwierzytelnianie 3D nie powiodło się lub zostało anulowane',
  'errors.ADYEN_REFUSED_3D_SECURE_AUTHENTICATION_FAILED':
    'Bezpieczne uwierzytelnianie 3D nie powiodło się',
  'errors.ADYEN_REFUSED_CVC_DECLINED': 'Numer CVC został odrzucony',
  'errors.ADYEN_REFUSED_EXPIRED_CARD': '',
  'errors.ADYEN_REFUSED_FRAUD': 'Wystąpił problem z Twoją kartą kredytową.',
  'errors.ADYEN_REFUSED_INVALID_CARD_NUMBER': 'Numer karty jest nieprawidłowy.',
  'errors.ADYEN_REFUSED_REFUSED': 'Karta kredytowa została odrzucona',
  'errors.ASSIGN_OPTUM_RX_ERROR.invalid_input':
    'Identyfikator pacjenta OptumRx jest nieprawidłowy',
  'errors.ASSIGN_OPTUM_RX_ERROR.patient_already_assigned':
    'Identyfikator pacjenta OptumRx został już wykorzystany',
  'errors.CARD_DECLINED': 'Karta została odrzucona.',
  'errors.CARD_NOT_SUPPORTED.cardNumber': 'Twoja karta nie jest obsługiwana.',
  'errors.COUNTRY_REGION_MISSMATCH':
    'Oferta, którą próbujesz wykorzystać, jest obecnie niedostępna w :nameCountry. Jest dostępna tylko dla użytkowników, którzy przechowują swoje dane w :offerRegions. Skontaktuj się z obsługą klienta.',
  'errors.DECLINED_CARD.cardNumber': 'Karta została odrzucona.',
  'errors.EMPTY_CART':
    'Twój koszyk jest pusty. Przed uruchomieniem subskrypcji trzeba wybrać pakiet.',
  'errors.EXISTS_IN_OTHER_REGION':
    'Twoje dane użytkownika są przechowywane w innym regionie niż oferta. Ze względu na prywatność nie możesz skorzystać z tej oferty z :offerRegions, jeżeli Twoje dane użytkownika są przechowywane w :userRegions. Skontaktuj się z obsługą klienta.',
  'errors.EXPIRED_CARD.cardNumber': 'Karta utraciła ważność.',
  'errors.INCOMPLETE_CARD_CVC.cvc': 'Numer CVC/CVV jest wymagany.',
  'errors.INCOMPLETE_CARD_EXPIRATION.expiration':
    'Data ważności karty jest wymagana.',
  'errors.INCOMPLETE_CARD_NUMBER.cardNumber': 'Numer karty jest wymagany.',
  'errors.INCORRECT_CVC': 'Numer CVC/CVV jest nieprawidłowy.',
  'errors.INSUFFICIENT_FUNDS':
    'Twoja karta została odrzucona, ponieważ nie ma na niej wystarczających środków.',
  'errors.INVALID_CARD_CVC_NUMBER.cvc': 'Numer CVC/CVV jest nieprawidłowy',
  'errors.INVALID_CARD_EXPIRATION_MONTH_PAST.expiration':
    'Miesiąc ważności już minął.',
  'errors.INVALID_CARD_EXPIRATION_YEAR_PAST.expiration':
    'Rok ważności już minął.',
  'errors.INVALID_CARD_NUMBER.cardNumber': 'Numer karty jest nieprawidłowy.',
  'errors.INVALID_COUNTRY':
    'Ta oferta nie jest dostępna w kraju powiązanym z Twoim kontem użytkownika. Aby rozwiązać problem, skontaktuj się z obsługą klienta.',
  'errors.INVALID_EMAIL_CONFIRMATION_CODE.code':
    'Nieprawidłowy kod potwierdzenia adresu e-mail',
  'errors.INVALID_EMAIL.companyEmail': 'Adres e-mail jest nieprawidłowy',
  'errors.INVALID_EMAIL.email': 'Adres e-mail jest nieprawidłowy',
  'errors.INVALID_GERMAN_STATE.state': 'Wybierz województwo.',
  'errors.INVALID_INSURANCE_CARD_INFO.groupNumber': 'Nieprawidłowy numer',
  'errors.INVALID_INSURANCE_CARD_INFO.memberId': 'Nieprawidłowy identyfikator',
  'errors.INVALID_INSURANCE_NUMBER.insuranceNumber':
    'Nieprawidłowy numer ubezpieczenia',
  'errors.INVALID_LOGIN':
    'Adres e-mail i hasło nie pasują do użytkownika mySugr',
  'errors.INVALID_PASSWORD_CONFIRMATION.password2':
    'Potwierdzenie hasła nie jest zgodne z hasłem.',
  'errors.INVALID_PHONE_NUMBER.phone': 'Numer telefonu jest nieprawidłowy',
  'errors.INVALID_POSTAL_CODE.postalCode': 'Kod pocztowy jest nieprawidłowy',
  'errors.INVALID_US_STATE.state': 'Wybierz państwo.',
  'errors.INVALID_VOUCHER_CODE.voucherCode': 'Nieprawidłowe',
  'errors.INVALID_ZIP_CODE.postalCode': 'Kod pocztowy jest nieprawidłowy',
  'errors.INVALID.customCodeVerification': 'Nieprawidłowy identyfikator',
  'errors.INVALID.customCodeVerification.dvg': '',
  'errors.MIN_LENGTH.password':
    'Hasło musi składać się z co najmniej :minLength znaków.',
  'errors.MIN_LENGTH.password2':
    'Hasło musi składać się z co najmniej :minLength znaków.',
  'errors.MISSING_CONSENT.consents': 'Trzeba zaakceptować :title.',
  'errors.MISSING_CONSENTS':
    'Nie mogliśmy przetworzyć Twojego zamówienia. Skontaktuj się z support@mysugr.com',
  'errors.MISSING_CONSENTS_FAILED':
    'Nie mogliśmy przetworzyć Twojego zamówienia. Skontaktuj się z support@mysugr.com',
  'errors.MISSING_FIELD.address': 'Adres jest wymagany',
  'errors.MISSING_FIELD.city': 'Miejscowość jest wymagana',
  'errors.MISSING_FIELD.companyEmail': '',
  'errors.MISSING_FIELD.country': 'Kraj jest wymagany',
  'errors.MISSING_FIELD.email': 'Adres e-mail jest wymagany',
  'errors.MISSING_FIELD.employer': 'Wybierz pracodawcę',
  'errors.MISSING_FIELD.firstName': 'Imię jest wymagane',
  'errors.MISSING_FIELD.lastName': 'Nazwisko jest wymagane',
  'errors.MISSING_FIELD.name': 'Imię jest wymagane',
  'errors.MISSING_FIELD.password': 'Wprowadź hasło',
  'errors.MISSING_FIELD.password2': 'Powtórz hasło',
  'errors.MISSING_FIELD.phone': 'Numer telefonu jest wymagany',
  'errors.MISSING_FIELD.postalCode': 'Kod pocztowy jest wymagany',
  'errors.MISSING_FIELD.state': 'Państwo jest wymagane',
  'errors.MISSING_FIELD.voucherCode': 'Wprowadź kod swojego kuponu',
  'errors.PASSWORD_RESET_TOKEN_EXPIRED': 'Token resetowania hasła wygasł.',
  'errors.PAYMENT_DETAILS_NOT_SUPPORTED': 'Nieobsługiwany typ karty.',
  'errors.POSSIBLE_PO_BOX.address':
    'Ten adres wydaje się kierować do skrytki pocztowej. Ze względów prawnych nie możemy dostarczyć do skrytek pocztowych.',
  'errors.PRESCRIPTION_ALREADY_ACTIVE': '',
  'errors.PROCESSING_ERROR': 'Nie mogliśmy przetworzyć danych Twojej karty.',
  'errors.SERVER_ERROR': 'Błąd podczas łączenia się z serwerem mySugr',
  'errors.STRIPE_ERROR': 'Nie można zainicjować płatności',
  'errors.THE_VOUCHER_IS_CURRENTLY_NOT_ACTIVE.voucherCode':
    'Ten kupon jest obecnie nieaktywny',
  'errors.undefined': 'Niezdefiniowany błąd',
  'errors.UNKNOWN_ERROR': 'Coś poszło nie tak.',
  'errors.UNKNOWN_ERROR_2': 'Oj! Coś poszło nie tak',
  'errors.UNLOCK_CODE_ALREADY_USED': 'Ten kod aktywacyjny był już użyty.',
  'errors.UNLOCK_CODE_INVALID': '',
  'errors.USER_ALREADY_SUBSCRIBED_TO_BUNDLE':
    'Już jesteś subskrybentem pakietu mySugr.',
  'errors.USER_ALREADY_SUBSCRIBED_TO_PLAN':
    'Już jesteś subskrybentem pakietu mySugr.',
  'errors.USER_EMAIL_HAS_ACTIVE_BUNDLE':
    'Subskrypcja pakietu mySugr dla tego adresu e-mail już istnieje.',
  'errors.USER_EMAIL_HAS_ACTIVE_PRESCRIPTION': '',
  'errors.USER_EXISTS.email': 'Użytkownik już istnieje',
  'errors.USER_HAS_NO_BUNDLE':
    'Zmiana szczegółów płatności jest dostępna tylko wtedy, kiedy posiadasz aktywną subskrypcję pakietu mySugr.',
  'errors.VOUCHER_ALREADY_EXPIRED.voucherCode': 'Ten kupon utracił już ważność',
  'errors.VOUCHER_ALREADY_REDEEMED':
    'Kupon dla tego konta został już zrealizowany',
  'errors.VOUCHER_DOES_NOT_EXISTS.voucherCode': 'Ten kupon nie istnieje',
  'errors.VOUCHER_MAXIMUM_REDEMPTION_EXCEEDED':
    'Ten kod kuponu był już zbyt często wykorzystany i utracił ważność.',
  'errors.VOUCHER_MAXIMUM_REDEMPTION_EXCEEDED.voucherCode':
    'Ten kod kuponu był już zbyt często wykorzystany i utracił ważność.',
  'footer.callSupport': 'Bezpłatna infolinia: <strong>:phone</strong>',
  'footer.happyToHelp': 'Zawsze chętnie pomożemy:',
  'footer.headline': 'Masz pytania dotyczące zamówienia?',
  'footer.legalNotice.activision_blizzard_1': '',
  'footer.legalNotice.allianz_1': '',
  'footer.legalNotice.aok_1': '',
  'footer.legalNotice.aok_nordwest_1': '',
  'footer.legalNotice.barmenia_1': '',
  'footer.legalNotice.barmer_1': '',
  'footer.legalNotice.bi_state_development_1': '',
  'footer.legalNotice.buchanan_us_1': '',
  'footer.legalNotice.dkv_1': '',
  'footer.legalNotice.genentech_1': '',
  'footer.legalNotice.gothaer_1': '',
  'footer.legalNotice.inter_1': '',
  'footer.legalNotice.interstate_cold_storage_1': '',
  'footer.legalNotice.jbz_1': '',
  'footer.legalNotice.lazer_spot_1': '',
  'footer.legalNotice.mastec_1': '',
  'footer.legalNotice.mmc_1': '',
  'footer.legalNotice.neomed_1': '',
  'footer.legalNotice.optumrx_1': '',
  'footer.legalNotice.roche_ponce': '',
  'footer.legalNotice.roche_us_1': '',
  'footer.legalNotice.si_group_1': '',
  'footer.legalNotice.ukv_1': '',
  'footer.legalNotice.vkb_1': '',
  'footer.legalNotice.wth_1': '',
  'footer.links.imprint': 'Impressum',
  'footer.links.privacyNotice': 'Polityka prywatności',
  'footer.links.tcs': 'OWH',
  'footer.subscriptionLegalNotice': '',
  'footer.writeSupport':
    'Albo napisz na adres: <a href="mailto::email">:email</a>',
  'form.availableCountriesLabel': 'Dostępne kraje',
  'groupSelection.continue': 'Zaczynamy',
  'groupSelection.items.description.coach':
    'Wyłączny dostęp do <strong>Coacha</strong>',
  'groupSelection.items.description.guide':
    '<strong>Glukometr</strong> Accu-Chek® Guide',
  'groupSelection.items.description.guide_me_1':
    '<strong>Glukometr</strong> Accu-Chek® Guide Me',
  'groupSelection.items.description.pro':
    '<strong>Aplikacja mySugr</strong> z <strong>opcjami PRO</strong>',
  'groupSelection.items.description.scale_1': '',
  'groupSelection.items.description.strips_subscription_guide':
    '<strong>Automatyczne dostawy testów paskowych</strong> na podstawie zaimportowanych pomiarów',
  'groupSelection.items.description.strips_subscription_guide.2':
    '<strong>Nieograniczone dostawy testów paskowych</strong> na podstawie zaimportowanych pomiarów',
  'groupSelection.message.reimbursement.linkText': 'więcej informacji',
  'groupSelection.message.reimbursement.linkText.dvg': '',
  'groupSelection.message.reimbursement.text': 'Zwrot kosztów do 100%',
  'groupSelection.message.reimbursement.text.dkv': '',
  'groupSelection.message.reimbursement.text.dvg': '',
  'groupSelection.message.roche_bundle_group_coach_1': '',
  'groupSelection.message.roche_bundle_group_guide_1': '',
  'groupSelection.moreInfo': 'POKAŻ WIĘCEJ SZCZEGÓŁÓW',
  'groupSelection.titles.allianz_bundle_group_coach_2': '',
  'groupSelection.titles.allianz_bundle_group_guide_3': '',
  'groupSelection.titles.allianz_bundle_group_guide_4': '',
  'groupSelection.titles.barmenia_bundle_group_coach_2': '',
  'groupSelection.titles.barmenia_bundle_group_guide_2': '',
  'groupSelection.titles.barmenia_bundle_group_guide_3': '',
  'groupSelection.titles.bundle': 'Pakiet mySugr',
  'groupSelection.titles.coach': 'mySugr Coach',
  'groupSelection.titles.gothaer_bundle_group_coach_2': '',
  'groupSelection.titles.gothaer_bundle_group_guide_3': '',
  'groupSelection.titles.roche_bundle_group_coach_1': '',
  'groupSelection.titles.roche_bundle_group_guide_1': '',
  'groupSelection.titles.ukv_bundle_group_coach_2': '',
  'groupSelection.titles.ukv_bundle_group_guide_3': '',
  'groupSelection.titles.vkb_bundle_group_coach_2': '',
  'groupSelection.titles.vkb_bundle_group_guide_3': '',
  'groupSelectionPage.priceFree': 'Gratis',
  'groupSelectionPage.priceFreeExtra': 'dla Ciebie',
  'groupSelectionPage.title': 'Wybierz program mySugr',
  'header.heart.altText': 'serce',
  'headerNotice.bundleNotAvailable':
    'Obecnie nowe subskrypcje nie są akceptowane. Ten pakiet nie jest już dostępny.',
  'headerNotice.bundleNotAvailable.barmer_1': '',
  Help: 'Pomoc',
  'homepage.bundle.text':
    'Aby sprawdzić dostępne <b>pakiety mySugr</b>, wybierz swój kraj:',
  'homepage.bundle.title': 'Pakiet mySugr',
  'homepage.countries.defaultValue': 'Wybierz kraj',
  'homepage.pro.infoText':
    '<b>Aplikacja DVG PRO</b> jest dostępna tylko w Niemczech.',
  'homepage.pro.moreInfo': 'więcej informacji',
  'homepage.pro.moreInfo.dvg': 'Dowiedz się więcej',
  'homepage.pro.text':
    'Aby zrealizować kupon na <strong>aplikację mySugr PRO</strong>:',
  'homepage.pro.title': 'Aplikacja mySugr PRO',
  'homepage.text': 'Witamy w sklepie mySugr!',
  'insuranceCardForm.groupNumberLabel': 'Identyfikator grupy ubezpieczeniowej',
  'insuranceCardForm.groupNumberLabel.mastec': '',
  'insuranceCardForm.groupNumberLabel.roche-benefits-us': '',
  'insuranceCardForm.groupNumberLabel.roche-benefits-us-coach': '',
  'insuranceCardForm.groupNumberPlaceholder': '000123',
  'insuranceCardForm.groupNumberPlaceholder.ActivisionBlizzard': 'W1234567',
  'insuranceCardForm.groupNumberPlaceholder.buchanan-group-us': '',
  'insuranceCardForm.groupNumberPlaceholder.interstatecoldstorage': 'IN1234567',
  'insuranceCardForm.groupNumberPlaceholder.lazerspot': '000ABC123',
  'insuranceCardForm.groupNumberPlaceholder.mastec': 'PHRASE01',
  'insuranceCardForm.infoTextGeneric':
    'Wprowadź identyfikator swojej grupy ubezpieczeniowej i swój identyfikator członkowski, które widnieją na karcie członkowskiej.',
  'insuranceCardForm.infoTextGeneric.ActivisionBlizzard': '',
  'insuranceCardForm.infoTextGeneric.kaiser.roche-benefits-us': '',
  'insuranceCardForm.infoTextGeneric.mastec': '',
  'insuranceCardForm.infoTextGeneric.roche-benefits-us-coach': '',
  'insuranceCardForm.memberIdLabel': 'Karta członkowska',
  'insuranceCardForm.memberIdLabel.ActivisionBlizzard': '',
  'insuranceCardForm.memberIdLabel.kaiser.roche-benefits-us': '',
  'insuranceCardForm.memberIdLabel.roche-benefits-us': '',
  'insuranceCardForm.memberIdLabel.roche-benefits-us-coach': '',
  'insuranceCardForm.memberIdPlaceholder': '123456789',
  'insuranceCardForm.memberIdPlaceholder.ActivisionBlizzard': 'AZO 12345678X',
  'insuranceCardForm.memberIdPlaceholder.buchanan-group-us': '',
  'insuranceCardForm.memberIdPlaceholder.kaiser.roche-benefits-us': '',
  'insuranceCardForm.memberIdPlaceholder.lazerspot': 'ABC12345678C',
  'insuranceCardForm.memberIdPlaceholder.mastec': 'ABC0123456789',
  'insuranceNumberForm.label': 'Numer ubezpieczenia',
  'insuranceNumberForm.overlayButtonText': 'więcej informacji',
  'languages.de': 'Deutsch',
  'languages.en': 'English',
  'languages.es': 'Español',
  'languages.nl': 'Nederlands',
  languageSelectLabel: 'Zmień ustawienia języka',
  'login.pageName': 'Login',
  'logoText.mySugr': 'Program mySugr',
  'logoText.payor': 'we współpracy z: payor',
  'measurementUnitSamples.mg/dl': '70 - 180',
  'measurementUnitSamples.mmol/l': '3,9 - 8,9',
  'notFound.lead': 'To, czego szukasz, już nie istnieje.',
  'notFound.text':
    'Strona, której szukasz, nie istnieje lub została przeniesiona w inne miejsce. <a href="mailto::email">Skontaktuj się z naszym zespołem</a> w celu uzyskania pomocy.',
  'notFound.title': 'Strona wycofana',
  'notifications.CARD_DECLINED': 'Twoja karta została odrzucona.',
  'notifications.dismissNotification': 'Odrzuć powiadomienie',
  'notifications.EXPIRED_CARD': 'Twoja karta utraciła ważność.',
  'notifications.IE11_NOT_SUPPORTED':
    'Twoja przeglądarka (Internet Explorer 11) nie jest obecnie obsługiwana. Mogą pojawić się problemy podczas przeglądania naszej strony. Zachęcamy do użycia innej przeglądarki.',
  'notifications.INCORRECT_CVC': 'Numer CVC/CVV jest nieprawidłowy.',
  'notifications.INCORRECT_NUMBER':
    'Numer karty kredytowej jest nieprawidłowy.',
  'notifications.INSUFFICIENT_FUNDS':
    'Twoja karta została odrzucona, ponieważ nie ma na niej wystarczających środków.',
  'notifications.PROCESSING_ERROR':
    'Nie mogliśmy przetworzyć danych Twojej karty.',
  'notifications.SERVER_ERROR': 'Błąd podczas łączenia się z serwerem mySugr',
  'notifications.STRIPE_ERROR': 'Nie można zainicjować Stripe',
  'notifications.TRIGGER_EMAIL_VERIFICATION_SUCCESS':
    'E-mail został właśnie wysłany',
  'notifications.UNDEFINED_ERROR': 'Przepraszamy, coś poszło nie tak.',
  'notifications.UNKNOWN_ERROR': 'Coś poszło nie tak.',
  'notifications.UNKNOWN_ERROR_2': 'Oj! Coś poszło nie tak',
  'notifications.USER_ALREADY_SUBSCRIBED_TO_BUNDLE':
    'Już jesteś subskrybentem pakietu mySugr',
  'notifications.USER_ALREADY_SUBSCRIBED_TO_PLAN':
    'Już jesteś subskrybentem pakietu mySugr.',
  'numbers.0': 'zero',
  'numbers.1': 'jeden',
  'numbers.2': 'dwa',
  'numbers.3': 'trzy',
  'numbers.4': 'cztery',
  'numbers.5': 'pięć',
  'numbers.6': 'sześć',
  'numbers.7': 'siedem',
  'numbers.8': 'osiem',
  'numbers.9': 'dziewięć',
  'numbers.10': 'dziesięć',
  'offlinePage.subtitle': 'Sprawdź swoje połączenie.',
  'offlinePage.title': 'Ups, jesteś w trybie offline',
  optionalConsent: 'Opcja:',
  optionalPlaceholder: 'Opcja',
  'overlay.close': 'Zamknij nakładkę',
  'overview.address.phone': 'Telefon:',
  'overview.autoCancelInfo.DAY.1':
    'Pakiet kończy się automatycznie po pierwszym dniu.',
  'overview.autoCancelInfo.DAY.2':
    'Pakiet kończy się automatycznie po :count dniach.',
  'overview.autoCancelInfo.MONTH.1':
    'Pakiet kończy się automatycznie po pierwszym miesiącu.',
  'overview.autoCancelInfo.MONTH.2':
    'Pakiet kończy się automatycznie po :count miesiącach.',
  'overview.autoCancelInfo.WEEK.2': '',
  'overview.autoCancelInfo.YEAR.1':
    'Pakiet kończy się automatycznie po pierwszym roku.',
  'overview.autoCancelInfo.YEAR.2':
    'Pakiet kończy się automatycznie po :count latach.',
  'overview.backButton': 'Wróć',
  'overview.billingAddress.edit': 'Edytuj',
  'overview.billingAddress.title': 'Dane do faktury',
  'overview.cancelationInfo.MONTH.1':
    'Możesz anulować subskrypcję w dowolnym momencie po pierwszym miesiącu.',
  'overview.cancelationInfo.MONTH.2':
    'Możesz anulować subskrypcję w dowolnym momencie po pierwszych :count miesiącach.',
  'overview.cancelationInfo.YEAR.1':
    'Możesz anulować subskrypcję w dowolnym momencie po pierwszym roku.',
  'overview.cancelationInfo.YEAR.2':
    'Możesz anulować subskrypcję w dowolnym momencie po pierwszych :count latach.',
  'overview.coachLanguageOverview.change': 'Zmień',
  'overview.coachLanguageOverview.title': 'Język Coacha',
  'overview.contactDetails.edit': 'Edytuj',
  'overview.contactDetails.title': 'Dane kontaktowe',
  'overview.continueButton': 'Zamówienie',
  'overview.continueButton.at': 'Zamówienie',
  'overview.continueButton.at.fallback': 'Zamówienie',
  'overview.continueButton.ca.fallback': '',
  'overview.continueButton.de': 'Zamówienie',
  'overview.continueButton.de.fallback': 'Zamówienie',
  'overview.continueButton.es': 'Zamówienie',
  'overview.continueButton.es.fallback': 'Zamówienie',
  'overview.continueButton.nl.fallback': 'Zarejestruj się',
  'overview.continueButton.sg.fallback': 'Zarejestruj się',
  'overview.continueButton.us': 'Subskrybuj',
  'overview.continueButton.us.fallback': 'Zarejestruj się',
  'overview.continueButton.us.optumrx_bundle_1': '',
  'overview.continueButton.us.us_bundle_3': 'Subskrybuj',
  'overview.enrollmentTitle': 'Rejestracja w programie',
  'overview.freeShipping': 'Bezpłatna dostawa',
  'overview.measurementUnitOverview.change': 'Zmień',
  'overview.measurementUnitOverview.mgdl': 'mg/dL',
  'overview.measurementUnitOverview.mmoll': 'mmol/L',
  'overview.measurementUnitOverview.title': 'Jednostka miary',
  'overview.pageName': 'Przegląd zamówienia',
  'overview.paymentOverview.change': 'Edytuj',
  'overview.paymentOverview.creditCard': 'Karta kredytowa',
  'overview.paymentOverview.payment': 'Płatność',
  'overview.payorCovered':
    'Program ten jest w pełni sponsorowany przez twojego ubezpieczyciela i masz do niego dostęp bezpłatnie.',
  'overview.payorCovered.ActivisionBlizzard': '',
  'overview.payorCovered.aok_nordwest_bundle_1': '',
  'overview.payorCovered.bi_state_development_bundle_1':
    'Program ten jest w pełni sponsorowany przez twojego pracodawcę i masz do niego dostęp bezpłatnie.',
  'overview.payorCovered.buchanan-group-us': '',
  'overview.payorCovered.dec_i_bundle_1': '',
  'overview.payorCovered.dec_ii_bundle_1': '',
  'overview.payorCovered.genentech_bundle_1': '',
  'overview.payorCovered.interstate_cold_storage_bundle_1':
    'Program ten jest w pełni sponsorowany przez twojego pracodawcę i masz do niego dostęp bezpłatnie.',
  'overview.payorCovered.lazer_spot_bundle_1': '',
  'overview.payorCovered.mastec_bundle_1': '',
  'overview.payorCovered.neomed':
    'Program ten jest w pełni sponsorowany przez twojego pracodawcę i masz do niego dostęp bezpłatnie.',
  'overview.payorCovered.onemed_bundle_1': '',
  'overview.payorCovered.optumrx_bundle_1': '',
  'overview.payorCovered.roche-benefits-us': '',
  'overview.payorCovered.roche-benefits-us-coach': '',
  'overview.payorCovered.roche-ponce': '',
  'overview.payorCovered.servidigest_bundle_1': '',
  'overview.payorCovered.si-group': '',
  'overview.payorCovered.zdg_bundle_1': '',
  'overview.seller': 'Sprzedający',
  'overview.shipmentAddress.edit': 'Edytuj',
  'overview.shipmentAddress.title': 'Wysyłka',
  'overview.shipmentOverview.edit': 'Edytuj',
  'overview.shipmentOverview.title': 'Adres dostawy',
  'overview.stepName': 'Przegląd',
  'overview.subscribeNotice':
    'Klikając przycisk zamówienia, potwierdzasz, że masz powyżej :minAge lat.',
  'overview.title': 'Prawie gotowe',
  pageTitle: '',
  'pageTitle.flowTitle.withPayor': 'mySugr i :payorName',
  'pageTitle.flowTitle.withProductAndPayor': ':productName i :payorName',
  'pageTitle.withFlow': ':flowTitle',
  'pageTitle.withStep': ':stepTitle | Sklep mySugr',
  'pageTitle.withStepAndFlow': ':stepTitle | :flowTitle',
  'passwordInput.hidePasswordButton': 'Ukryj',
  'passwordInput.showPasswordButton': 'Pokaż',
  'passwordReset.pageName': 'Zresetuj hasło',
  'passwordResetSuccessPage.buttonText': 'Kontynuuj',
  'passwordResetSuccessPage.congratsImageAlt':
    'Gratulacje, fruwające konfetti,',
  'passwordResetSuccessPage.text':
    'Pamiętaj, że Twoje hasło zmieniło się również w aplikacji mySugr.',
  'passwordResetSuccessPage.title': 'Hasło zmienione!',
  'payment.cardInfo.backButton': 'Wróć',
  'payment.cardInfo.changeCard': 'Zmień kartę',
  'payment.cardInfo.continueButton': 'Kontynuuj',
  'payment.cardInfo.discardButton': 'Powrót do przeglądu',
  'payment.cardInfo.expires': 'Ważność :month/:year',
  'payment.cardInfo.usingText':
    'Płacisz za pomocą karty kredytowej <strong>:last4</strong>.',
  'payment.form.backButton': 'Wróć',
  'payment.form.cardNumberLabel': 'Numer karty',
  'payment.form.continueButton': 'Kontynuuj',
  'payment.form.cvcLabel': 'CVC/CVV',
  'payment.form.discardButton': 'Odrzuć zmiany',
  'payment.form.expirationLabel': 'Termin ważności',
  'payment.form.firstNameLabel': 'Imię',
  'payment.form.lastNameLabel': 'Nazwisko',
  'payment.form.nameLabel': 'Imię na karcie',
  'payment.form.sameAsShipment': 'Adres na fakturze taki sam jak adres wysyłki',
  'payment.form.saveChangesButton': 'Zapisz zmiany',
  'payment.stepName': 'Płatność',
  'payment.title': 'Dane <strong>płatności</strong>',
  'paymentDetails.list.info':
    'Ta karta będzie obciążana z tytułu subskrypcji na pakiet.',
  'paymentDetails.list.title': 'Dane płatności',
  'paymentDetails.login.title': 'Zaloguj się, aby zmienić szczegóły płatności',
  'paymentLogos.title': 'Obsługiwane metody płatności:',
  'product.bundleBoxImage.alt': 'Pakiet mySugr',
  'product.bundleBoxImage.alt.bundle_coach':
    'Pakiet mySugr: pudełko z logo mySugr otoczone zawartością pakietu z aplikacją mySugr otwartą w telefonie i chmurką czatu nad telefonem.',
  'product.bundleBoxImage.alt.bundle_guide':
    'Pakiet mySugr: pudełko z logo mySugr otoczone zawartością pakietu.',
  'product.bundleBoxImage.alt.bundle_guide_me':
    'Pakiet mySugr: pudełko z logo mySugr otoczone zawartością pakietu.',
  'product.bundleBoxImage.alt.coach':
    'Ilustracja telefonu z otwartą aplikacją mySugr i chmurką czatu obok telefonu oddzieloną znakiem plus.',
  'product.bundleBoxImage.alt.pro': 'Telefon z gwiazdką i napisem „Pro”',
  'product.continueButton': '',
  'product.items.moreInfo.dvg': 'więcej informacji',
  'product.items.overlayText.dvg': '',
  'product.stepName': 'Produkt',
  'product.submitButton': '',
  'product.title': 'Pakiet mySugr',
  'productName.bundle': 'Pakiet mySugr',
  'productName.coach': 'mySugr Coach',
  'promotion.APRILFOOLS2019_PROMOTION_PRICE_TEXT': 'co miesiąc po',
  'promotion.APRILFOOLS2019_PROMOTION_TEXT': '1 miesiąc bezpłatnie',
  'proVoucher.pageName': 'Zrealizuj kupon PRO',
  'proVoucherInfo.checklist.challenges':
    'Bierz udział w <strong>wyzwaniach</strong>',
  'proVoucherInfo.checklist.pdf': 'Raporty <strong>PDF</strong>',
  'proVoucherInfo.checklist.photos':
    '<strong>Rób zdjęcia</strong> swoich posiłków',
  'proVoucherInfo.checklist.reminders':
    'Otrzymuj automatyczne <strong>przypomnienia</strong>',
  'proVoucherInfo.checklist.search':
    '<strong>Szukaj</strong> - z łatwością znajdź swoje wpisy',
  'proVoucherInfo.checklist.sync':
    'Synchronizuj <strong>wiele urządzeń</strong>',
  'proVoucherInfo.ctaButtonText': 'Aktywuj kupon teraz',
  'proVoucherInfo.headline': 'Co aktywuje Twój kupon...',
  'proVoucherInfo.imageTitle': 'mySugr PRO',
  'proVoucherInfo.stepName': 'Kupon PRO',
  'proVoucherInfo.validDate':
    'Funkcje będą odblokowane do dnia: <strong>:date</strong>.',
  'proVoucherInfo.validTimeMonths':
    'Funkcje będą odblokowane przez następującą liczbę miesięcy: <strong>:months</strong>.',
  'proVoucherInfo.validTimeUnlimited':
    'Uzyskasz <strong>nieograniczony</strong> dostęp do funkcji.',
  'proVoucherInfo.voucherCodeValid':
    'Twój kod kuponu <code>:voucherCode</code> jest ważny.',
  readMoreBundleContentInformation: 'Dowiedz się więcej o zawartości pakietu',
  'register.pageName': 'Rejestracja',
  'reimbursement.overlay.text':
    '<h4>Jaką kwotę zwrotu otrzymam?</h4>\n<p>Tyle samo, ile otrzymujesz, kupując testy paskowe w aptece. W zależności od taryfy ubezpieczeniowej będzie to nawet 100%.</p>\n\n<h4>Uzyskanie zwrotu jest bardzo łatwe:</h4>\n<p>Kiedy zakupisz pakiet, wyślemy Ci pocztą e-mail fakturę. Możesz ją wydrukować i przesłać wraz z wnioskiem o zwrot do swojego ubezpieczyciela.</p>',
  'reimbursement.overlay.text.barmenia': '',
  'reimbursement.overlay.text.dkv': '',
  'reimbursement.overlay.text.gothaer': '',
  'reimbursement.overlay.text.ukv': '',
  'reimbursement.overlay.text.vkb': '',
  'reimbursement.overlay.title': 'Pakiet mySugr',
  'reimbursement.overlay.title.allianz_bundle_group_coach_2': '',
  'reimbursement.overlay.title.barmenia_bundle_group_coach_2': '',
  'reimbursement.overlay.title.bundle': 'Pakiet mySugr',
  'reimbursement.overlay.title.coach': 'Pakiet „Edukator diabetologiczny”',
  'reimbursement.overlay.title.coach.dkv': '',
  'reimbursement.overlay.title.gothaer_bundle_group_coach_2': '',
  'reimbursement.overlay.title.ukv_bundle_group_coach_2': '',
  'reimbursement.overlay.title.vkb_bundle_group_coach_2': '',
  'selectCountry.selectCountryLabel': 'Wybierz kraj',
  'selectPayor.otherInsuranceButton': '',
  'selectPayor.otherInsuranceExplanation': '',
  'selectPayor.pleaseSelect': '',
  'selectPayor.title': '',
  'shipment.contactDataForCoaches':
    'Twoje dane kontaktowe zostaną przekazane tylko Twojemu osobistemu Coachowi.',
  'shipment.form.address2Label': 'Mieszkanie, apartament itp.',
  'shipment.form.addressHint': 'Nie skrytka pocztowa',
  'shipment.form.addressLabel': 'Adres',
  'shipment.form.addressPlaceholder.germany': 'Numer domu/klatki/mieszkania',
  'shipment.form.backButton': 'Wróć',
  'shipment.form.billingAddressTitle': 'Adres na fakturze',
  'shipment.form.cityLabel': 'Miasto',
  'shipment.form.continueButton': 'Kontynuuj',
  'shipment.form.discardChangesButton': 'Odrzuć zmiany',
  'shipment.form.emailLabel': 'Adres e-mail',
  'shipment.form.employerDefaultOption': 'Wybierz pracodawcę',
  'shipment.form.employerHint': '',
  'shipment.form.employerLabel': 'Pracodawca',
  'shipment.form.firstNameLabel': 'Imię',
  'shipment.form.lastNameLabel': 'Nazwisko',
  'shipment.form.memberClass.dependentLabel': 'Osoba na utrzymaniu',
  'shipment.form.memberClass.employeeLabel': 'Pracownik',
  'shipment.form.memberClass.explanation': 'Relacja z beneficjentem polisy',
  'shipment.form.phoneLabel': 'Numer telefonu',
  'shipment.form.policy.headline': 'Dane polisy ubezpieczeniowej',
  'shipment.form.postalCodeLabel': 'Kod pocztowy',
  'shipment.form.postalCodeLabel_new': 'Kod pocztowy',
  'shipment.form.saveChangesButton': 'Zapisz zmiany',
  'shipment.form.stateDefaultOption': 'Wybierz państwo',
  'shipment.form.stateLabel': 'Stan',
  'shipment.form.zipCodeLabel': 'Kod pocztowy',
  'shipment.stepName': 'Wysyłka',
  'shipment.title': 'Dane <strong>przesyłki</strong>',
  'shoppingCart.deliverOnce': '(dostarcz jeden raz)',
  'shoppingCart.immediateTitle': 'Natychmiastowy dostęp do:',
  'shoppingCart.productTitle': 'Twoje pudełko dotrze w ciągu kilku dni:',
  'shoppingCart.subscriptionTitle': 'Nieprzerwany dostęp do:',
  'shoppingCart.title': 'Twój pakiet zawiera:',
  'shoppingCart.title.dvg': '',
  'shoppingCart.title.roche-benefits-us': '',
  'shoppingCart.title.roche-benefits-us-coach': '',
  'stepBar.activeStep.screenReader': '(aktualny etap)',
  'stepBar.completedStep.screenReader': '(etap ukończony)',
  'stepBar.notCompletedStep.screenReader': '(etap nieukończony)',
  'stepBar.remaining':
    'Od Twojego pakietu dzielą Cię tylko <strong>:remaining steps</strong> kroki!',
  'stepBar.title.screenReader': 'Etapy rejestrowania się w pakiecie mySugr',
  stepIndicationText: 'Etap :current z :total',
  'success.coach.text':
    'W aplikacji czeka już na Ciebie wiadomość od Twojego Coacha.',
  'success.coach.title':
    '<b>Przywitaj się</b> ze swoim <b>osobistym Coachem</b>',
  'success.doneButton': 'Gotowe',
  'success.download.bundle.text':
    '<b>Monitoruj</b> swoje <b>codzienne pomiary</b> a my będziemy liczyć, ile testów zużywasz.',
  'success.download.coach.text':
    '<b>Monitoruj</b> swoje <b>codzienne pomiary</b> i panuj nad swoją cukrzycą.',
  'success.download.title': 'Następnie <b>pobierz</b> aplikację <b>mySugr</b>',
  'success.help':
    'Jeżeli cokolwiek nie jest do końca jasne, skontaktuj się z naszym wspaniałym zespołem obsługi klienta.',
  'success.nextStep1':
    '<strong>Pobierz</strong> <strong>aplikację</strong> mySugr',
  'success.nextStep2':
    '<strong>Zaloguj się</strong> używając swojego adresu e-mail <strong>:email</strong> i <strong>hasła</strong>',
  'success.nextStep3': '<strong>Wszystko gotowe!</strong>',
  'success.orderSuccess.coachText':
    'Przejdź do kolejnych etapów, aby skontaktować się ze swoim Coachem.',
  'success.orderSuccess.deliveryText':
    'Wyślemy do Ciebie wiadomość e-mail ze szczegółami Twojej pierwszej dostawy.\n\n<b>Wszystkie kolejne dostawy będą uruchamiane z aplikacji.</b>',
  'success.orderSuccess.title':
    'Twoje zamówienie zostało złożone <b>prawidłowo</b>',
  'success.pageFooterTitle':
    'Wykonaj tych kilka prostych czynności i bądź na bieżąco!',
  'success.pageName': 'Zamówienie prawidłowo złożone',
  'success.proVoucherInfo.validDate':
    'Funkcje będą odblokowane do dnia: <strong>:date</strong>.',
  'success.proVoucherInfo.validTimeMonths':
    'Twoje funkcje PRO są teraz odblokowane na następującą liczbę miesięcy: <strong>:months</strong>.',
  'success.proVoucherInfo.validTimeUnlimited':
    'Masz teraz <strong>nieograniczony</strong> dostęp do wszystkich funkcji PRO.',
  'success.proVoucherInfo.validTimeUnlimited.dvg':
    'Masz teraz dostęp do wszystkich funkcji PRO.',
  'success.shippingInfo':
    'Twój pakiet dotrze w ciągu <strong>5-7 dni roboczych</strong>.',
  'success.shippingInfoDynamic':
    'Twój pakiet dotrze w ciągu <strong>:time dni roboczych</strong>.',
  'success.shippingMoreInfo':
    'Po otrzymaniu pakietu podłącz glukometr i zacznij rejestrować jak najwięcej danych. Pamiętaj, aby <b>synchronizować je z aplikacją</b> - tylko w ten sposób będziemy w stanie stwierdzić, kiedy wysłać Ci <b>dodatkowe testy paskowe</b>.',
  'success.stepName': 'Złożone',
  'success.title':
    'Twoje zamówienie zostało <strong>pomyślnie złożone!</strong>',
  'success.titleProOnly':
    'Gratulacje,<br> jesteś teraz <strong>użytkownikiem PRO</strong>!',
  'success.trackingInfo':
    'W ciągu dwóch dni wyślemy do Ciebie wiadomość e-mail z numerem przesyłki, dzięki któremu możesz dokładnie monitorować dostawę!',
  'unitSelection.buttonText': 'Wybierz',
  'unitSelection.label': 'Wybór jednostki',
  'unitSelection.labelClose': 'Zamknij wybór jednostki',
  'unitSelection.title': 'Wybierz <strong>jednostkę miary</strong>',
  'voucher.pageName': 'Zrealizuj kupon',
  'voucherForm.buttonText': 'Sprawdź kupon',
  'voucherForm.label': 'Kod kuponu',
  'voucherForm.overlayButtonText': 'więcej informacji',
  'voucherForm.overlayText':
    'Możesz znaleźć swój 12-cyfrowy kod kuponu w liście otrzymanym od ubezpieczyciela. Jeżeli nie otrzymałeś kodu kuponu, skontaktuj się ze swoim ubezpieczycielem.',
  'voucherForm.overlayText.aok_1': '',
  'voucherForm.overlayText.aok_nordwest_1': '',
  'voucherForm.overlayText.barmer_1': '',
  'voucherForm.overlayText.bi_state_development_1':
    'Wprowadź 12-cyfrowy kod kuponu, który otrzymałeś, aby uzyskać dostęp do tej oferty.',
  'voucherForm.overlayText.dkv': '',
  'voucherForm.overlayText.genentech_1': '',
  'voucherForm.overlayText.jbz_1': '',
  'voucherForm.overlayText.mmc_1': '',
  'voucherForm.overlayText.onemed_1': '',
  'voucherForm.overlayText.roche_pl_1': '--- BRAK ---',
  'voucherForm.overlayText.roche_sg_1': '',
  'voucherForm.overlayText.schwenninger_1': '',
  'voucherForm.overlayText.servidigest_1': '',
  'voucherForm.overlayText.si_group_1': '',
  'voucherForm.overlayText.zdg_1': '',
  'voucherForm.title': 'Zrealizuj swój kupon!',
};
