import { BACKEND, BACKEND_EU, BACKEND_US } from 'src/constants/backends';
import { COUNTRY_US } from 'src/constants/countries';
import IBundle from 'src/types/IBundle';

const getBundleBackendRegion = (bundle?: IBundle): BACKEND => {
  const bundleCountry = bundle?.country?.toLowerCase();

  if (bundleCountry === COUNTRY_US) {
    return BACKEND_US;
  }

  return BACKEND_EU;
};

export default getBundleBackendRegion;
