/* istanbul ignore file */

export const TOP_BAR_MESSAGE = 'shop.text.top.bar.message';
export type TOP_BAR_MESSAGE = typeof TOP_BAR_MESSAGE;

export const MORE_INFO_OVERLAY = 'shop.text.more.info.overlay';
export type MORE_INFO_OVERLAY = typeof MORE_INFO_OVERLAY;

export const ENROLLMENT_BARRIER_INFO_INSURANCE_CARD =
  'shop.text.enrollment.barrier.info.insurance.card';
export type ENROLLMENT_BARRIER_INFO_INSURANCE_CARD =
  typeof ENROLLMENT_BARRIER_INFO_INSURANCE_CARD;

export const ENROLLMENT_BARRIER_INFO_VOUCHER_CODE =
  'shop.text.enrollment.barrier.info.voucher.code';
export type ENROLLMENT_BARRIER_INFO_VOUCHER_CODE =
  typeof ENROLLMENT_BARRIER_INFO_VOUCHER_CODE;

export const INSURANCE_CODE_INPUT_FIELD_LABEL_1 =
  'shop.text.insurance.code.input.field.label.1';
export type INSURANCE_CODE_INPUT_FIELD_LABEL_1 =
  typeof INSURANCE_CODE_INPUT_FIELD_LABEL_1;

export const INSURANCE_CODE_INPUT_FIELD_LABEL_2 =
  'shop.text.insurance.code.input.field.label.2';
export type INSURANCE_CODE_INPUT_FIELD_LABEL_2 =
  typeof INSURANCE_CODE_INPUT_FIELD_LABEL_2;

export const INSURANCE_CODE_INPUT_FIELD_PLACEHOLDER_1 =
  'shop.text.insurance.code.input.field.placeholder.1';
export type INSURANCE_CODE_INPUT_FIELD_PLACEHOLDER_1 =
  typeof INSURANCE_CODE_INPUT_FIELD_PLACEHOLDER_1;

export const INSURANCE_CODE_INPUT_FIELD_PLACEHOLDER_2 =
  'shop.text.insurance.code.input.field.placeholder.2';
export type INSURANCE_CODE_INPUT_FIELD_PLACEHOLDER_2 =
  typeof INSURANCE_CODE_INPUT_FIELD_PLACEHOLDER_2;

export const INSURANCE_NUMBER_INPUT_FIELD_LABEL =
  'shop.text.insurance.number.input.field.label';
export type INSURANCE_NUMBER_INPUT_FIELD_LABEL =
  typeof INSURANCE_NUMBER_INPUT_FIELD_LABEL;

export const INSURANCE_NUMBER_INPUT_FIELD_PLACEHOLDER =
  'shop.text.insurance.number.input.field.placeholder';
export type INSURANCE_NUMBER_INPUT_FIELD_PLACEHOLDER =
  typeof INSURANCE_NUMBER_INPUT_FIELD_PLACEHOLDER;

export type CustomTextKey =
  | TOP_BAR_MESSAGE
  | MORE_INFO_OVERLAY
  | ENROLLMENT_BARRIER_INFO_INSURANCE_CARD
  | ENROLLMENT_BARRIER_INFO_VOUCHER_CODE
  | INSURANCE_CODE_INPUT_FIELD_LABEL_1
  | INSURANCE_CODE_INPUT_FIELD_LABEL_2
  | INSURANCE_CODE_INPUT_FIELD_PLACEHOLDER_1
  | INSURANCE_CODE_INPUT_FIELD_PLACEHOLDER_2
  | INSURANCE_NUMBER_INPUT_FIELD_LABEL
  | INSURANCE_NUMBER_INPUT_FIELD_PLACEHOLDER;
