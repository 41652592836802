import {
  IOrderBundleSuccessAction,
  ORDER_BUNDLE_SUCCESS_ACTION,
} from 'src/actions/bundleActions';
import {
  CHECK_USER_BUNDLE_FAILURE_ACTION,
  CHECK_USER_BUNDLE_SUCCESS_ACTION,
  ICheckUserBundleFailureAction,
  ICheckUserBundleSuccessAction,
} from 'src/actions/checkUserBundleAction';
import {
  CONFIRM_EMAIL_FAILURE_ACTION,
  CONFIRM_EMAIL_SUCCESS_ACTION,
  ConfirmEmailAction,
} from 'src/actions/confirmEmailActions';
import {
  CHANGE_EMAIL_ACTION,
  ILoginFailureAction,
  ILoginFormAction,
  ILoginSuccessAction,
  IRemoveLoginErrorAction,
  LOGIN_FAILURE_ACTION,
  LOGIN_FORM_ACTION,
  LOGIN_SUCCESS_ACTION,
  LoginAction,
  REMOVE_LOGIN_ERROR_ACTION,
  ISetAccessTokenAction,
  SET_ACCESS_TOKEN_ACTION,
} from 'src/actions/loginActions';
import { ILogoutAction, LOGOUT_ACTION } from 'src/actions/logoutActions';
import {
  IRequestPasswordResetFailureAction,
  PasswordResetAction,
  REQUEST_PASSWORD_RESET_FAILURE_ACTION,
  REQUEST_PASSWORD_RESET_SUCCESS_ACTION,
} from 'src/actions/passwordResetActions';
import {
  IRequestResetPasswordTokenFailureAction,
  IRequestResetPasswordTokenSuccessAction,
  IResetPasswordFailureAction,
  REQUEST_RESET_PASSWORD_TOKEN_FAILURE_ACTION,
  REQUEST_RESET_PASSWORD_TOKEN_SUCCESS_ACTION,
  RESET_PASSWORD_FAILURE_ACTION,
  RESET_PASSWORD_SUCCESS_ACTION,
  ResetPasswordAction,
} from 'src/actions/resetPasswordActions';
import {
  TRIGGER_EMAIL_VERIFICATION_FAILURE_ACTION,
  TRIGGER_EMAIL_VERIFICATION_SUCCESS_ACTION,
  TriggerEmailVerificationAction,
} from 'src/actions/triggerEmailVerificationActions';
import {
  ISetAppUserAction,
  SET_APP_USER_ACTION,
} from 'src/actions/userActions';
import {
  IUserExistsFailureAction,
  IUserExistsFormAction,
  IUserExistsSuccessAction,
  USER_EXISTS_FAILURE_ACTION,
  USER_EXISTS_FORM_ACTION,
  USER_EXISTS_SUCCESS_ACTION,
  UserExistsAction,
} from 'src/actions/userExistsActions';
import getChangedFormFields from 'src/lib/getChangedFormFields';
import removeError from 'src/lib/removeError';
import removeErrorsForFields from 'src/lib/removeErrorsForFields';
import ILoginForm from 'src/types/ILoginForm';
import ILoginState from 'src/types/ILoginState';

const initialForm: ILoginForm = { email: '', password: '' };

export const initialState: ILoginState = {
  appUser: false,
  authToken: undefined,
  checkedIfUserExists: false,
  errors: [],
  form: { ...initialForm },
  loggedIn: false,
  passwordChanged: false,
  requestedPasswordReset: false,
  user: { email: '', password: '' },
  userExists: false,
};

function handleLoginSuccess(
  state: ILoginState,
  action: ILoginSuccessAction,
): ILoginState {
  return {
    ...state,
    errors: [],
    loggedIn: true,
    requestedPasswordReset: false,
    user: action.user,
  };
}

function handleLoginFailure(
  state: ILoginState,
  action: ILoginFailureAction,
): ILoginState {
  return {
    ...state,
    errors: action.errors,
    loggedIn: false,
    requestedPasswordReset: false,
    user: { email: '', password: '' },
  };
}

function handleCheckUserBundleSuccess(state: ILoginState): ILoginState {
  return {
    ...state,
    errors: [],
  };
}

function handleCheckUserBundleFailure(
  state: ILoginState,
  action: ICheckUserBundleFailureAction,
): ILoginState {
  return {
    ...state,
    errors: action.errors,
  };
}

function handleUserExistsSuccess(
  state: ILoginState,
  action: IUserExistsSuccessAction,
): ILoginState {
  return {
    ...state,
    checkedIfUserExists: true,
    errors: [],
    form: { email: action.form.email },
    userExists: action.userExists,
  };
}

function handleUserExistsFailure(
  state: ILoginState,
  action: IUserExistsFailureAction,
): ILoginState {
  return {
    ...state,
    checkedIfUserExists: false,
    errors: [...action.errors],
    userExists: false,
  };
}

function handleChangeEmail(state: ILoginState): ILoginState {
  return {
    ...state,
    checkedIfUserExists: false,
    errors: [],
    form: { email: state.form.email },
  };
}

function handleRequestPasswordResetSuccess(state: ILoginState): ILoginState {
  return {
    ...state,
    errors: [],
    passwordChanged: false,
    requestedPasswordReset: true,
    resetPasswordToken: undefined,
  };
}

function handleRequestPasswordResetFailure(
  state: ILoginState,
  action: IRequestPasswordResetFailureAction,
): ILoginState {
  return {
    ...state,
    errors: [...action.errors],
    passwordChanged: false,
    requestedPasswordReset: false,
    resetPasswordToken: undefined,
  };
}

function handleSetAppUser(
  state: ILoginState,
  action: ISetAppUserAction,
): ILoginState {
  return {
    ...state,
    appUser: true,
    user: {
      authenticationToken: action.appUser.authToken,
      email: action.appUser.email,
      authType: action.appUser.authType,
      password: '',
      isAppUser: true,
    },
  };
}

function handleSetAccessToken(
  state: ILoginState,
  action: ISetAccessTokenAction,
): ILoginState {
  return {
    ...state,
    user: {
      ...state.user,
      email: state.user?.email ?? '',
      authenticationToken: action.payload.accessToken,
      refreshToken: action.payload.refreshToken ?? state.user?.refreshToken,
    },
  };
}

function handleLoginForm(
  state: ILoginState,
  action: ILoginFormAction | IUserExistsFormAction,
): ILoginState {
  const errors = removeErrorsForFields(
    state.errors,
    getChangedFormFields(state.form, action.form),
  );
  return { ...state, errors: [...errors], form: { ...action.form } };
}

function handleOrderSuccess(state: ILoginState): ILoginState {
  return { ...state, errors: [] };
}

function handleLogout(state: ILoginState): ILoginState {
  return {
    ...state,
    authToken: undefined,
    checkedIfUserExists: false,
    form: { ...initialForm },
    loggedIn: false,
    resetPasswordToken: undefined,
    user: { email: '', password: '' },
  };
}

function handleConfirmEmailSuccess(state: ILoginState): ILoginState {
  return {
    ...state,
    loggedIn: false,
    user: { email: '', password: '' },
  };
}

function handleGetResetPasswordTokenFailure(
  state: ILoginState,
  action: IRequestResetPasswordTokenFailureAction,
): ILoginState {
  return {
    ...state,
    errors: action.errors,
    passwordChanged: false,
    requestedPasswordReset: true,
    resetPasswordToken: undefined,
  };
}

function handleRequestResetPasswordTokenSuccess(
  state: ILoginState,
  action: IRequestResetPasswordTokenSuccessAction,
): ILoginState {
  return {
    ...state,
    errors: [],
    passwordChanged: false,
    requestedPasswordReset: true,
    resetPasswordToken: action.token,
  };
}

function handleRemoveLoginError(
  state: ILoginState,
  action: IRemoveLoginErrorAction,
): ILoginState {
  return {
    ...state,
    errors:
      typeof action.field === 'string'
        ? removeError(state.errors, {
            field: action.field,
            message: action.message,
          })
        : [],
  };
}

function handleResetPasswordSuccessAction(state: ILoginState): ILoginState {
  return {
    ...state,
    errors: [],
    passwordChanged: true,
    requestedPasswordReset: true,
    resetPasswordToken: undefined,
  };
}

function handleResetPasswordFailureAction(
  state: ILoginState,
  action: IResetPasswordFailureAction,
): ILoginState {
  return {
    ...state,
    errors: action.errors,
    passwordChanged: false,
    requestedPasswordReset: !action.options?.reset,
    resetPasswordToken: action.options?.reset
      ? undefined
      : state.resetPasswordToken,
  };
}

function loginReducer(
  state: ILoginState = initialState,
  action:
    | LoginAction
    | UserExistsAction
    | PasswordResetAction
    | IOrderBundleSuccessAction
    | ISetAppUserAction
    | ICheckUserBundleSuccessAction
    | ICheckUserBundleFailureAction
    | ILogoutAction
    | ConfirmEmailAction
    | TriggerEmailVerificationAction
    | ResetPasswordAction
    | { type: null } = { type: null },
): ILoginState {
  switch (action.type) {
    case LOGIN_SUCCESS_ACTION:
      return handleLoginSuccess(state, action);

    case LOGIN_FAILURE_ACTION:
      return handleLoginFailure(state, action);

    case CHECK_USER_BUNDLE_SUCCESS_ACTION:
      return handleCheckUserBundleSuccess(state);

    case CHECK_USER_BUNDLE_FAILURE_ACTION:
      return handleCheckUserBundleFailure(state, action);

    case USER_EXISTS_SUCCESS_ACTION:
      return handleUserExistsSuccess(state, action);

    case USER_EXISTS_FAILURE_ACTION:
      return handleUserExistsFailure(state, action);

    case CHANGE_EMAIL_ACTION:
      return handleChangeEmail(state);

    case REQUEST_PASSWORD_RESET_SUCCESS_ACTION:
      return handleRequestPasswordResetSuccess(state);

    case REQUEST_PASSWORD_RESET_FAILURE_ACTION:
      return handleRequestPasswordResetFailure(state, action);

    case SET_APP_USER_ACTION:
      return handleSetAppUser(state, action);

    case USER_EXISTS_FORM_ACTION:
      return handleLoginForm(state, action);

    case LOGIN_FORM_ACTION:
      return handleLoginForm(state, action);

    case ORDER_BUNDLE_SUCCESS_ACTION:
      return handleOrderSuccess(state);

    case LOGOUT_ACTION:
      return handleLogout(state);

    case SET_ACCESS_TOKEN_ACTION:
      return handleSetAccessToken(state, action);

    case CONFIRM_EMAIL_FAILURE_ACTION:
    case CONFIRM_EMAIL_SUCCESS_ACTION:
    case TRIGGER_EMAIL_VERIFICATION_SUCCESS_ACTION:
    case TRIGGER_EMAIL_VERIFICATION_FAILURE_ACTION:
      // When the users confirms an email address, we need to clear existing
      // user data from the state
      return handleConfirmEmailSuccess(state);

    case REQUEST_RESET_PASSWORD_TOKEN_FAILURE_ACTION:
      return handleGetResetPasswordTokenFailure(state, action);

    case REQUEST_RESET_PASSWORD_TOKEN_SUCCESS_ACTION:
      return handleRequestResetPasswordTokenSuccess(state, action);

    case RESET_PASSWORD_SUCCESS_ACTION:
      return handleResetPasswordSuccessAction(state);

    case RESET_PASSWORD_FAILURE_ACTION:
      return handleResetPasswordFailureAction(state, action);

    case REMOVE_LOGIN_ERROR_ACTION:
      return handleRemoveLoginError(state, action);
  }

  return state;
}

export default loginReducer;
