import IBundle from 'src/types/IBundle';
import IVoucherResponse from 'src/types/IVoucherResponse';

/**
 *
 * @param response Response from the backend
 * @param bundle Bundle context in which the voucher is redeemed. When not
 *               `undefined` the voucher is entered on a page relating to that bundle
 */
function isValidVoucherResponse(
  response: IVoucherResponse,
  bundle?: IBundle,
): boolean {
  const isBundleVoucher = response.voucher?.bundleConfigurationCode;

  // A voucher is a valid bundle voucher if it is a "bundle voucher" and if
  // the bundle config code from the voucher matches the given bundle or
  // if no bundle is given.
  const isValidBundleVoucher =
    isBundleVoucher &&
    (!bundle || response.voucher!.bundleConfigurationCode === bundle.code);

  // A voucher is a valid pro voucher if it is not a bundle voucher and if
  // it is a valid voucher and no bundle is given
  const isValidProVoucher =
    !bundle && !isBundleVoucher && response.valid && !!response.voucher;

  return isValidBundleVoucher || isValidProVoucher;
}

export default isValidVoucherResponse;
