import { INormalizedConsentData } from 'src/types/IConsentEndpoint';
import IFormNotice from 'src/types/IFormNotice';
import IRegisterForm from 'src/types/IRegisterForm';
import IUser from 'src/types/IUser';
import { IChangeEmailAction } from './loginActions';

export const REGISTER_FORM_ACTION = 'REGISTER_FORM_ACTION';
export type REGISTER_FORM_ACTION = typeof REGISTER_FORM_ACTION;

export const REGISTER_ACTION = 'REGISTER_ACTION';
export type REGISTER_ACTION = typeof REGISTER_ACTION;

export const REGISTER_BEGIN_ACTION = 'REGISTER_BEGIN_ACTION';
export type REGISTER_BEGIN_ACTION = typeof REGISTER_BEGIN_ACTION;

export const REGISTER_SUCCESS_ACTION = 'REGISTER_SUCCESS_ACTION';
export type REGISTER_SUCCESS_ACTION = typeof REGISTER_SUCCESS_ACTION;

export const REGISTER_FAILURE_ACTION = 'REGISTER_FAILURE_ACTION';
export type REGISTER_FAILURE_ACTION = typeof REGISTER_FAILURE_ACTION;

export const MISSING_CONSENTS_SUCCESS_ACTION =
  'MISSING_CONSENTS_SUCCESS_ACTION';
export type MISSING_CONSENTS_SUCCESS_ACTION =
  typeof MISSING_CONSENTS_SUCCESS_ACTION;

export const OPTIONAL_CONSENTS_SUCCESS_ACTION =
  'OPTIONAL_CONSENTS_SUCCESS_ACTION';
export type OPTIONAL_CONSENTS_SUCCESS_ACTION =
  typeof OPTIONAL_CONSENTS_SUCCESS_ACTION;

export const MISSING_CONSENTS_FAILURE_ACTION =
  'MISSING_CONSENTS_FAILURE_ACTION';
export type MISSING_CONSENTS_FAILURE_ACTION =
  typeof MISSING_CONSENTS_FAILURE_ACTION;

export const TOGGLE_CONSENT_ACTION = 'TOGGLE_CONSENT_ACTION';
export type TOGGLE_CONSENT_ACTION = typeof TOGGLE_CONSENT_ACTION;

export const SHOW_REGISTER_ACTION = 'SHOW_REGISTER_ACTION';
export type SHOW_REGISTER_ACTION = typeof SHOW_REGISTER_ACTION;

export const REMOVE_REGISTER_ERROR_ACTION = 'REMOVE_REGISTER_ERROR_ACTION';
export type REMOVE_REGISTER_ERROR_ACTION = typeof REMOVE_REGISTER_ERROR_ACTION;

export interface IRegisterFormAction {
  type: REGISTER_FORM_ACTION;
  form: IRegisterForm;
}

export interface IRegisterAction {
  type: REGISTER_ACTION;
  user: IUser;
}

export interface IRegisterBeginAction {
  type: REGISTER_BEGIN_ACTION;
}

export interface IRegisterSuccessAction {
  type: REGISTER_SUCCESS_ACTION;
  user: IUser;
}

export interface IRegisterFailureAction {
  type: REGISTER_FAILURE_ACTION;
  errors: IFormNotice[];
}

export interface IMissingConsentsSuccessAction {
  type: MISSING_CONSENTS_SUCCESS_ACTION;
  consents: INormalizedConsentData;
}

export interface IOptionalConsentsSuccessAction {
  type: OPTIONAL_CONSENTS_SUCCESS_ACTION;
  consents: INormalizedConsentData;
}

export interface IMissingConsentsFailureAction {
  type: MISSING_CONSENTS_FAILURE_ACTION;
  errors: IFormNotice[];
}

export interface IToggleConsentAction {
  type: TOGGLE_CONSENT_ACTION;
  consents: string[];
}

export interface IShowRegisterAction {
  type: SHOW_REGISTER_ACTION;
}

export interface IRemoveRegisterErrorAction {
  type: REMOVE_REGISTER_ERROR_ACTION;
  field?: string;
  message?: string;
}

export type RegisterAction =
  | IRegisterFormAction
  | IRegisterAction
  | IRegisterBeginAction
  | IRegisterSuccessAction
  | IRegisterFailureAction
  | IMissingConsentsSuccessAction
  | IOptionalConsentsSuccessAction
  | IMissingConsentsFailureAction
  | IChangeEmailAction
  | IToggleConsentAction
  | IShowRegisterAction
  | IRemoveRegisterErrorAction;

export function registerFormAction(form: IRegisterForm): IRegisterFormAction {
  return { type: REGISTER_FORM_ACTION, form };
}

export function registerBeginAction(): IRegisterBeginAction {
  return { type: REGISTER_BEGIN_ACTION };
}

export function registerSuccessAction(user: IUser): IRegisterSuccessAction {
  return { type: REGISTER_SUCCESS_ACTION, user };
}

export function registerFailureAction(
  errors: IFormNotice[],
): IRegisterFailureAction {
  return { type: REGISTER_FAILURE_ACTION, errors };
}

export function missingConsentsSuccessAction(
  consents: INormalizedConsentData,
): IMissingConsentsSuccessAction {
  return { type: MISSING_CONSENTS_SUCCESS_ACTION, consents };
}

export function optionalConsentsSuccessAction(
  consents: INormalizedConsentData,
): IOptionalConsentsSuccessAction {
  return { type: OPTIONAL_CONSENTS_SUCCESS_ACTION, consents };
}

export function missingConsentsFailureAction(
  errors: IFormNotice[],
): IMissingConsentsFailureAction {
  return { type: MISSING_CONSENTS_FAILURE_ACTION, errors };
}

export function toggleConsentAction(consents: string[]): IToggleConsentAction {
  return { type: TOGGLE_CONSENT_ACTION, consents };
}

export function showRegisterAction(): IShowRegisterAction {
  return { type: SHOW_REGISTER_ACTION };
}

export function removeRegisterErrorAction({
  field,
  message,
}: {
  field?: string;
  message?: string;
}): IRemoveRegisterErrorAction {
  return { type: REMOVE_REGISTER_ERROR_ACTION, field, message };
}
