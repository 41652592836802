import * as React from 'react';
import { INTERVAL } from 'src/constants/bundles';
import Translate from '../Translate/Translate';

export interface IProps {
  interval: INTERVAL;
  intervalCount: number;
  short?: boolean;
}

function PaymentInterval({
  interval,
  intervalCount,
  short,
}: Readonly<IProps>): React.ReactElement {
  if (intervalCount > 1) {
    return (
      <Translate
        t={`bundleProduct.paymentInterval.${interval}.2+`}
        variables={{ intervalCount }}
      />
    );
  }

  const suffix = short ? 'short' : 'long';
  return (
    <Translate t={`bundleProduct.paymentInterval.${interval}.1.${suffix}`} />
  );
}

export default PaymentInterval;
