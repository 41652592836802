/* istanbul ignore file */

import {
  LANGUAGE_DE,
  LANGUAGE_EN,
  LANGUAGE_ES,
  LANGUAGE_NL,
} from 'src/constants/languages';
import {
  COUNTRY_DE,
  COUNTRY_ES,
  COUNTRY_NL,
  COUNTRY_PL,
  COUNTRY_US,
  COUNTRY_ZA,
} from './countries';

export interface ILegal {
  [propName: string]: (language?: string) => {
    imprint: string;
    privacyPolicy: string;
    privacyPolicyApp: string;
    tcs: string;
  };
}

const legal: ILegal = {
  [COUNTRY_US]: (language: string = LANGUAGE_EN) => ({
    imprint: `https://mysugr.com/en-us/imprint`,
    privacyPolicyApp: `https://legal.mysugr.com/documents/PRIVACY_POLICY_US/current/${language}.html`,
    privacyPolicy: `https://legal.mysugr.com/documents/privacy_policy_web/current/${language}.html`,
    tcs: `https://legal.mysugr.com/documents/GENERAL_TERMS_OF_SERVICE/current/${language}.html`,
  }),
  [COUNTRY_ES]: (language: string = LANGUAGE_ES) => ({
    imprint: `https://mysugr.com/en/imprint`,
    privacyPolicyApp: `https://legal.mysugr.com/documents/PRIVACY_POLICY_EU/current/${language}.html`,
    privacyPolicy: `https://legal.mysugr.com/documents/privacy_policy_web/current/${language}.html`,
    tcs: `https://legal.mysugr.com/documents/GENERAL_TERMS_OF_SERVICE/current/${language}.html`,
  }),
  [COUNTRY_DE]: (language: string = LANGUAGE_DE) => ({
    imprint: `https://mysugr.com/de-de/impressum`,
    privacyPolicyApp: `https://legal.mysugr.com/documents/PRIVACY_POLICY_EU/current/${language}.html`,
    privacyPolicy: `https://legal.mysugr.com/documents/privacy_policy_web/current/${language}.html`,
    tcs: `https://legal.mysugr.com/documents/GENERAL_TERMS_OF_SERVICE/current/${language}.html`,
  }),
  [COUNTRY_NL]: (language: string = LANGUAGE_NL) => ({
    imprint: `https://mysugr.com/en/imprint`,
    privacyPolicyApp: `https://legal.mysugr.com/documents/PRIVACY_POLICY_EU/current/${language}.html`,
    privacyPolicy: `https://legal.mysugr.com/documents/privacy_policy_web/current/${language}.html`,
    tcs: `https://legal.mysugr.com/documents/GENERAL_TERMS_OF_SERVICE/current/${language}.html`,
  }),
  [COUNTRY_PL]: (language: string = LANGUAGE_DE) => ({
    imprint: `https://mysugr.com/de/contact/`,
    privacyPolicyApp: `https://legal.mysugr.com/documents/PRIVACY_POLICY_EU/current/${language}.html`,
    privacyPolicy: `https://legal.mysugr.com/documents/privacy_policy_web/current/${language}.html`,
    tcs: `https://legal.mysugr.com/documents/GENERAL_TERMS_OF_SERVICE/current/${language}.html`,
  }),
  [COUNTRY_ZA]: (language: string = LANGUAGE_EN) => ({
    imprint: `https://mysugr.com/en/imprint/`,
    privacyPolicyApp: `https://legal.mysugr.com/documents/PRIVACY_POLICY_EU/current/${language}.html`,
    privacyPolicy: `https://legal.mysugr.com/documents/privacy_policy_web/current/${language}.html`,
    tcs: `https://legal.mysugr.com/documents/GENERAL_TERMS_OF_SERVICE/current/${language}.html`,
  }),
};

export default legal;
