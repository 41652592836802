import IBundleGroup from 'src/types/IBundleGroup';
import IBundleGroupItem from 'src/types/IBundleGroupItem';
import orderBundleGroupItems from './orderBundleGroupItems';

function getBundleGroupFeaturedItems(
  bundleGroup: IBundleGroup,
  itemOrder: string[],
): IBundleGroupItem[] {
  const itemsFeaturedCodes: IBundleGroupItem[] = [];

  if (bundleGroup.items) {
    orderBundleGroupItems(bundleGroup, itemOrder).forEach(item => {
      itemsFeaturedCodes.push(item);
    });
  }

  return itemsFeaturedCodes;
}

export default getBundleGroupFeaturedItems;
