import {
  ADD_SPACING_ACTION,
  FormAction,
  IAddSpacingAction,
  IInputBlurAction,
  IInputFocusAction,
  INPUT_BLUR_ACTION,
  INPUT_FOCUS_ACTION,
} from 'src/actions/formActions';
import IFormState from 'src/types/IFormState';

export const initialState: IFormState = {
  focused: {},
  spacings: {},
};

function handleInputFocus(
  state: IFormState,
  action: IInputFocusAction,
): IFormState {
  const focused = { ...state.focused };
  if (focused[action.form] === undefined) {
    focused[action.form] = {};
  } else {
    focused[action.form] = { ...focused[action.form] };
  }
  focused[action.form][action.field] = true;
  return { ...state, focused: { ...focused } };
}

function handleInputBlur(
  state: IFormState,
  action: IInputBlurAction,
): IFormState {
  const focused = { ...state.focused };
  if (focused[action.form]?.[action.field]) {
    focused[action.form][action.field] = false;
    return { ...state, focused: { ...focused } };
  }
  return state;
}

function handleAddSpacing(
  state: IFormState,
  action: IAddSpacingAction,
): IFormState {
  const spacings = { ...state.spacings };
  spacings[action.field] = action.spacing;
  return { ...state, spacings };
}

function formReducer(
  state: IFormState = initialState,
  action: FormAction | { type: null } = { type: null },
): IFormState {
  switch (action.type) {
    case INPUT_FOCUS_ACTION:
      return handleInputFocus(state, action);

    case INPUT_BLUR_ACTION:
      return handleInputBlur(state, action);

    case ADD_SPACING_ACTION:
      return handleAddSpacing(state, action);
  }

  return state;
}

export default formReducer;
