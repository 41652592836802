import cn from 'classnames';
import * as React from 'react';
import css from './LinkButton.module.scss';

interface IProps {
  onClick: (event?: React.SyntheticEvent) => any;
  children?: any;
  className?: string;
}

export default function LinkButton(props: Readonly<IProps>) {
  return (
    <button
      className={cn(css.link, props.className)}
      type="button"
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
