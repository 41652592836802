import { PROPERTY_VOUCHER } from 'src/constants/bundleProperties';
import errorConstants from 'src/constants/errors';
import IAuthUser from 'src/types/IAuthUser';
import IBundle from 'src/types/IBundle';
import { IConsentDocument } from 'src/types/IConsentEndpoint';
import IFormNotice from 'src/types/IFormNotice';
import IShipment from 'src/types/IShipment';
import IVoucher from 'src/types/IVoucher';

function validateOverviewForm(options: {
  requiresBundle?: boolean;
  requiresShipment?: boolean;
  bundle?: IBundle;
  user?: IAuthUser;
  shipment?: IShipment;
  consents?: string[];
  requiredConsents?: IConsentDocument[];
  optionalConsents?: IConsentDocument[];
  voucher?: IVoucher;
}): { valid: boolean; errors: IFormNotice[] } {
  const {
    requiresBundle = true,
    requiresShipment = true,
    bundle,
    user,
    shipment,
    consents = [],
    requiredConsents,
    optionalConsents = [],
    voucher,
  } = options;

  const errors: IFormNotice[] = [];

  const hasVoucher = !!voucher?.types.pro;

  if (requiresBundle && !hasVoucher && !bundle) {
    errors.push({ field: '', message: errorConstants.EMPTY_CART });
  }
  if (
    !user ||
    (user.email === '' && user.password === '' && !user.authenticationToken)
  ) {
    errors.push({ field: '', message: errorConstants.USER_MISSING });
  }
  if (requiresShipment && !hasVoucher && !shipment) {
    errors.push({ field: '', message: errorConstants.SHIPMENT_MISSING });
  }
  if (bundle?.propertiesParsed?.[PROPERTY_VOUCHER] && !voucher?.voucherCode) {
    errors.push({ field: '', message: errorConstants.VOUCHER_IS_NOT_VALID });
  }
  if (
    requiredConsents &&
    consents.length - optionalConsents.length !== requiredConsents.length
  ) {
    requiredConsents.forEach((requiredConsent: IConsentDocument) => {
      if (!consents?.includes(requiredConsent.type)) {
        errors.push({
          field: 'consents',
          message: errorConstants.MISSING_CONSENT,
          options: { ...requiredConsent },
        });
      }
    });
  }
  return { errors, valid: errors.length === 0 };
}

export default validateOverviewForm;
