import IBundleGroupItem, {
  IBundleGroupItemAttr,
  BundleGroupItemAttrTypeValue,
  translationKeyMap,
} from 'src/types/IBundleGroupItem';
import getCountFromStripsItem from '../getCountFromStripsItem';

export interface IBundleGroupItemModel {
  data: IBundleGroupItem;
  attributes: IBundleGroupItemAttr[];
  code: string;
  findAttributeByKey: (key: string) => IBundleGroupItemAttr | undefined;
  getAmount: () => number;
  getCoachLanguage: () => string | undefined;
  getTranslationKey: (useAltText?: boolean) => string | undefined;
  getTypeAttr: () => BundleGroupItemAttrTypeValue | null;
  hasAttributeWithKeyAndValue: (key: string, value: string) => boolean;
}

function modelBundleGroupItem(item: IBundleGroupItem): IBundleGroupItemModel {
  const attributes = (item.attributes || []) as IBundleGroupItemAttr[];

  function findAttributeByKey(key: string): IBundleGroupItemAttr | undefined {
    return attributes
      .filter(attribute => !!attribute)
      .find(attribute => attribute.key === key);
  }

  function getAmount(): number {
    if (getTypeAttr() === BundleGroupItemAttrTypeValue.testStrips) {
      return getCountFromStripsItem(item);
    }
    return item.quantity;
  }

  function getCoachLanguage(): string | undefined {
    const hasCoachAttr = hasAttributeWithKeyAndValue(
      'TYPE',
      'COACH_SUBSCRIPTION',
    );
    const languageAttr = findAttributeByKey('LANGUAGE');
    return hasCoachAttr ? languageAttr?.value : undefined;
  }

  function getTypeAttr(): BundleGroupItemAttrTypeValue | null {
    return (
      (findAttributeByKey('TYPE')
        ?.value as unknown as BundleGroupItemAttrTypeValue) || null
    );
  }

  function getTranslationKey(useAltText = true): string | undefined {
    const hasAlternateText = hasAttributeWithKeyAndValue(
      'ALTERNATE_TEXT',
      'TRUE',
    );

    return hasAlternateText && useAltText
      ? 'alt_text.' + translationKeyMap[`${getTypeAttr()}`]
      : translationKeyMap[`${getTypeAttr()}`];
  }

  function hasAttributeWithKeyAndValue(key: string, value: string): boolean {
    return (
      attributes
        .filter(attribute => !!attribute)
        .find(
          attribute => attribute.key === key && attribute.value === value,
        ) !== undefined
    );
  }

  return {
    data: item,
    attributes,
    code: item.code,
    findAttributeByKey,
    getAmount,
    getCoachLanguage,
    getTranslationKey,
    getTypeAttr,
    hasAttributeWithKeyAndValue,
  };
}

export default modelBundleGroupItem;
