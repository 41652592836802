import * as React from 'react';
import { PARAM_BUNDLE_SUPPORT_PHONE } from 'src/constants/bundleParameters';
import {
  COUNTRY_DE,
  COUNTRY_US,
  SUPPORTED_COUNTRY,
} from 'src/constants/countries';
import support from 'src/constants/support';
import getBundleParameter from 'src/lib/getBundleParameter';
import IBundle from 'src/types/IBundle';
import Translate from '../Translate/Translate';

export interface IProps {
  country?: SUPPORTED_COUNTRY;
  bundle?: IBundle;
  withCountryName?: boolean;
}

export function CallSupport({
  country,
  bundle,
  withCountryName,
}: Readonly<IProps>): React.ReactElement {
  if (!country && !bundle) {
    return (
      <>
        <div>
          <CallSupport country={COUNTRY_US} withCountryName={true} />
        </div>
        <div>
          <CallSupport country={COUNTRY_DE} withCountryName={true} />
        </div>
      </>
    );
  }

  const phoneBundle = getBundleParameter(PARAM_BUNDLE_SUPPORT_PHONE, bundle);
  let phone = support.default.phone;

  if (phoneBundle) {
    phone = phoneBundle;
  } else if (country && support[country]) {
    phone = support[country].phone;
  }
  return (
    <li>
      <Translate html={true} t="footer.callSupport" variables={{ phone }} />
      {withCountryName && (
        <>
          {' '}
          (<Translate t={`countryNames.${country}.medium`} />)
        </>
      )}
    </li>
  );
}

export default CallSupport;
