import { Dispatch } from 'redux';
import { INonBundleFlowStep } from 'src/config/REMOTE/NON_BUNDLE_FLOWS/nonBundleSteps';
import { BACKEND } from 'src/constants/backends';
import stepRoutes from 'src/constants/stepRoutes';
import browserOnly from 'src/lib/browserOnly';
import { getCookie, setCookie } from 'src/lib/cookie';
import navigate from 'src/lib/navigate';
import {
  getBundleDynamic,
  getBundleGroupDynamic,
  getNonBundleFlowConfig,
  getSelectedBundleGroup,
} from 'src/selectors/bundleSelectors';
import { getCountry, getSteps } from 'src/selectors/flowSelectors';
import { getLanguage } from 'src/selectors/translationSelectors';
import { getVoucher } from 'src/selectors/voucherSelectors';
import INonBundleFlowConfig from 'src/types/INonBundleFlowConfig';
import IPadding from 'src/types/IPadding';
import IStep from 'src/types/IStep';
import IStoreState from 'src/types/IStoreState';
import BackendClient from 'src/lib/apiClient/BackendClient';
import {
  IChangeBillingAddressAction,
  IEditShipmentAction,
  IEndChangeBillingAddressAction,
  IEndChangePaymentAction,
  IEndEditShipmentAction,
  IStartChangePaymentAction,
} from './overviewActions';
import { IRehydrationAction } from './persistActions';
import { IDiscardShipmentFormAction } from './shipmentActions';
import FLOW_NAME from '../constants/flowName';
import {
  loadingBeginAction,
  loadingFailureAction,
  loadingSuccessAction,
} from './loadingActions';
import getRDCPAuthFlags from 'src/lib/getRDCPAuthFlags';
import { getProductBasedRegion } from 'src/selectors/getProductBasedRegion';

export interface INavigateToStepPromise {
  didNavigate: boolean;
  url?: string;
}

export const SET_NON_BUNDLE_FLOW_ACTION = 'SET_NON_BUNDLE_FLOW_ACTION';
export type SET_NON_BUNDLE_FLOW_ACTION = typeof SET_NON_BUNDLE_FLOW_ACTION;

export const DELETE_BUNDLE_CONFIG_ACTION = 'DELETE_BUNDLE_CONFIG_ACTION';
export type DELETE_BUNDLE_CONFIG_ACTION = typeof DELETE_BUNDLE_CONFIG_ACTION;

export const SET_CURRENT_STEP_ACTION = 'SET_CURRENT_STEP';
export type SET_CURRENT_STEP_ACTION = typeof SET_CURRENT_STEP_ACTION;

export const SET_STEP_ACTION = 'SET_STEP_ACTION';
export type SET_STEP_ACTION = typeof SET_STEP_ACTION;

export const SET_APP_SPACING_ACTION = 'SET_APP_SPACING_ACTION';
export type SET_APP_SPACING_ACTION = typeof SET_APP_SPACING_ACTION;

export const ACCEPT_COOKIES_ACTION = 'ACCEPT_COOKIES_ACTION';
export type ACCEPT_COOKIES_ACTION = typeof ACCEPT_COOKIES_ACTION;

export const SET_SOURCE_ACTION = 'SET_SOURCE_ACTION';
export type SET_SOURCE_ACTION = typeof SET_SOURCE_ACTION;

export const UPDATE_BACKEND_REGIONS_ACTION = 'UPDATE_BACKEND_REGIONS_ACTION';
export type UPDATE_BACKEND_REGIONS_ACTION =
  typeof UPDATE_BACKEND_REGIONS_ACTION;

export const UPDATE_FLOW_NAME_ACTION = 'UPDATE_FLOW_NAME_ACTION';
export type UPDATE_FLOW_NAME_ACTION = typeof UPDATE_FLOW_NAME_ACTION;

export const SET_PATHNAME_ACTION = 'SET_PATHNAME_ACTION';
export type SET_PATHNAME_ACTION = typeof SET_PATHNAME_ACTION;

export const SET_RDCP_AUTH_FLAGS_ACTION = 'SET_RDCP_AUTH_FLAGS_ACTION';
export type SET_RDCP_AUTH_FLAGS_ACTION = typeof SET_RDCP_AUTH_FLAGS_ACTION;

export const UPDATE_RECOMMENDED_REGION_ACTION =
  'UPDATE_RECOMMENDED_REGION_ACTION';
export type UPDATE_RECOMMENDED_REGION_ACTION =
  typeof UPDATE_RECOMMENDED_REGION_ACTION;

export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
export type SET_REDIRECT_URL = typeof SET_REDIRECT_URL;

export interface ISetPathnameAction {
  type: SET_PATHNAME_ACTION;
  pathname: string;
}

export interface IHandleSetRDCPAuthFlags {
  type: SET_RDCP_AUTH_FLAGS_ACTION;
  isRDCPLogin: boolean;
  isRDCPRegister: boolean;
}

export interface ISetNonBundleFLowAction {
  type: SET_NON_BUNDLE_FLOW_ACTION;
  nonBundleFlowConfig: INonBundleFlowConfig | undefined;
}

export interface IDeleteBundleConfigAction {
  type: DELETE_BUNDLE_CONFIG_ACTION;
}

export interface ISetCurrentStepAction {
  type: SET_CURRENT_STEP_ACTION;
  step: number;
}

export interface ISetStepAction {
  type: SET_STEP_ACTION;
  steps: IStep[];
  completedSteps: number[];
}

export interface ISetAppSpacingAction {
  type: SET_APP_SPACING_ACTION;
  spacing: IPadding;
}

export interface ISetAcceptedCookies {
  type: ACCEPT_COOKIES_ACTION;
  acceptedCookies: boolean;
}

export interface ISetSourceAction {
  type: SET_SOURCE_ACTION;
  source: string;
}

export interface IUpdateBackendRegionsAction {
  type: UPDATE_BACKEND_REGIONS_ACTION;
  regions: BACKEND[];
}

export interface IUpdateFlowNameAction {
  type: UPDATE_FLOW_NAME_ACTION;
  name: FLOW_NAME;
}

export interface IUpdateRecommendedRegionAction {
  type: UPDATE_RECOMMENDED_REGION_ACTION;
  region: BACKEND;
}

export interface ISetRedirectUrlAction {
  type: SET_REDIRECT_URL;
  redirectUrl: string | null;
}

export type FlowAction =
  | IUpdateFlowNameAction
  | ISetPathnameAction
  | IChangeBillingAddressAction
  | IDiscardShipmentFormAction
  | IEditShipmentAction
  | IEndChangeBillingAddressAction
  | IEndChangePaymentAction
  | IEndEditShipmentAction
  | ISetStepAction
  | IRehydrationAction
  | ISetAcceptedCookies
  | ISetAppSpacingAction
  | ISetCurrentStepAction
  | IStartChangePaymentAction
  | ISetNonBundleFLowAction
  | IDeleteBundleConfigAction
  | ISetSourceAction
  | IUpdateBackendRegionsAction
  | IHandleSetRDCPAuthFlags
  | IUpdateRecommendedRegionAction
  | ISetRedirectUrlAction;

export function setPathnameAction(pathname: string): ISetPathnameAction {
  return { type: SET_PATHNAME_ACTION, pathname };
}

export function setNonBundleFlowAction(
  flowConfig: INonBundleFlowConfig | undefined,
): ISetNonBundleFLowAction {
  return { type: SET_NON_BUNDLE_FLOW_ACTION, nonBundleFlowConfig: flowConfig };
}

export function deleteBundleConfigAction(): IDeleteBundleConfigAction {
  return { type: DELETE_BUNDLE_CONFIG_ACTION };
}

export function setCurrentStepAction(step: number): ISetCurrentStepAction {
  return { type: SET_CURRENT_STEP_ACTION, step };
}

export function setRedirectUrlAction(
  url: string | null,
): ISetRedirectUrlAction {
  return { type: SET_REDIRECT_URL, redirectUrl: url };
}

export function setStepAction(
  steps: IStep[],
  completedSteps: number[],
): ISetStepAction {
  browserOnly(() => {
    window.scroll(0, 0);
  });
  return { type: SET_STEP_ACTION, steps, completedSteps };
}

export function continueAction(): (
  dispatch: Dispatch,
  getState: () => IStoreState,
) => Promise<any> {
  return (dispatch: Dispatch, getState: () => IStoreState) => {
    const state = getState();
    const steps = getSteps(state);
    const { flow } = state;

    const nextStep = steps.filter(
      step => step.step === flow.currentStep + 1,
    )[0];

    if (!nextStep) {
      return Promise.reject(
        new Error(`Cannot find next step from active step ${flow.currentStep}`),
      );
    }

    const completedSteps = [...flow.completedSteps];
    if (!completedSteps.includes(flow.currentStep)) {
      completedSteps.push(flow.currentStep);
    }

    const path = nextStep.route.getPath({
      bundle: getBundleDynamic(state),
      country: getCountry(state),
      nonBundleFlowConfig: getNonBundleFlowConfig(state),
      language: getLanguage(state),
      voucher: getVoucher(state),
      bundleGroup: getBundleGroupDynamic(state),
    });

    navigate(path);

    dispatch(setStepAction(steps, completedSteps));
    return Promise.resolve();
  };
}

export function backAction(): (
  dispatch: Dispatch,
  getState: () => IStoreState,
) => Promise<any> {
  return (dispatch: Dispatch, getState: () => IStoreState) => {
    const state = getState();
    const steps = getSteps(state);
    const { flow } = state;

    const prevStep = steps.filter(
      step => step.step === flow.currentStep - 1,
    )[0];

    if (!prevStep) {
      return Promise.reject(
        new Error(
          `Cannot find previous step from active step ${flow.currentStep}`,
        ),
      );
    }

    const path = prevStep.route.getPath({
      bundle: getBundleDynamic(state),
      country: getCountry(state),
      nonBundleFlowConfig: getNonBundleFlowConfig(state),
      language: getLanguage(state),
      voucher: getVoucher(state),
      bundleGroup: getBundleGroupDynamic(state),
    });

    navigate(path);

    dispatch(setStepAction(steps, flow.completedSteps));
    return Promise.resolve();
  };
}

export function goToAction(
  step: IStep | INonBundleFlowStep,
): (dispatch: Dispatch, getState: () => IStoreState) => Promise<any> {
  return (dispatch: Dispatch, getState: () => IStoreState) => {
    const state = getState();
    let stepNumber: number | null = null;
    getSteps(state)
      .filter(e => e)
      .forEach((conf, num) => {
        if (conf.name === step.name) {
          stepNumber = num;
        }
      });

    if (stepNumber !== null) {
      dispatch(setCurrentStepAction(stepNumber));
    }

    const path = step.route.getPath({
      bundle: getBundleDynamic(state),
      country: getCountry(state),
      nonBundleFlowConfig: getNonBundleFlowConfig(state),
      language: getLanguage(state),
      voucher: getVoucher(state),
      bundleGroup: getBundleGroupDynamic(state),
    });

    navigate(path);

    return Promise.resolve();
  };
}

export function setAppSpacingAction(spacing: IPadding): ISetAppSpacingAction {
  return { type: SET_APP_SPACING_ACTION, spacing };
}

export function setAcceptedCookies(
  acceptedCookies: boolean,
): ISetAcceptedCookies {
  /* istanbul ignore else */
  if (acceptedCookies) {
    const cookieValue = getCookie('mysugr-cookie-banner-dismiss');
    /* istanbul ignore else */
    if (cookieValue !== '1') {
      setCookie('mysugr-cookie-banner-dismiss', '1', 365);
    }
  }

  return { type: ACCEPT_COOKIES_ACTION, acceptedCookies };
}

export function navigateToProduct(): (
  dispatch: Dispatch,
  getState: () => IStoreState,
) => Promise<INavigateToStepPromise> {
  return (dispatch: Dispatch, getState: () => IStoreState) => {
    const state = getState();
    const url = stepRoutes.product.getPath({
      country: getCountry(state),
      nonBundleFlowConfig: getNonBundleFlowConfig(state),
      language: getLanguage(state),
      voucher: getVoucher(state),
      bundle: getBundleDynamic(state),
      bundleGroup: getSelectedBundleGroup(state),
    });
    navigate(url);
    return Promise.resolve({ didNavigate: true, url });
  };
}

export function navigateToVoucher(): (
  dispatch: Dispatch,
  getState: () => IStoreState,
) => Promise<INavigateToStepPromise> {
  return (dispatch: Dispatch, getState: () => IStoreState) => {
    const state = getState();
    const url = stepRoutes.voucher.getPath({
      country: getCountry(state),
      nonBundleFlowConfig: getNonBundleFlowConfig(state),
      language: getLanguage(state),
      voucher: getVoucher(state),
    });
    navigate(url);
    return Promise.resolve({ didNavigate: true, url });
  };
}

export function navigateToOverview(): (
  dispatch: Dispatch,
  getState: () => IStoreState,
) => Promise<INavigateToStepPromise> {
  return (dispatch: Dispatch, getState: () => IStoreState) => {
    const state = getState();
    const url = stepRoutes.overview.getPath({
      bundle: getBundleDynamic(state),
      country: getCountry(state),
      nonBundleFlowConfig: getNonBundleFlowConfig(state),
      language: getLanguage(state),
      voucher: getVoucher(state),
      bundleGroup: getBundleGroupDynamic(state),
    });
    navigate(url);
    return Promise.resolve({ didNavigate: true, url });
  };
}

export function navigateToSuccess(): (
  dispatch: Dispatch,
  getState: () => IStoreState,
) => Promise<INavigateToStepPromise> {
  return (dispatch: Dispatch, getState: () => IStoreState) => {
    const state = getState();
    const url = stepRoutes.success.getPath({
      bundle: getBundleDynamic(state),
      country: getCountry(state),
      nonBundleFlowConfig: getNonBundleFlowConfig(state),
      language: getLanguage(state),
      voucher: getVoucher(state),
      bundleGroup: getBundleGroupDynamic(state),
    });
    navigate(url);
    return Promise.resolve({ didNavigate: true, url });
  };
}

export function setSourceAction(source: string): ISetSourceAction {
  return { type: SET_SOURCE_ACTION, source };
}

export function updateBackendRegionsAction(
  regions: BACKEND[],
): IUpdateBackendRegionsAction {
  return { type: UPDATE_BACKEND_REGIONS_ACTION, regions };
}

export function updateRecommendedRegionAction(
  region: BACKEND,
): IUpdateRecommendedRegionAction {
  return { type: UPDATE_RECOMMENDED_REGION_ACTION, region };
}

export function updateFlowNameAction(name: FLOW_NAME): IUpdateFlowNameAction {
  return { type: UPDATE_FLOW_NAME_ACTION, name };
}

export function setRDCPAuthFlagsAction({
  isRDCPLogin,
  isRDCPRegister,
}: {
  isRDCPLogin: boolean;
  isRDCPRegister: boolean;
}): IHandleSetRDCPAuthFlags {
  return {
    type: SET_RDCP_AUTH_FLAGS_ACTION,
    isRDCPLogin,
    isRDCPRegister,
  };
}

export function getRecommendedRegionAction(): (
  dispatch: Dispatch,
  getState: () => IStoreState,
) => Promise<void> {
  return async (dispatch, getState) => {
    try {
      dispatch(loadingBeginAction('getRecommendedRegion'));
      const state = getState();
      const regions = getProductBasedRegion(state);

      const { region, probe } =
        await BackendClient.getInstance().getRecommendedRegionDetails(regions);
      const RDCPAuthFlags = getRDCPAuthFlags(probe?.enabledFeatures ?? []);

      dispatch(updateRecommendedRegionAction(region));
      dispatch(setRDCPAuthFlagsAction(RDCPAuthFlags));
      dispatch(loadingSuccessAction('getRecommendedRegion'));
    } catch (e) {
      dispatch(loadingFailureAction('getRecommendedRegion'));
    }
  };
}
