import IFormNotice from 'src/types/IFormNotice';

function removeErrorsForFields(
  errors: IFormNotice[],
  fields: string[],
): IFormNotice[] {
  return errors.filter(error => !fields.includes(error.field));
}

export default removeErrorsForFields;
