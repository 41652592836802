import ERRORS from 'src/constants/errors';
import BackendClient from 'src/lib/apiClient/BackendClient';
import validateLoginForm from 'src/lib/validation/validateLoginForm';
import IAuthUser from 'src/types/IAuthUser';
import ICheckUserBundleEndpoint from 'src/types/ICheckUserBundleEndpoint';
import IFormNotice from 'src/types/IFormNotice';
import ILoginForm from 'src/types/ILoginForm';
import IUser from 'src/types/IUser';
import { loadingBeginAction, loadingSuccessAction } from './loadingActions';
import { loginFailureAction } from './loginActions';

// checkUserBundleAction

export const CHECK_USER_BUNDLE = 'CHECK_USER_BUNDLE';
export type CHECK_USER_BUNDLE = typeof CHECK_USER_BUNDLE;

export const CHECK_USER_BUNDLE_SUCCESS_ACTION =
  'CHECK_USER_BUNDLE_SUCCESS_ACTION';
export type CHECK_USER_BUNDLE_SUCCESS_ACTION =
  typeof CHECK_USER_BUNDLE_SUCCESS_ACTION;

export const CHECK_USER_BUNDLE_FAILURE_ACTION =
  'CHECK_USER_BUNDLE_FAILURE_ACTION';
export type CHECK_USER_BUNDLE_FAILURE_ACTION =
  typeof CHECK_USER_BUNDLE_FAILURE_ACTION;

export const CHECK_USER_BUNDLE_FORM_ACTION = 'CHECK_USER_BUNDLE_FORM_ACTION';
export type CHECK_USER_BUNDLE_FORM_ACTION =
  typeof CHECK_USER_BUNDLE_FORM_ACTION;

export interface ICheckUserBundleAction {
  type: CHECK_USER_BUNDLE;
  user: IUser;
}

export interface ICheckUserBundleSuccessAction {
  type: CHECK_USER_BUNDLE_SUCCESS_ACTION;
}

export interface ICheckUserBundleFailureAction {
  type: CHECK_USER_BUNDLE_FAILURE_ACTION;
  errors: IFormNotice[];
}

export interface ICheckUserBundleFormAction {
  type: CHECK_USER_BUNDLE_FORM_ACTION;
  form: ILoginForm;
}

export type CheckUserBundleAction =
  | ICheckUserBundleAction
  | ICheckUserBundleSuccessAction
  | ICheckUserBundleFailureAction
  | ICheckUserBundleFormAction;

export function checkUserBundleSuccessAction(): ICheckUserBundleSuccessAction {
  return { type: CHECK_USER_BUNDLE_SUCCESS_ACTION };
}

export function checkUserBundleFailureAction(
  errors: IFormNotice[],
): ICheckUserBundleFailureAction {
  return { type: CHECK_USER_BUNDLE_FAILURE_ACTION, errors };
}

export function checkUserBundleAction(
  form?: ILoginForm,
  customUser?: IAuthUser,
): (dispatch: any) => Promise<ICheckUserBundleEndpoint | Error> | undefined {
  return (dispatch: any) => {
    if (form) {
      const { valid, errors } = validateLoginForm(form);
      if (valid === false) {
        dispatch(checkUserBundleFailureAction(errors));
        return Promise.reject(new Error('Login form is not valid'));
      }
    }
    const user = customUser ?? {
      email: form!.email!,
      password: form!.password,
    };
    dispatch(loadingBeginAction('account/checkUserBundle'));

    return BackendClient.getInstance()
      .checkUserBundle(user)
      .then(data => {
        if (data.bundleActive) {
          dispatch(
            checkUserBundleFailureAction([
              { field: '', message: ERRORS.USER_EMAIL_HAS_ACTIVE_BUNDLE },
            ]),
          );
        } else {
          dispatch(checkUserBundleSuccessAction());
        }
        dispatch(loadingSuccessAction('account/checkUserBundle'));
        return data;
      })
      .catch(() => {
        dispatch(
          loginFailureAction([{ field: '', message: ERRORS.INVALID_LOGIN }]),
        );
        dispatch(loadingSuccessAction('account/checkUserBundle'));
        return { bundleActive: false, validCredentials: false };
      });
  };
}

export function checkUserBundleFormAction(
  form: ILoginForm,
): ICheckUserBundleFormAction {
  return { type: CHECK_USER_BUNDLE_FORM_ACTION, form };
}
