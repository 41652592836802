import {
  ISetLanguageAction,
  ISetTranslationsAction,
  SET_LANGUAGE_ACTION,
  SET_TRANSLATIONS_ACTION,
  TranslationAction,
} from 'src/actions/translationActions';
import ITranslationState from 'src/types/ITranslationState';

export const initialState: ITranslationState = {};

function handleSetLanguage(
  state: ITranslationState,
  action: ISetLanguageAction,
): ITranslationState {
  return { ...state, language: action.language };
}

function handleSetTranslations(
  state: ITranslationState,
  action: ISetTranslationsAction,
): ITranslationState {
  return { ...state, translations: action.translations };
}

const translationReducer = (
  state: ITranslationState = initialState,
  action: TranslationAction | { type: null } = { type: null },
): ITranslationState => {
  switch (action.type) {
    case SET_LANGUAGE_ACTION:
      return handleSetLanguage(state, action);

    case SET_TRANSLATIONS_ACTION:
      return handleSetTranslations(state, action);
  }

  return state;
};

export default translationReducer;
