import cleanPathname from 'src/lib/cleanPathname';
import getBasePathFromUrl from 'src/lib/getBasepathFromUrl';
import getLocaleFromPathname from 'src/lib/getLocaleFromPathname';
import IEnvironment from 'src/types/IEnvironment';
import IStep from 'src/types/IStep';

const env = process.env as any as IEnvironment;

function getActiveStepFromPathname(options: {
  pathname: string;
  steps: IStep[];
}) {
  const basePath = getBasePathFromUrl(env.PUBLIC_URL);
  const [language, country] = getLocaleFromPathname(options.pathname);
  const realPathname = cleanPathname(options.pathname)
    .replace(basePath, '')
    .replace('//', '/');

  const newStep: IStep | undefined = options.steps
    .filter(e => e)
    .find(step => {
      const routeMatch = step.route.match
        .map(r => r.exec(realPathname))
        .filter(e => e);
      return routeMatch && routeMatch.length > 0;
    });

  if (!newStep && language && country) {
    return options.steps
      .filter(e => e)
      .find(step => step.name === 'product.stepName');
  }

  return newStep;
}

export default getActiveStepFromPathname;
