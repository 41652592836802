import {
  applyMiddleware,
  combineReducers,
  createStore,
  Dispatch,
  Store,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  createMigrate,
  createTransform,
  persistReducer,
  persistStore,
} from 'redux-persist';
import { Persistor } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import IVoucherState from 'src/types/IVoucherState';
import migrations from './migrations';
import bundleReducer from './reducers/bundleReducer';
import eligibilityCheckReducer from './reducers/eligibilityCheckReducer';
import flowReducer from './reducers/flowReducer';
import formReducer from './reducers/formReducer';
import insuranceNumberReducer from './reducers/insuranceNumberReducer';
import loadingReducer from './reducers/loadingReducer';
import loginReducer from './reducers/loginReducer';
import notificationReducer from './reducers/notificationReducer';
import registerReducer from './reducers/registerReducer';
import shipmentReducer from './reducers/shipmentReducer';
import translationReducer from './reducers/translationReducer';
import userReducer from './reducers/userReducer';
import voucherReducer from './reducers/voucherReducer';
import IBundleState from './types/IBundleState';
import IFlowState from './types/IFlowState';
import ILoginState from './types/ILoginState';
import IRegisterState from './types/IRegisterState';
import IStoreState from './types/IStoreState';
import ITranslationState from './types/ITranslationState';

export const removePasswordTransform = createTransform(
  (inboundState, key: string) => {
    switch (key) {
      case 'register':
        const registerState = inboundState as any as IRegisterState;
        return {
          ...registerState,
          form: { ...registerState.form, password: '', password2: '' },
        };

      case 'login':
        const loginState = inboundState as any as ILoginState;
        return {
          ...loginState,
          form: { ...loginState.form, password: '' },
          user: { ...loginState.user, password: '' },
        };
    }

    // This path can't be never taken because the `whitelist` option of `createTransform()`
    // does not allow states with other keys to be passed into this
    // istanbul ignore next
    return inboundState;
  },
  outboundState => outboundState,
  { whitelist: ['register', 'login'] },
);

const removePayorFromState = (state: IFlowState, key: string): IFlowState => {
  // istanbul ignore else
  if (key === 'flow') {
    return {
      ...state,
      nonBundleFlowConfig: undefined,
    };
  }
  // This path can't be never taken because the `whitelist` option of `createTransform()`
  // does not allow states with other keys to be passed into this
  // istanbul ignore next
  return state;
};

export const removePayorTransform = createTransform(
  removePayorFromState,
  removePayorFromState,
  { whitelist: ['flow'] },
);

function removeLanguageFromState(
  state: ITranslationState,
  key: string,
): ITranslationState {
  // istanbul ignore else
  if (key === 'translation') {
    return { ...state, language: undefined };
  }
  // This path can't be never taken because the `whitelist` option of `createTransform()`
  // does not allow states with other keys to be passed into this
  // istanbul ignore next
  return state;
}

export const removeLanguageTransform = createTransform(
  removeLanguageFromState,
  removeLanguageFromState,
  { whitelist: ['translation'] },
);

function removeBundlesFromState(
  state: IBundleState,
  key: string,
): IBundleState {
  // istanbul ignore else
  if (key === 'bundle') {
    return { ...state, bundles: undefined };
  }
  // This path can't be never taken because the `whitelist` option of `createTransform()`
  // does not allow states with other keys to be passed into this
  // istanbul ignore next
  return state;
}

export const removeBundlesTransform = createTransform(
  removeBundlesFromState,
  removeBundlesFromState,
  { whitelist: ['bundle'] },
);

function removeErrorsFromState(inboundState: any, key: string) {
  // istanbul ignore else
  if (key === 'voucher') {
    const newVoucherState: IVoucherState = { ...inboundState };
    newVoucherState.errors = [];
    newVoucherState.voucher.errors = [];
    return newVoucherState;
  }

  if (key === 'bundle') {
    const newVoucherState: IBundleState = { ...inboundState };
    newVoucherState.errors = [];
    if (newVoucherState.customCodeVerification) {
      newVoucherState.customCodeVerification.errors = [];
    }
    if (newVoucherState.insuranceCardErrors) {
      newVoucherState.insuranceCardErrors = [];
    }
    return newVoucherState;
  }

  const justRemoveErrorsAtRoot = [
    'bundle',
    'eligibilityCheck',
    'insuranceNumber',
    'login',
    'payment',
    'register',
    'shipment',
    'voucher',
  ];

  // istanbul ignore else
  if (justRemoveErrorsAtRoot.indexOf(key) !== -1) {
    return { ...inboundState, errors: [] };
  }

  // This path can't be never taken because the `whitelist` option of `createTransform()`
  // does not allow states with other keys to be passed into this
  // istanbul ignore next
  return inboundState;
}

export const removeErrorsTransform = createTransform(
  removeErrorsFromState,
  removeErrorsFromState,
  {
    whitelist: [
      'bundle',
      'eligibilityCheck',
      'insuranceNumber',
      'login',
      'register',
      'shipment',
      'voucher',
    ],
  },
);

const persistConfig = {
  blacklist: ['loading', 'translation'],
  key: 'mys',
  migrate: createMigrate(migrations, { debug: true }),
  storage,
  transforms: [
    removePasswordTransform,
    removePayorTransform,
    removeLanguageTransform,
    removeBundlesTransform,
    removeErrorsTransform,
  ],
  version: 12,
};

const rootReducer = combineReducers({
  bundle: bundleReducer,
  eligibilityCheck: eligibilityCheckReducer,
  flow: flowReducer,
  form: formReducer,
  insuranceNumber: insuranceNumberReducer,
  loading: loadingReducer,
  login: loginReducer,
  notification: notificationReducer,
  register: registerReducer,
  shipment: shipmentReducer,
  translation: translationReducer,
  user: userReducer,
  voucher: voucherReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer as any);

function configureStore(
  afterRehydration?: (dispatch: Dispatch, getState: any) => void,
): { store: Store; persistor: Persistor } {
  const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 10 });
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk)),
  );
  const persistor = persistStore(store, {}, () => {
    // istanbul ignore else
    if (afterRehydration) {
      const getState: () => IStoreState = () => store.getState() as any;
      afterRehydration(store.dispatch, getState);
    }
  });
  return { store, persistor };
}

export default configureStore;
