import IBundleGroup from 'src/types/IBundleGroup';
import modelBundleGroup from './models/modelBundleGroup';

function arrangeBundleGroupsByType(groups: IBundleGroup[]): string[] {
  if (groups.length < 2) {
    return [];
  } else {
    groups.sort((a, b) => {
      const aModel = modelBundleGroup(a);
      const bModel = modelBundleGroup(b);

      if (aModel.isFullBundle() && !bModel.isFullBundle()) {
        return -1;
      } else if (!aModel.isFullBundle() && bModel.isFullBundle()) {
        return 0;
      } else {
        return 1;
      }
    });

    const firstGroup = groups[0];
    const secondGroup = groups.find(x => x.type !== groups[0].type);

    if (!secondGroup) {
      return [];
    } else {
      return [firstGroup, secondGroup].map(group => group.code);
    }
  }
}

export default arrangeBundleGroupsByType;
