import IFormNotice from 'src/types/IFormNotice';

/**
 * Request reset password token success action
 * after the user clicks on "forgot password" they get a code,
 * if they enter that code and it is correct this action should be dispatched
 */
export const REQUEST_RESET_PASSWORD_TOKEN_SUCCESS_ACTION =
  'REQUEST_RESET_PASSWORD_TOKEN_SUCCESS_ACTION';
export type REQUEST_RESET_PASSWORD_TOKEN_SUCCESS_ACTION =
  typeof REQUEST_RESET_PASSWORD_TOKEN_SUCCESS_ACTION;

export interface IRequestResetPasswordTokenSuccessAction {
  type: REQUEST_RESET_PASSWORD_TOKEN_SUCCESS_ACTION;
  token: string;
}

export function requestResetPasswordTokenSuccessAction(
  token: string,
): IRequestResetPasswordTokenSuccessAction {
  return { type: REQUEST_RESET_PASSWORD_TOKEN_SUCCESS_ACTION, token };
}

/**
 * Request reset password token failure action
 * after the user clicks on "forgot password" they get a code,
 * if they enter that code and it is invalid this action should be dispatched
 */
export const REQUEST_RESET_PASSWORD_TOKEN_FAILURE_ACTION =
  'REQUEST_RESET_PASSWORD_TOKEN_FAILURE_ACTION';
export type REQUEST_RESET_PASSWORD_TOKEN_FAILURE_ACTION =
  typeof REQUEST_RESET_PASSWORD_TOKEN_FAILURE_ACTION;

export interface IRequestResetPasswordTokenFailureAction {
  type: REQUEST_RESET_PASSWORD_TOKEN_FAILURE_ACTION;
  errors: IFormNotice[];
}

export function requestResetPasswordTokenFailureAction(
  errors: IFormNotice[],
): IRequestResetPasswordTokenFailureAction {
  return { type: REQUEST_RESET_PASSWORD_TOKEN_FAILURE_ACTION, errors };
}

/**
 * Reset password success action
 * after the user successfully enters the code from the email and have entered
 * a new password, then clicked on "save password", this actions should be
 * called if we get a success response from the backend
 */
export const RESET_PASSWORD_SUCCESS_ACTION = 'RESET_PASSWORD_SUCCESS_ACTION';
export type RESET_PASSWORD_SUCCESS_ACTION =
  typeof RESET_PASSWORD_SUCCESS_ACTION;

export interface IResetPasswordSuccessAction {
  type: RESET_PASSWORD_SUCCESS_ACTION;
}

export function resetPasswordSuccessAction(): IResetPasswordSuccessAction {
  return { type: RESET_PASSWORD_SUCCESS_ACTION };
}

/**
 * Reset password failure action
 * after the user successfully enters the code from the email and have entered
 * a new password, then clicked on "save password", this actions should be
 * called if we get a error response from the backend
 */
export const RESET_PASSWORD_FAILURE_ACTION = 'RESET_PASSWORD_FAILURE_ACTION';
export type RESET_PASSWORD_FAILURE_ACTION =
  typeof RESET_PASSWORD_FAILURE_ACTION;

export interface IResetPasswordFailureAction {
  type: RESET_PASSWORD_FAILURE_ACTION;
  errors: IFormNotice[];
  options?: { reset: boolean };
}

export function resetPasswordFailureAction(
  errors: IFormNotice[],
  options?: { reset: boolean },
): IResetPasswordFailureAction {
  return { type: RESET_PASSWORD_FAILURE_ACTION, errors, options };
}

export type ResetPasswordAction =
  | IResetPasswordFailureAction
  | IResetPasswordSuccessAction
  | IRequestResetPasswordTokenSuccessAction
  | IRequestResetPasswordTokenFailureAction;
