/* istanbul ignore file */

import { SUPPORTED_COUNTRY } from 'src/constants/countries';
import { MEMBER_CLASS } from 'src/constants/memberClass';
import IForm from './IForm';

interface IShipmentForm extends IForm {
  address?: string;
  address2?: string;
  city?: string;
  country?: SUPPORTED_COUNTRY;
  firstName?: string;
  lastName?: string;
  memberClass?: MEMBER_CLASS;
  phone?: string;
  postalCode?: string;
  state?: string;
  employer?: string;

  /** We render a text input for US B2B bundles */
  birthdate?: string;

  /** We render a checkbox for B2C bundles and bundles outside the US. */
  birthdateCheckbox?: CheckboxState;
}

export enum CheckboxState {
  Checked = 'Checked',
  Unchecked = 'Unchecked',
}

export default IShipmentForm;
