import ERRORS from 'src/constants/errors';
import IFormNotice from 'src/types/IFormNotice';
import ILoginForm from 'src/types/ILoginForm';
import validateEmail from './validateEmail';
import validateMinLength from './validateMinLength';

function validateLoginForm(user: ILoginForm): {
  valid: boolean;
  errors: IFormNotice[];
} {
  const errors = [];

  if (!validateMinLength(1, user.email)) {
    errors.push({ field: 'email', message: ERRORS.MISSING_FIELD });
  } else if (!validateEmail(user.email)) {
    errors.push({ field: 'email', message: ERRORS.INVALID_EMAIL });
  }

  if (!validateMinLength(1, user.password)) {
    errors.push({ field: 'password', message: ERRORS.MISSING_FIELD });
  }

  return { valid: errors.length === 0, errors };
}

export default validateLoginForm;
