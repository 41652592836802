import * as React from 'react';
import { BundleGroupItemImage } from 'src/components/BundleGroupItemImage/BundleGroupItemImage';
import Translate from 'src/components/Translate/Translate';
import { BundleItemOrderContent } from 'src/constants/itemsOrder';
import getBundleGroupFeaturedItems from 'src/lib/getBundleGroupFeaturedItems';
import modelBundleGroupItem from 'src/lib/models/modelBundleGroupItem';
import IBundleGroup from 'src/types/IBundleGroup';
import css from './BundleContent.module.scss';

export interface IProps {
  bundleGroup: IBundleGroup;
}

function BundleContent({
  bundleGroup,
}: Readonly<IProps>): React.ReactElement | null {
  const featuredItems = getBundleGroupFeaturedItems(
    bundleGroup,
    BundleItemOrderContent,
  ).slice(0, 6);

  if (!bundleGroup?.items || !featuredItems) {
    return null;
  }

  const itemModels = featuredItems.map(modelBundleGroupItem);

  return (
    <ul className={css.list}>
      {itemModels.map(itemModel => (
        <li key={itemModel.code} className={css.item}>
          <div className={css.itemIcon}>
            <BundleGroupItemImage item={itemModel} folder="featured-item" />
          </div>
          <div className={css.itemName}>
            <Translate
              t={`bundleProduct.content.items.${itemModel.code}`}
              altKey={`bundleProduct.content.items.${itemModel.getTranslationKey(
                false,
              )}`}
              html={true}
            />
          </div>
        </li>
      ))}
    </ul>
  );
}

export default BundleContent;
