import classNames from 'classnames';
import * as React from 'react';
import css from './Button.module.scss';

// This is the only way to make CSS Modules work with Rollup and Jest

interface IProps {
  children: any;
  className?: string;
  disabled?: boolean;
  focus?: boolean;
  grey?: boolean;
  inverse?: boolean;
  large?: boolean;
  line?: boolean;
  onClick: (event?: any) => void;
  secondary?: boolean;
  slim?: boolean;
  tabIndex?: number;
  tertiary?: boolean;
  text?: boolean;
  type?: 'button' | 'submit' | 'reset';
  ariaLabel?: string;
}

const ERR_FOCUS_DISABLED =
  'Can not combine focus and disabled props on a single button.';

const ERR_SECONDARY_TERTIARY =
  'Can not combine secondary and tertiary props on a single button.';

const ERR_LARGE_SLIM =
  'Can not combine large and slim props on a single button.';

const ERR_LINE_TEXT = 'Can not combine line and text props on a single button.';

const ERR_LINE_INVERSE =
  'Can not combine line and inverse props on a single button.';

const ERR_TEXT_INVERSE =
  'Can not combine text and inverse props on a single button.';

function Button({
  children,
  className,
  disabled,
  focus,
  grey,
  inverse,
  large,
  line,
  onClick,
  secondary,
  slim,
  tabIndex,
  tertiary,
  text,
  type,
  ariaLabel,
}: Readonly<IProps>): React.ReactElement {
  if (focus && disabled) {
    throw new Error(ERR_FOCUS_DISABLED);
  }
  if (secondary && tertiary) {
    throw new Error(ERR_SECONDARY_TERTIARY);
  }
  if (large && slim) {
    throw new Error(ERR_LARGE_SLIM);
  }
  if (line && text) {
    throw new Error(ERR_LINE_TEXT);
  }
  if (line && inverse) {
    throw new Error(ERR_LINE_INVERSE);
  }
  if (text && inverse) {
    throw new Error(ERR_TEXT_INVERSE);
  }
  return (
    <button
      type={type ?? 'button'}
      className={classNames(
        css.button,
        {
          [css.disabled]: disabled,
          [css.focus]: focus,
          [css.grey]: grey,
          [css.inverse]: inverse,
          [css.large]: large,
          [css.line]: line,
          [css.secondary]: secondary,
          [css.slim]: slim,
          [css.tertiary]: tertiary,
          [css.text]: text,
        },
        className,
      )}
      disabled={disabled}
      tabIndex={tabIndex}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {children}
    </button>
  );
}

Button.defaultProps = {
  disabled: false,
  focus: false,
  grey: false,
  inverse: false,
  large: false,
  line: false,
  onClick: null,
  secondary: false,
  slim: false,
  tabIndex: undefined,
  tertiary: false,
  text: false,
};

export default Button;
