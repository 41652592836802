import { PARAM_BUNDLE_LANGUAGE } from 'src/constants/bundleParameters';
import getBundleParameter from 'src/lib/getBundleParameter';
import IBundle from 'src/types/IBundle';

export default function getBundleLanguages(bundle?: IBundle) {
  if (!bundle) {
    return [];
  }

  const bundleLang =
    bundle && getBundleParameter(PARAM_BUNDLE_LANGUAGE, bundle);

  const bundleLanguages = bundleLang && bundleLang.split(',');

  return bundleLanguages || [];
}
