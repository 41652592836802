import IBundle from 'src/types/IBundle';
import IVoucher, { IVoucherTypes } from 'src/types/IVoucher';
import IVoucherResponseData from 'src/types/IVoucherResponseData';

export default function parseVoucher(
  voucher: IVoucherResponseData = { id: '0' },
  bundle?: IBundle,
): IVoucher {
  const grantProUntil = voucher.grantProUntil;
  const types: IVoucherTypes = {
    bundle: voucher.type === 'BUNDLE',
    feature: voucher.type === 'FEATURE',
    pro: voucher.type === 'PRO',
  };

  const payor = bundle?.payor?.code;
  const country = bundle?.country;

  return {
    bundle,
    bundleCode: voucher.bundleConfigurationCode,
    country,
    extensionDays: voucher.extensionDays,
    grantProUntil,
    partnerName: voucher.partnerName,
    payor,
    types,
    validated: true,
    voucherCode: `${voucher.id}`,
  };
}
