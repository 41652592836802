/* istanbul ignore file */

export const BACKEND_EU = 'eu';
export type BACKEND_EU = typeof BACKEND_EU;

export const BACKEND_US = 'us';
export type BACKEND_US = typeof BACKEND_US;

export type BACKEND = BACKEND_EU | BACKEND_US;

export type SUPPORTED_BACKENDS = BACKEND[];
export const SUPPORTED_BACKENDS: SUPPORTED_BACKENDS = [BACKEND_EU, BACKEND_US];
