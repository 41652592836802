import stepRoutes, { IRoute } from 'src/constants/stepRoutes';
import modelBundleGroup from 'src/lib/models/modelBundleGroup';
import {
  isAccessibleStepFunc,
  isAccountPageAccessible,
  isOverviewPageAccessible,
  isProductPageAccessible,
  isProVoucherPageAccessible,
  isShipmentPageAccessible,
  isSuccessPageAccessible,
} from 'src/selectors/flowSelectors';
import IBundle from 'src/types/IBundle';
import IBundleGroup from 'src/types/IBundleGroup';
import IStep from 'src/types/IStep';
import IVoucher from 'src/types/IVoucher';

export interface IStepInput {
  appUser: boolean;
  authenticated: boolean;
  bundle?: IBundle;
  bundleGroup?: IBundleGroup;
  voucher?: IVoucher;
}

interface IStepConfig {
  footerLegalNotice?: string;
  isAccessible: isAccessibleStepFunc;
  name: string;
  route: IRoute;
  step?: number;
  success?: boolean;
  uiStep?: number;
}

function productStep(input: IStepInput): IStepConfig | undefined {
  if (input.voucher?.types.pro) {
    return undefined;
  }
  return {
    isAccessible: isProductPageAccessible,
    name: 'product.stepName',
    route: stepRoutes.product,
  };
}

function proVoucherStep(input: IStepInput): IStepConfig | undefined {
  if (!input.voucher?.types.pro) {
    return undefined;
  }
  return {
    isAccessible: isProVoucherPageAccessible,
    name: 'proVoucherInfo.stepName',
    route: stepRoutes.proVoucher,
  };
}

function shipmentStep(input: IStepInput): IStepConfig | undefined {
  // Don't show shipment step for pro vouchers
  const { bundleGroup, voucher } = input;

  if (voucher?.types.pro) {
    return undefined;
  }
  const groupModel = bundleGroup && modelBundleGroup(bundleGroup);

  // For coach-only or pro-only bundles the step is called "Contact"
  // → SHOP-493, SHOP-601
  const isCoach = Boolean(groupModel?.isCoachOnly());

  const isPro = Boolean(groupModel?.isProOnly());

  if (isCoach || isPro) {
    return {
      isAccessible: isShipmentPageAccessible,
      name: 'contact.stepName',
      route: stepRoutes.shipment,
    };
  }

  return {
    isAccessible: isShipmentPageAccessible,
    name: 'shipment.stepName',
    route: stepRoutes.shipment,
  };
}

function accountStep(input: IStepInput): IStepConfig | undefined {
  if (!input.authenticated || !input.appUser) {
    return {
      isAccessible: isAccountPageAccessible,
      name: 'account.stepName',
      route: stepRoutes.account,
    };
  }
  return undefined;
}

function overviewStep(input: IStepInput): IStepConfig | undefined {
  if (input.voucher?.types.pro) {
    return undefined;
  }
  let footerLegalNotice = 'footer.subscriptionLegalNotice';
  if (input.bundle?.payor) {
    footerLegalNotice = `footer.legalNotice.${input.bundle.payor.code}`;
  }
  return {
    footerLegalNotice,
    isAccessible: isOverviewPageAccessible,
    name: 'overview.stepName',
    route: stepRoutes.overview,
    success: true,
  };
}

function successStep(): IStepConfig {
  return {
    isAccessible: isSuccessPageAccessible,
    name: 'success.stepName',
    route: stepRoutes.success,
    success: true,
    uiStep: 0,
  };
}

function parseBundleSteps(input: IStepInput): IStep[] {
  const stepOrder = [
    productStep(input),
    proVoucherStep(input),
    shipmentStep(input),
    accountStep(input),
    overviewStep(input),
    successStep(),
  ];
  return stepOrder
    .filter(stepConfig => stepConfig !== undefined)
    .map(
      (stepConfig: IStepConfig, index): IStep => ({
        footerLegalNotice: stepConfig.footerLegalNotice ?? undefined,
        isAccessible: stepConfig.isAccessible,
        name: stepConfig.name,
        route: stepConfig.route,
        step: stepConfig.step ?? index + 1,
        success: stepConfig.success ?? false,
        uiStep: stepConfig.uiStep ?? index + 1,
        visible: true,
      }),
    );
}

export default parseBundleSteps;
