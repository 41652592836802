import { parametersRegularExpressions } from 'src/constants/parametersRegularExpressions';
import IBundle from 'src/types/IBundle';
import { parseBundleParameters } from './parseBundleParameters';

const getBundleCustomParameter = (parameter: string, bundle?: IBundle) => {
  const params = parseBundleParameters(bundle);

  const matches = parametersRegularExpressions.some(regularExp =>
    regularExp.exec(parameter),
  );
  if (!matches) {
    return;
  } else {
    return params[parameter] || '';
  }
};

export default getBundleCustomParameter;
