import loadable from '@loadable/component';
import {
  HistoryLocation,
  Location,
  LocationContext,
  Router,
} from '@gatsbyjs/reach-router';
import classnames from 'classnames';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import Connection from 'src/components/Connection/Connection';
import FlowController from 'src/components/FlowController/FlowController';
import Footer from 'src/components/Footer/Footer';
import Header from 'src/components/Header/Header';
import NotificationList from 'src/components/NotificationList/NotificationList';
import OfflineScreen from 'src/components/OfflineScreen/OfflineScreen';
import RequiresValidLocale from 'src/components/RequiresValidLocale/RequiresValidLocale';
import RouteConfig from 'src/components/RouteConfig/RouteConfig';
import withLocation from 'src/components/withLocation/withLocation';
import appRoutes from 'src/constants/appRoutes';
import { COUNTRY_US, SUPPORTED_COUNTRY } from 'src/constants/countries';
import stepRoutes from 'src/constants/stepRoutes';
import BackendClient from 'src/lib/apiClient/BackendClient';
import { getActiveStep, getCountry } from 'src/selectors/flowSelectors';
import IEnvironment from 'src/types/IEnvironment';
import IPadding from 'src/types/IPadding';
import IStep from 'src/types/IStep';
import IStoreState from 'src/types/IStoreState';
import Loader from '../Loader/Loader';
import MapQueryStringToState from '../MapQueryStringToState/MapQueryStringToState';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import ProductPage from '../ProductPage/ProductPage';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import withLocale, { IWithLocaleProps } from '../withLocale/withLocale';
import css from './App.module.scss';

const env = process.env as any as IEnvironment;

interface IStateProps {
  activeStep?: IStep;
  appSpacing: IPadding;
  country?: SUPPORTED_COUNTRY;
  loading: boolean;
}

export interface IProps
  extends IStateProps,
    LocationContext,
    IWithLocaleProps {}

// istanbul ignore next
const AsyncAccountPage = loadable(() => import('../AccountPage/AccountPage'), {
  fallback: <Loader />,
});

// istanbul ignore next
const AsyncShipmentPage = loadable(
  () => import('../ShipmentPage/ShipmentPage'),
  {
    fallback: <Loader />,
  },
);

// istanbul ignore next
const AsyncOverviewPage = loadable(
  () => import('../OverviewPage/OverviewPage'),
  {
    fallback: <Loader />,
  },
);

// istanbul ignore next
const AsyncSuccessPage = loadable(() => import('../SuccessPage/SuccessPage'), {
  fallback: <Loader />,
});

// istanbul ignore next
const AsyncVoucherPage = loadable(
  () => import('src/components/VoucherPage/VoucherPage'),
);

// istanbul ignore next
const AsyncProVoucherPage = loadable(
  () => import('../ProVoucherPage/ProVoucherPage'),
  {
    fallback: <Loader />,
  },
);

// istanbul ignore next
const AsyncEligibilityCheckPage = loadable(
  () => import('src/components/EligibilityCheckPage/EligibilityCheckPage'),
  {
    fallback: <Loader />,
  },
);

// istanbul ignore next
const AsyncMessageSupportButton = loadable(
  () => import('src/components/MessageSupportButton/MessageSupportButton'),
);

// istanbul ignore next
const AsyncNonBundleFlowPages = loadable(
  () => import('src/components/NonBundleFlowPages/NonBundleFlowPages'),
  {
    fallback: <Loader />,
  },
);

// istanbul ignore next
const AsyncAuthorizationRedirectHandler = loadable(
  () =>
    import(
      'src/components/AuthorizationRedirectHandler/AuthorizationRedirectHandler'
    ),
  {
    fallback: <Loader />,
  },
);

function getBackendRegion(country?: SUPPORTED_COUNTRY): 'eu' | 'us' {
  return country === COUNTRY_US ? 'us' : 'eu';
}

export class App extends React.PureComponent<IProps> {
  public componentDidMount() {
    const { country } = this.props;
    /* istanbul ignore else */
    if (country) {
      BackendClient.getInstance().region = getBackendRegion(country);
    }
  }

  public componentDidUpdate() {
    /* istanbul ignore else */
    if (this.props.country) {
      BackendClient.getInstance().region = getBackendRegion(this.props.country);
    }
  }

  public render() {
    const { activeStep, appSpacing, loading } = this.props;
    const isProductPage =
      activeStep && activeStep.route.name === stepRoutes.product.name;
    const basepath = env.PUBLIC_URL.replace(/https?:\/\/[a-zA-Z0-9-.:]+/, '');
    return (
      <div
        className={css.container}
        style={{
          paddingBottom: `${appSpacing.bottom ?? 0}px`,
          paddingLeft: `${appSpacing.left ?? 0}px`,
          paddingRight: `${appSpacing.right ?? 0}px`,
          paddingTop: `${appSpacing.top ?? 0}px`,
        }}
      >
        <MapQueryStringToState />
        <Connection online={false}>
          <OfflineScreen />
        </Connection>
        <Connection online={true}>
          <div id="mainContent">
            <div
              className={classnames(css.content, {
                [css.productContent]: isProductPage,
              })}
            >
              {this.props.language && (
                <Helmet htmlAttributes={{ lang: this.props.language }} />
              )}
              <Header />
              <FlowController />
              <Location>
                {({ location }: { location: HistoryLocation }) => (
                  <ScrollToTop location={location}>
                    <Router basepath={basepath}>
                      <RequiresValidLocale default={true}>
                        <RouteConfig path={appRoutes.home}>
                          <ProductPage path={stepRoutes.product.name} />
                          <AsyncShipmentPage path={stepRoutes.shipment.name} />
                          <AsyncAccountPage path={stepRoutes.account.name} />
                          <AsyncOverviewPage path={stepRoutes.overview.name} />
                          <AsyncSuccessPage path={stepRoutes.success.name} />
                          <NotFoundPage default={true} />
                        </RouteConfig>

                        <RouteConfig path={appRoutes.payor}>
                          <ProductPage path={stepRoutes.product.name} />
                          <AsyncShipmentPage path={stepRoutes.shipment.name} />
                          <AsyncAccountPage path={stepRoutes.account.name} />
                          <AsyncOverviewPage path={stepRoutes.overview.name} />
                          <AsyncSuccessPage path={stepRoutes.success.name} />
                          <NotFoundPage default={true} />
                        </RouteConfig>

                        <RouteConfig path={appRoutes.payorGroup}>
                          <ProductPage path={stepRoutes.product.name} />
                          <AsyncShipmentPage path={stepRoutes.shipment.name} />
                          <AsyncAccountPage path={stepRoutes.account.name} />
                          <AsyncOverviewPage path={stepRoutes.overview.name} />
                          <AsyncSuccessPage path={stepRoutes.success.name} />
                          <NotFoundPage default={true} />
                        </RouteConfig>

                        <RouteConfig path={appRoutes.group}>
                          <ProductPage path={stepRoutes.product.name} />
                          <AsyncShipmentPage path={stepRoutes.shipment.name} />
                          <AsyncAccountPage path={stepRoutes.account.name} />
                          <AsyncOverviewPage path={stepRoutes.overview.name} />
                          <AsyncSuccessPage path={stepRoutes.success.name} />
                          <NotFoundPage default={true} />
                        </RouteConfig>

                        <RouteConfig path={appRoutes.voucher}>
                          <AsyncVoucherPage path={stepRoutes.voucher.name} />
                        </RouteConfig>

                        <RouteConfig path={appRoutes.voucherFlow}>
                          <AsyncProVoucherPage
                            path={stepRoutes.proVoucher.name}
                          />
                          <AsyncAccountPage path={stepRoutes.account.name} />
                          <AsyncSuccessPage path={stepRoutes.success.name} />
                          <NotFoundPage default={true} />
                        </RouteConfig>

                        <RouteConfig path={appRoutes.eligibility}>
                          <AsyncEligibilityCheckPage path="/" />
                          <AsyncEligibilityCheckPage
                            path="/success"
                            status="success"
                          />
                          <AsyncEligibilityCheckPage
                            path="/failure"
                            status="failure"
                          />
                          <NotFoundPage default={true} />
                        </RouteConfig>

                        <AsyncNonBundleFlowPages
                          path={appRoutes.nonBundleHome}
                        />
                        <AsyncNonBundleFlowPages
                          path={appRoutes.nonBundleFlow}
                        />
                      </RequiresValidLocale>
                      <AsyncAuthorizationRedirectHandler path="authorization/success" />
                    </Router>
                  </ScrollToTop>
                )}
              </Location>
            </div>
            <NotificationList />
            <Footer />
            <AsyncMessageSupportButton />
          </div>
          <div id="portalTarget" />
        </Connection>
        {loading && <Loader />}
        {process.env.NODE_ENV !== 'production' && (
          <div className={css.buildInfo}>
            Build Time: {process.env.BUILD_TIME}
          </div>
        )}
      </div>
    );
  }
}

export function mapStateToProps(state: IStoreState): IStateProps {
  return {
    activeStep: getActiveStep(state),
    appSpacing: state.flow.appSpacing,
    country: getCountry(state),
    loading: state.loading.loading.length > 0,
  };
}

export default connect(mapStateToProps)(withLocation(withLocale(App)));
