import IStoreState from 'src/types/IStoreState';

export const REHYDRATE_ACTION = 'persist/REHYDRATE';
export type REHYDRATE_ACTION = typeof REHYDRATE_ACTION;

export interface IRehydrationAction {
  type: REHYDRATE_ACTION;
  err?: string;
  key: string;
  payload?: IStoreState;
}
