import * as React from 'react';
import getBundleCartItems from 'src/lib/getBundleCartItems';
import modelBundleGroupItem from 'src/lib/models/modelBundleGroupItem';
import { IBundleConfigBundleFeatures } from 'src/types/IBundleConfig';
import IBundleGroup from 'src/types/IBundleGroup';
import IBundleGroupItem from 'src/types/IBundleGroupItem';
import Translate from '../Translate/Translate';
import css from './BundleFeatures.module.scss';

export interface IProps {
  bundleGroup: IBundleGroup;
}

enum SectionKey {
  immediate = 'immediate',
  product = 'product',
  sub = 'subscription',
}

enum SectionTitle {
  immediate = 'bundleProduct.features.titles.immediate',
  product = 'bundleProduct.features.titles.product',
  sub = 'bundleProduct.features.titles.subscription',
}

interface ISection {
  key: SectionKey;
  title: SectionTitle;
}

const sections: ISection[] = [
  { key: SectionKey.immediate, title: SectionTitle.immediate },
  { key: SectionKey.product, title: SectionTitle.product },
  { key: SectionKey.sub, title: SectionTitle.sub },
];

const renderSection =
  (bundleGroup: IBundleGroup, bundleFeatures: IBundleConfigBundleFeatures) =>
  (section: ISection) => {
    /* istanbul ignore if */
    if (bundleFeatures[section.key].length === 0) {
      return null;
    }

    return (
      <div key={section.key} className={css.section}>
        <h3 className={css.title}>
          <Translate t={section.title} />
        </h3>
        <ul className={css.list}>
          {bundleFeatures[section.key].map(renderFeature(bundleGroup, section))}
        </ul>
      </div>
    );
  };

const renderFeature =
  (bundleGroup: IBundleGroup, section: ISection) =>
  (feature: IBundleGroupItem) => {
    const itemModel = modelBundleGroupItem(feature);
    const variables: any = {};
    variables.amount = itemModel.getAmount();

    const trans = `bundleProduct.features.items.${section.key}.${itemModel.code}`;
    const altTrans = `bundleProduct.features.items.${
      section.key
    }.${itemModel.getTranslationKey()}`;
    return (
      <li key={feature.code} className={css.listItem}>
        <div className={css.listItemContent}>
          <Translate
            html={true}
            t={trans}
            altKey={altTrans}
            variables={variables}
          />
        </div>
      </li>
    );
  };

export function BundleFeatures({
  bundleGroup,
}: Readonly<IProps>): React.ReactElement | null {
  const bundleFeatures = getBundleCartItems(bundleGroup);

  if (
    bundleFeatures.immediate.length === 0 &&
    bundleFeatures.product.length === 0 &&
    bundleFeatures.subscription.length === 0
  ) {
    return null;
  }

  return <div>{sections.map(renderSection(bundleGroup, bundleFeatures))}</div>;
}

export default BundleFeatures;
