import classnames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import IFormNotice from 'src/types/IFormNotice';
import IStoreState from 'src/types/IStoreState';
import Translate from '../Translate/Translate';
import css from './FormFieldError.module.scss';

interface IStateProps {
  spacing: number;
}

interface IOwnProps {
  checkbox?: boolean;
  errors: IFormNotice[];
  formName: string;
  name: string;
  id?: string;
  className?: string;
  title?: string;
}

export interface IProps extends IOwnProps, IStateProps {}

function getMessageCode(error: IFormNotice): string {
  const suffix = error.field ? `.${error.field}` : '';
  return `errors.${error.message}${suffix}`;
}

export function FormFieldError({
  checkbox,
  errors,
  spacing,
  id,
  className,
  title,
}: IProps): React.ReactElement {
  return (
    <ul
      className={classnames(css.container, className, {
        [css['container-checkbox']]: checkbox,
        [css.noInteraction]: errors.length === 0,
      })}
      style={{ right: `${spacing || 0}px` }}
    >
      {errors.map((error, index) => (
        <li
          key={`${error.field}-${error.message}-${index}`}
          id={id ?? ''}
          aria-live="polite"
          className={classnames(css.error, {
            [css[`error--withspacing`]]: spacing,
          })}
        >
          <Translate
            t={getMessageCode(error)}
            variables={{
              ...error.options,
              title: title ?? error.options?.title,
            }}
          />
        </li>
      ))}
    </ul>
  );
}

FormFieldError.defaultProps = {
  checkbox: false,
};

export function mapStateToProps(
  state: IStoreState,
  ownProps: IOwnProps,
): IStateProps {
  return {
    spacing: state.form.spacings[`${ownProps.formName}-${ownProps.name}`],
  };
}

export default connect(mapStateToProps)(FormFieldError);
