/* istanbul ignore file */

import migrateCleanState from './migrateCleanState';
import migrateTo1 from './migrateTo1';
import migrateTo2 from './migrateTo2';
import migrateTo3 from './migrateTo3';
import migrateTo4 from './migrateTo4';
import migrateToShop287 from './migrateToShop287';
import migrateToShop353 from './migrateToShop353';
import migrateToShop443 from './migrateToShop443';
import migrateToShop479 from './migrateToShop479';
import migrateToShop939 from './migrateToShop939';

export default {
  1: migrateTo1,
  2: migrateTo2,
  3: migrateTo3,
  4: migrateTo4,
  5: migrateCleanState,
  6: migrateCleanState,
  7: migrateCleanState,
  8: migrateToShop287,
  9: migrateToShop353,
  10: migrateToShop443,
  11: migrateToShop479,
  12: migrateToShop939,
};
