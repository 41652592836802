import stepRoutes from 'src/constants/stepRoutes';
import browserOnly from 'src/lib/browserOnly';
import navigate from 'src/lib/navigate';
import {
  getBundleDynamic,
  getBundleGroupDynamic,
  getNonBundleFlowConfig,
} from 'src/selectors/bundleSelectors';
import { getCountry, getSteps } from 'src/selectors/flowSelectors';
import { getLanguage } from 'src/selectors/translationSelectors';
import IStep from 'src/types/IStep';
import IStoreState from 'src/types/IStoreState';
import { getVoucher } from '../selectors/voucherSelectors';
import { goToAction } from './flowActions';

export const EDIT_SHIPMENT_ACTION = 'EDIT_SHIPMENT_ACTION';
export type EDIT_SHIPMENT_ACTION = typeof EDIT_SHIPMENT_ACTION;

export const START_CHANGE_PAYMENT_ACTION = 'START_CHANGE_PAYMENT_ACTION';
export type START_CHANGE_PAYMENT_ACTION = typeof START_CHANGE_PAYMENT_ACTION;

export const CHANGE_BILLING_ADDRESS_ACTION = 'CHANGE_BILLING_ADDRESS_ACTION';
export type CHANGE_BILLING_ADDRESS_ACTION =
  typeof CHANGE_BILLING_ADDRESS_ACTION;

export const END_EDIT_SHIPMENT_ACTION = 'END_EDIT_SHIPMENT_ACTION';
export type END_EDIT_SHIPMENT_ACTION = typeof END_EDIT_SHIPMENT_ACTION;

export const END_CHANGE_PAYMENT_ACTION = 'END_CHANGE_PAYMENT_ACTION';
export type END_CHANGE_PAYMENT_ACTION = typeof END_CHANGE_PAYMENT_ACTION;

export const END_CHANGE_BILLING_ADDRESS_ACTION =
  'END_CHANGE_BILLING_ADDRESS_ACTION';
export type END_CHANGE_BILLING_ADDRESS_ACTION =
  typeof END_CHANGE_BILLING_ADDRESS_ACTION;

export interface IEditShipmentAction {
  type: EDIT_SHIPMENT_ACTION;
}

export interface IStartChangePaymentAction {
  type: START_CHANGE_PAYMENT_ACTION;
}

export interface IChangeBillingAddressAction {
  type: CHANGE_BILLING_ADDRESS_ACTION;
}

export interface IEndEditShipmentAction {
  type: END_EDIT_SHIPMENT_ACTION;
}

export interface IEndChangePaymentAction {
  type: END_CHANGE_PAYMENT_ACTION;
}

export interface IEndChangeBillingAddressAction {
  type: END_CHANGE_BILLING_ADDRESS_ACTION;
}

export type ShipmentAction =
  | IEditShipmentAction
  | IStartChangePaymentAction
  | IEndEditShipmentAction
  | IEndChangePaymentAction;

/**
 * Navigates to the given step based on the state (ie, language, country and payor are considered).
 *
 * Needs to be called after the state has been updated.
 */
export const navigateToStep = (state: IStoreState, step: IStep) => () => {
  navigate(
    step.route.getPath({
      bundle: getBundleDynamic(state),
      country: getCountry(state),
      nonBundleFlowConfig: getNonBundleFlowConfig(state),
      language: getLanguage(state),
      voucher: getVoucher(state),
      bundleGroup: getBundleGroupDynamic(state),
    }),
  );
  return Promise.resolve();
};

export function editShipmentAction(): (
  dispatch: any,
  getState: () => IStoreState,
) => Promise<any> {
  return (dispatch: any, getState: () => IStoreState) => {
    const state = getState();
    const step = getSteps(state).find(
      current =>
        current.route.name === stepRoutes.shipment.name ||
        current.route.name === stepRoutes.contact.name,
    );
    // In practice step should never be undefined as long as every bundle requires shipment
    // information. Add test case if there is a bundle that does not require shipment info
    /* istanbul ignore else */
    if (step) {
      dispatch(editShipmentNavigateAction());
      return dispatch(goToAction(step)).then(navigateToStep(state, step));
    }
    /* istanbul ignore next */
    throw new Error('Shipment step is not configured! Check steps config!');
  };
}

export function editShipmentNavigateAction(): IEditShipmentAction {
  return { type: EDIT_SHIPMENT_ACTION };
}

export function startChangePaymentAction(): IStartChangePaymentAction {
  return { type: START_CHANGE_PAYMENT_ACTION };
}

export function changeBillingAddressNavigateAction(): IChangeBillingAddressAction {
  return { type: CHANGE_BILLING_ADDRESS_ACTION };
}

export function endEditShipmentAction(): IEndEditShipmentAction {
  browserOnly(() => {
    window.scroll(0, 0);
  });
  return { type: END_EDIT_SHIPMENT_ACTION };
}

export function endChangePaymentAction(): IEndChangePaymentAction {
  browserOnly(() => {
    window.scroll(0, 0);
  });
  return { type: END_CHANGE_PAYMENT_ACTION };
}

export function endChangeBillingAddressAction(): IEndChangeBillingAddressAction {
  browserOnly(() => {
    window.scroll(0, 0);
  });
  return { type: END_CHANGE_BILLING_ADDRESS_ACTION };
}
