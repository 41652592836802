import { COUNTRY_DE, COUNTRY_US } from 'src/constants/countries';
import ERRORS from 'src/constants/errors';
import { dateMaskUS } from 'src/constants/FieldMasks';
import IFormNotice from 'src/types/IFormNotice';
import IShipmentForm, { CheckboxState } from 'src/types/IShipmentForm';
import calculateAge from '../calculateAge';
import checkIfAddressIsPoBox from '../checkIfAddressIsPoBox';
import FieldMask from '../FieldMask';
import validateGermanState from './validateGermanState';
import validateMinLength from './validateMinLength';
import validatePhoneNumber from './validatePhoneNumber';
import validatePostalCode from './validatePostalCode';
import validateUsState from './validateUsState';

export function softValidateAddressForm(form: IShipmentForm): {
  valid: boolean;
  warnings: IFormNotice[];
} {
  const warnings: IFormNotice[] = [];

  if (checkIfAddressIsPoBox(form.address)) {
    warnings.push({
      field: 'address',
      message: ERRORS.POSSIBLE_PO_BOX,
    });
  }

  return { valid: warnings.length === 0, warnings };
}

export function getBirthdateTextFieldError(
  birthdate?: string,
  minAgeToSubscribe?: number,
): string {
  if (!validateMinLength(1, birthdate)) {
    return ERRORS.MISSING_FIELD;
  }

  const birthdateMask = new FieldMask(dateMaskUS, {
    value: birthdate,
  });

  try {
    // Convert date format to MM/DD/YYYY, this will throw an error if validation fails
    birthdateMask.mapToMask(dateMaskUS);
    // Create date object from value, if this fails the date fits the mask but is not valid
    const birthdayAsDate = new Date(birthdateMask.getValue());

    if (!minAgeToSubscribe) {
      return '';
    }

    // Calculate the age of the user
    const age = calculateAge(birthdayAsDate);

    // Return error if user is younger than minimum required age
    if (age < minAgeToSubscribe) {
      return ERRORS.INVALID_AGE;
    }
  } catch (e) {
    return ERRORS.INVALID_BIRTHDATE;
  }

  return '';
}

export function getBirthdateCheckboxError(checkbox?: CheckboxState): string {
  if (checkbox === CheckboxState.Unchecked) {
    return ERRORS.MISSING_FIELD;
  }

  return '';
}

export function validateBirthdate(
  form: IShipmentForm,
  conditionalRequiredFields: { [key: string]: boolean },
  minAgeToSubscribe?: number,
): IFormNotice {
  if (conditionalRequiredFields.birthdate) {
    return {
      field: 'birthdate',
      message: getBirthdateTextFieldError(form.birthdate, minAgeToSubscribe),
      options: { minAge: minAgeToSubscribe },
    };
  }

  if (conditionalRequiredFields.birthdateCheckbox) {
    return {
      field: 'birthdateCheckbox',
      message: getBirthdateCheckboxError(form.birthdateCheckbox),
    };
  }

  return {
    field: '',
    message: '',
  };
}

function getPostalCodeError(
  isRequired: boolean,
  postalCode: string | undefined,
  country: string | undefined,
): string {
  if (isRequired && !validateMinLength(1, postalCode)) {
    return ERRORS.MISSING_FIELD;
  }

  if (
    isRequired &&
    postalCode &&
    country &&
    !validatePostalCode(country, postalCode)
  ) {
    return ERRORS.INVALID_POSTAL_CODE[country];
  }

  return '';
}

function getStateError(
  isRequired: boolean,
  state: string | undefined,
  country: string | undefined,
): string {
  if (isRequired && !validateMinLength(1, state)) {
    return ERRORS.MISSING_FIELD;
  }
  if (
    isRequired &&
    state &&
    country === COUNTRY_US &&
    !validateUsState(state)
  ) {
    return ERRORS.INVALID_US_STATE;
  }

  if (
    isRequired &&
    state &&
    country === COUNTRY_DE &&
    !validateGermanState(state)
  ) {
    return ERRORS.INVALID_GERMAN_STATE;
  }

  return '';
}

function getPhoneError(phone: string | undefined): string {
  if (!validateMinLength(1, phone)) {
    return ERRORS.MISSING_FIELD;
  }
  if (phone && !validatePhoneNumber(phone)) {
    return ERRORS.INVALID_PHONE_NUMBER;
  }

  return '';
}

function validateShipmentForm(
  form?: IShipmentForm,
  conditionalRequiredFields: { [key: string]: boolean } = {},
  minAgeToSubscribe?: number,
): { valid: boolean; errors: IFormNotice[] } {
  const errors: IFormNotice[] = [];
  if (!form) {
    return { valid: errors.length === 0, errors };
  }

  if (!validateMinLength(1, form.firstName)) {
    errors.push({ field: 'firstName', message: ERRORS.MISSING_FIELD });
  }

  if (!validateMinLength(1, form.lastName)) {
    errors.push({ field: 'lastName', message: ERRORS.MISSING_FIELD });
  }

  const birthdateError = validateBirthdate(
    form,
    conditionalRequiredFields,
    minAgeToSubscribe,
  );
  if (birthdateError.message) {
    errors.push(birthdateError);
  }

  if (
    conditionalRequiredFields.address &&
    !validateMinLength(1, form.address)
  ) {
    errors.push({ field: 'address', message: ERRORS.MISSING_FIELD });
  }

  if (!validateMinLength(1, form.country)) {
    errors.push({ field: 'country', message: ERRORS.MISSING_FIELD });
  }

  const postalCodeError = getPostalCodeError(
    conditionalRequiredFields.postalCode,
    form.postalCode,
    form.country,
  );
  if (postalCodeError) {
    errors.push({ field: 'postalCode', message: postalCodeError });
  }

  if (
    conditionalRequiredFields.employer &&
    !validateMinLength(1, form.employer)
  ) {
    errors.push({ field: 'employer', message: ERRORS.MISSING_FIELD });
  }

  const stateError = getStateError(
    conditionalRequiredFields.state,
    form.state,
    form.country,
  );
  if (stateError) {
    errors.push({ field: 'state', message: stateError });
  }

  if (conditionalRequiredFields.city && !validateMinLength(1, form.city)) {
    errors.push({ field: 'city', message: ERRORS.MISSING_FIELD });
  }

  const phoneError = getPhoneError(form.phone);
  if (phoneError) {
    errors.push({ field: 'phone', message: phoneError });
  }

  return { valid: errors.length === 0, errors };
}

export default validateShipmentForm;
