import * as React from 'react';
import { connect } from 'react-redux';
import loadFeaturedItemImageSet from 'src/lib/loadItemImageSet';
import { IBundleGroupItemModel } from 'src/lib/models/modelBundleGroupItem';
import { getTranslation } from 'src/selectors/translationSelectors';
import IStoreState from 'src/types/IStoreState';
import Image from '../Image/Image';

interface IOwnProps {
  item: IBundleGroupItemModel;
  folder: 'featured-item' | 'item';
}

interface IStateProps {
  translatedName?: string;
}

interface IProps extends IOwnProps, IStateProps {}

export function BundleGroupItemImage({
  item,
  folder,
  translatedName,
}: IProps): React.ReactElement | null {
  const mappedCode = item.getTranslationKey(false);

  if (!mappedCode) {
    return null;
  }

  const imageSrcProps = loadFeaturedItemImageSet(mappedCode, folder);
  return <Image alt={translatedName ?? item.data.name} {...imageSrcProps} />;
}

export function mapStateToProps(
  state: IStoreState,
  ownProps: IOwnProps,
): IStateProps {
  return {
    translatedName: getTranslation(
      `bundleGroupItemName.${ownProps.item.code}`,
      {},
      true,
    )(state),
  };
}

export default connect(mapStateToProps)(BundleGroupItemImage);
