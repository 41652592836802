/* istanbul ignore file */

import { BACKEND } from '../constants/backends';
import IUser from './IUser';

export enum AuthType {
  mySugr = 'mySugr', // authToken credentials comes from regular mySugr email + password authorization
  RDCP = 'RDCP', // authToken credentials comes from Roche account authorization
}

interface IAuthUser extends IUser {
  authenticationToken?: string;
  refreshToken?: string;
  authType?: AuthType;
  userId?: string;
  region?: BACKEND;
  isAppUser?: boolean;
}

export default IAuthUser;
