import browserOnly from 'src/lib/browserOnly';

export function setCookie(name: string, value: any, days: number) {
  const d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  browserOnly(() => {
    document.cookie = name + '=' + value + ';' + expires + ';path=/';
  });
}

export function getCookie(name: string) {
  const stringName = name + '=';
  return browserOnly(() => {
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (const c of ca) {
      let newC = c;
      while (newC.startsWith(' ')) {
        newC = newC.substring(1);
      }
      if (newC.startsWith(stringName)) {
        return newC.substring(stringName.length, newC.length);
      }
    }
    return '';
  }, '');
}
