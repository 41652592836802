import classnames from 'classnames';
import * as React from 'react';
import Translate from '../Translate/Translate';
import css from './HeaderBundleInfoBar.module.scss';
import HeaderBundleInfoOverlay from 'src/components/HeaderBundleInfoOverlay/HeaderBundleInfoOverlay';

interface IProps {
  bundleCode: string;
  className?: string;
}

export default function HeaderBundleInfoBar({
  className,
  bundleCode,
}: Readonly<IProps>): React.ReactElement {
  return (
    <div className={classnames(css.bundleInfo, className)}>
      <span>
        <Translate
          html={true}
          t={`headerNotice.infoNotice.teaser.${bundleCode}`}
        />
      </span>
      <span>
        <HeaderBundleInfoOverlay>
          <Translate
            html={true}
            t={`headerNotice.infoNotice.linkText.${bundleCode}`}
          />
        </HeaderBundleInfoOverlay>
      </span>
    </div>
  );
}
