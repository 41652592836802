import classnames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  IBundleParsedParameters,
  parseBundleParameters,
} from 'src/lib/parseBundleParameters';
import {
  getBundleDynamic,
  getBundleGroupDynamic,
} from 'src/selectors/bundleSelectors';
import IBundle from 'src/types/IBundle';
import IBundleGroup from 'src/types/IBundleGroup';
import IStoreState from 'src/types/IStoreState';
import { BundlePrice } from '../BundlePrice/BundlePrice';
import Translate from '../Translate/Translate';
import withLocale, { IWithLocaleProps } from '../withLocale/withLocale';
import css from './PromoPrice.module.scss';

interface IStateProps {
  params: IBundleParsedParameters;
  bundle?: IBundle;
  group?: IBundleGroup;
}

export interface IProps extends IWithLocaleProps, IStateProps {
  line?: boolean;
  country?: string;
}

export function PromoPrice({
  group,
  country,
  line,
  params,
}: Readonly<IProps>): React.ReactElement {
  return (
    <div
      className={classnames(
        css.wrapper,
        line ? css.lineWrapper : css.badgeWrapper,
      )}
    >
      <div
        className={classnames(css.promo, line ? css.linePromo : css.badgePromo)}
      >
        <div
          className={classnames(
            css.title,
            line ? css.lineTitle : css.badgeTitle,
          )}
        >
          <span>
            <Translate t={`promotion.${params['bundle.promotion.text']}`} />
          </span>
          {!line && (
            <svg width="210px" height="37px" viewBox="0 0 210 37" version="1.1">
              <g id="Page-1" stroke="none" fill="none">
                <g
                  id="Desktop-Copy-10"
                  transform="translate(0.000000, -402.000000)"
                  fill="#9A709B"
                >
                  <g id="Group-10" transform="translate(-8.000000, 402.000000)">
                    <g
                      id="Group"
                      transform="translate(109.500000, 18.500000) rotate(-90.000000) translate(-109.500000, -18.500000) translate(91.000000, -91.000000)"
                    >
                      <path
                        d="M-88.5177018,90.5179681 L125.517968,90.5179681 C126.622538,90.5179681 127.517968,91.4133986 127.517968,92.5179681 L127.517968,125.517968 C127.517968,126.622538 126.622538,127.517968 125.517968,127.517968 L-87.9451983,127.517947 C-89.0497563,127.517947 -89.9451774,126.622526 -89.9451774,125.517968 C-89.9451774,124.799991 -89.5603218,124.137105 -88.9368088,123.781125 C-87.9400213,123.212032 -86.8300234,122.578304 -85.6068152,121.879942 C-79.5621763,118.428896 -73.5175373,114.977849 -67.4728983,111.526803 L-67.4728983,111.526803 C-66.5136562,110.979146 -66.1800005,109.757562 -66.7276576,108.79832 C-66.8913516,108.511604 -67.1234791,108.269871 -67.4033262,108.094692 L-89.5788947,94.2132172 C-90.5151549,93.6271365 -90.7990315,92.3930354 -90.2129508,91.4567752 C-89.8473281,90.8726951 -89.2067806,90.5179681 -88.5177018,90.5179681 Z"
                        id="Rectangle"
                        transform="translate(18.500000, 109.017968) rotate(-90.000000) translate(-18.500000, -109.017968) "
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          )}
        </div>
        <div
          className={classnames(
            css.priceBox,
            line ? css.linePriceBox : css.badgePriceBox,
          )}
        >
          <div
            className={classnames(
              css.price,
              line ? css.linePrice : css.badgePrice,
            )}
          >
            {group && <BundlePrice group={group} country={country} />}
          </div>
          <div
            className={classnames(
              css.priceText,
              line ? css.linePriceText : css.badgePriceText,
            )}
          >
            <Translate
              t={`promotion.${params['bundle.promotion.price.text']}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function mapStateToProps(state: IStoreState): IStateProps {
  const bundle = getBundleDynamic(state);
  const params = parseBundleParameters(bundle);
  return {
    bundle,
    params,
    group: getBundleGroupDynamic(state),
  };
}

export default connect(mapStateToProps)(withLocale(PromoPrice));
