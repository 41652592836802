import IBundleGroupItem, {
  BundleGroupItemAttrKey,
  BundleGroupItemAttrTypeValue,
  BundleGroupItemAttrValue,
} from 'src/types/IBundleGroupItem';

export interface IBundleItemParsedAttributes
  extends Partial<
    Record<
      BundleGroupItemAttrKey,
      BundleGroupItemAttrValue | BundleGroupItemAttrTypeValue
    >
  > {
  [key: string]: any;
}

export function parseItemAttributes(
  item: IBundleGroupItem,
): IBundleItemParsedAttributes {
  const attributes = item.attributes ?? [];
  const parsed: IBundleItemParsedAttributes = {};

  for (const { key, value } of attributes) {
    parsed[key as string] = value;
  }

  return parsed;
}
