import { SUPPORTED_COUNTRY } from 'src/constants/countries';
import { CURRENCY } from 'src/constants/currencies';
import formatters from 'src/constants/formatters';

export interface IParsedPrice {
  match: boolean;
  values: Array<{ value: string; type: string }>;
  formated: string;
}

export interface IParsePriceParams {
  country: SUPPORTED_COUNTRY;
  price:
    | undefined
    | {
        currency: CURRENCY;
        price: number;
      };
}

export default function parsePrice({
  country,
  price,
}: IParsePriceParams): IParsedPrice {
  const formated = formatPrice({ country, price });
  return splitPrice(formated);
}

export function formatPrice({ country, price }: IParsePriceParams): string {
  if (!price) {
    return '';
  }

  const numberFormat = formatters[country.toLowerCase()] || 'en-US';
  const formatter = new Intl.NumberFormat(numberFormat, {
    currency: price.currency,
    minimumFractionDigits: 2,
    style: 'currency',
  });

  return formatter.format(price.price / 100);
}

export function splitPrice(priceFormated: string): IParsedPrice {
  const matches = /([^\d.,\s])?(\s)?([\d.,]+)(\s)?([^\d.,\s])?/.exec(
    priceFormated,
  );
  const parsed: IParsedPrice = {
    formated: priceFormated,
    match: false,
    values: [],
  };

  /* istanbul ignore else */
  if (matches && matches.length > 0) {
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    delete matches[0];
    parsed.match = true;
    matches.forEach(match => {
      if (!match) {
        return;
      } // exit if match is undefined

      const isSymbol = /[^\d.,\s]/.exec(match); // check for currency symbol
      const isPrice = /[\d.,]+/.exec(match); // check for the price

      if (isSymbol) {
        parsed.values.push({
          type: 'symbol',
          value: match,
        });
      } else if (isPrice) {
        parsed.values.push({
          type: 'price',
          value: match,
        });
      } else {
        parsed.values.push({
          type: 'spacer',
          value: match,
        });
      }
    });
  }

  return parsed;
}
