import { updateBackendRegionsAction } from 'src/actions/flowActions';
import {
  loadingBeginAction,
  loadingSuccessAction,
} from 'src/actions/loadingActions';
import {
  redeemVoucherFailureAction,
  redeemVoucherSetErrorsAction,
  redeemVoucherSuccessAction,
  validateVoucherCodeFailureAction,
  validateVoucherCodeSuccessAction,
} from 'src/actions/voucherActions';
import { BACKEND, SUPPORTED_BACKENDS } from 'src/constants/backends';
import { PROPERTY_VOUCHER } from 'src/constants/bundleProperties';
import errorConstants from 'src/constants/errors';
import BackendClient from 'src/lib/apiClient/BackendClient';
import backendRegionsFromState from 'src/lib/backendRegionsFromState';
import filterBackendRegionsByCountry from 'src/lib/filterBackendRegionsByCountry';
import isValidVoucherResponse from 'src/lib/isValidVoucherResponse';
import removeDuplicateBackendRegions from 'src/lib/removeDuplicateBackendRegions';
import validateOverviewForm from 'src/lib/validation/validateOverviewForm';
import { getSelectedBundle } from 'src/selectors/bundleSelectors';
import IBundle from 'src/types/IBundle';
import { IConsentDocument } from 'src/types/IConsentEndpoint';
import IFormNotice from 'src/types/IFormNotice';
import IStoreState from 'src/types/IStoreState';
import IValidateResponse from 'src/types/IValidateResponse';
import IVoucher from 'src/types/IVoucher';
import { getUser } from '../selectors/loginSelectors';
import { unauthorizedErrorFlow } from './errorFlows';

export function validateVoucherFlow(
  voucherCode?: string,
): (dispatch: any, getState: () => IStoreState) => Promise<boolean> {
  return async (
    dispatch: any,
    getState: () => IStoreState,
  ): Promise<boolean> => {
    const state = getState();
    const bundle = getSelectedBundle(state);
    let voucherBundle: IBundle | undefined;

    if (!voucherCode || voucherCode.length === 0) {
      const error = {
        field: PROPERTY_VOUCHER,
        message: errorConstants.MISSING_FIELD,
      };
      dispatch(validateVoucherCodeFailureAction([error], voucherCode));
      return Promise.resolve(false);
    }

    const backend = BackendClient.getInstance();
    const cleanVoucherCode = voucherCode.replace(/[-.\s]/g, '');

    dispatch(loadingBeginAction(`validateVoucherCode/${cleanVoucherCode}`));

    const validRegions: BACKEND[] = [];
    let voucher;
    const regions = SUPPORTED_BACKENDS;
    const responseErrors: string[] = [];

    let validBundle: boolean | undefined;

    for (const region of regions) {
      const response = await backend.validateVoucherCode(
        cleanVoucherCode,
        region,
      );

      const bundleCode = response.voucher?.bundleConfigurationCode;

      if (bundleCode) {
        try {
          voucherBundle = await BackendClient.getInstance().getBundle(
            bundleCode,
            region,
          );
          validBundle = true;
        } catch (e) {
          dispatch(
            validateVoucherCodeFailureAction(
              [
                {
                  field: PROPERTY_VOUCHER,
                  message: errorConstants.INVALID_VOUCHER_CODE,
                },
              ],
              voucherCode,
            ),
          );
          validBundle = false;
        }
      }

      if (isValidVoucherResponse(response, bundle)) {
        validRegions.push(region);
        if (!voucher) {
          voucher = response.voucher;
        }
      } else {
        const message = (response.message ?? '')
          .toUpperCase()
          .replace(/ /g, '_');
        const error = errorConstants[message];

        if (message && !responseErrors.includes(error)) {
          responseErrors.push(error);
        }
      }
    }

    const valid = validRegions.length > 0;

    if (valid && validBundle !== false && voucher) {
      dispatch(updateBackendRegionsAction(validRegions));
      dispatch(validateVoucherCodeSuccessAction(voucher, voucherBundle));
    } else {
      const customError = responseErrors[responseErrors.length - 1];

      const error = {
        field: PROPERTY_VOUCHER,
        message: customError || errorConstants.INVALID_VOUCHER_CODE,
      };
      dispatch(validateVoucherCodeFailureAction([error], voucherCode));
    }
    dispatch(loadingSuccessAction(`validateVoucherCode/${cleanVoucherCode}`));

    return Promise.resolve(valid);
  };
}

export function redeemVoucherFlow(
  voucher: IVoucher,
  consents: string[],
  optionalConsents?: IConsentDocument[],
  country?: string,
  hasUserSelectedCountry?: boolean,
): (dispatch: any, getState: () => IStoreState) => Promise<IValidateResponse> {
  return async (dispatch: any, getState: () => IStoreState) => {
    const formErrors: IFormNotice[] = [];
    const state = getState();
    let redeemSuccess = false;
    const user = getUser(state);
    const formValidation = validateOverviewForm({
      bundle: getSelectedBundle(state),
      user,
      consents,
      optionalConsents,
      voucher,
    });

    if (!formValidation.valid || !user) {
      dispatch(redeemVoucherFailureAction(formValidation.errors));
      return Promise.resolve({ valid: false, errors: formValidation.errors });
    }

    // Vouchers are the best example when we need to select the backend region on
    // the fly. First, we get all possible regions from the state. That is, regions
    // where the voucher code is valid.
    // In the case that a voucher code is valid in multiple regions and we are dealing
    // with a new user we offer a dropdown where the user can select their country.
    // We need to filter the available regions by this country so that we know where
    // we should redeem.

    let regions = backendRegionsFromState(state);

    if (country && hasUserSelectedCountry) {
      regions = filterBackendRegionsByCountry(
        [...regions],
        country,
        hasUserSelectedCountry,
      );
    }

    regions = removeDuplicateBackendRegions(regions);

    if (regions.length === 0) {
      // We don't have a valid backend region! That can't happen, technically, because
      // redeemVoucherFlow() should only be called after either loginFlow() or
      // registerFlow() both of them also check if we have a region
      // Just crash, honestly, this should only happen during development if you don't
      // check valid regions before calling redeemVoucherFlow()
      throw new Error('no backend region available');
    }

    dispatch(redeemVoucherSetErrorsAction(formErrors));
    dispatch(loadingBeginAction('redeemVoucherAction'));

    try {
      // Redeem voucher on the backend the user logged in on
      await BackendClient.getInstance().redeemVoucher({
        region: user.region,
        user,
        voucher,
      });
      redeemSuccess = true;
      dispatch(redeemVoucherSuccessAction());
    } catch (error) {
      if (error.unauthorized === true) {
        dispatch(unauthorizedErrorFlow());
      } else {
        const message = error?.data?.message ?? '';
        formErrors.push({
          field: '',
          message: message.toUpperCase().replace(/ /g, '_'),
        });
        dispatch(redeemVoucherFailureAction(formErrors));
      }
    }

    dispatch(loadingSuccessAction('redeemVoucherAction'));

    return Promise.resolve({ valid: redeemSuccess, errors: formErrors });
  };
}
