import classnames from 'classnames';
import * as React from 'react';
import ReimbursementOverlay from '../ReimbursementOverlay/ReimbursementOverlay';
import Translate from '../Translate/Translate';
import css from './ReimbursementBar.module.scss';

interface IProps {
  className?: string;
}

export default function ReimbursementBar({
  className,
}: Readonly<IProps>): React.ReactElement {
  return (
    <div className={classnames(css.reimbursement, className)}>
      <div>
        <Translate
          html={true}
          t={`groupSelection.message.reimbursement.text`}
        />
      </div>
      <div>
        <ReimbursementOverlay>
          <Translate
            html={true}
            t={`groupSelection.message.reimbursement.linkText`}
          />
        </ReimbursementOverlay>
      </div>
    </div>
  );
}
