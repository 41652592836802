import QuestionMarkButton from 'src/components/QuestionMarkButton/QuestionMarkButton';

function renderMoreInfoButton(
  altText: string,
  onClick: (event: React.SyntheticEvent) => void,
): () => JSX.Element {
  return (): JSX.Element => (
    <QuestionMarkButton altText={altText} onClick={onClick} />
  );
}

export default renderMoreInfoButton;
