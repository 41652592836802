import IBundle from 'src/types/IBundle';
import IBundleProperty from 'src/types/IBundleProperty';

function getBundleProperty(
  bundle: IBundle,
  key: string,
): IBundleProperty | undefined {
  return (
    (bundle.properties ?? []).find(
      (property: IBundleProperty) => property.key === key,
    ) ?? undefined
  );
}

export default getBundleProperty;
