function getBundleGroupFromPathname(pathname: string): string | undefined {
  // Regular expression to extract group from `pathname`
  // - Pathname can contain feature branch prefix, eg, `/feature/SHOP-499`
  // - the group always follows after "group/" in the pathname
  // - the group name can be alphanumerical with "_" and "-"
  const match =
    /^(\/feature\/SHOP-\d{1,4})?\/(.+payor\/[A-Za-z0-9_-]+)\/group\/([A-Za-z0-9_-]+)/.exec(
      pathname,
    );

  return match?.[3] ?? undefined;
}

export default getBundleGroupFromPathname;
