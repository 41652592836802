import bundleGroupOverride from 'src/config/nonStandardBundleOverride/bundleGroupOverride';
import { ATTRIBUTE_BUNDLE_GROUP_PHYSICAL_ITEM } from 'src/constants/bundleGroupAttributes';
import {
  bundleImages,
  IMAGE_SET_DEFAULT_GRAY,
  IMAGE_SET_DEFAULT_WHITE,
} from 'src/images/product-image/productImages';
import modelBundleGroupItem from 'src/lib/models/modelBundleGroupItem';
import IBundleGroup from 'src/types/IBundleGroup';
import { BundleGroupItemAttrTypeValue } from 'src/types/IBundleGroupItem';
import getBundleGroupAttribute from '../getBundleGroupAttribute';
import { IBundleImageSet } from '../loadBundleImageSet';

interface IBundleGroupModel {
  data: IBundleGroup;
  hasItemWithCoachLanguage: () => boolean;
  hasItemWithUnit: () => boolean;
  getItemUnit: () => string | undefined;
  getCoachLanguage: () => string | undefined;
  getBundleImage: (options: { whiteBackground: boolean }) => IBundleImageSet;
  hasPhysicalItems: () => boolean;
  isCoachOnly: () => boolean;
  isProOnly: () => boolean;
  isFullBundle: () => boolean;
  getBundleGroupType: () => string | undefined;
}

export interface IItemPattern {
  parameters: Partial<{
    [K in BundleGroupItemAttrTypeValue]: boolean | number;
  }>;
  country?: string;
}

function modelBundleGroup(bundleGroup: IBundleGroup): IBundleGroupModel {
  const items = bundleGroup.items?.map(modelBundleGroupItem);

  function matchesItemPattern(itemPattern: IItemPattern): boolean {
    const { parameters, country } = itemPattern;
    let match = true;

    const itemTypeAmount = items
      .map(item => ({
        type: item.getTypeAttr(),
        amount: item.getAmount(),
      }))
      .filter(item => item.type);

    const itemTypes = itemTypeAmount.map(item => item.type);

    if (
      country &&
      country.toLocaleLowerCase() !==
        `${bundleGroup.bundleCountry}`.toLocaleLowerCase()
    ) {
      match = false;
    }

    for (const key of Object.keys(parameters)) {
      const value = parameters[key];
      const inItemTypes =
        itemTypes.indexOf(key as BundleGroupItemAttrTypeValue) !== -1;

      if (typeof value === 'boolean' && value !== inItemTypes) {
        match = false;
        break;
      }
      if (typeof value === 'number') {
        const found = itemTypeAmount.find(item => item.type === key);
        if (!found || found.amount !== value) {
          match = false;
          break;
        }
      }
    }

    return match;
  }

  /**
   * getBundleImage
   * returns a bundle image set that can be used as a source for images
   * or null when the bundle group items dont fit any image sets
   */
  function getBundleImage({
    whiteBackground,
  }: {
    whiteBackground: boolean;
  }): IBundleImageSet {
    const overwrites = bundleGroupOverride[bundleGroup.code];
    if (overwrites?.productImages) {
      const forcedSet = overwrites.productImages;
      return whiteBackground ? forcedSet.white : forcedSet.gray;
    }

    for (const { itemPattern, imageSet } of bundleImages) {
      const set = whiteBackground ? imageSet.white : imageSet.gray;

      const match = matchesItemPattern(itemPattern);

      if (match) {
        return set;
      }
    }

    const defaultSet = {
      gray: IMAGE_SET_DEFAULT_GRAY,
      white: IMAGE_SET_DEFAULT_WHITE,
    };

    return whiteBackground ? defaultSet.white : defaultSet.gray;
  }

  function hasItemWithCoachLanguage(): boolean {
    return Boolean(
      bundleGroup.items.find(item => {
        const itemModel = modelBundleGroupItem(item);
        return itemModel.getCoachLanguage() !== undefined;
      }),
    );
  }

  function hasItemWithUnit(): boolean {
    return Boolean(
      bundleGroup.items.find(item => {
        const itemModel = modelBundleGroupItem(item);
        return itemModel.findAttributeByKey('UNIT') !== undefined;
      }),
    );
  }

  function getItemUnit(): string | undefined {
    return bundleGroup.items
      .map(item => {
        const itemModel = modelBundleGroupItem(item);
        return itemModel.findAttributeByKey('UNIT')?.value;
      })
      .find(value => !!value);
  }

  function getCoachLanguage(): string | undefined {
    return bundleGroup.items
      .map(item => {
        const itemModel = modelBundleGroupItem(item);
        return itemModel.getCoachLanguage();
      })
      .find(value => !!value);
  }

  function hasPhysicalItems(): boolean {
    return !!getBundleGroupAttribute(
      ATTRIBUTE_BUNDLE_GROUP_PHYSICAL_ITEM,
      bundleGroup,
    );
  }

  function isCoachOnly(): boolean {
    const hasOverwrittenType =
      bundleGroup && bundleGroupOverride[bundleGroup.code]?.type;
    if (hasOverwrittenType) {
      return hasOverwrittenType === 'coach';
    } else {
      return !!(
        bundleGroup.items &&
        bundleGroup.items.length === 2 &&
        bundleGroup.items.some(e => e.code.includes('coach')) &&
        !getBundleGroupAttribute(
          ATTRIBUTE_BUNDLE_GROUP_PHYSICAL_ITEM,
          bundleGroup,
        )
      );
    }
  }

  function isProOnly(): boolean {
    const hasOverwrittenType =
      bundleGroup && bundleGroupOverride[bundleGroup.code]?.type;
    if (hasOverwrittenType) {
      return hasOverwrittenType === 'pro';
    } else {
      return !!(
        bundleGroup.items &&
        bundleGroup.items.length === 1 &&
        bundleGroup.items[0].code.includes('mysugr_pro')
      );
    }
  }

  function isFullBundle(): boolean {
    const hasOverwrittenType =
      bundleGroup && bundleGroupOverride[bundleGroup.code]?.type;
    if (hasOverwrittenType) {
      return hasOverwrittenType === 'bundle';
    } else {
      return !!getBundleGroupAttribute(
        ATTRIBUTE_BUNDLE_GROUP_PHYSICAL_ITEM,
        bundleGroup,
      );
    }
  }

  function getBundleGroupType(): string | undefined {
    let bundleGroupType;
    const hasPhysicalItem = !!getBundleGroupAttribute(
      ATTRIBUTE_BUNDLE_GROUP_PHYSICAL_ITEM,
      bundleGroup,
    );
    const overwrittenBundleGroupType =
      bundleGroup && bundleGroupOverride[bundleGroup.code]?.type;
    if (hasPhysicalItem) {
      bundleGroupType = 'bundle';
    } else if (
      bundleGroup.items &&
      bundleGroup.items.length === 1 &&
      bundleGroup.items[0].code.includes('mysugr_pro')
    ) {
      bundleGroupType = 'pro';
    } else if (
      bundleGroup.items &&
      bundleGroup.items.length === 2 &&
      bundleGroup.items.some(e => e.code.includes('coach'))
    ) {
      bundleGroupType = 'coach';
    }
    return overwrittenBundleGroupType ?? bundleGroupType;
  }

  return {
    data: bundleGroup,
    hasItemWithCoachLanguage,
    hasItemWithUnit,
    getItemUnit,
    getCoachLanguage,
    getBundleImage,
    hasPhysicalItems,
    isCoachOnly,
    isProOnly,
    isFullBundle,
    getBundleGroupType,
  };
}

export default modelBundleGroup;
