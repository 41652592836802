import { LocationContext } from '@gatsbyjs/reach-router';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { translateAction } from 'src/actions/translationActions';
import VisuallyHidden from 'src/components/VisuallyHidden/VisuallyHidden';
import { SUPPORTED_COUNTRY } from 'src/constants/countries';
import countryLanguageMapping from 'src/constants/countryLanguageMapping';
import {
  LANGUAGE,
  SUPPORTED_LANGUAGES,
  SUPPORTED_LANGUAGES_BY_PATHNAME,
} from 'src/constants/languages';
import { switchLanguageFlow } from 'src/flows/translationFlows';
import getBundleLanguages from 'src/lib/getBundleLanguages';
import getLocaleFromPathname from 'src/lib/getLocaleFromPathname';
import {
  getNonBundleFlowConfig,
  getSelectedBundle,
} from 'src/selectors/bundleSelectors';
import IBundle from 'src/types/IBundle';
import INonBundleFlowConfig from 'src/types/INonBundleFlowConfig';
import IStoreState from 'src/types/IStoreState';
import withLocation from '../withLocation/withLocation';
import css from './LanguageSelector.module.scss';

export interface IStateProps {
  translations?: any;
  nonBundleFlowConfig?: INonBundleFlowConfig;
  bundle?: IBundle;
}

export interface IDispatchProps {
  setLanguage: (newLanguage: LANGUAGE) => Promise<any>;
  translate: (key: string) => Promise<string>;
}

export interface IProps extends IStateProps, IDispatchProps, LocationContext {}

function filterLanguagesByCountry(
  languages: LANGUAGE[],
  country: SUPPORTED_COUNTRY,
): LANGUAGE[] {
  return languages.filter(lang =>
    (countryLanguageMapping[country] || []).includes(lang),
  );
}

function filterLanguagesByPathname(
  languages: LANGUAGE[],
  pathname: string,
): LANGUAGE[] {
  let currentLanguages = languages;

  SUPPORTED_LANGUAGES_BY_PATHNAME.forEach(options => {
    const regex = new RegExp(options.match);
    if (regex.test(pathname)) {
      currentLanguages = options.languages;
    }
  });
  return currentLanguages;
}

export function LanguageSelector({
  location,
  setLanguage,
  translate,
  translations,
  nonBundleFlowConfig,
  bundle,
}: IProps): React.ReactElement | null {
  const pathname = location.pathname;
  const [language, country] = getLocaleFromPathname(pathname);

  if (translations && language) {
    const handleChange = async ({ target }: ChangeEvent<HTMLSelectElement>) => {
      if (language !== target.value) {
        await setLanguage(target.value as LANGUAGE);
      }
    };
    let languages: LANGUAGE[] = [];
    const supportedLanguages = getBundleLanguages(bundle);

    if (!bundle && !nonBundleFlowConfig) {
      languages = filterLanguagesByPathname(SUPPORTED_LANGUAGES, pathname);

      // If we have a country, filter the supported languages based on the languages
      // available for the given country
      if (country) {
        languages = filterLanguagesByCountry(languages, country);
      }
    } else {
      languages = supportedLanguages;
    }

    if (nonBundleFlowConfig) {
      languages = nonBundleFlowConfig.LANGUAGES;
    }

    const languageOptions = languages.map(key => ({
      key,
      text: translate(`languages.${key}`),
    }));

    // Don't render LanguageSelector if there are not at least 2 languages
    if (languageOptions.length < 2) {
      return null;
    }

    return (
      <form>
        <VisuallyHidden>
          <label htmlFor="languageSelect">
            {translate('languageSelectLabel')}
          </label>
        </VisuallyHidden>
        <select
          className={css.input}
          value={language}
          id="languageSelect"
          onChange={handleChange}
          onBlur={handleChange}
        >
          {languageOptions.map(({ key, text }) => (
            <option key={key} value={key}>
              {text}
            </option>
          ))}
        </select>
      </form>
    );
  } else {
    return null;
  }
}

export function mapStateToProps(state: IStoreState): IStateProps {
  return {
    translations: state.translation.translations,
    nonBundleFlowConfig: getNonBundleFlowConfig(state),
    bundle: getSelectedBundle(state),
  };
}

export function mapDispatchToProps(dispatch: any): IDispatchProps {
  return {
    setLanguage: async (newLanguage: LANGUAGE) =>
      dispatch(switchLanguageFlow(newLanguage)),
    translate: (key: string, values?: Record<string, string>) =>
      dispatch(translateAction(key, values)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocation(LanguageSelector));
