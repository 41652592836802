/* istanbul ignore file */

function requireAll(r: any) {
  r.keys().forEach(r);
}

let loadedFeaturedItems = false;
let uiImages = false;
export default (() => {
  try {
    if (!loadedFeaturedItems) {
      requireAll(
        // @ts-ignore
        require.context(
          '../images/bundle/featured-item/',
          true,
          /\.(png|webp)$/,
        ),
      );
      loadedFeaturedItems = true;
    }

    if (!uiImages) {
      // @ts-ignore
      requireAll(require.context('../images/ui/', true, /\.(png|webp|svg)$/));

      uiImages = true;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
  }
})();
