import IFormNotice from 'src/types/IFormNotice';

export const TRIGGER_EMAIL_VERIFICATION_ACTION =
  'TRIGGER_EMAIL_VERIFICATION_ACTION';
export type TRIGGER_EMAIL_VERIFICATION_ACTION =
  typeof TRIGGER_EMAIL_VERIFICATION_ACTION;

export const TRIGGER_EMAIL_VERIFICATION_BEGIN_ACTION =
  'TRIGGER_EMAIL_VERIFICATION_BEGIN_ACTION';
export type TRIGGER_EMAIL_VERIFICATION_BEGIN_ACTION =
  typeof TRIGGER_EMAIL_VERIFICATION_BEGIN_ACTION;

export const TRIGGER_EMAIL_VERIFICATION_SUCCESS_ACTION =
  'TRIGGER_EMAIL_VERIFICATION_SUCCESS_ACTION';
export type TRIGGER_EMAIL_VERIFICATION_SUCCESS_ACTION =
  typeof TRIGGER_EMAIL_VERIFICATION_SUCCESS_ACTION;

export const TRIGGER_EMAIL_VERIFICATION_FAILURE_ACTION =
  'TRIGGER_EMAIL_VERIFICATION_FAILURE_ACTION';
export type TRIGGER_EMAIL_VERIFICATION_FAILURE_ACTION =
  typeof TRIGGER_EMAIL_VERIFICATION_FAILURE_ACTION;

export interface ITriggerEmailVerificationAction {
  type: TRIGGER_EMAIL_VERIFICATION_ACTION;
  email: string;
}

export interface ITriggerEmailVerificationBeginAction {
  type: TRIGGER_EMAIL_VERIFICATION_BEGIN_ACTION;
}

export interface ITriggerEmailVerificationSuccessAction {
  type: TRIGGER_EMAIL_VERIFICATION_SUCCESS_ACTION;
}

export interface ITriggerEmailVerificationFailureAction {
  type: TRIGGER_EMAIL_VERIFICATION_FAILURE_ACTION;
  errors: IFormNotice[];
}

export type TriggerEmailVerificationAction =
  | ITriggerEmailVerificationAction
  | ITriggerEmailVerificationBeginAction
  | ITriggerEmailVerificationSuccessAction
  | ITriggerEmailVerificationFailureAction;

export function triggerEmailVerificationBeginAction(): ITriggerEmailVerificationBeginAction {
  return { type: TRIGGER_EMAIL_VERIFICATION_BEGIN_ACTION };
}

export function triggerEmailVerificationSuccessAction(): ITriggerEmailVerificationSuccessAction {
  return { type: TRIGGER_EMAIL_VERIFICATION_SUCCESS_ACTION };
}

export function triggerEmailVerificationFailureAction(
  errors: IFormNotice[],
): ITriggerEmailVerificationFailureAction {
  return { type: TRIGGER_EMAIL_VERIFICATION_FAILURE_ACTION, errors };
}
