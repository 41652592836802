import {
  DISMISS_NOTIFICATION_ACTION,
  HIDE_NOTIFICATION_ACTION,
  IDismissNotificationAction,
  IHideNotificationAction,
  IShowNotificationAction,
  NotificationAction,
  SHOW_NOTIFICATION_ACTION,
} from 'src/actions/notificationActions';
import INotification from 'src/types/INotification';
import INotificationState from 'src/types/INotificationState';

export const initialState: INotificationState = {
  notifications: [],
};

function handleShowNotification(
  state: INotificationState,
  action: IShowNotificationAction,
): INotificationState {
  const id = state.notifications.reduce(
    (prev, current: INotification) =>
      current.id >= prev ? current.id + 1 : prev,
    0,
  );
  return {
    ...state,
    notifications: [
      ...state.notifications,
      { id, message: action.message, style: action.style, visible: true },
    ],
  };
}

function handleDismissNotification(
  state: INotificationState,
  action: IDismissNotificationAction,
): INotificationState {
  return {
    ...state,
    notifications: [
      ...state.notifications.filter(
        notification => notification.id !== action.id,
      ),
    ],
  };
}

function handleNotificationAction(
  state: INotificationState,
  action: IHideNotificationAction,
): INotificationState {
  return {
    ...state,
    notifications: state.notifications.map(notification => {
      if (notification.id === action.id) {
        return { ...notification, visible: false };
      }
      return notification;
    }),
  };
}

function notificationReducer(
  state: INotificationState = initialState,
  action: NotificationAction | { type: null } = { type: null },
): INotificationState {
  switch (action.type) {
    case SHOW_NOTIFICATION_ACTION:
      return handleShowNotification(state, action);

    case DISMISS_NOTIFICATION_ACTION:
      return handleDismissNotification(state, action);

    case HIDE_NOTIFICATION_ACTION:
      return handleNotificationAction(state, action);
  }

  return state;
}

export default notificationReducer;
