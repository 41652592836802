export const LOGOUT_ACTION = 'LOGOUT_ACTION';
export type LOGOUT_ACTION = typeof LOGOUT_ACTION;

export interface ILogoutAction {
  type: LOGOUT_ACTION;
}

export function logoutAction(): ILogoutAction {
  return { type: LOGOUT_ACTION };
}
