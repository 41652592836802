import { PROPERTY_VOUCHER } from 'src/constants/bundleProperties';
import IBundle from 'src/types/IBundle';
import getBundleProperty from './getBundleProperty';

function bundleRequiresVoucherCode(bundle: IBundle): boolean {
  const voucherProperty = getBundleProperty(bundle, PROPERTY_VOUCHER);
  return voucherProperty !== undefined;
}

export default bundleRequiresVoucherCode;
