import classnames from 'classnames';
import * as React from 'react';
import Translate from '../Translate/Translate';
import css from '../ReimbursementBar/ReimbursementBar.module.scss';
import LinkButton from '../LinkButton/LinkButton';

interface IProps {
  className?: string;
  onNavigate?: (event: React.SyntheticEvent) => Promise<void>;
}

export default function NonBundleFlowBar({
  className,
  onNavigate,
}: Readonly<IProps>) {
  if (!onNavigate) {
    return null;
  }

  return (
    <div className={classnames(css.reimbursement, css.dvgText, className)}>
      <Translate
        html={true}
        t="groupSelection.message.reimbursement.text.dvg"
      />
      <LinkButton
        className={classnames(css.link, css.linkDvg, className)}
        onClick={onNavigate}
      >
        <Translate
          html={true}
          t="groupSelection.message.reimbursement.linkText.dvg"
        />
      </LinkButton>
    </div>
  );
}
