import * as React from 'react';
import Translate from '../Translate/Translate';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import americanExpressLogo from './american-express.svg';
import mastercardLogo from './mastercard.svg';
import css from './PaymentLogos.module.scss';
import visaLogo from './visa.svg';

function PaymentLogos(): React.ReactElement {
  return (
    <>
      <VisuallyHidden>
        <Translate t="paymentLogos.title" />
      </VisuallyHidden>
      <ul className={css.container}>
        <li>
          <img src={mastercardLogo} alt="mastercard" />
        </li>
        <li>
          <img src={visaLogo} alt="VISA" />
        </li>
        <li>
          <img src={americanExpressLogo} alt="American Express" />
        </li>
      </ul>
    </>
  );
}

export default PaymentLogos;
