/**
 * Returns whether a country should be considered part of the US
 * → https://en.wikipedia.org/wiki/Territories_of_the_United_States
 * → https://en.wikipedia.org/wiki/United_States_Minor_Outlying_Islands
 *
 * @param country ISO 3166 country code
 */
import { BACKEND } from 'src/constants/backends';
import { SUPPORTED_COUNTRY } from 'src/constants/countries';

function isCountryUs(country: string): boolean {
  switch (country) {
    case 'as': // American Samoa
    case 'gu': // Guam
    case 'mp': // Northern Mariana Islands
    case 'pr': // Puerto Rico
    case 'um': // United States Minor Outlying Islands
    case 'us': // United States
    case 'vi': // Virgin Islands (U.S.)
      return true;
  }
  return false;
}

/**
 * Returns whether data for the given country should be stored on the given
 * backend region.
 *
 * @param country ISO 3166 country code
 * @param region Code for backend region
 */
function isUSAndUsBackendRegion(country: string, region: string): boolean {
  return isCountryUs(country) && region === 'us';
}

/**
 * Returns whether data for the given country should be stored on the given
 * backend region.
 *
 * @param country ISO 3166 country code
 * @param region Code for backend region
 */
function isNotUSAndEuBackendRegion(country: string, region: string): boolean {
  return !isCountryUs(country) && region === 'eu';
}

/**
 * Filters an array of backend regions by the given country and returns all
 * viable regions to store data for this user on. The country is only used
 * for filtering if it has been selected by the user, otherwise all given
 * regions are returned.
 *
 * @param regions Array of backend region codes
 * @param country ISO 3166 country code
 * @param isUserSelected Whether the given country has been selected by the user
 */
function filterBackendRegionsByCountry(
  regions: BACKEND[],
  country: SUPPORTED_COUNTRY,
  isUserSelected: boolean,
): BACKEND[] {
  return regions.filter(
    region =>
      !isUserSelected ||
      isUSAndUsBackendRegion(country, region) ||
      isNotUSAndEuBackendRegion(country, region),
  );
}

export default filterBackendRegionsByCountry;
