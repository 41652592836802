/* istanbul ignore file */

import IAddress from 'src/types/IAddress';
import {
  COUNTRY_AT,
  COUNTRY_DE,
  COUNTRY_NL,
  COUNTRY_US,
  COUNTRY_ZA,
} from './countries';

export const MYSUGR_GMBH_AT: IAddress = {
  address1: 'Trattnerhof 1',
  city: 'Vienna',
  companyName: 'mySugr GmbH',
  country: COUNTRY_AT,
  zipCode: '1010',
};

export const ROCHE_GMBH_DE: IAddress = {
  address1: 'Sandhofer Straße 116',
  city: 'Mannheim',
  companyName: 'Roche Diabetes Care Deutschland GmbH',
  country: COUNTRY_DE,
  zipCode: '68305',
};

export const ROCHE_GMBH_NL: IAddress = {
  address1: 'Transistorstraat 41',
  city: 'CK Almere',
  companyName: 'Roche Diabetes Care Nederland B. V.',
  country: COUNTRY_NL,
  zipCode: '1322',
};

export const ALMEDA_GMBH_DE: IAddress = {
  address1: 'Balanstraße 69',
  city: 'München',
  companyName: 'almeda GmbH',
  country: COUNTRY_DE,
  zipCode: '81669',
};

export const ROCHE_INC_US: IAddress = {
  address1: '9115 Hague Rd',
  city: 'Indianapolis',
  companyName: 'Roche Diabetes Care, Inc.',
  country: COUNTRY_US,
  zipCode: '46256',
  state: 'IN',
};

export const ROCHE_LTD_ZA: IAddress = {
  address1: '90 Bekker Road',
  address2: 'Hertford Office Park, Building E',
  city: 'Gauteng',
  companyName: 'Roche Diabetes Care South Africa (Pty) Ltd',
  country: COUNTRY_ZA,
  zipCode: '1686',
  state: 'Midrand',
};
