/* istanbul ignore file */

export const LANGUAGE_DE = 'de';
export type LANGUAGE_DE = typeof LANGUAGE_DE;

export const LANGUAGE_EN = 'en';
export type LANGUAGE_EN = typeof LANGUAGE_EN;

export const LANGUAGE_NL = 'nl';
export type LANGUAGE_NL = typeof LANGUAGE_NL;

export const LANGUAGE_ES = 'es';
export type LANGUAGE_ES = typeof LANGUAGE_ES;

export const LANGUAGE_FR = 'fr';
export type LANGUAGE_FR = typeof LANGUAGE_FR;

export const LANGUAGE_FALLBACK = LANGUAGE_EN;

export type LANGUAGE =
  | LANGUAGE_DE
  | LANGUAGE_EN
  | LANGUAGE_NL
  | LANGUAGE_ES
  | LANGUAGE_FR;

export const SUPPORTED_LANGUAGES: LANGUAGE[] = [
  LANGUAGE_DE,
  LANGUAGE_EN,
  LANGUAGE_ES,
  LANGUAGE_NL,
  LANGUAGE_FR,
];

export interface ILanguagesByPathnameOptions {
  match: RegExp;
  languages: LANGUAGE[];
}

export const SUPPORTED_LANGUAGES_BY_PATHNAME: ILanguagesByPathnameOptions[] = [
  { match: /\/eligibility/, languages: [LANGUAGE_DE, LANGUAGE_EN] },
];
