import 'src/lib/requireImagesContext';
import { IBundleImageName } from 'src/types/IBundleImageName';

export interface IBundleImageSet {
  box1x: string;
  box1xWebp: string;
  box2x: string;
  box2xWebp: string;
  box3x: string;
  box3xWebp: string;
  name: IBundleImageName | '';
}

/** Loads the bundle image set from the root folder `src/images/product-image`,
 * found by searching for the given `imagePartialPath` (composed of a potential folder + image name).
 * The image set consists of the bundle image in 3 sizes (`@1x`, `@2x`, `@3x`) and in `jpg` and `webp` formats.
 * It also features an image type.
 *
 * In case the images are not found, a default set of empty values is returned **/
export function loadBundleImageSet(
  partialImagePath: string,
  imageType: IBundleImageName,
): IBundleImageSet {
  try {
    return {
      box1x: require(`../images/product-image/${partialImagePath}@1x.jpg`)
        .default,
      box1xWebp: require(`../images/product-image/${partialImagePath}@1x.webp`)
        .default,
      box2x: require(`../images/product-image/${partialImagePath}@2x.jpg`)
        .default,
      box2xWebp: require(`../images/product-image/${partialImagePath}@2x.webp`)
        .default,
      box3x: require(`../images/product-image/${partialImagePath}@3x.jpg`)
        .default,
      box3xWebp: require(`../images/product-image/${partialImagePath}@3x.webp`)
        .default,
      name: imageType,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
    return {
      box1x: '',
      box1xWebp: '',
      box2x: '',
      box2xWebp: '',
      box3x: '',
      box3xWebp: '',
      name: '',
    };
  }
}
