import { ORDER_BUNDLE_SUCCESS_ACTION } from 'src/actions/bundleActions';
import { EDIT_SHIPMENT_ACTION } from 'src/actions/overviewActions';
import {
  CLEAR_SHIPMENT_DATA_ACTION,
  DISCARD_SHIPMENT_FORM_ACTION,
  IShipmentFailureAction,
  IShipmentFormAction,
  IShipmentSoftValidationAction,
  IShipmentSuccessAction,
  SHIPMENT_FAILURE_ACTION,
  SHIPMENT_FORM_ACTION,
  SHIPMENT_SOFT_VALIDATION_ACTION,
  SHIPMENT_SUCCESS_ACTION,
  ShipmentAction,
} from 'src/actions/shipmentActions';
import {
  ISetAppUserAction,
  SET_APP_USER_ACTION,
} from 'src/actions/userActions';
import getChangedFormFields from 'src/lib/getChangedFormFields';
import removeErrorsForFields from 'src/lib/removeErrorsForFields';
import IShipmentForm, { CheckboxState } from 'src/types/IShipmentForm';
import IShipmentState from 'src/types/IShipmentState';

const initialForm: IShipmentForm = {
  address: '',
  address2: '',
  city: '',
  firstName: '',
  lastName: '',
  phone: '',
  postalCode: '',
  state: '',
  birthdateCheckbox: CheckboxState.Unchecked,
};

export const initialState: IShipmentState = {
  errors: [],
  form: { ...initialForm },
  oldForm: { ...initialForm },
  warnings: [],
};

function handleShipmentSuccess(
  state: IShipmentState,
  action: IShipmentSuccessAction,
): IShipmentState {
  return {
    ...state,
    errors: [],
    form: { ...action.form },
    shipment: { ...action.shipment },
  };
}

function handleSetAppUser(
  state: IShipmentState,
  action: ISetAppUserAction,
): IShipmentState {
  if (!action.appUser.firstName && !action.appUser.lastName) {
    return state;
  }
  const firstName = action.appUser.firstName || state.form.firstName;
  const lastName = action.appUser.lastName || state.form.lastName;
  return {
    ...state,
    form: {
      ...state.form,
      firstName,
      lastName,
    },
  };
}

function handleShipmentFailure(
  state: IShipmentState,
  action: IShipmentFailureAction,
): IShipmentState {
  return { ...state, errors: [...action.errors], form: { ...action.form } };
}

function handleShipmentSoftValidation(
  state: IShipmentState,
  action: IShipmentSoftValidationAction,
): IShipmentState {
  return { ...state, warnings: [...action.warnings] };
}

function handleShipmentForm(
  state: IShipmentState,
  action: IShipmentFormAction,
): IShipmentState {
  const errors = removeErrorsForFields(
    state.errors,
    getChangedFormFields(state.form, action.form),
  );
  return { ...state, errors: [...errors], form: { ...action.form } };
}

function handleOrderBundleSuccess(state: IShipmentState): IShipmentState {
  return {
    ...state,
    errors: [],
  };
}

function handleEditShipment(state: IShipmentState): IShipmentState {
  return { ...state, oldForm: { ...state.form } };
}

function handleDiscardForm(state: IShipmentState): IShipmentState {
  return {
    ...state,
    errors: [],
    form: { ...state.oldForm },
    oldForm: { ...initialForm },
  };
}

function handleClearShipmentData(): IShipmentState {
  return { ...initialState };
}

function shipmentReducer(
  state: IShipmentState = initialState,
  action: ShipmentAction | { type: null } = {
    type: null,
  },
): IShipmentState {
  switch (action.type) {
    case SHIPMENT_SUCCESS_ACTION:
      return handleShipmentSuccess(state, action);

    case SHIPMENT_FAILURE_ACTION:
      return handleShipmentFailure(state, action);

    case SHIPMENT_SOFT_VALIDATION_ACTION:
      return handleShipmentSoftValidation(state, action);

    case SHIPMENT_FORM_ACTION:
      return handleShipmentForm(state, action);

    case ORDER_BUNDLE_SUCCESS_ACTION:
      return handleOrderBundleSuccess(state);

    case SET_APP_USER_ACTION:
      return handleSetAppUser(state, action);

    case EDIT_SHIPMENT_ACTION:
      return handleEditShipment(state);

    case DISCARD_SHIPMENT_FORM_ACTION:
      return handleDiscardForm(state);

    case CLEAR_SHIPMENT_DATA_ACTION:
      return handleClearShipmentData();
  }

  return state;
}

export default shipmentReducer;
