import cn from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import LinkButton from 'src/components/LinkButton/LinkButton';
import { getBundleDynamic } from '../../selectors/bundleSelectors';
import Overlay from '../Overlay/Overlay';
import Translate from '../Translate/Translate';
import css from './HeaderBundleInfoOverlay.module.scss';

export interface IProps {
  children: any;
  className?: string;
}

export function HeaderBundleInfoOverlay({
  children,
  className,
}: Readonly<IProps>) {
  const [visibility, setVisibility] = React.useState(false);
  const bundle = useSelector(getBundleDynamic);

  const onClose = () => setVisibility(false);
  const openOverlay = (event: any): void => {
    event.preventDefault();
    setVisibility(true);
  };

  const bundleCode = bundle?.code;

  if (!bundleCode) {
    return null;
  }

  return (
    <>
      <LinkButton className={cn(className, css.link)} onClick={openOverlay}>
        {children}
      </LinkButton>
      <Overlay show={visibility} onClose={onClose}>
        <div className={css.container}>
          <Translate
            t={`headerNotice.infoNotice.text.${bundleCode}`}
            html={true}
          />
        </div>
      </Overlay>
    </>
  );
}

export default HeaderBundleInfoOverlay;
