import { BACKEND, SUPPORTED_BACKENDS } from 'src/constants/backends';
import getBundleBackendRegion from 'src/lib/getBundleBackendRegion';
import IStoreState from 'src/types/IStoreState';
import { getSelectedBundle } from './bundleSelectors';

export function getProductBasedRegion(state: IStoreState): BACKEND[] {
  const bundle = getSelectedBundle(state);
  if (bundle) {
    return [getBundleBackendRegion(bundle)];
  }
  const backendRegions = state.flow.backendRegions ?? [];
  if (backendRegions.length > 0) {
    return backendRegions;
  }

  return SUPPORTED_BACKENDS;
}
