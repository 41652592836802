/* istanbul ignore file */

import {
  COUNTRY_DE,
  COUNTRY_ES,
  COUNTRY_NL,
  COUNTRY_PL,
  COUNTRY_US,
  COUNTRY_ZA,
} from './countries';

export interface ISupport {
  [propName: string]: {
    email: string;
    phone: string;
  };
}

export const SUPPORT_EMAIL_DEFAULT = 'support@mysugr.com';
export const SUPPORT_PHONE_US = '+1 (855) 337-7847';
export const SUPPORT_PHONE_DE = '+49 3221 1001 999';

const support: ISupport = {
  default: {
    email: SUPPORT_EMAIL_DEFAULT,
    phone: SUPPORT_PHONE_US,
  },
  [COUNTRY_NL]: {
    email: SUPPORT_EMAIL_DEFAULT,
    phone: SUPPORT_PHONE_US,
  },
  [COUNTRY_US]: {
    email: SUPPORT_EMAIL_DEFAULT,
    phone: SUPPORT_PHONE_US,
  },
  [COUNTRY_DE]: {
    email: SUPPORT_EMAIL_DEFAULT,
    phone: SUPPORT_PHONE_DE,
  },
  [COUNTRY_PL]: {
    email: '--- TODO ---',
    phone: '--- TODO ---',
  },
  [COUNTRY_ES]: {
    email: SUPPORT_EMAIL_DEFAULT,
    phone: SUPPORT_PHONE_DE,
  },
  [COUNTRY_ZA]: {
    email: SUPPORT_EMAIL_DEFAULT,
    phone: '', // No support phone number fallback for South Africa
  },
};

export default support;
