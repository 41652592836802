import {
  BACKEND,
  BACKEND_EU,
  BACKEND_US,
  SUPPORTED_BACKENDS,
} from 'src/constants/backends';
import IEnvironment from 'src/types/IEnvironment';

const env = process.env as any as IEnvironment;

interface IApiManagerConfig {
  hostName: string;
  client_id: string;
  client_secret: string;
  apiKey: string;
}

type ApiManagerConfigList = Record<BACKEND, IApiManagerConfig>;

const prepareApiManagerConfig = (region: BACKEND, isAppUser: boolean) => {
  const config: ApiManagerConfigList = {
    [BACKEND_US]: {
      hostName: env.REACT_APP_API_MANAGER_HOSTNAME_US,
      client_id: env.REACT_APP_API_MANAGER_CLIENT_ID_US,
      client_secret: env.REACT_APP_API_MANAGER_CLIENT_SECRET_US,
      apiKey: isAppUser
        ? env.REACT_APP_API_MANAGER_API_KEY_MOBILE_US
        : env.REACT_APP_API_MANAGER_API_KEY_US,
    },
    [BACKEND_EU]: {
      hostName: env.REACT_APP_API_MANAGER_HOSTNAME_EU,
      client_id: env.REACT_APP_API_MANAGER_CLIENT_ID_EU,
      client_secret: env.REACT_APP_API_MANAGER_CLIENT_SECRET_EU,
      apiKey: isAppUser
        ? env.REACT_APP_API_MANAGER_API_KEY_MOBILE_EU
        : env.REACT_APP_API_MANAGER_API_KEY_EU,
    },
  };
  return config[region];
};

export const backendsToProbe = Object.keys(SUPPORTED_BACKENDS) as BACKEND[];

export function getApiManagerConfig(region: BACKEND, isAppUser = false) {
  return prepareApiManagerConfig(region, isAppUser);
}
