import IBundleGroup from 'src/types/IBundleGroup';

export interface IBundleParsedParameters {
  showPromotion?: boolean;
}

export function parseBundleGroupAttributes(bundleGroup?: IBundleGroup) {
  const parameters = bundleGroup?.attributes ?? [];
  const parsed = {};

  for (const { key, value } of parameters) {
    parsed[key] = value;
  }

  return parsed;
}
