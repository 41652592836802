import * as React from 'react';
import Svg from 'react-inlinesvg';
import { connect } from 'react-redux';
import { getActiveStep, getSteps } from 'src/selectors/flowSelectors';
import IStep from 'src/types/IStep';
import IStoreState from 'src/types/IStoreState';
import Translate from '../Translate/Translate';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import css from './StepBar.module.scss';
import StepBarStep from './StepBarStep';
import stepIcon from './step.svg';

interface IStateProps {
  activeStep?: IStep;
  steps: IStep[];
}

export interface IProps extends IStateProps {
  alwaysShowNumbers?: boolean;
}

export function StepBar({
  alwaysShowNumbers,
  steps,
  activeStep,
}: Readonly<IProps>): React.ReactElement {
  const uiSteps = steps
    .filter(step => step.uiStep > 0)
    .filter(step => step.visible !== false);

  if (uiSteps.length <= 2) {
    return <div className={css.placeholder} />;
  }

  const activeStepNumber = activeStep?.step ?? 0;
  const ariaHidden = activeStepNumber > 1;

  return (
    <>
      {ariaHidden && activeStep && (
        <VisuallyHidden>
          <Translate
            t={'stepIndicationText'}
            variables={{
              current: activeStep.uiStep,
              total: uiSteps.length,
            }}
          />
        </VisuallyHidden>
      )}
      <div className={css.container} aria-hidden={ariaHidden}>
        <div className={css.imgContainer}>
          {<Svg src={stepIcon} uniquifyIDs={false} />}
        </div>
        <VisuallyHidden>
          <Translate t="stepBar.title.screenReader" />
        </VisuallyHidden>
        <ul className={css.list}>
          {uiSteps.map(step => {
            const isActive = activeStep ? activeStep.step === step.step : false;
            const isDone = activeStep ? step.step < activeStep.step : false;
            return (
              <StepBarStep
                alwaysShowNumbers={alwaysShowNumbers}
                className={css[`step-${uiSteps.length}`]}
                key={`step-${step.step}`}
                step={step}
                totalStepCount={uiSteps.length}
                isActive={isActive}
                isDone={isDone}
              />
            );
          })}
        </ul>
      </div>
    </>
  );
}

StepBar.defaultProps = {
  alwaysShowNumbers: false,
};

export function mapStateToProps(state: IStoreState): IStateProps {
  return {
    activeStep: getActiveStep(state),
    steps: getSteps(state),
  };
}

export default connect(mapStateToProps)(StepBar);
