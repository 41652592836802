import { BACKEND, BACKEND_EU, BACKEND_US } from 'src/constants/backends';
import { LANGUAGE } from 'src/constants/languages';
import BackendErrorException from 'src/lib/apiClient/BackendErrorException';
import isErrorResponse from 'src/lib/apiClient/isErrorResponse';
import UnauthorizedException from 'src/lib/apiClient/UnauthorizedException';
import IAuthUser, { AuthType } from 'src/types/IAuthUser';
import IEnvironment from 'src/types/IEnvironment';
import IShopWindow from 'src/types/IShopWindow';
import IUser from 'src/types/IUser';
import BackendClient from './BackendClient';
import { getApiManagerConfig } from './getApiManagerConfig';
import prepareOIDCHeader, { OIDC_HEADER } from './prepareOIDCHeader';

const env = process.env as any as IEnvironment;

export type REQUEST_METHOD = 'GET' | 'POST' | 'DELETE' | 'PUT';
export type BACKEND_API_VERSION = 2 | 3;

export interface IRequestParams {
  [key: string]: string | number | boolean | undefined;
}

export interface IApiResponse<T = any> {
  data?: T;
  status: number;
  statusText?: string;
  request?: any;
  unauthorized?: boolean;
}

export interface IRequestOptions {
  apiVersion?: BACKEND_API_VERSION;
  backend: BACKEND;
  data?: any;
  language?: LANGUAGE;
  user?: IUser | IAuthUser;
  method?: REQUEST_METHOD;
  params?: IRequestParams;
  headers?: { [key: string]: string };
}

export interface IRDCPRequestConfig {
  hostName: string;
  additionalHeaders: Record<string, string>;
}

export interface IRequestOptionsNoBody extends IRequestOptions {
  data?: never;
}

export function isRDCPUser(user: IUser | undefined): user is IAuthUser {
  return (
    !!user &&
    (user as IAuthUser).authenticationToken !== undefined &&
    (user as IAuthUser).authType === AuthType.RDCP
  );
}

export function isAppUser(
  user: IAuthUser | IUser | undefined,
): user is IAuthUser {
  return !!user && !!(user as IAuthUser).isAppUser;
}

export function convertObjectToGetParams(params: IRequestParams) {
  const all = ['?'];
  const keys = Object.keys(params);

  keys.forEach(key => {
    const value = params[key];
    if (all.length > 1) {
      all.push('&');
    }
    if (typeof value !== 'undefined') {
      all.push(`${key}=${encodeURIComponent(value)}`);
    }
  });

  return keys.length > 0 ? all.join('') : '';
}

function isAuthUser(user: IUser): user is IAuthUser {
  return (user as IAuthUser).authenticationToken !== undefined;
}

function getAuth(user: IUser | IAuthUser): { Authorization: string } {
  if (isRDCPUser(user)) {
    return {
      Authorization: `Bearer ${user.authenticationToken}`,
    };
  }

  let authenticationToken = Buffer.from(
    `${user.email}:${user.password}`,
  ).toString('base64');

  if (isAuthUser(user)) {
    authenticationToken = Buffer.from(`x:${user.authenticationToken}`).toString(
      'base64',
    );
  }

  return { Authorization: `Basic ${authenticationToken}` };
}

export function getRDCPRequestConfig(
  backend: BACKEND,
  isAppUser: boolean,
): IRDCPRequestConfig {
  const apiManager = getApiManagerConfig(backend, isAppUser);
  const { apiKey, client_id, client_secret, hostName } = apiManager;
  return {
    additionalHeaders: {
      apiKey,
      client_id,
      client_secret,
    },
    hostName,
  };
}

export function getBaseUrlForBackend(backend: BACKEND): string {
  switch (backend) {
    case BACKEND_EU:
      return env.REACT_APP_API_BASE_URL_EU;
    case BACKEND_US:
      return env.REACT_APP_API_BASE_URL_US;

    default:
      return env.REACT_APP_API_BASE_URL_US;
  }
}

function getApiVersion(apiVersion?: BACKEND_API_VERSION): BACKEND_API_VERSION {
  return apiVersion ?? 2;
}

export async function getFieldsFromResponse(
  response: Response,
): Promise<IApiResponse> {
  let text = response.text && (await response.text());
  text = text?.replace(/(^\n*)|(\n*)$/g, '');

  let data = text;
  try {
    data = text && JSON.parse(text);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  return {
    data,
    status: response.status,
    statusText: response.statusText,
    unauthorized: response.status === 401,
  };
}

/**
 * Sends a request to the mySugr backend
 *
 * @param url
 * @param options
 */
async function doBackendRequest(
  url: string,
  options: IRequestOptions,
): Promise<IApiResponse> {
  const baseUrl = getBaseUrlForBackend(options.backend);
  const apiVersion = getApiVersion(options.apiVersion);
  const auth = options.user && getAuth(options.user);
  const getParameters = options.params
    ? convertObjectToGetParams(options.params)
    : '';

  let fullUrl = `${baseUrl}/v${apiVersion}${url}${getParameters}`;
  let headers: { [key: string]: string } = {
    accept: 'application/json, text/plain, */*',
    'cache-control': 'no-cache',
    'content-type': 'application/json;charset=UTF-8',
    pragma: 'no-cache',
    ...(options.headers ?? {}),
  };
  if (options.language) {
    headers['Accept-Language'] = options.language;
  }

  if (auth) {
    headers = { ...auth, ...headers };
  }
  const rdcpUser = isRDCPUser(options.user);

  if (rdcpUser) {
    const { additionalHeaders, hostName } = getRDCPRequestConfig(
      options.backend,
      isAppUser(options.user),
    );

    fullUrl = `${hostName}/mysugr-be/api/v${apiVersion}${url}${getParameters}`;
    headers = {
      ...headers,
      ...additionalHeaders,
    };
  }

  const initOptions: RequestInit = {
    headers,
    method: options.method,
  };

  const methodWithData = options.method === 'POST' || options.method === 'PUT';

  if (methodWithData && options.data) {
    initOptions.body = JSON.stringify(options.data);
    headers['content-length'] = initOptions.body.length.toString();
  }

  return new Promise(async (resolve, reject) => {
    try {
      let response = await fetch(fullUrl, initOptions);

      let fields = await getFieldsFromResponse(response);

      if (rdcpUser && fields.status === 401) {
        try {
          const { access_token, refresh_token } =
            await BackendClient.getInstance().RDCPAuth(
              (options.user as IAuthUser).refreshToken!,
              'REFRESH',
              options.backend,
            );

          let requestRetryHeaders: Record<string, string> = {};
          if (OIDC_HEADER in initOptions.headers!) {
            requestRetryHeaders = prepareOIDCHeader(
              access_token,
              AuthType.RDCP,
            );
          }

          response = await fetch(fullUrl, {
            ...initOptions,
            headers: {
              ...initOptions.headers,
              ...requestRetryHeaders,
              Authorization: `Bearer ${access_token}`,
            },
          });

          fields = await getFieldsFromResponse(response);

          const shopWindow: IShopWindow = window;
          if (shopWindow.setAuthToken) {
            shopWindow.setAuthToken(access_token, refresh_token);
          }
        } catch (e) {
          reject(new UnauthorizedException());
          return;
        }
      }

      if (!response.ok) {
        reject(fields);
        return;
      }
      resolve(fields);
    } catch (e) {
      if (e.response?.status === 401) {
        reject(new UnauthorizedException());
        return;
      } else if (e.response?.data && isErrorResponse(e.response.data)) {
        reject(new BackendErrorException(e.response.data.error));
        return;
      }
      reject(e);
    }
  });
}

export default {
  get: <T = any>(
    url: string,
    options: IRequestOptionsNoBody,
  ): Promise<IApiResponse<T>> =>
    doBackendRequest(url, {
      ...options,
      method: 'GET',
    }),
  post: <T = any>(
    url: string,
    options: IRequestOptions,
  ): Promise<IApiResponse<T>> =>
    doBackendRequest(url, {
      ...options,
      method: 'POST',
    }),
  delete: <T = any>(
    url: string,
    options: IRequestOptionsNoBody,
  ): Promise<IApiResponse<T>> =>
    doBackendRequest(url, {
      ...options,
      method: 'DELETE',
    }),
  put: <T = any>(
    url: string,
    options: IRequestOptions,
  ): Promise<IApiResponse<T>> =>
    doBackendRequest(url, {
      ...options,
      method: 'PUT',
    }),
};
