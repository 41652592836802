import { COUNTRY_US } from 'src/constants/countries';
import browserOnly from 'src/lib/browserOnly';
import validateShipmentForm, {
  softValidateAddressForm,
} from 'src/lib/validation/validateShipmentForm';
import IFormNotice from 'src/types/IFormNotice';
import IShipment from 'src/types/IShipment';
import IShipmentForm from 'src/types/IShipmentForm';
import IStoreState from 'src/types/IStoreState';
import { getCountry } from '../selectors/flowSelectors';
import { IOrderBundleSuccessAction } from './bundleActions';
import { IEditShipmentAction } from './overviewActions';
import { ISetAppUserAction } from './userActions';

export const SHIPMENT_SUCCESS_ACTION = 'SHIPMENT_SUCCESS_ACTION';
export type SHIPMENT_SUCCESS_ACTION = typeof SHIPMENT_SUCCESS_ACTION;

export const SHIPMENT_FAILURE_ACTION = 'SHIPMENT_FAILURE_ACTION';
export type SHIPMENT_FAILURE_ACTION = typeof SHIPMENT_FAILURE_ACTION;

export const SHIPMENT_SOFT_VALIDATION_ACTION =
  'SHIPMENT_SOFT_VALIDATION_ACTION';
export type SHIPMENT_SOFT_VALIDATION_ACTION =
  typeof SHIPMENT_SOFT_VALIDATION_ACTION;

export const SHIPMENT_FORM_ACTION = 'SHIPMENT_FORM_ACTION';
export type SHIPMENT_FORM_ACTION = typeof SHIPMENT_FORM_ACTION;

export const DISCARD_SHIPMENT_FORM_ACTION = 'DISCARD_SHIPMENT_FORM_ACTION';
export type DISCARD_SHIPMENT_FORM_ACTION = typeof DISCARD_SHIPMENT_FORM_ACTION;

export const CLEAR_SHIPMENT_DATA_ACTION = 'CLEAR_SHIPMENT_DATA_ACTION';
export type CLEAR_SHIPMENT_DATA_ACTION = typeof CLEAR_SHIPMENT_DATA_ACTION;

export interface IClearShipmentDataAction {
  type: CLEAR_SHIPMENT_DATA_ACTION;
}

export interface IShipmentSuccessAction {
  type: SHIPMENT_SUCCESS_ACTION;
  form: IShipmentForm;
  shipment: IShipment;
}

export interface IShipmentFailureAction {
  type: SHIPMENT_FAILURE_ACTION;
  form: IShipmentForm;
  errors: IFormNotice[];
}

export interface IShipmentSoftValidationAction {
  type: SHIPMENT_SOFT_VALIDATION_ACTION;
  warnings: IFormNotice[];
}

export interface IShipmentFormAction {
  type: SHIPMENT_FORM_ACTION;
  form: IShipmentForm;
}

export interface IDiscardShipmentFormAction {
  type: DISCARD_SHIPMENT_FORM_ACTION;
}

export type ShipmentAction =
  | IClearShipmentDataAction
  | IShipmentSuccessAction
  | IShipmentFailureAction
  | IShipmentFormAction
  | IOrderBundleSuccessAction
  | IDiscardShipmentFormAction
  | ISetAppUserAction
  | IShipmentSoftValidationAction
  | IEditShipmentAction;

export function clearShipmentDataAction(): IClearShipmentDataAction {
  return { type: CLEAR_SHIPMENT_DATA_ACTION };
}

export function shipmentSuccessAction(
  form: IShipmentForm,
  shipment: IShipment,
): IShipmentSuccessAction {
  return { type: SHIPMENT_SUCCESS_ACTION, form, shipment };
}

export function shipmentFailureAction(
  form: IShipmentForm,
  errors: IFormNotice[],
): IShipmentFailureAction {
  return { type: SHIPMENT_FAILURE_ACTION, form, errors };
}

export function shipmentAction(
  form: IShipmentForm,
  conditionalRequiredFields: { [key: string]: boolean } = {},
  minAgeToSubscribe?: number,
) {
  return (dispatch: any, getState: () => IStoreState) => {
    const fullForm: IShipmentForm = {
      ...form,
      country: form.country ?? getCountry(getState()) ?? COUNTRY_US,
    };
    const { valid, errors } = validateShipmentForm(
      fullForm,
      conditionalRequiredFields,
      minAgeToSubscribe,
    );

    if (valid === false) {
      dispatch(shipmentFailureAction(fullForm, errors));
      return Promise.reject(new Error('Shipment form is not valid'));
    }
    const shipment = { ...fullForm } as IShipment;
    dispatch(shipmentSuccessAction(fullForm, shipment));
    return Promise.resolve();
  };
}

export function shipmentSoftValidationAction(
  warnings: IFormNotice[],
): IShipmentSoftValidationAction {
  return { type: SHIPMENT_SOFT_VALIDATION_ACTION, warnings };
}

export function shipmentFormSoftValidateAction(form: IShipmentForm) {
  return (dispatch: any, getState: () => IStoreState) => {
    const fullForm = {
      ...form,
      country: form.country ?? getCountry(getState()),
    };
    const { warnings } = softValidateAddressForm(fullForm);
    dispatch(shipmentSoftValidationAction(warnings));
    return Promise.resolve();
  };
}

export function shipmentFormAction(form: IShipmentForm): IShipmentFormAction {
  return { type: SHIPMENT_FORM_ACTION, form };
}

export function discardShipmentFormAction(): IDiscardShipmentFormAction {
  browserOnly(() => {
    window.scroll(0, 0);
  });
  return { type: DISCARD_SHIPMENT_FORM_ACTION };
}
