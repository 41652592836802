import classnames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  getCartImmediateItems,
  getCartProductItems,
  getCartSubscriptionItems,
} from 'src/selectors/bundleSelectors';
import IBundleGroupItem from 'src/types/IBundleGroupItem';
import IStoreState from 'src/types/IStoreState';
import ShoppingCartList from '../ShoppingCartList/ShoppingCartList';
import Translate from '../Translate/Translate';
import css from './ShoppingCart.module.scss';

interface IStateProps {
  immediate: IBundleGroupItem[];
  product: IBundleGroupItem[];
  subscription: IBundleGroupItem[];
}

export interface IProps extends IStateProps {
  className?: string;
  listItemWhiteBg?: boolean;
  showHeadline?: boolean;
}

export class ShoppingCart extends React.PureComponent<IProps> {
  public render() {
    const {
      className,
      immediate,
      product,
      subscription,
      showHeadline = true,
    } = this.props;

    const hasProduct = product.length > 0 ? 1 : 0;
    const hasSubscription = subscription.length > 0 ? 1 : 0;
    const hasImmediate = immediate.length > 0 ? 1 : 0;
    const isOneType = hasProduct + hasSubscription + hasImmediate === 1;

    return (
      <div className={classnames(css.container, className)}>
        {showHeadline && (
          <h2 className={css.title}>
            <Translate t="shoppingCart.title" />
          </h2>
        )}
        <ul
          className={classnames(
            { [css.list]: !isOneType },
            { [css.listOneItem]: isOneType },
          )}
        >
          {immediate.length > 0 && (
            <li className={classnames(css.item, css['item--immediates'])}>
              <ShoppingCartList
                accentColor="radish"
                headline={'shoppingCart.immediateTitle'}
                items={immediate}
                whiteBg={this.props.listItemWhiteBg}
              />
            </li>
          )}
          {product.length > 0 && (
            <li className={classnames(css.item, css['item--products'])}>
              <ShoppingCartList
                accentColor="tree"
                headline={'shoppingCart.productTitle'}
                items={product}
                whiteBg={this.props.listItemWhiteBg}
              />
            </li>
          )}
          {subscription.length > 0 && (
            <li className={classnames(css.item, css['item--subscriptions'])}>
              <ShoppingCartList
                accentColor="dark-bolus"
                headline={'shoppingCart.subscriptionTitle'}
                items={subscription}
                whiteBg={this.props.listItemWhiteBg}
              />
            </li>
          )}
        </ul>
      </div>
    );
  }
}

export function mapStateToProps(state: IStoreState): IStateProps {
  return {
    immediate: getCartImmediateItems(state),
    product: getCartProductItems(state),
    subscription: getCartSubscriptionItems(state),
  };
}

export default connect(mapStateToProps)(ShoppingCart);
