function migrateTo2(state: any) {
  return {
    ...state,
    notification: { notifications: [] },
    register: {
      ...state.register,
      status: { ...state.register.status, showRegistration: false },
    },
  };
}

export default migrateTo2;
