import { IBundleImageName } from 'src/types/IBundleImageName';
import { IBundleImageSet, loadBundleImageSet } from './loadBundleImageSet';

/** Loads the bundle image set from the root folder `src/images/product-image`,
 * found by searching for the given `imagePartialPath` (composed of a potential folder + image name),
 * that ends with `_gray`.
 * The image set consists of the bundle image in 3 sizes (`@1x`, `@2x`, `@3x`) and in `jpg` and `webp` formats.
 * It also features an image type.
 *
 * In case the images are not found, a default set of empty values is returned **/
export function loadGrayBundleImageSet(
  partialImagePath: string,
  imageType: IBundleImageName,
): IBundleImageSet {
  return loadBundleImageSet(`${partialImagePath}_gray`, imageType);
}

/** Loads the bundle image set from the root folder `src/images/product-image`,
 * found by searching for the given `imagePartialPath` (composed of a potential folder + image name),
 * that ends with `_white`.
 * The image set consists of the bundle image in 3 sizes (`@1x`, `@2x`, `@3x`) and in `jpg` and `webp` formats.
 * It also features an image type.
 *
 * In case the images are not found, a default set of empty values is returned **/
export function loadWhiteBundleImageSet(
  partialImagePath: string,
  imageType: IBundleImageName,
): IBundleImageSet {
  return loadBundleImageSet(`${partialImagePath}_white`, imageType);
}

interface IGrayAndWhiteBundleImageSets {
  gray: IBundleImageSet;
  white: IBundleImageSet;
}

/** Loads 2 bundle image sets from the root folder `src/images/product-image`,
 * found by searching for the given `imagePartialPath` (composed of a potential folder + image name).
 * One set must end with `_gray`, while the other one must end with `_white`.
 * The image sets consist of the bundle images in 3 sizes (`@1x`, `@2x`, `@3x`) and in `jpg` and `webp` formats.
 * It also features an image type.
 *
 * In case the sets are not found, default sets with empty values are returned **/
export function loadGrayAndWhiteBundleImageSets(
  partialImagePath: string,
  imageType: IBundleImageName,
): IGrayAndWhiteBundleImageSets {
  return {
    gray: loadGrayBundleImageSet(partialImagePath, imageType),
    white: loadWhiteBundleImageSet(partialImagePath, imageType),
  };
}
