/* istanbul ignore file */

export const END_OF_PAYMENT_INTERVAL = 'END_OF_PAYMENT_INTERVAL';
export type END_OF_PAYMENT_INTERVAL = typeof END_OF_PAYMENT_INTERVAL;

export const MONTH = 'MONTH';
export type MONTH = typeof MONTH;

export const YEAR = 'YEAR';
export type YEAR = typeof YEAR;

export const PAYOR_COVERED = 'PAYOR_COVERED';
export type PAYOR_COVERED = typeof PAYOR_COVERED;

export const PAID_UP_FRONT = 'PAID_UP_FRONT';
export type PAID_UP_FRONT = typeof PAID_UP_FRONT;

export const SELF_PAID = 'SELF_PAID';
export type SELF_PAID = typeof SELF_PAID;

export const BUNDLE_NOT_AVAILABLE = 'BUNDLE_NOT_AVAILABLE';
export type BUNDLE_NOT_AVAILABLE = typeof BUNDLE_NOT_AVAILABLE;

export const BUNDLE_INFO_NOTICE = 'BUNDLE_INFO_NOTICE';
export type BUNDLE_INFO_NOTICE = typeof BUNDLE_INFO_NOTICE;

export type CANCELATION_PERIOD = END_OF_PAYMENT_INTERVAL;
export type INTERVAL = MONTH | YEAR;
export type PAYMENT_TYPE = PAYOR_COVERED | PAID_UP_FRONT | SELF_PAID;

export const DEFAULT_STRIPS_COUNT = 200;
