import React from 'react';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {
  ITranslateProps,
  translateAction,
} from 'src/actions/translationActions';
import OverlayInner from 'src/components/Overlay/OverlayInner';
import IEnvironment from 'src/types/IEnvironment';

const env = process.env as any as IEnvironment;

interface IDispatchProps {
  translate: (key: string, values?: Record<string, string>) => any;
}

interface IStateProps {
  ssr: boolean;
}

export interface IProps extends IDispatchProps, IStateProps {
  onClose: () => void;
  children?: any;
  show: boolean;
  ariaLabel?: string;
  ariaLabelClose?: string;
  className?: string;
}

function handleModalOpen() {
  // disable scrolling on window
  document.body.style.overflow = 'hidden';

  // prevent screenreader from entering the main content while the dialog is open
  const mainContent = document.querySelector('#mainContent');
  // istanbul ignore else
  if (mainContent) {
    mainContent.setAttribute('aria-hidden', 'true');
  }
}

function handleModalClose() {
  // allow scrolling on body
  document.body.style.overflow = '';

  // allow the screenreader to access main content
  const mainContent = document.querySelector('#mainContent');
  // istanbul ignore else
  if (mainContent) {
    mainContent.removeAttribute('aria-hidden');
  }
}

export function Overlay({
  onClose,
  ariaLabel,
  ariaLabelClose,
  show,
  children,
  translate,
  className,
  ssr,
}: IProps & ITranslateProps) {
  if (ssr) {
    return null;
  }

  const overlayNode: any = React.createRef();
  const componentInstanceId = `id_${Math.random()}`;
  let lastFocus: Element | null | false;
  const target = document.querySelector('#portalTarget');

  const closeWrap = () => {
    /* istanbul ignore else */
    if (lastFocus) {
      (lastFocus as HTMLElement).focus();
    }

    onClose();
  };

  function setFocus() {
    lastFocus = document.activeElement;
    /* istanbul ignore else */
    if (overlayNode?.current) {
      overlayNode.current.focus();
    }
  }

  function handleInnerClick(event: React.SyntheticEvent) {
    event.stopPropagation();
  }

  function eventListenerHandler(key: KeyboardEvent) {
    const { code } = key;

    // istanbul ignore else
    if (code === 'Escape') {
      closeWrap();
      window.removeEventListener('keydown', eventListenerHandler);
    }
  }

  function setKeyboardListener(setListener: boolean) {
    if (setListener) {
      window.addEventListener('keydown', eventListenerHandler);
    }
  }

  React.useEffect(() => {
    if (show) {
      handleModalOpen();
      setFocus();
    } else {
      handleModalClose();
    }

    setKeyboardListener(show);

    return () => {
      handleModalClose();
    };
  }, [show]);

  return target
    ? ReactDOM.createPortal(
        <OverlayInner
          ariaLabel={ariaLabel}
          ariaLabelClose={ariaLabelClose}
          className={className}
          closeWrap={closeWrap}
          componentInstanceId={componentInstanceId}
          handleInnerClick={handleInnerClick}
          overlayNode={overlayNode}
          show={show}
          translate={translate}
        >
          {children}
        </OverlayInner>,
        target,
      )
    : null;
}

export function mapStateToProps(): IStateProps {
  return {
    ssr: env.REACT_APP_SERVER_SIDE_RENDERING === '1',
  };
}

export function mapDispatchToProps(dispatch: any): IDispatchProps {
  return {
    translate: (key: string, values?: Record<string, any>) =>
      dispatch(translateAction(key, values)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Overlay);
