import * as React from 'react';
import { IImageSources } from 'src/lib/loadItemImageSet';
import { matchPixelDescriptor, matchWidthDescriptor } from './matcher';

export interface ISize {
  size: string;
  mediaCondition?: string;
}

export interface IProps {
  src: string;
  alt?: string;
  className?: string;
  srcSet?: any;
  sizes?: ISize[];
  webpSrc?: IImageSources;
  onRef?: (ref: HTMLImageElement) => void;
}

export default class Image extends React.PureComponent<IProps> {
  public static readonly defaultProps = {
    alt: '', // it indicates this image is not a key part of the content
  };

  public readonly widthDescriptorOnly: boolean;

  constructor(props: IProps) {
    super(props);

    this.widthDescriptorOnly = Object.keys(props.srcSet).every(descriptor =>
      matchWidthDescriptor(descriptor),
    );
  }

  public buildSrcSet(useWebp: boolean) {
    const { webpSrc, srcSet } = this.props;
    const useSrc = useWebp && webpSrc ? webpSrc.srcSet : srcSet;

    const matcher = this.widthDescriptorOnly
      ? matchWidthDescriptor
      : matchPixelDescriptor;

    return (
      Object.keys(useSrc)
        .filter(matcher)
        .map(descriptor => `${useSrc[descriptor]} ${descriptor}`)
        .join(',') || undefined
    );
  }

  public buildSizes() {
    if (this.props.sizes && this.widthDescriptorOnly) {
      return this.props.sizes
        .map(size => {
          if (size.mediaCondition) {
            return `${size.mediaCondition} ${size.size}`;
          }
          return `${size.size}`;
        })
        .join(',');
    }
    return undefined;
  }

  public renderImg() {
    return (
      <img
        alt={this.props.alt}
        className={this.props.className}
        src={this.props.src}
        srcSet={this.buildSrcSet(false)}
        sizes={this.buildSizes()}
        ref={this.props.onRef}
      />
    );
  }

  public renderWebp() {
    return (
      <source
        type="image/webp"
        className={this.props.className}
        srcSet={this.buildSrcSet(true)}
        sizes={this.buildSizes()}
      />
    );
  }

  public render() {
    if (this.props.webpSrc) {
      return (
        <picture>
          {this.renderWebp()}
          {this.renderImg()}
        </picture>
      );
    }
    return this.renderImg();
  }
}
