import IFormNotice from 'src/types/IFormNotice';

export const CONFIRM_EMAIL_ACTION = 'CONFIRM_EMAIL_ACTION';
export type CONFIRM_EMAIL_ACTION = typeof CONFIRM_EMAIL_ACTION;

export const CONFIRM_EMAIL_BEGIN_ACTION = 'CONFIRM_EMAIL_BEGIN_ACTION';
export type CONFIRM_EMAIL_BEGIN_ACTION = typeof CONFIRM_EMAIL_BEGIN_ACTION;

export const CONFIRM_EMAIL_SUCCESS_ACTION = 'CONFIRM_EMAIL_SUCCESS_ACTION';
export type CONFIRM_EMAIL_SUCCESS_ACTION = typeof CONFIRM_EMAIL_SUCCESS_ACTION;

export const CONFIRM_EMAIL_FAILURE_ACTION = 'CONFIRM_EMAIL_FAILURE_ACTION';
export type CONFIRM_EMAIL_FAILURE_ACTION = typeof CONFIRM_EMAIL_FAILURE_ACTION;

export interface IConfirmEmailAction {
  type: CONFIRM_EMAIL_ACTION;
  email: string;
  code: string;
}

export interface IConfirmEmailBeginAction {
  type: CONFIRM_EMAIL_BEGIN_ACTION;
}

export interface IConfirmEmailSuccessAction {
  type: CONFIRM_EMAIL_SUCCESS_ACTION;
  registrationToken: string;
}

export interface IConfirmEmailFailureAction {
  type: CONFIRM_EMAIL_FAILURE_ACTION;
  errors: IFormNotice[];
}

export type ConfirmEmailAction =
  | IConfirmEmailAction
  | IConfirmEmailBeginAction
  | IConfirmEmailSuccessAction
  | IConfirmEmailFailureAction;

export function confirmEmailBeginAction(): IConfirmEmailBeginAction {
  return { type: CONFIRM_EMAIL_BEGIN_ACTION };
}

export function confirmEmailSuccessAction(
  registrationToken: string,
): IConfirmEmailSuccessAction {
  return { type: CONFIRM_EMAIL_SUCCESS_ACTION, registrationToken };
}

export function confirmEmailFailureAction(
  errors: IFormNotice[],
): IConfirmEmailFailureAction {
  return { type: CONFIRM_EMAIL_FAILURE_ACTION, errors };
}
