import {
  IMAGE_SET_COACH_AOK_NORTHWEST,
  IMAGE_SET_COACH_ONLY,
  IMAGE_SET_COACH_ONLY_WHITE,
} from 'src/images/product-image/productImages';
import { IBundleImageSet } from 'src/lib/loadBundleImageSet';

interface IBundleGroupOverride {
  productImages?: {
    gray: IBundleImageSet;
    white: IBundleImageSet;
  };

  requiresShipment?: boolean;
  type?: string;
}

const bundleGroupOverride: {
  [bundleGroupCode: string]: IBundleGroupOverride;
} = {
  aok_nordwest_bundle_group_coach_1: {
    // custom image with extra stuff
    productImages: {
      gray: IMAGE_SET_COACH_AOK_NORTHWEST,
      white: IMAGE_SET_COACH_AOK_NORTHWEST,
    },
  },

  buchanan_group_bundle_coach_2: {
    // bundle is coach but has scale
    productImages: {
      gray: IMAGE_SET_COACH_ONLY,
      white: IMAGE_SET_COACH_ONLY_WHITE,
    },
    requiresShipment: true,
    type: 'coach',
  },

  garfield_county_bundle_group_2: {
    // bundle is coach but has scale
    productImages: {
      gray: IMAGE_SET_COACH_ONLY,
      white: IMAGE_SET_COACH_ONLY_WHITE,
    },
    requiresShipment: true,
    type: 'coach',
  },
};

export default bundleGroupOverride;
