import classnames from 'classnames';
import * as React from 'react';
import css from 'src/components/HeaderBundleInfoBar/HeaderBundleInfoBar.module.scss';
import MoreInfoOverlay from '../MoreInfoOverlay/MoreInfoOverlay';

interface IProps {
  topBarMessage: string;
  moreInfoOverlayText?: string;
}

export default function GenericTopBarMessage({
  topBarMessage,
  moreInfoOverlayText,
}: Readonly<IProps>): React.ReactElement {
  return (
    <div className={classnames(css.bundleInfo)}>
      <span>{topBarMessage}</span>
      {moreInfoOverlayText && (
        <span>
          <MoreInfoOverlay>{moreInfoOverlayText}</MoreInfoOverlay>
        </span>
      )}
    </div>
  );
}
