import classnames from 'classnames';
import * as React from 'react';
import css from './ProductPageTitle.module.scss';

export interface IProps {
  children: React.ReactChild;
  className?: string;
  tagName?: string;
}

export const ProductPageTitle: React.FC<IProps> = ({
  children,
  className,
  tagName,
}: IProps) => {
  const finalClassName = classnames(css.title, className);
  if (tagName === 'h2') {
    return <h2 className={finalClassName}>{children}</h2>;
  }
  return <h1 className={finalClassName}>{children}</h1>;
};

ProductPageTitle.defaultProps = {
  tagName: 'h1',
};

export default ProductPageTitle;
