import { createSelector } from 'reselect';
import IStoreState from 'src/types/IStoreState';

function getLoading(state: IStoreState): string[] {
  return state.loading.loading;
}

export function isLoading(id: string) {
  return createSelector([getLoading], (loading: string[]): boolean =>
    Boolean(loading.find(iteratorId => iteratorId === id)),
  );
}
