import IFormNotice from 'src/types/IFormNotice';
import IPayor from 'src/types/IPayor';

export const ADD_ELIGIBILITY_ERROR_ACTION = 'ADD_ELIGIBILITY_ERROR_ACTION';
export type ADD_ELIGIBILITY_ERROR_ACTION = typeof ADD_ELIGIBILITY_ERROR_ACTION;

export const REMOVE_ELIGIBILITY_ERRORS_ACTION =
  'REMOVE_ELIGIBILITY_ERRORS_ACTION';
export type REMOVE_ELIGIBILITY_ERRORS_ACTION =
  typeof REMOVE_ELIGIBILITY_ERRORS_ACTION;

export const ELIGIBILITY_CHECK_SUCCESS_ACTION =
  'ELIGIBILITY_CHECK_SUCCESS_ACTION';
export type ELIGIBILITY_CHECK_SUCCESS_ACTION =
  typeof ELIGIBILITY_CHECK_SUCCESS_ACTION;

export const ELIGIBILITY_CHECK_FAILURE_ACTION =
  'ELIGIBILITY_CHECK_FAILURE_ACTION';
export type ELIGIBILITY_CHECK_FAILURE_ACTION =
  typeof ELIGIBILITY_CHECK_FAILURE_ACTION;

export const ELIGIBILITY_STORE_EMAIL_ACTION = 'ELIGIBILITY_STORE_EMAIL_ACTION';
export type ELIGIBILITY_STORE_EMAIL_ACTION =
  typeof ELIGIBILITY_STORE_EMAIL_ACTION;

export interface IAddEligibilityErrorAction {
  type: ADD_ELIGIBILITY_ERROR_ACTION;
  error: IFormNotice;
}

export interface IRemoveEligibilityErrorAction {
  type: REMOVE_ELIGIBILITY_ERRORS_ACTION;
}

export interface IEligibilityCheckSuccessAction {
  type: ELIGIBILITY_CHECK_SUCCESS_ACTION;
  companyEmail: string;
  payor: IPayor;
}

export interface IEligibilityCheckFailureAction {
  type: ELIGIBILITY_CHECK_FAILURE_ACTION;
  companyEmail: string;
}

export interface IEligibilityStoreEmailAction {
  type: ELIGIBILITY_STORE_EMAIL_ACTION;
  companyEmail: string;
}

export type EligibilityCheckActions =
  | IAddEligibilityErrorAction
  | IRemoveEligibilityErrorAction
  | IEligibilityCheckFailureAction
  | IEligibilityCheckSuccessAction
  | IEligibilityStoreEmailAction;

export function addEligibilityErrorAction(
  error: IFormNotice,
): IAddEligibilityErrorAction {
  return { type: ADD_ELIGIBILITY_ERROR_ACTION, error };
}

export function removeEligibilityErrorsAction(): IRemoveEligibilityErrorAction {
  return { type: REMOVE_ELIGIBILITY_ERRORS_ACTION };
}

export function eligibilityCheckSuccessAction(
  companyEmail: string,
  payor: IPayor,
): IEligibilityCheckSuccessAction {
  return { type: ELIGIBILITY_CHECK_SUCCESS_ACTION, companyEmail, payor };
}

export function eligibilityCheckFailureAction(
  companyEmail: string,
): IEligibilityCheckFailureAction {
  return { type: ELIGIBILITY_CHECK_FAILURE_ACTION, companyEmail };
}

export function eligibilityStoreEmailAction(
  companyEmail: string,
): IEligibilityStoreEmailAction {
  return { type: ELIGIBILITY_STORE_EMAIL_ACTION, companyEmail };
}
