import classnames from 'classnames';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { addBundleToCartAction } from 'src/actions/bundleActions';
import BundleBoxImage from 'src/components/BundleBoxImage/BundleBoxImage';
import Button from 'src/components/Button/Button';
import VisuallyHidden from 'src/components/VisuallyHidden/VisuallyHidden';
import {
  PROPERTY_CARD_GROUP_ID,
  PROPERTY_CARD_MEMBER_ID,
  PROPERTY_INSURANCE_NUMBER,
  PROPERTY_VOUCHER,
} from 'src/constants/bundleProperties';
import { BUNDLE_NOT_AVAILABLE, PAYOR_COVERED } from 'src/constants/bundles';
import {
  BundleItemOrderContent,
  BundleItemOrderDescription,
} from 'src/constants/itemsOrder';
import browserOnly from 'src/lib/browserOnly';
import getBundleGroupFeaturedItems from 'src/lib/getBundleGroupFeaturedItems';
import getBundleProperty from 'src/lib/getBundleProperty';
import getPageTitle from 'src/lib/getPageTitle';
import isBundleAvailable from 'src/lib/isBundleAvailable';
import modelBundle from 'src/lib/models/modelBundle';
import modelBundleGroup from 'src/lib/models/modelBundleGroup';
import modelBundleGroupItem from 'src/lib/models/modelBundleGroupItem';
import { parseBundleParameters } from 'src/lib/parseBundleParameters';
import { getTranslation } from 'src/selectors/translationSelectors';
import { getVoucher } from 'src/selectors/voucherSelectors';
import IBundle from 'src/types/IBundle';
import IBundleGroup from 'src/types/IBundleGroup';
import IFormNotice from 'src/types/IFormNotice';
import IStoreState from 'src/types/IStoreState';
import BundleContent from '../BundleContent/BundleContent';
import BundleCountryRestriction from '../BundleCountryRestriction/BundleCountryRestriction';
import BundleFeatures from '../BundleFeatures/BundleFeatures';
import BundleMinCommitment from '../BundleMinCommitment/BundleMinCommitment';
import BundlePrice from '../BundlePrice/BundlePrice';
import Checklist from '../Checklist/Checklist';
import formCss from '../Form/Form.module.scss';
import HeaderNotice from '../HeaderNotice/HeaderNotice';
import InsuranceCardForm from '../InsuranceCardForm/InsuranceCardForm';
import InsuranceNumberForm from '../InsuranceNumberForm/InsuranceNumberForm';
import PaymentInterval from '../PaymentInterval/PaymentInterval';
import PaymentLogos from '../PaymentLogos/PaymentLogos';
import ProductPageTitle from '../ProductPageTitle/ProductPageTitle';
import { PromoPrice } from '../PromoPrice/PromoPrice';
import RemainingSteps from '../RemainingSteps/RemainingSteps';
import ScrollDownIcon from '../ScrollDownIcon/ScrollDownIcon';
import StepBar from '../StepBar/StepBar';
import Translate from '../Translate/Translate';
import UnitSelectionOverlay from '../UnitSelectionOverlay/UnitSelectionOverlay';
import VoucherForm from '../VoucherForm/VoucherForm';
import css from './BundleProduct.module.scss';

interface IOwnProps {
  bundle: IBundle;
  group: IBundleGroup;
  onDone: () => void;
}

interface IDispatchProps {
  addToCart: (bundle: IBundle, group: IBundleGroup) => Promise<any>;
}

interface IStateProps {
  voucherCode?: string;
  translations: { [propName: string]: string | undefined };
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

interface IState {
  showUnitSelection: boolean;
}

export class BundleProduct extends React.PureComponent<IProps, IState> {
  private voucherFormRef: HTMLDivElement;

  constructor(props: IProps) {
    super(props);
    this.state = { showUnitSelection: false };
  }

  public render() {
    const { bundle, group } = this.props;
    const groupModel = modelBundleGroup(group);

    const isPayorCovered = bundle && bundle.paymentType === PAYOR_COVERED;
    const requiresInsuranceCard = this.requiresInsuranceCard();
    const requiresInsuranceNumber = this.requiresInsuranceNumber();
    const requiresVoucherCode = this.requiresVoucherCode();
    const isPro = Boolean(groupModel.isProOnly());

    const hasForm = this.hasForm();
    const isCoachOnly = this.isBundleGroupCoachOnly();

    const bundleIsAvailable = isBundleAvailable(bundle);

    const noticeType = bundleIsAvailable
      ? bundle.paymentType
      : BUNDLE_NOT_AVAILABLE;

    const singleRowFeatureItems =
      getBundleGroupFeaturedItems(group, BundleItemOrderContent).length <= 3;

    const descriptionItems = getBundleGroupFeaturedItems(
      group,
      BundleItemOrderDescription,
    ).map(modelBundleGroupItem);

    return (
      <main role="main" className={css.wrapper}>
        <Helmet>
          <title>{this.props.translations.pageTitle}</title>
        </Helmet>
        <HeaderNotice
          bundle={bundle}
          noticeType={noticeType}
          className={css.payorCoveredInfo}
        />

        <div
          className={classnames(css.container, {
            [css.hasCoachOnly]: isCoachOnly && hasForm,
            [css.hasForm]: (hasForm || requiresInsuranceNumber) && !isCoachOnly,
            [css.isPro]: isPro,
            [css.hasInsuranceNumberForm]: requiresInsuranceNumber,
          })}
        >
          <div
            className={classnames(css.bundleDescription, {
              [css.bundleDescriptionExtraSpace]: isPayorCovered,
              [css.hasCoachOnly]: isCoachOnly,
              [css.isPro]: isPro,
            })}
          >
            <ProductPageTitle>
              <Translate t="bundleProduct.description.title" />
            </ProductPageTitle>
            <Checklist>
              {descriptionItems.map(item => (
                <Checklist.Item key={item.code}>
                  <Translate
                    t={`bundleProduct.description.list.${item.code}`}
                    altKey={`bundleProduct.description.list.${item.getTranslationKey()}`}
                    html={true}
                  />
                </Checklist.Item>
              ))}
            </Checklist>
          </div>

          <div className={css.bundleImage} aria-hidden="true">
            <BundleBoxImage group={group} />
          </div>

          <VisuallyHidden>
            <a href="#contentInformation">
              <Translate t="readMoreBundleContentInformation" />
            </a>
          </VisuallyHidden>

          {this.renderPricingInformation(
            isPayorCovered,
            requiresInsuranceNumber,
          )}
          {requiresVoucherCode && this.renderVoucherCodeForm()}
          {requiresInsuranceCard && this.renderInsuranceCardForm()}
          {requiresInsuranceNumber && this.renderInsuranceNumberForm()}
          {isPayorCovered && isCoachOnly && (
            <div className={css.shadowPlaceholder} />
          )}
          <div
            className={classnames(css.topCta, {
              [css.hasForm]: hasForm || requiresInsuranceNumber,
              [css.hasVoucherForm]: requiresVoucherCode,
              [css.hasInsuranceCardForm]: requiresInsuranceCard,
            })}
          >
            <div className={css.topCtaWrapper}>
              <Button
                type="submit"
                className={classnames(css.continueButton, formCss.ctaButton)}
                disabled={!bundleIsAvailable}
                large={true}
                secondary={true}
                onClick={this.handleContinue(bundle, group)}
                ariaLabel={this.props.translations.continueToNextStep}
              >
                <Translate t="bundleProduct.continueButton" />
              </Button>
            </div>
          </div>

          <div
            className={classnames(css.stepBar, {
              [css.hasForm]: hasForm && !isCoachOnly,
              [css.hasCoachOnly]: isCoachOnly,
            })}
          >
            <StepBar alwaysShowNumbers={isCoachOnly || !hasForm} />
          </div>
          <RemainingSteps
            className={classnames(css.remainingSteps, css.remainingStepsText)}
          />

          <div
            aria-hidden="true"
            className={classnames(css.bundleContent, {
              [css.bundleContentSmall]: singleRowFeatureItems,
              [css.isPro]: isPro,
              [css.hasCoachOnly]: isCoachOnly,
            })}
          >
            <div className={css.bundleContentBackground}>
              <BundleContent bundleGroup={group} />
            </div>
          </div>
          <div
            id="contentInformation"
            className={classnames(
              css.bundleFeatures,
              singleRowFeatureItems ? css.bundleFeaturesSmall : '',
            )}
          >
            <h2 className={css.bundleFeaturesTitle}>
              <Translate t="bundleProduct.features.title" />
            </h2>
            <BundleFeatures bundleGroup={group} />
          </div>
          <div className={css.leftBg} />
          <div className={css.rightBg} />
        </div>
        <div className={css.bottomCta}>
          <Button
            className={classnames(css.continueButton, formCss.ctaButton)}
            disabled={!bundleIsAvailable}
            large={true}
            secondary={true}
            onClick={this.handleContinue(bundle, group, true)}
          >
            <Translate t="bundleProduct.continueButton" />
          </Button>
          <StepBar />
          <RemainingSteps className={css.remainingStepsText} />
        </div>
        <UnitSelectionOverlay
          bundle={bundle}
          group={group}
          show={this.state.showUnitSelection}
          onClose={this.hideUnitSelection}
          onSelect={this.handleUnitSelection}
        />
        <ScrollDownIcon />
      </main>
    );
  }

  public hideUnitSelection = () => this.setState({ showUnitSelection: false });

  public renderPricingInformation(
    isPayorCovered: boolean,
    requiresInsuranceNumber: boolean,
  ) {
    const { bundle, group } = this.props;
    const params = parseBundleParameters(bundle);

    if (isPayorCovered && requiresInsuranceNumber) {
      return (
        <>
          <div className={css.priceBox} />
          <div className={css.priceInfo} />
          <div className={css.paymentLogos} />
        </>
      );
    }

    return (
      !isPayorCovered && (
        <>
          <div className={css.priceBox}>
            {params.showPromotion ? (
              <div className={css.promoPrice} role="text">
                <PromoPrice params={params} group={group} />
              </div>
            ) : (
              <div className={css.price} role="text">
                <BundlePrice group={group} />
              </div>
            )}
            <div className={classnames(css.priceBoxInfo, css.desktopOnly)}>
              <PaymentInterval
                interval={bundle.interval}
                intervalCount={bundle.intervalCount}
              />
            </div>
          </div>
          <div className={css.priceInfo}>
            <span className={css.mobileOnly}>
              <PaymentInterval
                interval={bundle.interval}
                intervalCount={bundle.intervalCount}
              />{' '}
              &bull;{' '}
            </span>
            {bundle.country && (
              <BundleCountryRestriction country={bundle.country} />
            )}
            <br aria-hidden={true} />
            <Translate t="bundleProduct.freeShipping" />
            <br aria-hidden={true} />
            <BundleMinCommitment
              interval={bundle.interval}
              minRecurringCount={
                bundle.minRecurringCount ?? bundle.intervalCount
              }
            />
          </div>
          <div className={css.paymentLogos}>
            <PaymentLogos />
          </div>
        </>
      )
    );
  }

  public renderVoucherCodeForm() {
    const { bundle, group } = this.props;
    const isCoachOnly = this.isBundleGroupCoachOnly();
    return (
      <div
        className={classnames(css.voucherForm, {
          [css.hasCoachOnly]: isCoachOnly,
        })}
        ref={this.setVoucherFormRef}
      >
        <VoucherForm
          bundle={bundle}
          buttonClassName={classnames(css.continueButton, formCss.ctaButton)}
          buttonText="bundleProduct.continueButton"
          onSubmit={this.handleContinue(bundle, group)}
        />
      </div>
    );
  }

  private requiresVoucherCode() {
    const { bundle, group } = this.props;
    const voucherProperty = getBundleProperty(bundle, PROPERTY_VOUCHER);
    return (
      voucherProperty !== undefined ||
      group.items.filter(item => item.code.includes('voucher_based')).length > 0
    );
  }

  private isBundleGroupCoachOnly() {
    const { group } = this.props;
    const groupModel = modelBundleGroup(group);
    return groupModel.isCoachOnly();
  }

  private requiresInsuranceCard() {
    const { bundle } = this.props;
    const payorGroupId = getBundleProperty(bundle, PROPERTY_CARD_GROUP_ID);
    const payorMemberId = getBundleProperty(bundle, PROPERTY_CARD_MEMBER_ID);
    return payorGroupId !== undefined && payorMemberId !== undefined;
  }

  private requiresInsuranceNumber() {
    const { bundle } = this.props;

    const payorInsuranceNumber = getBundleProperty(
      bundle,
      PROPERTY_INSURANCE_NUMBER,
    );
    if (payorInsuranceNumber !== undefined) {
      return true;
    }

    const payorMemberId = getBundleProperty(bundle, PROPERTY_CARD_MEMBER_ID);
    const payorGroupId = getBundleProperty(bundle, PROPERTY_CARD_GROUP_ID);
    if (payorMemberId !== undefined && payorGroupId === undefined) {
      return true;
    }

    return false;
  }

  private hasForm() {
    return this.requiresVoucherCode() || this.requiresInsuranceCard();
  }

  private readonly handleContinue =
    (bundle: IBundle, group: IBundleGroup, secondary = false) =>
    async (event?: React.SyntheticEvent): Promise<IFormNotice[]> => {
      /* istanbul ignore else */
      if (event) {
        event.preventDefault();
      }

      const bundleModel = modelBundle(bundle);

      const bundleIsAvailable = isBundleAvailable(bundle);
      if (!bundleIsAvailable) {
        return Promise.resolve([]);
      }

      if (
        secondary === true &&
        (this.hasForm() || this.requiresInsuranceNumber())
      ) {
        return browserOnly(() => {
          /* istanbul ignore else */
          if (this.voucherFormRef && event) {
            const top =
              this.voucherFormRef.getBoundingClientRect().top +
              (window.scrollY || (event.target as any).scrollTop) -
              ((event.target as any).clientTop || 0);
            window.scrollTo(0, top);
          }
          return [];
        });
      }

      if (bundleModel.hasUnitSelection(group)) {
        this.setState({ showUnitSelection: true });
        return [];
      }

      this.addBundleToCart(bundle, group);

      return [];
    };

  private readonly handleUnitSelection = (selectedGroup: IBundleGroup) => {
    this.hideUnitSelection();
    /* istanbul ignore else */
    if (this.props.bundle && selectedGroup) {
      this.addBundleToCart(this.props.bundle, selectedGroup);
    }
  };

  private readonly addBundleToCart = (bundle: IBundle, group: IBundleGroup) => {
    const { addToCart, onDone } = this.props;
    addToCart(bundle, group).then(onDone);
  };

  private readonly setVoucherFormRef = (ref: HTMLDivElement) => {
    this.voucherFormRef = ref;
  };

  private renderInsuranceCardForm() {
    const { bundle, group } = this.props;
    const isCoachOnly = this.isBundleGroupCoachOnly();
    return (
      <div
        className={classnames(css.voucherForm, {
          [css.hasCoachOnly]: isCoachOnly,
        })}
        ref={this.setVoucherFormRef}
      >
        <InsuranceCardForm
          group={group}
          bundle={bundle}
          buttonClassName={classnames(css.continueButton, formCss.ctaButton)}
          onSubmit={this.handleContinue(bundle, group)}
        />
      </div>
    );
  }

  private renderInsuranceNumberForm() {
    const { bundle, group } = this.props;
    return (
      <div className={css.insuranceNumberForm} ref={this.setVoucherFormRef}>
        <InsuranceNumberForm
          className={css.voucherFormWrapper}
          bundle={bundle}
          buttonClassName={classnames(css.continueButton, formCss.ctaButton)}
          buttonText="bundleProduct.continueButton"
          onSubmit={this.handleContinue(bundle, group)}
        />
      </div>
    );
  }
}

export function mapStateToProps(
  state: IStoreState,
  ownProps: IOwnProps,
): IStateProps {
  const voucher = getVoucher(state);

  const pageTitle = getPageTitle(state, {
    bundle: ownProps.bundle,
    bundleGroup: ownProps.group,
    pageName: 'product',
  });

  const translations = {
    pageTitle,
    continueToNextStep: getTranslation('continueToNextStep')(state),
  };

  return {
    translations,
    voucherCode: voucher.voucherCode,
  };
}

export function mapDispatchToProps(dispatch: any): IDispatchProps {
  return {
    addToCart: (bundle: IBundle, group: IBundleGroup) =>
      dispatch(addBundleToCartAction(bundle, group)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BundleProduct);
