import * as React from 'react';
import { connect } from 'react-redux';
import {
  clearCartAction,
  getBundleConfigurationsSuccessAction,
} from 'src/actions/bundleActions';
import Loader from 'src/components/Loader/Loader';
import { countryDefaultBundleCode } from 'src/config/nonStandardBundleOverride/bundleOverrides';
import { PARAM_BUNDLE_SLUG } from 'src/constants/bundleParameters';
import { clearDataFlow } from 'src/flows/clearDataFlow';
import browserOnly from 'src/lib/browserOnly';
import getBundleParameter from 'src/lib/getBundleParameter';
import loadBundle from 'src/lib/loadBundle';
import { getBundleDynamic } from 'src/selectors/bundleSelectors';
import IBundle from 'src/types/IBundle';
import IStoreState from 'src/types/IStoreState';

interface IDispatchProps {
  clearCart: () => void;
  clearData: () => void;
  setBundleConfig: (bundle: IBundle) => void;
}

export interface IStateProps {
  bundle?: IBundle;
}

export interface IProps extends IDispatchProps, IStateProps {
  default?: boolean;
  group?: string;
  path: string;
  language?: string;
  locale?: string;
  payor?: string;
  children?: any;
}

interface IState {
  loadingPayor: boolean;
  validPayor?: boolean;
}

export class RouteConfig extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loadingPayor: Boolean(props.payor),
      validPayor: undefined,
    };
  }

  public async componentDidMount() {
    await this.updateState();
  }

  public async componentDidUpdate(prevProps: IProps) {
    if (
      this.props.group !== prevProps.group ||
      this.props.locale !== prevProps.locale ||
      this.props.payor !== prevProps.payor ||
      this.props.language !== prevProps.language
    ) {
      await this.updateState();
    }
  }

  public render() {
    const documentIsDefined = browserOnly(() => true, false);
    const { loadingPayor } = this.state;

    if (documentIsDefined && loadingPayor) {
      return <Loader />;
    } else {
      return this.props.children || null;
    }
  }

  private readonly getLocale = () => (this.props.locale ?? '').split('-');

  private async updateState() {
    const { payor, bundle, clearData, clearCart } = this.props;

    const [, country] = this.getLocale();

    if (!bundle) {
      await this.loadBundleConfig(payor, country);
    } else {
      const pathMatchesCurrentBundle =
        getBundleParameter(PARAM_BUNDLE_SLUG, bundle) === payor ||
        countryDefaultBundleCode[country] === bundle.code;

      if (!pathMatchesCurrentBundle) {
        clearData();
        clearCart();
      } else {
        this.setState({ loadingPayor: false });
      }
    }
  }

  private readonly loadBundleConfig = async (
    slug?: string,
    country?: string,
  ) => {
    try {
      if (slug || (country && countryDefaultBundleCode[country])) {
        const config = await loadBundle({ slug, country });
        this.props.setBundleConfig(config);
      }
      this.setState({ loadingPayor: false, validPayor: true });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      this.setState({ loadingPayor: false, validPayor: false });
    }
  };
}

export function mapStateToProps(state: IStoreState): IStateProps {
  const bundle = getBundleDynamic(state);
  return {
    bundle,
  };
}

export function mapDispatchToProps(dispatch: any): IDispatchProps {
  return {
    clearCart: () => dispatch(clearCartAction()),
    clearData: () => dispatch(clearDataFlow()),
    setBundleConfig: bundle =>
      dispatch(getBundleConfigurationsSuccessAction([bundle])),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteConfig);
