import classnames from 'classnames';
import * as React from 'react';
import IStep from 'src/types/IStep';
import checkmarkImg from '../../icons/checkmark.svg';
import Translate from '../Translate/Translate';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import css from './StepBar.module.scss';

export interface IProps {
  alwaysShowNumbers?: boolean;
  className?: string;
  isActive: boolean;
  isDone: boolean;
  step: IStep;
  totalStepCount: number;
}

function svgRef(
  part: string,
  isFirstStep: boolean,
  isLastStep: boolean,
): string {
  if (isFirstStep) {
    return `#step-${part}-first`;
  } else if (isLastStep) {
    return `#step-${part}-last`;
  }
  return `#step-${part}`;
}

export class StepBarStep extends React.PureComponent<IProps> {
  public render() {
    const {
      alwaysShowNumbers,
      className,
      isActive,
      isDone,
      step,
      totalStepCount,
    } = this.props;

    const isFirstStep = step.step === 1;
    const isLastStep = step.step === totalStepCount;
    return (
      <li
        className={classnames(css.step, className, {
          [css.alwaysShowNumbers]: alwaysShowNumbers,
          [css.done]: isDone,
          [css.active]: isActive,
          [css.success]: step.success,
        })}
      >
        <span className={css.stepBorder} aria-hidden={true}>
          <span
            className={classnames(css.svg, css.svgLeft, {
              [css.svgLeftFirst]: isFirstStep,
              [css.svgLeftLast]: isLastStep,
            })}
          >
            <svg>
              <use
                className={css.stepBorderLeft}
                xlinkHref={svgRef('left', isFirstStep, isLastStep)}
              />
            </svg>
          </span>
          <span
            className={classnames(css.svg, css.svgMiddle, {
              [css.svgMiddleFirst]: isFirstStep,
              [css.svgMiddleLast]: isLastStep,
            })}
          >
            <svg>
              <use
                className={css.stepBorderMiddle}
                xlinkHref={svgRef('middle', isFirstStep, isLastStep)}
              />
            </svg>
          </span>
          <span
            className={classnames(css.svg, css.svgRight, {
              [css.svgRightFirst]: isFirstStep,
              [css.svgRightLast]: isLastStep,
            })}
          >
            <svg>
              <use
                className={css.stepBorderRight}
                xlinkHref={svgRef('right', isFirstStep, isLastStep)}
              />
            </svg>
          </span>
        </span>
        <span className={css.stepLink}>
          {this.renderLinkContent(step, { isActive, isDone })}
        </span>
      </li>
    );
  }

  private renderLinkContent(
    step: IStep,
    { isActive, isDone }: { isActive: boolean; isDone: boolean },
  ) {
    return [
      <span key="number" className={css.stepNumber}>
        {step.success ? (
          <>
            <img src={checkmarkImg} alt="Review order" aria-hidden="true" />
            <span className={css.successStepNumber}>{step.uiStep}</span>
          </>
        ) : (
          step.uiStep
        )}
      </span>,
      <span key="name" className={css.stepName}>
        <Translate t={step.name} />
        {step.success && (
          <svg
            width="18px"
            height="13px"
            viewBox="0 0 18 13"
            version="1.1"
            aria-hidden="true"
          >
            <g
              stroke="none"
              strokeWidth="1"
              fillRule="evenodd"
              className={css.checkmarkColor}
            >
              <polygon points="1.43181818 6.20895522 0 7.56716418 5.72727273 13 18 1.35820896 16.5681818 0 5.72727273 10.2835821" />
            </g>
          </svg>
        )}
      </span>,
      <VisuallyHidden key="aria">
        {isActive && <Translate t="stepBar.activeStep.screenReader" />}
        {isDone && !isActive && (
          <Translate t="stepBar.completedStep.screenReader" />
        )}
        {!isDone && !isActive && (
          <Translate t="stepBar.notCompletedStep.screenReader" />
        )}
      </VisuallyHidden>,
    ];
  }
}

export default StepBarStep;
