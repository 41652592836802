import { LocationContext } from '@gatsbyjs/reach-router';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  insuranceNumberInputAction,
  insuranceNumberValidateAction,
} from 'src/actions/insuranceNumberActions';
import Button from 'src/components/Button/Button';
import {
  INSURANCE_NUMBER_INPUT_FIELD_LABEL,
  INSURANCE_NUMBER_INPUT_FIELD_PLACEHOLDER,
} from 'src/constants/customTextKeys';
import focusErrorFields from 'src/lib/focusErrorFields';
import getLocaleFromPathname from 'src/lib/getLocaleFromPathname';
import getPayorForPathname from 'src/lib/getPayorForPathname';
import modelBundle from 'src/lib/models/modelBundle';
import IBundle from 'src/types/IBundle';
import IFormNotice from 'src/types/IFormNotice';
import IStoreState from 'src/types/IStoreState';
import FormField from '../FormField/FormField';
import TextInput from '../TextInput/TextInput';
import Translate from '../Translate/Translate';
import withLocation from '../withLocation/withLocation';
import css from './InsuranceNumberForm.module.scss';

const FORM_NAME = 'INSURANCE_NUMBER_FORM';
const UKV_AND_VBK_PLACEHOLDER_NUMBER = 'KK-1234-1234';

interface IStateProps {
  errors: IFormNotice[];
  insuranceNumber?: string;
}

interface IDispatchProps {
  validateInsuranceNumber: (
    bundle: IBundle,
    isUkvOrVkbPayor: boolean,
    insuranceNumber: string,
  ) => Promise<boolean>;
  insuranceNumberInput: (target: HTMLInputElement) => void;
}

export interface IProps extends IStateProps, IDispatchProps, LocationContext {
  bundle: IBundle;
  buttonClassName?: string;
  buttonText: string;
  className?: string;
  onSubmit: () => Promise<IFormNotice[]>;
}

export function InsuranceNumberForm({
  bundle,
  buttonClassName,
  buttonText,
  errors,
  onSubmit,
  validateInsuranceNumber,
  insuranceNumber,
  insuranceNumberInput,
  location,
}: Readonly<IProps>) {
  const pathname = location.pathname;

  const [language] = React.useMemo(
    () => getLocaleFromPathname(pathname),
    [pathname],
  );
  const isUkvOrVkbPayor = React.useMemo(() => {
    const payorSlug = getPayorForPathname(pathname) || '';
    return payorSlug === 'ukv' || payorSlug === 'vkb';
  }, [pathname]);

  const bundleModel = modelBundle(bundle);
  const customLabel = bundleModel.getCustomStringValue(
    INSURANCE_NUMBER_INPUT_FIELD_LABEL,
    language,
  );
  const customPlaceholder = bundleModel.getCustomStringValue(
    INSURANCE_NUMBER_INPUT_FIELD_PLACEHOLDER,
    language,
  );

  const handleSubmit = React.useCallback(
    async ({ nativeEvent }: React.SyntheticEvent) => {
      nativeEvent.preventDefault();
      const valid = await validateInsuranceNumber(
        bundle,
        isUkvOrVkbPayor,
        insuranceNumber || '',
      );
      if (valid) {
        await onSubmit();
      } else {
        focusErrorFields(nativeEvent.target as HTMLFormElement, [
          { field: 'insuranceNumber', message: '' },
        ]);
      }
    },
    [insuranceNumber],
  );

  const defaultPlaceholder = isUkvOrVkbPayor
    ? UKV_AND_VBK_PLACEHOLDER_NUMBER
    : '';

  return (
    <form className={css.form} onSubmit={handleSubmit} noValidate={true}>
      <FormField
        className={css.formField}
        errors={errors}
        formName={FORM_NAME}
        labelBundle={customLabel}
        label="insuranceNumberForm.label"
        name="insuranceNumber"
        warnings={[]}
      >
        <TextInput
          formName={FORM_NAME}
          name="insuranceNumber"
          placeholder={customPlaceholder || defaultPlaceholder}
          onChange={insuranceNumberInput}
          value={insuranceNumber || ''}
          error={errors.length > 0}
        />
      </FormField>
      <div className={css.actions}>
        <Button
          className={buttonClassName}
          large={true}
          secondary={true}
          type="submit"
        >
          <Translate t={buttonText} />
        </Button>
      </div>
    </form>
  );
}

export function mapStateToProps(state: IStoreState): IStateProps {
  return {
    errors: state.insuranceNumber.errors,
    insuranceNumber: state.insuranceNumber.insuranceNumber,
  };
}

export function mapDispatchToProps(dispatch: any): IDispatchProps {
  return {
    insuranceNumberInput: (target: HTMLInputElement) => {
      dispatch(insuranceNumberInputAction(target.value));
    },
    validateInsuranceNumber: (
      bundle: IBundle,
      isUkvOrVkbPayor: boolean,
      insuranceNumber: string,
    ) =>
      dispatch(
        insuranceNumberValidateAction(bundle, isUkvOrVkbPayor, insuranceNumber),
      ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocation(InsuranceNumberForm));
