import * as React from 'react';
import { connect } from 'react-redux';
import { getNotificationIds } from 'src/selectors/notificationSelectors';
import IStoreState from 'src/types/IStoreState';
import Notification from '../Notification/Notification';
import css from './NotificationList.module.scss';

export interface IProps {
  spacingBottom?: number;
  ids: number[];
}

export function NotificationList({
  ids,
  spacingBottom,
}: Readonly<IProps>): React.ReactElement {
  const containerStyle = spacingBottom ? { bottom: `${spacingBottom}px` } : {};

  return (
    <div className={css.container} style={containerStyle}>
      {ids.length > 0 ? (
        <ul className={css.list}>
          {ids.map(id => (
            <Notification key={id} id={id} />
          ))}
        </ul>
      ) : null}
    </div>
  );
}

NotificationList.defaultProps = {
  spacingBottom: 0,
};

export function mapStateToProps(state: IStoreState): IProps {
  return {
    ids: getNotificationIds(state),
    spacingBottom: state.flow.appSpacing.bottom ?? 0,
  };
}

export default connect(mapStateToProps)(NotificationList);
