/* istanbul ignore file */

module.exports = {
  "account.account.backButton": "",
  "account.account.continueButton": "",
  "account.account.loggedInAs": "Tu es connecté(e) avec l’adresse :email",
  "account.account.logoutButton": "Déconnexion",
  "account.account.logoutText": "Ce n’est pas toi ? Tu peux te déconnecter et te reconnecter avec un autre compte ou créer un nouveau compte.",
  "account.emailConfirmForm.backButton": "Retour",
  "account.emailConfirmForm.changeEmail": "Modifier l’adresse e-mail",
  "account.emailConfirmForm.checkYourEmail": "Vérifie tes mails",
  "account.emailConfirmForm.codeLabel": "Merci de saisir ton code de vérification",
  "account.emailConfirmForm.codeName": "Code de vérification",
  "account.emailConfirmForm.codeWrong": "Code erroné ou expiré",
  "account.emailConfirmForm.continueButton": "Continuer",
  "account.emailConfirmForm.emailLabel": "Adresse e-mail",
  "account.emailConfirmForm.receivedNoCode": "Tu n’as pas reçu le code ?",
  "account.emailConfirmForm.sendCodeAgain": "Renvoyer l'e-mail",
  "account.emailConfirmForm.sendCodeSuccess": "Nous avons envoyé ton code de vérification à l’adresse e-mail :email.",
  "account.emailConfirmForm.sentCode": "Nous t’avons envoyé un code",
  "account.emailForm.backButton": "Retour",
  "account.emailForm.continueButton": "Continuer",
  "account.emailForm.emailLabel": "Adresse e-mail",
  "account.emailForm.emailName": "Email",
  "account.emailForm.emailPlaceholder": "email@exemple.com",
  "account.emailForm.intro": "",
  "account.emailForm.proVoucherIntro": "Si tu <strong>disposes déjà d’un compte mySugr</strong>, saisis la même adresse e-mail pour t’assurer que ton bon est bien affecté à ton compte existant. Tu peux trouver l’adresse e-mail dans ton profil de l’application mySugr.",
  "account.loginForm.backButton": "Retour",
  "account.loginForm.continueButton": "",
  "account.loginForm.emailLabel": "Ton adresse e-mail",
  "account.loginForm.emailName": "Email",
  "account.loginForm.forgotPassword": "Mot de passe oublié ?",
  "account.loginForm.passwordLabel": "Entre ton mot de passe",
  "account.loginForm.passwordName": "Mot de passe",
  "account.loginForm.passwordResetRequestedText": "Tu as demandé la réinitialisation d’un mot de passe. Vérifie tes e-mails et suis les instructions que nous t’avons envoyées.",
  "account.loginForm.redeemButton": "Valider",
  "account.loginTitle": "",
  "account.loginTitleRedeem": "Connecte-toi pour faire valider ton bon",
  "account.registerForm.backButton": "Retour",
  "account.registerForm.consentsName": "",
  "account.registerForm.continueButton": "Continuer",
  "account.registerForm.countryLabel": "Sélectionne ton pays de résidence",
  "account.registerForm.emailLabel": "Ton adresse e-mail",
  "account.registerForm.emailName": "Email",
  "account.registerForm.passwordConfirmLabel": "Saisis à nouveau le mot de passe",
  "account.registerForm.passwordConfirmName": "Confirmation du mot de passe",
  "account.registerForm.passwordHint": "",
  "account.registerForm.passwordHintLength": "Doit contenir au moins :minLength caractères.",
  "account.registerForm.passwordLabel": "Mot de passe",
  "account.registerForm.passwordName": "Mot de passe",
  "account.registerForm.redeemButton": "Valider",
  "account.registerForm.verifiedEmail": "Vérifier",
  "account.registerTitle": "",
  "account.registerTitleRedeem": "Crée un compte pour faire valider ton bon",
  "account.resetPassword.checkYourEmail": "Tu as demandé la réinitialisation d’un mot de passe.",
  "account.resetPassword.passwordConfirmLabel": "Confirmer nouveau mot de passe",
  "account.resetPassword.passwordConfirmName": "Confirmer nouveau mot de passe",
  "account.resetPassword.passwordHint": "Doit contenir au moins 6 caractères.",
  "account.resetPassword.passwordHintLength": "Doit contenir au moins :minLength caractères.",
  "account.resetPassword.passwordLabel": "Saisir un nouveau mot de passe",
  "account.resetPassword.passwordName": "Saisir un nouveau mot de passe",
  "account.resetPassword.sentCode": "Nous avons envoyé un code à ton adresse e-mail",
  "account.resetPasswordCodeVerification.backButton": "Retour",
  "account.resetPasswordCodeVerification.continueButton": "Vérifier le code",
  "account.resetPasswordForm.backButton": "Retour",
  "account.resetPasswordForm.saveButton": "Enregister le mot de passe",
  "account.resetPasswordFormText": "Merci de choisir un nouveau mot de passe pour ton compte mySugr.",
  "account.resetPasswordTitle": "Réinitialisation du mot de passe",
  "account.stepName": "",
  "account.title": "Inscription / Connexion",
  "AccuChekRegistration_description": "",
  "AccuChekRegistration_or": "",
  "activateProTitle": "",
  "appLegalNotice": "",
  "appProvider.title": "Fournisseur d’applications",
  "birthdayLabel": "",
  "bundle.item.alt_text.strips_subscription_guide.description": "",
  "bundle.item.alt_text.strips_subscription_guide.name.2": "",
  "bundle.item.coach.description": "",
  "bundle.item.coach.name": "",
  "bundle.item.cuff.description": "",
  "bundle.item.cuff.name": "",
  "bundle.item.dkv_coach_1.name": "",
  "bundle.item.fastclix_lancets_2.description": "",
  "bundle.item.fastclix_lancets_2.name": "",
  "bundle.item.fastclix_lancets.description": "",
  "bundle.item.fastclix_lancets.name": "",
  "bundle.item.fastclix_lancing_kit.description": "",
  "bundle.item.fastclix_lancing_kit.name": "",
  "bundle.item.guide_3.description": "",
  "bundle.item.guide_3.name": "",
  "bundle.item.guide_4.description": "",
  "bundle.item.guide_4.name": "",
  "bundle.item.guide_me.description": "",
  "bundle.item.guide_me.name": "",
  "bundle.item.guide_strips_1.description.country_de": "",
  "bundle.item.guide_strips.description": "",
  "bundle.item.guide_strips.name": "",
  "bundle.item.guide.description": "",
  "bundle.item.guide.name": "",
  "bundle.item.mysugr_coach_voucher_based_1.description": "",
  "bundle.item.mysugr_coach_voucher_based_1.name": "",
  "bundle.item.mysugr_strips_subscription_guide_2.description": "",
  "bundle.item.mysugr_strips_subscription_guide_2.name": "",
  "bundle.item.mysugr_strips_subscription_guide_2.name.2": "",
  "bundle.item.pro.description": "",
  "bundle.item.pro.name": "",
  "bundle.item.scale.description": "",
  "bundle.item.scale.name": "",
  "bundle.item.schwenninger_coach_1.description": "",
  "bundle.item.schwenninger_coach_1.name": "",
  "bundle.item.servidigest_coach_1.description": "",
  "bundle.item.servidigest_coach_1.name": "",
  "bundle.item.softclix_lancets.name": "",
  "bundle.item.softclix_lancing_kit.description": "",
  "bundle.item.softclix_lancing_kit.name": "",
  "bundle.item.strips_subscription_guide.description": "",
  "bundle.item.strips_subscription_guide.name": "",
  "bundle.item.strips_subscription_guide.name.2": "",
  "bundleDelivery.text": "",
  "bundleGroupItemName.activisionblizzard_coach_1": "",
  "bundleGroupItemName.fastclix_lancets_1": "",
  "bundleGroupItemName.fastclix_lancets_2": "",
  "bundleGroupItemName.fastclix_lancing_kit_1": "",
  "bundleGroupItemName.guide_1": "",
  "bundleGroupItemName.guide_3": "",
  "bundleGroupItemName.guide_4": "",
  "bundleGroupItemName.guide_me_1": "",
  "bundleGroupItemName.guide_strips_1": "",
  "bundleGroupItemName.mysugr_coach_1": "",
  "bundleGroupItemName.mysugr_coach_2": "",
  "bundleGroupItemName.mysugr_coach_3": "",
  "bundleGroupItemName.mysugr_coach_5": "",
  "bundleGroupItemName.mysugr_coach_6": "",
  "bundleGroupItemName.mysugr_coach_6_copy": "",
  "bundleGroupItemName.mysugr_pro_1": "",
  "bundleGroupItemName.mysugr_pro_2": "",
  "bundleGroupItemName.mysugr_pro_3": "",
  "bundleGroupItemName.mysugr_pro_4": "",
  "bundleGroupItemName.mysugr_strips_subscription_guide_1": "",
  "bundleGroupItemName.mysugr_strips_subscription_guide_2": "",
  "bundleGroupItemName.mysugr_strips_subscription_guide_3": "",
  "bundleGroupItemName.schwenninger_coach_1": "",
  "bundleGroupItemName.servidigest_coach_1": "",
  "bundleGroupItemName.sigroup_coach_1": "",
  "bundleGroupItemName.us_spanish_coach_1": "",
  "bundleProduct.content.items.coach": "",
  "bundleProduct.content.items.cuff": "",
  "bundleProduct.content.items.fastclix_lancets": "",
  "bundleProduct.content.items.fastclix_lancing_kit": "",
  "bundleProduct.content.items.guide": "",
  "bundleProduct.content.items.guide_me": "",
  "bundleProduct.content.items.mysugr_coach_voucher_based_1": "",
  "bundleProduct.content.items.pro": "",
  "bundleProduct.content.items.scale": "",
  "bundleProduct.content.items.servidigest_coach_1": "",
  "bundleProduct.content.items.softclix_lancets": "",
  "bundleProduct.content.items.softclix_lancing_kit": "",
  "bundleProduct.content.items.strips_subscription_guide": "",
  "bundleProduct.content.title": "",
  "bundleProduct.content.title.roche-benefits-us": "",
  "bundleProduct.content.title.roche-benefits-us-coach": "",
  "bundleProduct.continueButton": "",
  "bundleProduct.countryRestriction.de": "",
  "bundleProduct.countryRestriction.sg": "",
  "bundleProduct.countryRestriction.us": "",
  "bundleProduct.description.list.alt_text.strips_subscription_guide.2": "",
  "bundleProduct.description.list.aok_nordwest_coach_1": "",
  "bundleProduct.description.list.coach": "",
  "bundleProduct.description.list.dkv_coach_1": "",
  "bundleProduct.description.list.entry_search": "",
  "bundleProduct.description.list.guide": "",
  "bundleProduct.description.list.guide_me": "",
  "bundleProduct.description.list.meal_photos": "",
  "bundleProduct.description.list.pdf_report": "",
  "bundleProduct.description.list.pro": "",
  "bundleProduct.description.list.pro.dkv": "",
  "bundleProduct.description.list.pro.roche-benefits-us": "",
  "bundleProduct.description.list.pro.roche-benefits-us-coach": "",
  "bundleProduct.description.list.reminders": "",
  "bundleProduct.description.list.scale_1": "",
  "bundleProduct.description.list.schwenninger_coach_1": "",
  "bundleProduct.description.list.strips_subscription_guide": "",
  "bundleProduct.description.list.strips_subscription_guide.2": "",
  "bundleProduct.description.title": "",
  "bundleProduct.description.title.dkv": "",
  "bundleProduct.description.title.dvg": "",
  "bundleProduct.features.items.immediate.coach": "",
  "bundleProduct.features.items.immediate.dkv_coach_1": "",
  "bundleProduct.features.items.immediate.mysugr_coach_voucher_based_1": "",
  "bundleProduct.features.items.immediate.mysugr_pro_1.dkv": "",
  "bundleProduct.features.items.immediate.pro": "",
  "bundleProduct.features.items.immediate.schwenninger_coach_1": "",
  "bundleProduct.features.items.immediate.servidigest_coach_1": "",
  "bundleProduct.features.items.product.cuff": "",
  "bundleProduct.features.items.product.fastclix_lancets": "",
  "bundleProduct.features.items.product.fastclix_lancets_2": "",
  "bundleProduct.features.items.product.fastclix_lancing_kit": "",
  "bundleProduct.features.items.product.guide": "",
  "bundleProduct.features.items.product.guide_me": "",
  "bundleProduct.features.items.product.guide_strips": "",
  "bundleProduct.features.items.product.scale": "",
  "bundleProduct.features.items.product.softclix_lancets": "",
  "bundleProduct.features.items.product.softclix_lancing_kit": "",
  "bundleProduct.features.items.subscription.alt_text.strips_subscription_guide.2": "",
  "bundleProduct.features.items.subscription.aok_nordwest_coach_1": "",
  "bundleProduct.features.items.subscription.coach": "",
  "bundleProduct.features.items.subscription.dkv_coach_1": "",
  "bundleProduct.features.items.subscription.mysugr_coach_voucher_based_1": "",
  "bundleProduct.features.items.subscription.schwenninger_coach_1": "",
  "bundleProduct.features.items.subscription.servidigest_coach_1": "",
  "bundleProduct.features.items.subscription.strips_subscription_guide": "",
  "bundleProduct.features.items.subscription.strips_subscription_guide.2": "",
  "bundleProduct.features.title": "",
  "bundleProduct.features.title.dkv": "",
  "bundleProduct.features.title.roche-benefits-us": "",
  "bundleProduct.features.title.roche-benefits-us-coach": "",
  "bundleProduct.features.titles.immediate": "",
  "bundleProduct.features.titles.product": "",
  "bundleProduct.features.titles.subscription": "",
  "bundleProduct.freeShipping": "",
  "bundleProduct.groupNotFound": "",
  "bundleProduct.minCommitment.info.DAY.1": "",
  "bundleProduct.minCommitment.info.DAY.2": "",
  "bundleProduct.minCommitment.info.MONTH.1": "",
  "bundleProduct.minCommitment.info.MONTH.2": "",
  "bundleProduct.minCommitment.info.WEEK.1": "",
  "bundleProduct.minCommitment.info.YEAR.1": "",
  "bundleProduct.minCommitment.info.YEAR.2": "",
  "bundleProduct.noBundle.button": "",
  "bundleProduct.noBundle.text": "",
  "bundleProduct.paymentInterval.DAY.1.long": "",
  "bundleProduct.paymentInterval.DAY.1.short": "",
  "bundleProduct.paymentInterval.DAY.2+": "",
  "bundleProduct.paymentInterval.MONTH.1.long": "",
  "bundleProduct.paymentInterval.MONTH.1.short": "",
  "bundleProduct.paymentInterval.MONTH.2+": "",
  "bundleProduct.paymentInterval.WEEK.1.long": "",
  "bundleProduct.paymentInterval.WEEK.1.short": "",
  "bundleProduct.paymentInterval.YEAR.1.long": "",
  "bundleProduct.paymentInterval.YEAR.1.short": "",
  "bundleProduct.paymentInterval.YEAR.2+": "",
  "bundleProduct.price": "",
  "cancelAnytime": "",
  "changeCardDetails": "",
  "coachBox.text": "",
  "coachBox.title": "",
  "coachSelection.message.en": "",
  "coachSelection.message.es": "",
  "coachSelection.select.button.en": "",
  "coachSelection.select.button.es": "",
  "coachSelection.subtitle": "",
  "coachSelection.title": "",
  "confirmEmailCodeInputLabel": "Saisis ici le code que tu as reçu par e-mail",
  "contact.stepName": "",
  "contact.title": "",
  "continueToNextStep": "",
  "cookieBanner.allowAllButton": "",
  "cookieBanner.cookieSettingsButton": "",
  "cookieBanner.noticeDescription": "",
  "cookieBanner.rejectAllButton": "",
  "cookieBanner.title": "",
  "cookieBar.buttonText": "",
  "cookieBar.text": "",
  "cookieBar.text.global": "",
  "cookieCategory.functionalDescription": "",
  "cookieCategory.functionalTitle": "",
  "cookieCategory.performanceDescription": "",
  "cookieCategory.performanceTitle": "",
  "cookieCategory.socialMediaDescription": "",
  "cookieCategory.socialMediaTitle": "",
  "cookieCategory.strictlyNecessaryDescription": "",
  "cookieCategory.strictlyNecessaryTitle": "",
  "cookieCategory.targetingDescription": "",
  "cookieCategory.targetingTitle": "",
  "cookieList.category": "",
  "cookieList.description": "",
  "cookieList.duration": "",
  "cookieList.host": "",
  "cookieList.name": "",
  "cookieList.type": "",
  "cookiePreferenceCenter.allowAllButton": "",
  "cookiePreferenceCenter.alwaysActiveTitle": "",
  "cookiePreferenceCenter.categoriesTitle": "",
  "cookiePreferenceCenter.confirmButton": "",
  "cookiePreferenceCenter.cookieListLink": "",
  "cookiePreferenceCenter.cookiePolicyLink": "",
  "cookiePreferenceCenter.cookiesListTitle": "",
  "cookiePreferenceCenter.description": "",
  "cookiePreferenceCenter.firstPartyCookies": "",
  "cookiePreferenceCenter.optionalGroupName": "",
  "cookiePreferenceCenter.rejectAllButton": "",
  "cookiePreferenceCenter.strictlyNecessaryGroupName": "",
  "cookiePreferenceCenter.title": "",
  "cookiePreferenceCenter.viewCookiesButton": "",
  "countres.gu": "",
  "countries.ad": "Andorre",
  "countries.ae": "Émirats arabes unis",
  "countries.af": "Afghanistan",
  "countries.ag": "Antigua-et-Barbuda",
  "countries.ai": "Anguilla",
  "countries.al": "Albanie",
  "countries.am": "Arménie",
  "countries.ao": "Angola",
  "countries.aq": "Antarctique",
  "countries.ar": "Argentine",
  "countries.as": "Samoa américaines",
  "countries.at": "Autriche",
  "countries.au": "Australie",
  "countries.aw": "Aruba",
  "countries.ax": "Îles Åland",
  "countries.az": "Azerbaïdjan",
  "countries.ba": "Bosnie-Herzégovine",
  "countries.bb": "Barbade",
  "countries.bd": "Bangladesh",
  "countries.be": "Belgique",
  "countries.bf": "Burkina Faso",
  "countries.bg": "Bulgarie",
  "countries.bh": "Bahreïn",
  "countries.bi": "Burundi",
  "countries.bj": "Bénin",
  "countries.bl": "Saint-Barthélemy",
  "countries.bm": "Bermudes",
  "countries.bn": "Brunei Darussalam",
  "countries.bo": "Bolivie",
  "countries.bq": "Bonaire",
  "countries.br": "Brésil",
  "countries.bs": "Bahamas",
  "countries.bt": "Bhoutan",
  "countries.bv": "Île Bouvet",
  "countries.bw": "Botswana",
  "countries.by": "Biélorussie",
  "countries.bz": "Belize",
  "countries.cc": "Îles Cocos (Keeling)",
  "countries.cd": "Congo, République démocratique du",
  "countries.cf": "République centrafricaine",
  "countries.cg": "Congo",
  "countries.ch": "Suisse",
  "countries.ci": "Côte d’Ivoire",
  "countries.ck": "Îles Cook",
  "countries.cl": "Chili",
  "countries.cm": "Cameroun",
  "countries.cn": "Canada",
  "countries.co": "Colombie",
  "countries.cr": "Costa Rica",
  "countries.cu": "Cuba",
  "countries.cv": "Cap-Vert",
  "countries.cw": "Curaçao",
  "countries.cx": "Île Christmas",
  "countries.cy": "Chypre",
  "countries.cz": "République tchèque",
  "countries.de": "Allemagne",
  "countries.dj": "Djibouti",
  "countries.dk": "Danemark",
  "countries.dm": "Dominique",
  "countries.do": "République dominicaine",
  "countries.dz": "Algérie",
  "countries.ec": "Équateur",
  "countries.ee": "Estonie",
  "countries.eg": "Égypte",
  "countries.eh": "Sahara occidental",
  "countries.er": "Érythrée",
  "countries.es": "Espagne",
  "countries.et": "Éthiopie",
  "countries.fi": "Finlande",
  "countries.fj": "Fidji",
  "countries.fk": "Îles Malouines (Malvinas)",
  "countries.fm": "Micronésie, États fédérés de",
  "countries.fo": "Îles Féroé",
  "countries.fr": "France",
  "countries.ga": "Gabon",
  "countries.gb": "Royaume-Uni",
  "countries.gd": "Grenade",
  "countries.ge": "Géorgie",
  "countries.gf": "Guyane française",
  "countries.gg": "Guernesey",
  "countries.gh": "Ghana",
  "countries.gi": "Gibraltar",
  "countries.gl": "Groenland",
  "countries.gm": "Gambie",
  "countries.gn": "Guinée",
  "countries.gp": "Guadeloupe",
  "countries.gq": "Guinée équatoriale",
  "countries.gr": "Grèce",
  "countries.gs": "Géorgie du Sud-et-les îles Sandwich du Sud",
  "countries.gt": "Guatemala",
  "countries.gw": "Guinée-Bissau",
  "countries.gy": "Guyana",
  "countries.hk": "Hong Kong",
  "countries.hm": "Îles Heard-et-McDonald",
  "countries.hn": "Honduras",
  "countries.hr": "Croatie",
  "countries.ht": "Haïti",
  "countries.hu": "Hongrie",
  "countries.id": "Indonésie",
  "countries.ie": "Irlande",
  "countries.il": "Israël",
  "countries.im": "Île de Man",
  "countries.in": "Inde",
  "countries.io": "Territoire britannique de l’océan Indien",
  "countries.iq": "Irak",
  "countries.ir": "Iran, République islamique d’",
  "countries.is": "Islande",
  "countries.it": "Italie",
  "countries.je": "Jersey",
  "countries.jm": "Jamaïque",
  "countries.jo": "Jordanie",
  "countries.jp": "Japon",
  "countries.ke": "Kenya",
  "countries.kg": "Kirghizistan",
  "countries.kh": "Cambodge",
  "countries.ki": "Kiribati",
  "countries.km": "Comores",
  "countries.kn": "Saint-Christophe-et-Niévès",
  "countries.kp": "Corée, République populaire démocratique de",
  "countries.kr": "Corée, République de",
  "countries.kw": "Koweït",
  "countries.ky": "Îles Caïmans",
  "countries.kz": "Kazakhstan",
  "countries.la": "République démocratique populaire lao",
  "countries.lb": "Liban",
  "countries.lc": "Sainte-Lucie",
  "countries.li": "Liechtenstein",
  "countries.lk": "Sri Lanka",
  "countries.lr": "Libéria",
  "countries.ls": "Lesotho",
  "countries.lt": "Lituanie",
  "countries.lu": "Luxembourg",
  "countries.lv": "Lettonie",
  "countries.ly": "Libye",
  "countries.ma": "Maroc",
  "countries.mc": "Monaco",
  "countries.md": "Moldavie, République de",
  "countries.me": "Monténégro",
  "countries.mf": "Saint-Martin (partie française)",
  "countries.mg": "Madagascar",
  "countries.mh": "Îles Marshall",
  "countries.mk": "Macédoine, ancienne République yougoslave de",
  "countries.ml": "Mali",
  "countries.mm": "Myanmar",
  "countries.mn": "Mongolie",
  "countries.mo": "Macao",
  "countries.mp": "Îles Mariannes du Nord",
  "countries.mq": "Martinique",
  "countries.mr": "Mauritanie",
  "countries.ms": "Montserrat",
  "countries.mt": "Malte",
  "countries.mu": "Maurice",
  "countries.mv": "Maldives",
  "countries.mw": "Malawi",
  "countries.mx": "Mexique",
  "countries.my": "Malaisie",
  "countries.mz": "Mozambique",
  "countries.na": "Namibie",
  "countries.nc": "Nouvelle-Calédonie",
  "countries.ne": "Niger",
  "countries.nf": "Île Norfolk",
  "countries.ng": "Nigéria",
  "countries.ni": "Nicaragua",
  "countries.nl": "Pays-Bas",
  "countries.no": "Norvège",
  "countries.np": "Népal",
  "countries.nr": "Nauru",
  "countries.nu": "Nioué",
  "countries.nz": "Nouvelle-Zélande",
  "countries.om": "Oman",
  "countries.pa": "Panama",
  "countries.pe": "Pérou",
  "countries.pf": "Polynésie française",
  "countries.pg": "Papouasie-Nouvelle-Guinée",
  "countries.ph": "Philippines",
  "countries.pk": "Pakistan",
  "countries.pl": "Pologne",
  "countries.pm": "Saint Pierre et Miquelon",
  "countries.pn": "Pitcairn",
  "countries.pr": "Porto Rico",
  "countries.ps": "Territoire palestinien occupé",
  "countries.pt": "Portugal",
  "countries.pw": "Palaos",
  "countries.py": "Paraguay",
  "countries.qa": "Qatar",
  "countries.re": "La Réunion",
  "countries.ro": "Roumanie",
  "countries.rs": "Serbie",
  "countries.ru": "Fédération de Russie",
  "countries.rw": "Rwanda",
  "countries.sa": "Arabie Saoudite",
  "countries.sb": "Îles Salomon",
  "countries.sc": "Seychelles",
  "countries.sd": "Soudan",
  "countries.se": "Suède",
  "countries.sg": "Singapour",
  "countries.sh": "Sainte-Hélène, Ascension et Tristan da Cunha",
  "countries.si": "Slovénie",
  "countries.sj": "Svalbard et Jan Mayen",
  "countries.sk": "Slovaquie",
  "countries.sl": "Sierra Leone",
  "countries.sm": "Saint-Marin",
  "countries.sn": "Sénégal",
  "countries.so": "Somalie",
  "countries.sr": "Suriname",
  "countries.ss": "Soudan du Sud",
  "countries.st": "Sao Tomé-et-Principe",
  "countries.sv": "El Salvador",
  "countries.sx": "Sint Maarten (partie néerlandaise)",
  "countries.sy": "République arabe syrienne",
  "countries.sz": "Swaziland",
  "countries.tc": "Îles Turques-et-Caïques",
  "countries.td": "Tchad",
  "countries.tf": "Terres australes françaises",
  "countries.tg": "Togo",
  "countries.th": "Thaïlande",
  "countries.tj": "Tadjikistan",
  "countries.tk": "Tokélaou",
  "countries.tl": "Timor-Leste",
  "countries.tm": "Turkménistan",
  "countries.tn": "Tunisie",
  "countries.to": "Tonga",
  "countries.tr": "Turquie",
  "countries.tt": "Trinité-et-Tobago",
  "countries.tv": "Tuvalu",
  "countries.tw": "Taïwan",
  "countries.tz": "Tanzanie, République unie de",
  "countries.ua": "Ukraine",
  "countries.ug": "Ouganda",
  "countries.um": "Îles mineures éloignées des États-Unis",
  "countries.us": "États-Unis",
  "countries.uy": "Uruguay",
  "countries.uz": "Ouzbékistan",
  "countries.va": "Saint-Siège (État de la Cité du Vatican)",
  "countries.vc": "Saint-Vincent-et-les-Grenadines",
  "countries.ve": "Venezuela, République bolivarienne du",
  "countries.vg": "Îles Vierges britanniques",
  "countries.vi": "Îles Vierges, États-Unis",
  "countries.vn": "Vietnam",
  "countries.vu": "Vanuatu",
  "countries.wf": "Wallis-et-Futuna",
  "countries.ws": "Samoa",
  "countries.ye": "Yémen",
  "countries.yt": "Mayotte",
  "countries.za": "Afrique du Sud",
  "countries.zm": "Zambie",
  "countries.zw": "Zimbabwe",
  "countryNames.de.long": "",
  "countryNames.de.medium": "Allemagne",
  "countryNames.de.short": "DE",
  "countryNames.us.long": "",
  "countryNames.us.medium": "USA",
  "countryNames.us.short": "USA",
  "cta.redeemVoucher": "Activer le bon",
  "cta.unlockFree": "",
  "customCodeVerificationForm.label.dvg": "",
  "customCodeVerificationForm.label.optumrx_1": "",
  "customCodeVerificationForm.overlayButtonText": "",
  "customCodeVerificationForm.overlayText.dvg": "",
  "customCodeVerificationForm.overlayText.optumrx_1": "",
  "customCodeVerificationForm.placeholder.dvg": "",
  "customCodeVerificationForm.placeholder.optumrx_1": "",
  "downloadApp.desktop.text": "",
  "downloadApp.mobile.text": "",
  "downloadApp.text": "",
  "downloadApp.title": "<b>Une dernière chose !</b>",
  "eligibilityCheck.failure.changeEmailButton": "",
  "eligibilityCheck.failure.monsterAlt": "",
  "eligibilityCheck.failure.title": "",
  "eligibilityCheck.failure.unsuccessfulText": "",
  "eligibilityCheck.form.companyEmail.label": "",
  "eligibilityCheck.form.continue": "",
  "eligibilityCheck.form.overlayText": "",
  "eligibilityCheck.introText": "",
  "eligibilityCheck.pageTitle": "",
  "eligibilityCheck.resend.notification.failure": "",
  "eligibilityCheck.resend.notification.success": "",
  "eligibilityCheck.success.boxAlt": "",
  "eligibilityCheck.success.boxWithHeartAlt": "",
  "eligibilityCheck.success.changeEmailButton": "",
  "eligibilityCheck.success.resendButton": "",
  "eligibilityCheck.success.resendText": "",
  "eligibilityCheck.success.successfullText": "",
  "eligibilityCheck.success.title": "",
  "eligibilityCheck.title": "",
  "email.stepName": "Inscription / Connexion",
  "endsWithNumber": "",
  "enroll": "",
  "errors.ADYEN_REFUSED_3D_NOT_AUTHENTICATED": "",
  "errors.ADYEN_REFUSED_3D_SECURE_AUTHENTICATION_FAILED": "",
  "errors.ADYEN_REFUSED_CVC_DECLINED": "",
  "errors.ADYEN_REFUSED_EXPIRED_CARD": "",
  "errors.ADYEN_REFUSED_FRAUD": "",
  "errors.ADYEN_REFUSED_INVALID_CARD_NUMBER": "",
  "errors.ADYEN_REFUSED_REFUSED": "",
  "errors.ASSIGN_OPTUM_RX_ERROR.invalid_input": "",
  "errors.ASSIGN_OPTUM_RX_ERROR.patient_already_assigned": "",
  "errors.CARD_DECLINED": "",
  "errors.CARD_NOT_SUPPORTED.cardNumber": "",
  "errors.COUNTRY_REGION_MISSMATCH": "L’offre que tu essaies de faire valider n’est actuellement pas disponible à/au/aux/en :nameCountry. Elle est uniquement disponible pour les utilisateurs qui conservent leurs données à/au/aux/en :offerRegions. Merci de contacter l’équipe d’assistance.",
  "errors.DECLINED_CARD.cardNumber": "",
  "errors.EMPTY_CART": "",
  "errors.EXISTS_IN_OTHER_REGION": "",
  "errors.EXPIRED_CARD.cardNumber": "",
  "errors.INCOMPLETE_CARD_CVC.cvc": "",
  "errors.INCOMPLETE_CARD_EXPIRATION.expiration": "",
  "errors.INCOMPLETE_CARD_NUMBER.cardNumber": "",
  "errors.INCORRECT_CVC": "",
  "errors.INSUFFICIENT_FUNDS": "",
  "errors.INVALID_AGE.birthdate": "",
  "errors.INVALID_BIRTHDATE.birthdate": "",
  "errors.INVALID_CARD_CVC_NUMBER.cvc": "",
  "errors.INVALID_CARD_EXPIRATION_MONTH_PAST.expiration": "",
  "errors.INVALID_CARD_EXPIRATION_YEAR_PAST.expiration": "",
  "errors.INVALID_CARD_NUMBER.cardNumber": "",
  "errors.INVALID_COUNTRY": "",
  "errors.INVALID_EMAIL_CONFIRMATION_CODE.code": "Code de confirmation d’adresse e-mail invalide",
  "errors.INVALID_EMAIL.companyEmail": "",
  "errors.INVALID_EMAIL.email": "Adresse e-mail invalide",
  "errors.INVALID_GERMAN_STATE.state": "",
  "errors.INVALID_INSURANCE_CARD_INFO.groupNumber": "",
  "errors.INVALID_INSURANCE_CARD_INFO.memberId": "",
  "errors.INVALID_INSURANCE_NUMBER.insuranceNumber": "",
  "errors.INVALID_LOGIN": "L’e-mail et le mot de passe ne correspondent pas à un utilisateur mySugr",
  "errors.INVALID_PASSWORD_CONFIRMATION.password2": "La confirmation du mot de passe ne correspond pas au mot de passe.",
  "errors.INVALID_PHONE_NUMBER.phone": "",
  "errors.INVALID_POSTAL_CODE.postalCode": "",
  "errors.INVALID_US_STATE.state": "",
  "errors.INVALID_VOUCHER_CODE.voucherCode": "Invalide",
  "errors.INVALID_ZIP_CODE.postalCode": "",
  "errors.INVALID.customCodeVerification": "",
  "errors.INVALID.customCodeVerification.dvg": "",
  "errors.MIN_LENGTH.password": "Le mot de passe doit comporter au moins :minLength caractères.",
  "errors.MIN_LENGTH.password2": "Le mot de passe doit comporter au moins :minLength caractères.",
  "errors.MISSING_CONSENT.consents": "",
  "errors.MISSING_CONSENTS": "",
  "errors.MISSING_CONSENTS_FAILED": "",
  "errors.MISSING_FIELD.address": "",
  "errors.MISSING_FIELD.birthdate": "",
  "errors.MISSING_FIELD.birthdateCheckbox": "",
  "errors.MISSING_FIELD.city": "",
  "errors.MISSING_FIELD.companyEmail": "",
  "errors.MISSING_FIELD.country": "Indique le pays",
  "errors.MISSING_FIELD.email": "Indique l’adresse e-mail",
  "errors.MISSING_FIELD.employer": "",
  "errors.MISSING_FIELD.firstName": "",
  "errors.MISSING_FIELD.lastName": "",
  "errors.MISSING_FIELD.name": "",
  "errors.MISSING_FIELD.password": "Merci de saisir un mot de passe",
  "errors.MISSING_FIELD.password2": "Merci de répéter le mot de passe",
  "errors.MISSING_FIELD.phone": "",
  "errors.MISSING_FIELD.postalCode": "",
  "errors.MISSING_FIELD.state": "",
  "errors.MISSING_FIELD.voucherCode": "Merci de saisir le code de ton bon",
  "errors.PASSWORD_RESET_TOKEN_EXPIRED": "Le jeton de réinitialisation du mot de passe a expiré.",
  "errors.PAYMENT_DETAILS_NOT_SUPPORTED": "",
  "errors.POSSIBLE_PO_BOX.address": "",
  "errors.PRESCRIPTION_ALREADY_ACTIVE": "",
  "errors.PROCESSING_ERROR": "",
  "errors.SERVER_ERROR": "",
  "errors.STRIPE_ERROR": "",
  "errors.THE_VOUCHER_IS_CURRENTLY_NOT_ACTIVE.voucherCode": "Ce bon n’est actuellement pas activé",
  "errors.undefined": "",
  "errors.UNKNOWN_ERROR": "",
  "errors.UNKNOWN_ERROR_2": "",
  "errors.UNLOCK_CODE_ALREADY_USED": "",
  "errors.UNLOCK_CODE_INVALID": "",
  "errors.USER_ALREADY_SUBSCRIBED_TO_BUNDLE": "",
  "errors.USER_ALREADY_SUBSCRIBED_TO_PLAN": "",
  "errors.USER_EMAIL_HAS_ACTIVE_BUNDLE": "",
  "errors.USER_EMAIL_HAS_ACTIVE_PRESCRIPTION": "",
  "errors.USER_EXISTS.email": "L’utilisateur existe déjà",
  "errors.USER_HAS_NO_BUNDLE": "",
  "errors.USER_HAS_NO_EXISTING_PAYMENT": "",
  "errors.USER_MISSING": "",
  "errors.VOUCHER_ALREADY_EXPIRED.voucherCode": "Ce bon a expiré",
  "errors.VOUCHER_ALREADY_REDEEMED": "Le bon a déjà été validé pour ce compte",
  "errors.VOUCHER_DOES_NOT_EXISTS.voucherCode": "Ce bon n’existe pas",
  "errors.VOUCHER_MAXIMUM_REDEMPTION_EXCEEDED": "",
  "errors.VOUCHER_MAXIMUM_REDEMPTION_EXCEEDED.voucherCode": "",
  "footer.callSupport": "Appelle le numéro vert : <strong>:phone</strong>",
  "footer.happyToHelp": "Nous sommes toujours ravis de t’aider :",
  "footer.headline": "Des questions sur ta commande ?",
  "footer.legalNotice.activision_blizzard_1": "",
  "footer.legalNotice.allianz_1": "",
  "footer.legalNotice.aok_1": "",
  "footer.legalNotice.aok_nordwest_1": "",
  "footer.legalNotice.barmenia_1": "",
  "footer.legalNotice.barmer_1": "",
  "footer.legalNotice.bi_state_development_1": "",
  "footer.legalNotice.buchanan_us_1": "",
  "footer.legalNotice.dkv_1": "",
  "footer.legalNotice.ed_voyles_1": "",
  "footer.legalNotice.garfield_county_1": "",
  "footer.legalNotice.genentech_1": "",
  "footer.legalNotice.gothaer_1": "",
  "footer.legalNotice.inter_1": "",
  "footer.legalNotice.jbz_1": "",
  "footer.legalNotice.lazer_spot_1": "",
  "footer.legalNotice.mastec_1": "",
  "footer.legalNotice.mmc_1": "",
  "footer.legalNotice.optumrx_1": "",
  "footer.legalNotice.roche_ponce": "",
  "footer.legalNotice.roche_us_1": "",
  "footer.legalNotice.si_group_1": "",
  "footer.legalNotice.ukv_1": "",
  "footer.legalNotice.vkb_1": "",
  "footer.legalNotice.wth_1": "",
  "footer.links.imprint": "Mentions légales",
  "footer.links.privacyNotice": "Déclaration de confidentialité",
  "footer.links.tcs": "Conditions générales",
  "footer.subscriptionLegalNotice": "",
  "footer.writeSupport": "Ou écris-nous tout simplement à : <a href=\"mailto::email\">:email</a>",
  "form.availableCountriesLabel": "",
  "groupSelection.continue": "",
  "groupSelection.items.description.alt_text.strips_subscription_guide.2": "",
  "groupSelection.items.description.coach": "",
  "groupSelection.items.description.guide": "",
  "groupSelection.items.description.guide_me_1": "",
  "groupSelection.items.description.pro": "",
  "groupSelection.items.description.scale_1": "",
  "groupSelection.items.description.strips_subscription_guide": "",
  "groupSelection.items.description.strips_subscription_guide.2": "",
  "groupSelection.message.reimbursement.linkText": "",
  "groupSelection.message.reimbursement.linkText.dvg": "",
  "groupSelection.message.reimbursement.text": "",
  "groupSelection.message.reimbursement.text.dkv": "",
  "groupSelection.message.reimbursement.text.dvg": "",
  "groupSelection.message.roche_bundle_group_coach_1": "",
  "groupSelection.message.roche_bundle_group_guide_1": "",
  "groupSelection.moreInfo": "",
  "groupSelection.titles.allianz_bundle_group_coach_2": "",
  "groupSelection.titles.allianz_bundle_group_guide_3": "",
  "groupSelection.titles.allianz_bundle_group_guide_4": "",
  "groupSelection.titles.barmenia_bundle_group_coach_2": "",
  "groupSelection.titles.barmenia_bundle_group_guide_2": "",
  "groupSelection.titles.barmenia_bundle_group_guide_3": "",
  "groupSelection.titles.bundle": "",
  "groupSelection.titles.coach": "",
  "groupSelection.titles.gothaer_bundle_group_coach_2": "",
  "groupSelection.titles.gothaer_bundle_group_guide_3": "",
  "groupSelection.titles.pro": "",
  "groupSelection.titles.roche_bundle_group_coach_1": "",
  "groupSelection.titles.roche_bundle_group_guide_1": "",
  "groupSelection.titles.ukv_bundle_group_coach_2": "",
  "groupSelection.titles.ukv_bundle_group_guide_3": "",
  "groupSelection.titles.vkb_bundle_group_coach_2": "",
  "groupSelection.titles.vkb_bundle_group_guide_3": "",
  "groupSelectionPage.priceFree": "",
  "groupSelectionPage.priceFreeExtra": "",
  "groupSelectionPage.title": "",
  "header.heart.altText": "cœur",
  "headerNotice.bundleNotAvailable": "",
  "headerNotice.bundleNotAvailable.barmer_1": "",
  "headerNotice.infoNotice.linkText.us_bundle_3": "",
  "headerNotice.infoNotice.teaser.us_bundle_3": "",
  "headerNotice.infoNotice.text.us_bundle_3": "",
  "Help": "Aide",
  "homepage.bundle.text": "",
  "homepage.bundle.text.us": "",
  "homepage.bundle.title": "",
  "homepage.countries.defaultValue": "",
  "homepage.pro.infoText": "",
  "homepage.pro.moreInfo": "",
  "homepage.pro.moreInfo.dvg": "",
  "homepage.pro.text": "Pour faire valoir ton bon pour l’<strong>app mySugr PRO</strong> :",
  "homepage.pro.title": "",
  "homepage.text": "Bienvenue dans la boutique mySugr !",
  "insuranceCardForm.groupNumberLabel": "",
  "insuranceCardForm.groupNumberLabel.edvoyles": "",
  "insuranceCardForm.groupNumberLabel.garfieldcounty": "",
  "insuranceCardForm.groupNumberLabel.mastec-1": "",
  "insuranceCardForm.groupNumberLabel.roche-benefits-us": "",
  "insuranceCardForm.groupNumberLabel.roche-benefits-us-coach": "",
  "insuranceCardForm.groupNumberPlaceholder": "",
  "insuranceCardForm.groupNumberPlaceholder.ActivisionBlizzard": "",
  "insuranceCardForm.groupNumberPlaceholder.buchanan-group-us": "",
  "insuranceCardForm.groupNumberPlaceholder.edvoyles": "",
  "insuranceCardForm.groupNumberPlaceholder.garfieldcounty": "",
  "insuranceCardForm.groupNumberPlaceholder.lazerspot": "",
  "insuranceCardForm.groupNumberPlaceholder.mastec-1": "",
  "insuranceCardForm.infoTextGeneric": "",
  "insuranceCardForm.infoTextGeneric.ActivisionBlizzard": "",
  "insuranceCardForm.infoTextGeneric.garfieldcounty": "",
  "insuranceCardForm.infoTextGeneric.kaiser.roche-benefits-us": "",
  "insuranceCardForm.infoTextGeneric.mastec-1": "",
  "insuranceCardForm.infoTextGeneric.roche-benefits-us-coach": "",
  "insuranceCardForm.memberIdLabel": "",
  "insuranceCardForm.memberIdLabel.ActivisionBlizzard": "",
  "insuranceCardForm.memberIdLabel.kaiser.roche-benefits-us": "",
  "insuranceCardForm.memberIdLabel.roche-benefits-us": "",
  "insuranceCardForm.memberIdLabel.roche-benefits-us-coach": "",
  "insuranceCardForm.memberIdPlaceholder": "",
  "insuranceCardForm.memberIdPlaceholder.ActivisionBlizzard": "",
  "insuranceCardForm.memberIdPlaceholder.buchanan-group-us": "",
  "insuranceCardForm.memberIdPlaceholder.edvoyles": "",
  "insuranceCardForm.memberIdPlaceholder.garfieldcounty": "",
  "insuranceCardForm.memberIdPlaceholder.kaiser.roche-benefits-us": "",
  "insuranceCardForm.memberIdPlaceholder.lazerspot": "",
  "insuranceCardForm.memberIdPlaceholder.mastec": "",
  "insuranceNumberForm.label": "",
  "insuranceNumberForm.overlayButtonText": "",
  "introButtonLabelCreateAccount": "",
  "languages.de": "Deutsch",
  "languages.en": "English",
  "languages.es": "Español",
  "languages.fr": "Français",
  "languages.nl": "Nederlands",
  "languageSelectLabel": "Modification des paramètres de langue",
  "login.pageName": "",
  "logoText.mySugr": "Programme mySugr",
  "logoText.payor": "en partenariat avec :payor",
  "measurementUnitSamples.mg/dl": "",
  "measurementUnitSamples.mmol/l": "",
  "more.info.overlay.linkText": "",
  "notFound.lead": "",
  "notFound.text": "",
  "notFound.title": "",
  "notifications.CARD_DECLINED": "",
  "notifications.dismissNotification": "Désactiver la notification",
  "notifications.EXPIRED_CARD": "",
  "notifications.IE11_NOT_SUPPORTED": "",
  "notifications.INCORRECT_CVC": "",
  "notifications.INCORRECT_NUMBER": "",
  "notifications.INSUFFICIENT_FUNDS": "",
  "notifications.PROCESSING_ERROR": "",
  "notifications.SERVER_ERROR": "",
  "notifications.STRIPE_ERROR": "",
  "notifications.TRIGGER_EMAIL_VERIFICATION_SUCCESS": "Un e-mail vient d’être envoyé",
  "notifications.UNDEFINED_ERROR": "",
  "notifications.UNKNOWN_ERROR": "",
  "notifications.UNKNOWN_ERROR_2": "",
  "notifications.USER_ALREADY_SUBSCRIBED_TO_BUNDLE": "",
  "notifications.USER_ALREADY_SUBSCRIBED_TO_PLAN": "",
  "numbers.0": "",
  "numbers.1": "",
  "numbers.2": "",
  "numbers.3": "",
  "numbers.4": "",
  "numbers.5": "",
  "numbers.6": "",
  "numbers.7": "",
  "numbers.8": "",
  "numbers.9": "",
  "numbers.10": "",
  "offlinePage.subtitle": "",
  "offlinePage.title": "",
  "optionalConsent": "",
  "optionalPlaceholder": "",
  "overlay.close": "",
  "overview.address.phone": "",
  "overview.autoCancelInfo.DAY.1": "",
  "overview.autoCancelInfo.DAY.2": "",
  "overview.autoCancelInfo.MONTH.1": "",
  "overview.autoCancelInfo.MONTH.2": "",
  "overview.autoCancelInfo.WEEK.2": "",
  "overview.autoCancelInfo.YEAR.1": "",
  "overview.autoCancelInfo.YEAR.2": "",
  "overview.backButton": "",
  "overview.billingAddress.edit": "",
  "overview.billingAddress.title": "",
  "overview.cancelationInfo.MONTH.1": "",
  "overview.cancelationInfo.MONTH.2": "",
  "overview.cancelationInfo.YEAR.1": "",
  "overview.cancelationInfo.YEAR.2": "",
  "overview.coachLanguageOverview.change": "",
  "overview.coachLanguageOverview.title": "",
  "overview.contactDetails.edit": "",
  "overview.contactDetails.title": "",
  "overview.continueButton": "",
  "overview.continueButton.at": "",
  "overview.continueButton.at.fallback": "",
  "overview.continueButton.ca.fallback": "",
  "overview.continueButton.de": "",
  "overview.continueButton.de.fallback": "",
  "overview.continueButton.es": "",
  "overview.continueButton.es.fallback": "",
  "overview.continueButton.nl.fallback": "",
  "overview.continueButton.sg.fallback": "",
  "overview.continueButton.us": "",
  "overview.continueButton.us.fallback": "",
  "overview.continueButton.us.optumrx_bundle_1": "",
  "overview.continueButton.us.us_bundle_3": "",
  "overview.continueButton.za.fallback": "",
  "overview.continueButton.za.south-africa_affiliate_(coaching_bundle)_2": "",
  "overview.enrollmentTitle": "",
  "overview.freeShipping": "",
  "overview.measurementUnitOverview.change": "",
  "overview.measurementUnitOverview.mgdl": "",
  "overview.measurementUnitOverview.mmoll": "",
  "overview.measurementUnitOverview.title": "",
  "overview.pageName": "",
  "overview.paymentOverview.change": "",
  "overview.paymentOverview.creditCard": "",
  "overview.paymentOverview.payment": "",
  "overview.payorCovered": "",
  "overview.payorCovered.aok_nordwest_bundle_1": "",
  "overview.payorCovered.dec_i_bundle_1": "",
  "overview.payorCovered.dec_ii_bundle_1": "",
  "overview.payorCovered.onemed_bundle_1": "",
  "overview.payorCovered.optumrx_bundle_1": "",
  "overview.payorCovered.servidigest_bundle_1": "",
  "overview.payorCovered.us": "",
  "overview.payorCovered.us.activision_blizzard_bundle_1": "",
  "overview.payorCovered.us.bi_state_development_bundle_1": "",
  "overview.payorCovered.us.buchanan_bundle_2": "",
  "overview.payorCovered.us.dhp_roche_ponce_bundle_1": "",
  "overview.payorCovered.us.garfield_county_bundle_1": "",
  "overview.payorCovered.us.genentech_bundle_1": "",
  "overview.payorCovered.us.lazer_spot_bundle_1": "",
  "overview.payorCovered.us.mastec_bundle_1": "",
  "overview.payorCovered.us.mysugr_ed_voyles_bundle_1": "",
  "overview.payorCovered.us.roche_bundle_us_1": "",
  "overview.payorCovered.us.roche_bundle_us_2": "",
  "overview.payorCovered.us.si_group_bundle_1": "",
  "overview.payorCovered.zdg_bundle_1": "",
  "overview.seller": "",
  "overview.shipmentAddress.edit": "",
  "overview.shipmentAddress.title": "",
  "overview.shipmentOverview.edit": "",
  "overview.shipmentOverview.title": "",
  "overview.stepName": "",
  "overview.subscribeNotice": "",
  "overview.title": "",
  "pageTitle": "",
  "pageTitle.flowTitle.withPayor": "",
  "pageTitle.flowTitle.withProductAndPayor": "",
  "pageTitle.withFlow": "",
  "pageTitle.withStep": ":stepTitle | Boutique mySugr",
  "pageTitle.withStepAndFlow": ":stepTitle | :flowTitle",
  "passwordInput.hidePasswordButton": "Masquer",
  "passwordInput.showPasswordButton": "Afficher",
  "passwordReset.pageName": "Réinitialiser mot de passe",
  "passwordResetSuccessPage.buttonText": "Continuer",
  "passwordResetSuccessPage.congratsImageAlt": "Félicitations, des confettis volent partout.",
  "passwordResetSuccessPage.text": "Merci de noter que ton mot de passe a aussi changé pour l’application mySugr.",
  "passwordResetSuccessPage.title": "Tu as modifié ton mot de passe !",
  "payment.cardInfo.backButton": "",
  "payment.cardInfo.changeCard": "",
  "payment.cardInfo.continueButton": "",
  "payment.cardInfo.discardButton": "",
  "payment.cardInfo.expires": "",
  "payment.cardInfo.usingText": "",
  "payment.form.backButton": "",
  "payment.form.cardNumberLabel": "",
  "payment.form.continueButton": "",
  "payment.form.cvcLabel": "",
  "payment.form.discardButton": "",
  "payment.form.expirationLabel": "",
  "payment.form.firstNameLabel": "",
  "payment.form.lastNameLabel": "",
  "payment.form.nameLabel": "",
  "payment.form.sameAsShipment": "",
  "payment.form.saveChangesButton": "",
  "payment.stepName": "",
  "payment.title": "",
  "paymentDetails.list.info": "",
  "paymentDetails.list.title": "",
  "paymentDetails.login.title": "",
  "paymentLogos.title": "",
  "product.bundleBoxImage.alt": "",
  "product.bundleBoxImage.alt.bundle_coach": "",
  "product.bundleBoxImage.alt.bundle_guide": "",
  "product.bundleBoxImage.alt.bundle_guide_me": "",
  "product.bundleBoxImage.alt.coach": "",
  "product.bundleBoxImage.alt.pro": "",
  "product.continueButton": "",
  "product.items.moreInfo.dvg": "",
  "product.items.overlayText.dvg": "",
  "product.stepName": "",
  "product.submitButton": "",
  "product.title": "",
  "productName.bundle": "",
  "productName.coach": "",
  "promotion.APRILFOOLS2019_PROMOTION_PRICE_TEXT": "",
  "promotion.APRILFOOLS2019_PROMOTION_TEXT": "",
  "proVoucher.pageName": "Valider un bon PRO",
  "proVoucherInfo.checklist.challenges": "Participer à des <strong>défis</strong>",
  "proVoucherInfo.checklist.pdf": "Rapports <strong>PDF</strong>",
  "proVoucherInfo.checklist.photos": "<strong>Prendre des photos</strong> de tes repas",
  "proVoucherInfo.checklist.reminders": "Recevoir des <strong>rappels</strong> automatiques",
  "proVoucherInfo.checklist.search": "<strong>Recherche</strong> - Trouve tes entrées en toute facilité",
  "proVoucherInfo.checklist.sync": "Synchronisation <strong>multi-dispositifs</strong>",
  "proVoucherInfo.ctaButtonText": "Activer maintenant le bon",
  "proVoucherInfo.headline": "Ton bon s’active...",
  "proVoucherInfo.imageTitle": "mySugr PRO",
  "proVoucherInfo.stepName": "",
  "proVoucherInfo.validDate": "Les fonctions seront déverrouillées d’ici le <strong>:date</strong>.",
  "proVoucherInfo.validTimeMonths": "",
  "proVoucherInfo.validTimeUnlimited": "Tu obtiendras un accès <strong>illimité</strong> aux fonctions.",
  "proVoucherInfo.voucherCodeValid": "",
  "RDRegistration_description": "",
  "RDRegistration_mySugr_description": "",
  "RDSignIn_description": "",
  "readMoreBundleContentInformation": "",
  "register.pageName": "Enregistrement",
  "reimbursement.overlay.text": "",
  "reimbursement.overlay.text.barmenia": "",
  "reimbursement.overlay.text.dkv": "",
  "reimbursement.overlay.text.gothaer": "",
  "reimbursement.overlay.text.ukv": "",
  "reimbursement.overlay.text.vkb": "",
  "reimbursement.overlay.title": "",
  "reimbursement.overlay.title.allianz_bundle_group_coach_2": "",
  "reimbursement.overlay.title.barmenia_bundle_group_coach_2": "",
  "reimbursement.overlay.title.bundle": "",
  "reimbursement.overlay.title.coach": "",
  "reimbursement.overlay.title.coach.dkv": "",
  "reimbursement.overlay.title.gothaer_bundle_group_coach_2": "",
  "reimbursement.overlay.title.ukv_bundle_group_coach_2": "",
  "reimbursement.overlay.title.vkb_bundle_group_coach_2": "",
  "selectCountry.selectCountryLabel": "Choisir pays",
  "selectPayor.otherInsuranceButton": "",
  "selectPayor.otherInsuranceExplanation": "",
  "selectPayor.pleaseSelect": "",
  "selectPayor.title": "",
  "shipment.contactDataForCoaches": "",
  "shipment.form.address2Label": "",
  "shipment.form.addressHint": "",
  "shipment.form.addressLabel": "",
  "shipment.form.addressPlaceholder.germany": "",
  "shipment.form.backButton": "",
  "shipment.form.billingAddressTitle": "",
  "shipment.form.birthdateCheckboxLabel": "",
  "shipment.form.birthdateHint": "",
  "shipment.form.birthdateOverlayText": "",
  "shipment.form.cityLabel": "",
  "shipment.form.continueButton": "",
  "shipment.form.discardChangesButton": "",
  "shipment.form.emailLabel": "",
  "shipment.form.employerDefaultOption": "",
  "shipment.form.employerHint": "",
  "shipment.form.employerLabel": "",
  "shipment.form.firstNameLabel": "",
  "shipment.form.lastNameLabel": "",
  "shipment.form.memberClass.dependentLabel": "",
  "shipment.form.memberClass.employeeLabel": "",
  "shipment.form.memberClass.explanation": "",
  "shipment.form.phoneLabel": "",
  "shipment.form.policy.headline": "",
  "shipment.form.postalCodeLabel": "",
  "shipment.form.postalCodeLabel_new": "",
  "shipment.form.saveChangesButton": "",
  "shipment.form.stateDefaultOption": "",
  "shipment.form.stateLabel": "",
  "shipment.form.zipCodeLabel": "",
  "shipment.stepName": "",
  "shipment.title": "",
  "shoppingCart.deliverOnce": "",
  "shoppingCart.immediateTitle": "",
  "shoppingCart.productTitle": "",
  "shoppingCart.subscriptionTitle": "",
  "shoppingCart.title": "",
  "shoppingCart.title.dvg": "",
  "shoppingCart.title.roche-benefits-us": "",
  "shoppingCart.title.roche-benefits-us-coach": "",
  "SignIn": "",
  "SignInMySugr": "",
  "stepBar.activeStep.screenReader": "",
  "stepBar.completedStep.screenReader": "",
  "stepBar.notCompletedStep.screenReader": "",
  "stepBar.remaining": "",
  "stepBar.title.screenReader": "",
  "stepIndicationText": "",
  "success.coach.text": "",
  "success.coach.title": "",
  "success.doneButton": "",
  "success.download.bundle.text": "",
  "success.download.coach.text": "<b>Suis</b> tes <b>mesures quotidiennes</b> et maîtrise ton diabète.",
  "success.download.title": "Puis, <b>télécharge</b> l’<b>app</b> mySugr",
  "success.help": "En cas d’incertitude, n’hésite pas à contacter notre merveilleuse équipe d’assistance.",
  "success.nextStep1": "",
  "success.nextStep2": "",
  "success.nextStep3": "",
  "success.orderSuccess.coachText": "",
  "success.orderSuccess.deliveryText": "",
  "success.orderSuccess.title": "",
  "success.pageFooterTitle": "Suis cette procédure simple et reste à jour !",
  "success.pageName": "",
  "success.payment.change": "",
  "success.proVoucherInfo.validDate": "Les fonctions seront déverrouillées d’ici le <strong>:date</strong>.",
  "success.proVoucherInfo.validTimeMonths": "",
  "success.proVoucherInfo.validTimeUnlimited": "Tu as maintenant un accès <strong>illimité</strong> à toutes les fonctions PRO.",
  "success.proVoucherInfo.validTimeUnlimited.dvg": "Tu as maintenant accès à toutes les fonctions PRO.",
  "success.shippingInfo": "",
  "success.shippingInfoDynamic": "",
  "success.shippingMoreInfo": "",
  "success.stepName": "",
  "success.title": "",
  "success.titleProOnly": "Félicitations,<br> tu es maintenant un <strong>utilisateur PRO</strong> !",
  "success.trackingInfo": "",
  "unitSelection.buttonText": "",
  "unitSelection.label": "",
  "unitSelection.labelClose": "",
  "unitSelection.title": "",
  "voucher.pageName": "Activer le bon",
  "voucherForm.buttonText": "Vérifier le bon",
  "voucherForm.label": "Code de bon",
  "voucherForm.overlayButtonText": "",
  "voucherForm.overlayText": "",
  "voucherForm.overlayText.aok_1": "",
  "voucherForm.overlayText.aok_nordwest_1": "",
  "voucherForm.overlayText.barmer_1": "",
  "voucherForm.overlayText.bi_state_development_1": "",
  "voucherForm.overlayText.dkv": "",
  "voucherForm.overlayText.fayette_county_1": "",
  "voucherForm.overlayText.genentech_1": "",
  "voucherForm.overlayText.jbz_1": "",
  "voucherForm.overlayText.mmc_1": "",
  "voucherForm.overlayText.onemed_1": "",
  "voucherForm.overlayText.roche_pl_1": "",
  "voucherForm.overlayText.roche_sg_1": "",
  "voucherForm.overlayText.schwenninger_1": "",
  "voucherForm.overlayText.servidigest_1": "",
  "voucherForm.overlayText.si_group_1": "",
  "voucherForm.overlayText.zdg_1": "",
  "voucherForm.title": "Fais valider ton bon !"
};