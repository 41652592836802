import dayjs from 'dayjs';

function calculateAge(dateOfBirth: Date) {
  // For ease of testing, this way we can mock `Date.now()` instead of mocking dayjs.
  const now = Date.now();

  const today = dayjs(now);
  const birthday = dayjs(dateOfBirth);
  return today.diff(birthday, 'year');
}

export default calculateAge;
