import classnames from 'classnames';
import * as React from 'react';
import { ITranslateProps } from 'src/actions/translationActions';
import css from 'src/components/Overlay/Overlay.module.scss';

export interface IProps extends ITranslateProps {
  ariaLabel?: string;
  ariaLabelClose?: string;
  children?: any;
  className?: string;
  closeWrap: () => void;
  componentInstanceId: string;
  handleInnerClick: (event: React.SyntheticEvent<Element, Event>) => void;
  overlayNode: any;
  show: boolean;
}

interface IState {
  show: boolean;
  visible: boolean;
}

export class OverlayInner extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      show: props.show,
      visible: false,
    };
  }

  public UNSAFE_componentWillReceiveProps(newProps: IProps) {
    // check for the mounted props
    if (!newProps.show) {
      return this.unMountStyle();
    }

    // remount the node when the mounted prop is true
    this.setState({
      show: true,
    });

    setTimeout(this.mountStyle, 10);
  }

  public componentDidMount() {
    if (this.state.show) {
      setTimeout(this.mountStyle, 10);
    }
  }

  public unMountStyle = () => {
    // css for unmount animation
    this.setState({
      visible: false,
    });
  };

  public mountStyle = () => {
    // css for mount animation
    this.setState({
      visible: true,
    });
  };

  public transitionEnd = () => {
    // istanbul ignore else
    if (!this.props.show) {
      // remove the node on transition end when the mounted prop is false
      this.setState({
        show: false,
      });
    }
  };

  public renderClose() {
    const { ariaLabelClose, translate } = this.props;

    return (
      <button
        aria-label={ariaLabelClose ? translate(ariaLabelClose) : 'Close Modal'}
        className={css.close}
        onClick={this.closeWrapInner}
        type="button"
      >
        <svg
          width="13px"
          height="13px"
          viewBox="0 0 13 13"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform="translate(-342.000000, -317.000000)" fill="#737373">
            <polygon points="347.136265 323.499318 342 318.368904 343.370466 317 348.505232 322.128917 353.628037 317.000749 355 318.368156 349.875699 323.497823 354.999251 328.615538 353.628786 329.984443 348.506732 324.868223 343.380323 330 342.00836 328.632593 347.136265 323.499318" />
          </g>
        </svg>
      </button>
    );
  }

  public render() {
    const {
      ariaLabel,
      children,
      className,
      componentInstanceId,
      handleInnerClick,
      overlayNode,
      translate,
    } = this.props;

    const { visible } = this.state;

    return (
      this.state.show && (
        <div
          onTransitionEnd={this.transitionEnd}
          className={classnames(css.outer, {
            [css.outerVisible]: visible,
            [css.outerHidden]: !visible,
          })}
          ref={overlayNode}
          tabIndex={-1}
          role="dialog"
          aria-label={ariaLabel ? translate(ariaLabel) : 'Modal'}
          aria-describedby={`${componentInstanceId}_title`}
          onClick={this.closeWrapInner}
          aria-modal="true"
        >
          <div
            className={classnames(css.innerWrap, {
              [css.innerWrapVisible]: visible,
              [css.innerWrapHidden]: !visible,
            })}
          >
            <div className={css.inner} onClick={handleInnerClick}>
              <div className={classnames(css.scrollable, className)}>
                {children}
              </div>
              {this.renderClose()}
            </div>
          </div>
        </div>
      )
    );
  }

  private readonly closeWrapInner = () => {
    this.unMountStyle();
    this.props.closeWrap();
  };
}

export default OverlayInner;
