import { createSelector } from 'reselect';
import INotification from 'src/types/INotification';
import IStoreState from 'src/types/IStoreState';

function getNotifications(state: IStoreState): INotification[] {
  return state.notification.notifications;
}

export const getNotificationIds = createSelector(
  [getNotifications],
  (notifications: INotification[]): number[] =>
    notifications.map(notification => notification.id),
);

export const getNotificationById = (id: number) =>
  createSelector(
    [getNotifications],
    (notifications: INotification[]): INotification | undefined =>
      notifications.find(notification => notification.id === id),
  );
