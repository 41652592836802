import * as React from 'react';
import { connect } from 'react-redux';
import getNonBundleFlowNameFromPathname from 'src/lib/getNonBundleFlowNameFromPathname';
import getPayorForPathname from 'src/lib/getPayorForPathname';
import parseTranslationHtml from 'src/lib/parseTranslationHtml';
import { getTranslation } from 'src/selectors/translationSelectors';
import getPathname from 'src/server/lib/getPathname';
import IStoreState from 'src/types/IStoreState';
import css from './Translate.module.scss';

interface IStateProps {
  altTranslation?: string;
  translation?: string;
}

interface IOwnProps {
  altKey?: string;
  ignoreIfEmpty?: boolean;
  requiresRoleText?: boolean;
  textId?: string;
  html?: boolean;
  t: string;
  variables?: Record<string, any>;
  scope?: string;
}

interface IProps extends IStateProps, IOwnProps {}

export function Translate({
  altKey,
  altTranslation,
  html,
  ignoreIfEmpty,
  requiresRoleText,
  textId,
  t,
  translation,
}: IProps): React.ReactElement | null {
  const isHtml = html ?? false;

  const realAltTranslation =
    altTranslation && altTranslation !== altKey ? altTranslation : translation;
  const realTranslation =
    translation && t !== translation ? translation : realAltTranslation;

  if (realTranslation === undefined && ignoreIfEmpty === true) {
    return null;
  } else if (isHtml && realTranslation) {
    const [parsed, addRoleText] = parseTranslationHtml(realTranslation);

    return (
      <span aria-labelledby={textId} role={textId ? 'group' : ''}>
        <span
          id={textId}
          role={addRoleText && requiresRoleText ? 'text' : ''}
          className={css.translateHtml}
          dangerouslySetInnerHTML={{
            __html: parsed,
          }}
        />
      </span>
    );
  }
  return <>{realTranslation}</>;
}

export function mapStateToProps(
  state: IStoreState,
  ownProps: IOwnProps,
): IStateProps {
  const payorCode = getPayorForPathname(getPathname(state));
  const flowName = getNonBundleFlowNameFromPathname(getPathname(state));

  const translation = getTranslation(
    ownProps.t,
    ownProps.variables,
    ownProps.ignoreIfEmpty ?? false,
    ownProps.scope ?? payorCode ?? flowName,
  )(state);

  return {
    altTranslation:
      ownProps.altKey && (!translation || translation === ownProps.t)
        ? getTranslation(
            ownProps.altKey,
            ownProps.variables,
            ownProps.ignoreIfEmpty ?? false,
            ownProps.scope ?? payorCode ?? flowName,
          )(state)
        : undefined,
    translation,
  };
}

export default connect(mapStateToProps)(Translate);
