import IError from 'src/types/IError';

class BackendErrorException {
  private readonly error: IError;

  constructor(error: IError) {
    this.error = error;
  }

  public getCode(): string {
    return this.error.code;
  }

  public getMessage(): string {
    return this.error.message;
  }
}

export default BackendErrorException;
