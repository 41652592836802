import IForm from 'src/types/IForm';

function getChangedFormFields(form1: IForm = {}, form2: IForm = {}): string[] {
  // Return list of properties of `form1` that have been changed in `form2`
  return Object.keys(form1).filter(
    field => form1[field] !== form2[field] && form2[field] !== undefined,
  );
}

export default getChangedFormFields;
