import { BACKEND } from 'src/constants/backends';
import IEnvironment from 'src/types/IEnvironment';

const env = process.env as any as IEnvironment;

function getApiBaseUrlForRegion(region: string): string {
  if (region === 'us') {
    return env.REACT_APP_API_BASE_URL_US;
  }
  return env.REACT_APP_API_BASE_URL_EU;
}

/**
 * Removes duplicate regions from the given list of regions.
 * Before removing duplicates, it resolves each region to it's bundle API URL.
 * This exists mostly to filter out duplicate backends on `dev` and `qa` where
 * we only have one backend for all regions.
 *
 * @param regions
 */
function removeDuplicateBackendRegions(regions: BACKEND[]): BACKEND[] {
  const apiBaseUrls: string[] = [];
  return regions.filter(region => {
    const url = getApiBaseUrlForRegion(region);
    if (apiBaseUrls.includes(url)) {
      return false;
    }
    apiBaseUrls.push(url);
    return true;
  });
}

export default removeDuplicateBackendRegions;
