import { LocationContext } from '@gatsbyjs/reach-router';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  PARAM_BUNDLE_SUPPORT_EMAIL,
  PARAM_BUNDLE_SUPPORT_PHONE,
} from 'src/constants/bundleParameters';
import { COUNTRY_US, SUPPORTED_COUNTRIES } from 'src/constants/countries';
import legal from 'src/constants/legal';
import support from 'src/constants/support';
import getBundleParameter from 'src/lib/getBundleParameter';
import getLocaleFromPathname from 'src/lib/getLocaleFromPathname';
import { getSelectedBundle } from 'src/selectors/bundleSelectors';
import { getActiveStep } from 'src/selectors/flowSelectors';
import IBundle from 'src/types/IBundle';
import IStep from 'src/types/IStep';
import IStoreState from 'src/types/IStoreState';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import Translate from '../Translate/Translate';
import withLocation from '../withLocation/withLocation';
import CallSupport from './CallSupport';
import CookieSettings from './CookieSettings';
import css from './Footer.module.scss';
import ImprintLink from './ImprintLink';
import PrivacyPolicyLink from './PrivacyPolicyLink';
import TCsLink from './TCsLink';
import WriteSupport from './WriteSupport';

interface IStateProps {
  step?: IStep;
  bundle?: IBundle;
}

export interface IProps extends IStateProps, LocationContext {}

export function Footer({ location, step, bundle }: IProps) {
  const [language, country] = React.useMemo(
    () => getLocaleFromPathname(location.pathname),
    [location.pathname],
  );
  const supportedCountry = SUPPORTED_COUNTRIES.includes(country!) && country;

  const currentYear = new Date().getFullYear();
  const supportDefaults = support[supportedCountry || 'default'];
  const phone =
    getBundleParameter(PARAM_BUNDLE_SUPPORT_PHONE, bundle) ||
    supportDefaults.phone;
  const email =
    getBundleParameter(PARAM_BUNDLE_SUPPORT_EMAIL, bundle) ||
    supportDefaults.email;

  const tcsUrl = legal[supportedCountry || COUNTRY_US](language).tcs;
  const privacyPolicyUrl =
    legal[supportedCountry || COUNTRY_US](language).privacyPolicyApp;

  return (
    <footer role="contentinfo" className={css.footer}>
      <div className={css.container}>
        <div className={css.innerContent}>
          <h2>
            <Translate t="footer.headline" />
          </h2>
          <div className={css.support}>
            <Translate t="footer.happyToHelp" />
            <ul>
              <CallSupport country={country} bundle={bundle} />
              <li>
                <WriteSupport country={country} bundle={bundle} />
              </li>
            </ul>
          </div>
        </div>
        {step?.footerLegalNotice && (
          <p className={css.legalNotice}>
            <Translate
              requiresRoleText={false}
              t={step.footerLegalNotice}
              ignoreIfEmpty={true}
              textId={'legalText'}
              variables={{
                currentYear,
                email,
                phone,
                privacyPolicyUrl,
                tcsUrl,
              }}
              html={true}
            />
          </p>
        )}
        <div className={css.innerContent}>
          <LanguageSelector />
        </div>
        <hr className={css.line} />
        <div className={css.innerContent}>
          <ul className={css.links}>
            <TCsLink country={country} />
            <li className={css.linkSpacer} aria-hidden={true} />
            <PrivacyPolicyLink country={country} />
            <li className={css.linkSpacer} aria-hidden={true} />
            <ImprintLink country={country} />
            <li className={css.linkSpacer} aria-hidden={true} />
            <CookieSettings />
          </ul>
        </div>
      </div>
    </footer>
  );
}

export function mapStateToProps(state: IStoreState): IStateProps {
  return {
    bundle: getSelectedBundle(state),
    step: getActiveStep(state),
  };
}

export default connect(mapStateToProps)(withLocation(Footer));
