import * as React from 'react';
import parsePrice from 'src/lib/parsePrice';
import IBundleGroup from 'src/types/IBundleGroup';
import withLocale, { IWithLocaleProps } from '../withLocale/withLocale';
import css from './BundlePrice.module.scss';

export interface IProps extends IWithLocaleProps {
  group: IBundleGroup;
  className?: string;
}

export function BundlePrice({
  country,
  group,
  className,
}: Readonly<IProps>): React.ReactElement | null {
  if (!country) {
    return null;
  }

  const parsed = parsePrice({ country, price: group.activePrice });
  return (
    <span className={className} role="text">
      {parsed.values.map(item => (
        <span key={item.type} className={css[item.type]}>
          {item.value}
        </span>
      ))}
    </span>
  );
}

export default withLocale(BundlePrice);
