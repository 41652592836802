import { Location } from '@gatsbyjs/reach-router';
import * as React from 'react';
import { SUPPORTED_COUNTRY } from 'src/constants/countries';
import { LANGUAGE } from 'src/constants/languages';
import getLocaleFromPathname from 'src/lib/getLocaleFromPathname';

export interface IWithLocaleProps {
  country?: SUPPORTED_COUNTRY;
  language?: LANGUAGE;
}

function withLocale<TWrappedComponentProps extends IWithLocaleProps>(
  WrappedComponent: React.ComponentType<TWrappedComponentProps>,
) {
  const comp = (props: TWrappedComponentProps) => (
    <Location>
      {({ location }) => {
        const [language, country] = getLocaleFromPathname(location.pathname);
        return (
          <WrappedComponent
            {...(props as any)}
            language={props.language ?? language}
            country={props.country ?? country}
          />
        );
      }}
    </Location>
  );
  comp.displayName = `withLocale(${
    WrappedComponent.displayName ?? WrappedComponent.name
  })`;
  return comp;
}

export default withLocale;
