import IBundle from 'src/types/IBundle';

export interface IBundleParsedParameters {
  showPromotion?: boolean;
}

export function parseBundleParameters(
  bundle?: IBundle,
): IBundleParsedParameters {
  if (!bundle) {
    return {
      showPromotion: false,
    };
  }

  const parameters = bundle?.parameters ?? [];
  const parsed: IBundleParsedParameters = {};

  for (const { key, value } of parameters) {
    parsed[key] = value;
  }

  parsed.showPromotion = !!parsed['bundle.promotion'];

  return parsed;
}
