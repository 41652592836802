import cn from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import LinkButton from 'src/components/LinkButton/LinkButton';
import modelBundleGroup from 'src/lib/models/modelBundleGroup';
import {
  getBundleGroupDynamic,
  getSelectedBundle,
} from 'src/selectors/bundleSelectors';
import IStoreState from 'src/types/IStoreState';
import Overlay from '../Overlay/Overlay';
import Translate from '../Translate/Translate';
import css from './ReimbursementOverlay.module.scss';

export interface IProps {
  children: any;
  className?: string;
}

export function ReimbursementOverlay({
  children,
  className,
}: Readonly<IProps>) {
  const [visibility, setVisibility] = React.useState(false);
  const bundleGroup = useSelector((state: IStoreState) => {
    const autoGroup = getBundleGroupDynamic(state);
    if (autoGroup) {
      return autoGroup;
    }

    return getSelectedBundle(state)?.groups[0];
  });

  const onClose = () => setVisibility(false);
  const openOverlay = (event: any): void => {
    event.preventDefault();
    setVisibility(true);
  };
  const groupModel = React.useMemo(
    () => (bundleGroup ? modelBundleGroup(bundleGroup) : undefined),
    [bundleGroup],
  );

  if (!groupModel) {
    return null;
  }

  const titleTransKey = `reimbursement.overlay.title.`;
  const bundleType = groupModel.getBundleGroupType();

  if (!bundleType) {
    return null;
  }

  return (
    <>
      <LinkButton className={cn(className, css.link)} onClick={openOverlay}>
        {children}
      </LinkButton>
      <Overlay show={visibility} onClose={onClose}>
        <div className={css.container}>
          <h2>
            <Translate
              t={titleTransKey + bundleType}
              altKey={`reimbursement.overlay.title`}
            />
          </h2>
          <br />
          <Translate t={`reimbursement.overlay.text`} html={true} />
        </div>
      </Overlay>
    </>
  );
}

export default ReimbursementOverlay;
