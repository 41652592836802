/* istanbul ignore file */

import * as React from 'react';

export interface IFieldMask {
  placeholder: string;
  auto?: boolean;
  length?: number;
  range?: [number, number];
  type?: 'number' | 'char';
}

interface IFieldMaskExtended extends IFieldMask {
  index: number;
}

interface IFieldMaskError {
  field: string;
  code: 'OUT_OF_RANGE_MAX' | 'OUT_OF_RANGE_MIN' | 'NOT_COMPLETE';
}

interface IFieldMaskOptions {
  value?: string;
}

export default class FieldMask {
  public hasMask = false;

  private value: string;
  private selectStart: number;
  private readonly valueCache: string[];
  private readonly mask: IFieldMaskExtended[];

  constructor(mask?: IFieldMask[], options?: IFieldMaskOptions) {
    if (!mask) {
      return;
    }

    this.value = options?.value ?? '';
    this.selectStart = 0;
    this.valueCache = [''];
    this.mask = this.extendMask(mask);
    this.hasMask = true;
  }

  public readonly getValue = () => this.value;

  public readonly getSelectStart = () => this.selectStart;

  public readonly handleFocus = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!this.mask) {
      return;
    }

    const target = event.target as HTMLInputElement;
    const value = target.value;
    this.valueCache.push(value);
    this.selectStart = event.target.selectionStart ?? 0;
  };

  public readonly updateValue = (
    incomingValue: string,
    selectStart: number | null = null,
  ): void => {
    if (!this.mask) {
      return;
    }

    this.selectStart = selectStart ?? incomingValue.length;

    let newValue = this.rebuildAndValidateValue(incomingValue);
    newValue = this.handleAutoPlaceholderDelete(newValue, incomingValue);
    newValue = this.removeAutoPlaceholders(newValue);
    newValue = this.insertAutoPlaceholders(newValue);

    this.valueCache.push(newValue);
    this.value = newValue;
  };

  public readonly handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!this.mask) {
      return;
    }

    const target = event.target as HTMLInputElement;
    const value = target.value;
    this.updateValue(value, event.target.selectionStart);
  };

  public readonly validate = (): IFieldMaskError[] => {
    const errors: IFieldMaskError[] = [];
    const value = this.value;

    if (!value) {
      return [];
    }

    let charIndex = 0;
    for (const part of this.mask) {
      let partChars = '';
      const partLength = part.placeholder.length;

      for (let i = 0; i < partLength; i++) {
        const char = charIndex < value.length ? value[charIndex] : '';

        if (this.charMatchesType(part.type, char)) {
          partChars += char;
        }

        charIndex++;
      }

      const error = this.validatePart(part, partLength, partChars);
      if (error) {
        errors.push(error);
      }
    }

    return errors;
  };

  public readonly mapToMask = (mask: IFieldMask[]): string => {
    const errors = this.validate();
    if (errors.length > 0) {
      throw new Error(`Value is not valid: ${this.value}`);
    }
    if (this.value.length === 0) {
      return this.value;
    }

    const parsed = this.parse();

    let newMaskValue = '';
    for (const part of mask) {
      if (part.auto) {
        newMaskValue += part.placeholder;
      } else {
        newMaskValue += parsed[part.placeholder];
      }
    }

    return newMaskValue;
  };

  private readonly validatePart = (
    part: IFieldMaskExtended,
    partLength: number,
    partChars: string,
  ): IFieldMaskError | null => {
    if (!part.auto) {
      if (partChars.length !== partLength) {
        return { field: part.placeholder, code: 'NOT_COMPLETE' };
      } else if (part.type === 'number' && part.range) {
        const min = part.range[0];
        const max = part.range[1];
        const parsedValue = parseInt(partChars, 10);

        if (parsedValue < min) {
          return { field: part.placeholder, code: 'OUT_OF_RANGE_MIN' };
        } else if (parsedValue > max) {
          return { field: part.placeholder, code: 'OUT_OF_RANGE_MAX' };
        }
      }
    }
    return null;
  };

  private readonly charMatchesType = (type: string | undefined, char: string) =>
    type === undefined ||
    (type === 'number' && /\d/i.exec(char)) ||
    (type === 'char' && /\D/i.exec(char));

  private readonly parse = (): Record<string, string> => {
    const placeholderValueMap: Record<string, string> = {};

    let i = 0;
    for (const part of this.mask) {
      if (!part.auto) {
        placeholderValueMap[part.placeholder] = this.value.substring(
          i,
          i + part.placeholder.length,
        );
      }
      i += part.placeholder.length;
    }

    return placeholderValueMap;
  };

  private readonly handleAutoPlaceholderDelete = (
    value: string,
    incomingValue: string,
  ): string => {
    let newValue = value;
    const prevValue = this.valueCache[this.valueCache.length - 1];

    if (newValue === prevValue && newValue.length > incomingValue.length) {
      let changeAtPosition = 0;
      for (let i = 0; i < newValue.length; i++) {
        if (newValue[i] === incomingValue[i]) {
          changeAtPosition++;
        } else {
          break;
        }
      }

      const removedPlaceholder = this.getPlaceholderAtIndex(changeAtPosition);
      // istanbul ignore else
      if (removedPlaceholder) {
        newValue =
          newValue.substring(0, changeAtPosition - 1) +
          newValue.substring(
            changeAtPosition - 1 + removedPlaceholder.placeholder.length,
          );
        this.selectStart -= removedPlaceholder.placeholder.length;
      }
    }
    return newValue;
  };

  private readonly insertAutoPlaceholders = (value: string): string => {
    let newValue = value;
    // insert and append the auto placeholders
    for (const part of this.mask) {
      const { placeholder, index } = part;

      const isPlaceholder =
        newValue.substring(index, index + placeholder.length) === placeholder;
      if (part.auto) {
        if (index < newValue.length && !isPlaceholder) {
          newValue =
            newValue.substring(0, index) +
            placeholder +
            newValue.substring(index);
        }

        if (index === newValue.length) {
          newValue += placeholder;
          this.selectStart = newValue.length;
        }
      }
    }
    return newValue;
  };

  private readonly getPlaceholderAtIndex = (
    position: number,
  ): IFieldMask | null => {
    let charIndex = 0;
    let found: IFieldMask | null = null;
    for (const part of this.mask) {
      const partLength = part.placeholder.length;

      for (let i = 0; i < partLength; i++) {
        if (charIndex === position) {
          found = part;
        }

        charIndex++;
      }
    }

    return found;
  };

  private readonly getPaddedStart = (
    incomingValue: string,
    part: IFieldMaskExtended,
    partChars: string,
  ): string => {
    const atEndOfInput = incomingValue.length === this.selectStart;
    const partLength = part.placeholder.length;

    if (atEndOfInput && part.range && part.type === 'number' && partChars) {
      const partCharsLength = partChars.length;
      if (partCharsLength < partLength) {
        const min = part.range[0];
        const max = part.range[1];

        const paddedEndLow = partChars.padEnd(partLength, '0');
        const parsedPaddedEndLow = parseInt(paddedEndLow, 10);
        const paddedStart = partChars.padStart(partLength, '0');
        const parsedPaddedStart = parseInt(paddedStart, 10);

        if (parsedPaddedEndLow > max && parsedPaddedStart > min) {
          return paddedStart;
        }
      }
    }

    return partChars;
  };

  private readonly rebuildAndValidateValue = (
    incomingValue: string,
  ): string => {
    let newValue = '';
    let charIndex = 0;

    for (const part of this.mask) {
      let partChars = '';
      const partLength = part.placeholder.length;

      for (let i = 0; i < partLength; i++) {
        const char =
          charIndex < incomingValue.length ? incomingValue[charIndex] : '';

        if (this.charMatchesType(part.type, char)) {
          partChars += char;
        }

        charIndex++;
      }

      partChars = this.getPaddedStart(incomingValue, part, partChars);

      if (partChars) {
        newValue += partChars;
      }
    }

    newValue = this.insertAutoPlaceholders(newValue);
    return newValue;
  };

  private readonly removeAutoPlaceholders = (value: string): string => {
    let cleanValue = value;

    for (const part of this.mask) {
      if (part.auto) {
        cleanValue = cleanValue.replace(part.placeholder, '');
      }
    }
    return cleanValue;
  };

  private readonly extendMask = (mask: IFieldMask[]): IFieldMaskExtended[] => {
    let i = 0;
    return mask.map(part => {
      const extended = {
        ...part,
        index: i,
      };

      i += part.placeholder.length;
      return extended;
    });
  };
}
