import IBundle from 'src/types/IBundle';
import IBundleGroup from 'src/types/IBundleGroup';
import IBundleGroupPrice from 'src/types/IBundleGroupPrice';
import { getParsedBundleProperties } from './bundleProperties';

function getIsActive(now: number, price: IBundleGroupPrice): boolean {
  const { activeFrom, activeTo } = price;

  // check if price has begun
  if (activeFrom && now < activeFrom) {
    return false;
  }

  // check if price has ended
  if (activeTo && now > activeTo) {
    return false;
  }

  return true;
}

export function parseBundleGroup(inputGroup: IBundleGroup): IBundleGroup {
  const group = { ...inputGroup };

  const now = Math.floor(Date.now() / 1000); // get timestamp without ms

  // convert prices into array
  if (!Array.isArray(group.prices)) {
    group.prices = [group.prices];
  }

  // parse prices
  for (const price of group.prices) {
    // get time frame where price is active
    const isActive = getIsActive(now, price);

    if (isActive) {
      // check if there is already a active price
      /* istanbul ignore else */
      if (!group.activePrice) {
        // assign this price as active price
        group.activePrice = price;
      } else if (group.activePrice) {
        // if there is a active price and this price is applicable then choose the one with the later `activeFrom` date
        const prevActiveFrom = group.activePrice.activeFrom ?? 0;
        if (prevActiveFrom < (price.activeFrom || 0)) {
          group.activePrice = price;
        }
      }
    }
  }

  return group;
}

export function parseBundle(item: IBundle): IBundle {
  // convert groups into array
  if (!Array.isArray(item.groups)) {
    item.groups = [item.groups].filter(e => e);
  }

  // parse groups
  item.groups = item.groups.map(parseBundleGroup).map(group => {
    group.bundleCountry = item.country;
    return group;
  });

  // parse properties
  item.propertiesParsed = getParsedBundleProperties(item);

  return item;
}
