/* istanbul ignore file */

import { COUNTRY_DE } from './countries';
import { LANGUAGE, LANGUAGE_DE, LANGUAGE_EN } from './languages';

const countryLanguageMapping: { [country: string]: LANGUAGE[] } = {
  [COUNTRY_DE]: [LANGUAGE_DE, LANGUAGE_EN],
};

export default countryLanguageMapping;
