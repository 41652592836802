import * as React from 'react';
import { SUPPORTED_COUNTRY } from 'src/constants/countries';
import Translate from '../Translate/Translate';

export interface IProps {
  country: SUPPORTED_COUNTRY;
}

function BundleCountryRestriction({
  country,
}: Readonly<IProps>): React.ReactElement {
  return (
    <Translate
      t={`bundleProduct.countryRestriction.${country.toLowerCase()}`}
    />
  );
}

export default BundleCountryRestriction;
