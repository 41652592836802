import { BACKEND } from 'src/constants/backends';
import IStoreState from 'src/types/IStoreState';
import BackendClient from './apiClient/BackendClient';

function backendRegionsFromState(state: IStoreState): BACKEND[] {
  return state.flow.backendRegions && state.flow.backendRegions.length > 0
    ? state.flow.backendRegions
    : [BackendClient.getInstance().region];
}

export default backendRegionsFromState;
