import { COUNTRY_US } from 'src/constants/countries';
import { ROCHE_GMBH_DE, ROCHE_LTD_ZA } from 'src/constants/sellers';
import IAddress from 'src/types/IAddress';

interface IBundleOverride {
  [bundleCode: string]: {
    showInfoNotice?: boolean;
    sellerAddress?: IAddress;
    insuranceGroupIdMap?: {
      [insurance: string]: string;
    };
  };
}

const bundleOverrides: IBundleOverride = {
  us_bundle_3: {
    showInfoNotice: true,
  },

  roche_bundle_us_1: {
    insuranceGroupIdMap: {
      kaiser: '35200',
    },
  },

  zdg_bundle_1: { sellerAddress: ROCHE_GMBH_DE },
  schwenninger_bundle_1: { sellerAddress: ROCHE_GMBH_DE },
  aok_nordwest_bundle_1: { sellerAddress: ROCHE_GMBH_DE },
  inter_bundle_1: { sellerAddress: ROCHE_GMBH_DE },
  'south-africa_affiliate_(coaching_bundle)_2': { sellerAddress: ROCHE_LTD_ZA },
};

export const countryDefaultBundleCode: { [country: string]: string } = {
  [COUNTRY_US]: 'us_bundle_3',
};

export default bundleOverrides;
