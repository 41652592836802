/* istanbul ignore file */

export const BundleItemOrderContent = [
  'TEST_STRIPS_SUBSCRIPTION',
  'COACH_SUBSCRIPTION',
  'PRO_SUBSCRIPTION',
  'LANCETS',
  'LANCETS_SOFTCLIX',
  'CUFF',
  'WEIGHT_SCALE',
  'LANCING_KIT',
  'LANCING_KIT_SOFTCLIX',
  'GUIDE',
  'GUIDE_ME',
];
export type BundleItemOrderContent = typeof BundleItemOrderContent;

export const BundleItemOrderGroupSelection = [
  'COACH_SUBSCRIPTION',
  'GUIDE',
  'GUIDE_ME',
  'TEST_STRIPS_SUBSCRIPTION',
  'PRO_SUBSCRIPTION',
  'WEIGHT_SCALE',
];
export type BundleItemOrderGroupSelection =
  typeof BundleItemOrderGroupSelection;

export const BundleItemOrderDescription = [
  'GUIDE',
  'GUIDE_ME',
  'COACH_SUBSCRIPTION',
  'PRO_SUBSCRIPTION',
  'TEST_STRIPS_SUBSCRIPTION',
];
export type BundleItemOrderDescription = typeof BundleItemOrderDescription;

export const BundleItemsFeaturedInShop = [
  'TEST_STRIPS_SUBSCRIPTION',
  'COACH_SUBSCRIPTION',
  'PRO_SUBSCRIPTION',
  'LANCETS',
  'LANCETS_SOFTCLIX',
  'CUFF',
  'WEIGHT_SCALE',
  'LANCING_KIT',
  'LANCING_KIT_SOFTCLIX',
  'GUIDE',
  'GUIDE_ME',
];
export type BundleItemsFeaturedInShop = typeof BundleItemsFeaturedInShop;

export const BundleItemOrderImmediates = [
  'PRO_SUBSCRIPTION',
  'COACH_SUBSCRIPTION',
];
export type BundleItemOrderImmediates = typeof BundleItemOrderImmediates;

export const BundleItemOrderSubscriptions = [
  'TEST_STRIPS_SUBSCRIPTION',
  'COACH_SUBSCRIPTION',
];
export type BundleItemOrderSubscriptions = typeof BundleItemOrderSubscriptions;

export const BundleItemOrderProducts = [
  'TEST_STRIPS',
  'GUIDE',
  'GUIDE_ME',
  'LANCETS',
  'LANCETS_SOFTCLIX',
  'LANCING_KIT',
  'LANCING_KIT_SOFTCLIX',
  'CUFF',
  'WEIGHT_SCALE',
];
export type BundleItemOrderProducts = typeof BundleItemOrderProducts;
