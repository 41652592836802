import classnames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  dismissNotificationAction,
  hideNotificationAction,
  NOTIFICATION_STYLE_ERROR,
  NOTIFICATION_STYLE_SUCCESS,
} from 'src/actions/notificationActions';
import {
  ITranslateProps,
  translateAction,
} from 'src/actions/translationActions';
import { getNotificationById } from 'src/selectors/notificationSelectors';
import INotification from 'src/types/INotification';
import IStoreState from 'src/types/IStoreState';
import css from './Notification.module.scss';
import closeIcon from '../../icons/close.svg';

interface IStateProps {
  notification?: INotification;
}

interface IDispatchProps {
  onDismiss: (event: React.SyntheticEvent) => void;
  onHide: () => void;
}

interface IOwnProps {
  id: number;
}

export interface IProps extends IDispatchProps, IOwnProps, IStateProps {}

export class Notification extends React.PureComponent<
  IProps & ITranslateProps
> {
  public componentDidMount() {
    const { notification, onHide } = this.props;
    if (notification && notification.style === NOTIFICATION_STYLE_SUCCESS) {
      setTimeout(onHide, 3000);
    }
  }

  public render() {
    const { notification, onDismiss } = this.props;

    if (!notification || notification.visible === false) {
      return null;
    }

    return (
      <div
        className={classnames(css.container, {
          [css.success]: notification.style === NOTIFICATION_STYLE_SUCCESS,
          [css.error]: notification.style === NOTIFICATION_STYLE_ERROR,
        })}
      >
        <div className={css.message} role="status" aria-live="assertive">
          {notification.message}
        </div>
        <button className={css.dismiss} type="button" onClick={onDismiss}>
          <img
            src={closeIcon}
            alt={this.props.translate('notifications.dismissNotification')}
          />
        </button>
      </div>
    );
  }
}

export function mapStateToProps(
  state: IStoreState,
  ownProps: IOwnProps,
): IStateProps {
  return {
    notification: getNotificationById(ownProps.id)(state),
  };
}

export function mapDispatchToProps(
  dispatch: any,
  ownProps: IOwnProps,
): IDispatchProps & ITranslateProps {
  return {
    onDismiss: (event: React.SyntheticEvent) => {
      event.preventDefault();
      dispatch(dismissNotificationAction(ownProps.id));
    },
    onHide: () => dispatch(hideNotificationAction(ownProps.id)),
    translate: (key: string, values?: Record<string, any>) =>
      dispatch(translateAction(key, values)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
