import { LocationContext } from '@gatsbyjs/reach-router';
import * as React from 'react';
import { useSelector } from 'react-redux';
import Loader from 'src/components/Loader/Loader';
import { PARAM_BUNDLE_SUPPORT_EMAIL } from 'src/constants/bundleParameters';
import { SUPPORTED_COUNTRIES } from 'src/constants/countries';
import support, { SUPPORT_EMAIL_DEFAULT } from 'src/constants/support';
import getBundleParameter from 'src/lib/getBundleParameter';
import getLocaleFromPathname from 'src/lib/getLocaleFromPathname';
import { getBundleDynamic } from 'src/selectors/bundleSelectors';
import IStoreState from 'src/types/IStoreState';
import Translate from '../Translate/Translate';
import withLocation from '../withLocation/withLocation';
import notFoundImg from './NotFound.svg';
import css from './NotFoundPage.module.scss';

export interface IProps extends LocationContext {
  default?: boolean;
  delay?: boolean;
}

export function NotFoundPage({ location, delay }: Readonly<IProps>) {
  const [showLoading, setShowLoading] = React.useState(Boolean(delay));
  const { bundle } = useSelector((state: IStoreState) => ({
    bundle: getBundleDynamic(state),
  }));

  const pathname = location.pathname;

  const [, country] = React.useMemo(
    () => getLocaleFromPathname(pathname),
    [pathname],
  );

  setTimeout(() => {
    if (showLoading) {
      setShowLoading(false);
    }
  }, 1000);

  const fallbackEmail =
    (country &&
      SUPPORTED_COUNTRIES.includes(country) &&
      support[country].email) ||
    SUPPORT_EMAIL_DEFAULT;

  if (showLoading) {
    return <Loader />;
  }

  return (
    <div className={css.container}>
      <main className={css.content}>
        <div className={css.text}>
          <h1>
            <Translate t="notFound.title" />
          </h1>
          <p className={css.lead}>
            <Translate t="notFound.lead" />
          </p>
          <p>
            <Translate
              t="notFound.text"
              html={true}
              variables={{
                email:
                  getBundleParameter(PARAM_BUNDLE_SUPPORT_EMAIL, bundle) ||
                  fallbackEmail,
              }}
            />
          </p>
        </div>
        <div className={css.imageWrapper}>
          <img
            src={notFoundImg}
            className={css.image}
            alt="Illustration of a cute but extinct dinosaur"
          />
        </div>
      </main>
    </div>
  );
}

export default withLocation(NotFoundPage);
