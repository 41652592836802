export const INPUT_FOCUS_ACTION = 'INPUT_FOCUS_ACTION';
export type INPUT_FOCUS_ACTION = typeof INPUT_FOCUS_ACTION;

export const INPUT_BLUR_ACTION = 'INPUT_BLUR_ACTION';
export type INPUT_BLUR_ACTION = typeof INPUT_BLUR_ACTION;

export const ADD_SPACING_ACTION = 'ADD_SPACING_ACTION';
export type ADD_SPACING_ACTION = typeof ADD_SPACING_ACTION;

export interface IInputFocusAction {
  type: INPUT_FOCUS_ACTION;
  form: string;
  field: string;
}

export interface IInputBlurAction {
  type: INPUT_BLUR_ACTION;
  form: string;
  field: string;
}

export interface IAddSpacingAction {
  type: ADD_SPACING_ACTION;
  field: string;
  spacing: number;
}

export type FormAction =
  | IInputFocusAction
  | IInputBlurAction
  | IAddSpacingAction;

export function inputFocusAction(
  form: string,
  field: string,
): IInputFocusAction {
  return { type: INPUT_FOCUS_ACTION, form, field };
}

export function inputBlurAction(form: string, field: string): IInputBlurAction {
  return { type: INPUT_BLUR_ACTION, form, field };
}

export function addSpacingAction(
  field: string,
  spacing: number,
): IAddSpacingAction {
  return { type: ADD_SPACING_ACTION, field, spacing };
}
