import { ATTRIBUTE_KEY } from 'src/constants/bundleGroupAttributes';
import IBundleGroup from 'src/types/IBundleGroup';
import { parseBundleGroupAttributes } from './parseBundleGroupAttributes';

const getBundleGroupAttribute = (
  attribute: ATTRIBUTE_KEY,
  bundleGroup?: IBundleGroup,
) => {
  const params = parseBundleGroupAttributes(bundleGroup);
  return params[attribute] || '';
};

export default getBundleGroupAttribute;
