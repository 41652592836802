export const NOTIFICATION_STYLE_SUCCESS = 'NOTIFICATION_STYLE_SUCCESS';
export type NOTIFICATION_STYLE_SUCCESS = typeof NOTIFICATION_STYLE_SUCCESS;

export const NOTIFICATION_STYLE_ERROR = 'NOTIFICATION_STYLE_ERROR';
export type NOTIFICATION_STYLE_ERROR = typeof NOTIFICATION_STYLE_ERROR;

export type NOTIFICATION_STYLE =
  | NOTIFICATION_STYLE_SUCCESS
  | NOTIFICATION_STYLE_ERROR;

export const SHOW_NOTIFICATION_ACTION = 'SHOW_NOTIFICATION_ACTION';
export type SHOW_NOTIFICATION_ACTION = typeof SHOW_NOTIFICATION_ACTION;

export const DISMISS_NOTIFICATION_ACTION = 'DISMISS_NOTIFICATION_ACTION';
export type DISMISS_NOTIFICATION_ACTION = typeof DISMISS_NOTIFICATION_ACTION;

export const HIDE_NOTIFICATION_ACTION = 'HIDE_NOTIFICATION_ACTION';
export type HIDE_NOTIFICATION_ACTION = typeof HIDE_NOTIFICATION_ACTION;

export interface IShowNotificationAction {
  type: SHOW_NOTIFICATION_ACTION;
  message: string;
  style: NOTIFICATION_STYLE;
}

export interface IDismissNotificationAction {
  type: DISMISS_NOTIFICATION_ACTION;
  id: number;
}

export interface IHideNotificationAction {
  type: HIDE_NOTIFICATION_ACTION;
  id: number;
}

export type NotificationAction =
  | IShowNotificationAction
  | IDismissNotificationAction
  | IHideNotificationAction;

export function showNotificationAction(
  message: string,
  style: NOTIFICATION_STYLE,
): IShowNotificationAction {
  return { type: SHOW_NOTIFICATION_ACTION, message, style };
}

export function dismissNotificationAction(
  id: number,
): IDismissNotificationAction {
  return { type: DISMISS_NOTIFICATION_ACTION, id };
}

export function hideNotificationAction(id: number): IHideNotificationAction {
  return { type: HIDE_NOTIFICATION_ACTION, id };
}
