import { LocationContext } from '@gatsbyjs/reach-router';
import classnames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { translateAction } from 'src/actions/translationActions';
import { voucherCodeInputAction } from 'src/actions/voucherActions';
import Button from 'src/components/Button/Button';
import { validateVoucherFlow } from 'src/flows/voucherFlows';
import focusErrorFields from 'src/lib/focusErrorFields';
import getLocaleFromPathname from 'src/lib/getLocaleFromPathname';
import isBundleAvailable from 'src/lib/isBundleAvailable';
import modelBundle from 'src/lib/models/modelBundle';
import { getVoucher } from 'src/selectors/voucherSelectors';
import IBundle from 'src/types/IBundle';
import IFormNotice from 'src/types/IFormNotice';
import IStoreState from 'src/types/IStoreState';
import FormField from '../FormField/FormField';
import Overlay from '../Overlay/Overlay';
import TextInput from '../TextInput/TextInput';
import Translate from '../Translate/Translate';
import withLocation from '../withLocation/withLocation';
import css from './VoucherForm.module.scss';
import renderMoreInfoButton from 'src/lib/renderMoreInfoButton';
import { ENROLLMENT_BARRIER_INFO_VOUCHER_CODE } from 'src/constants/customTextKeys';

const FORM_NAME = 'VOUCHER_FORM';

interface IStateProps {
  errors: IFormNotice[];
  voucherCode?: string;
}

interface IDispatchProps {
  validateVoucher: (voucherCode: string, bundle?: IBundle) => Promise<boolean>;
  translate: (key: string) => string;
  voucherCodeInput: (target: HTMLInputElement) => void;
}

export interface IProps extends IStateProps, IDispatchProps, LocationContext {
  bundle?: IBundle;
  buttonClassName?: string;
  buttonText: string;
  onSubmit: () => Promise<IFormNotice[]>;
}

export function VoucherForm({
  bundle,
  buttonClassName,
  buttonText,
  errors,
  onSubmit,
  validateVoucher,
  voucherCode,
  voucherCodeInput,
  translate,
  location,
}: Readonly<IProps>) {
  const [overlayVisible, setOverlayVisible] = React.useState(false);

  const showOverlay = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setOverlayVisible(true);
  };
  const hideOverlay = () => setOverlayVisible(false);

  const pathname = location.pathname;

  const [language] = React.useMemo(
    () => getLocaleFromPathname(pathname),
    [pathname],
  );

  const isBundle = !!bundle;
  // If the voucher activates a bundle, we need to make sure the bundle is available
  const bundleIsAvailable = !isBundle || (bundle && isBundleAvailable(bundle));
  const bundleModel = bundle && modelBundle(bundle);
  const voucherEnrollmentBarrierInfo = bundleModel?.getCustomStringValue(
    ENROLLMENT_BARRIER_INFO_VOUCHER_CODE,
    language,
  );

  const handleSubmit = React.useCallback(
    async ({ nativeEvent }: React.SyntheticEvent) => {
      nativeEvent.preventDefault();
      if (!bundleIsAvailable) {
        return;
      }

      const valid = await validateVoucher(voucherCode ?? '');

      if (valid) {
        nativeEvent.preventDefault();
        const currentErrors = await onSubmit();
        focusErrorFields(
          nativeEvent.target as HTMLFormElement,
          () => currentErrors,
        );
      }
    },
    [voucherCode],
  );

  return (
    <form
      className={classnames(css.form, { [css.onVoucherPage]: !isBundle })}
      onSubmit={handleSubmit}
      noValidate={true}
    >
      <FormField
        className={css.formField}
        errors={errors}
        formName={FORM_NAME}
        label="voucherForm.label"
        name="voucherCode"
        moreInfo={{
          hide: !bundle,
          type: 'icon',
          render: renderMoreInfoButton(
            translate('voucherForm.overlayButtonText'),
            showOverlay,
          ),
        }}
        warnings={[]}
      >
        <TextInput
          formName={FORM_NAME}
          name="voucherCode"
          pattern="\d*"
          onChange={voucherCodeInput}
          value={voucherCode ?? ''}
          error={errors.length > 0}
          disabled={!bundleIsAvailable}
          focusOnError={true}
          required={true}
        />
      </FormField>
      <div
        className={classnames(css.actions, { [css.onVoucherPage]: !isBundle })}
      >
        <Button
          className={classnames(buttonClassName, css.ctaButton)}
          large={true}
          secondary={true}
          type="submit"
          disabled={!bundleIsAvailable}
        >
          <Translate t={buttonText} />
        </Button>
      </div>
      {bundle?.payor?.code && (
        <Overlay show={overlayVisible} onClose={hideOverlay}>
          <div className={css.overlayContent}>
            <p>
              {' '}
              {voucherEnrollmentBarrierInfo || (
                <Translate
                  t={`voucherForm.overlayText.${bundle.payor.code}`}
                  altKey={'voucherForm.overlayText'}
                  html={true}
                />
              )}
            </p>
          </div>
        </Overlay>
      )}
    </form>
  );
}

export function mapStateToProps(state: IStoreState): IStateProps {
  const voucher = getVoucher(state);
  return {
    errors: voucher.errors ?? [],
    voucherCode: voucher.voucherCode,
  };
}

export function mapDispatchToProps(dispatch: any): IDispatchProps {
  return {
    translate: (key: string, variables?: Record<string, any>) =>
      dispatch(translateAction(key, variables)),
    validateVoucher: (voucherCode: string) =>
      dispatch(validateVoucherFlow(voucherCode)),
    voucherCodeInput: (target: HTMLInputElement) => {
      dispatch(voucherCodeInputAction(target.value));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocation(VoucherForm));
