export default function parseTranslationHtml(html: string): [string, boolean] {
  let parsed = html;
  const needsRoleText = /<strong/g.test(parsed);

  // add aria-hidden="true" to all line breaks to prevent reading text parts separately that are divided by the <br />
  parsed = parsed.replace(/<br ?\/?>/gi, '<br aria-hidden="true">');

  // replace <strong> with <b> because some screen readers dont read <strong> inside a tag with role="text"
  parsed = parsed.replace(/<strong>/gi, '<b>');
  parsed = parsed.replace(/<\/strong>/gi, '</b>');

  return [parsed, needsRoleText];
}
