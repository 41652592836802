import IAppUser from 'src/types/IAppUser';

export const SET_APP_USER_ACTION = 'SET_APP_USER_ACTION';
export type SET_APP_USER_ACTION = typeof SET_APP_USER_ACTION;

export interface ISetAppUserAction {
  type: SET_APP_USER_ACTION;
  appUser: IAppUser;
}

export type UserAction = ISetAppUserAction;

export function setAppUserAction(appUser: IAppUser): ISetAppUserAction {
  return { type: SET_APP_USER_ACTION, appUser };
}
