import IBackendError from 'src/types/IBackendError';

class OrderBundleError {
  private readonly error: IBackendError;

  constructor(error: IBackendError) {
    this.error = error;
  }

  get code() {
    return this.error.failureCode;
  }

  get message() {
    return this.error.failureMessage;
  }
}

export default OrderBundleError;
