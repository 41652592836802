import { LocationContext } from '@gatsbyjs/reach-router';
import * as queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { setInsuranceAction } from 'src/actions/bundleActions';
import { voucherCodeInputAction } from 'src/actions/voucherActions';
import { IInsurance } from 'src/types/IInsurance';
import withLocation from '../withLocation/withLocation';

interface IDispatchProps {
  onVoucherCodeInput: (voucherCode: string) => void;
  onInsuranceInput: (insurance: IInsurance) => void;
}

export interface IProps extends IDispatchProps, LocationContext {}

/**
 * Maps data from the query string to the app state
 *
 * For example, when the location contains the query string "?voucher-code=..."
 * it will `voucherCodeInputAction()` with the given voucher code.
 *
 * @param props
 */
export function MapQueryStringToState(props: IProps) {
  React.useEffect(() => {
    const queryParams = queryString.parse(props.location.search);

    const insurance = queryParams.insurance as IInsurance;
    const voucherCode = queryParams['voucher-code'] as string;

    if (voucherCode) {
      props.onVoucherCodeInput(voucherCode);
    }

    if (insurance) {
      props.onInsuranceInput(insurance);
    }
  }, [props.location.search]);

  return null;
}

export function mapDispatchToProps(dispatch: any): IDispatchProps {
  return {
    onInsuranceInput: (insurance: IInsurance) =>
      dispatch(setInsuranceAction(insurance)),
    onVoucherCodeInput: (voucherCode: string) =>
      dispatch(voucherCodeInputAction(voucherCode)),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(withLocation(MapQueryStringToState));
