import {
  CLEAR_INSURANCE_NUMBER_DATA_ACTION,
  IInsuranceNumberErrorAction,
  IInsuranceNumberInputAction,
  IInsuranceNumberSuccessAction,
  INSURANCE_NUMBER_ERROR_ACTION,
  INSURANCE_NUMBER_INPUT_ACTION,
  INSURANCE_NUMBER_SUCCESS_ACTION,
  InsuranceNumberAction,
} from 'src/actions/insuranceNumberActions';
import IInsuranceNumberState from 'src/types/IInsuranceNumberState';

export const initialState: IInsuranceNumberState = {
  errors: [],
  valid: false,
};

function handleInsuranceNumberInput(
  state: IInsuranceNumberState,
  action: IInsuranceNumberInputAction,
): IInsuranceNumberState {
  return { ...state, insuranceNumber: action.insuranceNumber, errors: [] };
}

function handleInsuranceNumberSuccess(
  state: IInsuranceNumberState,
  action: IInsuranceNumberSuccessAction,
): IInsuranceNumberState {
  return {
    ...state,
    errors: [],
    insuranceNumber: action.insuranceNumber,
    valid: true,
  };
}

function handleInsuranceNumberError(
  state: IInsuranceNumberState,
  action: IInsuranceNumberErrorAction,
): IInsuranceNumberState {
  return {
    ...state,
    errors: action.errors,
    insuranceNumber: action.insuranceNumber,
    valid: false,
  };
}

function handleClearInsuranceNumber() {
  return { ...initialState };
}

function insuranceNumberReducer(
  state: IInsuranceNumberState = initialState,
  action: InsuranceNumberAction | { type: null } = { type: null },
) {
  switch (action.type) {
    case INSURANCE_NUMBER_INPUT_ACTION:
      return handleInsuranceNumberInput(state, action);

    case INSURANCE_NUMBER_SUCCESS_ACTION:
      return handleInsuranceNumberSuccess(state, action);

    case INSURANCE_NUMBER_ERROR_ACTION:
      return handleInsuranceNumberError(state, action);

    case CLEAR_INSURANCE_NUMBER_DATA_ACTION:
      return handleClearInsuranceNumber();
  }

  return state;
}

export default insuranceNumberReducer;
