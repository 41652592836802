import {
  ACCEPT_COOKIES_ACTION,
  FlowAction,
  IHandleSetRDCPAuthFlags,
  ISetAcceptedCookies,
  ISetAppSpacingAction,
  ISetCurrentStepAction,
  ISetNonBundleFLowAction,
  ISetPathnameAction,
  ISetRedirectUrlAction,
  ISetSourceAction,
  ISetStepAction,
  IUpdateBackendRegionsAction,
  IUpdateFlowNameAction,
  IUpdateRecommendedRegionAction,
  SET_APP_SPACING_ACTION,
  SET_CURRENT_STEP_ACTION,
  SET_NON_BUNDLE_FLOW_ACTION,
  SET_PATHNAME_ACTION,
  SET_RDCP_AUTH_FLAGS_ACTION,
  SET_REDIRECT_URL,
  SET_SOURCE_ACTION,
  SET_STEP_ACTION,
  UPDATE_BACKEND_REGIONS_ACTION,
  UPDATE_FLOW_NAME_ACTION,
  UPDATE_RECOMMENDED_REGION_ACTION,
} from 'src/actions/flowActions';
import {
  CHANGE_BILLING_ADDRESS_ACTION,
  EDIT_SHIPMENT_ACTION,
  END_CHANGE_BILLING_ADDRESS_ACTION,
  END_CHANGE_PAYMENT_ACTION,
  END_EDIT_SHIPMENT_ACTION,
  START_CHANGE_PAYMENT_ACTION,
} from 'src/actions/overviewActions';
import {
  IRehydrationAction,
  REHYDRATE_ACTION,
} from 'src/actions/persistActions';
import { DISCARD_SHIPMENT_FORM_ACTION } from 'src/actions/shipmentActions';
import browserOnly from 'src/lib/browserOnly';
import { getCookie } from 'src/lib/cookie';
import IFlowState from 'src/types/IFlowState';
import FLOW_NAME from '../constants/flowName';

export const initialState: IFlowState = {
  acceptedCookies: browserOnly(
    () => getCookie('mysugr-cookie-banner-dismiss') === '1',
    true,
  ),
  appSpacing: { bottom: 0, left: 0, right: 0, top: 0 },
  backendRegions: [],
  recommendedRegion: undefined,
  completedSteps: [],
  currentStep: 1,
  isChangingBillingAddress: false,
  isChangingPayment: false,
  isEditingShipment: false,
  source: 'web',
  name: FLOW_NAME.none,
  pathname: browserOnly(() => location.pathname, ''),
  isRDCPLogin: false,
  isRDCPRegister: false,
  redirectUrl: null,
};

function handleSetCurrentStep(
  state: IFlowState,
  action: ISetCurrentStepAction,
): IFlowState {
  const appSpacing = action.step !== state.currentStep ? {} : state.appSpacing;
  return {
    ...state,
    currentStep: action.step,
    appSpacing,
  };
}

function handleSetStep(state: IFlowState, action: ISetStepAction): IFlowState {
  return {
    ...state,
    completedSteps: action.completedSteps,
    appSpacing: {},
  };
}

function handleSetAppSpacing(
  state: IFlowState,
  action: ISetAppSpacingAction,
): IFlowState {
  return { ...state, appSpacing: { ...action.spacing } };
}

function handleSetAcceptedCookies(
  state: IFlowState,
  action: ISetAcceptedCookies,
): IFlowState {
  return { ...state, acceptedCookies: action.acceptedCookies };
}

function handleEditShipment(state: IFlowState): IFlowState {
  return { ...state, appSpacing: {}, isEditingShipment: true };
}

function handleEndEditShipment(state: IFlowState): IFlowState {
  return { ...state, isEditingShipment: false };
}

function handleStartChangePayment(state: IFlowState): IFlowState {
  return { ...state, appSpacing: {}, isChangingPayment: true };
}

function handleEndChangePayment(state: IFlowState): IFlowState {
  return { ...state, isChangingPayment: false };
}

function handleChangingBillingAddress(state: IFlowState): IFlowState {
  return { ...state, appSpacing: {}, isChangingBillingAddress: true };
}

function handleEndChangeBillingAddress(state: IFlowState): IFlowState {
  return { ...state, isChangingBillingAddress: false };
}

function handleRehydrate(
  state: IFlowState,
  action: IRehydrationAction,
): IFlowState {
  if (action.payload?.flow) {
    // We don't want to restore the isChangingPayment and isEditingShipment state
    // when the app is reloading
    return {
      ...action.payload.flow,
      isChangingPayment: false,
      isEditingShipment: false,
    };
  }
  return state;
}

function handleSetSource(
  state: IFlowState,
  action: ISetSourceAction,
): IFlowState {
  return { ...state, source: action.source };
}

function handleUpdateBackendRegions(
  state: IFlowState,
  action: IUpdateBackendRegionsAction,
): IFlowState {
  return { ...state, backendRegions: action.regions };
}

function handleUpdateRecommendedRegion(
  state: IFlowState,
  action: IUpdateRecommendedRegionAction,
): IFlowState {
  return { ...state, recommendedRegion: action.region };
}

function handleSetNonBundleFlow(
  state: IFlowState,
  action: ISetNonBundleFLowAction,
) {
  return {
    ...state,
    nonBundleFlowConfig: action.nonBundleFlowConfig,
  };
}

function handleUpdateFlowName(
  state: IFlowState,
  action: IUpdateFlowNameAction,
) {
  return {
    ...state,
    name: action.name,
  };
}

function handleSetPathname(
  state: IFlowState,
  action: ISetPathnameAction,
): IFlowState {
  return {
    ...state,
    pathname: action.pathname,
  };
}

function handleSetRedirectUrl(
  state: IFlowState,
  action: ISetRedirectUrlAction,
): IFlowState {
  return {
    ...state,
    redirectUrl: action.redirectUrl,
  };
}

function handleSetRDCPAuthFlags(
  state: IFlowState,
  action: IHandleSetRDCPAuthFlags,
): IFlowState {
  const { isRDCPLogin, isRDCPRegister } = action;
  return {
    ...state,
    isRDCPLogin,
    isRDCPRegister,
  };
}

function flowReducer(
  state: IFlowState = initialState,
  action: FlowAction | { type: null } = { type: null },
) {
  switch (action.type) {
    case REHYDRATE_ACTION:
      return handleRehydrate(state, action);

    case SET_NON_BUNDLE_FLOW_ACTION:
      return handleSetNonBundleFlow(state, action);

    case SET_CURRENT_STEP_ACTION:
      return handleSetCurrentStep(state, action);

    case SET_STEP_ACTION:
      return handleSetStep(state, action);

    case SET_APP_SPACING_ACTION:
      return handleSetAppSpacing(state, action);

    case EDIT_SHIPMENT_ACTION:
      return handleEditShipment(state);

    case END_EDIT_SHIPMENT_ACTION:
      return handleEndEditShipment(state);

    case DISCARD_SHIPMENT_FORM_ACTION:
      return handleEndEditShipment(state);

    case CHANGE_BILLING_ADDRESS_ACTION:
      return handleChangingBillingAddress(state);

    case END_CHANGE_BILLING_ADDRESS_ACTION:
      return handleEndChangeBillingAddress(state);

    case START_CHANGE_PAYMENT_ACTION:
      return handleStartChangePayment(state);

    case END_CHANGE_PAYMENT_ACTION:
      return handleEndChangePayment(state);

    case ACCEPT_COOKIES_ACTION:
      return handleSetAcceptedCookies(state, action);

    case SET_SOURCE_ACTION:
      return handleSetSource(state, action);

    case UPDATE_BACKEND_REGIONS_ACTION:
      return handleUpdateBackendRegions(state, action);

    case UPDATE_FLOW_NAME_ACTION:
      return handleUpdateFlowName(state, action);

    case SET_PATHNAME_ACTION:
      return handleSetPathname(state, action);

    case UPDATE_RECOMMENDED_REGION_ACTION:
      return handleUpdateRecommendedRegion(state, action);

    case SET_RDCP_AUTH_FLAGS_ACTION:
      return handleSetRDCPAuthFlags(state, action);

    case SET_REDIRECT_URL:
      return handleSetRedirectUrl(state, action);
  }

  return state;
}

export default flowReducer;
