import IFormNotice from 'src/types/IFormNotice';

function removeError(
  errors: IFormNotice[],
  condition: { field?: string; message?: string },
): IFormNotice[] {
  return errors.filter(error => {
    let removeByField = false;
    let removeByMessage = false;
    if (condition.field !== undefined && condition.field === error.field) {
      removeByField = true;
    }
    if (
      condition.message !== undefined &&
      condition.message === error.message
    ) {
      removeByMessage = true;
    }
    return !(removeByField || removeByMessage);
  });
}

export default removeError;
