import { IFormLayout } from 'src/components/Form/Form';
import IFormNotice from 'src/types/IFormNotice';

function sortErrorsByLayout(
  errors: IFormNotice[],
  layout?: IFormLayout,
): IFormNotice[] {
  if (!layout) {
    return errors;
  }
  let sortedErrors: IFormNotice[] = [];
  layout.forEach(row => {
    row.fields.forEach(field => {
      const fieldErrors = errors.filter(error => error.field === field.name);
      sortedErrors = sortedErrors.concat(fieldErrors);
    });
  });
  return sortedErrors;
}

export default function focusErrorFields(
  form: HTMLFormElement,
  getErrors: IFormNotice[] | (() => IFormNotice[]),
  layout?: IFormLayout,
) {
  setTimeout(() => {
    const errors = sortErrorsByLayout(
      Array.isArray(getErrors) ? getErrors : getErrors() || [],
      layout,
    );

    if (errors.length && form) {
      const firstError = errors[0];
      const fieldName = firstError.options
        ? firstError.options.type
        : firstError.field;

      const firstErrorInput: HTMLInputElement | HTMLSelectElement | null =
        fieldName && form.querySelector(`[name=${fieldName}]`);

      /* istanbul ignore else */
      if (firstErrorInput) {
        firstErrorInput.focus();
      }
    }
  });
}
