/* istanbul ignore file */

export const PROPERTY_PHONE = 'phoneNumber';
export type PROPERTY_PHONE = typeof PROPERTY_PHONE;

export const PROPERTY_IP = 'ipAddress';
export type PROPERTY_IP = typeof PROPERTY_IP;

export const PROPERTY_LANGUAGE = 'language';
export type PROPERTY_LANGUAGE = typeof PROPERTY_LANGUAGE;

export const PROPERTY_VOUCHER = 'voucherCode';
export type PROPERTY_VOUCHER = typeof PROPERTY_VOUCHER;

export const PROPERTY_CARD_GROUP_ID = 'groupId';
export type PROPERTY_CARD_GROUP_ID = typeof PROPERTY_CARD_GROUP_ID;

export const PROPERTY_CARD_MEMBER_ID = 'memberId';
export type PROPERTY_CARD_MEMBER_ID = typeof PROPERTY_CARD_MEMBER_ID;

export const PROPERTY_INSURANCE_NUMBER = 'insuranceNumber';
export type PROPERTY_INSURANCE_NUMBER = typeof PROPERTY_INSURANCE_NUMBER;

export const PROPERTY_MEMBER_CLASS = 'memberClass';
export type PROPERTY_MEMBER_CLASS = typeof PROPERTY_MEMBER_CLASS;

export const PROPERTY_EMPLOYER = 'employer';
export type PROPERTY_EMPLOYER = typeof PROPERTY_EMPLOYER;

export type PROPERTY_KEY =
  | PROPERTY_EMPLOYER
  | PROPERTY_INSURANCE_NUMBER
  | PROPERTY_IP
  | PROPERTY_CARD_MEMBER_ID
  | PROPERTY_CARD_GROUP_ID
  | PROPERTY_VOUCHER
  | PROPERTY_LANGUAGE
  | PROPERTY_MEMBER_CLASS
  | PROPERTY_PHONE;
