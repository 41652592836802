import {
  PARAM_BUNDLE_MIN_SUBSCRIPTION_AGE,
  PARAM_BUNDLE_SLUG,
} from 'src/constants/bundleParameters';
import { CustomTextKey } from 'src/constants/customTextKeys';
import { DEFAULT_MINIMUM_AGE_TO_SUBSCRIBE } from 'src/constants/defaultMinimumAgeToSubscribe';
import { LANGUAGE } from 'src/constants/languages';
import IBundle from 'src/types/IBundle';
import IBundleGroup from 'src/types/IBundleGroup';
import getBundleCustomParameter from '../getBundleCustomParameter';
import getBundleParameter from '../getBundleParameter';
import modelBundleGroup from './modelBundleGroup';

interface IBundleModel {
  data: IBundle;
  getGroupsForUnitSelection: (selectedGroup: IBundleGroup) => IBundleGroup[];
  getGroupsForCoachLanguageSelection: (
    selectedGroup: IBundleGroup,
  ) => IBundleGroup[];
  hasUnitSelection: (selectedGroup: IBundleGroup) => boolean;
  hasCoachLanguageSelection: (selectedGroup: IBundleGroup) => boolean;
  getSlug: () => string | undefined;
  getCustomStringValue: (
    key: CustomTextKey,
    language?: LANGUAGE,
  ) => string | undefined;
  getMinimumAgeToSubscribe: () => number;
}

function modelBundle(bundle: IBundle): IBundleModel {
  function getGroupsForUnitSelection(
    selectedGroup: IBundleGroup,
  ): IBundleGroup[] {
    const selectedGroupModel = modelBundleGroup(selectedGroup);

    // 1. Stop looking for groups if the selectedGroup does not have a item with a unit
    if (!selectedGroupModel.hasItemWithUnit()) {
      return [];
    }

    const otherGroupsWithUnit = bundle.groups.filter(group => {
      const groupModel = modelBundleGroup(group);
      return (
        // 2. Select only groups that are not the selected group
        groupModel.data.code !== selectedGroupModel.data.code &&
        // 3. Select only groups with the same amount of items
        groupModel.data.items.length === selectedGroupModel.data.items.length &&
        // 4. Select only groups with a item that has a unit
        groupModel.hasItemWithUnit() &&
        // 5. Select only groups with a different unit than the given group
        groupModel.getItemUnit() !== selectedGroupModel.getItemUnit()
      );
    });

    if (otherGroupsWithUnit.length === 0) {
      return [];
    }

    return otherGroupsWithUnit.concat(selectedGroup);
  }

  function getGroupsForCoachLanguageSelection(
    selectedGroup: IBundleGroup,
  ): IBundleGroup[] {
    const selectedGroupModel = modelBundleGroup(selectedGroup);

    if (!selectedGroupModel.hasItemWithCoachLanguage()) {
      return [];
    }

    const otherGroupsWithCoaching = bundle.groups.filter(group => {
      const groupModel = modelBundleGroup(group);

      return (
        groupModel.data.code !== selectedGroupModel.data.code &&
        groupModel.data.items.length === selectedGroupModel.data.items.length &&
        groupModel.hasItemWithCoachLanguage() &&
        groupModel.getCoachLanguage() !== selectedGroupModel.getCoachLanguage()
      );
    });

    if (otherGroupsWithCoaching.length === 0) {
      return [];
    }

    return [selectedGroup].concat([...otherGroupsWithCoaching]);
  }

  function hasUnitSelection(selectedGroup: IBundleGroup): boolean {
    return getGroupsForUnitSelection(selectedGroup).length > 0;
  }

  function hasCoachLanguageSelection(selectedGroup: IBundleGroup): boolean {
    return getGroupsForCoachLanguageSelection(selectedGroup).length > 0;
  }

  function getSlug() {
    return getBundleParameter(PARAM_BUNDLE_SLUG, bundle);
  }

  function getCustomStringValue(key: CustomTextKey, language: LANGUAGE) {
    const newKey = key + '.' + language;
    return getBundleCustomParameter(newKey, bundle);
  }

  function getMinimumAgeToSubscribe(): number {
    const ageParam = getBundleParameter(
      PARAM_BUNDLE_MIN_SUBSCRIPTION_AGE,
      bundle,
    );

    // Backend parameter values are always a string
    const minimumAge = parseInt(ageParam, 10);

    // Return minimum age when defined and is a number
    if (minimumAge) {
      return minimumAge;
    }

    return DEFAULT_MINIMUM_AGE_TO_SUBSCRIBE;
  }

  return {
    data: bundle,
    getGroupsForUnitSelection,
    getGroupsForCoachLanguageSelection,
    hasUnitSelection,
    hasCoachLanguageSelection,
    getSlug,
    getCustomStringValue,
    getMinimumAgeToSubscribe,
  };
}

export default modelBundle;
