export interface IRDCPAuthFlags {
  isRDCPLogin: boolean;
  isRDCPRegister: boolean;
}

const getRDCPAuthFlags = (featuresList: string[]): IRDCPAuthFlags => ({
  isRDCPLogin: !!featuresList.find(
    item => item === 'F_ROCHE_DIABETES_ACCOUNT_LOGIN',
  ),
  isRDCPRegister: !!featuresList.find(
    item => item === 'F_ROCHE_DIABETES_ACCOUNT_REGISTRATION',
  ),
});

export default getRDCPAuthFlags;
