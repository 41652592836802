import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import HeaderNotice from 'src/components/HeaderNotice/HeaderNotice';
import Translate from 'src/components/Translate/Translate';
import { BUNDLE_NOT_AVAILABLE } from 'src/constants/bundles';
import arrangeBundleGroupsByType from 'src/lib/arrangeBundleGroupsByType';
import getPageTitle from 'src/lib/getPageTitle';
import isBundleAvailable from 'src/lib/isBundleAvailable';
import IBundle from 'src/types/IBundle';
import IStoreState from 'src/types/IStoreState';
import BundleGroupItem from '../BundleGroupItem/BundleGroupItem';
import css from './BundleGroupSelection.module.scss';

export interface IProps {
  bundle: IBundle;
}

export function BundleGroupSelection({ bundle }: Readonly<IProps>) {
  const groups = bundle.groups;
  const groupOrder = React.useMemo(
    () => arrangeBundleGroupsByType(groups),
    [groups],
  );
  const { translations } = useSelector((state: IStoreState) => ({
    translations: { pageTitle: getPageTitle(state, { pageName: '', bundle }) },
  }));

  const bundleIsAvailable = React.useMemo(
    () => isBundleAvailable(bundle),
    [bundle],
  );
  const noticeType = React.useMemo(
    () => (bundleIsAvailable ? bundle.paymentType : BUNDLE_NOT_AVAILABLE),
    [bundle, bundleIsAvailable],
  );

  return (
    <main role="main">
      <HeaderNotice
        bundle={bundle}
        noticeType={noticeType}
        className={css.payorCoveredInfo}
      />

      <div className={css.container}>
        <Helmet>
          <title>{translations.pageTitle}</title>
        </Helmet>

        <h1 className={css.title}>
          <Translate t="groupSelectionPage.title" />
        </h1>
        <div className={css.groupWrap}>
          {groupOrder?.map(code => {
            const group = groups.find(groupItem => groupItem.code === code);
            return (
              group && (
                <BundleGroupItem
                  key={group.code}
                  group={group}
                  bundle={bundle}
                />
              )
            );
          })}
        </div>
      </div>
    </main>
  );
}

export default BundleGroupSelection;
