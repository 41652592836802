function getNonBundleFlowNameFromPathname(
  pathname: string,
): string | undefined {
  const match = /\/flow\/([A-Za-z0-9_-]+)/.exec(pathname);
  if (match?.[1]) {
    return match[1];
  }
  return undefined;
}

export default getNonBundleFlowNameFromPathname;
