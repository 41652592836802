import errorConstants from 'src/constants/errors';
import IEmailForm from 'src/types/IEmailForm';
import IFormNotice from 'src/types/IFormNotice';

export const USER_EXISTS_ACTION = 'USER_EXISTS_ACTION';
export type USER_EXISTS_ACTION = typeof USER_EXISTS_ACTION;

export const USER_EXISTS_BEGIN_ACTION = 'USER_EXISTS_BEGIN_ACTION';
export type USER_EXISTS_BEGIN_ACTION = typeof USER_EXISTS_BEGIN_ACTION;

export const USER_EXISTS_SUCCESS_ACTION = 'USER_EXISTS_SUCCESS_ACTION';
export type USER_EXISTS_SUCCESS_ACTION = typeof USER_EXISTS_SUCCESS_ACTION;

export const USER_EXISTS_FAILURE_ACTION = 'USER_EXISTS_FAILURE_ACTION';
export type USER_EXISTS_FAILURE_ACTION = typeof USER_EXISTS_FAILURE_ACTION;

export const USER_EXISTS_FORM_ACTION = 'USER_EXISTS_FORM_ACTION';
export type USER_EXISTS_FORM_ACTION = typeof USER_EXISTS_FORM_ACTION;

export interface IUserExistsAction {
  type: USER_EXISTS_ACTION;
}

export interface IUserExistsBeginAction {
  type: USER_EXISTS_BEGIN_ACTION;
}

export interface IUserExistsSuccessAction {
  type: USER_EXISTS_SUCCESS_ACTION;
  form: IEmailForm;
  userExists: boolean;
}

export interface IUserExistsFailureAction {
  type: USER_EXISTS_FAILURE_ACTION;
  errors: IFormNotice[];
}

export interface IUserExistsFormAction {
  type: USER_EXISTS_FORM_ACTION;
  form: IEmailForm;
}

export type UserExistsAction =
  | IUserExistsAction
  | IUserExistsBeginAction
  | IUserExistsSuccessAction
  | IUserExistsFailureAction
  | IUserExistsFormAction;

export function userExistsBeginAction(): IUserExistsBeginAction {
  return { type: USER_EXISTS_BEGIN_ACTION };
}

export function userExistsSuccessAction(
  form: IEmailForm,
  userExists: boolean,
): IUserExistsSuccessAction {
  return { type: USER_EXISTS_SUCCESS_ACTION, form, userExists };
}

export function userExistsFailureAction(
  errors: IFormNotice[],
): IUserExistsFailureAction {
  return { type: USER_EXISTS_FAILURE_ACTION, errors };
}

export function userExistsFormAction(form: IEmailForm): IUserExistsFormAction {
  return { type: USER_EXISTS_FORM_ACTION, form };
}

export function userExistsInDifferentRegionAction(
  offerRegions: string[],
  userRegions: string[],
) {
  const formatRegions = (regions: string[]) =>
    regions.map(region => region.toUpperCase()).join(', ');

  return userExistsFailureAction([
    {
      field: '',
      message: errorConstants.EXISTS_IN_OTHER_REGION,
      options: {
        offerRegions: formatRegions(offerRegions),
        userRegions: formatRegions(userRegions),
      },
    },
  ]);
}
