import * as React from 'react';
import Translate from '../Translate/Translate';

export interface IProps {
  interval: string;
  minRecurringCount: number;
}

function BundleMinCommitment({
  interval,
  minRecurringCount,
}: Readonly<IProps>): React.ReactElement {
  return (
    <Translate
      t={`bundleProduct.minCommitment.info.${interval}.${
        minRecurringCount > 1 ? 2 : 1
      }`}
      variables={{ minRecurringCount }}
    />
  );
}

export default BundleMinCommitment;
