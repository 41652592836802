import { LANGUAGE } from 'src/constants/languages';
import { getTranslation } from 'src/selectors/translationSelectors';
import IStoreState from 'src/types/IStoreState';

export const SET_LANGUAGE_ACTION = 'SET_LANGUAGE_ACTION';
export type SET_LANGUAGE_ACTION = typeof SET_LANGUAGE_ACTION;

export const SET_TRANSLATIONS_ACTION = 'SET_TRANSLATIONS_ACTION';
export type SET_TRANSLATIONS_ACTION = typeof SET_TRANSLATIONS_ACTION;

export interface ISetLanguageAction {
  type: SET_LANGUAGE_ACTION;
  language: LANGUAGE;
}

export interface ISetTranslationsAction {
  type: SET_TRANSLATIONS_ACTION;
  translations: Record<string, string>;
}

export type TranslationAction = ISetLanguageAction | ISetTranslationsAction;

export function setLanguageAction(language: LANGUAGE): ISetLanguageAction {
  return { type: SET_LANGUAGE_ACTION, language };
}

export function setTranslationsAction(
  translations: Record<string, string>,
): ISetTranslationsAction {
  return { type: SET_TRANSLATIONS_ACTION, translations };
}

export interface ITranslateProps {
  translate: (
    key: string,
    values?: Record<string, string>,
  ) => string | undefined;
}

export function translateAction(
  key: string,
  variables?: Record<string, any>,
  ignoreIfEmpty?: boolean,
) {
  return (dispatch: any, getState: () => IStoreState): string | undefined =>
    getTranslation(key, variables, ignoreIfEmpty)(getState());
}
