import cnx from 'classnames';
import * as React from 'react';
import modelBundleGroupItem from 'src/lib/models/modelBundleGroupItem';
import IBundleGroupItem from 'src/types/IBundleGroupItem';
import BundleGroupItemImage from '../BundleGroupItemImage/BundleGroupItemImage';
import Translate from '../Translate/Translate';
import css from './ShoppingCartList.module.scss';

export interface IProps {
  accentColor: string;
  headline: string;
  items: IBundleGroupItem[];
  whiteBg?: boolean;
}

function ShoppingCartList({
  accentColor,
  headline,
  items,
  whiteBg,
}: Readonly<IProps>): React.ReactElement {
  return (
    <div className={css.container}>
      <h4 className={cnx(css.headline, css[`headline-${accentColor}`])}>
        <Translate t={headline} />
      </h4>
      <ul className={cnx(css.cart, css[`cart-${accentColor}`])}>
        {items.map(item => {
          const itemModel = modelBundleGroupItem(item);
          const variables: any = {};
          const itemTranslationKey = itemModel.getTranslationKey();
          variables.amount = itemModel.getAmount();

          return (
            <li
              key={itemModel.code}
              className={cnx(css.item, { [css.whiteBg]: whiteBg })}
            >
              <div className={css.itemText}>
                <h3 className={css.itemName}>
                  <Translate
                    t={`bundle.item.${itemModel.code}.name`}
                    altKey={`bundle.item.${itemTranslationKey}.name`}
                    html={true}
                    variables={variables}
                  />
                </h3>
                <p>
                  <Translate
                    t={`bundle.item.${itemModel.code}.description`}
                    altKey={`bundle.item.${itemTranslationKey}.description`}
                    html={true}
                    variables={variables}
                  />
                </p>
              </div>
              <div className={css.itemImage}>
                <BundleGroupItemImage item={itemModel} folder="item" />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

ShoppingCartList.defaultProps = {
  whiteBg: false,
};

export default ShoppingCartList;
