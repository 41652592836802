import { IBundleCustomCodeVerification } from 'src/types/IBundleState';
import IStoreState from 'src/types/IStoreState';
import IVoucher from 'src/types/IVoucher';

/**
 * Returns whether a user is currently redeeming a pro voucher
 * @param state
 */
export function getProVoucher(state: IStoreState): boolean {
  return state.voucher.voucher.types.pro;
}

/**
 * Returns the custom verification code and if its verified
 * @param state
 */
export function getCustomVerificationCode(
  state: IStoreState,
): IBundleCustomCodeVerification | undefined {
  return state.bundle.customCodeVerification;
}

/**
 * Returns the voucher the user is currently redeeming
 * @param state
 */
export function getVoucher(state: IStoreState): IVoucher {
  return state.voucher.voucher;
}
