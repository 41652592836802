import classnames from 'classnames';
import * as React from 'react';
import IFormNotice from 'src/types/IFormNotice';
import Translate from '../Translate/Translate';
import css from './InlineWarning.module.scss';

export interface IProps {
  warning: IFormNotice;
}

export default class InlineWarning extends React.PureComponent<IProps> {
  public render() {
    const { warning } = this.props;

    return (
      <div className={classnames(css.inlineWarning)}>
        <Translate
          t={`errors.${warning.message}.${warning.field}`}
          variables={warning.options || {}}
        />
      </div>
    );
  }
}
