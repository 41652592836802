import IBundle from 'src/types/IBundle';
import IFormNotice from 'src/types/IFormNotice';
import IVoucher from 'src/types/IVoucher';
import IVoucherResponseData from 'src/types/IVoucherResponseData';
import {
  IRedeemVoucherFailureAction,
  IRedeemVoucherSuccessAction,
  IVoucherSetErrors,
  REDEEM_VOUCHER_FAILURE_ACTION,
  REDEEM_VOUCHER_SUCCESS_ACTION,
  VOUCHER_SET_ERRORS,
} from './bundleActions';

export const CONTINUE_WITH_VOUCHER_ACTION = 'CONTINUE_WITH_VOUCHER_ACTION';
export type CONTINUE_WITH_VOUCHER_ACTION = typeof CONTINUE_WITH_VOUCHER_ACTION;

export const VOUCHER_CODE_INPUT_ACTION = 'VOUCHER_CODE_INPUT_ACTION';
export type VOUCHER_CODE_INPUT_ACTION = typeof VOUCHER_CODE_INPUT_ACTION;

export const VOUCHER_DELETE_ACTION = 'VOUCHER_DELETE_ACTION';
export type VOUCHER_DELETE_ACTION = typeof VOUCHER_DELETE_ACTION;

export const VOUCHER_RESET_ACTION = 'VOUCHER_RESET_ACION';
export type VOUCHER_RESET_ACTION = typeof VOUCHER_RESET_ACTION;

export const VALIDATE_VOUCHER_CODE_SUCCESS_ACTION =
  'VALIDATE_VOUCHER_CODE_SUCCESS_ACTION';
export type VALIDATE_VOUCHER_CODE_SUCCESS_ACTION =
  typeof VALIDATE_VOUCHER_CODE_SUCCESS_ACTION;

export const VALIDATE_VOUCHER_CODE_FAILURE_ACTION =
  'VALIDATE_VOUCHER_CODE_FAILURE_ACTION';
export type VALIDATE_VOUCHER_CODE_FAILURE_ACTION =
  typeof VALIDATE_VOUCHER_CODE_FAILURE_ACTION;

export const CLEAR_VOUCHER_DATA_ACTION = 'CLEAR_VOUCHER_DATA_ACTION';
export type CLEAR_VOUCHER_DATA_ACTION = typeof CLEAR_VOUCHER_DATA_ACTION;

export interface IClearVoucherDataAction {
  type: CLEAR_VOUCHER_DATA_ACTION;
}

export interface IContinueWithVoucherAction {
  type: CONTINUE_WITH_VOUCHER_ACTION;
  voucher: IVoucher;
}

export interface IVoucherCodeInputAction {
  type: VOUCHER_CODE_INPUT_ACTION;
  voucherCode: string;
}

export interface IValidateVoucherCodeSuccessAction {
  type: VALIDATE_VOUCHER_CODE_SUCCESS_ACTION;
  voucher: IVoucherResponseData;
  bundle?: IBundle;
}

export interface IValidateVoucherCodeFailureAction {
  type: VALIDATE_VOUCHER_CODE_FAILURE_ACTION;
  errors: IFormNotice[];
  voucherCode?: string;
}

export interface IVoucherDeleteAction {
  type: VOUCHER_DELETE_ACTION;
}

export interface IResetVoucherStateAction {
  type: VOUCHER_RESET_ACTION;
}

export type VoucherActions =
  | IClearVoucherDataAction
  | IContinueWithVoucherAction
  | IRedeemVoucherFailureAction
  | IRedeemVoucherSuccessAction
  | IValidateVoucherCodeFailureAction
  | IValidateVoucherCodeSuccessAction
  | IVoucherCodeInputAction
  | IVoucherDeleteAction
  | IResetVoucherStateAction
  | IVoucherSetErrors;

export function clearVoucherDataAction(): IClearVoucherDataAction {
  return { type: CLEAR_VOUCHER_DATA_ACTION };
}

export function voucherCodeInputAction(
  voucherCode: string,
): IVoucherCodeInputAction {
  return { type: VOUCHER_CODE_INPUT_ACTION, voucherCode };
}

export function validateVoucherCodeSuccessAction(
  voucher: IVoucherResponseData,
  bundle?: IBundle,
): IValidateVoucherCodeSuccessAction {
  return {
    bundle,
    type: VALIDATE_VOUCHER_CODE_SUCCESS_ACTION,
    voucher,
  };
}

export function validateVoucherCodeFailureAction(
  errors: IFormNotice[],
  voucherCode?: string,
): IValidateVoucherCodeFailureAction {
  return { type: VALIDATE_VOUCHER_CODE_FAILURE_ACTION, errors, voucherCode };
}

export function deleteVoucherAction(): IVoucherDeleteAction {
  return { type: VOUCHER_DELETE_ACTION };
}

export function resetVoucherStateAction(): IResetVoucherStateAction {
  return {
    type: VOUCHER_RESET_ACTION,
  };
}

export function continueWithVoucherAction(
  voucher: IVoucher,
  options: { pro?: boolean },
): IContinueWithVoucherAction {
  return {
    type: CONTINUE_WITH_VOUCHER_ACTION,
    voucher: {
      ...voucher,
      types: {
        ...voucher.types,
        pro: options.pro ?? false,
      },
    },
  };
}

export function redeemVoucherSuccessAction(): IRedeemVoucherSuccessAction {
  return { type: REDEEM_VOUCHER_SUCCESS_ACTION, orderSuccessful: true };
}

export function redeemVoucherSetErrorsAction(
  errors: IFormNotice[],
): IVoucherSetErrors {
  return {
    errors,
    type: VOUCHER_SET_ERRORS,
  };
}

export function redeemVoucherFailureAction(
  errors: IFormNotice[],
): IRedeemVoucherFailureAction {
  return {
    errors,
    orderSuccessful: false,
    type: REDEEM_VOUCHER_FAILURE_ACTION,
  };
}
