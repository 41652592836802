import { IFieldMask } from 'src/lib/FieldMask';

const day: IFieldMask = {
  length: 2,
  type: 'number',
  range: [1, 31],
  placeholder: 'DD',
};
const month: IFieldMask = {
  length: 2,
  type: 'number',
  range: [1, 12],
  placeholder: 'MM',
};
const year: IFieldMask = {
  length: 2,
  type: 'number',
  range: [new Date().getFullYear() - 100, new Date().getFullYear() - 1],
  placeholder: 'YYYY',
};

export const dateMaskUS: IFieldMask[] = [
  month,
  { auto: true, placeholder: '/' },
  day,
  { auto: true, placeholder: '/' },
  year,
];

export const dateMaskDE: IFieldMask[] = [
  day,
  { auto: true, placeholder: '.' },
  month,
  { auto: true, placeholder: '.' },
  year,
];

export const dateMaskUpdateUserPreference: IFieldMask[] = [
  year,
  { auto: true, placeholder: '-' },
  month,
  { auto: true, placeholder: '-' },
  day,
];
