import classnames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  LoadingAction,
  loadingBeginAction,
  loadingFailureAction,
  loadingSuccessAction,
} from 'src/actions/loadingActions';
import {
  ITranslateProps,
  translateAction,
} from 'src/actions/translationActions';
import modelBundleGroup from 'src/lib/models/modelBundleGroup';
import IBundleGroup from 'src/types/IBundleGroup';
import IStoreState from 'src/types/IStoreState';
import Image from '../Image/Image';
import css from './BundleBoxImage.module.scss';

interface IDispatchProps {
  loadingBegin: (resource: string) => void;
  loadingFailure: (resource: string) => void;
  loadingSuccess: (resource: string) => void;
}

export interface IProps extends IDispatchProps {
  group: IBundleGroup;
  map?: any;
  groupSelection?: boolean;
}

export class BundleBoxImage extends React.PureComponent<
  IProps & ITranslateProps
> {
  public imageRef: HTMLImageElement;

  public componentDidMount() {
    this.props.loadingBegin('BundleBoxImage/box.png');
  }

  public componentWillUnmount() {
    this.props.loadingFailure('BundleBoxImage/box.png');
    this.imageRef.removeEventListener('load', this.handleLoaded);
    this.imageRef.removeEventListener('error', this.handleError);
  }

  public render() {
    const { group, groupSelection } = this.props;
    const groupModel = modelBundleGroup(group);

    const source = groupModel.getBundleImage({
      whiteBackground: Boolean(groupSelection),
    });

    const isCoachOnly = groupModel.isCoachOnly();

    const altTextTranslationKey = `product.bundleBoxImage.alt.${source.name}`;
    let altText = this.props.translate(altTextTranslationKey);

    if (altText === altTextTranslationKey) {
      altText = this.props.translate('product.bundleBoxImage.alt');
    }

    return (
      <div className={css.container}>
        <Image
          alt={altText}
          className={classnames(
            css.image,
            isCoachOnly ? css.coachOnlyImage : '',
          )}
          onRef={this.setRef}
          src={source.box1x}
          srcSet={{
            '1x': source.box1x,
            '2x': source.box2x,
            '3x': source.box3x,
          }}
          webpSrc={{
            src: source.box1xWebp,
            srcSet: {
              '1x': source.box1xWebp,
              '2x': source.box2xWebp,
              '3x': source.box3xWebp,
            },
          }}
        />
      </div>
    );
  }

  private readonly setRef = (ref?: HTMLImageElement) => {
    // istanbul ignore else
    if (ref) {
      this.imageRef = ref;
      this.imageRef.addEventListener('load', this.handleLoaded);
      this.imageRef.addEventListener('error', this.handleError);
    }
  };

  private readonly handleLoaded = () => {
    this.props.loadingSuccess('BundleBoxImage/box.png');
  };

  private readonly handleError = () => {
    this.props.loadingFailure('BundleBoxImage/box.png');
  };
}

export function mapDispatchToProps(
  dispatch: ThunkDispatch<IStoreState, Record<string, any>, LoadingAction>,
): IDispatchProps & ITranslateProps {
  return {
    loadingBegin: (resource: string) => dispatch(loadingBeginAction(resource)),
    loadingFailure: (resource: string) =>
      dispatch(loadingFailureAction(resource)),
    loadingSuccess: (resource: string) =>
      dispatch(loadingSuccessAction(resource)),
    translate: (key: string, values?: Record<string, any>) =>
      dispatch(translateAction(key, values)),
  };
}

export default connect(null, mapDispatchToProps)(BundleBoxImage);
