import * as React from 'react';
import checkmarkImg from '../../icons/checkmark.svg';
import css from './Checklist.module.scss';

type childrenType = JSX.Element | string;

export interface IProps {
  children: JSX.Element[] | JSX.Element;
}

export interface IItemProps {
  children: string | JSX.Element | childrenType[];
}

function Checklist({ children }: Readonly<IProps>): React.ReactElement {
  return <ul className={css.list}>{children}</ul>;
}

Checklist.Item = ({ children }: IItemProps) => (
  <li className={css.item}>
    <div className={css.icon} aria-hidden="true">
      <img src={checkmarkImg} alt="checked" />
    </div>
    <div className={css.text}>{children}</div>
  </li>
);

export default Checklist;
