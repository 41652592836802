/* istanbul ignore file */

const appRoutes = {
  home: ':locale',
  payor: ':locale/payor/:payor',
  payorGroup: ':locale/payor/:payor/group/:group',
  group: ':locale/group/:group',
  voucher: ':language/voucher',
  voucherFlow: ':language/voucher/:voucher',
  eligibility: ':language/eligibility',
  nonBundleHome: ':locale/flow/:code',
  nonBundleFlow: ':locale/flow/:code/:page',
};

export default appRoutes;
