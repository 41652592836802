import { LocationContext } from '@gatsbyjs/reach-router';
import classnames from 'classnames';
import * as React from 'react';
import bundleOverrides from 'src/config/nonStandardBundleOverride/bundleOverrides';
import {
  BUNDLE_INFO_NOTICE,
  BUNDLE_NOT_AVAILABLE,
  PAID_UP_FRONT,
  PAYMENT_TYPE,
  PAYOR_COVERED,
} from 'src/constants/bundles';
import { COUNTRY_US } from 'src/constants/countries';
import {
  MORE_INFO_OVERLAY,
  TOP_BAR_MESSAGE,
} from 'src/constants/customTextKeys';
import getLocaleFromPathname from 'src/lib/getLocaleFromPathname';
import modelBundle from 'src/lib/models/modelBundle';
import IBundle from 'src/types/IBundle';
import GenericTopBarMessage from '../GenericTopBarMessage/GenericTopBarMessage';
import HeaderBundleInfoBar from '../HeaderBundleInfoBar/HeaderBundleInfoBar';
import NonBundleFlowBar from '../NonBundleFlowBar/NonBundleFlowBar';
import ReimbursementBar from '../ReimbursementBar/ReimbursementBar';
import Translate from '../Translate/Translate';
import withLocation from '../withLocation/withLocation';
import css from './HeaderNotice.module.scss';

export interface IProps extends LocationContext {
  bundle?: IBundle;
  noticeType?: PAYMENT_TYPE | BUNDLE_NOT_AVAILABLE | BUNDLE_INFO_NOTICE;
  className?: string;
  showDvgText?: boolean;
  onNavigate?: (event: React.SyntheticEvent) => Promise<void>;
}

export function NoticeText(props: Readonly<IProps>) {
  const { bundle, noticeType, showDvgText, onNavigate, location } = props;
  let payorCoveredText;
  let payorCoveredAltKey;

  const pathname = location.pathname;

  const [language] = React.useMemo(
    () => getLocaleFromPathname(pathname),
    [pathname],
  );

  if (bundle) {
    const bundleModel = modelBundle(bundle);
    const topBarMessageText = bundleModel.getCustomStringValue(
      TOP_BAR_MESSAGE,
      language,
    );
    const moreInfoOverlayText = bundleModel.getCustomStringValue(
      MORE_INFO_OVERLAY,
      language,
    );

    if (bundle.country?.toLowerCase() === COUNTRY_US) {
      payorCoveredText = `overview.payorCovered.us.${bundle.code}`;
      payorCoveredAltKey = 'overview.payorCovered.us';
    } else {
      payorCoveredText = `overview.payorCovered.${bundle.code}`;
      payorCoveredAltKey = 'overview.payorCovered';
    }
    if (topBarMessageText) {
      return (
        <GenericTopBarMessage
          topBarMessage={topBarMessageText}
          moreInfoOverlayText={moreInfoOverlayText}
        />
      );
    } else {
      switch (noticeType) {
        case PAYOR_COVERED:
          return (
            <Translate
              t={payorCoveredText}
              altKey={payorCoveredAltKey}
              html={true}
            />
          );
        case PAID_UP_FRONT:
          return <ReimbursementBar className={css.center} />;
        case BUNDLE_NOT_AVAILABLE:
          return (
            bundle.payor && (
              <Translate
                t={`headerNotice.bundleNotAvailable.${bundle.code}`}
                altKey="headerNotice.bundleNotAvailable"
              />
            )
          );
        case BUNDLE_INFO_NOTICE:
          return <HeaderBundleInfoBar bundleCode={bundle.code} />;

        default:
          return null;
      }
    }
  } else if (showDvgText) {
    return <NonBundleFlowBar onNavigate={onNavigate} className={css.center} />;
  } else {
    return null;
  }
}

export function HeaderNotice({
  bundle,
  className,
  noticeType,
  showDvgText,
  location,
  navigate,
  onNavigate,
}: Readonly<IProps>) {
  const bundleNoticeType =
    bundle && bundleOverrides[bundle.code]?.showInfoNotice
      ? BUNDLE_INFO_NOTICE
      : noticeType;

  const content = NoticeText({
    bundle,
    noticeType: bundleNoticeType,
    showDvgText,
    onNavigate,
    location,
    navigate,
  });

  if (!content) {
    return null;
  }

  return (
    <div className={classnames(css.wrapper, className)}>
      <div
        className={classnames(css.container, {
          [css.bundleNotAvailable]: noticeType === BUNDLE_NOT_AVAILABLE,
          [css.colorHoney]: bundleNoticeType === BUNDLE_INFO_NOTICE,
        })}
      >
        <div className={css.centerText}>{content}</div>
      </div>
    </div>
  );
}

export default withLocation(HeaderNotice);
