import * as React from 'react';
import IBundle from 'src/types/IBundle';
import IBundleGroup from 'src/types/IBundleGroup';
import Overlay from '../Overlay/Overlay';
import UnitSelection from '../UnitSelection/UnitSelection';
import modelBundle from 'src/lib/models/modelBundle';

export interface IProps {
  bundle: IBundle;
  group: IBundleGroup;
  show: boolean;
  onClose: () => void;
  onSelect: (group: IBundleGroup) => void;
}

function UnitSelectionOverlay({
  bundle,
  group,
  show,
  onClose,
  onSelect,
}: Readonly<IProps>): React.ReactElement | null {
  const bundleModel = modelBundle(bundle);
  const groups = bundleModel.getGroupsForUnitSelection(group);

  if (groups.length < 2) {
    return null;
  }

  return (
    <Overlay
      ariaLabel="unitSelection.label"
      ariaLabelClose="unitSelection.labelClose"
      show={show}
      onClose={onClose}
    >
      <UnitSelection onSelect={onSelect} groups={groups} />
    </Overlay>
  );
}

export default UnitSelectionOverlay;
