import * as React from 'react';
import { connect } from 'react-redux';
import { getRemainingSteps } from 'src/selectors/flowSelectors';
import IStoreState from 'src/types/IStoreState';
import Translate from '../Translate/Translate';

interface IProps {
  className?: string;
  remainingSteps: number;
}

export function RemainingSteps({
  className,
  remainingSteps,
}: Readonly<IProps>): React.ReactElement {
  return (
    <p className={className}>
      <Translate
        t="stepBar.remaining"
        variables={{ remaining: remainingSteps }}
        html={true}
      />
    </p>
  );
}

export function mapStateToProps(state: IStoreState) {
  return {
    remainingSteps: getRemainingSteps(state),
  };
}

export default connect(mapStateToProps)(RemainingSteps);
