import 'src/lib/requireImagesContext';

export interface IImageSources {
  src: string;
  srcSet: {
    '1x': string;
    '2x': string;
    '3x': string;
  };
  webpSrc?: {
    src: string;
    srcSet: {
      '1x': string;
      '2x': string;
      '3x': string;
    };
  };
}

export default function loadItemImageSet(
  key: string,
  folder: 'featured-item' | 'item',
): IImageSources {
  try {
    return {
      src: require(`../images/bundle/${folder}/${key}.png`).default,
      srcSet: {
        '1x': require(`../images/bundle/${folder}/${key}.png`).default,
        '2x': require(`../images/bundle/${folder}/${key}@2x.png`).default,
        '3x': require(`../images/bundle/${folder}/${key}@3x.png`).default,
      },
      webpSrc: {
        src: require(`../images/bundle/${folder}/${key}.webp`).default,
        srcSet: {
          '1x': require(`../images/bundle/${folder}/${key}.webp`).default,
          '2x': require(`../images/bundle/${folder}/${key}@2x.webp`).default,
          '3x': require(`../images/bundle/${folder}/${key}@3x.webp`).default,
        },
      },
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
    return {
      src: '',
      srcSet: { '1x': '', '2x': '', '3x': '' },
      webpSrc: { src: '', srcSet: { '1x': '', '2x': '', '3x': '' } },
    };
  }
}
