enum FLOW_NAME {
  none = '',
  orderBundle = 'ORDER_BUNDLE',
  nonBundleFlow = 'NON_BUNDLE_FLOW',
  voucher = 'VOUCHER',
  changePayment = 'CHANGE_PAYMENT',
  eligibility = 'ELIGIBILITY',
}

export default FLOW_NAME;
