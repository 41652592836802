import * as React from 'react';
import Translate from '../Translate/Translate';
import css from './FormLabel.module.scss';
import cn from 'classnames';

interface IProps {
  className?: string;
  disabled?: boolean;
  hint?: string;
  label: string;
  labelBundle?: string;
  autocomplete?: string;
  placeholder?: string;
  required?: boolean;
  labelFallback?: string;
  name: string;
  moreInfo?: IMoreInfo;
}

export interface IMoreInfo {
  hide?: boolean;
  type: 'text' | 'hint' | 'icon';
  render: () => JSX.Element;
}

export function renderMoreInfo(options: IMoreInfo) {
  const { hide, type, render } = options;

  if (hide) {
    return null;
  }

  const typeCss = [
    { [css.hint]: type === 'hint' },
    { [css.moreInfoText]: type === 'text' },
    { [css.moreInfoIcon]: type === 'icon' },
  ];

  return <span className={cn(css.moreInfo, typeCss)}>{render()}</span>;
}

function FormLabel({
  className,
  disabled,
  name,
  label,
  labelBundle,
  labelFallback,
  hint,
  moreInfo,
}: Readonly<IProps>): React.ReactElement {
  return (
    <div className={css.wrap}>
      <label
        className={cn(css.formLabel, className, {
          [css.disabled]: disabled,
        })}
        htmlFor={name}
      >
        {labelBundle ?? <Translate t={label} altKey={labelFallback} />}
      </label>
      {hint && (
        <div id={name + '-hint'} className={css.hint}>
          <Translate t={hint} />
        </div>
      )}
      {moreInfo && renderMoreInfo(moreInfo)}
    </div>
  );
}

export default FormLabel;
