import { Link, LocationContext } from '@gatsbyjs/reach-router';
import classnames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import BundleBoxImage from 'src/components/BundleBoxImage/BundleBoxImage';
import Button from 'src/components/Button/Button';
import { PAYOR_COVERED } from 'src/constants/bundles';
import {
  BundleItemOrderGroupSelection,
  BundleItemsFeaturedInShop,
} from 'src/constants/itemsOrder';
import browserOnly from 'src/lib/browserOnly';
import getBundleCartItems from 'src/lib/getBundleCartItems';
import getBundleGroupFeaturedItems from 'src/lib/getBundleGroupFeaturedItems';
import getLocaleFromPathname from 'src/lib/getLocaleFromPathname';
import getPayorForPathname from 'src/lib/getPayorForPathname';
import modelBundleGroup from 'src/lib/models/modelBundleGroup';
import modelBundleGroupItem from 'src/lib/models/modelBundleGroupItem';
import { getUrlForNavigate } from 'src/lib/navigate';
import IBundle from 'src/types/IBundle';
import IBundleGroup from 'src/types/IBundleGroup';
import BundleGroupItemImage from '../BundleGroupItemImage/BundleGroupItemImage';
import BundlePrice from '../BundlePrice/BundlePrice';
import Overlay from '../Overlay/Overlay';
import PaymentInterval from '../PaymentInterval/PaymentInterval';
import { ShoppingCart } from '../ShoppingCart/ShoppingCart';
import Translate from '../Translate/Translate';
import withLocation from '../withLocation/withLocation';
import css from './BundleGroupItem.module.scss';

export interface IProps extends LocationContext {
  bundle: IBundle;
  group: IBundleGroup;
}

const scrollToTop = () => browserOnly(() => window.scroll(0, 0));

export function BundleGroupItem({ bundle, group, location }: Readonly<IProps>) {
  const pathname = location.pathname;
  const [language, country] = React.useMemo(
    () => getLocaleFromPathname(pathname),
    [pathname],
  );
  const groupModel = React.useMemo(() => modelBundleGroup(group), [group]);

  const [overlayVisible, setOverlayVisible] = React.useState(false);
  const showOverlay = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setOverlayVisible(true);
  };
  const hideOverlay = () => setOverlayVisible(false);

  const featuredItemsInShop = React.useMemo(
    () => getBundleGroupFeaturedItems(group, BundleItemsFeaturedInShop),
    [group],
  );
  const groupFeaturedItemsOnSelectionGroup = React.useMemo(
    () =>
      getBundleGroupFeaturedItems(group, BundleItemOrderGroupSelection).map(
        modelBundleGroupItem,
      ),
    [group],
  );

  const showMoreInfo = React.useMemo(
    () =>
      groupFeaturedItemsOnSelectionGroup.length < featuredItemsInShop.length,
    [featuredItemsInShop, groupFeaturedItemsOnSelectionGroup],
  );

  const freeForUser = React.useMemo(
    () => bundle.paymentType === PAYOR_COVERED,
    [bundle],
  );

  const payor = React.useMemo(() => getPayorForPathname(pathname), [pathname]);

  const cartParams = React.useMemo(() => getBundleCartItems(group), [group]);

  // We need to use the helper function to support branch deployment on dev
  // remove the hostname because <Link> from reach/router doesn't support full URLs
  const groupUrl = React.useMemo(() => {
    const payorPath = payor ? `payor/${payor}/` : '';
    const path = `/${language}-${country}/${payorPath}group/${group.code}`;

    return getUrlForNavigate(path).replace(/^(https?:\/\/[A-Za-z0-9.:-]+)/, '');
  }, [language, country, payor]);

  const renderContinueButton = () => (
    <div className={css.buttonWrap}>
      <Link
        to={groupUrl}
        className={classnames(css.link, 'linkSelectGroup')}
        onClick={scrollToTop}
      >
        <Button large={true} secondary={true} className={css.linkButton}>
          <Translate t="groupSelection.continue" />
        </Button>
      </Link>
    </div>
  );

  if (!bundle || !country || !language) {
    return null;
  }

  const titleTranslationKey = groupModel.getBundleGroupType();

  return (
    <div className={css.wrap} data-coach-only={groupModel.isCoachOnly()}>
      <h2 className={css.title}>
        <Translate
          t={`groupSelection.titles.${titleTranslationKey}`}
          html={true}
        />
      </h2>
      <div
        className={classnames(css.inner, {
          [css.withoutMoreInfo]: !showMoreInfo,
        })}
      >
        <div className={css.imagePriceWrap}>
          <div className={css.image}>
            <BundleBoxImage group={group} groupSelection={true} />
          </div>

          <div className={css.price} role="text">
            {freeForUser ? (
              <>
                <div className={css.bundleFree}>
                  <Translate t="groupSelectionPage.priceFree" />
                </div>
                <div className={css.priceTime}>
                  <Translate t="groupSelectionPage.priceFreeExtra" />
                </div>
              </>
            ) : (
              <>
                <BundlePrice className={css.bundlePrice} group={group} />
                <div className={css.priceTime}>
                  <PaymentInterval
                    interval={bundle.interval}
                    intervalCount={bundle.intervalCount}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        {renderContinueButton()}
        <hr className={css.spacer} />

        <ul className={css.list}>
          {groupFeaturedItemsOnSelectionGroup.map(itemModel => (
            <li key={itemModel.code} className={css.listItem}>
              <div className={css.itemIcon}>
                <BundleGroupItemImage item={itemModel} folder="featured-item" />
              </div>
              <div className={css.itemName}>
                <Translate
                  t={`groupSelection.items.description.${itemModel.code}`}
                  altKey={`groupSelection.items.description.${itemModel.getTranslationKey()}`}
                  html={true}
                />
              </div>
            </li>
          ))}
        </ul>

        {showMoreInfo && (
          <>
            <hr className={classnames(css.spacer, css.spacerSpacingTop)} />
            <button className={css.more} type="button" onClick={showOverlay}>
              <Translate t="groupSelection.moreInfo" />
            </button>
            <Overlay show={overlayVisible} onClose={hideOverlay}>
              <ShoppingCart className={css.removeCartPadding} {...cartParams} />
            </Overlay>
          </>
        )}
      </div>
    </div>
  );
}

export default connect()(withLocation(BundleGroupItem));
