import cn from 'classnames';
import * as React from 'react';
import LinkButton from 'src/components/LinkButton/LinkButton';
import Overlay from '../Overlay/Overlay';
import css from 'src/components/HeaderBundleInfoOverlay/HeaderBundleInfoOverlay.module.scss';
import Translate from '../Translate/Translate';

export interface IProps {
  className?: string;
  children: React.ReactText | React.ReactElement;
}

export function MoreInfoOverlay(props: Readonly<IProps>) {
  const { children } = props;
  const [visibility, setVisibility] = React.useState(false);

  const onClose = () => setVisibility(false);
  const openOverlay = (event: any): void => {
    event.preventDefault();
    setVisibility(true);
  };
  return (
    <>
      <LinkButton
        className={cn(css.link, props.className)}
        onClick={openOverlay}
      >
        <Translate html={true} t={`more.info.overlay.linkText`} />
      </LinkButton>
      <Overlay show={visibility} onClose={onClose}>
        <div className={css.container}>{children}</div>
      </Overlay>
    </>
  );
}

export default MoreInfoOverlay;
