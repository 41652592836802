import * as React from 'react';
import {
  COUNTRY_DE,
  COUNTRY_US,
  SUPPORTED_COUNTRIES,
  SUPPORTED_COUNTRY,
} from 'src/constants/countries';
import legal from 'src/constants/legal';
import Translate from '../Translate/Translate';
import css from './Footer.module.scss';

export interface IProps {
  country?: SUPPORTED_COUNTRY;
  withCountryName?: boolean;
}

export function ImprintLink({
  country,
  withCountryName,
}: Readonly<IProps>): React.ReactElement {
  const supportedCountry = SUPPORTED_COUNTRIES.includes(country!) && country;
  if (!supportedCountry) {
    return (
      <>
        <ImprintLink country={COUNTRY_US} withCountryName={true} />
        <li className={css.linkSpacer} aria-hidden={true} />
        <ImprintLink country={COUNTRY_DE} withCountryName={true} />
      </>
    );
  }

  let countryName;
  if (withCountryName) {
    countryName = (
      <>
        {' '}
        (<Translate t={`countryNames.${country}.short`} />)
      </>
    );
  }

  return (
    <li className={css.link}>
      <a
        href={legal[supportedCountry]().imprint}
        target="_blank"
        rel="noopener"
      >
        <Translate t="footer.links.imprint" />
        {countryName}
      </a>
    </li>
  );
}

export default ImprintLink;
