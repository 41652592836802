/* istanbul ignore file */

module.exports = {
  "account.account.backButton": "Atrás",
  "account.account.continueButton": "Continuar",
  "account.account.loggedInAs": "Has iniciado sesión como :email",
  "account.account.logoutButton": "Cerrar sesión",
  "account.account.logoutText": "¿No eres tú? Puedes cerrar sesión e iniciar sesión con otra cuenta o crear una nueva.",
  "account.emailConfirmForm.backButton": "Atrás",
  "account.emailConfirmForm.changeEmail": "Cambiar dirección de e-mail",
  "account.emailConfirmForm.checkYourEmail": "Comprueba tu e-mail",
  "account.emailConfirmForm.codeLabel": "Introduce tu código de verificación",
  "account.emailConfirmForm.codeName": "Código de verificación",
  "account.emailConfirmForm.codeWrong": "Código erróneo o caducado",
  "account.emailConfirmForm.continueButton": "Continuar",
  "account.emailConfirmForm.emailLabel": "Dirección de e-mail",
  "account.emailConfirmForm.receivedNoCode": "¿No has recibido el código?",
  "account.emailConfirmForm.sendCodeAgain": "Reenviar e-mail",
  "account.emailConfirmForm.sendCodeSuccess": "Hemos enviado tu código de verificación a la dirección de e-mail :email.",
  "account.emailConfirmForm.sentCode": "Te hemos enviado un código",
  "account.emailForm.backButton": "Atrás",
  "account.emailForm.continueButton": "Continuar",
  "account.emailForm.emailLabel": "Dirección de e-mail",
  "account.emailForm.emailName": "E-mail",
  "account.emailForm.emailPlaceholder": "email@example.com",
  "account.emailForm.intro": "Si <strong>ya tienes una cuenta mySugr</strong>, introduce la misma dirección de e-mail para asegurarte de que tu paquete se asigna a tu cuenta existente. Hallarás tu dirección de e-mail en tu perfil de la app mySugr.",
  "account.emailForm.proVoucherIntro": "Si <strong>ya tienes una cuenta de mySugr</strong>, introduce la misma dirección de e-mail para asegurarte de que tu vale se asigna a tu cuenta existente. Hallarás tu dirección de e-mail en tu perfil de la app mySugr.",
  "account.loginForm.backButton": "Atrás",
  "account.loginForm.continueButton": "Continuar",
  "account.loginForm.emailLabel": "Tu dirección de e-mail",
  "account.loginForm.emailName": "E-mail",
  "account.loginForm.forgotPassword": "¿Olvidaste tu contraseña?",
  "account.loginForm.passwordLabel": "Introduce tu contraseña",
  "account.loginForm.passwordName": "Contraseña",
  "account.loginForm.passwordResetRequestedText": "Has solicitado restablecer tu contraseña. Comprueba tus e-mails y sigue las instrucciones que te hemos enviado.",
  "account.loginForm.redeemButton": "Canjear",
  "account.loginTitle": "Iniciar sesión",
  "account.loginTitleRedeem": "Inicia sesión para canjear tu vale",
  "account.registerForm.backButton": "Atrás",
  "account.registerForm.consentsName": "Consentimientos",
  "account.registerForm.continueButton": "Continuar",
  "account.registerForm.countryLabel": "Selecciona tu país",
  "account.registerForm.emailLabel": "Tu dirección de e-mail",
  "account.registerForm.emailName": "E-mail",
  "account.registerForm.passwordConfirmLabel": "Repite la contraseña",
  "account.registerForm.passwordConfirmName": "Confirmación de la contraseña",
  "account.registerForm.passwordHint": "Debe tener al menos 6 caracteres",
  "account.registerForm.passwordHintLength": "Debe tener al menos :minLength caracteres",
  "account.registerForm.passwordLabel": "Contraseña",
  "account.registerForm.passwordName": "Contraseña",
  "account.registerForm.redeemButton": "Canjear",
  "account.registerForm.verifiedEmail": "Verificado",
  "account.registerTitle": "Registro",
  "account.registerTitleRedeem": "Crea una cuenta para canjear tu vale",
  "account.resetPassword.checkYourEmail": "Has solicitado restablecer la contraseña.",
  "account.resetPassword.passwordConfirmLabel": "Confirmar la contraseña nueva",
  "account.resetPassword.passwordConfirmName": "Confirmar la contraseña nueva",
  "account.resetPassword.passwordHint": "Debe tener al menos 6 caracteres",
  "account.resetPassword.passwordHintLength": "Debe tener al menos :minLength caracteres",
  "account.resetPassword.passwordLabel": "Introducir la contraseña nueva",
  "account.resetPassword.passwordName": "Introducir la contraseña nueva",
  "account.resetPassword.sentCode": "Hemos enviado un código a tu e-mail",
  "account.resetPasswordCodeVerification.backButton": "Atrás",
  "account.resetPasswordCodeVerification.continueButton": "Verificar el código",
  "account.resetPasswordForm.backButton": "Atrás",
  "account.resetPasswordForm.saveButton": "Guardar contraseña",
  "account.resetPasswordFormText": "Por favor, elige una contraseña nueva para tu cuenta mySugr.",
  "account.resetPasswordTitle": "Restablecer la contraseña",
  "account.stepName": "Cuenta",
  "account.title": "Registro / Inicio de sesión",
  "AccuChekRegistration_description": "Empieza a usar mySugr con tu cuenta de Accu-Chek. <strong><a href=\"https://www.mysugr.com/en/mysugr-and-accu-chek/\" target=\"_blank\" rel=\"noopener\">Más información</a></strong>",
  "AccuChekRegistration_or": "o",
  "activateProTitle": "Activar mySugr PRO",
  "appLegalNotice": "La app mySugr forma parte del Paquete. El uso de la aplicación es necesario para recibir todos los servicios incluidos en el Paquete.",
  "appProvider.title": "Proveedor de aplicaciones",
  "birthdayLabel": "Fecha de nacimiento",
  "bundle.item.alt_text.strips_subscription_guide.description": "basado en tus mediciones importadas (lancetas no incluidas).",
  "bundle.item.alt_text.strips_subscription_guide.name.2": "Abastecimiento automático de tiras reactivas",
  "bundle.item.coach.description": "Ayuda de educadores de diabetes certificados dentro de la app mySugr.",
  "bundle.item.coach.name": "Educador de diabetes con certificación",
  "bundle.item.cuff.description": "Tensiómetro inteligente.",
  "bundle.item.cuff.name": "1x tensiómetro",
  "bundle.item.dkv_coach_1.name": "",
  "bundle.item.fastclix_lancets_2.description": "34 cartuchos de 6 lancetas precargadas cada uno",
  "bundle.item.fastclix_lancets_2.name": "204 lancetas",
  "bundle.item.fastclix_lancets.description": "17 cartuchos de 6 lancetas precargadas cada uno",
  "bundle.item.fastclix_lancets.name": "102 lancetas",
  "bundle.item.fastclix_lancing_kit.description": "Con un cartucho de 6 lancetas precargadas para que no tengas que ver o tocar lancetas individuales.",
  "bundle.item.fastclix_lancing_kit.name": "1 dispositivo de punción Accu-Chek FastClix",
  "bundle.item.guide_3.description": "Envía tus valores de glucemia directamente a la app mySugr.",
  "bundle.item.guide_3.name": "1 medidor Accu-Chek® Guide con dispositivo de punción",
  "bundle.item.guide_4.description": "Envía tus valores de glucemia directamente a la app mySugr.",
  "bundle.item.guide_4.name": "1 medidor Accu-Chek® Guide con dispositivo de punción",
  "bundle.item.guide_me.description": "Envía tus valores de glucemia directamente a la app mySugr.",
  "bundle.item.guide_me.name": "1 medidor Accu-Chek® Guide Me",
  "bundle.item.guide_strips_1.description.country_de": "Cuatro envases de tiras reactivas Accu-Chek® Guide.",
  "bundle.item.guide_strips.description": "Cinco envases de tiras reactivas Accu-Chek® Guide",
  "bundle.item.guide_strips.name": ":amountx tiras reactivas",
  "bundle.item.guide.description": "Envía tus valores de glucemia directamente a la app mySugr.",
  "bundle.item.guide.name": "1 medidor Accu-Chek® Guide",
  "bundle.item.mysugr_coach_voucher_based_1.description": "Ayuda de educadores de diabetes certificados dentro de la app mySugr.",
  "bundle.item.mysugr_coach_voucher_based_1.name": "Educador de diabetes con certificación",
  "bundle.item.mysugr_strips_subscription_guide_2.description": "basada en tus mediciones importadas.",
  "bundle.item.mysugr_strips_subscription_guide_2.name": "Entrega automática de tiras reactivas",
  "bundle.item.mysugr_strips_subscription_guide_2.name.2": "Entrega ilimitada de tiras reactivas",
  "bundle.item.pro.description": "Valores de glucemia sincronizados, HbA1c estimadas, informes en PDF, desafíos, etc.",
  "bundle.item.pro.name": "App mySugr con funciones PRO",
  "bundle.item.scale.description": "Báscula inteligente.",
  "bundle.item.scale.name": "1x báscula",
  "bundle.item.schwenninger_coach_1.description": "",
  "bundle.item.schwenninger_coach_1.name": "",
  "bundle.item.servidigest_coach_1.description": "",
  "bundle.item.servidigest_coach_1.name": "Entrenador de diabetes",
  "bundle.item.softclix_lancets.name": ":amountx 100 lancetas",
  "bundle.item.softclix_lancing_kit.description": "Fácil de usar. Menos dolor.",
  "bundle.item.softclix_lancing_kit.name": "1 dispositivo de punción Accu-Chek® Softclix",
  "bundle.item.strips_subscription_guide.description": "basada en tus mediciones importadas.",
  "bundle.item.strips_subscription_guide.name": "Entrega automática de tiras reactivas",
  "bundle.item.strips_subscription_guide.name.2": "Entrega ilimitada de tiras reactivas",
  "bundleDelivery.text": "Tu paquete llegará en :time días hábiles. En breve recibirás un email con los detalles de la entrega. <b>Todas las entregas posteriores se activan mediante la aplicación.</b>",
  "bundleGroupItemName.activisionblizzard_coach_1": "",
  "bundleGroupItemName.fastclix_lancets_1": "",
  "bundleGroupItemName.fastclix_lancets_2": "",
  "bundleGroupItemName.fastclix_lancing_kit_1": "",
  "bundleGroupItemName.guide_1": "",
  "bundleGroupItemName.guide_3": "",
  "bundleGroupItemName.guide_4": "",
  "bundleGroupItemName.guide_me_1": "",
  "bundleGroupItemName.guide_strips_1": "",
  "bundleGroupItemName.mysugr_coach_1": "",
  "bundleGroupItemName.mysugr_coach_2": "",
  "bundleGroupItemName.mysugr_coach_3": "",
  "bundleGroupItemName.mysugr_coach_5": "",
  "bundleGroupItemName.mysugr_coach_6": "",
  "bundleGroupItemName.mysugr_coach_6_copy": "",
  "bundleGroupItemName.mysugr_pro_1": "",
  "bundleGroupItemName.mysugr_pro_2": "",
  "bundleGroupItemName.mysugr_pro_3": "",
  "bundleGroupItemName.mysugr_pro_4": "",
  "bundleGroupItemName.mysugr_strips_subscription_guide_1": "",
  "bundleGroupItemName.mysugr_strips_subscription_guide_2": "",
  "bundleGroupItemName.mysugr_strips_subscription_guide_3": "",
  "bundleGroupItemName.schwenninger_coach_1": "",
  "bundleGroupItemName.servidigest_coach_1": "",
  "bundleGroupItemName.sigroup_coach_1": "",
  "bundleGroupItemName.us_spanish_coach_1": "",
  "bundleProduct.content.items.coach": "Coach",
  "bundleProduct.content.items.cuff": "Tensiómetro",
  "bundleProduct.content.items.fastclix_lancets": "Lancetas",
  "bundleProduct.content.items.fastclix_lancing_kit": "Dispositivo<br />de punción",
  "bundleProduct.content.items.guide": "Medidor de glucemia",
  "bundleProduct.content.items.guide_me": "Medidor de glucemia",
  "bundleProduct.content.items.mysugr_coach_voucher_based_1": "Coach",
  "bundleProduct.content.items.pro": "App PRO",
  "bundleProduct.content.items.scale": "Báscula",
  "bundleProduct.content.items.servidigest_coach_1": "Coach",
  "bundleProduct.content.items.softclix_lancets": "Lancetas",
  "bundleProduct.content.items.softclix_lancing_kit": "Dispositivo<br />de punción",
  "bundleProduct.content.items.strips_subscription_guide": "Tiras reactivas",
  "bundleProduct.content.title": "Tu Paquete incluye:",
  "bundleProduct.content.title.roche-benefits-us": "",
  "bundleProduct.content.title.roche-benefits-us-coach": "",
  "bundleProduct.continueButton": "Continuar",
  "bundleProduct.countryRestriction.de": "Solo disponible en Alemania",
  "bundleProduct.countryRestriction.sg": "",
  "bundleProduct.countryRestriction.us": "Solo disponible en EE. UU.",
  "bundleProduct.description.list.alt_text.strips_subscription_guide.2": "<strong>Reabastecimiento automático de tiras reactivas</strong>, entrega basada en tu uso (mediciones de glucemia importadas a la app)",
  "bundleProduct.description.list.aok_nordwest_coach_1": "",
  "bundleProduct.description.list.coach": "Acceso exclusivo a un <strong>entrenador de diabetes</strong>",
  "bundleProduct.description.list.dkv_coach_1": "",
  "bundleProduct.description.list.entry_search": "",
  "bundleProduct.description.list.guide": "<strong>Medidor de glucemia</strong>Accu-Chek® Guide conectado",
  "bundleProduct.description.list.guide_me": "<strong>Medidor de glucemia</strong> Accu-Chek® Guide Me conectado",
  "bundleProduct.description.list.meal_photos": "",
  "bundleProduct.description.list.pdf_report": "",
  "bundleProduct.description.list.pro": "Actualización a la <strong>app mySugr PRO</strong>",
  "bundleProduct.description.list.pro.dkv": "Actualización a la <strong>app mySugr PRO</strong>",
  "bundleProduct.description.list.pro.roche-benefits-us": "",
  "bundleProduct.description.list.pro.roche-benefits-us-coach": "",
  "bundleProduct.description.list.reminders": "",
  "bundleProduct.description.list.scale_1": "<strong>Báscula</strong> conectada",
  "bundleProduct.description.list.schwenninger_coach_1": "",
  "bundleProduct.description.list.strips_subscription_guide": "<strong>Tiras reactivas ilimitadas</strong>, enviadas en base a tu uso (mediciones de glucemia importadas a la aplicación)",
  "bundleProduct.description.list.strips_subscription_guide.2": "<strong>Tiras reactivas ilimitadas</strong>, entrega basada en tu uso (mediciones de glucemia importadas a la app)",
  "bundleProduct.description.title": "La diabetes se ha vuelto más fácil...",
  "bundleProduct.description.title.dkv": "",
  "bundleProduct.description.title.dvg": "",
  "bundleProduct.features.items.immediate.coach": "<strong>Acceso a tu entrenador personal</strong> a través de la aplicación",
  "bundleProduct.features.items.immediate.dkv_coach_1": "",
  "bundleProduct.features.items.immediate.mysugr_coach_voucher_based_1": "<strong>Acceso a tu entrenador personal</strong> a través de la aplicación",
  "bundleProduct.features.items.immediate.mysugr_pro_1.dkv": "",
  "bundleProduct.features.items.immediate.pro": "<strong>Funciones PRO</strong> de la app mySugr",
  "bundleProduct.features.items.immediate.schwenninger_coach_1": "",
  "bundleProduct.features.items.immediate.servidigest_coach_1": "",
  "bundleProduct.features.items.product.cuff": "<b>1x</b> tensiómetro",
  "bundleProduct.features.items.product.fastclix_lancets": "<strong>102 lancetas</strong>",
  "bundleProduct.features.items.product.fastclix_lancets_2": "<strong>204 lancetas</strong>",
  "bundleProduct.features.items.product.fastclix_lancing_kit": "<strong>1</strong> <strong>dispositivo de punción</strong> Accu-Chek FastClix",
  "bundleProduct.features.items.product.guide": "<strong>1</strong> <strong>medidor</strong> Accu-Chek® Guide",
  "bundleProduct.features.items.product.guide_me": "<strong>1</strong> <strong>medidor</strong> Accu-Chek® Guide Me",
  "bundleProduct.features.items.product.guide_strips": "<strong>:amountx tiras reactivas</strong>",
  "bundleProduct.features.items.product.scale": "<b>1x</b> báscula",
  "bundleProduct.features.items.product.softclix_lancets": "<strong>:amountx 100 lancetas</strong>",
  "bundleProduct.features.items.product.softclix_lancing_kit": "<strong>1</strong> <strong>dispositivo de punción</strong> Accu-Chek® Softclix",
  "bundleProduct.features.items.subscription.alt_text.strips_subscription_guide.2": "<strong>Reabastecimiento automático de tiras reactivas</strong> basado en tus mediciones importadas (lancetas no incluidas)",
  "bundleProduct.features.items.subscription.aok_nordwest_coach_1": "",
  "bundleProduct.features.items.subscription.coach": "<strong>mySugr Coach</strong> a través de la aplicación",
  "bundleProduct.features.items.subscription.dkv_coach_1": "",
  "bundleProduct.features.items.subscription.mysugr_coach_voucher_based_1": "<strong>mySugr Coach</strong> a través de la aplicación",
  "bundleProduct.features.items.subscription.schwenninger_coach_1": "",
  "bundleProduct.features.items.subscription.servidigest_coach_1": "",
  "bundleProduct.features.items.subscription.strips_subscription_guide": "<strong>Entrega automática de tiras reactivas</strong> basada en tus mediciones importadas",
  "bundleProduct.features.items.subscription.strips_subscription_guide.2": "<strong>Entrega ilimitada de tiras reactivas</strong> basada en las mediciones importadas",
  "bundleProduct.features.title": "Tu Paquete incluye:",
  "bundleProduct.features.title.dkv": "",
  "bundleProduct.features.title.roche-benefits-us": "",
  "bundleProduct.features.title.roche-benefits-us-coach": "",
  "bundleProduct.features.titles.immediate": "Acceso inmediato a:",
  "bundleProduct.features.titles.product": "Tu envío llegará en pocos días con:",
  "bundleProduct.features.titles.subscription": "Acceso continuo a:",
  "bundleProduct.freeShipping": "Envío gratuito",
  "bundleProduct.groupNotFound": "Este paquete no está disponible actualmente.",
  "bundleProduct.minCommitment.info.DAY.1": "Compromiso mínimo de 1 día requerido",
  "bundleProduct.minCommitment.info.DAY.2": "Compromiso mínimo de :minRecurringCount días requerido",
  "bundleProduct.minCommitment.info.MONTH.1": "Compromiso mínimo de 1 mes requerido",
  "bundleProduct.minCommitment.info.MONTH.2": "Compromiso mínimo de :minRecurringCount meses requerido",
  "bundleProduct.minCommitment.info.WEEK.1": "",
  "bundleProduct.minCommitment.info.YEAR.1": "Compromiso mínimo de 1 año requerido",
  "bundleProduct.minCommitment.info.YEAR.2": "Compromiso mínimo de :minRecurringCount años requerido",
  "bundleProduct.noBundle.button": "Inténtalo de nuevo",
  "bundleProduct.noBundle.text": "Lo sentimos, no hemos podido cargar información sobre el paquete.",
  "bundleProduct.paymentInterval.DAY.1.long": "Cada día",
  "bundleProduct.paymentInterval.DAY.1.short": "Diariamente:",
  "bundleProduct.paymentInterval.DAY.2+": "Cada :intervalCount días",
  "bundleProduct.paymentInterval.MONTH.1.long": "Cada mes",
  "bundleProduct.paymentInterval.MONTH.1.short": "Mensualmente:",
  "bundleProduct.paymentInterval.MONTH.2+": "Cada :intervalCount meses",
  "bundleProduct.paymentInterval.WEEK.1.long": "",
  "bundleProduct.paymentInterval.WEEK.1.short": "",
  "bundleProduct.paymentInterval.YEAR.1.long": "Cada año",
  "bundleProduct.paymentInterval.YEAR.1.short": "yearly:",
  "bundleProduct.paymentInterval.YEAR.2+": "Cada :intervalCount años",
  "bundleProduct.price": ":price",
  "cancelAnytime": "Puedes darte de baja de mySugr en cualquier momento",
  "changeCardDetails": "Cambiar detalles de la tarjeta",
  "coachBox.text": "Para leer el mensaje, inicia sesión en la aplicación y aprovecha al máximo tu nueva asistencia personalizada para la diabetes.",
  "coachBox.title": "¡Tu <b>entrenador personal</b> te ha enviado un mensaje!",
  "coachSelection.message.en": "Hello, <br>\nI am your <br>\npersonal <br>\ncoach!",
  "coachSelection.message.es": "Hola,<br>\nsoy tu<br>\n¡entrenador<br>\npersonal!",
  "coachSelection.select.button.en": "Select",
  "coachSelection.select.button.es": "Seleccionar",
  "coachSelection.subtitle": "¿En qué idioma quieres hablar con tu entrenador?",
  "coachSelection.title": "<strong>Selecciona el idioma de tu entrenador</strong>",
  "confirmEmailCodeInputLabel": "Introduce aquí el código que has recibido por e-mail",
  "contact.stepName": "Contacto",
  "contact.title": "Información de <strong>contacto</strong>",
  "continueToNextStep": "Continuar con el siguiente paso",
  "cookieBanner.allowAllButton": "Aceptar todo",
  "cookieBanner.cookieSettingsButton": "Configuración de cookies",
  "cookieBanner.noticeDescription": "<p>Utilizamos cookies para que nuestro sitio web funcione a la perfección y para ofrecerte una experiencia online de primera clase. Al hacer clic en “Aceptar todo”, aceptas que las cookies se almacenen en tu dispositivo para mejorar la navegación por la página web, analizar el uso y ayudarnos con nuestro marketing. Para elegir exactamente qué categorías quieres permitir, solo tienes que ir a “Configuración de cookies”. <a href=\"https://legal.mysugr.com/documents/privacy_policy_web/current/en.html\" rel=\"noopener noreferrer\" target=\"_blank\"><strong style=\"color: #538400;\">Más información</strong></a>.</p>",
  "cookieBanner.rejectAllButton": "Rechazar opcionales",
  "cookieBanner.title": "¡Ñam, ñam, nos encantan las Cookies!",
  "cookieBar.buttonText": "OK",
  "cookieBar.text": "Utilizamos cookies a fin de optimizar y mejorar continuamente nuestro sitio web. Por favor, consulta nuestra <a target=\"_blank\" rel=\"noopener\" href=\":link\" tabindex=\":tabIndex\">política de privacidad</a> para obtener más información sobre las cookies y como aceptarlas o rechazarlas.",
  "cookieBar.text.global": "Utilizamos cookies a fin de optimizar y mejorar continuamente nuestro sitio web. Por favor, consulta nuestra política de privacidad (<a target=\"_blank\" rel=\"noopener\" href=\":linkUs\" tabindex=\":tabIndex\">EE. UU.</a>, <a target=\"_blank\" rel=\"noopener\" href=\":linkEu\" tabindex=\":tabIndex\">UE</a>) para obtener más información sobre las cookies y como aceptarlas o rechazarlas.",
  "cookieCategory.functionalDescription": "El procesamiento y las cookies funcionales permiten que nuestras páginas web ofrezcan funciones y personalización avanzadas. Con tu consentimiento, pueden ser instaladas por nosotros o por terceros cuyos servicios hemos añadido a nuestras páginas web. Si rechazas estas cookies, es posible que algunos o todos estos servicios no funcionen correctamente.",
  "cookieCategory.functionalTitle": "Cookies funcionales",
  "cookieCategory.performanceDescription": "Estas cookies nos permiten contar las visitas y las fuentes de tráfico para poder medir y mejorar el rendimiento de nuestro sitio web. Nos ayudan a comprender cómo se utilizan nuestras páginas web, por ejemplo, cuáles son las más o menos populares y cómo navega la gente en ellas. La información recogida por estas cookies se agrega y, por lo tanto, es anónima. Si no permites esta categoría de cookies, no podremos saber cuándo has visitado nuestra página web y no podremos controlar el rendimiento de la misma. En algunos casos, estas cookies pueden ser enviadas a nuestros proveedores de servicios externos para ayudarnos a gestionar nuestros análisis.",
  "cookieCategory.performanceTitle": "Cookies de rendimiento",
  "cookieCategory.socialMediaDescription": "Con tu consentimiento, las cookies de las redes sociales son instaladas por una serie de servicios de redes sociales que hemos añadido al sitio web (como Facebook, Twitter, LinkedIn) para permitirte compartir nuestro contenido con tus amigos, colegas y redes. Estas cookies pueden rastrear tu navegador a través de sitios web de terceros y crear un perfil de tus intereses. Esto puede afectar al contenido y a los mensajes que ves en los sitios web de terceros que visitas. Si no permites estas cookies, es posible que no puedas utilizar o ver estas herramientas para uso compartido.",
  "cookieCategory.socialMediaTitle": "Cookies de redes sociales",
  "cookieCategory.strictlyNecessaryDescription": "Estas cookies son estrictamente necesarias para el funcionamiento del sitio web y no se pueden desactivar en nuestros sistemas. Solo se instalan en respuesta a acciones que realizas tú y que equivalen a una solicitud de servicios, tales como configurar tus preferencias de privacidad y cookies, iniciar sesión o rellenar formularios. Puedes configurar tu navegador para que bloquee o te informe sobre estas cookies. Sin embargo, si las bloqueas, las páginas web no funcionarán.",
  "cookieCategory.strictlyNecessaryTitle": "Cookies estrictamente necesarias",
  "cookieCategory.targetingDescription": "Con tu consentimiento, estas cookies pueden ser instaladas por nuestros socios publicitarios a través de nuestro sitio web. Dichas empresas pueden utilizarlas para elaborar un perfil de tus intereses y mostrarte anuncios relevantes en otras páginas web. No almacenan directamente información personal, sino que se basan en una identificación única de tu navegador y tu dispositivo de Internet. Si no permites estas cookies, se te mostrarán anuncios menos específicos. mySugr utiliza estas cookies para hacer un seguimiento del éxito de las campañas de marketing digital que promueven nuestros productos y servicios.",
  "cookieCategory.targetingTitle": "Cookies para fines de marketing",
  "cookieList.category": "Categoría",
  "cookieList.description": "Descripción",
  "cookieList.duration": "Duración",
  "cookieList.host": "Host",
  "cookieList.name": "Nombre",
  "cookieList.type": "Tipo",
  "cookiePreferenceCenter.allowAllButton": "Aceptar todas las cookies",
  "cookiePreferenceCenter.alwaysActiveTitle": "Siempre activas",
  "cookiePreferenceCenter.categoriesTitle": "Gestionar preferencias de consentimiento",
  "cookiePreferenceCenter.confirmButton": "Aceptar las cookies seleccionadas",
  "cookiePreferenceCenter.cookieListLink": "Detalles de las cookies",
  "cookiePreferenceCenter.cookiePolicyLink": "Puedes encontrar la política de privacidad y cookies de mySugr aquí.",
  "cookiePreferenceCenter.cookiesListTitle": "Lista de cookies",
  "cookiePreferenceCenter.description": "El centro de preferencias de privacidad te permite activar varias categorías de cookies, puedes cambiar la configuración en cualquier momento.\nSi deseas cambiar la configuración en el futuro, utiliza el enlace “Configuración de cookies” situado en la parte inferior de la página web.",
  "cookiePreferenceCenter.firstPartyCookies": "Cookies propias",
  "cookiePreferenceCenter.optionalGroupName": "Opcional",
  "cookiePreferenceCenter.rejectAllButton": "Rechazar cookies opcionales",
  "cookiePreferenceCenter.strictlyNecessaryGroupName": "Estrictamente necesarias",
  "cookiePreferenceCenter.title": "Centro de preferencias de privacidad",
  "cookiePreferenceCenter.viewCookiesButton": "Ver cookies",
  "countres.gu": "Guam",
  "countries.ad": "Andorra",
  "countries.ae": "Emiratos Árabes Unidos",
  "countries.af": "Afganistán",
  "countries.ag": "Antigua y Barbuda",
  "countries.ai": "Anguila",
  "countries.al": "Albania",
  "countries.am": "Armenia",
  "countries.ao": "Angola",
  "countries.aq": "Antártida",
  "countries.ar": "Argentina",
  "countries.as": "Samoa Americana",
  "countries.at": "Austria",
  "countries.au": "Australia",
  "countries.aw": "Aruba",
  "countries.ax": "Islas Åland",
  "countries.az": "Azerbaiyán",
  "countries.ba": "Bosnia y Herzegovina",
  "countries.bb": "Barbados",
  "countries.bd": "Bangladesh",
  "countries.be": "Bélgica",
  "countries.bf": "Burkina Faso",
  "countries.bg": "Bulgaria",
  "countries.bh": "Bahrein",
  "countries.bi": "Burundi",
  "countries.bj": "Benin",
  "countries.bl": "San Bartolomé",
  "countries.bm": "Bermudas",
  "countries.bn": "Brunei Darussalam",
  "countries.bo": "Bolivia",
  "countries.bq": "Bonaire",
  "countries.br": "Brasil",
  "countries.bs": "Bahamas",
  "countries.bt": "Bután",
  "countries.bv": "Isla Bouvet",
  "countries.bw": "Botsuana",
  "countries.by": "Bielorrusia",
  "countries.bz": "Belice",
  "countries.cc": "Islas Cocos (Keeling)",
  "countries.cd": "Congo (la República Democrática del)",
  "countries.cf": "República Centroafricana",
  "countries.cg": "Congo",
  "countries.ch": "Suiza",
  "countries.ci": "Côte d'Ivoire",
  "countries.ck": "Islas Cook",
  "countries.cl": "Chile",
  "countries.cm": "Camerún",
  "countries.cn": "Canadá",
  "countries.co": "Colombia",
  "countries.cr": "Costa Rica",
  "countries.cu": "Cuba",
  "countries.cv": "Cabo Verde",
  "countries.cw": "Curaçao",
  "countries.cx": "Isla de Navidad",
  "countries.cy": "Chipre",
  "countries.cz": "Chequia",
  "countries.de": "Alemania",
  "countries.dj": "Yibuti",
  "countries.dk": "Dinamarca",
  "countries.dm": "Dominica",
  "countries.do": "República Dominicana",
  "countries.dz": "Argelia",
  "countries.ec": "Ecuador",
  "countries.ee": "Estonia",
  "countries.eg": "Egipto",
  "countries.eh": "Sahara Occidental",
  "countries.er": "Eritrea",
  "countries.es": "España",
  "countries.et": "Etiopía",
  "countries.fi": "Finlandia",
  "countries.fj": "Fiyi",
  "countries.fk": "Islas Malvinas (Falkland Islands)",
  "countries.fm": "Micronesia (Estados Federados de)",
  "countries.fo": "Islas Feroe",
  "countries.fr": "Francia",
  "countries.ga": "Gabón",
  "countries.gb": "Reino Unido",
  "countries.gd": "Granada",
  "countries.ge": "Georgia",
  "countries.gf": "Guayana Francesa",
  "countries.gg": "Guernsey",
  "countries.gh": "Ghana",
  "countries.gi": "Gibraltar",
  "countries.gl": "Groenlandia",
  "countries.gm": "Gambia",
  "countries.gn": "Guinea",
  "countries.gp": "Guadalupe",
  "countries.gq": "Guinea Ecuatorial",
  "countries.gr": "Grecia",
  "countries.gs": "Georgia del Sur y las Islas Sandwich del Sur",
  "countries.gt": "Guatemala",
  "countries.gw": "Guinea-Bissau",
  "countries.gy": "Guayana",
  "countries.hk": "Hong Kong",
  "countries.hm": "Islas Heard y McDonald",
  "countries.hn": "Honduras",
  "countries.hr": "Croacia",
  "countries.ht": "Haití",
  "countries.hu": "Hungría",
  "countries.id": "Indonesia",
  "countries.ie": "Irlanda",
  "countries.il": "Israel",
  "countries.im": "Isla de Man",
  "countries.in": "India",
  "countries.io": "Territorio Británico del Océano Índico",
  "countries.iq": "Iraq",
  "countries.ir": "Irán (República Islámica de)",
  "countries.is": "Islandia",
  "countries.it": "Italia",
  "countries.je": "Jersey",
  "countries.jm": "Jamaica",
  "countries.jo": "Jordania",
  "countries.jp": "Japón",
  "countries.ke": "Kenia",
  "countries.kg": "Kirguistán",
  "countries.kh": "Camboya",
  "countries.ki": "Kiribati",
  "countries.km": "Comoras",
  "countries.kn": "San Cristóbal y Nieves",
  "countries.kp": "Corea (República Popular Democrática de)",
  "countries.kr": "Corea (República de)",
  "countries.kw": "Kuwait",
  "countries.ky": "Islas Caimán",
  "countries.kz": "Kazajstán",
  "countries.la": "Lao (República Democrática Popular)",
  "countries.lb": "Líbano",
  "countries.lc": "Santa Lucía",
  "countries.li": "Liechtenstein",
  "countries.lk": "Sri Lanka",
  "countries.lr": "Liberia",
  "countries.ls": "Lesoto",
  "countries.lt": "Lituania",
  "countries.lu": "Luxemburgo",
  "countries.lv": "Letonia",
  "countries.ly": "Libia",
  "countries.ma": "Marruecos",
  "countries.mc": "Mónaco",
  "countries.md": "Moldavia (República de)",
  "countries.me": "Montenegro",
  "countries.mf": "San Martín (parte francesa)",
  "countries.mg": "Madagascar",
  "countries.mh": "Islas Marshall",
  "countries.mk": "Macedonia (Antigua República Yugoslava de)",
  "countries.ml": "Malí",
  "countries.mm": "Myanmar",
  "countries.mn": "Mongolia",
  "countries.mo": "Macao",
  "countries.mp": "Islas Marianas del Norte",
  "countries.mq": "Martinica",
  "countries.mr": "Mauritania",
  "countries.ms": "Montserrat",
  "countries.mt": "Malta",
  "countries.mu": "Mauricio",
  "countries.mv": "Maldivas",
  "countries.mw": "Malaui",
  "countries.mx": "México",
  "countries.my": "Malasia",
  "countries.mz": "Mozambique",
  "countries.na": "Namibia",
  "countries.nc": "Nueva Caledonia",
  "countries.ne": "Níger",
  "countries.nf": "Isla Norfolk",
  "countries.ng": "Nigeria",
  "countries.ni": "Nicaragua",
  "countries.nl": "Países Bajos",
  "countries.no": "Noruega",
  "countries.np": "Nepal",
  "countries.nr": "Nauru",
  "countries.nu": "Niue",
  "countries.nz": "Nueva Zelanda",
  "countries.om": "Omán",
  "countries.pa": "Panamá",
  "countries.pe": "Perú",
  "countries.pf": "Polinesia Francesa",
  "countries.pg": "Papúa Nueva Guinea",
  "countries.ph": "Filipinas",
  "countries.pk": "Pakistán",
  "countries.pl": "Polonia",
  "countries.pm": "San Pedro y Miquelón",
  "countries.pn": "Pitcairn",
  "countries.pr": "Puerto Rico",
  "countries.ps": "Territorio Palestino, Ocupado",
  "countries.pt": "Portugal",
  "countries.pw": "Palau",
  "countries.py": "Paraguay",
  "countries.qa": "Qatar",
  "countries.re": "Reunión",
  "countries.ro": "Rumania",
  "countries.rs": "Serbia",
  "countries.ru": "Federación de Rusia",
  "countries.rw": "Ruanda",
  "countries.sa": "Arabia Saudita",
  "countries.sb": "Islas Salomón",
  "countries.sc": "Seychelles",
  "countries.sd": "Sudán",
  "countries.se": "Suecia",
  "countries.sg": "Singapur",
  "countries.sh": "Santa Elena, Ascensión y Tristán de Acuña",
  "countries.si": "Eslovenia",
  "countries.sj": "Svalbard y Jan Mayen",
  "countries.sk": "Eslovaquia",
  "countries.sl": "Sierra Leona",
  "countries.sm": "San Marino",
  "countries.sn": "Senegal",
  "countries.so": "Somalia",
  "countries.sr": "Surinam",
  "countries.ss": "Sudán del Sur",
  "countries.st": "Santo Tomé y Príncipe",
  "countries.sv": "El Salvador",
  "countries.sx": "Sint Maarten (parte neerlandesa)",
  "countries.sy": "República Árabe Siria",
  "countries.sz": "Suazilandia",
  "countries.tc": "Islas Turcas y Caicos",
  "countries.td": "Chad",
  "countries.tf": "Territorios Australes Franceses",
  "countries.tg": "Togo",
  "countries.th": "Tailandia",
  "countries.tj": "Tayikistán",
  "countries.tk": "Tokelau",
  "countries.tl": "Timor-Leste",
  "countries.tm": "Turkmenistán",
  "countries.tn": "Túnez",
  "countries.to": "Tonga",
  "countries.tr": "Turquía",
  "countries.tt": "Trinidad y Tobago",
  "countries.tv": "Tuvalu",
  "countries.tw": "Taiwán",
  "countries.tz": "Tanzania (República Unida de)",
  "countries.ua": "Ucrania",
  "countries.ug": "Uganda",
  "countries.um": "Islas Ultramarinas Menores de los Estados Unidos",
  "countries.us": "Estados Unidos de América",
  "countries.uy": "Uruguay",
  "countries.uz": "Uzbekistán",
  "countries.va": "Santa Sede (Ciudad del Vaticano)",
  "countries.vc": "San Vicente y las Granadinas",
  "countries.ve": "Venezuela (República Bolivariana de)",
  "countries.vg": "Islas Vírgenes Británicas",
  "countries.vi": "Islas Vírgenes de los Estados Unidos",
  "countries.vn": "Vietnam",
  "countries.vu": "Vanuatu",
  "countries.wf": "Wallis y Futuna",
  "countries.ws": "Samoa",
  "countries.ye": "Yemen",
  "countries.yt": "Mayotte",
  "countries.za": "Sudáfrica",
  "countries.zm": "Zambia",
  "countries.zw": "Zimbabue",
  "countryNames.de.long": "Alemania",
  "countryNames.de.medium": "Alemania",
  "countryNames.de.short": "DE",
  "countryNames.us.long": "Estados Unidos de América",
  "countryNames.us.medium": "EE.UU.",
  "countryNames.us.short": "EE.UU.",
  "cta.redeemVoucher": "Canjear vale",
  "cta.unlockFree": "Desbloquear gratis",
  "customCodeVerificationForm.label.dvg": "",
  "customCodeVerificationForm.label.optumrx_1": "ID de OptumRX",
  "customCodeVerificationForm.overlayButtonText": "más información",
  "customCodeVerificationForm.overlayText.dvg": "",
  "customCodeVerificationForm.overlayText.optumrx_1": "",
  "customCodeVerificationForm.placeholder.dvg": " ",
  "customCodeVerificationForm.placeholder.optumrx_1": " ",
  "downloadApp.desktop.text": "Para descargar la aplicación, escanea el <br> código QR de abajo con la cámara de tu teléfono",
  "downloadApp.mobile.text": "Puedes descargar la app aquí:",
  "downloadApp.text": "Para completar el registro, <b>descarga la app mySugr</b> desde la App Store e inicia sesión con tu dirección de email.",
  "downloadApp.title": "<b>¡Una cosa más!</b>",
  "eligibilityCheck.failure.changeEmailButton": "Cambiar dirección de e-mail",
  "eligibilityCheck.failure.monsterAlt": "",
  "eligibilityCheck.failure.title": "",
  "eligibilityCheck.failure.unsuccessfulText": "",
  "eligibilityCheck.form.companyEmail.label": "",
  "eligibilityCheck.form.continue": "",
  "eligibilityCheck.form.overlayText": "<p>Introduce la dirección de e-mail emitida por tu empresa para comprobar si tienes derecho a recibir el paquete mySugr.</p>\n\n<p>Si tienes derecho a recibir el paquete mySugr te enviaremos un e-mail con un enlace para darte de alta en el paquete.</p>\n\n<p>Solo usamos la dirección de e-mail emitida por tu empresa para comprobar si eres elegible para recibir el paquete. Cuando te inscribas en el programa, podrás darte de alta con tu cuenta de mySugr existente o crear una nueva con la dirección de e-mail que prefieras.</p>",
  "eligibilityCheck.introText": "",
  "eligibilityCheck.pageTitle": "",
  "eligibilityCheck.resend.notification.failure": "No hemos podido reenviar el e-mail de verificación de elegibilidad.",
  "eligibilityCheck.resend.notification.success": "Hemos vuelto a enviar por e-mail tu verificación de elegibilidad.",
  "eligibilityCheck.success.boxAlt": "",
  "eligibilityCheck.success.boxWithHeartAlt": "Corazón saliendo de una caja",
  "eligibilityCheck.success.changeEmailButton": "Cambiar dirección de e-mail",
  "eligibilityCheck.success.resendButton": "Reenviar e-mail",
  "eligibilityCheck.success.resendText": "¿Has recibido el código de tu vale?",
  "eligibilityCheck.success.successfullText": "Tu empresa cubre el Paquete mySugr.<br>\nTe hemos enviado un e-mail con el código del vale para registrarte.",
  "eligibilityCheck.success.title": "La dirección de e-mail es elegible para el Paquete mySugr",
  "eligibilityCheck.title": "",
  "email.stepName": "Registro / Inicio de sesión",
  "endsWithNumber": "Termina en:",
  "enroll": "Inscribirse",
  "errors.ADYEN_REFUSED_3D_NOT_AUTHENTICATED": "La autenticación 3D Secure ha fallado o ha sido cancelada",
  "errors.ADYEN_REFUSED_3D_SECURE_AUTHENTICATION_FAILED": "La autenticación 3D Secure ha fallado",
  "errors.ADYEN_REFUSED_CVC_DECLINED": "El CVC ha sido rechazado",
  "errors.ADYEN_REFUSED_EXPIRED_CARD": "Tarjeta caducada.",
  "errors.ADYEN_REFUSED_FRAUD": "Hay un problema con tu tarjeta de crédito.",
  "errors.ADYEN_REFUSED_INVALID_CARD_NUMBER": "El número de tarjeta no es válido.",
  "errors.ADYEN_REFUSED_REFUSED": "La tarjeta de crédito ha sido rechazada",
  "errors.ASSIGN_OPTUM_RX_ERROR.invalid_input": "Esta identificación de paciente de OptumRx no es válida",
  "errors.ASSIGN_OPTUM_RX_ERROR.patient_already_assigned": "Esta identificación de paciente de OptumRx ya se ha utilizado",
  "errors.CARD_DECLINED": "La tarjeta ha sido rechazada.",
  "errors.CARD_NOT_SUPPORTED.cardNumber": "Tu tarjeta no es admisible.",
  "errors.COUNTRY_REGION_MISSMATCH": "La oferta que estás intentando canjear no está disponible actualmente en :nameCountry. Solo está disponible para los usuarios que almacenan sus datos en :offerRegions. Ponte en contacto con el servicio de atención al cliente.",
  "errors.DECLINED_CARD.cardNumber": "La tarjeta ha sido rechazada.",
  "errors.EMPTY_CART": "Tu cesta está vacía. Tienes que seleccionar un paquete antes de suscribirte.",
  "errors.EXISTS_IN_OTHER_REGION": "El vale ya ha sido canjeado o tus datos de usuario se almacenan en una zona diferente a la de la oferta. Ponte en contacto con el servicio de atención al cliente.",
  "errors.EXPIRED_CARD.cardNumber": "Tu tarjeta ha caducado",
  "errors.INCOMPLETE_CARD_CVC.cvc": "Es obligatorio indicar el CVC/CVV.",
  "errors.INCOMPLETE_CARD_EXPIRATION.expiration": "Es obligatorio indicar la fecha de caducidad de la tarjeta.",
  "errors.INCOMPLETE_CARD_NUMBER.cardNumber": "Es obligatorio indicar el número de la tarjeta.",
  "errors.INCORRECT_CVC": "El CVC/CVV no es correcto.",
  "errors.INSUFFICIENT_FUNDS": "Tu tarjeta ha sido rechazada porque no tiene fondos suficientes.",
  "errors.INVALID_AGE.birthdate": "Tu edad debe ser superior a :minAge años para suscribirte",
  "errors.INVALID_BIRTHDATE.birthdate": "Fecha de nacimiento no válida",
  "errors.INVALID_CARD_CVC_NUMBER.cvc": "El CVC/CVV no es correcto.",
  "errors.INVALID_CARD_EXPIRATION_MONTH_PAST.expiration": "El mes de caducidad es anterior al actual.",
  "errors.INVALID_CARD_EXPIRATION_YEAR_PAST.expiration": "El año de caducidad es anterior al actual.",
  "errors.INVALID_CARD_NUMBER.cardNumber": "El número de tarjeta no es válido.",
  "errors.INVALID_COUNTRY": "Esta oferta no es compatible con el país registrado para tu usuario, por favor contacta con el servicio de asistencia para resolver este problema.",
  "errors.INVALID_EMAIL_CONFIRMATION_CODE.code": "Código de confirmación de e-mail no válido",
  "errors.INVALID_EMAIL.companyEmail": "Dirección de e-mail no válida",
  "errors.INVALID_EMAIL.email": "Dirección de e-mail no válida",
  "errors.INVALID_GERMAN_STATE.state": "Selecciona un estado.",
  "errors.INVALID_INSURANCE_CARD_INFO.groupNumber": "ID no válido",
  "errors.INVALID_INSURANCE_CARD_INFO.memberId": "ID no válido",
  "errors.INVALID_INSURANCE_NUMBER.insuranceNumber": "Número de seguro no válido",
  "errors.INVALID_LOGIN": "La dirección de e-mail y la contraseña no coinciden con un usuario de mySugr",
  "errors.INVALID_PASSWORD_CONFIRMATION.password2": "La confirmación de la contraseña no coincide con la contraseña.",
  "errors.INVALID_PHONE_NUMBER.phone": "El número de teléfono no es válido",
  "errors.INVALID_POSTAL_CODE.postalCode": "El código postal no es válido",
  "errors.INVALID_US_STATE.state": "Selecciona un estado.",
  "errors.INVALID_VOUCHER_CODE.voucherCode": "Inválido",
  "errors.INVALID_ZIP_CODE.postalCode": "El código postal no es válido",
  "errors.INVALID.customCodeVerification": "ID no válido",
  "errors.INVALID.customCodeVerification.dvg": "",
  "errors.MIN_LENGTH.password": "La contraseña debe tener al menos :minLength caracteres.",
  "errors.MIN_LENGTH.password2": "La contraseña debe tener al menos :minLength caracteres.",
  "errors.MISSING_CONSENT.consents": "Tienes que aceptar :title.",
  "errors.MISSING_CONSENTS": "No hemos podido procesar tu pedido. Ponte en contacto con support@mysugr.com",
  "errors.MISSING_CONSENTS_FAILED": "No hemos podido procesar tu pedido. Ponte en contacto con support@mysugr.com",
  "errors.MISSING_FIELD.address": "Es obligatorio indicar la dirección",
  "errors.MISSING_FIELD.birthdate": "Es obligatorio indicar la fecha de nacimiento",
  "errors.MISSING_FIELD.birthdateCheckbox": "Los participantes deben tener la edad mínima requerida para poder suscribirse",
  "errors.MISSING_FIELD.city": "Es obligatorio indicar la población",
  "errors.MISSING_FIELD.companyEmail": "Es obligatorio indicar el e-mail",
  "errors.MISSING_FIELD.country": "Es obligatorio indicar el país",
  "errors.MISSING_FIELD.email": "Es obligatorio indicar el e-mail",
  "errors.MISSING_FIELD.employer": "Por favor, selecciona un empleador",
  "errors.MISSING_FIELD.firstName": "Es obligatorio indicar el nombre",
  "errors.MISSING_FIELD.lastName": "Es obligatorio indicar los apellidos",
  "errors.MISSING_FIELD.name": "Es obligatorio indicar el nombre",
  "errors.MISSING_FIELD.password": "Por favor, introduce una contraseña",
  "errors.MISSING_FIELD.password2": "Por favor, repite la contraseña",
  "errors.MISSING_FIELD.phone": "Es obligatorio indicar el número de teléfono",
  "errors.MISSING_FIELD.postalCode": "Es obligatorio indicar el código postal",
  "errors.MISSING_FIELD.state": "Es obligatorio indicar el estado",
  "errors.MISSING_FIELD.voucherCode": "Por favor, introduce el código de tu vale",
  "errors.PASSWORD_RESET_TOKEN_EXPIRED": "El token de restablecimiento de contraseña ha expirado.",
  "errors.PAYMENT_DETAILS_NOT_SUPPORTED": "Tipo de tarjeta no admisible.",
  "errors.POSSIBLE_PO_BOX.address": "Esta dirección parece un apartado de correos. Por razones legales no podemos entregar a apartados de correos.",
  "errors.PRESCRIPTION_ALREADY_ACTIVE": "",
  "errors.PROCESSING_ERROR": "No hemos podido procesar tu tarjeta.",
  "errors.SERVER_ERROR": "Error al contactar con el servidor mySugr",
  "errors.STRIPE_ERROR": "No se ha podido inicializar el pago",
  "errors.THE_VOUCHER_IS_CURRENTLY_NOT_ACTIVE.voucherCode": "Este vale no está activo actualmente",
  "errors.undefined": "Error indefinido",
  "errors.UNKNOWN_ERROR": "Algo ha fallado.",
  "errors.UNKNOWN_ERROR_2": "Vaya, algo ha fallado",
  "errors.UNLOCK_CODE_ALREADY_USED": "Este código de activación ya se ha utilizado.",
  "errors.UNLOCK_CODE_INVALID": "",
  "errors.USER_ALREADY_SUBSCRIBED_TO_BUNDLE": "Ya estás suscrito al Paquete mySugr.",
  "errors.USER_ALREADY_SUBSCRIBED_TO_PLAN": "Ya estás suscrito al Paquete mySugr.",
  "errors.USER_EMAIL_HAS_ACTIVE_BUNDLE": "Ya te has registrado para el Paquete mySugr con este e-mail.",
  "errors.USER_EMAIL_HAS_ACTIVE_PRESCRIPTION": "",
  "errors.USER_EXISTS.email": "El usuario ya existe",
  "errors.USER_HAS_NO_BUNDLE": "Solo es posible modificar los detalles de pago con una suscripción activa al paquete mySugr.",
  "errors.USER_HAS_NO_EXISTING_PAYMENT": "No se ha encontrado información de pago",
  "errors.USER_MISSING": "Ha habido un problema con tu suscripción.\nPor favor, contacta con nuestro soporte.",
  "errors.VOUCHER_ALREADY_EXPIRED.voucherCode": "Este vale ha caducado",
  "errors.VOUCHER_ALREADY_REDEEMED": "El vale ya ha sido canjeado para esta cuenta",
  "errors.VOUCHER_DOES_NOT_EXISTS.voucherCode": "Este vale no existe",
  "errors.VOUCHER_MAXIMUM_REDEMPTION_EXCEEDED": "Este código de vale ya ha sido canjeado con demasiada frecuencia, ya no es válido.",
  "errors.VOUCHER_MAXIMUM_REDEMPTION_EXCEEDED.voucherCode": "Este código de vale ya ha sido canjeado con demasiada frecuencia, ya no es válido.",
  "footer.callSupport": "Llama gratis al: <strong>:phone</strong>",
  "footer.happyToHelp": "Siempre estamos encantados de ayudarte:",
  "footer.headline": "¿Tienes preguntas sobre tu pedido?",
  "footer.legalNotice.activision_blizzard_1": "",
  "footer.legalNotice.allianz_1": "",
  "footer.legalNotice.aok_1": "",
  "footer.legalNotice.aok_nordwest_1": "",
  "footer.legalNotice.barmenia_1": "",
  "footer.legalNotice.barmer_1": "",
  "footer.legalNotice.bi_state_development_1": "Al elegir el Paquete mySugr estás sujeto a todos nuestros Términos y Condiciones, que se detallan a continuación. Si deseas darte de baja puedes enviar un correo electrónico en cualquier momento a <a href=\"mailto::email\">:email</a>, tu solicitud de cancelación será procesada con prontitud. La Aplicación mySugr y mySugr Coach se proporcionarán en forma de un vale. Al descargar la aplicación y realizar el proceso de registro, aceptas los Términos y Condiciones y la Política de Privacidad de mySugr GmbH. Este sitio web contiene información sobre productos que están dirigidos a una amplia gama de audiencias y podría contener detalles del producto o información que de otra manera no sería accesible o válida en tu país. Ten en cuenta que no asumimos ninguna responsabilidad por el acceso a dicha información, la cual puede no cumplir con algún proceso legal, regulación, registro o uso válido en tu país de origen. © 2018 mySugr, Inc. Todos los derechos reservados. MYSUGR, ACCU-CHEK y ACCU-CHEK GUIDE son marcas registradas de Roche. Todos los demás nombres de productos y marcas son propiedad de sus respectivos propietarios.<br/>© 2019 Roche Diabetes Care.",
  "footer.legalNotice.buchanan_us_1": "Al elegir el Paquete mySugr estás sujeto a todos nuestros Términos y Condiciones, que se detallan a continuación. Si deseas darte de baja puedes enviar un correo electrónico en cualquier momento a <a href=\"mailto::email\">:email</a>, tu solicitud de cancelación será procesada con prontitud.  Al descargar la app y realizar el proceso de registro, aceptas los Términos y Condiciones y la Política de Privacidad de mySugr GmbH. Este sitio web contiene información sobre productos que están dirigidos a una amplia gama de audiencias y podría contener detalles del producto o información que de otra manera no sería accesible o válida en tu país. Ten en cuenta que no asumimos ninguna responsabilidad por el acceso a dicha información, la cual puede no cumplir con algún proceso legal, regulación, registro o uso válido en tu país de origen. © 2018 mySugr, Inc. Todos los derechos reservados. MYSUGR, ACCU-CHEK y ACCU-CHEK GUIDE son marcas registradas de Roche. Todos los demás nombres de productos y marcas son propiedad de sus respectivos propietarios.<br/>© 2019 Roche Diabetes Care.",
  "footer.legalNotice.dkv_1": "",
  "footer.legalNotice.ed_voyles_1": "Al elegir el Paquete mySugr estás sujeto a todos nuestros Términos y Condiciones, que se detallan a continuación. Si deseas darte de baja puedes enviar un correo electrónico en cualquier momento a <a href=\"mailto::email\">:email</a>, tu solicitud de cancelación será procesada con prontitud.  Al descargar la app y realizar el proceso de registro, aceptas los Términos y Condiciones y la Política de Privacidad de mySugr GmbH. Este sitio web contiene información sobre productos que están dirigidos a una amplia gama de audiencias y podría contener detalles del producto o información que de otra manera no sería accesible o válida en tu país. Ten en cuenta que no asumimos ninguna responsabilidad por el acceso a dicha información, la cual puede no cumplir con algún proceso legal, regulación, registro o uso válido en tu país de origen. © 2018 mySugr, Inc. Todos los derechos reservados. MYSUGR, ACCU-CHEK y ACCU-CHEK GUIDE son marcas registradas de Roche. Todos los demás nombres de productos y marcas son propiedad de sus respectivos propietarios.<br/>© 2019 Roche Diabetes Care.",
  "footer.legalNotice.garfield_county_1": "Al elegir el Paquete mySugr estás sujeto a todos nuestros Términos y Condiciones, que se detallan a continuación. Si deseas darte de baja puedes enviar un correo electrónico en cualquier momento a <a href=\"mailto::email\">:email</a>, tu solicitud de cancelación será procesada con prontitud.  Al descargar la app y realizar el proceso de registro, aceptas los Términos y Condiciones y la Política de Privacidad de mySugr GmbH. Este sitio web contiene información sobre productos que están dirigidos a una amplia gama de audiencias y podría contener detalles del producto o información que de otra manera no sería accesible o válida en tu país. Ten en cuenta que no asumimos ninguna responsabilidad por el acceso a dicha información, la cual puede no cumplir con algún proceso legal, regulación, registro o uso válido en tu país de origen. © 2018 mySugr, Inc. Todos los derechos reservados. MYSUGR, ACCU-CHEK y ACCU-CHEK GUIDE son marcas registradas de Roche. Todos los demás nombres de productos y marcas son propiedad de sus respectivos propietarios.<br/>© 2019 Roche Diabetes Care.",
  "footer.legalNotice.genentech_1": "Al elegir el Paquete mySugr estás sujeto a todos nuestros Términos y Condiciones, que se detallan a continuación. Si deseas darte de baja puedes enviar un correo electrónico en cualquier momento a <a href=\"mailto::email\">:email</a>, tu solicitud de cancelación será procesada con prontitud. La App mySugr y mySugr Coach se proporcionarán en forma de un vale. Al descargar la app y realizar el proceso de registro, aceptas los Términos y Condiciones y la Política de Privacidad de mySugr GmbH. Este sitio web contiene información sobre productos que están dirigidos a una amplia gama de audiencias y podría contener detalles del producto o información que de otra manera no sería accesible o válida en tu país. Ten en cuenta que no asumimos ninguna responsabilidad por el acceso a dicha información, la cual puede no cumplir con algún proceso legal, regulación, registro o uso válido en tu país de origen. © 2018 mySugr, Inc. Todos los derechos reservados. MYSUGR, ACCU-CHEK y ACCU-CHEK GUIDE son marcas registradas de Roche. Todos los demás nombres de productos y marcas son propiedad de sus respectivos propietarios.<br/>© 2019 Roche Diabetes Care.",
  "footer.legalNotice.gothaer_1": "",
  "footer.legalNotice.inter_1": "",
  "footer.legalNotice.jbz_1": "",
  "footer.legalNotice.lazer_spot_1": "Al elegir el Paquete mySugr estás sujeto a todos nuestros Términos y Condiciones, que se detallan a continuación. Si deseas darte de baja puedes enviar un correo electrónico en cualquier momento a <a href=\"mailto::email\">:email</a>, tu solicitud de cancelación será procesada con prontitud. Al descargar la app y realizar el proceso de registro, aceptas los Términos y Condiciones y la Política de Privacidad de mySugr GmbH. Este sitio web contiene información sobre productos que están dirigidos a una amplia gama de audiencias y podría contener detalles del producto o información que de otra manera no sería accesible o válida en tu país. Ten en cuenta que no asumimos ninguna responsabilidad por el acceso a dicha información, la cual puede no cumplir con algún proceso legal, regulación, registro o uso válido en tu país de origen. © 2018 mySugr, Inc. Todos los derechos reservados. MYSUGR, ACCU-CHEK y ACCU-CHEK GUIDE son marcas registradas de Roche. Todos los demás nombres de productos y marcas son propiedad de sus respectivos propietarios.<br/>© 2019 Roche Diabetes Care.",
  "footer.legalNotice.mastec_1": "Al elegir el Paquete mySugr estás sujeto a todos nuestros Términos y Condiciones, que se detallan a continuación. Si deseas darte de baja puedes enviar un correo electrónico en cualquier momento a <a href=\"mailto::email\">:email</a>, tu solicitud de cancelación será procesada con prontitud.  Al descargar la app y realizar el proceso de registro, aceptas los Términos y Condiciones y la Política de Privacidad de mySugr GmbH. Este sitio web contiene información sobre productos que están dirigidos a una amplia gama de audiencias y podría contener detalles del producto o información que de otra manera no sería accesible o válida en tu país. Ten en cuenta que no asumimos ninguna responsabilidad por el acceso a dicha información, la cual puede no cumplir con algún proceso legal, regulación, registro o uso válido en tu país de origen. © 2018 mySugr, Inc. Todos los derechos reservados. MYSUGR, ACCU-CHEK y ACCU-CHEK GUIDE son marcas registradas de Roche. Todos los demás nombres de productos y marcas son propiedad de sus respectivos propietarios.<br/>© 2019 Roche Diabetes Care.",
  "footer.legalNotice.mmc_1": "",
  "footer.legalNotice.optumrx_1": "",
  "footer.legalNotice.roche_ponce": "Al elegir el Paquete mySugr estás sujeto a todos nuestros Términos y Condiciones, que se detallan a continuación. Si deseas darte de baja puedes enviar un correo electrónico en cualquier momento a <a href=\"mailto::email\">:email</a>, tu solicitud de cancelación será procesada con prontitud. La Aplicación mySugr y mySugr Coach se proporcionarán en forma de un vale. Al descargar la aplicación y realizar el proceso de registro, aceptas los Términos y Condiciones y la Política de Privacidad de mySugr GmbH. Este sitio web contiene información sobre productos que están dirigidos a una amplia gama de audiencias y podría contener detalles del producto o información que de otra manera no sería accesible o válida en tu país. Ten en cuenta que no asumimos ninguna responsabilidad por el acceso a dicha información, la cual puede no cumplir con algún proceso legal, regulación, registro o uso válido en tu país de origen. © 2018 mySugr, Inc. Todos los derechos reservados. MYSUGR, ACCU-CHEK y ACCU-CHEK GUIDE son marcas registradas de Roche. Todos los demás nombres de productos y marcas son propiedad de sus respectivos propietarios.<br/>© 2019 Roche Diabetes Care.",
  "footer.legalNotice.roche_us_1": "",
  "footer.legalNotice.si_group_1": "",
  "footer.legalNotice.ukv_1": "",
  "footer.legalNotice.vkb_1": "",
  "footer.legalNotice.wth_1": "",
  "footer.links.imprint": "Aviso legal",
  "footer.links.privacyNotice": "Política de privacidad",
  "footer.links.tcs": "Términos y condiciones",
  "footer.subscriptionLegalNotice": "",
  "footer.writeSupport": "O simplemente escribe a: <a href=\"mailto::email\">:email</a>",
  "form.availableCountriesLabel": "Países disponibles",
  "groupSelection.continue": "Comenzar",
  "groupSelection.items.description.alt_text.strips_subscription_guide.2": "<strong>Reabastecimiento automático de tiras reactivas</strong> basado en tus mediciones importadas (lancetas no incluidas)",
  "groupSelection.items.description.coach": "Acceso exclusivo a un <strong>entrenador de diabetes</strong>",
  "groupSelection.items.description.guide": "<strong>Medidor</strong> Accu-Chek® Guide",
  "groupSelection.items.description.guide_me_1": "<strong>Medidor</strong> Accu-Chek® Guide Me",
  "groupSelection.items.description.pro": "<strong>App mySugr</strong> con <strong>funciones PRO</strong>",
  "groupSelection.items.description.scale_1": "<strong>Báscula</strong> conectada",
  "groupSelection.items.description.strips_subscription_guide": "<strong>Entrega automática de tiras reactivas</strong> basada en tus mediciones importadas",
  "groupSelection.items.description.strips_subscription_guide.2": "<strong>Entrega ilimitada de tiras reactivas</strong> basada en las mediciones importadas",
  "groupSelection.message.reimbursement.linkText": "info",
  "groupSelection.message.reimbursement.linkText.dvg": "",
  "groupSelection.message.reimbursement.text": "Reembolso de hasta el 100%",
  "groupSelection.message.reimbursement.text.dkv": "",
  "groupSelection.message.reimbursement.text.dvg": "",
  "groupSelection.message.roche_bundle_group_coach_1": "",
  "groupSelection.message.roche_bundle_group_guide_1": "",
  "groupSelection.moreInfo": "VER MÁS DETALLES",
  "groupSelection.titles.allianz_bundle_group_coach_2": "",
  "groupSelection.titles.allianz_bundle_group_guide_3": "",
  "groupSelection.titles.allianz_bundle_group_guide_4": "",
  "groupSelection.titles.barmenia_bundle_group_coach_2": "",
  "groupSelection.titles.barmenia_bundle_group_guide_2": "",
  "groupSelection.titles.barmenia_bundle_group_guide_3": "",
  "groupSelection.titles.bundle": "Paquete mySugr",
  "groupSelection.titles.coach": "mySugr Coach",
  "groupSelection.titles.gothaer_bundle_group_coach_2": "",
  "groupSelection.titles.gothaer_bundle_group_guide_3": "",
  "groupSelection.titles.pro": "mySugr PRO",
  "groupSelection.titles.roche_bundle_group_coach_1": "",
  "groupSelection.titles.roche_bundle_group_guide_1": "",
  "groupSelection.titles.ukv_bundle_group_coach_2": "",
  "groupSelection.titles.ukv_bundle_group_guide_3": "",
  "groupSelection.titles.vkb_bundle_group_coach_2": "",
  "groupSelection.titles.vkb_bundle_group_guide_3": "",
  "groupSelectionPage.priceFree": "Gratis",
  "groupSelectionPage.priceFreeExtra": "para ti",
  "groupSelectionPage.title": "Elige un programa mySugr",
  "header.heart.altText": "corazón",
  "headerNotice.bundleNotAvailable": "Actualmente no se aceptan nuevas suscripciones. Este paquete ya no está disponible.",
  "headerNotice.bundleNotAvailable.barmer_1": "",
  "headerNotice.infoNotice.linkText.us_bundle_3": "",
  "headerNotice.infoNotice.teaser.us_bundle_3": "",
  "headerNotice.infoNotice.text.us_bundle_3": "",
  "Help": "Ayuda",
  "homepage.bundle.text": "Selecciona tu país para comprobar qué <b>paquetes mySugr</b> están disponibles:",
  "homepage.bundle.text.us": "Actualmente, el paquete mySugr solo está disponible en <b>EE.UU.</b>",
  "homepage.bundle.title": "Paquete mySugr",
  "homepage.countries.defaultValue": "Seleccione un país",
  "homepage.pro.infoText": "La <b>app DVG PRO</b> solo está disponible en Alemania.",
  "homepage.pro.moreInfo": "más información",
  "homepage.pro.moreInfo.dvg": "Más información",
  "homepage.pro.text": "Para canjear tu vale para la app <strong>mySugr PRO</strong>:",
  "homepage.pro.title": "App mySugr PRO",
  "homepage.text": "¡Bienvenido a la tienda mySugr!",
  "insuranceCardForm.groupNumberLabel": "ID de grupo de seguro",
  "insuranceCardForm.groupNumberLabel.edvoyles": "ID de grupo",
  "insuranceCardForm.groupNumberLabel.garfieldcounty": "ID de grupo",
  "insuranceCardForm.groupNumberLabel.mastec-1": "Código de empresa",
  "insuranceCardForm.groupNumberLabel.roche-benefits-us": "",
  "insuranceCardForm.groupNumberLabel.roche-benefits-us-coach": "",
  "insuranceCardForm.groupNumberPlaceholder": "000123",
  "insuranceCardForm.groupNumberPlaceholder.ActivisionBlizzard": "W1234567",
  "insuranceCardForm.groupNumberPlaceholder.buchanan-group-us": "000ABC123",
  "insuranceCardForm.groupNumberPlaceholder.edvoyles": "000ABC123",
  "insuranceCardForm.groupNumberPlaceholder.garfieldcounty": "",
  "insuranceCardForm.groupNumberPlaceholder.lazerspot": "000ABC123",
  "insuranceCardForm.groupNumberPlaceholder.mastec-1": "PHRASE01",
  "insuranceCardForm.infoTextGeneric": "Por favor, introduce tu número de identificación de grupo de seguro y tu número de identificación de asegurado del plan tal como aparecen en tu tarjeta de identificación de asegurado.",
  "insuranceCardForm.infoTextGeneric.ActivisionBlizzard": "",
  "insuranceCardForm.infoTextGeneric.garfieldcounty": "Por favor, introduce el ID de miembro y el ID de grupo tal y como se compartieron en el material promocional de mySugr del Condado de Garfield.",
  "insuranceCardForm.infoTextGeneric.kaiser.roche-benefits-us": "",
  "insuranceCardForm.infoTextGeneric.mastec-1": "Por favor, introduce tu número de identificación de asegurado (incluidas las letras) tal como aparece en tu tarjeta de identificación de asegurado. Tu código de empresa fue proporcionado por tu empleador. Escribe a <a href=\"mailto:support@mysugr.com\">support@mysugr.com</a> si necesitas asistencia.",
  "insuranceCardForm.infoTextGeneric.roche-benefits-us-coach": "",
  "insuranceCardForm.memberIdLabel": "ID de asegurado",
  "insuranceCardForm.memberIdLabel.ActivisionBlizzard": "",
  "insuranceCardForm.memberIdLabel.kaiser.roche-benefits-us": "",
  "insuranceCardForm.memberIdLabel.roche-benefits-us": "",
  "insuranceCardForm.memberIdLabel.roche-benefits-us-coach": "",
  "insuranceCardForm.memberIdPlaceholder": "123456789",
  "insuranceCardForm.memberIdPlaceholder.ActivisionBlizzard": "AZO 12345678X",
  "insuranceCardForm.memberIdPlaceholder.buchanan-group-us": "ABC12345678C",
  "insuranceCardForm.memberIdPlaceholder.edvoyles": "ABC12345678C",
  "insuranceCardForm.memberIdPlaceholder.garfieldcounty": "00000",
  "insuranceCardForm.memberIdPlaceholder.kaiser.roche-benefits-us": "",
  "insuranceCardForm.memberIdPlaceholder.lazerspot": "ABC12345678C",
  "insuranceCardForm.memberIdPlaceholder.mastec": "ABC0123456789",
  "insuranceNumberForm.label": "Número de seguro",
  "insuranceNumberForm.overlayButtonText": "info",
  "introButtonLabelCreateAccount": "Crear cuenta",
  "languages.de": "Deutsch",
  "languages.en": "English",
  "languages.es": "Español",
  "languages.fr": "Francés",
  "languages.nl": "Nederlands",
  "languageSelectLabel": "Cambiar los ajustes de idioma",
  "login.pageName": "Inicio de sesión",
  "logoText.mySugr": "Programa mySugr",
  "logoText.payor": "en colaboración con :payor",
  "measurementUnitSamples.mg/dl": "70 - 180",
  "measurementUnitSamples.mmol/l": "3,9 - 8,9",
  "more.info.overlay.linkText": "más información",
  "notFound.lead": "¡Lo que estás buscando ya no existe!",
  "notFound.text": "La página que estás buscando no existe o se ha movido a otro lugar. Ponte en <a href=\"mailto::email\">contacto con nuestro equipo</a> para obtener asistencia.",
  "notFound.title": "La página ya no existe",
  "notifications.CARD_DECLINED": "Tu tarjeta ha sido rechazada.",
  "notifications.dismissNotification": "Descartar notificación",
  "notifications.EXPIRED_CARD": "Tu tarjeta ha caducado",
  "notifications.IE11_NOT_SUPPORTED": "Tu navegador (Internet Explorer 11) no es compatible actualmente. Es posible que encuentres problemas en nuestra página. Por favor, considera la posibilidad de utilizar otro navegador.",
  "notifications.INCORRECT_CVC": "El CVC/CVV no es correcto.",
  "notifications.INCORRECT_NUMBER": "El número de la tarjeta de crédito no es correcto.",
  "notifications.INSUFFICIENT_FUNDS": "Tu tarjeta ha sido rechazada porque no tiene fondos suficientes.",
  "notifications.PROCESSING_ERROR": "No hemos podido procesar tu tarjeta.",
  "notifications.SERVER_ERROR": "Error al contactar con el servidor mySugr",
  "notifications.STRIPE_ERROR": "No se ha podido inicializar Stripe",
  "notifications.TRIGGER_EMAIL_VERIFICATION_SUCCESS": "El e-mail se ha enviado",
  "notifications.UNDEFINED_ERROR": "Lo sentimos, algo ha fallado",
  "notifications.UNKNOWN_ERROR": "Algo ha fallado.",
  "notifications.UNKNOWN_ERROR_2": "Vaya, algo ha fallado",
  "notifications.USER_ALREADY_SUBSCRIBED_TO_BUNDLE": "Ya estás suscrito al Paquete mySugr",
  "notifications.USER_ALREADY_SUBSCRIBED_TO_PLAN": "Ya estás suscrito al Paquete mySugr.",
  "numbers.0": "cero",
  "numbers.1": "uno",
  "numbers.2": "dos",
  "numbers.3": "tres",
  "numbers.4": "cuatro",
  "numbers.5": "cinco",
  "numbers.6": "seis",
  "numbers.7": "siete",
  "numbers.8": "ocho",
  "numbers.9": "nueve",
  "numbers.10": "diez",
  "offlinePage.subtitle": "Por favor, comprueba tu conexión.",
  "offlinePage.title": "Upps, no tienes internet",
  "optionalConsent": "Opcional:",
  "optionalPlaceholder": "Opcional",
  "overlay.close": "Cerrar",
  "overview.address.phone": "Teléfono:",
  "overview.autoCancelInfo.DAY.1": "El paquete finaliza automáticamente después del primer día.",
  "overview.autoCancelInfo.DAY.2": "El paquete finaliza automáticamente después de :count días.",
  "overview.autoCancelInfo.MONTH.1": "El paquete finaliza automáticamente después del primer mes.",
  "overview.autoCancelInfo.MONTH.2": "El paquete finaliza automáticamente después de :count meses.",
  "overview.autoCancelInfo.WEEK.2": "",
  "overview.autoCancelInfo.YEAR.1": "El paquete finaliza automáticamente después del primer año.",
  "overview.autoCancelInfo.YEAR.2": "El paquete finaliza automáticamente después de :count años.",
  "overview.backButton": "Atrás",
  "overview.billingAddress.edit": "Editar",
  "overview.billingAddress.title": "Facturación",
  "overview.cancelationInfo.MONTH.1": "Puedes cancelar tu suscripción en cualquier momento después del primer mes.",
  "overview.cancelationInfo.MONTH.2": "Puedes cancelar tu suscripción en cualquier momento después de los primeros :count meses.",
  "overview.cancelationInfo.YEAR.1": "Puedes cancelar tu suscripción en cualquier momento después del primer año.",
  "overview.cancelationInfo.YEAR.2": "Puedes cancelar tu suscripción en cualquier momento después de los primeros :count años.",
  "overview.coachLanguageOverview.change": "Cambiar",
  "overview.coachLanguageOverview.title": "Idioma del entrenador",
  "overview.contactDetails.edit": "Editar",
  "overview.contactDetails.title": "Información de contacto",
  "overview.continueButton": "Pedir",
  "overview.continueButton.at": "Pedir",
  "overview.continueButton.at.fallback": "Pedir",
  "overview.continueButton.ca.fallback": "",
  "overview.continueButton.de": "Pedir",
  "overview.continueButton.de.fallback": "Pedir",
  "overview.continueButton.es": "Pedir",
  "overview.continueButton.es.fallback": "Pedir",
  "overview.continueButton.nl.fallback": "Inscribirse",
  "overview.continueButton.sg.fallback": "Inscribirse",
  "overview.continueButton.us": "Suscribirse",
  "overview.continueButton.us.fallback": "Inscribirse",
  "overview.continueButton.us.optumrx_bundle_1": "Inscribirse",
  "overview.continueButton.us.us_bundle_3": "Suscribirse",
  "overview.continueButton.za.fallback": "Suscribirse",
  "overview.continueButton.za.south-africa_affiliate_(coaching_bundle)_2": "Suscribirse",
  "overview.enrollmentTitle": "Inscripción en el programa",
  "overview.freeShipping": "Envío gratuito",
  "overview.measurementUnitOverview.change": "Cambiar",
  "overview.measurementUnitOverview.mgdl": "mg/dL",
  "overview.measurementUnitOverview.mmoll": "mmol/L",
  "overview.measurementUnitOverview.title": "Unidad de medida",
  "overview.pageName": "Vista general del pedido",
  "overview.paymentOverview.change": "Editar",
  "overview.paymentOverview.creditCard": "Tarjeta de crédito",
  "overview.paymentOverview.payment": "Pago",
  "overview.payorCovered": "Este programa está totalmente patrocinado por tu seguro y está disponible para ti sin costo alguno.",
  "overview.payorCovered.aok_nordwest_bundle_1": "",
  "overview.payorCovered.dec_i_bundle_1": "",
  "overview.payorCovered.dec_ii_bundle_1": "",
  "overview.payorCovered.onemed_bundle_1": "",
  "overview.payorCovered.optumrx_bundle_1": "",
  "overview.payorCovered.servidigest_bundle_1": "",
  "overview.payorCovered.us": "Este programa está totalmente patrocinado por tu empleador y está disponible para ti sin costo alguno.",
  "overview.payorCovered.us.activision_blizzard_bundle_1": "",
  "overview.payorCovered.us.bi_state_development_bundle_1": "Este programa está totalmente patrocinado por tu empleador y está disponible para ti sin costo alguno.",
  "overview.payorCovered.us.buchanan_bundle_2": "Este programa está totalmente patrocinado por tu empleador y está disponible para ti sin costo alguno.",
  "overview.payorCovered.us.dhp_roche_ponce_bundle_1": "Este programa está totalmente patrocinado por tu empleador y está disponible para ti sin costo alguno.",
  "overview.payorCovered.us.garfield_county_bundle_1": "Este programa está totalmente patrocinado por tu empleador y está disponible para ti sin costo alguno.",
  "overview.payorCovered.us.genentech_bundle_1": "Este programa es un beneficio gratuito a través del Ensayo Clínico de Roche/Genentech.",
  "overview.payorCovered.us.lazer_spot_bundle_1": "Este programa está totalmente patrocinado por tu empleador y está disponible para ti sin costo alguno.",
  "overview.payorCovered.us.mastec_bundle_1": "Este programa está totalmente patrocinado por tu empleador y está disponible para ti sin costo alguno.",
  "overview.payorCovered.us.mysugr_ed_voyles_bundle_1": "Este programa está totalmente patrocinado por tu empleador y está disponible para ti sin costo alguno.",
  "overview.payorCovered.us.roche_bundle_us_1": "",
  "overview.payorCovered.us.roche_bundle_us_2": "",
  "overview.payorCovered.us.si_group_bundle_1": "",
  "overview.payorCovered.zdg_bundle_1": "",
  "overview.seller": "Vendedor",
  "overview.shipmentAddress.edit": "Editar",
  "overview.shipmentAddress.title": "Envío",
  "overview.shipmentOverview.edit": "Editar",
  "overview.shipmentOverview.title": "Dirección de entrega",
  "overview.stepName": "Vista general",
  "overview.subscribeNotice": "Al hacer clic en el botón de arriba confirmas que tienes más de :minAge años.",
  "overview.title": "Ya casi has terminado",
  "pageTitle": "mySugr Shop",
  "pageTitle.flowTitle.withPayor": "mySugr y :payorName",
  "pageTitle.flowTitle.withProductAndPayor": ":productName y :payorName",
  "pageTitle.withFlow": ":flowTitle",
  "pageTitle.withStep": ":stepTitle | mySugr Shop",
  "pageTitle.withStepAndFlow": ":stepTitle | :flowTitle",
  "passwordInput.hidePasswordButton": "Ocultar",
  "passwordInput.showPasswordButton": "Mostrar",
  "passwordReset.pageName": "Cambiar contraseña",
  "passwordResetSuccessPage.buttonText": "Continuar",
  "passwordResetSuccessPage.congratsImageAlt": "Felicidades, confeti volando,",
  "passwordResetSuccessPage.text": "Por favor, recuerda que tu contraseña también ha cambiado para la app mySugr.",
  "passwordResetSuccessPage.title": "¡Has cambiado tu contraseña!",
  "payment.cardInfo.backButton": "Atrás",
  "payment.cardInfo.changeCard": "Cambiar tarjeta",
  "payment.cardInfo.continueButton": "Continuar",
  "payment.cardInfo.discardButton": "Volver a la vista general",
  "payment.cardInfo.expires": "Válida hasta :month/:year",
  "payment.cardInfo.usingText": "Estás pagando con la tarjeta de crédito <strong>:last4</strong>.",
  "payment.form.backButton": "Atrás",
  "payment.form.cardNumberLabel": "Número de la tarjeta",
  "payment.form.continueButton": "Continuar",
  "payment.form.cvcLabel": "CVC/CVV",
  "payment.form.discardButton": "Descartar cambios",
  "payment.form.expirationLabel": "Fecha de caducidad",
  "payment.form.firstNameLabel": "Nombre",
  "payment.form.lastNameLabel": "Apellidos",
  "payment.form.nameLabel": "Nombre que figura en la tarjeta",
  "payment.form.sameAsShipment": "La dirección de facturación es la misma que la dirección de envío",
  "payment.form.saveChangesButton": "Guardar cambios",
  "payment.stepName": "Pago",
  "payment.title": "Información de <strong>pago</strong>",
  "paymentDetails.list.info": "El cargo de tu suscripción al paquete se hará periódicamente a esta tarjeta.",
  "paymentDetails.list.title": "Información de pago",
  "paymentDetails.login.title": "Inicia sesión para cambiar la información de pago",
  "paymentLogos.title": "Métodos de pago admitidos:",
  "product.bundleBoxImage.alt": "Paquete mySugr",
  "product.bundleBoxImage.alt.bundle_coach": "El paquete mySugr: un paguete con el logotipo de mySugr rodeado por el contenido del paquete y la app mySugr app abierta en un teléfono con una burbuja de chat por encima del teléfono.",
  "product.bundleBoxImage.alt.bundle_guide": "El paquete mySugr: un paquete con el logotipo de mySugr rodeado por el contenido del paquete.",
  "product.bundleBoxImage.alt.bundle_guide_me": "El paquete mySugr: un paquete con el logotipo de mySugr rodeado por el contenido del paquete.",
  "product.bundleBoxImage.alt.coach": "Representación de un teléfono con la aplicación mySugr abierta y una burbuja de chat junto al teléfono separada por un símbolo más.",
  "product.bundleBoxImage.alt.pro": "Un teléfono que sostiene una estrella con la palabra “Pro”.",
  "product.continueButton": "",
  "product.items.moreInfo.dvg": "más información",
  "product.items.overlayText.dvg": "",
  "product.stepName": "Producto",
  "product.submitButton": "",
  "product.title": "El paquete mySugr",
  "productName.bundle": "Paquete mySugr",
  "productName.coach": "mySugr Coach",
  "promotion.APRILFOOLS2019_PROMOTION_PRICE_TEXT": "después, cada mes",
  "promotion.APRILFOOLS2019_PROMOTION_TEXT": "1 mes gratis",
  "proVoucher.pageName": "Canjear vale PRO",
  "proVoucherInfo.checklist.challenges": "Participa en los <strong>desafíos</strong>",
  "proVoucherInfo.checklist.pdf": "Informes <strong>PDF</strong>",
  "proVoucherInfo.checklist.photos": "<strong>Haz fotos</strong> de tus comidas",
  "proVoucherInfo.checklist.reminders": "Recibe <strong>recordatorios</strong> automáticos",
  "proVoucherInfo.checklist.search": "<strong>Buscar</strong> - Encuentra tus entradas fácilmente",
  "proVoucherInfo.checklist.sync": "Sincroniza <strong>varios dispositivos</strong>",
  "proVoucherInfo.ctaButtonText": "Canjear el vale ahora",
  "proVoucherInfo.headline": "Activando tu vale...",
  "proVoucherInfo.imageTitle": "mySugr PRO",
  "proVoucherInfo.stepName": "PRO (vale)",
  "proVoucherInfo.validDate": "Las funciones se desbloquearán hasta el <strong>:date</strong>.",
  "proVoucherInfo.validTimeMonths": "Las funciones se desbloquearán durante <strong>:months meses</strong>.",
  "proVoucherInfo.validTimeUnlimited": "Obtendrás acceso <strong>ilimitado</strong> a las funciones.",
  "proVoucherInfo.voucherCodeValid": "Tu código de vale <code>:voucherCode</code> es válido.",
  "RDRegistration_description": "Empieza a usar mySugr con tu cuenta de RocheDiabetes.",
  "RDRegistration_mySugr_description": "¿Sabías que mySugr es parte de RocheDiabetes? **Más información**",
  "RDSignIn_description": "¿Ya tienes una cuenta? ¡Genial!",
  "readMoreBundleContentInformation": "Leer más sobre el contenido del paquete",
  "register.pageName": "Registro",
  "reimbursement.overlay.text": "<h4>¿Qué cantidad me reembolsarán?</h4>\n<p>La misma cantidad que cuando compras las tiras reactivas en la farmacia. Dependiendo de la tarifa del seguro, será hasta el 100%.</p>\n\n<h4>Obtener el reembolso es muy fácil:</h4>\n<p>Te enviaremos tu factura después de que compres el paquete. Puedes imprimirla y enviarla al seguro junto con tu solicitud de prestación.</p>",
  "reimbursement.overlay.text.barmenia": "",
  "reimbursement.overlay.text.dkv": "",
  "reimbursement.overlay.text.gothaer": "",
  "reimbursement.overlay.text.ukv": "",
  "reimbursement.overlay.text.vkb": "",
  "reimbursement.overlay.title": "El paquete mySugr",
  "reimbursement.overlay.title.allianz_bundle_group_coach_2": "",
  "reimbursement.overlay.title.barmenia_bundle_group_coach_2": "",
  "reimbursement.overlay.title.bundle": "El paquete mySugr",
  "reimbursement.overlay.title.coach": "El paquete Diabetes Coaching",
  "reimbursement.overlay.title.coach.dkv": "",
  "reimbursement.overlay.title.gothaer_bundle_group_coach_2": "",
  "reimbursement.overlay.title.ukv_bundle_group_coach_2": "",
  "reimbursement.overlay.title.vkb_bundle_group_coach_2": "",
  "selectCountry.selectCountryLabel": "Seleccione un país",
  "selectPayor.otherInsuranceButton": "",
  "selectPayor.otherInsuranceExplanation": "",
  "selectPayor.pleaseSelect": "",
  "selectPayor.title": "",
  "shipment.contactDataForCoaches": "Tus datos de contacto solo se proporcionarán a tu entrenador personal.",
  "shipment.form.address2Label": "Apartamento, piso, etc.",
  "shipment.form.addressHint": "No indicar apartado de correos",
  "shipment.form.addressLabel": "Dirección",
  "shipment.form.addressPlaceholder.germany": "Número de calle/escalera/puerta",
  "shipment.form.backButton": "Atrás",
  "shipment.form.billingAddressTitle": "Dirección de facturación",
  "shipment.form.birthdateCheckboxLabel": "Confirmo que tengo la edad mínima de :minAge años y soy una persona diagnosticada con diabetes.",
  "shipment.form.birthdateHint": "El usuario debe tener al menos :minAge años.",
  "shipment.form.birthdateOverlayText": "<p>El paquete mySugr solo está disponible para personas con diabetes de :minAge años de edad o más.</p><p>Para inscribirse en el paquete mySugr es necesario cumplir los siguientes criterios:</p><ol><li>La persona que se inscriba y utilice el Paquete mySugr tiene que ser una persona diagnosticada con diabetes <strong>Y</strong> tener al menos :minAge años.</li><li>La persona que se inscriba debe ser el usuario. No está permitido inscribir a otra persona. (Los padres no pueden inscribir a sus hijos en el paquete mySugr).</li></ol><p>Cualquier infracción de los criterios anteriores conllevará la cancelación del paquete mySugr. Para más información, consulta nuestros <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">Términos y Condiciones de uso</a>.</p>",
  "shipment.form.cityLabel": "Ciudad",
  "shipment.form.continueButton": "Continuar",
  "shipment.form.discardChangesButton": "Descartar cambios",
  "shipment.form.emailLabel": "Dirección de e-mail",
  "shipment.form.employerDefaultOption": "Selecciona un empleador",
  "shipment.form.employerHint": "",
  "shipment.form.employerLabel": "Empleador",
  "shipment.form.firstNameLabel": "Nombre",
  "shipment.form.lastNameLabel": "Apellidos",
  "shipment.form.memberClass.dependentLabel": "Dependiente",
  "shipment.form.memberClass.employeeLabel": "Titular",
  "shipment.form.memberClass.explanation": "Relación con el asegurado",
  "shipment.form.phoneLabel": "Número de teléfono",
  "shipment.form.policy.headline": "Información de la póliza de seguro",
  "shipment.form.postalCodeLabel": "Código postal",
  "shipment.form.postalCodeLabel_new": "Código postal",
  "shipment.form.saveChangesButton": "Guardar cambios",
  "shipment.form.stateDefaultOption": "Selecciona un estado",
  "shipment.form.stateLabel": "Estado",
  "shipment.form.zipCodeLabel": "Código postal",
  "shipment.stepName": "Envío",
  "shipment.title": "Información de <strong>envío</strong>",
  "shoppingCart.deliverOnce": "(una única entrega)",
  "shoppingCart.immediateTitle": "Acceso inmediato a:",
  "shoppingCart.productTitle": "Tu envío llegará en pocos días:",
  "shoppingCart.subscriptionTitle": "Acceso continuo a:",
  "shoppingCart.title": "Tu paquete incluye:",
  "shoppingCart.title.dvg": "",
  "shoppingCart.title.roche-benefits-us": "",
  "shoppingCart.title.roche-benefits-us-coach": "",
  "SignIn": "Iniciar sesión",
  "SignInMySugr": "Iniciar sesión con ID de mySugr",
  "stepBar.activeStep.screenReader": "(paso activo)",
  "stepBar.completedStep.screenReader": "(paso completado)",
  "stepBar.notCompletedStep.screenReader": "(paso no completado)",
  "stepBar.remaining": "¡Estás a solo <strong>:remaining pasos</strong> de tu paquete!",
  "stepBar.title.screenReader": "Pasos necesarios para inscribirse en el Paquete mySugr",
  "stepIndicationText": "Paso :current de :total",
  "success.coach.text": "De hecho, hay un mensaje de tu entrenador esperándote en la aplicación.",
  "success.coach.title": "Di <b>“Hola”</b> a tu <b>entrenador personal</b>",
  "success.doneButton": "Hecho",
  "success.download.bundle.text": "<b>Añade</b> tus <b>mediciones diarias</b> y nosotros calcularemos cuantas tiras reactivas has usado.",
  "success.download.coach.text": "<b>Sigue</b> tus <b>mediciones diarias</b> y manténte al tanto de tu diabetes.",
  "success.download.title": "A continuación, <b>descarga</b> la <b>aplicación</b> mySugr",
  "success.help": "Si algo no está claro, no dudes en contactar a nuestro maravilloso equipo de soporte.",
  "success.nextStep1": "<strong>Descarga</strong> la <strong>app</strong> mySugr",
  "success.nextStep2": "<strong>Inicia sesión</strong> con tu dirección de e-mail <strong>:email</strong> y <strong>contraseña</strong>",
  "success.nextStep3": "<strong>¡Listo!</strong>",
  "success.orderSuccess.coachText": "Sigue los siguientes pasos para ponerte en contacto con tu entrenador.",
  "success.orderSuccess.deliveryText": "Te enviaremos un e-mail con los detalles de tu primera entrega.\n\n<b>Las próximas entregas se activarán desde la aplicación.</b>",
  "success.orderSuccess.title": "Tu pedido se ha realizado <b>correctamente</b>",
  "success.pageFooterTitle": "¡Sigue estos sencillos pasos y manténte al día!",
  "success.pageName": "Pedido realizado con éxito",
  "success.payment.change": "Tarjeta actualizada correctamente",
  "success.proVoucherInfo.validDate": "Las funciones se desbloquearán hasta el <strong>:date</strong>.",
  "success.proVoucherInfo.validTimeMonths": "Las funciones PRO se desbloquearán durante <strong>:months meses</strong>.",
  "success.proVoucherInfo.validTimeUnlimited": "Ahora tienes acceso <strong>ilimitado</strong> a todas las funciones PRO.",
  "success.proVoucherInfo.validTimeUnlimited.dvg": "Ahora tienes acceso a todas las funciones PRO.",
  "success.shippingInfo": "Tu paquete llegará en <strong>5-7 días hábiles</strong>.",
  "success.shippingInfoDynamic": "Tu paquete llegará en <strong>:time días hábiles</strong>.",
  "success.shippingMoreInfo": "Cuando recibas tu paquete, conecta tu medidor y comienza a medir todo lo que puedas. Recuerda <b>sincronizar con la aplicación</b> siempre - es el único modo de saber cuándo enviarte <b>tiras reactivas adicionales</b>.",
  "success.stepName": "Concluido",
  "success.title": "¡Tu pedido se ha <strong>completado con éxito!</strong>",
  "success.titleProOnly": "Enhorabuena,<br> ¡ahora eres un <strong>usuario PRO</strong>!",
  "success.trackingInfo": "Te enviaremos un e-mail en los próximos dos días con una identificación de seguimiento para que puedas seguir de cerca tu envío.",
  "unitSelection.buttonText": "Seleccionar",
  "unitSelection.label": "Selección de unidades",
  "unitSelection.labelClose": "Cerrar la selección de unidades",
  "unitSelection.title": "Selecciona tu <strong>unidad de medida</strong>",
  "voucher.pageName": "Canjear vale",
  "voucherForm.buttonText": "Comprobar vale",
  "voucherForm.label": "Código del vale",
  "voucherForm.overlayButtonText": "info",
  "voucherForm.overlayText": "Puedes encontrar el código de 12 dígitos de tu vale en la carta que recibiste del seguro. Si no has recibido un código de vale, ponte en contacto con el seguro.",
  "voucherForm.overlayText.aok_1": "",
  "voucherForm.overlayText.aok_nordwest_1": "",
  "voucherForm.overlayText.barmer_1": "",
  "voucherForm.overlayText.bi_state_development_1": "Introduce el código de 12 dígitos del vale que recibiste para acceder a esta oferta.",
  "voucherForm.overlayText.dkv": "",
  "voucherForm.overlayText.fayette_county_1": "Puedes encontrar el código de tu vale en la carta que recibiste del empleador. Si no has recibido un código de vale, ponte en contacto con el empleador.",
  "voucherForm.overlayText.genentech_1": "Por favor introduce el código de 12 dígitos del folleto del programa mySugr.",
  "voucherForm.overlayText.jbz_1": "",
  "voucherForm.overlayText.mmc_1": "",
  "voucherForm.overlayText.onemed_1": "",
  "voucherForm.overlayText.roche_pl_1": "--- MISSING ---",
  "voucherForm.overlayText.roche_sg_1": "",
  "voucherForm.overlayText.schwenninger_1": "",
  "voucherForm.overlayText.servidigest_1": "",
  "voucherForm.overlayText.si_group_1": "",
  "voucherForm.overlayText.zdg_1": "",
  "voucherForm.title": "¡Canjea tu vale!"
};