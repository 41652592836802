import { AuthType } from 'src/types/IAuthUser';

export const OIDC_HEADER = 'X-mySugr-OIDC-access-token' as const;

// If backend needs to perform additional calls to external RDC services we need to pass header with access token
function prepareOIDCHeader(
  accessToken: string | undefined,
  authType: AuthType | undefined,
): Record<string, string> {
  if (authType === AuthType.RDCP && accessToken) {
    return {
      [OIDC_HEADER]: accessToken,
    };
  }
  return {};
}

export default prepareOIDCHeader;
