function migrateToShop939(state: any): any {
  return {
    ...state,
    register: {
      ...state.register,
      consents: {
        mandatory: {
          consentRequirements: { purpose: '', requiredDocuments: [] },
        },
      },
    },
  };
}

export default migrateToShop939;
