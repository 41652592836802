import * as React from 'react';
import { PARAM_BUNDLE_SUPPORT_EMAIL } from 'src/constants/bundleParameters';
import { SUPPORTED_COUNTRY } from 'src/constants/countries';
import support from 'src/constants/support';
import getBundleParameter from 'src/lib/getBundleParameter';
import IBundle from 'src/types/IBundle';
import Translate from '../Translate/Translate';

export interface IProps {
  country?: SUPPORTED_COUNTRY;
  bundle?: IBundle;
}

export function WriteSupport({
  country,
  bundle,
}: Readonly<IProps>): React.ReactElement {
  let email = support.default.email;
  const supportEmail = getBundleParameter(PARAM_BUNDLE_SUPPORT_EMAIL, bundle);

  if (supportEmail) {
    email = supportEmail;
  } else if (country && support[country]) {
    email = support[country].email;
  }
  return (
    <Translate html={true} t="footer.writeSupport" variables={{ email }} />
  );
}

export default WriteSupport;
