import { LocationContext } from '@gatsbyjs/reach-router';
import React from 'react';
import { connect } from 'react-redux';
import { setCurrentStepAction } from 'src/actions/flowActions';
import {
  setLanguageAction,
  setTranslationsAction,
} from 'src/actions/translationActions';
import withLocation from 'src/components/withLocation/withLocation';
import { LANGUAGE, SUPPORTED_LANGUAGES } from 'src/constants/languages';
import getActiveStepFromPathname from 'src/lib/getActiveStepFromPathname';
import getLocaleFromPathname from 'src/lib/getLocaleFromPathname';
import { getSteps } from 'src/selectors/flowSelectors';
import IStep from 'src/types/IStep';
import IStoreState from 'src/types/IStoreState';

export interface IStateProps {
  activeTranslationLanguage?: string;
  steps: IStep[];
}

export interface IDispatchProps {
  setCurrentStep: (newStep: IStep) => Promise<any>;
  setLanguage: (language: LANGUAGE) => void;
  setTranslations: (translations: Record<string, any>) => void;
}

export interface IProps extends IStateProps, IDispatchProps, LocationContext {}

const isValidLanguage = (language?: string) =>
  language && SUPPORTED_LANGUAGES.includes(language as LANGUAGE);

const loadTranslations = async (language: LANGUAGE) =>
  new Promise(async resolve => {
    const lang = language.toLowerCase();
    const translations = await import(`../../translations/${lang}`);
    resolve(translations.default);
  });

export const FlowController = (props: IProps) => {
  const { location, steps, setCurrentStep } = props;
  const pathname = location.pathname;

  React.useEffect(() => {
    const step = getActiveStepFromPathname({
      pathname: location.pathname,
      steps,
    });

    if (step) {
      setCurrentStep(step);
    }

    const [language] = getLocaleFromPathname(pathname);

    if (
      language &&
      isValidLanguage(language) &&
      language !== props.activeTranslationLanguage
    ) {
      props.setLanguage(language);
      loadTranslations(language).then(data => {
        props.setTranslations(data as any);
      });
    }
  }, [pathname, steps]);

  return null;
};

export function mapStateToProps(state: IStoreState): IStateProps {
  return {
    activeTranslationLanguage: state.translation.language,
    steps: getSteps(state),
  };
}

export function mapDispatchToProps(dispatch: any): IDispatchProps {
  return {
    setCurrentStep: newStep => dispatch(setCurrentStepAction(newStep.step)),
    setLanguage: (language: LANGUAGE) => dispatch(setLanguageAction(language)),
    setTranslations: (translations: Record<string, any>) =>
      dispatch(setTranslationsAction(translations)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocation(FlowController));
