import classnames from 'classnames';
import * as React from 'react';
import Button from 'src/components/Button/Button';
import {
  MEASUREMENT_UNIT_MG_DL,
  MEASUREMENT_UNIT_MMOL_L,
} from 'src/constants/measurementUnits';
import hasBundleGroupUnit from 'src/lib/hasBundleGroupUnit';
import useMemoizedCallback from 'src/lib/hooks/useMemoizedCallback';
import IBundleGroup from 'src/types/IBundleGroup';
import overlayCss from '../Overlay/Overlay.module.scss';
import Translate from '../Translate/Translate';
import deviceHusk from './deviceHusk.svg';
import css from './UnitSelection.module.scss';

interface IProps {
  onSelect: (selection: any) => void;
  groups: IBundleGroup[];
}

type CreateOnSelectFunc = (
  selected: IBundleGroup,
) => (event: React.SyntheticEvent) => void;

const renderDevice = (units: string): React.ReactElement => (
  <div className={css.unitItem}>
    <img className={css.unitItemHusk} src={deviceHusk} alt="" />
    <div className={css.unitExampleValues} aria-hidden={true}>
      <Translate t={`measurementUnitSamples.${units}`} />
    </div>
    <div className={css.unitValueUnits}>{units}</div>
  </div>
);

const renderGroup =
  (handleSelect: CreateOnSelectFunc) =>
  (group: IBundleGroup): React.ReactElement => {
    const units = hasBundleGroupUnit(group, MEASUREMENT_UNIT_MG_DL)
      ? MEASUREMENT_UNIT_MG_DL
      : MEASUREMENT_UNIT_MMOL_L;

    return (
      <button
        key={group.code}
        className={css.unitSelect}
        onClick={handleSelect(group)}
        type="button"
      >
        {renderDevice(units)}
        <Button
          className={css.ctaButton}
          large={true}
          aria-label={units}
          tabIndex={0}
          secondary={true}
        >
          <Translate t="unitSelection.buttonText" />
        </Button>
      </button>
    );
  };

const UnitSelection: React.FC<IProps> = ({
  onSelect,
  groups,
}: IProps): React.ReactElement => {
  const handleSelect = useMemoizedCallback(
    (selected: IBundleGroup) =>
      (event: React.SyntheticEvent): void => {
        event.preventDefault();
        onSelect(selected);
      },
    [onSelect],
  );

  return (
    <div>
      <h3 className={classnames(overlayCss.title, overlayCss.textCenter)}>
        <Translate t="unitSelection.title" html={true} />
      </h3>

      <div className={css.unitList}>
        {groups.map(renderGroup(handleSelect))}
      </div>
    </div>
  );
};

export default UnitSelection;
