/* istanbul ignore file */

import { getPathBase } from 'src/lib/paths';
import IBundle from 'src/types/IBundle';
import IBundleGroup from 'src/types/IBundleGroup';
import INonBundleFlowConfig from 'src/types/INonBundleFlowConfig';
import IVoucher from 'src/types/IVoucher';
import { SUPPORTED_COUNTRY } from './countries';
import { LANGUAGE } from './languages';

export interface IStepRouterConfig {
  [propName: string]: IRoute;
}

export interface IRoute {
  getPath: (params?: IStepRouteOptions) => string;
  match: RegExp[];
  name: string;
}

export interface IStepRouteOptions {
  country?: SUPPORTED_COUNTRY;
  language?: LANGUAGE;
  bundle?: IBundle;
  voucher?: IVoucher;
  nonBundleFlowConfig?: INonBundleFlowConfig;
  bundleGroup?: IBundleGroup;
}

const stepRoutes: IStepRouterConfig = {
  home: {
    getPath: (options: IStepRouteOptions) => getPathBase(options, ''),
    match: [/\/[a-z]{2}/],
    name: '/',
  },
  account: {
    getPath: (options: IStepRouteOptions) => getPathBase(options, '/account'),
    match: [/\/account$/],
    name: 'account',
  },
  group: {
    getPath: (options: IStepRouteOptions) =>
      getPathBase(options, '/group/:group'),
    match: [],
    name: 'group/:group',
  },
  overview: {
    getPath: (options: IStepRouteOptions) => getPathBase(options, '/overview'),
    match: [/\/overview$/],
    name: 'overview',
  },
  proVoucher: {
    getPath: (options: IStepRouteOptions) => getPathBase(options, ''),
    match: [/\/voucher\/pro$/],
    name: '/',
  },
  shipment: {
    getPath: (options: IStepRouteOptions) => getPathBase(options, '/shipment'),
    match: [/\/shipment$/],
    name: 'shipment',
  },
  product: {
    getPath: (options: IStepRouteOptions) => getPathBase(options, ''),
    match: [/\/group\/([a-zA-Z0-9_-]+)$/, /\/payor\/([a-zA-Z0-9_-]+)$/],
    name: '/',
  },
  contact: {
    getPath: (options: IStepRouteOptions) => getPathBase(options, '/contact'),
    match: [/\/contact$/],
    name: 'contact',
  },
  success: {
    getPath: (options: IStepRouteOptions) => getPathBase(options, '/success'),
    match: [/\/success$/],
    name: 'success',
  },
  voucher: {
    getPath: (options: IStepRouteOptions) => getPathBase(options, '/voucher'),
    match: [/\/voucher$/],
    name: '/',
  },
};

export default stepRoutes;
