import IBundleGroup from 'src/types/IBundleGroup';
import IBundleGroupItem, {
  BundleGroupItemAttrKey,
} from 'src/types/IBundleGroupItem';
import getItemAttribute from './getItemAttribute';

function orderBundleGroupItems(
  group: IBundleGroup,
  orderedItemTypes: string[],
): IBundleGroupItem[] {
  const itemsOrdered = [];
  for (const type of orderedItemTypes) {
    for (const item of group.items) {
      if (getItemAttribute(BundleGroupItemAttrKey.type, item) === type) {
        itemsOrdered.push(item);
      }
    }
  }
  return itemsOrdered;
}

export default orderBundleGroupItems;
