function migrateToShop353(state: any) {
  return {
    ...state,
    voucher: {
      pro: false,
      voucher: {
        types: {
          pro: false,
        },
      },
    },
  };
}

export default migrateToShop353;
