import classnames from 'classnames';
import * as React from 'react';
import css from './Loader.module.scss';

export function Loader(): React.ReactElement {
  return (
    <div className={css.container}>
      <div className={css.box}>
        <div className={classnames(css.circle, css.circle1)} />
        <div className={classnames(css.circle, css.circle2)} />
        <div className={classnames(css.circle, css.circle3)} />
        <div className={classnames(css.circle, css.circle4)} />
      </div>
    </div>
  );
}

export default Loader;
