import stepRoutes, {
  IRoute,
  IStepRouteOptions,
} from 'src/constants/stepRoutes';
import { getPathBase } from 'src/lib/paths';

export interface INonBundleFlowStep {
  name: string;
  route: IRoute;
  slug: string;
  requiresVerification?: boolean;
  visible: boolean;
}

export const NON_BUNDLE_PRODUCT_PAGE: INonBundleFlowStep = {
  name: 'product.stepName',
  slug: '',
  route: {
    getPath: (options: IStepRouteOptions) => getPathBase(options, ''),
    match: [/\e$/],
    name: '/',
  },
  visible: true,
};

export const NON_BUNDLE_CONTACT_PAGE: INonBundleFlowStep = {
  name: 'contact.stepName',
  slug: 'contact',
  route: stepRoutes.contact,
  requiresVerification: true,
  visible: true,
};

export const NON_BUNDLE_ACCOUNT_PAGE: INonBundleFlowStep = {
  name: 'account.stepName',
  slug: 'account',
  route: stepRoutes.account,
  requiresVerification: true,
  visible: true,
};

export const NON_BUNDLE_OVERVIEW_PAGE: INonBundleFlowStep = {
  name: 'overview.stepName',
  slug: 'overview',
  route: stepRoutes.overview,
  requiresVerification: true,
  visible: true,
};

export const NON_BUNDLE_SUCCESS_PAGE: INonBundleFlowStep = {
  name: 'success.stepName',
  slug: 'success',
  route: stepRoutes.success,
  visible: false,
};
