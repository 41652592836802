import {
  ISetAppUserAction,
  SET_APP_USER_ACTION,
  UserAction,
} from 'src/actions/userActions';
import IUserState from 'src/types/IUserState';

export const initialState: IUserState = {};

function handleSetAppUser(
  state: IUserState,
  action: ISetAppUserAction,
): IUserState {
  return { ...state, appUser: action.appUser };
}

const userReducer = (
  state: IUserState = initialState,
  action: UserAction | { type: null } = { type: null },
): IUserState => {
  if (action.type === SET_APP_USER_ACTION) {
    return handleSetAppUser(state, action);
  }

  return state;
};

export default userReducer;
