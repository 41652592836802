/* istanbul ignore file */

import { COUNTRY_DE, COUNTRY_US } from './countries';

// tslint:disable:object-literal-sort-keys
const errors = {
  COUNTRY_REGION_MISSMATCH: 'COUNTRY_REGION_MISSMATCH',
  EMPTY_CART: 'EMPTY_CART',
  EXISTS_IN_OTHER_REGION: 'EXISTS_IN_OTHER_REGION',
  INCORRECT_NUMBER: 'INCORRECT_NUMBER',
  INVALID_BIRTHDATE: 'INVALID_BIRTHDATE',
  INVALID_AGE: 'INVALID_AGE',
  INVALID_CHARACTERS: 'INVALID_CHARACTERS',
  INVALID_EMAIL: 'INVALID_EMAIL',
  INVALID_EMAIL_CONFIRMATION_CODE: 'INVALID_EMAIL_CONFIRMATION_CODE',
  INVALID_GERMAN_STATE: 'INVALID_GERMAN_STATE',
  INVALID_INSURANCE_CARD_INFO: 'INVALID_INSURANCE_CARD_INFO',
  INVALID_INSURANCE_NUMBER: 'INVALID_INSURANCE_NUMBER',
  INVALID_LOGIN: 'INVALID_LOGIN',
  INVALID_PASSWORD_CONFIRMATION: 'INVALID_PASSWORD_CONFIRMATION',
  INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
  INVALID_POSTAL_CODE: {
    [COUNTRY_DE]: 'INVALID_POSTAL_CODE',
    [COUNTRY_US]: 'INVALID_ZIP_CODE',
  },
  INVALID_US_STATE: 'INVALID_US_STATE',
  LENGTH: 'LENGTH',
  MIN_LENGTH: 'MIN_LENGTH',
  MISSING_CONSENT: 'MISSING_CONSENT',
  MISSING_CONSENTS_FAILED: 'MISSING_CONSENTS_FAILED',
  MISSING_FIELD: 'MISSING_FIELD',
  NOT_ELIGIBLE_EMAIL: 'NOT_ELIGIBLE_EMAIL',
  PASSWORD_RESET_TOKEN_EXPIRED: 'PASSWORD_RESET_TOKEN_EXPIRED',
  PAYMENT_DETAILS_NOT_SUPPORTED: 'PAYMENT_DETAILS_NOT_SUPPORTED',
  POSSIBLE_PO_BOX: 'POSSIBLE_PO_BOX',
  REQUEST_PASSWORD_RESET_FAILED: 'REQUEST_PASSWORD_RESET_FAILED',
  SERVER_ERROR: 'SERVER_ERROR',
  SHIPMENT_MISSING: 'SHIPMENT_MISSING',
  THREEDS_AUTHENTICATION_FAILED: 'THREEDS_AUTHENTICATION_FAILED',
  TRIGGER_EMAIL_VERIFICATION_FAILED: 'TRIGGER_EMAIL_VERIFICATION_FAILED',
  UNAUTHORIZED: 'UNAUTHORIZED',
  UNKNOWN_ERROR_2: 'UNKNOWN_ERROR_2',
  USER_ALREADY_SUBSCRIBED_TO_BUNDLE: 'USER_ALREADY_SUBSCRIBED_TO_BUNDLE',
  USER_ALREADY_SUBSCRIBED_TO_PLAN: 'USER_ALREADY_SUBSCRIBED_TO_PLAN',
  USER_EMAIL_HAS_ACTIVE_BUNDLE: 'USER_EMAIL_HAS_ACTIVE_BUNDLE',
  USER_EMAIL_HAS_ACTIVE_PRESCRIPTION: 'USER_EMAIL_HAS_ACTIVE_PRESCRIPTION',
  PRESCRIPTION_ALREADY_ACTIVE: 'PRESCRIPTION_ALREADY_ACTIVE',
  USER_EXISTS: 'USER_EXISTS',
  USER_MISSING: 'USER_MISSING',
  VALIDATION_FAILED: 'VALIDATION_FAILED',
  INVALID_VOUCHER_CODE: 'INVALID_VOUCHER_CODE',
  VOUCHER_IS_NOT_VALID: 'INVALID_VOUCHER_CODE',
  THE_VOUCHER_IS_CURRENTLY_NOT_ACTIVE: 'THE_VOUCHER_IS_CURRENTLY_NOT_ACTIVE',
  VOUCHER_ALREADY_EXPIRED: 'VOUCHER_ALREADY_EXPIRED',
  VOUCHER_DOES_NOT_EXISTS: 'VOUCHER_DOES_NOT_EXISTS',
  VOUCHER_MAXIMUM_REDEMPTION_EXCEEDED: 'VOUCHER_MAXIMUM_REDEMPTION_EXCEEDED',
  UNLOCK_CODE_ALREADY_USED: 'UNLOCK_CODE_ALREADY_USED',
  UNLOCK_CODE_INVALID: 'UNLOCK_CODE_INVALID',
  MISSING_CONSENTS: 'MISSING_CONSENTS',
  USER_HAS_NO_BUNDLE: 'USER_HAS_NO_BUNDLE',
  USER_HAS_NO_EXISTING_PAYMENT: 'USER_HAS_NO_EXISTING_PAYMENT',
};

export default errors;
