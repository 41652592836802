import { PROPERTY_CARD_MEMBER_ID } from 'src/constants/bundleProperties';
import errorConstants from 'src/constants/errors';
import { UKV_AND_VKB_INSURANCE_NUMBER_REGEX } from 'src/constants/ukvAndVkbInsuranceNumberRegex';
import BackendClient from 'src/lib/apiClient/BackendClient';
import IBundle from 'src/types/IBundle';
import IFormNotice from 'src/types/IFormNotice';
import {
  loadingBeginAction,
  loadingFailureAction,
  loadingSuccessAction,
} from './loadingActions';

export const INSURANCE_NUMBER_INPUT_ACTION = 'INSURANCE_NUMBER_INPUT_ACTION';
export type INSURANCE_NUMBER_INPUT_ACTION =
  typeof INSURANCE_NUMBER_INPUT_ACTION;

export const INSURANCE_NUMBER_SUCCESS_ACTION =
  'INSURANCE_NUMBER_SUCCESS_ACTION';
export type INSURANCE_NUMBER_SUCCESS_ACTION =
  typeof INSURANCE_NUMBER_SUCCESS_ACTION;

export const INSURANCE_NUMBER_ERROR_ACTION = 'INSURANCE_NUMBER_ERROR_ACTION';
export type INSURANCE_NUMBER_ERROR_ACTION =
  typeof INSURANCE_NUMBER_ERROR_ACTION;

export const CLEAR_INSURANCE_NUMBER_DATA_ACTION =
  'CLEAR_INSURANCE_NUMBER_DATA_ACTION';
export type CLEAR_INSURANCE_NUMBER_DATA_ACTION =
  typeof CLEAR_INSURANCE_NUMBER_DATA_ACTION;

export interface IClearInsuranceNumberDataAction {
  type: CLEAR_INSURANCE_NUMBER_DATA_ACTION;
}

export interface IInsuranceNumberInputAction {
  type: INSURANCE_NUMBER_INPUT_ACTION;
  insuranceNumber: string;
}

export interface IInsuranceNumberSuccessAction {
  type: INSURANCE_NUMBER_SUCCESS_ACTION;
  insuranceNumber: string;
}

export interface IInsuranceNumberErrorAction {
  type: INSURANCE_NUMBER_ERROR_ACTION;
  insuranceNumber: string;
  errors: IFormNotice[];
}

export type InsuranceNumberAction =
  | IClearInsuranceNumberDataAction
  | IInsuranceNumberInputAction
  | IInsuranceNumberErrorAction
  | IInsuranceNumberSuccessAction;

export function clearInsuranceNumberDataAction(): IClearInsuranceNumberDataAction {
  return { type: CLEAR_INSURANCE_NUMBER_DATA_ACTION };
}

export function insuranceNumberInputAction(
  insuranceNumber: string,
): IInsuranceNumberInputAction {
  return { type: INSURANCE_NUMBER_INPUT_ACTION, insuranceNumber };
}

export function insuranceNumberSuccessAction(
  insuranceNumber: string,
): IInsuranceNumberSuccessAction {
  return { type: INSURANCE_NUMBER_SUCCESS_ACTION, insuranceNumber };
}

export function insuranceNumberErrorAction(
  insuranceNumber: string,
  errors: IFormNotice[],
): IInsuranceNumberErrorAction {
  return { type: INSURANCE_NUMBER_ERROR_ACTION, insuranceNumber, errors };
}

export function insuranceNumberValidateAction(
  bundle: IBundle,
  isUkvOrVkbPayor: boolean,
  insuranceNumber: string,
) {
  return async (dispatch: any) => {
    dispatch(loadingBeginAction('validateInsuranceNumberAction'));
    let valid = false;

    if (isUkvOrVkbPayor) {
      valid = UKV_AND_VKB_INSURANCE_NUMBER_REGEX.test(insuranceNumber);
    } else {
      const property =
        await BackendClient.getInstance().validateBundleProperties(bundle, [
          {
            key: PROPERTY_CARD_MEMBER_ID,
            value: insuranceNumber,
          },
        ]);
      valid = property[0].valid === true;
    }

    if (!valid) {
      dispatch(loadingFailureAction('validateInsuranceNumberAction'));
      dispatch(
        insuranceNumberErrorAction(insuranceNumber, [
          {
            field: 'insuranceNumber',
            message: errorConstants.INVALID_INSURANCE_NUMBER,
          },
        ]),
      );
      return false;
    }
    dispatch(loadingSuccessAction('validateInsuranceNumberAction'));
    dispatch(insuranceNumberSuccessAction(insuranceNumber));
    return true;
  };
}
