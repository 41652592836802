import { goToAction } from 'src/actions/flowActions';
import { logoutAction } from 'src/actions/logoutActions';
import { getSteps } from 'src/selectors/flowSelectors';
import IStoreState from 'src/types/IStoreState';

export function unauthorizedErrorFlow() {
  return (dispatch: any, getState: () => IStoreState) => {
    const accountStep = getSteps(getState()).find(
      step => step.name === 'account.stepName',
    )!;
    dispatch(logoutAction());

    dispatch(goToAction(accountStep));
  };
}
