import { COUNTRY_DE, COUNTRY_US } from 'src/constants/countries';
import {
  IBundleImageSet,
  loadBundleImageSet,
} from 'src/lib/loadBundleImageSet';
import {
  loadGrayAndWhiteBundleImageSets,
  loadGrayBundleImageSet,
  loadWhiteBundleImageSet,
} from 'src/lib/loadGrayAndWhiteBundleImageSets';
import { IItemPattern } from 'src/lib/models/modelBundleGroup';
import { BundleGroupItemAttrTypeValue } from 'src/types/IBundleGroupItem';
import { IBundleImageName } from 'src/types/IBundleImageName';
import proVoucher from './proVoucher.svg';

export const IMAGE_PRO_ONLY: IBundleImageSet = {
  box1x: proVoucher,
  box1xWebp: proVoucher,
  box2x: proVoucher,
  box2xWebp: proVoucher,
  box3x: proVoucher,
  box3xWebp: proVoucher,
  name: IBundleImageName.pro,
};

export const IMAGE_BUNDLE_GUIDE_US: IBundleImageSet = loadBundleImageSet(
  'bundle-guide-us/bundle-guide-us',
  IBundleImageName.bundleGuide,
);

export const IMAGE_BUNDLE_GUIDE_US_WHITE: IBundleImageSet = loadBundleImageSet(
  'bundle-guide-us/bundle-guide-us-white',
  IBundleImageName.bundleGuide,
);

export const IMAGE_BUNDLE_FULL_GUIDE_ME_3_STRIPS: IBundleImageSet =
  loadBundleImageSet(
    'bundle-full-guide-me-3-strips/full_bundle_guide_me_3_strip',
    IBundleImageName.bundleGuideMe,
  );

export const IMAGE_BUNDLE_GUIDE_ME_US: IBundleImageSet = loadBundleImageSet(
  'bundle-guide-me-us/bundle-guide-me-us',
  IBundleImageName.bundleGuideMe,
);

export const IMAGE_BUNDLE_GUIDE_ME_US_WHITE: IBundleImageSet =
  loadBundleImageSet(
    'bundle-guide-me-us/bundle-guide-me-us-white',
    IBundleImageName.bundleGuideMe,
  );

export const IMAGE_SET_COACH_ONLY: IBundleImageSet = loadGrayBundleImageSet(
  'coach-only/coach-bundle',
  IBundleImageName.coach,
);

export const IMAGE_SET_COACH_ONLY_WHITE: IBundleImageSet =
  loadWhiteBundleImageSet('coach-only/coach-bundle', IBundleImageName.coach);

export const IMAGE_SET_COACH_AOK_NORTHWEST: IBundleImageSet =
  loadBundleImageSet(
    'bundle-coach-aok-northwest/bundle-coach-aok-northwest',
    IBundleImageName.coach,
  );

export const IMAGE_SET_BUNDLE_GRAY: IBundleImageSet = loadBundleImageSet(
  'bundle/full-bundle-accuchek-gray',
  IBundleImageName.bundleGuide,
);

export const IMAGE_SET_BUNDLE_WHITE: IBundleImageSet = loadBundleImageSet(
  'bundle/full-bundle-accuchek-white',
  IBundleImageName.bundleGuide,
);

export const IMAGE_SET_DEFAULT_GRAY: IBundleImageSet = loadGrayBundleImageSet(
  'generated/default',
  IBundleImageName.bundleGuide,
);

export const IMAGE_SET_DEFAULT_WHITE: IBundleImageSet = loadWhiteBundleImageSet(
  'generated/default',
  IBundleImageName.bundleGuide,
);

/**
 * New mapping to automatically detect image from bundle items
 * SHOP-974
 */
export interface IBundleImageMap {
  itemPattern: IItemPattern;
  imageSet: {
    gray: IBundleImageSet;
    white: IBundleImageSet;
  };
}

/**
 * Explicit definition for which items are in an image
 * @parameter parameters: consists of BundleGroupItemAttrTypeValue's and a boolean or number value
 *  if the parameter value is true it will only use the image if the group has the item
 *  if the parameter value is false it will only use the image if the group does not have the item
 *  if the parameter value is a number it will only use the image if the group has the item and the amount is equal
 * @parameter imageSet: the image set that will be used if the parameters apply
 */
export const bundleImages: IBundleImageMap[] = [
  {
    itemPattern: {
      parameters: {
        [BundleGroupItemAttrTypeValue.guideMe]: false,
        [BundleGroupItemAttrTypeValue.lancingKit]: false,
        [BundleGroupItemAttrTypeValue.coachSub]: true,
        [BundleGroupItemAttrTypeValue.lancets]: false,
        [BundleGroupItemAttrTypeValue.testStrips]: false,
        [BundleGroupItemAttrTypeValue.cuff]: false,
        [BundleGroupItemAttrTypeValue.weightScale]: false,
      },
    },
    imageSet: {
      gray: IMAGE_SET_COACH_ONLY,
      white: IMAGE_SET_COACH_ONLY_WHITE,
    },
  },
  {
    itemPattern: {
      parameters: {
        [BundleGroupItemAttrTypeValue.proSub]: true,
        [BundleGroupItemAttrTypeValue.coachSub]: false,
        [BundleGroupItemAttrTypeValue.lancingKit]: false,
        [BundleGroupItemAttrTypeValue.testStrips]: false,
      },
    },
    imageSet: {
      gray: IMAGE_PRO_ONLY,
      white: IMAGE_PRO_ONLY,
    },
  },
  {
    itemPattern: {
      parameters: {
        [BundleGroupItemAttrTypeValue.guide]: true,
        [BundleGroupItemAttrTypeValue.coachSub]: true,
        [BundleGroupItemAttrTypeValue.testStrips]: 200,
        [BundleGroupItemAttrTypeValue.lancets]: true,
        [BundleGroupItemAttrTypeValue.cuff]: false,
      },
      country: COUNTRY_DE,
    },
    imageSet: {
      gray: IMAGE_SET_BUNDLE_GRAY,
      white: IMAGE_SET_BUNDLE_WHITE,
    },
  },
  {
    itemPattern: {
      parameters: {
        [BundleGroupItemAttrTypeValue.guideMe]: true,
        [BundleGroupItemAttrTypeValue.lancingKit]: true,
        [BundleGroupItemAttrTypeValue.coachSub]: true,
        [BundleGroupItemAttrTypeValue.lancets]: true,
        [BundleGroupItemAttrTypeValue.testStrips]: 250,
        [BundleGroupItemAttrTypeValue.cuff]: false,
        [BundleGroupItemAttrTypeValue.weightScale]: false,
      },
      country: COUNTRY_US,
    },
    imageSet: loadGrayAndWhiteBundleImageSets(
      'generated/coach_guideme_lancingkit_lancets100_strips250',
      IBundleImageName.bundleGuide,
    ),
  },
  {
    itemPattern: {
      parameters: {
        [BundleGroupItemAttrTypeValue.guide]: true,
        [BundleGroupItemAttrTypeValue.coachSub]: true,
        [BundleGroupItemAttrTypeValue.lancets]: true,
        [BundleGroupItemAttrTypeValue.testStrips]: 250,
        [BundleGroupItemAttrTypeValue.cuff]: false,
        [BundleGroupItemAttrTypeValue.weightScale]: false,
      },
      country: COUNTRY_US,
    },
    imageSet: loadGrayAndWhiteBundleImageSets(
      'generated/coach_guide_lancingkit_lancets100_strips250',
      IBundleImageName.bundleGuide,
    ),
  },
  {
    itemPattern: {
      parameters: {
        [BundleGroupItemAttrTypeValue.coachSub]: true,
        [BundleGroupItemAttrTypeValue.lancingKit]: true,
        [BundleGroupItemAttrTypeValue.testStrips]: 100,
        [BundleGroupItemAttrTypeValue.guideMe]: true,
        [BundleGroupItemAttrTypeValue.cuff]: false,
        [BundleGroupItemAttrTypeValue.weightScale]: false,
      },
      country: COUNTRY_US,
    },
    imageSet: loadGrayAndWhiteBundleImageSets(
      'generated/coach_guideme_lancingkit_lancets100_strips100',
      IBundleImageName.bundleGuide,
    ),
  },
  {
    itemPattern: {
      parameters: {
        [BundleGroupItemAttrTypeValue.coachSub]: true,
        [BundleGroupItemAttrTypeValue.guideMe]: true,
        [BundleGroupItemAttrTypeValue.testStrips]: 150,
        [BundleGroupItemAttrTypeValue.lancingKit]: true,
        [BundleGroupItemAttrTypeValue.lancets]: true,
        [BundleGroupItemAttrTypeValue.cuff]: false,
        [BundleGroupItemAttrTypeValue.weightScale]: false,
      },
      country: COUNTRY_US,
    },
    imageSet: loadGrayAndWhiteBundleImageSets(
      'generated/coach_guideme_lancingkit_lancets100_strips150',
      IBundleImageName.bundleGuide,
    ),
  },
  {
    itemPattern: {
      parameters: {
        [BundleGroupItemAttrTypeValue.coachSub]: true,
        [BundleGroupItemAttrTypeValue.lancingKit]: true,
        [BundleGroupItemAttrTypeValue.lancets]: true,
        [BundleGroupItemAttrTypeValue.guideMe]: true,
        [BundleGroupItemAttrTypeValue.testStrips]: 150,
        [BundleGroupItemAttrTypeValue.cuff]: true,
        [BundleGroupItemAttrTypeValue.weightScale]: true,
      },
      country: COUNTRY_US,
    },
    imageSet: loadGrayAndWhiteBundleImageSets(
      'generated/coach_guideme_lancingkit_lancets100_strips150_scale_cuff',
      IBundleImageName.bundleGuide,
    ),
  },
  {
    itemPattern: {
      parameters: {
        [BundleGroupItemAttrTypeValue.coachSub]: true,
        [BundleGroupItemAttrTypeValue.lancingKit]: true,
        [BundleGroupItemAttrTypeValue.lancets]: true,
        [BundleGroupItemAttrTypeValue.guideMe]: true,
        [BundleGroupItemAttrTypeValue.testStrips]: 150,
        [BundleGroupItemAttrTypeValue.cuff]: false,
        [BundleGroupItemAttrTypeValue.weightScale]: true,
      },
      country: COUNTRY_US,
    },
    imageSet: loadGrayAndWhiteBundleImageSets(
      'generated/guideme_lancingkit_lancets100_strips150_scale',
      IBundleImageName.bundleGuide,
    ),
  },
  {
    itemPattern: {
      parameters: {
        [BundleGroupItemAttrTypeValue.coachSub]: true,
        [BundleGroupItemAttrTypeValue.lancingKit]: true,
        [BundleGroupItemAttrTypeValue.lancets]: true,
        [BundleGroupItemAttrTypeValue.guideMe]: true,
        [BundleGroupItemAttrTypeValue.testStrips]: 150,
        [BundleGroupItemAttrTypeValue.cuff]: true,
        [BundleGroupItemAttrTypeValue.weightScale]: false,
      },
      country: COUNTRY_US,
    },
    imageSet: loadGrayAndWhiteBundleImageSets(
      'generated/guideme_lancingkit_lancets100_strips150_cuff',
      IBundleImageName.bundleGuide,
    ),
  },
  {
    itemPattern: {
      parameters: {
        [BundleGroupItemAttrTypeValue.coachSub]: true,
        [BundleGroupItemAttrTypeValue.guideMe]: true,
        [BundleGroupItemAttrTypeValue.softclixLancingKit]: true,
        [BundleGroupItemAttrTypeValue.softclixLancets]: true,
        [BundleGroupItemAttrTypeValue.testStrips]: 150,
        [BundleGroupItemAttrTypeValue.cuff]: true,
        [BundleGroupItemAttrTypeValue.weightScale]: false,
      },
      country: COUNTRY_US,
    },
    imageSet: loadGrayAndWhiteBundleImageSets(
      'generated/coach_guideme_softclix_lancingkit_lancets100_strips150_cuff',
      IBundleImageName.bundleGuide,
    ),
  },
  {
    itemPattern: {
      parameters: {
        [BundleGroupItemAttrTypeValue.coachSub]: true,
        [BundleGroupItemAttrTypeValue.guideMe]: true,
        [BundleGroupItemAttrTypeValue.softclixLancingKit]: true,
        [BundleGroupItemAttrTypeValue.softclixLancets]: true,
        [BundleGroupItemAttrTypeValue.testStrips]: 150,
        [BundleGroupItemAttrTypeValue.cuff]: true,
        [BundleGroupItemAttrTypeValue.weightScale]: true,
      },
      country: COUNTRY_US,
    },
    imageSet: loadGrayAndWhiteBundleImageSets(
      'generated/coach_guideme_softclix_lancingkit_lancets100_strips150_scale_cuff',
      IBundleImageName.bundleGuide,
    ),
  },
  {
    itemPattern: {
      parameters: {
        [BundleGroupItemAttrTypeValue.coachSub]: true,
        [BundleGroupItemAttrTypeValue.guideMe]: true,
        [BundleGroupItemAttrTypeValue.softclixLancingKit]: true,
        [BundleGroupItemAttrTypeValue.softclixLancets]: true,
        [BundleGroupItemAttrTypeValue.testStrips]: 150,
        [BundleGroupItemAttrTypeValue.cuff]: false,
        [BundleGroupItemAttrTypeValue.weightScale]: true,
      },
      country: COUNTRY_US,
    },
    imageSet: loadGrayAndWhiteBundleImageSets(
      'generated/coach_guideme_softclix_lancingkit_lancets100_strips150_scale',
      IBundleImageName.bundleGuide,
    ),
  },
  {
    itemPattern: {
      parameters: {
        [BundleGroupItemAttrTypeValue.coachSub]: true,
        [BundleGroupItemAttrTypeValue.guideMe]: true,
        [BundleGroupItemAttrTypeValue.softclixLancingKit]: true,
        [BundleGroupItemAttrTypeValue.softclixLancets]: true,
        [BundleGroupItemAttrTypeValue.testStrips]: 150,
        [BundleGroupItemAttrTypeValue.cuff]: false,
        [BundleGroupItemAttrTypeValue.weightScale]: false,
      },
      country: COUNTRY_US,
    },
    imageSet: loadGrayAndWhiteBundleImageSets(
      'generated/coach_guideme_softclix_lancingkit_lancets100_strips150',
      IBundleImageName.bundleGuide,
    ),
  },
  {
    itemPattern: {
      parameters: {
        [BundleGroupItemAttrTypeValue.coachSub]: true,
        [BundleGroupItemAttrTypeValue.guideMe]: true,
        [BundleGroupItemAttrTypeValue.softclixLancingKit]: true,
        [BundleGroupItemAttrTypeValue.softclixLancets]: true,
        [BundleGroupItemAttrTypeValue.testStrips]: 250,
        [BundleGroupItemAttrTypeValue.cuff]: true,
        [BundleGroupItemAttrTypeValue.weightScale]: false,
      },
      country: COUNTRY_US,
    },
    imageSet: loadGrayAndWhiteBundleImageSets(
      'generated/coach_guideme_softclix_lancingkit_lancets200_strips250_cuff',
      IBundleImageName.bundleGuide,
    ),
  },
  {
    itemPattern: {
      parameters: {
        [BundleGroupItemAttrTypeValue.coachSub]: true,
        [BundleGroupItemAttrTypeValue.guideMe]: true,
        [BundleGroupItemAttrTypeValue.softclixLancingKit]: true,
        [BundleGroupItemAttrTypeValue.softclixLancets]: true,
        [BundleGroupItemAttrTypeValue.testStrips]: 250,
        [BundleGroupItemAttrTypeValue.cuff]: true,
        [BundleGroupItemAttrTypeValue.weightScale]: true,
      },
      country: COUNTRY_US,
    },
    imageSet: loadGrayAndWhiteBundleImageSets(
      'generated/coach_guideme_softclix_lancingkit_lancets200_strips250_scale_cuff',
      IBundleImageName.bundleGuide,
    ),
  },
  {
    itemPattern: {
      parameters: {
        [BundleGroupItemAttrTypeValue.coachSub]: true,
        [BundleGroupItemAttrTypeValue.guideMe]: true,
        [BundleGroupItemAttrTypeValue.softclixLancingKit]: true,
        [BundleGroupItemAttrTypeValue.softclixLancets]: true,
        [BundleGroupItemAttrTypeValue.testStrips]: 250,
        [BundleGroupItemAttrTypeValue.cuff]: false,
        [BundleGroupItemAttrTypeValue.weightScale]: true,
      },
      country: COUNTRY_US,
    },
    imageSet: loadGrayAndWhiteBundleImageSets(
      'generated/coach_guideme_softclix_lancingkit_lancets200_strips250_scale',
      IBundleImageName.bundleGuide,
    ),
  },
  {
    itemPattern: {
      parameters: {
        [BundleGroupItemAttrTypeValue.coachSub]: true,
        [BundleGroupItemAttrTypeValue.guideMe]: true,
        [BundleGroupItemAttrTypeValue.softclixLancingKit]: true,
        [BundleGroupItemAttrTypeValue.softclixLancets]: true,
        [BundleGroupItemAttrTypeValue.testStrips]: 250,
        [BundleGroupItemAttrTypeValue.cuff]: false,
        [BundleGroupItemAttrTypeValue.weightScale]: false,
      },
      country: COUNTRY_US,
    },
    imageSet: loadGrayAndWhiteBundleImageSets(
      'generated/coach_guideme_softclix_lancingkit_lancets200_strips250',
      IBundleImageName.bundleGuide,
    ),
  },
];
