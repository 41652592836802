import IBundleGroupItem, {
  BundleGroupItemAttrKey,
  BundleGroupItemAttrTypeValue,
  BundleGroupItemAttrValue,
} from 'src/types/IBundleGroupItem';
import { parseItemAttributes } from './parseItemAttributes';

const getItemAttribute = (
  attribute: BundleGroupItemAttrKey,
  item: IBundleGroupItem,
): BundleGroupItemAttrValue | BundleGroupItemAttrTypeValue | undefined => {
  const att = parseItemAttributes(item);
  return att[attribute];
};

export default getItemAttribute;
