/* istanbul ignore file */

import {
  LocationProvider,
  LocationProviderProps,
} from '@gatsbyjs/reach-router';
import * as queryString from 'query-string';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { Dispatch } from 'redux';
import { PersistGate } from 'redux-persist/integration/react';
import {
  getBundleConfigurationsSuccessAction,
  setCartAction,
} from 'src/actions/bundleActions';
import { setTranslationsAction } from 'src/actions/translationActions';
import { IServerFlowData } from 'src/server/lib/types';
import { setSourceAction } from './actions/flowActions';
import {
  loadingBeginAction,
  loadingSuccessAction,
} from './actions/loadingActions';
import { setAppUserAction } from './actions/userActions';
import App from './components/App/App';
import configureStore from './configureStore';
import './index.scss';
import BackendClient from './lib/apiClient/BackendClient';
import hasCssGridSupport from './lib/hasCssGridSupport';
import IAppUser from './types/IAppUser';
import IEnvironment from './types/IEnvironment';
import IShopWindow from './types/IShopWindow';
import { setAccessToken } from './actions/loginActions';
import getLocaleFromPathname from './lib/getLocaleFromPathname';
import { SUPPORTED_LANGUAGES } from './constants/languages';
import { loadLanguageFile } from './server/lib/loadLanguageFile';

function Void(): React.ReactElement {
  return <span />;
}

const env = process.env as any as IEnvironment;

const NODE_ENV = env.NODE_ENV || 'development';
const BASE_URL_EU = env.REACT_APP_API_BASE_URL_EU || '';
const BASE_URL_US = env.REACT_APP_API_BASE_URL_US || '';

const urlQuery = queryString.parse(window.location.search);

const shopWindow: IShopWindow = window;

BackendClient.getInstance({
  baseUrls: { eu: BASE_URL_EU, us: BASE_URL_US },
  environment: NODE_ENV,
  region: 'eu',
});

// This is a TypeScript type hack because we want to retrieve the history from the default props
// By not creating our own history, `navigate` does still work in @gatsbyjs/reach-router
const locationProvider = LocationProvider as unknown as LocationProvider & {
  defaultProps: LocationProviderProps;
};
const history = locationProvider.defaultProps.history;

const { store, persistor } = configureStore(async (dispatch: Dispatch) => {
  const flowData = shopWindow.flowData as IServerFlowData;
  if (flowData) {
    if (flowData.bundle) {
      dispatch(getBundleConfigurationsSuccessAction([flowData.bundle]));
    }

    if (flowData.cart) {
      dispatch(setCartAction(flowData.cart));
    }

    if (flowData.translations) {
      dispatch(setTranslationsAction(flowData.translations));
    }

    // Refetch once more the prerendered translation setup on client side in case of redirect
    const [language] = getLocaleFromPathname(window.location.pathname);
    if (language && SUPPORTED_LANGUAGES.includes(language)) {
      const lang = await loadLanguageFile(language.toLowerCase());
      flowData.translations = lang;
      dispatch(setTranslationsAction(flowData.translations));
    }
  }

  if (
    urlQuery.utm_source === 'app-ios' ||
    urlQuery.utm_source === 'app-android'
  ) {
    dispatch(setSourceAction(urlQuery.utm_source));
    dispatch(loadingBeginAction('transferData'));
    setTimeout(() => {
      dispatch(loadingSuccessAction('transferData'));
    }, 3000);
  } else {
    dispatch(setSourceAction('web'));
  }

  const helmetContext = {};

  const rootElement = document.getElementById('root') as HTMLElement;

  if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(
      <LocationProvider history={history}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <HelmetProvider context={helmetContext}>
              <App />
            </HelmetProvider>
          </PersistGate>
        </Provider>
      </LocationProvider>,
      rootElement,
    );
  } else {
    ReactDOM.render(
      <LocationProvider history={history}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <HelmetProvider context={helmetContext}>
              <App />
              <Void />
            </HelmetProvider>
          </PersistGate>
        </Provider>
      </LocationProvider>,
      rootElement,
    );
  }
});

shopWindow.transferData = (appUser: IAppUser) => {
  store.dispatch(setAppUserAction(appUser));
  store.dispatch(loadingSuccessAction('transferData'));
};

shopWindow.setAuthToken = (accessToken: string, refreshToken?: string) => {
  store.dispatch(setAccessToken(accessToken, refreshToken));
};
// add `no-grid` class to body when css grid is not supported in browser
document.body.className = (
  document.body.className + (hasCssGridSupport() ? '' : ' no-grid')
).trim();
