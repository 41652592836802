import {
  ILoadingBeginAction,
  ILoadingFailureAction,
  ILoadingSuccessAction,
  LOADING_BEGIN_ACTION,
  LOADING_CLEAN_ACTION,
  LOADING_FAILURE_ACTION,
  LOADING_SUCCESS_ACTION,
  LoadingAction,
} from 'src/actions/loadingActions';
import ILoadingState from 'src/types/ILoadingState';

export const initialState: ILoadingState = {
  loading: [],
};

function handleLoadingBegin(
  state: ILoadingState,
  action: ILoadingBeginAction,
): ILoadingState {
  if (!state.loading.includes(action.resource)) {
    const loading = [...state.loading];
    loading.push(action.resource);
    return { ...state, loading };
  }
  return state;
}

function handleLoadingDone(
  state: ILoadingState,
  action: ILoadingSuccessAction | ILoadingFailureAction,
): ILoadingState {
  const loading = state.loading.filter(
    resource => resource !== action.resource,
  );
  return { ...state, loading: [...loading] };
}

function handleLoadingClean(state: ILoadingState): ILoadingState {
  return { ...state, loading: [] };
}

function loadingReducer(
  state: ILoadingState = initialState,
  action: LoadingAction | { type: null } = { type: null },
) {
  switch (action.type) {
    case LOADING_BEGIN_ACTION:
      return handleLoadingBegin(state, action);

    case LOADING_SUCCESS_ACTION:
      return handleLoadingDone(state, action);

    case LOADING_FAILURE_ACTION:
      return handleLoadingDone(state, action);

    case LOADING_CLEAN_ACTION:
      return handleLoadingClean(state);
  }
  return state;
}

export default loadingReducer;
