import { LocationContext } from '@gatsbyjs/reach-router';
import React from 'react';
import { useSelector } from 'react-redux';
import NotFoundPage from 'src/components/NotFoundPage/NotFoundPage';
import withLocation from 'src/components/withLocation/withLocation';
import { SUPPORTED_COUNTRIES } from 'src/constants/countries';
import { LANGUAGE, SUPPORTED_LANGUAGES } from 'src/constants/languages';
import getBundleLanguages from 'src/lib/getBundleLanguages';
import getLocaleFromPathname from 'src/lib/getLocaleFromPathname';
import {
  getNonBundleFlowConfig,
  getSelectedBundle,
} from 'src/selectors/bundleSelectors';

interface IProps extends LocationContext {
  default: boolean;
  children: any;
}

export function RequiresValidLocale({
  location,
  children,
  navigate,
}: Readonly<IProps>) {
  const pathname = location.pathname;
  const bundle = useSelector(getSelectedBundle);
  const nonBundleFlow = useSelector(getNonBundleFlowConfig);

  const [language, country] = React.useMemo(
    () => getLocaleFromPathname(pathname),
    [pathname],
  );
  const validLanguage = React.useMemo(
    () => SUPPORTED_LANGUAGES.indexOf(language as LANGUAGE) !== -1,
    [language],
  );

  const redirectToLanguage = React.useMemo(() => {
    if (bundle) {
      const bundleLanguages = getBundleLanguages(bundle);

      const supports = bundleLanguages.indexOf(language as LANGUAGE) !== -1;

      if (!supports) {
        return bundleLanguages[0];
      }
    }

    if (nonBundleFlow && nonBundleFlow.LANGUAGES) {
      const supports =
        nonBundleFlow.LANGUAGES.indexOf(language as LANGUAGE) !== -1;
      if (!supports) {
        return nonBundleFlow.LANGUAGES[0];
      }
    }

    return false;
  }, [language, bundle, nonBundleFlow]);

  React.useEffect(() => {
    if (redirectToLanguage) {
      const newPathname = pathname.replace(
        `/${language}-`,
        `/${redirectToLanguage}-`,
      );
      navigate(newPathname, { replace: true });
    }
  }, [redirectToLanguage]);

  const validCountry = React.useMemo(
    () =>
      typeof country === 'undefined' ||
      SUPPORTED_COUNTRIES.indexOf(country) !== -1,
    [country],
  );

  if (validLanguage && validCountry) {
    return <>{children}</>;
  } else {
    return <NotFoundPage />;
  }
}

export default withLocation(RequiresValidLocale);
