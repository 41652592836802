import { LocationContext } from '@gatsbyjs/reach-router';
import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import {
  insuranceGroupNumberInputAction,
  insuranceMemberIdInputAction,
  validateInsuranceCardAction,
} from 'src/actions/bundleActions';
import { translateAction } from 'src/actions/translationActions';
import Button from 'src/components/Button/Button';
import bundleOverrides from 'src/config/nonStandardBundleOverride/bundleOverrides';
import { PROPERTY_CARD_MEMBER_ID } from 'src/constants/bundleProperties';
import focusErrorFields from 'src/lib/focusErrorFields';
import getLocaleFromPathname from 'src/lib/getLocaleFromPathname';
import modelBundle from 'src/lib/models/modelBundle';
import modelBundleGroup from 'src/lib/models/modelBundleGroup';
import IBundle from 'src/types/IBundle';
import IBundleGroup from 'src/types/IBundleGroup';
import IFormNotice from 'src/types/IFormNotice';
import { IInsurance } from 'src/types/IInsurance';
import IInsuranceCard from 'src/types/IInsuranceCard';
import IStoreState from 'src/types/IStoreState';
import IValidateResponse from 'src/types/IValidateResponse';
import FormField from '../FormField/FormField';
import Overlay from '../Overlay/Overlay';
import TextInput from '../TextInput/TextInput';
import Translate from '../Translate/Translate';
import withLocation from '../withLocation/withLocation';
import css from './InsuranceCardForm.module.scss';
import renderMoreInfoButton from 'src/lib/renderMoreInfoButton';
import {
  ENROLLMENT_BARRIER_INFO_INSURANCE_CARD,
  INSURANCE_CODE_INPUT_FIELD_LABEL_1,
  INSURANCE_CODE_INPUT_FIELD_LABEL_2,
  INSURANCE_CODE_INPUT_FIELD_PLACEHOLDER_1,
  INSURANCE_CODE_INPUT_FIELD_PLACEHOLDER_2,
} from 'src/constants/customTextKeys';

const FORM_NAME = 'INSURANCE_CARD_FORM';

interface IStateProps {
  errors: IFormNotice[];
  insurance?: IInsurance;
  insuranceCard?: IInsuranceCard;
}

interface IDispatchProps {
  memberIdInput: (target: HTMLInputElement) => void;
  setGroupNumber: (groupNumber: string) => void;
  groupNumberInput: (target: HTMLInputElement) => void;
  validateInsuranceCard: (
    bundle: IBundle,
    insuranceCard: IInsuranceCard,
  ) => Promise<IValidateResponse>;
  translate: (
    key: string,
    variables?: Record<string, any>,
    ignoreIfEmpty?: boolean,
  ) => string;
}

export interface IOwnProps {
  bundle: IBundle;
  buttonClassName?: string;
  group: IBundleGroup;
  onSubmit: () => void;
}

export interface IProps
  extends IOwnProps,
    IStateProps,
    IDispatchProps,
    LocationContext {}

export function InsuranceCardForm({
  bundle,
  group,
  buttonClassName,
  errors,
  groupNumberInput,
  setGroupNumber,
  insurance,
  insuranceCard,
  memberIdInput,
  onSubmit,
  translate,
  validateInsuranceCard,
  location,
}: IProps) {
  const [overlayVisible, setOverlayVisible] = React.useState(false);
  const [groupIdInputVisible, setGroupIdInputVisible] = React.useState(true);
  const groupModel = modelBundleGroup(group);
  const isOnlyCoach = groupModel.isCoachOnly();

  const pathname = location.pathname;

  const [language] = React.useMemo(
    () => getLocaleFromPathname(pathname),
    [pathname],
  );

  React.useEffect(() => {
    const customOverwrite = bundleOverrides[bundle.code];
    if (insurance && customOverwrite?.insuranceGroupIdMap?.[insurance]) {
      setGroupNumber(customOverwrite.insuranceGroupIdMap[insurance]);
      setGroupIdInputVisible(false);
    } else {
      setGroupIdInputVisible(true);
    }
  }, [insurance]);

  const showOverlay = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setOverlayVisible(true);
  };
  const hideOverlay = () => setOverlayVisible(false);

  const memberId = insuranceCard ? insuranceCard.memberId : '';
  const groupNumber = insuranceCard ? insuranceCard.groupNumber : '';

  const handleSubmit = React.useCallback(
    async ({ nativeEvent }: React.SyntheticEvent) => {
      nativeEvent.preventDefault();
      const validatedCard = await validateInsuranceCard(bundle, {
        groupNumber,
        memberId,
      });

      if (!validatedCard.valid) {
        focusErrorFields(nativeEvent.target as HTMLFormElement, () =>
          [...validatedCard.errors].reverse(),
        );
      } else {
        onSubmit();
      }
    },
    [bundle, memberId, groupNumber],
  );

  const insuranceTransSuffix = insurance ? `.${insurance}` : '';

  const bundleModel = modelBundle(bundle);
  const bundleGroupNumberPlaceholder = bundleModel.getCustomStringValue(
    INSURANCE_CODE_INPUT_FIELD_PLACEHOLDER_2,
    language,
  );
  const bundleGroupMemberIdPlaceholder = bundleModel.getCustomStringValue(
    INSURANCE_CODE_INPUT_FIELD_PLACEHOLDER_1,
    language,
  );
  const bundleGroupNumberLabel = bundleModel.getCustomStringValue(
    INSURANCE_CODE_INPUT_FIELD_LABEL_2,
    language,
  );
  const bundleGroupMemberIdLabel = bundleModel.getCustomStringValue(
    INSURANCE_CODE_INPUT_FIELD_LABEL_1,
    language,
  );
  const insuranceCardEnrollmentBarrierInfo = bundleModel.getCustomStringValue(
    ENROLLMENT_BARRIER_INFO_INSURANCE_CARD,
    language,
  );

  const groupNumberPlaceholder =
    bundleGroupNumberPlaceholder ||
    translate(
      `insuranceCardForm.groupNumberPlaceholder${insuranceTransSuffix}`,
      {},
      true,
    ) ||
    translate('insuranceCardForm.groupNumberPlaceholder');

  const memberIdPlaceholder =
    bundleGroupMemberIdPlaceholder ||
    translate(
      `insuranceCardForm.memberIdPlaceholder${insuranceTransSuffix}`,
      {},
      true,
    ) ||
    translate('insuranceCardForm.memberIdPlaceholder');

  return (
    <form
      className={classnames(css.form, { [css.hasCoachOnly]: isOnlyCoach })}
      onSubmit={handleSubmit}
    >
      {bundle.payor?.code && (
        <Overlay show={overlayVisible} onClose={hideOverlay}>
          <div className={css.overlayContent}>
            <p>
              {insuranceCardEnrollmentBarrierInfo || (
                <Translate
                  html={true}
                  t={`insuranceCardForm.infoTextGeneric${insuranceTransSuffix}`}
                  altKey="insuranceCardForm.infoTextGeneric"
                />
              )}
            </p>
          </div>
        </Overlay>
      )}
      <div
        className={classnames(css.inputs, { [css.hasCoachOnly]: isOnlyCoach })}
      >
        <FormField
          className={classnames(css.field, {
            [css.groupIdInputVisible]: groupIdInputVisible,
            [css.hasCoachOnly]: isOnlyCoach,
          })}
          errors={errors.filter(
            error => error.field === PROPERTY_CARD_MEMBER_ID,
          )}
          formName={FORM_NAME}
          labelBundle={bundleGroupMemberIdLabel}
          label={`insuranceCardForm.memberIdLabel${insuranceTransSuffix}`}
          labelFallback="insuranceCardForm.memberIdLabel"
          name={PROPERTY_CARD_MEMBER_ID}
          warnings={[]}
          moreInfo={{
            type: 'icon',
            render: renderMoreInfoButton(
              translate('voucherForm.overlayButtonText'),
              showOverlay,
            ),
          }}
        >
          <TextInput
            error={errors.length > 0}
            formName={FORM_NAME}
            name={PROPERTY_CARD_MEMBER_ID}
            onChange={memberIdInput}
            placeholder={memberIdPlaceholder}
            value={memberId}
          />
        </FormField>
        {groupIdInputVisible && (
          <FormField
            className={classnames(css.field, {
              [css.groupIdInputVisible]: groupIdInputVisible,
              [css.hasCoachOnly]: isOnlyCoach,
            })}
            errors={errors.filter(error => error.field === 'groupNumber')}
            formName={FORM_NAME}
            labelBundle={bundleGroupNumberLabel}
            label={`insuranceCardForm.groupNumberLabel${insuranceTransSuffix}`}
            labelFallback="insuranceCardForm.groupNumberLabel"
            name="groupNumber"
            warnings={[]}
          >
            <TextInput
              error={errors.length > 0}
              formName={FORM_NAME}
              name="groupNumber"
              onChange={groupNumberInput}
              placeholder={groupNumberPlaceholder}
              value={groupNumber}
            />
          </FormField>
        )}
      </div>
      <div
        className={classnames(css.actions, { [css.hasCoachOnly]: isOnlyCoach })}
      >
        <Button
          className={buttonClassName}
          large={true}
          secondary={true}
          type="submit"
        >
          <Translate t="bundleProduct.continueButton" />
        </Button>
      </div>
    </form>
  );
}

export function mapStateToProps(state: IStoreState): IStateProps {
  return {
    errors: state.bundle.insuranceCardErrors
      ? state.bundle.insuranceCardErrors
      : [],
    insurance: state.bundle.insurance,
    insuranceCard: state.bundle.insuranceCard,
  };
}

export function mapDispatchToProps(dispatch: any): IDispatchProps {
  return {
    groupNumberInput: (target: HTMLInputElement) =>
      dispatch(insuranceGroupNumberInputAction(target.value)),
    memberIdInput: (target: HTMLInputElement) =>
      dispatch(insuranceMemberIdInputAction(target.value)),
    setGroupNumber: (groupNumber: string) =>
      dispatch(insuranceGroupNumberInputAction(groupNumber)),
    translate: (
      key: string,
      variables?: Record<string, any>,
      ignoreIfEmpty?: boolean,
    ) => dispatch(translateAction(key, variables, ignoreIfEmpty)),
    validateInsuranceCard: async (
      bundle: IBundle,
      insuranceCard: IInsuranceCard,
    ) => dispatch(validateInsuranceCardAction(bundle, insuranceCard)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocation(InsuranceCardForm));
