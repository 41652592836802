import IEnvironment from 'src/types/IEnvironment';

const browserOnly = (fn: () => any, defaultValue?: any) => {
  const env = process.env as any as IEnvironment;
  if (env.REACT_APP_SERVER_SIDE_RENDERING !== '1') {
    return fn();
  }
  return defaultValue;
};

export default browserOnly;
