import classnames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import browserOnly from 'src/lib/browserOnly';
import IEnvironment from 'src/types/IEnvironment';
import IShopWindow from 'src/types/IShopWindow';
import scrollDownIcon from './scroll-down.svg';
import css from './ScrollDownIcon.module.scss';

export interface IStateProps {
  shopWindow?: IShopWindow;
  ssr: boolean;
}

const env = process.env as any as IEnvironment;

export function ScrollDownIcon({ shopWindow, ssr }: Readonly<IStateProps>) {
  const [visible, setVisibility] = React.useState(true);
  React.useEffect(() => {
    const handleScroll = () => {
      const topOffset = shopWindow!.scrollY;
      // istanbul ignore else
      if (topOffset > 100) {
        setVisibility(false);
        shopWindow!.removeEventListener('scroll', handleScroll);
      }
    };

    shopWindow!.addEventListener('scroll', handleScroll);
  }, []);

  if (ssr) {
    return null;
  }

  return (
    <div
      aria-hidden
      className={classnames(css.container, { [css.hidden]: !visible })}
    >
      <img alt="" src={scrollDownIcon} aria-hidden={true} />
    </div>
  );
}

export function mapStateToProps(): IStateProps {
  return {
    shopWindow: browserOnly(() => window as IShopWindow),
    ssr: env.REACT_APP_SERVER_SIDE_RENDERING === '1',
  };
}

export default connect(mapStateToProps)(ScrollDownIcon);
