import {
  ADD_ELIGIBILITY_ERROR_ACTION,
  ELIGIBILITY_CHECK_FAILURE_ACTION,
  ELIGIBILITY_CHECK_SUCCESS_ACTION,
  EligibilityCheckActions,
  IAddEligibilityErrorAction,
  IEligibilityCheckFailureAction,
  IEligibilityCheckSuccessAction,
  IEligibilityStoreEmailAction,
  REMOVE_ELIGIBILITY_ERRORS_ACTION,
  ELIGIBILITY_STORE_EMAIL_ACTION,
} from 'src/actions/eligibilityCheckActions';
import IEligibilityCheckState from 'src/types/IEligibilityCheckState';

export const initialState: IEligibilityCheckState = {
  errors: [],
};

function handleEligibilityCheckError(
  state: IEligibilityCheckState,
  action: IAddEligibilityErrorAction,
): IEligibilityCheckState {
  return {
    ...state,
    errors: [
      ...state.errors.filter(
        existingError => existingError.field !== action.error.field,
      ),
      action.error,
    ],
  };
}

function handleRemoveEligibilityErrors(
  state: IEligibilityCheckState,
): IEligibilityCheckState {
  return { ...state, errors: [] };
}

function handleEligibilityCheckFailure(
  state: IEligibilityCheckState,
  action: IEligibilityCheckFailureAction,
): IEligibilityCheckState {
  return { ...state, companyEmail: action.companyEmail };
}

function handleEligibilityCheckSuccess(
  state: IEligibilityCheckState,
  action: IEligibilityCheckSuccessAction,
): IEligibilityCheckState {
  return { ...state, companyEmail: action.companyEmail, payor: action.payor };
}

function handleEligibilityStoreEmail(
  state: IEligibilityCheckState,
  action: IEligibilityStoreEmailAction,
): IEligibilityCheckState {
  return { ...state, companyEmail: action.companyEmail };
}

function eligibilityCheckReducer(
  state: IEligibilityCheckState = initialState,
  action: EligibilityCheckActions | { type: null } = { type: null },
): IEligibilityCheckState {
  switch (action.type) {
    case ADD_ELIGIBILITY_ERROR_ACTION:
      return handleEligibilityCheckError(state, action);
    case REMOVE_ELIGIBILITY_ERRORS_ACTION:
      return handleRemoveEligibilityErrors(state);
    case ELIGIBILITY_CHECK_FAILURE_ACTION:
      return handleEligibilityCheckFailure(state, action);
    case ELIGIBILITY_CHECK_SUCCESS_ACTION:
      return handleEligibilityCheckSuccess(state, action);
    case ELIGIBILITY_STORE_EMAIL_ACTION:
      return handleEligibilityStoreEmail(state, action);
  }

  return state;
}

export default eligibilityCheckReducer;
