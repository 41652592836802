import { Dispatch } from 'redux';
import {
  PROPERTY_CARD_GROUP_ID,
  PROPERTY_CARD_MEMBER_ID,
} from 'src/constants/bundleProperties';
import errorConstants from 'src/constants/errors';
import BackendClient from 'src/lib/apiClient/BackendClient';
import cleanString from 'src/lib/cleanString';
import IBundle from 'src/types/IBundle';
import IBundleGroup from 'src/types/IBundleGroup';
import ICartBundle from 'src/types/ICartBundle';
import IErrorEndpoint from 'src/types/IErrorEndpoint';
import IFormNotice from 'src/types/IFormNotice';
import { IInsurance } from 'src/types/IInsurance';
import IInsuranceCard from 'src/types/IInsuranceCard';
import IServerError from 'src/types/IServerError';
import IStoreState from 'src/types/IStoreState';
import IValidateResponse from 'src/types/IValidateResponse';
import {
  loadingBeginAction,
  loadingFailureAction,
  loadingSuccessAction,
} from './loadingActions';
import { IToggleConsentAction } from './registerActions';
import { IValidateVoucherCodeSuccessAction } from './voucherActions';

export const GET_BUNDLE_SUCCESS_ACTION = 'GET_BUNDLE_SUCCESS_ACTION';
export type GET_BUNDLE_SUCCESS_ACTION = typeof GET_BUNDLE_SUCCESS_ACTION;

export const GET_BUNDLE_FAILURE_ACTION = 'GET_BUNDLE_FAILURE_ACTION';
export type GET_BUNDLE_FAILURE_ACTION = typeof GET_BUNDLE_FAILURE_ACTION;

export const GET_BUNDLE_CONFIGS_SUCCESS_ACTION =
  'GET_BUNDLE_CONFIGS_SUCCESS_ACTION';
export type GET_BUNDLE_CONFIGS_SUCCESS_ACTION =
  typeof GET_BUNDLE_CONFIGS_SUCCESS_ACTION;

export const GET_BUNDLE_CONFIGS_FAILURE_ACTION =
  'GET_BUNDLE_CONFIGS_FAILURE_ACTION';
export type GET_BUNDLE_CONFIGS_FAILURE_ACTION =
  typeof GET_BUNDLE_CONFIGS_FAILURE_ACTION;

export const ADD_BUNDLE_TO_CART_ACTION = 'ADD_BUNDLE_TO_CART_ACTION';
export type ADD_BUNDLE_TO_CART_ACTION = typeof ADD_BUNDLE_TO_CART_ACTION;

export const ORDER_BUNDLE_SUCCESS_ACTION = 'ORDER_BUNDLE_SUCCESS_ACTION';
export type ORDER_BUNDLE_SUCCESS_ACTION = typeof ORDER_BUNDLE_SUCCESS_ACTION;

export const ORDER_BUNDLE_FAILURE_ACTION = 'ORDER_BUNDLE_FAILURE_ACTION';
export type ORDER_BUNDLE_FAILURE_ACTION = typeof ORDER_BUNDLE_FAILURE_ACTION;

export const REDEEM_VOUCHER_SUCCESS_ACTION = 'REDEEM_VOUCHER_SUCCESS_ACTION';
export type REDEEM_VOUCHER_SUCCESS_ACTION =
  typeof REDEEM_VOUCHER_SUCCESS_ACTION;

export const REDEEM_VOUCHER_FAILURE_ACTION = 'REDEEM_VOUCHER_FAILURE_ACTION';
export type REDEEM_VOUCHER_FAILURE_ACTION =
  typeof REDEEM_VOUCHER_FAILURE_ACTION;

export const VOUCHER_SET_ERRORS = 'VOUCHER_SET_ERRORS';
export type VOUCHER_SET_ERRORS = typeof VOUCHER_SET_ERRORS;

export const CLEAR_CART_ACTION = 'CLEAR_CART_ACTION';
export type CLEAR_CART_ACTION = typeof CLEAR_CART_ACTION;

export const INSURANCE_MEMBER_ID_INPUT_ACTION =
  'INSURANCE_MEMBER_ID_INPUT_ACTION';
export type INSURANCE_MEMBER_ID_INPUT_ACTION =
  typeof INSURANCE_MEMBER_ID_INPUT_ACTION;

export const INSURANCE_GROUP_NUMBER_INPUT_ACTION =
  'INSURANCE_GROUP_NUMBER_INPUT_ACTION';
export type INSURANCE_GROUP_NUMBER_INPUT_ACTION =
  typeof INSURANCE_GROUP_NUMBER_INPUT_ACTION;

export const VALIDATE_INSURANCE_CARD_SUCCESS_ACTION =
  'VALIDATE_INSURANCE_CARD_SUCCESS_ACTION';
export type VALIDATE_INSURANCE_CARD_SUCCESS_ACTION =
  typeof VALIDATE_INSURANCE_CARD_SUCCESS_ACTION;

export const VALIDATE_INSURANCE_CARD_FAILURE_ACTION =
  'VALIDATE_INSURANCE_CARD_FAILURE_ACTION';
export type VALIDATE_INSURANCE_CARD_FAILURE_ACTION =
  typeof VALIDATE_INSURANCE_CARD_FAILURE_ACTION;

export const CLEAR_USER_BUNDLE_DATA = 'CLEAR_USER_BUNDLE_DATA';
export type CLEAR_USER_BUNDLE_DATA = typeof CLEAR_USER_BUNDLE_DATA;

export const CUSTOM_VERIFICATION_CODE_INPUT = 'CUSTOM_CODE_VALIDATION_INPUT';
export type CUSTOM_CODE_VALIDATION_INPUT =
  typeof CUSTOM_VERIFICATION_CODE_INPUT;

export const SET_CUSTOM_VERIFICATION_CODE_ERRORS =
  'SET_CUSTOM_CODE_VALIDATION_ERRORS';
export type SET_CUSTOM_CODE_VALIDATION_ERRORS =
  typeof SET_CUSTOM_VERIFICATION_CODE_ERRORS;

export const SET_INSURANCE_ACTION = 'SET_INSURANCE_ACTION';
export type SET_INSURANCE_ACTION = typeof SET_INSURANCE_ACTION;

export const SET_CART_ACTION = 'SET_CART_ACTION';
export type SET_CART_ACTION = typeof SET_CART_ACTION;

export interface ICustomVerificationCodeInputAction {
  type: CUSTOM_CODE_VALIDATION_INPUT;
  code: string;
}

export interface ISetCustomVerificationCodeErrorAction {
  type: SET_CUSTOM_CODE_VALIDATION_ERRORS;
  errors: IFormNotice[];
}

export interface IClearUserBundleData {
  type: CLEAR_USER_BUNDLE_DATA;
}

export interface IGetBundleSuccessAction {
  type: GET_BUNDLE_SUCCESS_ACTION;
  bundle: IBundle;
}

export interface IGetBundleFailureAction {
  type: GET_BUNDLE_FAILURE_ACTION;
  error: IServerError;
}

export interface IGetBundleConfigsSuccessAction {
  type: GET_BUNDLE_CONFIGS_SUCCESS_ACTION;
  bundles: IBundle[];
}

export interface IGetBundleConfigsFailureAction {
  type: GET_BUNDLE_CONFIGS_FAILURE_ACTION;
  error: IServerError;
}

export interface IAddBundleToCartAction {
  type: ADD_BUNDLE_TO_CART_ACTION;
  bundle: IBundle;
  group: IBundleGroup;
}

export interface IOrderBundleSuccessAction {
  type: ORDER_BUNDLE_SUCCESS_ACTION;
  bundle: IBundle;
  group: IBundleGroup;
}

export interface IOrderBundleFailureAction {
  type: ORDER_BUNDLE_FAILURE_ACTION;
  errors: IFormNotice[];
}

export interface IRedeemVoucherSuccessAction {
  type: REDEEM_VOUCHER_SUCCESS_ACTION;
  orderSuccessful: boolean;
}

export interface IVoucherSetErrors {
  type: VOUCHER_SET_ERRORS;
  errors: IFormNotice[];
}

export interface IRedeemVoucherFailureAction {
  type: REDEEM_VOUCHER_FAILURE_ACTION;
  errors: IFormNotice[];
  orderSuccessful: boolean;
}

export interface IInsuranceMemberIdInputAction {
  type: INSURANCE_MEMBER_ID_INPUT_ACTION;
  memberId: string;
}

export interface IInsuranceGroupNumberInputAction {
  type: INSURANCE_GROUP_NUMBER_INPUT_ACTION;
  groupNumber: string;
}

export interface IValidateInsuranceCardSuccessAction {
  type: VALIDATE_INSURANCE_CARD_SUCCESS_ACTION;
  insuranceCard: IInsuranceCard;
}

export interface IValidateInsuranceCardFailureAction {
  type: VALIDATE_INSURANCE_CARD_FAILURE_ACTION;
  errors: IFormNotice[];
}

export interface IClearCartAction {
  type: CLEAR_CART_ACTION;
}

export interface ISetInsuranceAction {
  type: SET_INSURANCE_ACTION;
  insurance?: IInsurance;
}

export interface ISetCartAction {
  type: SET_CART_ACTION;
  cart: ICartBundle[];
}

export type BundleAction =
  | ISetCartAction
  | IAddBundleToCartAction
  | IClearCartAction
  | IClearUserBundleData
  | ICustomVerificationCodeInputAction
  | IGetBundleConfigsFailureAction
  | IGetBundleConfigsSuccessAction
  | IGetBundleFailureAction
  | IGetBundleSuccessAction
  | IInsuranceGroupNumberInputAction
  | IInsuranceMemberIdInputAction
  | IOrderBundleFailureAction
  | IOrderBundleSuccessAction
  | ISetCustomVerificationCodeErrorAction
  | ISetInsuranceAction
  | IToggleConsentAction
  | IValidateInsuranceCardFailureAction
  | IValidateInsuranceCardSuccessAction
  | IValidateVoucherCodeSuccessAction;

export function setCartAction(cart: ICartBundle[]): ISetCartAction {
  return { type: SET_CART_ACTION, cart };
}

export function customCodeVerificationInputAction(
  code: string,
): ICustomVerificationCodeInputAction {
  return { type: CUSTOM_VERIFICATION_CODE_INPUT, code };
}

export function setCustomCodeVerificationErrorsAction(
  errors: IFormNotice[],
): ISetCustomVerificationCodeErrorAction {
  return { type: SET_CUSTOM_VERIFICATION_CODE_ERRORS, errors };
}

export function clearUserBundleDataAction(): IClearUserBundleData {
  return { type: CLEAR_USER_BUNDLE_DATA };
}

export function addBundleToCartSuccessAction(
  bundle: IBundle,
  group: IBundleGroup,
): IAddBundleToCartAction {
  return { type: ADD_BUNDLE_TO_CART_ACTION, bundle, group };
}

export function addBundleToCartAction(
  bundle: IBundle,
  group: IBundleGroup,
): (dispatch: any, getState: () => IStoreState) => Promise<any> {
  return (dispatch: any) => {
    dispatch(addBundleToCartSuccessAction(bundle, group));
    return Promise.resolve();
  };
}

export function clearCartAction(): IClearCartAction {
  return { type: CLEAR_CART_ACTION };
}

export function getBundleSuccessAction(
  bundle: IBundle,
): IGetBundleSuccessAction {
  return { type: GET_BUNDLE_SUCCESS_ACTION, bundle };
}

export function getBundleFailureAction(
  error: IServerError,
): IGetBundleFailureAction {
  return { type: GET_BUNDLE_FAILURE_ACTION, error };
}

export function getBundleConfigurationsSuccessAction(
  bundles: IBundle[],
): IGetBundleConfigsSuccessAction {
  return { type: GET_BUNDLE_CONFIGS_SUCCESS_ACTION, bundles };
}

export function getBundleConfigurationsFailureAction(
  error: IServerError,
): IGetBundleConfigsFailureAction {
  return { type: GET_BUNDLE_CONFIGS_FAILURE_ACTION, error };
}

export function getBundleAction(
  code: string,
): (dispatch: Dispatch) => Promise<any> {
  return (dispatch: Dispatch) => {
    dispatch(loadingBeginAction(`getBundle/${code}`));
    return BackendClient.getInstance()
      .getBundle(code)
      .then((bundle: IBundle) => {
        dispatch(getBundleSuccessAction(bundle));
        dispatch(loadingSuccessAction(`getBundle/${code}`));
        return Promise.resolve();
      })
      .catch((response: IErrorEndpoint) => {
        dispatch(getBundleFailureAction(response.error));
        dispatch(loadingFailureAction(`getBundle/${code}`));
        return Promise.resolve();
      });
  };
}

export function orderBundleSuccessAction(
  bundle: IBundle,
  group: IBundleGroup,
): IOrderBundleSuccessAction {
  return { type: ORDER_BUNDLE_SUCCESS_ACTION, bundle, group };
}

export function orderBundleFailureAction(
  errors: IFormNotice[],
): IOrderBundleFailureAction {
  return { type: ORDER_BUNDLE_FAILURE_ACTION, errors };
}

export function insuranceMemberIdInputAction(
  memberId: string,
): IInsuranceMemberIdInputAction {
  return { type: INSURANCE_MEMBER_ID_INPUT_ACTION, memberId };
}

export function insuranceGroupNumberInputAction(
  groupNumber: string,
): IInsuranceGroupNumberInputAction {
  return { type: 'INSURANCE_GROUP_NUMBER_INPUT_ACTION', groupNumber };
}

export function validateInsuranceCardSuccessAction(
  insuranceCard: IInsuranceCard,
): IValidateInsuranceCardSuccessAction {
  return { type: VALIDATE_INSURANCE_CARD_SUCCESS_ACTION, insuranceCard };
}

export function validateInsuranceCardFailureAction(
  errors: IFormNotice[],
): IValidateInsuranceCardFailureAction {
  return { type: VALIDATE_INSURANCE_CARD_FAILURE_ACTION, errors };
}

export function validateInsuranceCardAction(
  bundle: IBundle,
  insuranceCard: IInsuranceCard,
): (dispatch: any, getState: () => IStoreState) => Promise<IValidateResponse> {
  return async (dispatch: any) => {
    let errors: IFormNotice[] = [];
    dispatch(loadingBeginAction('validateInsuranceCardAction'));
    const inputProperties = [
      {
        key: PROPERTY_CARD_GROUP_ID,
        value: cleanString(insuranceCard.groupNumber),
      },
      {
        key: PROPERTY_CARD_MEMBER_ID,
        value: cleanString(insuranceCard.memberId),
      },
    ];

    const properties =
      await BackendClient.getInstance().validateBundleProperties(
        bundle,
        inputProperties,
      );

    const valid =
      properties.length === inputProperties.length &&
      properties.reduce(
        (preValue: boolean, property) => property.valid && preValue,
        true,
      );

    dispatch(loadingSuccessAction('validateInsuranceCardAction'));

    if (valid && insuranceCard) {
      dispatch(validateInsuranceCardSuccessAction(insuranceCard));
    } else {
      errors = properties
        .filter(property => property.valid === false)
        .map(
          (property): IFormNotice => ({
            field:
              property.key === PROPERTY_CARD_GROUP_ID
                ? 'groupNumber'
                : property.key,
            message: errorConstants.INVALID_INSURANCE_CARD_INFO,
          }),
        );
      dispatch(validateInsuranceCardFailureAction(errors));
    }
    return Promise.resolve({ valid, errors });
  };
}

export function setInsuranceAction(
  insurance?: IInsurance,
): ISetInsuranceAction {
  return { type: SET_INSURANCE_ACTION, insurance };
}
