/* istanbul ignore file */

export const PARAM_BUNDLE_REQUIRES_BIRTHDATE = 'bundle.requires_birthdate';

export const PARAM_BUNDLE_LANGUAGE = 'email.order.bundle.supported.locales';
export type PARAM_BUNDLE_LANGUAGE = typeof PARAM_BUNDLE_LANGUAGE;

export const PARAM_BUNDLE_MIN_SUBSCRIPTION_AGE = 'bundle.subscribe.notice.age';
export type PARAM_BUNDLE_MIN_SUBSCRIPTION_AGE =
  typeof PARAM_BUNDLE_MIN_SUBSCRIPTION_AGE;

export const PARAM_BUNDLE_LOGO_URL = 'bundle.logo.url';
export type PARAM_BUNDLE_LOGO_URL = typeof PARAM_BUNDLE_LOGO_URL;

export const PARAM_BUNDLE_MARKETING_CONSENT = 'bundle.consent.marketing';
export type PARAM_BUNDLE_MARKETING_CONSENT =
  typeof PARAM_BUNDLE_MARKETING_CONSENT;

export const PARAM_BUNDLE_SUPPORT_EMAIL = 'bundle.support.email';
export type PARAM_BUNDLE_SUPPORT_EMAIL = typeof PARAM_BUNDLE_SUPPORT_EMAIL;

export const PARAM_BUNDLE_SUPPORT_PHONE = 'bundle.support.phone';
export type PARAM_BUNDLE_SUPPORT_PHONE = typeof PARAM_BUNDLE_SUPPORT_PHONE;

export const PARAM_BUNDLE_SLUG = 'bundle.slug';
export type PARAM_BUNDLE_SLUG = typeof PARAM_BUNDLE_SLUG;

export type PARAMETER_KEY =
  | PARAM_BUNDLE_SLUG
  | typeof PARAM_BUNDLE_REQUIRES_BIRTHDATE
  | PARAM_BUNDLE_MIN_SUBSCRIPTION_AGE
  | PARAM_BUNDLE_LANGUAGE
  | PARAM_BUNDLE_LOGO_URL
  | PARAM_BUNDLE_SLUG
  | PARAM_BUNDLE_MARKETING_CONSENT
  | PARAM_BUNDLE_SUPPORT_EMAIL
  | PARAM_BUNDLE_SUPPORT_PHONE;
