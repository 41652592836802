import { Dispatch } from 'redux';
import { clearUserBundleDataAction } from 'src/actions/bundleActions';
import { clearInsuranceNumberDataAction } from 'src/actions/insuranceNumberActions';
import { clearShipmentDataAction } from 'src/actions/shipmentActions';
import { clearVoucherDataAction } from 'src/actions/voucherActions';

export function clearDataFlow() {
  return async (dispatch: Dispatch) => {
    dispatch(clearUserBundleDataAction());
    dispatch(clearInsuranceNumberDataAction());
    dispatch(clearShipmentDataAction());
    dispatch(clearVoucherDataAction());
  };
}
