import * as router from '@gatsbyjs/reach-router';
import IEnvironment from 'src/types/IEnvironment';

const env = process.env as any as IEnvironment;

/**
 * Ensures that the given string has no trailing slash.
 * If the string has a trailing slash, it is removed.
 *
 * @param value String (path or URL or something similar)
 */
function ensureNoTrailingSlash(value: string): string {
  return value.endsWith('/') && value !== '' ? value.slice(0, -1) : value;
}

export function getUrlForNavigate(path: string): string {
  const base = ensureNoTrailingSlash(env.PUBLIC_URL);
  return path.startsWith('/') && base.length > 0 ? `${base}${path}` : path;
}

/**
 * Wrapper for `navigate()` from @gatsbyjs/reach-router that preprends the
 * PUBLIC_PATH (environment variable) if one is configured.
 *
 * @param path Absolute or relative path to navigate to
 */
function navigate(path: string): Promise<void> {
  return router.navigate(getUrlForNavigate(path));
}

export default navigate;
