import { PARAM_BUNDLE_SLUG } from 'src/constants/bundleParameters';
import { IStepRouteOptions } from 'src/constants/stepRoutes';
import getLocaleFromPathname from 'src/lib/getLocaleFromPathname';
import getNonBundleFlowNameFromPathname from 'src/lib/getNonBundleFlowNameFromPathname';
import getPayorForPathname from 'src/lib/getPayorForPathname';
import arrangeBundleGroupsByType from './arrangeBundleGroupsByType';
import getBundleParameter from './getBundleParameter';

export function getStartPagePath(path: string) {
  const [language, country] = getLocaleFromPathname(path);
  const payor = getPayorForPathname(path);
  const nonBundleFlowCode = getNonBundleFlowNameFromPathname(path);
  const withVoucher = path.indexOf('voucher') === 4;
  const withEligibility = path.indexOf('eligibility') === 4;

  if (withVoucher && language) {
    return `/${language}/voucher`;
  } else if (withEligibility && language) {
    return `/${language}/eligibility`;
  } else if (payor && language && country) {
    return `/${language}-${country}/payor/${payor}`;
  } else if (nonBundleFlowCode) {
    return `/${language}-${country}/flow/${nonBundleFlowCode}`;
  } else if (language && country) {
    return `/${language}-${country}`;
  } else if (language) {
    return `/${language}`;
  }

  return '/';
}

export function getPathBase(
  {
    country,
    language,
    bundle,
    voucher,
    nonBundleFlowConfig,
    bundleGroup,
  }: IStepRouteOptions,
  path = '',
) {
  // pro voucher
  if (voucher?.types.pro) {
    return `/${language}/voucher/pro${path}`;
  }

  // voucher
  if (/\/voucher/.exec(path)) {
    return `/${language}${path}`;
  }

  // non bundle flow
  if (nonBundleFlowConfig) {
    return `/${language}-${country}/flow/${nonBundleFlowConfig.CODE}${path}`;
  }

  // bundle group
  const hasGroupSelection = Boolean(
    bundle && arrangeBundleGroupsByType(bundle.groups).length === 2,
  );
  const bundleGroupCode = bundleGroup ? `/group/${bundleGroup.code}` : '';

  // payor bundle
  const slug = getBundleParameter(PARAM_BUNDLE_SLUG, bundle);
  if (slug) {
    return `/${language}-${country}/payor/${slug}${
      hasGroupSelection ? bundleGroupCode : ''
    }${path}`;
  }

  // country default
  return `/${language}-${country}${
    hasGroupSelection ? bundleGroupCode : ''
  }${path}`;
}
