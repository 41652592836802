/* istanbul ignore file */

import {
  COUNTRY_DE,
  COUNTRY_ES,
  COUNTRY_NL,
  COUNTRY_US,
  COUNTRY_ZA,
} from './countries';

const formatters = {
  [COUNTRY_DE]: 'de-DE',
  [COUNTRY_US]: 'en-US',
  [COUNTRY_NL]: 'nl-NL',
  [COUNTRY_ES]: 'es-ES',
  [COUNTRY_ZA]: 'en-ZA',
};

export default formatters;
