import {
  ADD_BUNDLE_TO_CART_ACTION,
  BundleAction,
  CLEAR_CART_ACTION,
  CLEAR_USER_BUNDLE_DATA,
  CUSTOM_VERIFICATION_CODE_INPUT,
  GET_BUNDLE_CONFIGS_FAILURE_ACTION,
  GET_BUNDLE_CONFIGS_SUCCESS_ACTION,
  GET_BUNDLE_FAILURE_ACTION,
  GET_BUNDLE_SUCCESS_ACTION,
  IAddBundleToCartAction,
  ICustomVerificationCodeInputAction,
  IGetBundleConfigsFailureAction,
  IGetBundleConfigsSuccessAction,
  IGetBundleFailureAction,
  IGetBundleSuccessAction,
  IInsuranceGroupNumberInputAction,
  IInsuranceMemberIdInputAction,
  INSURANCE_GROUP_NUMBER_INPUT_ACTION,
  INSURANCE_MEMBER_ID_INPUT_ACTION,
  IOrderBundleFailureAction,
  ISetCustomVerificationCodeErrorAction,
  ISetInsuranceAction,
  IValidateInsuranceCardFailureAction,
  ORDER_BUNDLE_FAILURE_ACTION,
  ORDER_BUNDLE_SUCCESS_ACTION,
  SET_CART_ACTION,
  SET_CUSTOM_VERIFICATION_CODE_ERRORS,
  SET_INSURANCE_ACTION,
  VALIDATE_INSURANCE_CARD_FAILURE_ACTION,
  VALIDATE_INSURANCE_CARD_SUCCESS_ACTION,
} from 'src/actions/bundleActions';
import {
  IToggleConsentAction,
  TOGGLE_CONSENT_ACTION,
} from 'src/actions/registerActions';
import { VALIDATE_VOUCHER_CODE_SUCCESS_ACTION } from 'src/actions/voucherActions';
import IBundleState, {
  IBundleCustomCodeVerification,
} from 'src/types/IBundleState';
import IFormNotice from 'src/types/IFormNotice';

export const initialState: IBundleState = {
  cart: [],
  errors: [],
  orderSuccessful: false,
};

function handleGetBundleSuccess(
  state: IBundleState,
  action: IGetBundleSuccessAction,
): IBundleState {
  const bundles = state.bundles ?? [];
  bundles.push(action.bundle);
  return { ...state, bundles, error: undefined, insurance: undefined };
}

function handleGetBundleFailure(
  state: IBundleState,
  action: IGetBundleFailureAction,
) {
  return { ...state, error: action.error };
}

function handleGetBundleConfigsSuccess(
  state: IBundleState,
  action: IGetBundleConfigsSuccessAction,
): IBundleState {
  return { ...state, bundles: action.bundles, error: undefined };
}

function handleGetBundleConfigsFailure(
  state: IBundleState,
  action: IGetBundleConfigsFailureAction,
): IBundleState {
  return { ...state, bundles: [], error: action.error };
}

function handleAddBundleToCart(
  state: IBundleState,
  action: IAddBundleToCartAction,
): IBundleState {
  const cart = [{ bundle: action.bundle.code, group: action.group.code }];
  return { ...state, cart };
}

function handleClearCart(state: IBundleState): IBundleState {
  return { ...state, cart: [] };
}

function handleOrderBundleSuccess(state: IBundleState): IBundleState {
  return {
    ...state,
    error: undefined,
    errors: [],
    orderSuccessful: true,
  };
}

function handleOrderBundleFailure(
  state: IBundleState,
  action: IOrderBundleFailureAction,
): IBundleState {
  return { ...state, errors: action.errors, orderSuccessful: false };
}

function handleToggleConsent(
  state: IBundleState,
  action: IToggleConsentAction,
) {
  const errors = state.errors.filter(
    error =>
      error.field !== 'consents' ||
      !error.options ||
      !action.consents.includes(error.options.type),
  );
  return { ...state, errors: [...errors] };
}

function handleInsuranceMemberIdInput(
  state: IBundleState,
  action: IInsuranceMemberIdInputAction,
): IBundleState {
  const insuranceCard = state.insuranceCard ?? {
    groupNumber: '',
    memberId: '',
  };
  const errors = (state.insuranceCardErrors ?? []).filter(
    (error: IFormNotice) => error.field !== 'memberId',
  );
  return {
    ...state,
    insuranceCard: { ...insuranceCard, memberId: action.memberId },
    insuranceCardErrors: [...errors],
  };
}

function handleInsuranceGroupNumberInput(
  state: IBundleState,
  action: IInsuranceGroupNumberInputAction,
): IBundleState {
  const insuranceCard = state.insuranceCard ?? {
    groupNumber: '',
    memberId: '',
  };
  const errors = (state.insuranceCardErrors ?? []).filter(
    (error: IFormNotice) => error.field !== 'groupNumber',
  );
  return {
    ...state,
    insuranceCard: { ...insuranceCard, groupNumber: action.groupNumber },
    insuranceCardErrors: [...errors],
  };
}

function handleValidateInsuranceCardSuccess(state: IBundleState): IBundleState {
  return { ...state, insuranceCardErrors: [] };
}

function handleValidateInsuranceCardFailure(
  state: IBundleState,
  action: IValidateInsuranceCardFailureAction,
): IBundleState {
  return { ...state, insuranceCardErrors: action.errors };
}

function handleValidateVoucherSuccess(state: IBundleState): IBundleState {
  return { ...state, cart: [] };
}

function handleClearUserBundleData(state: IBundleState): IBundleState {
  return {
    ...state,
    customCodeVerification: undefined,
    insurance: undefined,
    insuranceCard: undefined,
    insuranceCardErrors: [],
    orderSuccessful: false,
  };
}

function handleCustomCodeVerificationInput(
  state: IBundleState,
  action: ICustomVerificationCodeInputAction,
): IBundleState {
  const customCodeVerification: IBundleCustomCodeVerification = {
    ...(state.customCodeVerification ?? {}),
    code: action.code,
    errors: [],
  };

  return { ...state, customCodeVerification };
}

function handleCustomCodeVerificationError(
  state: IBundleState,
  action: ISetCustomVerificationCodeErrorAction,
): IBundleState {
  const customCodeVerification: IBundleCustomCodeVerification = {
    ...(state.customCodeVerification ?? { code: '' }),
    errors: action.errors,
  };
  return { ...state, customCodeVerification };
}

function handleSetInsurance(
  state: IBundleState,
  action: ISetInsuranceAction,
): IBundleState {
  const insurance = action.insurance;
  return { ...state, insurance };
}

function bundleReducer(
  state: IBundleState = initialState,
  action: BundleAction | { type: null } = { type: null },
) {
  switch (action.type) {
    case GET_BUNDLE_SUCCESS_ACTION:
      return handleGetBundleSuccess(state, action);

    case GET_BUNDLE_FAILURE_ACTION:
      return handleGetBundleFailure(state, action);

    case GET_BUNDLE_CONFIGS_SUCCESS_ACTION:
      return handleGetBundleConfigsSuccess(state, action);

    case GET_BUNDLE_CONFIGS_FAILURE_ACTION:
      return handleGetBundleConfigsFailure(state, action);

    case ADD_BUNDLE_TO_CART_ACTION:
      return handleAddBundleToCart(state, action);

    case ORDER_BUNDLE_SUCCESS_ACTION:
      return handleOrderBundleSuccess(state);

    case ORDER_BUNDLE_FAILURE_ACTION:
      return handleOrderBundleFailure(state, action);

    case TOGGLE_CONSENT_ACTION:
      return handleToggleConsent(state, action);

    case INSURANCE_MEMBER_ID_INPUT_ACTION:
      return handleInsuranceMemberIdInput(state, action);

    case INSURANCE_GROUP_NUMBER_INPUT_ACTION:
      return handleInsuranceGroupNumberInput(state, action);

    case VALIDATE_INSURANCE_CARD_SUCCESS_ACTION:
      return handleValidateInsuranceCardSuccess(state);

    case VALIDATE_INSURANCE_CARD_FAILURE_ACTION:
      return handleValidateInsuranceCardFailure(state, action);

    case CLEAR_CART_ACTION:
      return handleClearCart(state);

    case VALIDATE_VOUCHER_CODE_SUCCESS_ACTION:
      return handleValidateVoucherSuccess(state);

    case CLEAR_USER_BUNDLE_DATA:
      return handleClearUserBundleData(state);

    case CUSTOM_VERIFICATION_CODE_INPUT:
      return handleCustomCodeVerificationInput(state, action);

    case SET_CUSTOM_VERIFICATION_CODE_ERRORS:
      return handleCustomCodeVerificationError(state, action);

    case SET_INSURANCE_ACTION:
      return handleSetInsurance(state, action);

    case SET_CART_ACTION:
      return { ...state, cart: action.cart };
  }

  return state;
}

export default bundleReducer;
