import { HistoryLocation } from '@gatsbyjs/reach-router';
import * as React from 'react';
import { useEffect } from 'react';
import browserOnly from 'src/lib/browserOnly';

interface IProps {
  children?: React.ReactElement;
  location: HistoryLocation;
}

function ScrollToTop({ children, location: { pathname } }: IProps) {
  useEffect(() => {
    // Request from the browser to scroll before next repaint
    browserOnly(() => {
      requestAnimationFrame(() => {
        window.scrollTo(0, 0);
      });
    });
  }, [pathname]);

  return children ?? null;
}

export default ScrollToTop;
