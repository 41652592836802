const cleanString = (input?: string) => {
  let out = `${input ?? ''}`;

  out = out.replace(/-/g, '');
  out = out.replace(/ /g, '');

  return out;
};

export default cleanString;
