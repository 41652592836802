import { Location, LocationContext } from '@gatsbyjs/reach-router';
import * as React from 'react';

function withLocation<TWrappedComponentProps extends LocationContext>(
  WrappedComponent: React.ComponentType<TWrappedComponentProps>,
) {
  type WrappedComponentPropsExceptProvided = Exclude<
    keyof TWrappedComponentProps,
    keyof LocationContext
  >;
  type ForwardedProps = Pick<
    TWrappedComponentProps,
    WrappedComponentPropsExceptProvided
  >;
  const comp = (props: ForwardedProps) => (
    <Location>
      {({ location, navigate }) => (
        <WrappedComponent
          {...(props as any)}
          location={location}
          navigate={navigate}
        />
      )}
    </Location>
  );
  comp.displayName = `withLocation(${
    WrappedComponent.displayName ?? WrappedComponent.name
  })`;
  return comp;
}

export default withLocation;
