import * as React from 'react';
import browserOnly from 'src/lib/browserOnly';
import Translate from '../Translate/Translate';
import svgImage from './offline.svg';
import css from './OfflineScreen.module.scss';

browserOnly(() => {
  const preloadSvg = new Image();
  preloadSvg.src = svgImage;
  preloadSvg.style.display = 'none';
  document.documentElement.appendChild(preloadSvg);
});

export default function OfflineScreen(): React.ReactElement {
  return (
    <div className={css.offline}>
      <img className={css.image} src={svgImage} alt="" />
      <h1 className={css.title}>
        <Translate t="offlinePage.title" />
      </h1>
      <h2 className={css.subtitle}>
        <Translate t="offlinePage.subtitle" />
      </h2>
    </div>
  );
}
