import { createSelector } from 'reselect';
import IAuthUser from 'src/types/IAuthUser';
import IStoreState from 'src/types/IStoreState';

export function getUser(state: IStoreState): IAuthUser | undefined {
  return state.login.user;
}

export function isAppUser(state: IStoreState): boolean {
  return state.login.appUser || false;
}

export const isAuthenticated = createSelector(
  [getUser],
  (user?: IAuthUser) => user?.authenticationToken !== undefined,
);
