import { createSelector } from 'reselect';
import { countryDefaultBundleCode } from 'src/config/nonStandardBundleOverride/bundleOverrides';
import { INonBundleFlowStep } from 'src/config/REMOTE/NON_BUNDLE_FLOWS/nonBundleSteps';
import { PARAM_BUNDLE_SLUG } from 'src/constants/bundleParameters';
import arrangeBundleGroupsByType from 'src/lib/arrangeBundleGroupsByType';
import getBundleGroupFromPathname from 'src/lib/getBundleGroupFromPathname';
import getBundleParameter from 'src/lib/getBundleParameter';
import getLocaleFromPathname from 'src/lib/getLocaleFromPathname';
import getPayorForPathname from 'src/lib/getPayorForPathname';
import getPathname from 'src/server/lib/getPathname';
import IBundle from 'src/types/IBundle';
import IBundleGroup from 'src/types/IBundleGroup';
import IBundleGroupItem, {
  BundleGroupItemType,
} from 'src/types/IBundleGroupItem';
import ICartBundle from 'src/types/ICartBundle';
import INonBundleFlowConfig from 'src/types/INonBundleFlowConfig';
import IStoreState from 'src/types/IStoreState';

export function getBundles(state: IStoreState): IBundle[] {
  return state.bundle.bundles ?? [];
}

export function getCart(state: IStoreState): ICartBundle[] {
  return state.bundle.cart;
}

export function getNonBundleFlowConfig(
  state: IStoreState,
): undefined | INonBundleFlowConfig {
  return state.flow.nonBundleFlowConfig;
}

export function findGroupByGroupCode(
  bundles: IBundle[],
  bundleGroupCode?: string,
): IBundle | undefined {
  if (bundleGroupCode) {
    return bundles.find(bundle => {
      for (const group of bundle.groups) {
        if (group.code === bundleGroupCode) {
          return true;
        }
      }
      return false;
    });
  }

  return undefined;
}

/**
 * Find the fitting bundle for given bundleConfig and country from the list of bundles
 * that we get from the backend
 * @param bundles
 * @param country
 * @param pathname
 */
export function findSelectedBundle(
  bundles: IBundle[],
  pathname: string,
): IBundle | undefined {
  const [, country] = getLocaleFromPathname(pathname);
  if (bundles.length === 0) {
    return undefined;
  }

  const bundleGroupCode = getBundleGroupFromPathname(pathname);

  const bundleFromGroup = findGroupByGroupCode(bundles, bundleGroupCode);
  if (bundleGroupCode) {
    return bundleFromGroup;
  }

  const payorSlug = getPayorForPathname(pathname) ?? '';

  const selectedBundle = bundles.find(bundle => {
    if (payorSlug) {
      return (
        getBundleParameter(PARAM_BUNDLE_SLUG, bundle).toLowerCase() ===
        payorSlug.toLowerCase()
      );
    }
    return country && countryDefaultBundleCode[country] === bundle.code;
  });

  /* istanbul ignore else */
  if (selectedBundle) {
    return selectedBundle;
  }

  return undefined;
}

/**
 * Get the first the bundle that is currently in the cart
 */
export const getCartBundle = createSelector(
  [getCart, getBundles],
  (cart: ICartBundle[], bundles: IBundle[]): IBundle | undefined =>
    cart.length > 0
      ? bundles.find(bundle => bundle.code === cart[0].bundle)
      : undefined,
);

export const getSelectedBundle = createSelector(
  [getBundles, getPathname],
  findSelectedBundle,
);

export const getCartBundleGroup = createSelector(
  [getCart, getCartBundle],
  (cart: ICartBundle[], cartBundle): IBundleGroup | undefined => {
    if (!cartBundle) {
      return;
    }

    for (const group of cartBundle.groups) {
      if (group.code === cart[0].group) {
        return group;
      }
    }

    return undefined;
  },
);

export const getSelectedBundleGroup = createSelector(
  [getPathname, getSelectedBundle, getCartBundleGroup],
  (pathname, bundle, cartBundleGroup) => {
    const bundleGroupCode = getBundleGroupFromPathname(pathname);

    if (!bundle || bundle.groups.length === 0) {
      return undefined;
    }

    if (bundleGroupCode) {
      return bundle.groups.find(group => group.code === bundleGroupCode);
    }

    const hasGroupSelection = Boolean(
      bundle && arrangeBundleGroupsByType(bundle.groups).length === 2,
    );

    if (!hasGroupSelection) {
      return cartBundleGroup ?? bundle.groups[0];
    }

    return undefined;
  },
);

export const getBundleGroupDynamic = createSelector(
  [getSelectedBundleGroup, getCartBundleGroup],
  (selected, cart) => cart ?? selected,
);

export const getBundleDynamic = createSelector(
  [getSelectedBundle, getCartBundle],
  (selected, cart) => cart ?? selected,
);

/**
 * Get the first the group that is currently in the cart
 */
export const getNonBundleFlowSteps = createSelector(
  [getNonBundleFlowConfig],
  (
    nonBundleFlowConfig?: INonBundleFlowConfig,
  ): INonBundleFlowStep[] | undefined => nonBundleFlowConfig?.STEPS,
);

export function getBundle(code: string) {
  return createSelector(
    [getBundles],
    (bundles: IBundle[]): IBundle | undefined =>
      bundles.find(
        bundle => getBundleParameter(PARAM_BUNDLE_SLUG, bundle) === code,
      ),
  );
}

export function getCartItemsByType(
  type: BundleGroupItemType,
  exclude: string[],
) {
  return createSelector(
    [getBundleGroupDynamic],
    (group?: IBundleGroup): IBundleGroupItem[] =>
      group
        ? group.items
            .filter(item => item.type === type)
            .filter(item => !exclude.includes(item.code))
        : [],
  );
}

export function getCartItemsByCode(include: string[]) {
  return createSelector(
    [getBundleGroupDynamic],
    (group?: IBundleGroup): IBundleGroupItem[] =>
      group ? group.items.filter(item => include.includes(item.code)) : [],
  );
}

export const getCartProductItems = getCartItemsByType(
  BundleGroupItemType.product,
  [],
);

export const getCartSubscriptionItems = getCartItemsByType(
  BundleGroupItemType.sub,
  ['mysugr_pro_1', 'mysugr_pro_2', 'mysugr_pro_3', 'mysugr_pro_4'],
);

export const getCartImmediateItems = getCartItemsByCode([
  'mysugr_pro_1',
  'mysugr_pro_2',
  'mysugr_pro_3',
  'mysugr_pro_4',
]);
