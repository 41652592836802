/* istanbul ignore file */

export const COUNTRY_US = 'us';
export type COUNTRY_US = typeof COUNTRY_US;

export const COUNTRY_NL = 'nl';
export type COUNTRY_NL = typeof COUNTRY_NL;

export const COUNTRY_DE = 'de';
export type COUNTRY_DE = typeof COUNTRY_DE;

export const COUNTRY_AT = 'at';
export type COUNTRY_AT = typeof COUNTRY_AT;

export const COUNTRY_PL = 'pl';
export type COUNTRY_PL = typeof COUNTRY_PL;

export const COUNTRY_ES = 'es';
export type COUNTRY_ES = typeof COUNTRY_ES;

export const COUNTRY_ZA = 'za';
export type COUNTRY_ZA = typeof COUNTRY_ZA;

export const COUNTRY_FR = 'fr';
export type COUNTRY_FR = typeof COUNTRY_FR;

export const COUNTRY_FALLBACK = COUNTRY_US;

export type SUPPORTED_COUNTRY =
  | COUNTRY_NL
  | COUNTRY_DE
  | COUNTRY_US
  | COUNTRY_AT
  | COUNTRY_PL
  | COUNTRY_ES
  | COUNTRY_ZA
  | COUNTRY_FR
  | string;

export const SUPPORTED_COUNTRIES = [
  COUNTRY_NL,
  COUNTRY_US,
  COUNTRY_DE,
  COUNTRY_PL,
  COUNTRY_ES,
  COUNTRY_ZA,
  COUNTRY_FR,
];
