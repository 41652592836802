export const LOADING_BEGIN_ACTION = 'LOADING_BEGIN_ACTION';
export type LOADING_BEGIN_ACTION = typeof LOADING_BEGIN_ACTION;

export const LOADING_SUCCESS_ACTION = 'LOADING_SUCCESS_ACTION';
export type LOADING_SUCCESS_ACTION = typeof LOADING_SUCCESS_ACTION;

export const LOADING_FAILURE_ACTION = 'LOADING_FAILURE_ACTION';
export type LOADING_FAILURE_ACTION = typeof LOADING_FAILURE_ACTION;

export const LOADING_CLEAN_ACTION = 'LOADING_CLEAN_ACTION';
export type LOADING_CLEAN_ACTION = typeof LOADING_CLEAN_ACTION;

export interface ILoadingBeginAction {
  type: LOADING_BEGIN_ACTION;
  resource: string;
}

export interface ILoadingSuccessAction {
  type: LOADING_SUCCESS_ACTION;
  resource: string;
}

export interface ILoadingFailureAction {
  type: LOADING_FAILURE_ACTION;
  resource: string;
}

export interface ILoadingCleanAction {
  type: LOADING_CLEAN_ACTION;
}

export type LoadingAction =
  | ILoadingBeginAction
  | ILoadingCleanAction
  | ILoadingSuccessAction
  | ILoadingFailureAction;

export function loadingBeginAction(resource: string): ILoadingBeginAction {
  return { type: LOADING_BEGIN_ACTION, resource };
}

export function loadingSuccessAction(resource: string): ILoadingSuccessAction {
  return { type: LOADING_SUCCESS_ACTION, resource };
}

export function loadingFailureAction(resource: string): ILoadingFailureAction {
  return { type: LOADING_FAILURE_ACTION, resource };
}

export function loadingCleanAction(): ILoadingCleanAction {
  return { type: LOADING_CLEAN_ACTION };
}
