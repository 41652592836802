import cn from 'classnames';
import * as React from 'react';
import { SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { deleteBundleConfigAction } from 'src/actions/flowActions';
import Button from 'src/components/Button/Button';
import formCss from 'src/components/Form/Form.module.scss';
import Image from 'src/components/Image/Image';
import ProductPageTitle from 'src/components/ProductPageTitle/ProductPageTitle';
import Translate from 'src/components/Translate/Translate';
import { IMAGE_PRO_ONLY } from 'src/images/product-image/productImages';
import getLocaleFromPathname from 'src/lib/getLocaleFromPathname';
import { IBundleImageSet } from 'src/lib/loadBundleImageSet';
import navigate from 'src/lib/navigate';
import getPathname from 'src/server/lib/getPathname';
import IStoreState from 'src/types/IStoreState';
import css from './HomePage.module.scss';

export interface IStateProps {
  language?: string;
}

interface IDispatchProps {
  navigateTo: (pathname: string) => Promise<any>;
  deleteBundleConfig: () => Promise<any>;
}

export interface IProps extends IStateProps, IDispatchProps {}

export function HomePage({ language, navigateTo, deleteBundleConfig }: IProps) {
  React.useEffect(() => {
    deleteBundleConfig();
  }, []);

  const navigateToPro = async (event: SyntheticEvent) => {
    event.preventDefault();
    await navigateTo(`/${language}/voucher`);
  };

  const renderImage = (source: IBundleImageSet) => (
    <Image
      aria-hidden={true}
      className={css.image}
      src={source.box1x}
      srcSet={{
        '1x': source.box1x,
        '2x': source.box2x,
        '3x': source.box3x,
      }}
      webpSrc={{
        src: source.box1xWebp,
        srcSet: {
          '1x': source.box1xWebp,
          '2x': source.box2xWebp,
          '3x': source.box3xWebp,
        },
      }}
    />
  );

  return (
    <main role="main" className={cn(css.main, css.gray)}>
      <div className={css.gray}>
        <h1 className={css.title}>
          <Translate t="homepage.text" />
        </h1>
      </div>

      <section className={cn(css.section, css.pro)}>
        <div className={css.visual}>{renderImage(IMAGE_PRO_ONLY)}</div>

        <div className={css.text}>
          <ProductPageTitle>
            <Translate t="homepage.pro.title" />
          </ProductPageTitle>
          <p>
            <Translate html={true} t="homepage.pro.text" />
          </p>

          <Button
            className={cn(formCss.ctaButton, css.homePageButton)}
            large={true}
            secondary={true}
            onClick={navigateToPro}
          >
            <Translate t="cta.redeemVoucher" />
          </Button>
        </div>
      </section>
    </main>
  );
}

export function mapStateToProps(state: IStoreState): IStateProps {
  const [language] = getLocaleFromPathname(getPathname(state));
  return { language };
}

export function mapDispatchToProps(dispatch: any): IDispatchProps {
  return {
    navigateTo: pathname => navigate(pathname),
    deleteBundleConfig: () => dispatch(deleteBundleConfigAction()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
