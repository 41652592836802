/* istanbul ignore file */

module.exports = {
  "account.account.backButton": "Zurück",
  "account.account.continueButton": "Weiter",
  "account.account.loggedInAs": "Du bist eingeloggt als :email",
  "account.account.logoutButton": "Ausloggen",
  "account.account.logoutText": "Nicht du? Du kannst dich abmelden und mit einem anderen Konto anmelden oder ein neues Konto erstellen.",
  "account.emailConfirmForm.backButton": "Zurück",
  "account.emailConfirmForm.changeEmail": "E-Mail-Adresse ändern",
  "account.emailConfirmForm.checkYourEmail": "Überprüfe deine E-Mails",
  "account.emailConfirmForm.codeLabel": "Bitte gib deinen Bestätigungscode ein",
  "account.emailConfirmForm.codeName": "Bestätigungscode",
  "account.emailConfirmForm.codeWrong": "Code falsch oder abgelaufen",
  "account.emailConfirmForm.continueButton": "Weiter",
  "account.emailConfirmForm.emailLabel": "E-Mail",
  "account.emailConfirmForm.receivedNoCode": "Bestätigungscode nicht erhalten?",
  "account.emailConfirmForm.sendCodeAgain": "E-Mail noch einmal senden",
  "account.emailConfirmForm.sendCodeSuccess": "Wir haben deinen Bestätigungscode an die E-Mail-Adresse :email gesendet.",
  "account.emailConfirmForm.sentCode": "Wir haben dir einen Code gesendet",
  "account.emailForm.backButton": "Zurück",
  "account.emailForm.continueButton": "Weiter",
  "account.emailForm.emailLabel": "E-Mail",
  "account.emailForm.emailName": "E-Mail",
  "account.emailForm.emailPlaceholder": "email@example.com",
  "account.emailForm.intro": "Wenn du <strong>bereits einen mySugr Account hast</strong>, gib dieselbe E-Mail-Adresse ein, damit wir dein Paket deinem bestehenden Account zuordnen können. Du findest deine E-Mail-Adresse im Profil in der mySugr App.",
  "account.emailForm.proVoucherIntro": "Wenn du <strong>bereits einen mySugr Account hast</strong>, gib dieselbe E-Mail-Adresse ein, damit wir deinen Gutschein deinem bestehenden Account zuordnen können. Du findest deine E-Mail-Adresse im Profil in der mySugr App.",
  "account.loginForm.backButton": "Zurück",
  "account.loginForm.continueButton": "Weiter",
  "account.loginForm.emailLabel": "Deine E-Mail-Adresse",
  "account.loginForm.emailName": "E-Mail",
  "account.loginForm.forgotPassword": "Passwort vergessen?",
  "account.loginForm.passwordLabel": "Passwort eingeben",
  "account.loginForm.passwordName": "Passwort",
  "account.loginForm.passwordResetRequestedText": "Du hast ein neues Passwort angefordert. Wir haben dir eine E-Mail mit Anleitung zu den weiteren Schritten gesendet.",
  "account.loginForm.redeemButton": "Einlösen",
  "account.loginTitle": "Login",
  "account.loginTitleRedeem": "Melde dich an, um deinen Gutschein einzulösen",
  "account.registerForm.backButton": "Zurück",
  "account.registerForm.consentsName": "Einwilligung",
  "account.registerForm.continueButton": "Weiter",
  "account.registerForm.countryLabel": "Wähle dein Heimatland",
  "account.registerForm.emailLabel": "Deine E-Mail-Adresse",
  "account.registerForm.emailName": "E-Mail",
  "account.registerForm.passwordConfirmLabel": "Passwort wiederholen",
  "account.registerForm.passwordConfirmName": "Passwort wiederholen",
  "account.registerForm.passwordHint": "Mindestens 6 Zeichen",
  "account.registerForm.passwordHintLength": "Mindestens :minLength Zeichen",
  "account.registerForm.passwordLabel": "Wähle ein Passwort",
  "account.registerForm.passwordName": "Wähle ein Passwort",
  "account.registerForm.redeemButton": "Einlösen",
  "account.registerForm.verifiedEmail": "Bestätigt",
  "account.registerTitle": "Registrierung",
  "account.registerTitleRedeem": "Erstelle einen Account, um deinen Gutschein einzulösen",
  "account.resetPassword.checkYourEmail": "Du hast ein neues Passwort angefordert.",
  "account.resetPassword.passwordConfirmLabel": "Neues Passwort bestätigen",
  "account.resetPassword.passwordConfirmName": "Neues Passwort bestätigen",
  "account.resetPassword.passwordHint": "Mindestens 6 Zeichen",
  "account.resetPassword.passwordHintLength": "Mindestens :minLength Zeichen",
  "account.resetPassword.passwordLabel": "Neues Passwort eingeben",
  "account.resetPassword.passwordName": "Neues Passwort eingeben",
  "account.resetPassword.sentCode": "Wir haben dir per E-Mail einen Code geschickt",
  "account.resetPasswordCodeVerification.backButton": "Zurück",
  "account.resetPasswordCodeVerification.continueButton": "Code verifizieren",
  "account.resetPasswordForm.backButton": "Zurück",
  "account.resetPasswordForm.saveButton": "Passwort speichern",
  "account.resetPasswordFormText": "Bitte wähle ein neues Passwort für deinen mySugr Account aus",
  "account.resetPasswordTitle": "Passwort zurücksetzen",
  "account.stepName": "Benutzerkonto",
  "account.title": "Registrierung / Login",
  "AccuChekRegistration_description": "Verwende dein Accu-Chek Konto für mySugr. <strong><a href=\"https://www.mysugr.com/en/mysugr-and-accu-chek/\" target=\"_blank\" rel=\"noopener\">Mehr erfahren</a></strong>",
  "AccuChekRegistration_or": "oder",
  "activateProTitle": "mySugr PRO freischalten",
  "appLegalNotice": "Die mySugr App ist Teil des Pakets. Um alle Leistungen des Pakets in Anspruch zu nehmen, muss die App benutzt werden.",
  "appProvider.title": "App-Provider",
  "birthdayLabel": "Geburtsdatum",
  "bundle.item.alt_text.strips_subscription_guide.description": "basierend auf den importierten Messungen (keine Lanzetten enthalten).",
  "bundle.item.alt_text.strips_subscription_guide.name.2": "",
  "bundle.item.coach.description": "Zugang zu deinem persönlichen Coach über die App",
  "bundle.item.coach.name": "Diabetesberater",
  "bundle.item.cuff.description": "Verbundenes Blutdruckmessgerät.",
  "bundle.item.cuff.name": "1x Blutdruckmessgerät",
  "bundle.item.dkv_coach_1.name": "Medizinisch ausgebildete Coaches",
  "bundle.item.fastclix_lancets_2.description": "34 Accu-Chek FastClix Lanzettentrommeln mit je 6 Lanzetten",
  "bundle.item.fastclix_lancets_2.name": "204x Lanzetten",
  "bundle.item.fastclix_lancets.description": "17 Accu-Chek FastClix Lanzettentrommeln mit je 6 Lanzetten",
  "bundle.item.fastclix_lancets.name": "102x Lanzetten",
  "bundle.item.fastclix_lancing_kit.description": "Inklusive einer Trommel mit 6 Lanzetten damit du keine Lanzetten sehen oder berühren musst.",
  "bundle.item.fastclix_lancing_kit.name": "1x Accu-Chek FastClix Stechhilfe",
  "bundle.item.guide_3.description": "Sendet deine Blutzuckerwerte direkt an die mySugr App",
  "bundle.item.guide_3.name": "1x Accu-Chek® Guide Messgerät inkl. Stechhilfe",
  "bundle.item.guide_4.description": "Sendet deine Blutzuckerwerte direkt an die mySugr App",
  "bundle.item.guide_4.name": "1x Accu-Chek® Guide Messgerät inkl. Stechhilfe",
  "bundle.item.guide_me.description": "Sendet deine Blutzuckerwerte direkt an die mySugr App",
  "bundle.item.guide_me.name": "1x Accu-Chek® Guide Me Messgerät",
  "bundle.item.guide_strips_1.description.country_de": "Vier Boxen mit Accu-Chek® Guide Teststreifen.",
  "bundle.item.guide_strips.description": "Fünf Boxen mit Accu-Chek® Guide Teststreifen.",
  "bundle.item.guide_strips.name": ":amountx Teststreifen",
  "bundle.item.guide.description": "Sendet deine Blutzuckerwerte direkt an die mySugr App",
  "bundle.item.guide.name": "1x Accu-Chek® Guide Messgerät",
  "bundle.item.mysugr_coach_voucher_based_1.description": "Zugang zu deinem persönlichen Coach über die App",
  "bundle.item.mysugr_coach_voucher_based_1.name": "Diabetesberater",
  "bundle.item.mysugr_strips_subscription_guide_2.description": "basierend auf deinen importierten Messungen für ein Jahr.",
  "bundle.item.mysugr_strips_subscription_guide_2.name": "Automatische Teststreifen<wbr />lieferung",
  "bundle.item.mysugr_strips_subscription_guide_2.name.2": "Unbegrenzt Teststreifen nach Hause liefern lassen",
  "bundle.item.pro.description": "Die mySugr App für iOS und Android mit allen Funktionen",
  "bundle.item.pro.name": "mySugr PRO",
  "bundle.item.scale.description": "Verbundene Waage.",
  "bundle.item.scale.name": "1x Waage",
  "bundle.item.schwenninger_coach_1.description": "Zugang zu deinem Lifestyle Coach über die App",
  "bundle.item.schwenninger_coach_1.name": "Lifestyle Coach",
  "bundle.item.servidigest_coach_1.description": "",
  "bundle.item.servidigest_coach_1.name": "",
  "bundle.item.softclix_lancets.name": ":amountx 100 Lanzetten",
  "bundle.item.softclix_lancing_kit.description": "Einfache Bedienung. Weniger Schmerzen.",
  "bundle.item.softclix_lancing_kit.name": "1x Accu-Chek® Softclix  Stechhilfe",
  "bundle.item.strips_subscription_guide.description": "basierend auf deinen importierten Messungen.",
  "bundle.item.strips_subscription_guide.name": "Automatische Teststreifen<wbr />lieferung",
  "bundle.item.strips_subscription_guide.name.2": "Unbegrenzt Teststreifen nach Hause liefern lassen",
  "bundleDelivery.text": "Dein Paket kommt in :time business Tagen bei dir an. Du erhältst in Kürze eine E-Mail mit den Details zu deiner Lieferung.\n<b>Alle weiteren Lieferungen werden durch die App ausgelöst.</b>",
  "bundleGroupItemName.activisionblizzard_coach_1": "",
  "bundleGroupItemName.fastclix_lancets_1": "Accu-Chek FastClix Lanzetten (102 Stück)",
  "bundleGroupItemName.fastclix_lancets_2": "Accu-Chek FastClix Lanzetten (204 Stück)",
  "bundleGroupItemName.fastclix_lancing_kit_1": "Accu-Chek FastClix Stechhilfe",
  "bundleGroupItemName.guide_1": "",
  "bundleGroupItemName.guide_3": "Accu-Chek Guide mg/dl inkl. Accu-Chek FastClix",
  "bundleGroupItemName.guide_4": "Accu-Chek Guide mmol/l inkl. Accu-Chek FastClix",
  "bundleGroupItemName.guide_me_1": "",
  "bundleGroupItemName.guide_strips_1": "",
  "bundleGroupItemName.mysugr_coach_1": "mySugr Coach (1 Monat)",
  "bundleGroupItemName.mysugr_coach_2": "mySugr Coach (1 Jahr)",
  "bundleGroupItemName.mysugr_coach_3": "mySugr Coach (1 Monat)",
  "bundleGroupItemName.mysugr_coach_5": "mySugr Coach (6 Monate)",
  "bundleGroupItemName.mysugr_coach_6": "",
  "bundleGroupItemName.mysugr_coach_6_copy": "",
  "bundleGroupItemName.mysugr_pro_1": "mySugr PRO (1 Monat)",
  "bundleGroupItemName.mysugr_pro_2": "mySugr PRO (1 Jahr)",
  "bundleGroupItemName.mysugr_pro_3": "mySugr PRO (6 Monate)",
  "bundleGroupItemName.mysugr_pro_4": "mySugr PRO (3 Monate)",
  "bundleGroupItemName.mysugr_strips_subscription_guide_1": "mySugr Teststreifen Subscription für Guide (1 Monat)",
  "bundleGroupItemName.mysugr_strips_subscription_guide_2": "mySugr Teststreifen Subscription für Guide (1 Jahr)",
  "bundleGroupItemName.mysugr_strips_subscription_guide_3": "mySugr Teststreifen Subscription für Guide (6 Monate)",
  "bundleGroupItemName.schwenninger_coach_1": "Schwenninger Coach (3 Monate)",
  "bundleGroupItemName.servidigest_coach_1": "",
  "bundleGroupItemName.sigroup_coach_1": "",
  "bundleGroupItemName.us_spanish_coach_1": "",
  "bundleProduct.content.items.coach": "Persönlicher Coach",
  "bundleProduct.content.items.cuff": "Blutdruckmessgerät",
  "bundleProduct.content.items.fastclix_lancets": "Lanzetten",
  "bundleProduct.content.items.fastclix_lancing_kit": "Stechhilfe",
  "bundleProduct.content.items.guide": "Blutzucker<wbr>messgerät",
  "bundleProduct.content.items.guide_me": "Blutzucker<wbr>messgerät",
  "bundleProduct.content.items.mysugr_coach_voucher_based_1": "Persönlicher Coach",
  "bundleProduct.content.items.pro": "PRO App",
  "bundleProduct.content.items.scale": "Waage<wbr>",
  "bundleProduct.content.items.servidigest_coach_1": "",
  "bundleProduct.content.items.softclix_lancets": "Lanzetten",
  "bundleProduct.content.items.softclix_lancing_kit": "Stechhilfe",
  "bundleProduct.content.items.strips_subscription_guide": "Test<wbr>streifen",
  "bundleProduct.content.title": "Dein Paket enthält:",
  "bundleProduct.content.title.roche-benefits-us": "Dein Programm beinhaltet:",
  "bundleProduct.content.title.roche-benefits-us-coach": "Dein Programm beinhaltet:",
  "bundleProduct.continueButton": "Los geht's",
  "bundleProduct.countryRestriction.de": "Nur in Deutschland verfügbar",
  "bundleProduct.countryRestriction.sg": "",
  "bundleProduct.countryRestriction.us": "Nur in USA verfügbar",
  "bundleProduct.description.list.alt_text.strips_subscription_guide.2": "",
  "bundleProduct.description.list.aok_nordwest_coach_1": "Online Datenübertragung an <b>Ihre Arztpraxis</b> (ComanD-Teilnahme vorausgesetzt)",
  "bundleProduct.description.list.coach": "Zugang zu <strong>erstklassigen DiabetesberaterInnen</strong>",
  "bundleProduct.description.list.dkv_coach_1": "Individuelle Beratung durch <b>medizinisch ausgebildete Coaches</b>",
  "bundleProduct.description.list.entry_search": "<b>Suche</b> – Finde deine Einträge ganz einfach",
  "bundleProduct.description.list.guide": "Accu-Chek® Guide <strong>Blutzuckermessgerät</strong>",
  "bundleProduct.description.list.guide_me": "Accu-Chek® Guide Me <strong>Blutzuckermessgerät</strong>",
  "bundleProduct.description.list.meal_photos": "<b>Mache Fotos</b> von deinen Mahlzeiten",
  "bundleProduct.description.list.pdf_report": "<b> PDF</b>-Berichte",
  "bundleProduct.description.list.pro": "Upgrade auf <strong>mySugr PRO App</strong>",
  "bundleProduct.description.list.pro.dkv": "Upgrade auf <strong>mySugr PRO App</strong>",
  "bundleProduct.description.list.pro.roche-benefits-us": "Gratis Upgrade für die <strong>mySugr Tagebuch-App</strong> mit allen Funktionen",
  "bundleProduct.description.list.pro.roche-benefits-us-coach": "Gratis Upgrade für die <strong>mySugr Tagebuch-App</strong> mit allen Funktionen",
  "bundleProduct.description.list.reminders": "Erhalte automatische <b>Erinnerungen</b>",
  "bundleProduct.description.list.scale_1": "",
  "bundleProduct.description.list.schwenninger_coach_1": "Zugang zu <strong>erstklassigen Lifestyle BeraterInnen</strong>",
  "bundleProduct.description.list.strips_subscription_guide": "<strong>Unbegrenzt Teststreifen</strong> nach Hause liefern lassen, basierend auf deinem tatsächlichen Verbrauch (importierte Blutzuckerwerte in der App)",
  "bundleProduct.description.list.strips_subscription_guide.2": "<strong>Unbegrenzt Teststreifen</strong> nach Hause liefern lassen, basierend auf deinem tatsächlichen Verbrauch (importierte Blutzuckerwerte in der App)",
  "bundleProduct.description.title": "Das mySugr Paket",
  "bundleProduct.description.title.dkv": "Das mySugr Paket besteht aus:",
  "bundleProduct.description.title.dvg": "Die mySugr PRO App beinhaltet …",
  "bundleProduct.features.items.immediate.coach": "Zugang zu deinem <strong>persönlichen Coach</strong> über die App",
  "bundleProduct.features.items.immediate.dkv_coach_1": "Individuelle Beratung durch <b>medizinisch ausgebildete Coaches</b>",
  "bundleProduct.features.items.immediate.mysugr_coach_voucher_based_1": "Zugang zu deinem <strong>persönlichen Coach</strong> über die App",
  "bundleProduct.features.items.immediate.mysugr_pro_1.dkv": "die <b>PRO Funktion</b> der mySugr Tagebuch App",
  "bundleProduct.features.items.immediate.pro": "<strong>PRO Funktionen</strong> der mySugr App",
  "bundleProduct.features.items.immediate.schwenninger_coach_1": "Zugang zu <strong>ausgebildeten Lifestyle BeraterInnen</strong>",
  "bundleProduct.features.items.immediate.servidigest_coach_1": "",
  "bundleProduct.features.items.product.cuff": "<b>1x</b> Blutdruckmessgerät",
  "bundleProduct.features.items.product.fastclix_lancets": "<strong>102x Lanzetten</strong>",
  "bundleProduct.features.items.product.fastclix_lancets_2": "<strong>204x Lanzetten</strong>",
  "bundleProduct.features.items.product.fastclix_lancing_kit": "<strong>1x</strong> Accu-Chek FastClix <strong>Stechhilfe</strong>",
  "bundleProduct.features.items.product.guide": "<strong>1x</strong> Accu-Chek® Guide <strong>Messgerät</strong>",
  "bundleProduct.features.items.product.guide_me": "<strong>1x</strong> Accu-Chek® Guide Me <strong>Messgerät</strong>",
  "bundleProduct.features.items.product.guide_strips": "<strong>:amount Teststreifen</strong>",
  "bundleProduct.features.items.product.scale": "<b>1x</b> Waage",
  "bundleProduct.features.items.product.softclix_lancets": "<strong>:amountx 100 Lanzetten</strong>",
  "bundleProduct.features.items.product.softclix_lancing_kit": "<strong>1x</strong> Accu-Chek® Softclix <strong>Stechhilfe</strong>",
  "bundleProduct.features.items.subscription.alt_text.strips_subscription_guide.2": "<strong>Automatischer Teststreifennachschub</strong> basierend auf deinen importierten Messungen (keine Lanzetten enthalten)",
  "bundleProduct.features.items.subscription.aok_nordwest_coach_1": "Zugang zu deinem <b>persönlichen Coach</b>",
  "bundleProduct.features.items.subscription.coach": "Zugang zu <strong>zertifizierten DiabetesberaterInnen</strong>",
  "bundleProduct.features.items.subscription.dkv_coach_1": "Individuelle Beratung durch <b>medizinisch ausgebildete Coaches</b>",
  "bundleProduct.features.items.subscription.mysugr_coach_voucher_based_1": "Zugang zu <strong>zertifizierten DiabetesberaterInnen</strong>",
  "bundleProduct.features.items.subscription.schwenninger_coach_1": "Zugang zu <strong>ausgebildeten Lifestyle BeraterInnen</strong>",
  "bundleProduct.features.items.subscription.servidigest_coach_1": "",
  "bundleProduct.features.items.subscription.strips_subscription_guide": "<strong>Automatische Teststreifen<wbr />lieferung</strong> basierend auf deinen importierten Messungen",
  "bundleProduct.features.items.subscription.strips_subscription_guide.2": "<strong>Unbegrenzt Teststreifen</strong> nach Hause liefern lassen, basierend auf den importierten Messungen",
  "bundleProduct.features.title": "Dein Paket enthält:",
  "bundleProduct.features.title.dkv": "Ihr mySugr Paket der DKV enthält:",
  "bundleProduct.features.title.roche-benefits-us": "Dein Programm beinhaltet:",
  "bundleProduct.features.title.roche-benefits-us-coach": "Dein Programm beinhaltet:",
  "bundleProduct.features.titles.immediate": "Unmittelbar verfügbar:",
  "bundleProduct.features.titles.product": "Dein Startpaket kommt per Post:",
  "bundleProduct.features.titles.subscription": "Laufend versorgt mit:",
  "bundleProduct.freeShipping": "Gratis Versand",
  "bundleProduct.groupNotFound": "Dieses Paket ist derzeit nicht verfügbar.",
  "bundleProduct.minCommitment.info.DAY.1": "Mindestlaufzeit 1 Tag",
  "bundleProduct.minCommitment.info.DAY.2": "Mindestlaufzeit :minRecurringCount Tage",
  "bundleProduct.minCommitment.info.MONTH.1": "Mindestlaufzeit 1 Monat",
  "bundleProduct.minCommitment.info.MONTH.2": "Mindestlaufzeit :minRecurringCount Monate",
  "bundleProduct.minCommitment.info.WEEK.1": "",
  "bundleProduct.minCommitment.info.YEAR.1": "Mindestlaufzeit 1 Jahr",
  "bundleProduct.minCommitment.info.YEAR.2": "Mindestens :minRecurringCount Jahre",
  "bundleProduct.noBundle.button": "Noch einmal versuchen",
  "bundleProduct.noBundle.text": "Entschuldige, wir können derzeit keine Informationen über das mySugr Paket laden",
  "bundleProduct.paymentInterval.DAY.1.long": "Pro Tag",
  "bundleProduct.paymentInterval.DAY.1.short": "Täglich:",
  "bundleProduct.paymentInterval.DAY.2+": "Alle :intervalCount Tage",
  "bundleProduct.paymentInterval.MONTH.1.long": "Pro Monat",
  "bundleProduct.paymentInterval.MONTH.1.short": "Monatlich:",
  "bundleProduct.paymentInterval.MONTH.2+": "Alle :intervalCount Monate",
  "bundleProduct.paymentInterval.WEEK.1.long": "",
  "bundleProduct.paymentInterval.WEEK.1.short": "",
  "bundleProduct.paymentInterval.YEAR.1.long": "Pro Jahr",
  "bundleProduct.paymentInterval.YEAR.1.short": "Jährlich",
  "bundleProduct.paymentInterval.YEAR.2+": "Alle :intervalCount Jahre",
  "bundleProduct.price": ":price",
  "cancelAnytime": "Du kannst dich jederzeit von mySugr abmelden.",
  "changeCardDetails": "Karteninformationen ändern",
  "coachBox.text": "Logge dich ein, um die Nachricht zu lesen und das Beste aus deiner persönlichen Diabetesbetreuung herauszuholen.",
  "coachBox.title": "Dein <b>persönlicher Coach</b> hat dir eine Nachricht geschickt!",
  "coachSelection.message.en": "Hello, <br>\nI am your <br>\npersonal <br>\ncoach!",
  "coachSelection.message.es": "Hola,<br>\nsoy tu<br>\n¡entrenador<br>\npersonal!",
  "coachSelection.select.button.en": "Select",
  "coachSelection.select.button.es": "Seleccionar",
  "coachSelection.subtitle": "Welche Sprache möchtest du mit deinem Coach sprechen?",
  "coachSelection.title": "<strong>Sprache für Coach auswählen</strong>",
  "confirmEmailCodeInputLabel": "Geben Sie hier den Code ein, den Sie per E-Mail erhalten haben",
  "contact.stepName": "Kontakt",
  "contact.title": "<strong>Kontakt</strong>informationen",
  "continueToNextStep": "Weiter zum nächsten Schritt",
  "cookieBanner.allowAllButton": "Alle akzeptieren",
  "cookieBanner.cookieSettingsButton": "Cookie-Einstellungen",
  "cookieBanner.noticeDescription": "<p>Wir verwenden Cookies, damit unsere Website bestmöglich funktioniert und du ein erstklassiges Online-Erlebnis hast! Indem du auf „Alle akzeptieren“ klickst, erklärst du dich damit einverstanden, dass Cookies auf deinem Gerät gespeichert werden, um die Website-Navigation zu verbessern, die Nutzung zu analysieren und unsere Marketing-Bemühungen zu unterstützen. Um genau zu wählen, welche Kategorien du zulassen möchtest, geh einfach zu den „Cookie-Einstellungen“.\n<a href=\"https://legal.mysugr.com/documents/privacy_policy_web/current/de.html\" rel=\"noopener noreferrer\" target=\"_blank\"><strong style=\"color: #538400;\">Weitere Informationen</strong></a>.</p>",
  "cookieBanner.rejectAllButton": "Optionale ablehnen",
  "cookieBanner.title": "Lecker, lecker, wir lieben Cookies!",
  "cookieBar.buttonText": "OK",
  "cookieBar.text": "Um unsere Webseite für Dich optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Weitere Informationen zu Cookies und wie der Verwendung widersprochen werden kann, erhältst Du in unserer <a target=\"_blank\" rel=\"noopener\" href=\":link\">Datenschutzerklärung</a>.",
  "cookieBar.text.global": "Um unsere Webseite für Dich optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Weitere Informationen zu Cookies und wie der Verwendung widersprochen werden kann, erhältst Du in unserer Datenschutzerklärung (<a target=\"_blank\" rel=\"noopener\" href=\":linkUs\" tabindex=\":tabIndex\">US</a>, <a target=\"_blank\" rel=\"noopener\" href=\":linkEu\" tabindex=\":tabIndex\">EU</a>).",
  "cookieCategory.functionalDescription": "Funktionale Verarbeitung und Cookies ermöglichen es unseren Webseiten, erweiterte Funktionen und Personalisierungen bereitzustellen. Mit deiner Zustimmung können sie von uns oder von Drittanbietern festgelegt werden, deren Dienste wir unseren Seiten hinzugefügt haben. Wenn du diese Cookies ablehnst, funktionieren einige oder alle dieser Dienste möglicherweise nicht ordnungsgemäß.",
  "cookieCategory.functionalTitle": "Funktionale Cookies",
  "cookieCategory.performanceDescription": "Diese Cookies ermöglichen es uns, Besuche und Verkehrsquellen zu zählen, damit wir die Leistung unserer Website messen und verbessern können. Sie unterstützen uns bei der Beantwortung der Fragen, welche Seiten am beliebtesten sind, welche am wenigsten genutzt werden und wie sich Besucher auf der Website bewegen. Alle von diesen Cookies erfassten Informationen werden aggregiert und sind deshalb anonym. Wenn du diese Cookies nicht aktivierst, können wir nicht wissen, wann du unsere Website besucht hast.",
  "cookieCategory.performanceTitle": "Leistungsbezogene Cookies",
  "cookieCategory.socialMediaDescription": "Mit deiner Zustimmung werden Social-Media-Cookies von einer Reihe von Social-Media-Diensten gesetzt, die wir der Webseite hinzugefügt haben (z. B. Facebook, Twitter, LinkedIn), damit du unsere Inhalte mit deinen Freunden, Kollegen und Netzwerken teilen kannst. Diese Cookies können deinen Browser auf anderen Websites von Drittanbietern verfolgen und ein Profil deiner Interessen erstellen. Dies kann sich auf den Inhalt und die Nachrichten auswirken, die du auf den anderen von dir besuchten Websites von Drittanbietern siehst. Wenn du diese Cookies nicht zulässt, kannst du diese Freigabe-Tools möglicherweise nicht verwenden oder anzeigen.",
  "cookieCategory.socialMediaTitle": "Social-Media-Cookies",
  "cookieCategory.strictlyNecessaryDescription": "Diese Cookies sind für die Funktion der Webseite erforderlich und können in unseren Systemen nicht deaktiviert werden. Sie werden nur als Reaktion auf von dir durchgeführte Aktionen gesetzt, die einer Anforderung von Diensten gleichkommen, z. B. das Festlegen deiner Privatsphäre und Cookie-Einstellungen, Anmelden oder Ausfüllen von Formularen. Du kannst deinen Browser so einstellen, dass er diese Cookies blockiert oder dich darüber informiert. Wenn du diese Cookies jedoch blockierst, funktionieren die Webseiten nicht mehr.",
  "cookieCategory.strictlyNecessaryTitle": "Unbedingt erforderliche Cookies",
  "cookieCategory.targetingDescription": "Diese Cookies können über unsere Website von unseren Werbepartnern gesetzt werden. Sie können von diesen Unternehmen verwendet werden, um ein Profil deiner Interessen zu erstellen und dir relevante Anzeigen auf anderen Websites zu zeigen. Sie speichern nicht direkt personenbezogene Daten, basieren jedoch auf einer einzigartigen Identifizierung deines Browsers und Internet-Geräts. Wenn du diese Cookies nicht zulässt, wird dir weniger gezielte Werbung angezeigt.",
  "cookieCategory.targetingTitle": "Cookies für Marketingzwecke",
  "cookieList.category": "Kategorie",
  "cookieList.description": "Beschreibung",
  "cookieList.duration": "Dauer",
  "cookieList.host": "Host",
  "cookieList.name": "Name",
  "cookieList.type": "Art",
  "cookiePreferenceCenter.allowAllButton": "Alle Cookies erlauben",
  "cookiePreferenceCenter.alwaysActiveTitle": "Immer aktiv",
  "cookiePreferenceCenter.categoriesTitle": "Einstellungen für Einwilligungen verwalten",
  "cookiePreferenceCenter.confirmButton": "Meine Auswahl bestätigen",
  "cookiePreferenceCenter.cookieListLink": "Details zu den Cookies",
  "cookiePreferenceCenter.cookiePolicyLink": "Den Datenschutz- und Cookie-Hinweis von mySugr für diese Webseite findest du hier.",
  "cookiePreferenceCenter.cookiesListTitle": "Liste der Cookies",
  "cookiePreferenceCenter.description": "Im Datenschutz-Präferenz-Center kannst du jederzeit einstellen, welche Cookies verarbeitet werden sollen.\nWenn du deine Einstellungen ändern möchtest, klicke einfach unten auf der Webseite auf den Link „Cookie-Einstellungen“.",
  "cookiePreferenceCenter.firstPartyCookies": "Erstanbieter-Cookies",
  "cookiePreferenceCenter.optionalGroupName": "Optional",
  "cookiePreferenceCenter.rejectAllButton": "Optionale Cookies ablehnen",
  "cookiePreferenceCenter.strictlyNecessaryGroupName": "Unbedingt erforderlich",
  "cookiePreferenceCenter.title": "Datenschutz-Präferenz-Center",
  "cookiePreferenceCenter.viewCookiesButton": "Cookies anzeigen",
  "countres.gu": "Guam",
  "countries.ad": "Andorra",
  "countries.ae": "Vereinigte Arabische Emirate",
  "countries.af": "Afghanistan",
  "countries.ag": "Antigua und Barbuda",
  "countries.ai": "Anguilla",
  "countries.al": "Albanien",
  "countries.am": "Armenien",
  "countries.ao": "Angola",
  "countries.aq": "Antarktis",
  "countries.ar": "Argentinien",
  "countries.as": "Amerikanisch-Samoa",
  "countries.at": "Österreich",
  "countries.au": "Australien",
  "countries.aw": "Aruba",
  "countries.ax": "Åland-Inseln",
  "countries.az": "Aserbaidschan",
  "countries.ba": "Bosnien und Herzegowina",
  "countries.bb": "Barbados",
  "countries.bd": "Bangladesch",
  "countries.be": "Belgien",
  "countries.bf": "Burkina Faso",
  "countries.bg": "Bulgarien",
  "countries.bh": "Bahrain",
  "countries.bi": "Burundi",
  "countries.bj": "Benin",
  "countries.bl": "Saint Barthélemy",
  "countries.bm": "Bermuda",
  "countries.bn": "Brunei Darussalam",
  "countries.bo": "Bolivien",
  "countries.bq": "Bonaire",
  "countries.br": "Brasilien",
  "countries.bs": "Bahamas",
  "countries.bt": "Bhutan",
  "countries.bv": "Bouvetinsel",
  "countries.bw": "Botswana",
  "countries.by": "Weißrussland",
  "countries.bz": "Belize",
  "countries.cc": "Kokosinseln (Keelinginseln)",
  "countries.cd": "Kongo, Demokratische Republik",
  "countries.cf": "Zentralafrikanische Republik",
  "countries.cg": "Kongo",
  "countries.ch": "Schweiz",
  "countries.ci": "Elfenbeinküste",
  "countries.ck": "Cookinseln",
  "countries.cl": "Chile",
  "countries.cm": "Kamerun",
  "countries.cn": "Kanada",
  "countries.co": "Kolumbien",
  "countries.cr": "Costa Rica",
  "countries.cu": "Kuba",
  "countries.cv": "Kap Verde",
  "countries.cw": "Curacao",
  "countries.cx": "Weihnachtsinsel",
  "countries.cy": "Zypern",
  "countries.cz": "Tschechische Republik",
  "countries.de": "Deutschland",
  "countries.dj": "Dschibuti",
  "countries.dk": "Dänemark",
  "countries.dm": "Dominica",
  "countries.do": "Dominikanische Republik",
  "countries.dz": "Algerien",
  "countries.ec": "Ecuador",
  "countries.ee": "Estland",
  "countries.eg": "Ägypten",
  "countries.eh": "Westsahara",
  "countries.er": "Eritrea",
  "countries.es": "Spanien",
  "countries.et": "Äthiopien",
  "countries.fi": "Finnland",
  "countries.fj": "Fidschi",
  "countries.fk": "Falklandinseln (Malvinas)",
  "countries.fm": "Mikronesien, Föderierte Staaten von",
  "countries.fo": "Färöer Inseln",
  "countries.fr": "Frankreich",
  "countries.ga": "Gabun",
  "countries.gb": "Vereinigtes Königreich",
  "countries.gd": "Grenada",
  "countries.ge": "Georgia",
  "countries.gf": "Französisch-Guayana",
  "countries.gg": "Guernsey",
  "countries.gh": "Ghana",
  "countries.gi": "Gibraltar",
  "countries.gl": "Grönland",
  "countries.gm": "Gambia",
  "countries.gn": "Guinea",
  "countries.gp": "Guadeloupe",
  "countries.gq": "Äquatorialguinea",
  "countries.gr": "Griechenland",
  "countries.gs": "Süd-Georgien und die südlichen Sandwich-Inseln",
  "countries.gt": "Guatemala",
  "countries.gw": "Guinea-Bissau",
  "countries.gy": "Guyana",
  "countries.hk": "Hongkong",
  "countries.hm": "Heard Island und McDonald Islands",
  "countries.hn": "Honduras",
  "countries.hr": "Kroatien",
  "countries.ht": "Haiti",
  "countries.hu": "Ungarn",
  "countries.id": "Indonesien",
  "countries.ie": "Irland",
  "countries.il": "Israel",
  "countries.im": "Isle of Man",
  "countries.in": "Indien",
  "countries.io": "Britisches Territorium des Indischen Ozeans",
  "countries.iq": "Irak",
  "countries.ir": "Iran, Islamische Republik",
  "countries.is": "Island",
  "countries.it": "Italien",
  "countries.je": "Jersey",
  "countries.jm": "Jamaika",
  "countries.jo": "Jordanien",
  "countries.jp": "Japan",
  "countries.ke": "Kenia",
  "countries.kg": "Kirgisistan",
  "countries.kh": "Kambodscha",
  "countries.ki": "Kiribati",
  "countries.km": "Komoren",
  "countries.kn": "St. Kitts und Nevis",
  "countries.kp": "Korea, Demokratische Volksrepublik",
  "countries.kr": "Korea, Republik von",
  "countries.kw": "Kuwait",
  "countries.ky": "Cayman Inseln",
  "countries.kz": "Kasachstan",
  "countries.la": "Demokratische Volksrepublik Laos",
  "countries.lb": "Libanon",
  "countries.lc": "St. Lucia",
  "countries.li": "Liechtenstein",
  "countries.lk": "Sri Lanka",
  "countries.lr": "Liberia",
  "countries.ls": "Lesotho",
  "countries.lt": "Litauen",
  "countries.lu": "Luxemburg",
  "countries.lv": "Lettland",
  "countries.ly": "Libyen",
  "countries.ma": "Marokko",
  "countries.mc": "Monaco",
  "countries.md": "Moldawien, Republik",
  "countries.me": "Montenegro",
  "countries.mf": "Saint Martin (französischer Teil)",
  "countries.mg": "Madagaskar",
  "countries.mh": "Marshallinseln",
  "countries.mk": "Mazedonien, ehemalige jugoslawische Republik",
  "countries.ml": "Mali",
  "countries.mm": "Myanmar",
  "countries.mn": "Mongolei",
  "countries.mo": "Macao",
  "countries.mp": "Nördliche Marianeninseln",
  "countries.mq": "Martinique",
  "countries.mr": "Mauretanien",
  "countries.ms": "Montserrat",
  "countries.mt": "Malta",
  "countries.mu": "Mauritius",
  "countries.mv": "Malediven",
  "countries.mw": "Malawi",
  "countries.mx": "Mexiko",
  "countries.my": "Malaysia",
  "countries.mz": "Mosambik",
  "countries.na": "Namibia",
  "countries.nc": "Neukaledonien",
  "countries.ne": "Niger",
  "countries.nf": "Norfolkinsel",
  "countries.ng": "Nigeria",
  "countries.ni": "Nicaragua",
  "countries.nl": "Niederlande",
  "countries.no": "Norwegen",
  "countries.np": "Nepal",
  "countries.nr": "Nauru",
  "countries.nu": "Niue",
  "countries.nz": "Neuseeland",
  "countries.om": "Oman",
  "countries.pa": "Panama",
  "countries.pe": "Peru",
  "countries.pf": "Französisch Polynesien",
  "countries.pg": "Papua Neu-Guinea",
  "countries.ph": "Philippinen",
  "countries.pk": "Pakistan",
  "countries.pl": "Polen",
  "countries.pm": "Saint Pierre und Miquelon",
  "countries.pn": "Pitcairn",
  "countries.pr": "Puerto Rico",
  "countries.ps": "Palästinensisches Gebiet, besetztes Gebiet",
  "countries.pt": "Portugal",
  "countries.pw": "Palau",
  "countries.py": "Paraguay",
  "countries.qa": "Katar",
  "countries.re": "Réunion",
  "countries.ro": "Rumänien",
  "countries.rs": "Serbien",
  "countries.ru": "Russische Föderation",
  "countries.rw": "Ruanda",
  "countries.sa": "Saudi Arabien",
  "countries.sb": "Salomon-Inseln",
  "countries.sc": "Seychellen",
  "countries.sd": "Sudan",
  "countries.se": "Schweden",
  "countries.sg": "Singapur",
  "countries.sh": "St. Helena, Ascension und Tristan da Cunha",
  "countries.si": "Slowenien",
  "countries.sj": "Spitzbergen und Jan Mayen",
  "countries.sk": "Slowakei",
  "countries.sl": "Sierra Leone",
  "countries.sm": "San Marino",
  "countries.sn": "Senegal",
  "countries.so": "Somalia",
  "countries.sr": "Suriname",
  "countries.ss": "Südsudan",
  "countries.st": "São Tomé und Príncipe",
  "countries.sv": "El Salvador",
  "countries.sx": "Sint Maarten (niederländischer Teil)",
  "countries.sy": "Syrische Arabische Republik",
  "countries.sz": "Swasiland",
  "countries.tc": "Turks- und Caicosinseln",
  "countries.td": "Tschad",
  "countries.tf": "Französische Südgebiete",
  "countries.tg": "Togo",
  "countries.th": "Thailand",
  "countries.tj": "Tadschikistan",
  "countries.tk": "Tokelau",
  "countries.tl": "Timor-Leste",
  "countries.tm": "Turkmenistan",
  "countries.tn": "Tunesien",
  "countries.to": "Tonga",
  "countries.tr": "Türkei",
  "countries.tt": "Trinidad und Tobago",
  "countries.tv": "Tuvalu",
  "countries.tw": "Taiwan",
  "countries.tz": "Tansania, Vereinigte Republik",
  "countries.ua": "Ukraine",
  "countries.ug": "Uganda",
  "countries.um": "USA, kleinere ausgelagerte Inseln",
  "countries.us": "Vereinigte Staaten",
  "countries.uy": "Uruguay",
  "countries.uz": "Usbekistan",
  "countries.va": "Heiliger Stuhl (Staat Vatikanstadt)",
  "countries.vc": "St. Vincent und die Grenadinen",
  "countries.ve": "Venezuela, Bolivarische Republik",
  "countries.vg": "Virgin Inseln, Britisch",
  "countries.vi": "Amerikanische Jungferninseln",
  "countries.vn": "Vietnam",
  "countries.vu": "Vanuatu",
  "countries.wf": "Wallis und Futuna",
  "countries.ws": "Samoa",
  "countries.ye": "Jemen",
  "countries.yt": "Mayotte",
  "countries.za": "Südafrika",
  "countries.zm": "Sambia",
  "countries.zw": "Simbabwe",
  "countryNames.de.long": "Deutschland",
  "countryNames.de.medium": "Deutschland",
  "countryNames.de.short": "DE",
  "countryNames.us.long": "Vereinigte Staaten von Amerika",
  "countryNames.us.medium": "USA",
  "countryNames.us.short": "USA",
  "cta.redeemVoucher": "Voucher einlösen",
  "cta.unlockFree": "Kostenlos freischalten",
  "customCodeVerificationForm.label.dvg": "Freischaltcode",
  "customCodeVerificationForm.label.optumrx_1": "OptumRX ID",
  "customCodeVerificationForm.overlayButtonText": "mehr Info",
  "customCodeVerificationForm.overlayText.dvg": "<h4 style=\"text-align: center\">Das DVG:</h4>\n<p>Durch das „Digitale-Versorgung-Gesetz“ (kurz DVG) können Ärzte in Deutschland seit einiger Zeit ausgewählte Gesundheits-Apps verschreiben. Die gesetzlichen Krankenkassen übernehmen dann die App-Kosten. Für Menschen mit Diabetes gibt’s jetzt tolle Neuigkeiten: <b>die mySugr PRO App</b> ist auf Rezept erhältlich! <br>\n<a href=\"https://www.mysugr.com/de-de/dvg/#faq\" target=\"_blank\">mehr Info</a></p>",
  "customCodeVerificationForm.overlayText.optumrx_1": "",
  "customCodeVerificationForm.placeholder.dvg": " ",
  "customCodeVerificationForm.placeholder.optumrx_1": " ",
  "downloadApp.desktop.text": "Richte die Kamera deines Smartphones auf den <br> QR-Code unten und lade die App herunter",
  "downloadApp.mobile.text": "Die App kannst du hier herunterladen:",
  "downloadApp.text": "Um die Registrierung abzuschließen, <b>lade bitte die mySugr App</b> aus deinem App Store herunter und melde dich mit deiner E-Mail-Adresse an.",
  "downloadApp.title": "<b>Noch ein Hinweis!</b>",
  "eligibilityCheck.failure.changeEmailButton": "E-Mail-Adresse ändern",
  "eligibilityCheck.failure.monsterAlt": "Illustration von einem traurigen Monster",
  "eligibilityCheck.failure.title": "E-Mail-Adresse ist nicht berechtigt für das mySugr Paket",
  "eligibilityCheck.failure.unsuccessfulText": "Oh nein! Diese E-Mail-Adresse ist nicht berechtigt. Bitte überprüfe, ob du sie richtig eingegeben hast und kontaktiere <a href=\"mailto:support@mysugr.com\">support@mysugr.com</a> falls du denkst, dass dies ein Fehler ist.",
  "eligibilityCheck.form.companyEmail.label": "Firmen E-Mail",
  "eligibilityCheck.form.continue": "Los geht's",
  "eligibilityCheck.form.overlayText": "<p>Bitte gib deine Firmen-E-Mail-Adresse ein, um zu überprüfen, ob du für das mySugr Paket berechtigt bist.</p>\n\n<p>Wenn du berechtigt bist, bekommst du eine E-Mail von uns mit einem Link um dich für das Paket anzumelden.</p>\n\n<p>Wir verwenden deine Firmen-E-Mail-Adresse nur, um zu überprüfen, ob du berechtigt bist. Wenn du dich für das Programm anmeldest, kannst du dich in deinen bestehenden mySugr Account einloggen oder mit einer E-Mail-Adresse deiner Wahl einen neuen mySugr Account registrieren.</p>",
  "eligibilityCheck.introText": "Gib die von deinem Arbeitgeber ausgestellte E-Mail-Adresse an, um die Prüfung deiner Anspruchsberechtigung zu starten. Du musst nicht diese E-Mail-Adresse für dein mySugr Konto verwenden, falls du lieber eine persönliche E-Mail-Adresse verwenden möchtest.",
  "eligibilityCheck.pageTitle": "mySugr Programm, Anspruchsberechtigung",
  "eligibilityCheck.resend.notification.failure": "Wir konnten dir die Berechtigungsbestätigung nicht per E-Mail zusenden.",
  "eligibilityCheck.resend.notification.success": "Wir haben dir die Berechtigungsbestätigung erneut per E-Mail gesendet.",
  "eligibilityCheck.success.boxAlt": "Illustration von einem Herz, das aus einer Kiste springt",
  "eligibilityCheck.success.boxWithHeartAlt": "Herz, das aus einer Kiste springt",
  "eligibilityCheck.success.changeEmailButton": "E-Mail-Adresse ändern",
  "eligibilityCheck.success.resendButton": "E-Mail erneut senden",
  "eligibilityCheck.success.resendText": "Hast du keinen Gutscheincode von mySugr erhalten?",
  "eligibilityCheck.success.successfullText": "Deine Firma deckt das mySugr Paket ab.<br>\nWir haben dir einen Gutscheincode per E-Mail zugesendet. Mit diesem Code kannst du dich für das mySugr Paket anmelden.",
  "eligibilityCheck.success.title": "E-Mail-Adresse ist berechtigt für das mySugr Paket",
  "eligibilityCheck.title": "Prüfe deine Anspruchsberechtigung für das mySugr Programm",
  "email.stepName": "Registrierung / Login",
  "endsWithNumber": "Endet mit:",
  "enroll": "Abonnieren",
  "errors.ADYEN_REFUSED_3D_NOT_AUTHENTICATED": "Die sichere 3D-Authentifizierung ist fehlgeschlagen oder wurde abgebrochen",
  "errors.ADYEN_REFUSED_3D_SECURE_AUTHENTICATION_FAILED": "3D-Secure-Authentifizierung fehlgeschlagen",
  "errors.ADYEN_REFUSED_CVC_DECLINED": "CVC wurde abgelehnt",
  "errors.ADYEN_REFUSED_EXPIRED_CARD": "Kreditkarte ist abgelaufen.",
  "errors.ADYEN_REFUSED_FRAUD": "Es liegt ein Problem mit deiner Kreditkarte vor.",
  "errors.ADYEN_REFUSED_INVALID_CARD_NUMBER": "Kreditkarte ist ungültig",
  "errors.ADYEN_REFUSED_REFUSED": "Kreditkarte wurde abgelehnt",
  "errors.ASSIGN_OPTUM_RX_ERROR.invalid_input": "Die OptumRx Patienten-ID ist ungültig",
  "errors.ASSIGN_OPTUM_RX_ERROR.patient_already_assigned": "Die OptumRx Patienten-ID wurde bereits verwendet",
  "errors.CARD_DECLINED": "Deine Karte wurde abgelehnt",
  "errors.CARD_NOT_SUPPORTED.cardNumber": "Karten von diesem Anbieter können wir derzeit nicht akzeptieren",
  "errors.COUNTRY_REGION_MISSMATCH": "Das Angebot, das du einlösen möchtest, ist in :nameCountry derzeit nicht verfügbar. Es ist nur verfügbar für BenutzerInnen, die ihre Daten in :offerRegions speichern. Bitte kontaktiere unseren Support.",
  "errors.DECLINED_CARD.cardNumber": "Kreditkarte wurde abgelehnt",
  "errors.EMPTY_CART": "Dein Warenkorb ist leer",
  "errors.EXISTS_IN_OTHER_REGION": "Der Gutschein wurde bereits eingelöst oder deine Benutzerdaten sind in einer anderen Region gespeichert als dieses Angebot. Bitte kontaktiere unseren Support.",
  "errors.EXPIRED_CARD.cardNumber": "Kreditkarte ist abgelaufen",
  "errors.INCOMPLETE_CARD_CVC.cvc": "Unvollständig",
  "errors.INCOMPLETE_CARD_EXPIRATION.expiration": "Unvollständig",
  "errors.INCOMPLETE_CARD_NUMBER.cardNumber": "Unvollständig",
  "errors.INCORRECT_CVC": "CVC ist nicht korrekt",
  "errors.INSUFFICIENT_FUNDS": "Dein Konto scheint nicht ausreichend gedeckt zu sein",
  "errors.INVALID_AGE.birthdate": "Du musst für das Abo :minAge+ sein",
  "errors.INVALID_BIRTHDATE.birthdate": "Ungültiges Geburtsdatum",
  "errors.INVALID_CARD_CVC_NUMBER.cvc": "CVC ist ungültig",
  "errors.INVALID_CARD_EXPIRATION_MONTH_PAST.expiration": "Gültigkeit ist in der Vergangenheit",
  "errors.INVALID_CARD_EXPIRATION_YEAR_PAST.expiration": "Gültigkeit ist in der Vergangenheit",
  "errors.INVALID_CARD_NUMBER.cardNumber": "Kreditkarte ist ungültig",
  "errors.INVALID_COUNTRY": "Dieses Angebot ist nicht mit dem Land kompatibel, das für deinen Benutzer hinterlegt ist. Bitte kontaktiere den Support, um das Problem zu lösen.",
  "errors.INVALID_EMAIL_CONFIRMATION_CODE.code": "Ungültiger E-Mail-Bestätigungs-Code",
  "errors.INVALID_EMAIL.companyEmail": "E-Mail ist ungültig",
  "errors.INVALID_EMAIL.email": "E-Mail ist ungültig",
  "errors.INVALID_GERMAN_STATE.state": "Bundesland ist Ungültig",
  "errors.INVALID_INSURANCE_CARD_INFO.groupNumber": "Ungültige Nummer",
  "errors.INVALID_INSURANCE_CARD_INFO.memberId": "Ungültige Nummer",
  "errors.INVALID_INSURANCE_NUMBER.insuranceNumber": "Ungültige Versicherungsnummer",
  "errors.INVALID_LOGIN": "E-Mail und Passwort stimmen nicht überein",
  "errors.INVALID_PASSWORD_CONFIRMATION.password2": "Die beiden Passwörter stimmen nicht überein",
  "errors.INVALID_PHONE_NUMBER.phone": "Telefonnummer ist ungültig",
  "errors.INVALID_POSTAL_CODE.postalCode": "Postleitzahl ist ungültig",
  "errors.INVALID_US_STATE.state": "Ungültig",
  "errors.INVALID_VOUCHER_CODE.voucherCode": "Anmeldecode ist ungültig",
  "errors.INVALID_ZIP_CODE.postalCode": "Ungültige Postleitzahl",
  "errors.INVALID.customCodeVerification": "Ungültige ID",
  "errors.INVALID.customCodeVerification.dvg": "Ungültiger Aktivierungscode",
  "errors.MIN_LENGTH.password": "Passwort muss mindestens :minLength Zeichen lang sein",
  "errors.MIN_LENGTH.password2": "Passwort muss mindestens :minLength Zeichen lang sein",
  "errors.MISSING_CONSENT.consents": "Du musst :title akzeptieren.",
  "errors.MISSING_CONSENTS": "Wir konnten deine Bestellung nicht bearbeiten. Bitte kontaktiere support@mysugr.com",
  "errors.MISSING_CONSENTS_FAILED": "Wir konnten deine Bestellung nicht bearbeiten. Bitte kontaktiere support@mysugr.com",
  "errors.MISSING_FIELD.address": "Adresse ist erforderlich",
  "errors.MISSING_FIELD.birthdate": "Geburtsdatum ist erforderlich",
  "errors.MISSING_FIELD.birthdateCheckbox": "Teilnehmer müssen das erforderliche Mindestalter für das Abonnement haben",
  "errors.MISSING_FIELD.city": "Stadt/Ort ist erforderlich",
  "errors.MISSING_FIELD.companyEmail": "E-Mail ist erforderlich",
  "errors.MISSING_FIELD.country": "Bundesland ist erforderlich",
  "errors.MISSING_FIELD.email": "E-Mail ist erforderlich",
  "errors.MISSING_FIELD.employer": "Bitte auswählen",
  "errors.MISSING_FIELD.firstName": "Vorname ist erforderlich",
  "errors.MISSING_FIELD.lastName": "Nachname ist erforderlich",
  "errors.MISSING_FIELD.name": "Name ist erforderlich",
  "errors.MISSING_FIELD.password": "Passwort ist erforderlich",
  "errors.MISSING_FIELD.password2": "Passwort wiederholen",
  "errors.MISSING_FIELD.phone": "Telefonnummer ist erforderlich",
  "errors.MISSING_FIELD.postalCode": "Postleitzahl ist erforderlich",
  "errors.MISSING_FIELD.state": "Bundesland ist erforderlich",
  "errors.MISSING_FIELD.voucherCode": "Bitte Gutscheincode eingeben",
  "errors.PASSWORD_RESET_TOKEN_EXPIRED": "Der Zeitraum für die Vergabe deines neuen Passworts ist abgelaufen",
  "errors.PAYMENT_DETAILS_NOT_SUPPORTED": "Kartentyp nicht unterstützt.",
  "errors.POSSIBLE_PO_BOX.address": "Ist diese Adresse eine Packstation? Aus rechtlichen Gründen können wir nicht an Packstationen liefern.",
  "errors.PRESCRIPTION_ALREADY_ACTIVE": "Du hast bereits ein bestehendes Abo",
  "errors.PROCESSING_ERROR": "Wir konnten deine Karte zu belasten",
  "errors.SERVER_ERROR": "Fehler beim Aufrufen des mySugr-Servers",
  "errors.STRIPE_ERROR": "Fehler beim Aufrufen des Bezahl-Servers",
  "errors.THE_VOUCHER_IS_CURRENTLY_NOT_ACTIVE.voucherCode": "Der Gutschein ist zur Zeit nicht aktiv",
  "errors.undefined": "Nicht definierter Fehler",
  "errors.UNKNOWN_ERROR": "Ups, da ist was schiefgegangen",
  "errors.UNKNOWN_ERROR_2": "Hoppla, etwas ist schiefgelaufen",
  "errors.UNLOCK_CODE_ALREADY_USED": "Dieser Aktivierungscode wurde bereits verwendet.",
  "errors.UNLOCK_CODE_INVALID": "Oh nein, der Aktivierungscode ist ungültig!",
  "errors.USER_ALREADY_SUBSCRIBED_TO_BUNDLE": "Du hast bereits ein mySugr Paket Abo",
  "errors.USER_ALREADY_SUBSCRIBED_TO_PLAN": "Du hast bereits ein mySugr Paket Abo",
  "errors.USER_EMAIL_HAS_ACTIVE_BUNDLE": "Du hast bereits ein mySugr Paket Abo",
  "errors.USER_EMAIL_HAS_ACTIVE_PRESCRIPTION": "Du hast bereits ein bestehendes Abo",
  "errors.USER_EXISTS.email": "BenutzerIn existiert bereits",
  "errors.USER_HAS_NO_BUNDLE": "Eine Änderung der Zahlungsinformationen ist nur mit einem laufenden mySugr Paket-Abonnement möglich.",
  "errors.USER_HAS_NO_EXISTING_PAYMENT": "Keine Zahlungsinformationen gefunden",
  "errors.USER_MISSING": "Es liegt ein Problem mit deinem Abonnement vor.\nBitte kontaktiere den Support.",
  "errors.VOUCHER_ALREADY_EXPIRED.voucherCode": "Dieser Gutschein ist abgelaufen",
  "errors.VOUCHER_ALREADY_REDEEMED": "Gutschein wurde für dieses Konto schon eingelöst",
  "errors.VOUCHER_DOES_NOT_EXISTS.voucherCode": "Dieser Gutschein existiert nicht",
  "errors.VOUCHER_MAXIMUM_REDEMPTION_EXCEEDED": "Dieser Gutschein-Code wurde bereits zu oft eingelöst, er ist nicht mehr gültig.",
  "errors.VOUCHER_MAXIMUM_REDEMPTION_EXCEEDED.voucherCode": "Dieser Gutschein-Code wurde bereits zu oft eingelöst, er ist nicht mehr gültig.",
  "footer.callSupport": "Telefon: <strong>:phone</strong>",
  "footer.happyToHelp": "Wir freuen uns immer dir zu helfen:",
  "footer.headline": "Fragen zur Bestellung?",
  "footer.legalNotice.activision_blizzard_1": "",
  "footer.legalNotice.allianz_1": "<p>Durch Bestellung des mySugr Pakets stimmst Du unseren <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">Allgemeinen Nutzungsbedingungen</a> zu. Solltest Du das mySugr Paket nicht mehr nutzen wollen kannst du dich jederzeit an <a href=\"mailto::email\">:email</a> wenden.</p>\n<p>Die mySugr App und mySugr Coach werden als Coupon zur Verfügung gestellt. Durch Anmeldung in der App stimmst du den <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">allgemeinen Geschäftsbedingungen</a> und der <a href=\":privacyPolicyUrl\" target=\"_blank\" rel=\"noopener\">Datenschutzerklärung</a> der mySugr GmbH zu.</p>",
  "footer.legalNotice.aok_1": "<p>Durch Bestellung des mySugr Pakets stimmst Du unseren <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">Allgemeinen Nutzungsbedingungen</a> zu. Solltest Du das mySugr Paket nicht mehr nutzen wollen kannst du dich jederzeit an <a href=\"mailto::email\">:email</a> wenden.</p>\n<p>Die mySugr App und mySugr Coach werden als Coupon zur Verfügung gestellt. Durch Anmeldung in der App stimmst du den <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">allgemeinen Geschäftsbedingungen</a> und der <a href=\":privacyPolicyUrl\" target=\"_blank\" rel=\"noopener\">Datenschutzerklärung</a> der mySugr GmbH zu.</p>",
  "footer.legalNotice.aok_nordwest_1": "<p>Durch Bestellung des mySugr Pakets stimmst Du unseren <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">Allgemeinen Nutzungsbedingungen</a> zu. Solltest Du das mySugr Paket nicht mehr nutzen wollen kannst du dich jederzeit an <a href=\"mailto::email\">:email</a> wenden.</p>\n<p>Die mySugr App und mySugr Coach werden als Coupon zur Verfügung gestellt. Durch Anmeldung in der App stimmst du den <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">allgemeinen Geschäftsbedingungen</a> und der <a href=\":privacyPolicyUrl\" target=\"_blank\" rel=\"noopener\">Datenschutzerklärung</a> der mySugr GmbH zu.</p>",
  "footer.legalNotice.barmenia_1": "<p>Durch Bestellung des mySugr Pakets stimmst Du unseren <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">Allgemeinen Nutzungsbedingungen</a> zu. Solltest Du das mySugr Paket nicht mehr nutzen wollen kannst du dich jederzeit an <a href=\"mailto::email\">:email</a> wenden.</p>\n<p>Die mySugr App und mySugr Coach werden als Coupon zur Verfügung gestellt. Durch Anmeldung in der App stimmst du den <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">allgemeinen Geschäftsbedingungen</a> und der <a href=\":privacyPolicyUrl\" target=\"_blank\" rel=\"noopener\">Datenschutzerklärung</a> der mySugr GmbH zu.</p>",
  "footer.legalNotice.barmer_1": "<p>Durch Bestellung des mySugr Pakets stimmst Du unseren <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">Allgemeinen Nutzungsbedingungen</a> zu. Solltest Du das mySugr Paket nicht mehr nutzen wollen kannst du dich jederzeit an <a href=\"mailto::email\">:email</a> wenden.</p>\n<p>Die mySugr App und mySugr Coach werden als Coupon zur Verfügung gestellt. Durch Anmeldung in der App stimmst du den <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">allgemeinen Geschäftsbedingungen</a> und der <a href=\":privacyPolicyUrl\" target=\"_blank\" rel=\"noopener\">Datenschutzerklärung</a> der mySugr GmbH zu.</p>",
  "footer.legalNotice.bi_state_development_1": "",
  "footer.legalNotice.buchanan_us_1": "",
  "footer.legalNotice.dkv_1": "<p>Durch Bestellung des mySugr Pakets stimmst Du unseren <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">Allgemeinen Nutzungsbedingungen</a> zu. Solltest Du das mySugr Paket nicht mehr nutzen wollen kannst du dich jederzeit an <a href=\"mailto::email\">:email</a> wenden.</p>\n<p>Die mySugr App und mySugr Coach werden als Coupon zur Verfügung gestellt. Durch Anmeldung in der App stimmst du den <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">allgemeinen Geschäftsbedingungen</a> und der <a href=\":privacyPolicyUrl\" target=\"_blank\" rel=\"noopener\">Datenschutzerklärung</a> der mySugr GmbH zu.</p>",
  "footer.legalNotice.ed_voyles_1": "",
  "footer.legalNotice.garfield_county_1": "",
  "footer.legalNotice.genentech_1": "",
  "footer.legalNotice.gothaer_1": "<p>Durch Bestellung des mySugr Pakets stimmst Du unseren <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">Allgemeinen Nutzungsbedingungen</a> zu. Solltest Du das mySugr Paket nicht mehr nutzen wollen kannst du dich jederzeit an <a href=\"mailto::email\">:email</a> wenden.</p>\n<p>Die mySugr App und mySugr Coach werden als Coupon zur Verfügung gestellt. Durch Anmeldung in der App stimmst du den <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">allgemeinen Geschäftsbedingungen</a> und der <a href=\":privacyPolicyUrl\" target=\"_blank\" rel=\"noopener\">Datenschutzerklärung</a> der mySugr GmbH zu.</p>",
  "footer.legalNotice.inter_1": "<p>Durch Bestellung des mySugr Pakets stimmst Du unseren <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">Allgemeinen Nutzungsbedingungen</a> zu. Solltest Du das mySugr Paket nicht mehr nutzen wollen kannst du dich jederzeit an <a href=\"mailto::email\">:email</a> wenden.</p>\n<p>Die mySugr App und mySugr Coach werden als Coupon zur Verfügung gestellt. Durch Anmeldung in der App stimmst du den <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">allgemeinen Geschäftsbedingungen</a> und der <a href=\":privacyPolicyUrl\" target=\"_blank\" rel=\"noopener\">Datenschutzerklärung</a> der mySugr GmbH zu.</p>",
  "footer.legalNotice.jbz_1": "",
  "footer.legalNotice.lazer_spot_1": "",
  "footer.legalNotice.mastec_1": "",
  "footer.legalNotice.mmc_1": "",
  "footer.legalNotice.optumrx_1": "",
  "footer.legalNotice.roche_ponce": "",
  "footer.legalNotice.roche_us_1": "",
  "footer.legalNotice.si_group_1": "",
  "footer.legalNotice.ukv_1": "<p>Durch Bestellung des mySugr Pakets stimmst Du unseren <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">Allgemeinen Nutzungsbedingungen</a> zu. Solltest Du das mySugr Paket nicht mehr nutzen wollen kannst du dich jederzeit an <a href=\"mailto::email\">:email</a> wenden.</p>\n<p>Die mySugr App und mySugr Coach werden als Coupon zur Verfügung gestellt. Durch Anmeldung in der App stimmst du den <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">allgemeinen Geschäftsbedingungen</a> und der <a href=\":privacyPolicyUrl\" target=\"_blank\" rel=\"noopener\">Datenschutzerklärung</a> der mySugr GmbH zu.</p>",
  "footer.legalNotice.vkb_1": "<p>Durch Bestellung des mySugr Pakets stimmst Du unseren <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">Allgemeinen Nutzungsbedingungen</a> zu. Solltest Du das mySugr Paket nicht mehr nutzen wollen kannst du dich jederzeit an <a href=\"mailto::email\">:email</a> wenden.</p>\n<p>Die mySugr App und mySugr Coach werden als Coupon zur Verfügung gestellt. Durch Anmeldung in der App stimmst du den <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">allgemeinen Geschäftsbedingungen</a> und der <a href=\":privacyPolicyUrl\" target=\"_blank\" rel=\"noopener\">Datenschutzerklärung</a> der mySugr GmbH zu.</p>",
  "footer.legalNotice.wth_1": "",
  "footer.links.imprint": "Impressum",
  "footer.links.privacyNotice": "Datenschutzerklärung",
  "footer.links.tcs": "AGB",
  "footer.subscriptionLegalNotice": "",
  "footer.writeSupport": "E-Mail: <a href=\"mailto::email\">:email</a>",
  "form.availableCountriesLabel": "Verfügbare Länder",
  "groupSelection.continue": "Los geht's",
  "groupSelection.items.description.alt_text.strips_subscription_guide.2": "<strong>Automatischer Teststreifennachschub</strong> basierend auf deinen importierten Messungen (keine Lanzetten enthalten)",
  "groupSelection.items.description.coach": "Zugang zu <strong>erstklassigen DiabetesberaterInnen</strong>",
  "groupSelection.items.description.guide": "Accu-Chek® Guide <strong>Blutzuckermessgerät</strong>",
  "groupSelection.items.description.guide_me_1": "Accu-Chek® Guide Me <strong>Blutzuckermessgerät</strong>",
  "groupSelection.items.description.pro": "Die <strong>mySugr App</strong> mit <strong>PRO-Funktionen</strong>",
  "groupSelection.items.description.scale_1": "",
  "groupSelection.items.description.strips_subscription_guide": "<strong>Unbegrenzt Teststreifen</strong> nach Hause geliefert, anhand deines tatsächlichen Verbrauchs (importierte Blutzuckerwerte in der App)",
  "groupSelection.items.description.strips_subscription_guide.2": "<strong>Unbegrenzt Teststreifen</strong> nach Hause liefern lassen, basierend auf den importierten Messungen",
  "groupSelection.message.reimbursement.linkText": "mehr Info",
  "groupSelection.message.reimbursement.linkText.dvg": "Finde hier mehr heraus",
  "groupSelection.message.reimbursement.text": "Rückerstattung bis zu 100%",
  "groupSelection.message.reimbursement.text.dkv": "Das mySugr Paket",
  "groupSelection.message.reimbursement.text.dvg": "Wusstest du, dass du dir die mySugr PRO App verschreiben lassen kannst?",
  "groupSelection.message.roche_bundle_group_coach_1": "Gratis mit deinem Roche Leistungsplan",
  "groupSelection.message.roche_bundle_group_guide_1": "Gratis mit deinem Roche Leistungsplan",
  "groupSelection.moreInfo": "MEHR DETAILS ANZEIGEN",
  "groupSelection.titles.allianz_bundle_group_coach_2": "mySugr Coach",
  "groupSelection.titles.allianz_bundle_group_guide_3": "mySugr Paket",
  "groupSelection.titles.allianz_bundle_group_guide_4": "mySugr Paket",
  "groupSelection.titles.barmenia_bundle_group_coach_2": "mySugr Coach",
  "groupSelection.titles.barmenia_bundle_group_guide_2": "mySugr Paket",
  "groupSelection.titles.barmenia_bundle_group_guide_3": "mySugr Paket",
  "groupSelection.titles.bundle": "mySugr Paket",
  "groupSelection.titles.coach": "mySugr Coach",
  "groupSelection.titles.gothaer_bundle_group_coach_2": "mySugr Coach",
  "groupSelection.titles.gothaer_bundle_group_guide_3": "mySugr Paket",
  "groupSelection.titles.pro": "mySugr PRO",
  "groupSelection.titles.roche_bundle_group_coach_1": "mySugr Coach",
  "groupSelection.titles.roche_bundle_group_guide_1": "mySugr Bundle",
  "groupSelection.titles.ukv_bundle_group_coach_2": "mySugr Coach",
  "groupSelection.titles.ukv_bundle_group_guide_3": "mySugr Paket",
  "groupSelection.titles.vkb_bundle_group_coach_2": "mySugr Coach",
  "groupSelection.titles.vkb_bundle_group_guide_3": "mySugr Paket",
  "groupSelectionPage.priceFree": "Gratis",
  "groupSelectionPage.priceFreeExtra": "für dich",
  "groupSelectionPage.title": "Wähle ein mySugr Programm",
  "header.heart.altText": "Herz",
  "headerNotice.bundleNotAvailable": "Eine Anmeldung ist leider nicht mehr möglich, da dieses Paket nicht mehr verfügbar ist.",
  "headerNotice.bundleNotAvailable.barmer_1": "Eine neue Anmeldung ist leider nicht mehr möglich, da unsere Kooperation mit der BARMER ausläuft.",
  "headerNotice.infoNotice.linkText.us_bundle_3": "",
  "headerNotice.infoNotice.teaser.us_bundle_3": "",
  "headerNotice.infoNotice.text.us_bundle_3": "",
  "Help": "Hilfe",
  "homepage.bundle.text": "Wähle dein Land aus, um zu prüfen, welche <b>mySugr Pakete</b> verfügbar sind:",
  "homepage.bundle.text.us": "Derzeit ist das mySugr Bundle nur in den <b>USA</b> verfügbar.",
  "homepage.bundle.title": "mySugr Paket",
  "homepage.countries.defaultValue": "Wähle ein Land aus",
  "homepage.pro.infoText": "Die <b>DVG PRO App</b> ist nur in Deutschland verfügbar.",
  "homepage.pro.moreInfo": "mehr Info",
  "homepage.pro.moreInfo.dvg": "Mehr erfahren",
  "homepage.pro.text": "Um deinen Gutschein für die <strong>mySugr PRO App</strong> einzulösen:",
  "homepage.pro.title": "mySugr PRO App",
  "homepage.text": "Willkommen im mySugr Shop!",
  "insuranceCardForm.groupNumberLabel": "Versicherungsgruppennummer",
  "insuranceCardForm.groupNumberLabel.edvoyles": "",
  "insuranceCardForm.groupNumberLabel.garfieldcounty": "",
  "insuranceCardForm.groupNumberLabel.mastec-1": "",
  "insuranceCardForm.groupNumberLabel.roche-benefits-us": "",
  "insuranceCardForm.groupNumberLabel.roche-benefits-us-coach": "",
  "insuranceCardForm.groupNumberPlaceholder": "000123",
  "insuranceCardForm.groupNumberPlaceholder.ActivisionBlizzard": "W1234567",
  "insuranceCardForm.groupNumberPlaceholder.buchanan-group-us": "000ABC123",
  "insuranceCardForm.groupNumberPlaceholder.edvoyles": "000ABC123",
  "insuranceCardForm.groupNumberPlaceholder.garfieldcounty": "",
  "insuranceCardForm.groupNumberPlaceholder.lazerspot": "000ABC123",
  "insuranceCardForm.groupNumberPlaceholder.mastec-1": "PHRASE01",
  "insuranceCardForm.infoTextGeneric": "Bitte gib deine Versicherungsgruppennummer und deine Mitgliedsnummer so ein, wie sie auf deiner Mitgliedskarte erscheinen.",
  "insuranceCardForm.infoTextGeneric.ActivisionBlizzard": "",
  "insuranceCardForm.infoTextGeneric.garfieldcounty": "",
  "insuranceCardForm.infoTextGeneric.kaiser.roche-benefits-us": "",
  "insuranceCardForm.infoTextGeneric.mastec-1": "",
  "insuranceCardForm.infoTextGeneric.roche-benefits-us-coach": "",
  "insuranceCardForm.memberIdLabel": "Mitgliedsnummer",
  "insuranceCardForm.memberIdLabel.ActivisionBlizzard": "",
  "insuranceCardForm.memberIdLabel.kaiser.roche-benefits-us": "",
  "insuranceCardForm.memberIdLabel.roche-benefits-us": "",
  "insuranceCardForm.memberIdLabel.roche-benefits-us-coach": "",
  "insuranceCardForm.memberIdPlaceholder": "123456789",
  "insuranceCardForm.memberIdPlaceholder.ActivisionBlizzard": "AZO 12345678X",
  "insuranceCardForm.memberIdPlaceholder.buchanan-group-us": "",
  "insuranceCardForm.memberIdPlaceholder.edvoyles": "",
  "insuranceCardForm.memberIdPlaceholder.garfieldcounty": "",
  "insuranceCardForm.memberIdPlaceholder.kaiser.roche-benefits-us": "",
  "insuranceCardForm.memberIdPlaceholder.lazerspot": "ABC12345678C",
  "insuranceCardForm.memberIdPlaceholder.mastec": "ABC0123456789",
  "insuranceNumberForm.label": "Versicherungsnummer",
  "insuranceNumberForm.overlayButtonText": "mehr Info",
  "introButtonLabelCreateAccount": "Account erstellen",
  "languages.de": "Deutsch",
  "languages.en": "English",
  "languages.es": "Español",
  "languages.fr": "Französisch",
  "languages.nl": "Nederlands",
  "languageSelectLabel": "Spracheinstellungen ändern",
  "login.pageName": "Login",
  "logoText.mySugr": "mySugr Programm",
  "logoText.payor": "in Zusammenarbeit mit :payor",
  "measurementUnitSamples.mg/dl": "70 - 180",
  "measurementUnitSamples.mmol/l": "3.9 - 8.9",
  "more.info.overlay.linkText": "mehr Info",
  "notFound.lead": "Wonach du suchst, existiert nicht mehr!",
  "notFound.text": "Die Seite, die du suchst, existiert nicht mehr oder wurde verschoben. Bitte <a href=\"mailto::email\">kontaktiere unser Team</a>, wenn du Hilfe benötigst.",
  "notFound.title": "Seite ausgestorben",
  "notifications.CARD_DECLINED": "Deine Karte wurde abgelehnt.",
  "notifications.dismissNotification": "Benachrichtigung schließen",
  "notifications.EXPIRED_CARD": "Deine Karte ist abgelaufen",
  "notifications.IE11_NOT_SUPPORTED": "Dein Browser (Internet Explorer 11) wird derzeit nicht unterstützt.",
  "notifications.INCORRECT_CVC": "CVC ist nicht korrekt",
  "notifications.INCORRECT_NUMBER": "Kreditkartennummer ist nicht korrekt",
  "notifications.INSUFFICIENT_FUNDS": "Deine Karte scheint nicht ausreichend gedeckt zu sein",
  "notifications.PROCESSING_ERROR": "Wir konnten deine Karte nicht belasten",
  "notifications.SERVER_ERROR": "Fehler beim Aufrufen des mySugr Servers",
  "notifications.STRIPE_ERROR": "Fehler beim Aufrufen des Bezahl Servers",
  "notifications.TRIGGER_EMAIL_VERIFICATION_SUCCESS": "E-Mail wurde gesendet",
  "notifications.UNDEFINED_ERROR": "Sorry, etwas ist schiefgelaufen",
  "notifications.UNKNOWN_ERROR": "Ups, da ist was schief gegangnen",
  "notifications.UNKNOWN_ERROR_2": "Hoppla, etwas ist schiefgelaufen",
  "notifications.USER_ALREADY_SUBSCRIBED_TO_BUNDLE": "Du hast bereits ein mySugr Paket Abo",
  "notifications.USER_ALREADY_SUBSCRIBED_TO_PLAN": "Du hast bereits ein mySugr Paket Abo",
  "numbers.0": "null",
  "numbers.1": "eins",
  "numbers.2": "zwei",
  "numbers.3": "drei",
  "numbers.4": "vier",
  "numbers.5": "fünf",
  "numbers.6": "sechs",
  "numbers.7": "sieben",
  "numbers.8": "acht",
  "numbers.9": "neun",
  "numbers.10": "zehn",
  "offlinePage.subtitle": "Bitte überprüfe deine Internetverbindung",
  "offlinePage.title": "Du bist offline",
  "optionalConsent": "Optional:",
  "optionalPlaceholder": "Optional",
  "overlay.close": "Schließen",
  "overview.address.phone": "Telefon:",
  "overview.autoCancelInfo.DAY.1": "Das Paket endet automatisch nach dem ersten Tag.",
  "overview.autoCancelInfo.DAY.2": "Das Paket endet automatisch nach :count Tagen.",
  "overview.autoCancelInfo.MONTH.1": "Das Paket endet automatisch nach dem ersten Monat.",
  "overview.autoCancelInfo.MONTH.2": "Das Paket endet automatisch nach :count Monaten.",
  "overview.autoCancelInfo.WEEK.2": "",
  "overview.autoCancelInfo.YEAR.1": "Das Paket endet automatisch nach dem ersten Jahr.",
  "overview.autoCancelInfo.YEAR.2": "Das Paket endet automatisch nach :count Jahren.",
  "overview.backButton": "Zurück",
  "overview.billingAddress.edit": "Ändern",
  "overview.billingAddress.title": "Rechnung",
  "overview.cancelationInfo.MONTH.1": "Das Paket verlängert sich automatisch nach einem Monat, und kann vor Ablauf dieser Frist gekündigt werden",
  "overview.cancelationInfo.MONTH.2": "Das Paket verlängert sich automatisch alle :count Monate, und kann vor Ablauf dieser Frist gekündigt werden",
  "overview.cancelationInfo.YEAR.1": "Das Paket verlängert sich automatisch nach einem Jahr, und kann vor Ablauf dieser Frist gekündigt werden",
  "overview.cancelationInfo.YEAR.2": "Das Paket verlängert sich automatisch alle :count Jahre, und kann vor Ablauf dieser Frist gekündigt werden",
  "overview.coachLanguageOverview.change": "Ändern",
  "overview.coachLanguageOverview.title": "Sprache für Coach",
  "overview.contactDetails.edit": "Ändern",
  "overview.contactDetails.title": "Kontaktinformationen",
  "overview.continueButton": "Bestellen",
  "overview.continueButton.at": "Bestellen",
  "overview.continueButton.at.fallback": "Bestellen",
  "overview.continueButton.ca.fallback": "",
  "overview.continueButton.de": "Bestellen",
  "overview.continueButton.de.fallback": "Bestellen",
  "overview.continueButton.es": "Bestellen",
  "overview.continueButton.es.fallback": "Bestellen",
  "overview.continueButton.nl.fallback": "Abonnieren",
  "overview.continueButton.sg.fallback": "Abonnieren",
  "overview.continueButton.us": "Abonnieren",
  "overview.continueButton.us.fallback": "Abonnieren",
  "overview.continueButton.us.optumrx_bundle_1": "Abonnieren",
  "overview.continueButton.us.us_bundle_3": "Abonnieren",
  "overview.continueButton.za.fallback": "Abonnieren",
  "overview.continueButton.za.south-africa_affiliate_(coaching_bundle)_2": "Abonnieren",
  "overview.enrollmentTitle": "Für das Paket anmelden",
  "overview.freeShipping": "Gratis Versand",
  "overview.measurementUnitOverview.change": "Ändern",
  "overview.measurementUnitOverview.mgdl": "mg/dL",
  "overview.measurementUnitOverview.mmoll": "mmol/L",
  "overview.measurementUnitOverview.title": "Messeinheit",
  "overview.pageName": "Bestellübersicht",
  "overview.paymentOverview.change": "Ändern",
  "overview.paymentOverview.creditCard": "Kreditkarte",
  "overview.paymentOverview.payment": "Bezahlung",
  "overview.payorCovered": "Die Kosten für dein mySugr Paket werden von deiner Versicherung übernommen.",
  "overview.payorCovered.aok_nordwest_bundle_1": "„<b>ComanD - control and manage Diabetes</b>“ ist ein ärztliches Versorgungsprogramm für erstdiagnostizierte Patienten mit Diabetes mellitus Typ 2. Nach Rücksprache mit Ihrem Arzt beinhaltet Ihre kostenlose Teilnahme die mySugr App, das Accu-Chek Guide Messgerät, 110 Teststreifen, 96 Lanzetten und eine persönliche Ernährungsberatung.",
  "overview.payorCovered.dec_i_bundle_1": "",
  "overview.payorCovered.dec_ii_bundle_1": "",
  "overview.payorCovered.onemed_bundle_1": "",
  "overview.payorCovered.optumrx_bundle_1": "",
  "overview.payorCovered.servidigest_bundle_1": "",
  "overview.payorCovered.us": "",
  "overview.payorCovered.us.activision_blizzard_bundle_1": "",
  "overview.payorCovered.us.bi_state_development_bundle_1": "Die Kosten für dein mySugr Paket werden von deinem Arbeitgeber übernommen.",
  "overview.payorCovered.us.buchanan_bundle_2": "Die Kosten für dein mySugr Paket werden von deinem Arbeitgeber übernommen.",
  "overview.payorCovered.us.dhp_roche_ponce_bundle_1": "",
  "overview.payorCovered.us.garfield_county_bundle_1": "",
  "overview.payorCovered.us.genentech_bundle_1": "",
  "overview.payorCovered.us.lazer_spot_bundle_1": "",
  "overview.payorCovered.us.mastec_bundle_1": "",
  "overview.payorCovered.us.mysugr_ed_voyles_bundle_1": "",
  "overview.payorCovered.us.roche_bundle_us_1": "Die Kosten für dein mySugr Paket werden von deinem Arbeitgeber übernommen.",
  "overview.payorCovered.us.roche_bundle_us_2": "Die Kosten für dein mySugr Paket werden von deinem Arbeitgeber übernommen.",
  "overview.payorCovered.us.si_group_bundle_1": "",
  "overview.payorCovered.zdg_bundle_1": "Die Kosten für dein mySugr Paket werden von der Zukunftsregion Digitale Gesundheit übernommen",
  "overview.seller": "Verkäufer",
  "overview.shipmentAddress.edit": "Ändern",
  "overview.shipmentAddress.title": "Lieferung",
  "overview.shipmentOverview.edit": "Ändern",
  "overview.shipmentOverview.title": "Lieferung",
  "overview.stepName": "Überblick",
  "overview.subscribeNotice": "Durch Klicken auf den obenstehenden Button bestätigst du, dass du über :minAge Jahre alt bist.",
  "overview.title": "Fast geschafft",
  "pageTitle": "mySugr Shop",
  "pageTitle.flowTitle.withPayor": "mySugr und :payorName",
  "pageTitle.flowTitle.withProductAndPayor": ":productName und :payorName",
  "pageTitle.withFlow": ":flowTitle",
  "pageTitle.withStep": ":stepTitle | mySugr Shop",
  "pageTitle.withStepAndFlow": ":stepTitle | :flowTitle",
  "passwordInput.hidePasswordButton": "Verstecken",
  "passwordInput.showPasswordButton": "Zeigen",
  "passwordReset.pageName": "Passwort zurücksetzen",
  "passwordResetSuccessPage.buttonText": "Weiter",
  "passwordResetSuccessPage.congratsImageAlt": "Glückwunsch, Konfetti fliegt herum,",
  "passwordResetSuccessPage.text": "Bitte denk daran, dass sich das Passwort für die mySugr App ebenfalls geändert hat.",
  "passwordResetSuccessPage.title": "Du hast dein Passwort geändert!",
  "payment.cardInfo.backButton": "Zurück",
  "payment.cardInfo.changeCard": "Kreditkarte ändern",
  "payment.cardInfo.continueButton": "Weiter",
  "payment.cardInfo.discardButton": "Änderungen verwerfen",
  "payment.cardInfo.expires": "Gültig bis :month/:year",
  "payment.cardInfo.usingText": "Du bezahlst mit der Karte <strong>:last4</strong>.",
  "payment.form.backButton": "Zurück",
  "payment.form.cardNumberLabel": "Kreditkartennummer",
  "payment.form.continueButton": "Weiter",
  "payment.form.cvcLabel": "CVC",
  "payment.form.discardButton": "Änderungen verwerfen",
  "payment.form.expirationLabel": "Gültig bis",
  "payment.form.firstNameLabel": "Vorname",
  "payment.form.lastNameLabel": "Nachname",
  "payment.form.nameLabel": "Name",
  "payment.form.sameAsShipment": "Rechnungsadresse ist gleich wie die Lieferadresse",
  "payment.form.saveChangesButton": "Änderungen speichern",
  "payment.stepName": "Bezahlung",
  "payment.title": "Bezahlung",
  "paymentDetails.list.info": "Diese Karte wird regelmäßig für dein Paket-Abonnement belastet.",
  "paymentDetails.list.title": "Zahlungsdaten",
  "paymentDetails.login.title": "Logge dich ein, um deine Zahlungsinformationen zu ändern",
  "paymentLogos.title": "Unterstützte Zahlungsmethoden:",
  "product.bundleBoxImage.alt": "mySugr Paket",
  "product.bundleBoxImage.alt.bundle_coach": "Das mySugr Paket: Eine Box mit dem mySugr Logo, umgeben vom Inhalt des Pakets. Die mySugr App ist auf einem Telefon geöffnet, darüber sieht man eine Chat-Sprechblase.",
  "product.bundleBoxImage.alt.bundle_guide": "Das mySugr Paket: Eine Box mit dem mySugr Logo, umgeben vom Inhalt des Pakets.",
  "product.bundleBoxImage.alt.bundle_guide_me": "Das mySugr Paket: Eine Box mit dem mySugr Logo, umgeben vom Inhalt des Pakets.",
  "product.bundleBoxImage.alt.coach": "Darstellung eines Telefons mit geöffneter mySugr-App und einer Chatblase neben dem Telefon, getrennt durch ein Pluszeichen.",
  "product.bundleBoxImage.alt.pro": "Ein Telefon mit einem Stern in der Hand, auf dem das Wort „Pro“ steht",
  "product.continueButton": "Weiter",
  "product.items.moreInfo.dvg": "mehr Info",
  "product.items.overlayText.dvg": "<h4 data-icon=\"file\">PDF- und Excel-Berichte</h4> \n<p>Verschaff dir einen Überblick über deine Daten und teile sie mit deinem Arzt.</p> \n\n<h4 data-icon=\"log\">Logging leicht gemacht</h4> \n<p>Lade Fotos von deinen Mahlzeiten hoch, setze Erinnerungen für Messungen nach den Mahlzeiten und verwende alle möglichen Tags.</p> \n\n<h4 data-icon=\"search\">Intelligente Suche</h4> \n<p>Erkenne einfach Muster in deinen Log-Einträgen.</p> \n\n<h4 data-icon=\"badge\">Herausforderungen, die dich stark machen</h4> \n<p>Aktiviere alle Herausforderungen, steigere deine Motivation und erreiche deine Ziele.</p> \n\n<h4 data-icon=\"bs-meter\">Verbindung mit mehreren Blutzuckermessgeräten</h4> \n<p>Verbinde mehrere Messgeräte und automatisiere den Datenimport. Schnell und unkompliziert.</p>",
  "product.stepName": "Produktinfo",
  "product.submitButton": "Aktivieren",
  "product.title": "Das mySugr Paket",
  "productName.bundle": "mySugr Paket",
  "productName.coach": "mySugr Coach",
  "promotion.APRILFOOLS2019_PROMOTION_PRICE_TEXT": "jedes Monat danach",
  "promotion.APRILFOOLS2019_PROMOTION_TEXT": "1 Monat gratis",
  "proVoucher.pageName": "PRO Voucher einlösen",
  "proVoucherInfo.checklist.challenges": "Nimm an <strong>Herausforderungen</strong> teil",
  "proVoucherInfo.checklist.pdf": "<strong>PDF</strong> Berichte",
  "proVoucherInfo.checklist.photos": "<strong>Mache Fotos</strong> von deinen Mahlzeiten",
  "proVoucherInfo.checklist.reminders": "Erhalte automatische <strong>Erinnerungen</strong>",
  "proVoucherInfo.checklist.search": "<strong>Suche</strong> - Finde deine Einträge ganz einfach",
  "proVoucherInfo.checklist.sync": "Synchronisiere <strong>mehrere Geräte</strong>",
  "proVoucherInfo.ctaButtonText": "Gutschein jetzt einlösen",
  "proVoucherInfo.headline": "Dein Gutschein wird aktiviert …",
  "proVoucherInfo.imageTitle": "mySugr PRO",
  "proVoucherInfo.stepName": "PRO Voucher",
  "proVoucherInfo.validDate": "Die Funktionen werden bis zum <strong>:date</strong> freigeschaltet.",
  "proVoucherInfo.validTimeMonths": "Die Funktionen werden für <strong>:months Monate</strong> freigeschaltet.",
  "proVoucherInfo.validTimeUnlimited": "Du bekommst <strong>unbegrenzten</strong> Zugang zu allen Funktionen.",
  "proVoucherInfo.voucherCodeValid": "Dein Gutscheincode <code>:voucherCode</code> ist gültig.",
  "RDRegistration_description": "Verwende dein RocheDiabetes-Konto für mySugr.",
  "RDRegistration_mySugr_description": "Wusstest du schon, dass mySugr jetzt ein Teil von RocheDiabetes ist? **Mehr erfahren**",
  "RDSignIn_description": "Du hast schon einen Account? Super!",
  "readMoreBundleContentInformation": "Erfahre mehr über den Inhalt des Pakets",
  "register.pageName": "Registrierung",
  "reimbursement.overlay.text": "<h4>Wie viel bekomme ich erstattet?</h4>\n<p>Genauso viel wie für den Kauf deiner Teststreifen in der Apotheke. Das sind je nach Versicherungstarif bis zu 100 %.</p>\n\n<h4>Die Erstattung ist ganz einfach:</h4>\n<p>Wir senden dir nach dem Kauf deine Rechnung per E-Mail zu. Diese kannst du ausdrucken und zusammen mit dem Leistungsantrag wie gewohnt an deine Versicherung schicken.</p>",
  "reimbursement.overlay.text.barmenia": "<h4>Wie viel bekomme ich erstattet?</h4>\n<p>Genauso viel wie für den Kauf deiner Diabetes-Verbrauchsmaterialien. Das sind je nach Versicherungstarif bis zu 100 %.</p>\n\n<h4>Die Erstattung ist ganz einfach:</h4>\n<p>Wir senden dir nach dem Kauf deine Rechnung per E-Mail zu. Diese kannst du ausdrucken und zusammen mit dem Leistungsantrag wie gewohnt an deine Versicherung schicken.</p>\n<p>Oder nutze die „RechnungsApp“ der Barmenia und lade ein Foto deiner Rechnung hoch. Dann bekommst du dein Geld noch schneller erstattet!</p>",
  "reimbursement.overlay.text.dkv": "<h4>Unmittelbar verfügbar:</h4>\n<ul>\n\t<li>die PRO-Version der mySugr Tagebuch-App</li>\n\t<li>die Kontaktdaten deines persönlichen Coaches</li>\n</ul>\n\n<h4>Das Starter-Paket kommt per Post und enthält:</h4>\n<ul>\n\t<li>bei Bedarf - das Accu-Chek© Guide Blutzuckermessgerät inkl. Zubehör</li>\n\t<li>eine Anleitung zur Einrichtung der App und ggf. des Blutzuckermessgeräts</li>\n\t<li>Frage- und Befundbögen für den Einstieg in das individuelle Coaching</li>\n</ul>\n\n<h4>Laufende Versorgung mit:</h4>\n<ul>\n\t<li>individueller Beratung durch medizinisch ausgebildete Coaches</li>\n\t<li>unveränderter Erstattung der Teststreifen und Lanzetten nach ärztlicher Verordnung</li>\n\t<li>regelmäßigen Informationsunterlagen rund um das Thema Diabetes</li>\n</ul>",
  "reimbursement.overlay.text.gothaer": "<h4>Wie viel bekomme ich erstattet?</h4>\n<p>Genauso viel wie für den Kauf deiner Diabetes-Verbrauchsmaterialien. Das sind je nach Versicherungstarif bis zu 100 %.</p>\n\n<h4>Die Erstattung ist ganz einfach:</h4>\n<p>Wir senden dir nach dem Kauf deine Rechnung per E-Mail zu. Diese kannst du ausdrucken und zusammen mit dem Leistungsantrag wie gewohnt an deine Versicherung schicken.</p>\n<p>Oder nutze die „GesundheitsApp“ der Gothaer und lade ein Foto deiner Rechnung hoch. Dann bekommst du dein Geld noch schneller erstattet!</p>",
  "reimbursement.overlay.text.ukv": "<h4>Wie viel bekomme ich erstattet?</h4>\n<p>Genauso viel wie für den Kauf deiner Teststreifen in der Apotheke. Das sind je nach Versicherungstarif bis zu 100 %.</p>\n\n<h4>Die Erstattung ist ganz einfach:</h4>\n<p>Wir senden dir nach dem Kauf deine Rechnung per E-Mail zu. Diese kannst du ausdrucken und zusammen mit dem Leistungsantrag wie gewohnt an deine Versicherung schicken.</p>\n<p>Oder nutze die App der UKV „Rundum Gesund“ und lade ein Foto deiner Rechnung hoch. Dann bekommst du dein Geld noch schneller erstattet!</p>",
  "reimbursement.overlay.text.vkb": "<h4>Wie viel bekomme ich erstattet?</h4>\n<p>Genauso viel wie für den Kauf deiner Teststreifen in der Apotheke. Das sind je nach Versicherungstarif bis zu 100 %.</p>\n\n<h4>Die Erstattung ist ganz einfach:</h4>\n<p>Wir senden dir nach dem Kauf deine Rechnung per E-Mail zu. Diese kannst du ausdrucken und zusammen mit dem Leistungsantrag wie gewohnt an deine Versicherung schicken.</p>\n<p>Oder nutze die App der VKB „Rundum Gesund“ und lade ein Foto deiner Rechnung hoch. Dann bekommst du dein Geld noch schneller erstattet!</p>",
  "reimbursement.overlay.title": "Das mySugr Paket",
  "reimbursement.overlay.title.allianz_bundle_group_coach_2": "Das Diabetes-Coaching Paket",
  "reimbursement.overlay.title.barmenia_bundle_group_coach_2": "Das Diabetes-Coaching Paket",
  "reimbursement.overlay.title.bundle": "Das mySugr Paket",
  "reimbursement.overlay.title.coach": "Das Diabetes-Coaching Paket",
  "reimbursement.overlay.title.coach.dkv": "Ihr mySugr Paket der DKV enthält:",
  "reimbursement.overlay.title.gothaer_bundle_group_coach_2": "Das Diabetes-Coaching Paket",
  "reimbursement.overlay.title.ukv_bundle_group_coach_2": "Das Diabetes-Coaching Paket",
  "reimbursement.overlay.title.vkb_bundle_group_coach_2": "Das Diabetes-Coaching Paket",
  "selectCountry.selectCountryLabel": "Land auswählen",
  "selectPayor.otherInsuranceButton": "In Warteliste eintragen",
  "selectPayor.otherInsuranceExplanation": "Wir arbeiten daran, dass alle Krankenkassen die Kosten für das mySugr Paket übernehmen. Bitte trage dich hier ein und wir informieren dich sobald deine Kasse das mySugr Paket übernimmt.",
  "selectPayor.pleaseSelect": "Bitte wähle <strong>deine Krankenkasse</strong> aus.",
  "selectPayor.title": "Diese Krankenkassen übernehmen die Kosten für das mySugr Paket in Deutschland",
  "shipment.contactDataForCoaches": "Deine Kontaktdaten werden nur deinem persönlichen Coach zur Verfügung gestellt.",
  "shipment.form.address2Label": "Adresszusatz",
  "shipment.form.addressHint": "Keine Packstationen",
  "shipment.form.addressLabel": "Adresse",
  "shipment.form.addressPlaceholder.germany": "Straße Nummer/Stiege/Tür",
  "shipment.form.backButton": "Zurück",
  "shipment.form.billingAddressTitle": "Rechnungsadresse",
  "shipment.form.birthdateCheckboxLabel": "Ich bestätige, dass ich :minAge+ Jahre alt bin und bei mir Diabetes diagnostiziert wurde.",
  "shipment.form.birthdateHint": "Der Nutzer muss :minAge+ Jahre alt sein.",
  "shipment.form.birthdateOverlayText": "<p>Das mySugr Paket ist nur für Menschen mit Diabetes ab einem Alter von :minAge erhältlich.</p><p>Die folgenden Kriterien müssen bei der Anmeldung zum mySugr Paket erfüllt sein:</p><ol><li>Für die Person, die das mySugr Paket abonniert und verwendet, muss eine Diabetesdiagnose vorliegen <strong>UND</strong> sie muss mindestens :minAge+ Jahre alt sein.</li><li>Der Abonnent muss auch der Nutzer sein. Es kann kein Abo für eine andere Person abgeschlossen werden (Eltern dürfen z. B. nicht ihre Kinder für das mySugr Paket anmelden).</li></ol><p>Jede Nichterfüllung der oben genannten Kriterien führt zur Kündigung des mySugr Pakets. Weitere Informationen sind in unseren <a href=\":tcsUrl\" target=\"_blank\" rel=\"noopener\">Geschäftsbedingungen</a> enthalten.</p>",
  "shipment.form.cityLabel": "Stadt/Ort",
  "shipment.form.continueButton": "Weiter",
  "shipment.form.discardChangesButton": "Änderungen verwerfen",
  "shipment.form.emailLabel": "E-Mail",
  "shipment.form.employerDefaultOption": "Wähle einen Arbeitgeber",
  "shipment.form.employerHint": "Which organization do you receive your health benefits through?",
  "shipment.form.employerLabel": "Arbeitgeber",
  "shipment.form.firstNameLabel": "Vorname",
  "shipment.form.lastNameLabel": "Nachname",
  "shipment.form.memberClass.dependentLabel": "Angehörige_r",
  "shipment.form.memberClass.employeeLabel": "Mitarbeiter_in",
  "shipment.form.memberClass.explanation": "Verhältnis zur Versicherungsnehmer_in",
  "shipment.form.phoneLabel": "Telefonnummer",
  "shipment.form.policy.headline": "Information zur Versicherung",
  "shipment.form.postalCodeLabel": "Postleitzahl",
  "shipment.form.postalCodeLabel_new": "Postleitzahl",
  "shipment.form.saveChangesButton": "Änderungen speichern",
  "shipment.form.stateDefaultOption": "Bitte auswählen",
  "shipment.form.stateLabel": "Bundesland",
  "shipment.form.zipCodeLabel": "Postleitzahl",
  "shipment.stepName": "Lieferadresse",
  "shipment.title": "Deine <strong>Lieferadresse</strong>",
  "shoppingCart.deliverOnce": "(einmalige Lieferung)",
  "shoppingCart.immediateTitle": "Unmittelbar verfügbar:",
  "shoppingCart.productTitle": "Dein Startpaket kommt per Post:",
  "shoppingCart.subscriptionTitle": "Laufend versorgt mit:",
  "shoppingCart.title": "Dein Paket enthält:",
  "shoppingCart.title.dvg": "Ihr Programm umfasst:",
  "shoppingCart.title.roche-benefits-us": "Dein Programm beinhaltet:",
  "shoppingCart.title.roche-benefits-us-coach": "Dein Programm beinhaltet:",
  "SignIn": "Anmelden",
  "SignInMySugr": "Mit mySugr ID anmelden",
  "stepBar.activeStep.screenReader": "(aktiver Schritt)",
  "stepBar.completedStep.screenReader": "(abgeschlossener Schritt)",
  "stepBar.notCompletedStep.screenReader": "(nicht abgeschlossener Schritt)",
  "stepBar.remaining": "Nur noch <strong>:remaining Schritte</strong> bis zu deinem Paket!",
  "stepBar.title.screenReader": "Erforderliche Schritte um dich für das mySugr Paket anzumelden",
  "stepIndicationText": "Schritt :current von :total",
  "success.coach.text": "Genaugenommen findest du die erste Nachricht von deinem Coach schon in der App.",
  "success.coach.title": "Sag <b>„Hallo“</b> zu deinem <b>persönlichen Coach</b>.",
  "success.doneButton": "Fertig",
  "success.download.bundle.text": "<b>Synchronisiere</b> deine <b>täglichen Messungen</b>, damit wir wissen, wie viele Teststreifen du verbraucht hast.",
  "success.download.coach.text": "<b>Synchronisiere</b> deine <b>täglichen Messungen</b> und behalte den Überblick über deine Diabetes-Daten.",
  "success.download.title": "Als nächstes <b>lade</b> die mySugr <b>App</b> herunter",
  "success.help": "Solltest du noch Fragen haben, zögere nicht, unser wunderbares Support-Team zu kontaktieren.",
  "success.nextStep1": "<strong>Lade</strong> die mySugr <strong>App</strong> auf dein Smartphone",
  "success.nextStep2": "<strong>Melde dich</strong> mit der E-Mail-Adresse <strong>:email</strong> und dem zugehörigen <strong>Passwort</strong> an.",
  "success.nextStep3": "<strong>Fertig!</strong>",
  "success.orderSuccess.coachText": "Folge den nächsten Schritten, um mit deinem Coach in Kontakt zu treten.",
  "success.orderSuccess.deliveryText": "Du erhältst in Kürze eine E-Mail mit den Details zu deiner ersten Lieferung.\n<b>Alle weiteren Lieferungen werden durch die App ausgelöst.</b>",
  "success.orderSuccess.title": "Bestellung <b>abgeschlossen</b>",
  "success.pageFooterTitle": "Befolge diese einfachen Schritte und bleibe auf dem Laufenden!",
  "success.pageName": "Bestellung erfolgreich",
  "success.payment.change": "Karte erfolgreich aktualisiert!",
  "success.proVoucherInfo.validDate": "Die Funktionen werden bis zum <strong>:date</strong> freigeschaltet.",
  "success.proVoucherInfo.validTimeMonths": "Die PRO-Funktionen wurden für <strong>:months Monate</strong> freigeschaltet.",
  "success.proVoucherInfo.validTimeUnlimited": "Du hast jetzt <strong>unbegrenzten</strong> Zugang zu allen PRO-Funktionen.",
  "success.proVoucherInfo.validTimeUnlimited.dvg": "Du hast jetzt Zugang zu allen PRO-Funktionen.",
  "success.shippingInfo": "Dein mySugr Paket sollte in den <strong>nächsten 14 Werktagen</strong> bei dir ankommen.",
  "success.shippingInfoDynamic": "Dein mySugr Paket sollte in den <strong>nächsten :time Werktagen</strong> bei dir ankommen.",
  "success.shippingMoreInfo": "Sobald du das mySugr Paket erhalten hast, verbinde dein Messgerät mit der App und beginne deine Messungen zu synchronisieren. Stell sicher, dass <b>dein Messgerät immer mit der App verbunden ist.</b> Nur so können wir wissen, wann wir dir <b>weitere Teststreifen</b> senden müssen.",
  "success.stepName": "Fertig!",
  "success.title": "Deine Bestellung wurde <strong>erfolgreich abgeschlossen!</strong>",
  "success.titleProOnly": "Glückwunsch,<br> du bist jetzt ein <strong>PRO User</strong>!",
  "success.trackingInfo": "Du bekommst in den nächsten zwei Tagen eine E-Mail mit Informationen zur Sendungsverfolgung",
  "unitSelection.buttonText": "Auswählen",
  "unitSelection.label": "Messeinheitsauswahl",
  "unitSelection.labelClose": "Messeinheitsauswahl schließen",
  "unitSelection.title": "Wähle Deine <strong>Maßeinheit</strong> aus",
  "voucher.pageName": "Gutschein einlösen",
  "voucherForm.buttonText": "Gutschein prüfen",
  "voucherForm.label": "Anmeldecode",
  "voucherForm.overlayButtonText": "mehr Info",
  "voucherForm.overlayText": "Deinen 12-stelligen Anmeldecode findest du in dem Schreiben, welches du von deiner Versicherung erhalten hast. Solltest du keinen Anmeldecode haben, wende dich bitte an deine Versicherung.",
  "voucherForm.overlayText.aok_1": "<p>Du erhältst deinen Anmeldecode direkt von deiner AOK-Bayern, sobald dein Arzt deine Versicherung über deine Teilnahme informiert. Derzeit erfolgt die Einschreibung zum mySugr Paket über Ärzte der folgenden Arzt-Netze:</p>\n\n<h2>Partner-Arztnetze</h2>\n<ul>\n<li><a href=\"http://patient-partner.de/aerzte-des-patient-partner-verbunds/\" target=\"_blank\" rel=\"noopener\">Patient-Partner Verbund</a></li>\n<li><a href=\"https://ugho.de/unsere-arzte/\" target=\"_blank\" rel=\"noopener\">UGHO - Unternehmung Gesundheit Hochfranken</a></li>\n</ul>",
  "voucherForm.overlayText.aok_nordwest_1": "Ihren 12-stelligen Gutscheincode erhalten Sie von Ihrem Behandler oder Arzt.",
  "voucherForm.overlayText.barmer_1": "Deinen 12-stelligen Anmeldecode findest du in dem Schreiben, welches du von deiner BARMER erhalten hast. Voraussetzung dafür ist, dass deine BARMER dich über das Angebot informiert und dir den Anmeldecode zur Verfügung gestellt hat.",
  "voucherForm.overlayText.bi_state_development_1": "Bitte gib den 12-stelligen Anmeldecode ein, den du erhalten hast, um das Angebot zu nutzen.",
  "voucherForm.overlayText.dkv": "Deinen 12-stelligen Anmeldecode findest du in dem Schreiben, welches du von deiner Versicherung erhalten hast. Solltest du keinen Anmeldecode haben, wende dich bitte an deine Versicherung.",
  "voucherForm.overlayText.fayette_county_1": "",
  "voucherForm.overlayText.genentech_1": "",
  "voucherForm.overlayText.jbz_1": "",
  "voucherForm.overlayText.mmc_1": "",
  "voucherForm.overlayText.onemed_1": "",
  "voucherForm.overlayText.roche_pl_1": "--- MISSING ---",
  "voucherForm.overlayText.roche_sg_1": "",
  "voucherForm.overlayText.schwenninger_1": "Deinen 12-stelligen Anmeldecode findest du in dem Schreiben, welches du von deiner Versicherung erhalten hast. Solltest du keinen Anmeldecode haben, wende dich bitte an deine Versicherung.",
  "voucherForm.overlayText.servidigest_1": "",
  "voucherForm.overlayText.si_group_1": "",
  "voucherForm.overlayText.zdg_1": "Deinen 12-stelligen Anmeldecode erhältst du von deinem Arzt/Behandler. Solltest du keinen Anmeldecode haben, wende dich bitte an deinen Arzt/Behandler.",
  "voucherForm.title": "Löse deinen Gutschein ein!"
};