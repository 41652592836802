import {
  BundleItemOrderImmediates,
  BundleItemOrderProducts,
  BundleItemOrderSubscriptions,
} from 'src/constants/itemsOrder';
import { IBundleConfigBundleFeatures } from 'src/types/IBundleConfig';
import IBundleGroup from 'src/types/IBundleGroup';
import IBundleGroupItem from 'src/types/IBundleGroupItem';
import orderBundleGroupItems from './orderBundleGroupItems';

function getBundleCartItems(group: IBundleGroup): IBundleConfigBundleFeatures {
  let immediate: IBundleGroupItem[] = [];
  let product: IBundleGroupItem[] = [];
  let subscription: IBundleGroupItem[] = [];

  if (group.items) {
    immediate = orderBundleGroupItems(group, BundleItemOrderImmediates);
    subscription = orderBundleGroupItems(group, BundleItemOrderSubscriptions);
    product = orderBundleGroupItems(group, BundleItemOrderProducts);
  }

  const cartParams: IBundleConfigBundleFeatures = {
    immediate,
    product,
    subscription,
  };

  return cartParams;
}

export default getBundleCartItems;
