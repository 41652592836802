import classNames from 'classnames';

import * as React from 'react';
import { connect } from 'react-redux';
import { inputBlurAction, inputFocusAction } from 'src/actions/formActions';
import { getTranslation } from 'src/selectors/translationSelectors';
import { INPUT_TYPE } from 'src/types/IInputType';
import IStoreState from 'src/types/IStoreState';
import css from './Input.module.scss';
import FieldMask, { IFieldMask } from 'src/lib/FieldMask';

interface IStoreProps {
  translations: {
    optionalPlaceholder?: string;
  };
}

interface IDispatchProps {
  onBlur?: (target: HTMLInputElement) => void;
  onFocus?: (target: HTMLInputElement) => void;
}

interface IOwnProps {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  formName: string;
  inputClassName?: string;
  name: string;
  onBlur?: (target: HTMLInputElement) => void;
  onChange?: (target: HTMLInputElement) => void;
  onFocus?: (target: HTMLInputElement) => void;
  onRef?: (ref: HTMLInputElement) => void;
  pattern?: string;
  placeholder?: string;
  success?: boolean;
  type: INPUT_TYPE;
  autocomplete?: string;
  value?: string;
  verified?: boolean;
  warning?: boolean;
  maxLength?: number;
  required?: boolean;
  mask?: IFieldMask[];
}

export interface IProps extends IDispatchProps, IOwnProps, IStoreProps {}

interface IState {
  selectPosition: number;
  fieldMask: FieldMask;
}

export class Input extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectPosition: 0,
      fieldMask: new FieldMask(props.mask, {
        value: props.value,
      }),
    };
  }

  public render() {
    const {
      className,
      disabled,
      error,
      inputClassName,
      name,
      pattern,
      placeholder,
      success,
      type,
      autocomplete,
      value,
      verified,
      warning,
      maxLength,
      required = true,
      translations,
    } = this.props;

    const computedProps: any = {};
    if (name && error) {
      computedProps['aria-describedby'] = `${name}-field-error ${name}-hint`;
    }

    const inputPlaceholder =
      placeholder || (!required && translations.optionalPlaceholder);

    if (inputPlaceholder) {
      computedProps.placeholder = inputPlaceholder;
    }

    if (typeof maxLength === 'number') {
      computedProps.maxLength = maxLength;
    }

    return (
      <div className={classNames(css.container, className)}>
        {this.renderMask()}
        <input
          {...computedProps}
          required={required}
          type={type}
          autoComplete={autocomplete}
          placeholder={inputPlaceholder || undefined}
          name={name}
          id={name}
          value={value}
          pattern={pattern}
          disabled={disabled ?? false}
          className={classNames(css.input, inputClassName, {
            [css.success]: success,
            [css.verified]: verified,
            [css.warning]: warning,
            [css.error]: error,
          })}
          ref={this.setRef}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />
      </div>
    );
  }

  private readonly renderMask = () => {
    const { mask = [], value = '' } = this.props;
    const maskText: string = mask.map(item => item.placeholder).join('');

    const maskElements: JSX.Element[] = maskText.split('').map((char, index) =>
      index < value.length ? (
        <span key={index} style={{ opacity: 0 }}>
          {value[index]}
        </span>
      ) : (
        <span key={index}>{char}</span>
      ),
    );

    return <span className={css.maskText}>{maskElements}</span>;
  };

  private readonly setRef = (ref: HTMLInputElement) => {
    if (this.props.onRef) {
      this.props.onRef(ref);
    }
  };

  private readonly handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { onChange } = this.props;
    const { fieldMask } = this.state;

    if (fieldMask.hasMask) {
      fieldMask.handleChange(event);

      const value = fieldMask.getValue();
      event.target.value = value;

      const selectStart = fieldMask.getSelectStart();
      event.target.setSelectionRange(selectStart, selectStart);
    }

    if (onChange) {
      onChange(event.target);
    }
  };

  private readonly handleFocus = (
    event: React.FocusEvent<HTMLInputElement>,
  ) => {
    const { onFocus } = this.props;
    this.state.fieldMask.handleFocus(event);
    if (onFocus) {
      onFocus(event.target);
    }
  };

  private readonly handleBlur = (
    event: React.SyntheticEvent<HTMLInputElement>,
  ) => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(event.currentTarget);
    }
  };
}

export function mapStateToProps(state: IStoreState): IStoreProps {
  const translations = {
    optionalPlaceholder: getTranslation('optionalPlaceholder')(state),
  };
  return {
    translations,
  };
}

export function mapDispatchToProps(
  dispatch: any,
  ownProps: IOwnProps,
): IDispatchProps {
  return {
    onBlur: (target: HTMLInputElement) => {
      dispatch(inputBlurAction(ownProps.formName, ownProps.name));
      if (ownProps.onBlur) {
        ownProps.onBlur(target);
      }
    },
    onFocus: (target: HTMLInputElement) => {
      dispatch(inputFocusAction(ownProps.formName, ownProps.name));
      if (ownProps.onFocus) {
        ownProps.onFocus(target);
      }
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Input);
