import { SUPPORTED_COUNTRY } from 'src/constants/countries';
import { LANGUAGE } from 'src/constants/languages';

function getLocaleFromPathname(
  pathname: string,
): [LANGUAGE?, SUPPORTED_COUNTRY?, string?] {
  // Regular expression to extract language and country from `pathname`
  // - Pathname can contain feature branch prefix, eg, `/feature/SHOP-499`
  // - Language first, country separated with dash
  // - Only language is allowed, eg, `/de` or `/de/payment`
  // - Language + country is allowed, `/en-us` or `/en-us/payment`
  const match =
    /^(\/feature\/SHOP-\d{1,4})?\/([a-z]{2})(-([a-z]{2}))?(\/|$)/.exec(
      pathname,
    );
  const path = pathname.split('/').splice(2).join('/');
  const language = match?.[2] ?? undefined;
  const country = match?.[4] ?? undefined;

  return [language as LANGUAGE, country, path];
}

export default getLocaleFromPathname;
